import en from 'vue2-datepicker/locale/en';
import fr from 'vue2-datepicker/locale/fr';
import 'vue2-datepicker/index.css';

export default {
  data () {
    return {
      locales: {
        en: en,
        fr: fr
      }
    }
  }
}
