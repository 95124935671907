var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c(
        "modal",
        {
          ref: "tagForm",
          attrs: {
            name: _vm.formName,
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form",
            draggable: ".drag-handle",
            delay: 100,
            resizable: true,
            width: "75%",
            height: "620",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          },
          on: { resize: _vm.getModalWidth }
        },
        [
          _c("div", { attrs: { id: "tagging-form" } }, [
            _c(
              "div",
              {
                staticClass: "iconButton link",
                class: { disabled: _vm.loading },
                on: { click: _vm.closeForm }
              },
              [_c("span", { staticClass: "ion-close" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              { ref: "modalHeader", staticClass: "modal-header drag-handle" },
              [
                _c(
                  "h6",
                  [
                    _vm.isBatchSelected
                      ? [_vm._v(_vm._s(_vm.$t("aruVerificationTagForm-batch")))]
                      : _vm.forUntagged
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm.$t("aruVerificationTagForm-allUntagged")
                            ) + " "
                          )
                        ]
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getTitleAndLocation()) +
                        " " +
                        _vm._s(
                          _vm.hasFormModified
                            ? "(* " + _vm.$t("common-unsaved") + ")"
                            : ""
                        )
                    )
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { ref: "scroller", staticClass: "modal-scroller" }, [
              _vm.options.allSpecies != null && _vm.localTag != null
                ? _c(
                    "div",
                    { staticClass: "modal-content container image-tagging" },
                    [
                      _vm.loading
                        ? _c(
                            "div",
                            [
                              _c("pulse-loader", {
                                attrs: {
                                  loading: true,
                                  color: "#C94412",
                                  size: "15px",
                                  width: 60,
                                  height: 30
                                }
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.$t("cameraTagging-updating")))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.length > 0 ||
                                _vm.message.length > 0 ||
                                _vm.validationMsg.length > 0,
                              expression:
                                "error.length > 0 || message.length > 0 || validationMsg.length > 0"
                            }
                          ],
                          staticClass: "link red-font",
                          on: {
                            click: function($event) {
                              _vm.error = []
                              _vm.validationMsg = []
                              _vm.message = []
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("cameraTagging-clearMessages")) +
                              "\n            "
                          ),
                          _c("span", { staticClass: "ion-android-close" })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.message, function(msg, index) {
                            return [
                              _c(
                                "div",
                                { key: "msg" + index, staticClass: "message" },
                                [_vm._v(" " + _vm._s(msg))]
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm._l(_vm.error, function(msg, index) {
                            return [
                              _c(
                                "div",
                                { key: "error" + index, staticClass: "error" },
                                [_vm._v(" " + _vm._s(msg))]
                              )
                            ]
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.validationMsg, function(msg, index) {
                            return [
                              _c(
                                "div",
                                {
                                  key: "validation" + index,
                                  staticClass: "validation"
                                },
                                [_vm._v(" " + _vm._s(msg))]
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.localTag.speciesTags, function(tag, index) {
                        return [
                          !tag.isLocked
                            ? _c(
                                "div",
                                {
                                  key: "tagRow" + index,
                                  staticClass: "row",
                                  class: {
                                    even: index % 2 == 0,
                                    odd: index % 2 == 1,
                                    "batch-update":
                                      tag.hasOwnProperty("isLocked") &&
                                      _vm.isBatchSelected
                                  }
                                },
                                [
                                  _vm.isEditable
                                    ? _c("span", {
                                        staticClass: "ion icon spp-new-tag",
                                        class: {
                                          "ion-ios-pricetag": tag.id == null,
                                          "ion-edit": tag.id != null
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "spp-column",
                                      class: _vm.getTagFieldClasses(
                                        _vm.modalWidth,
                                        "5"
                                      )
                                    },
                                    [
                                      _c("multiselect", {
                                        staticClass: "input",
                                        attrs: {
                                          selectAllLabel: _vm.$t(
                                            "common-selectAll"
                                          ),
                                          options: _vm.options.allSpecies,
                                          multiple: false,
                                          "close-on-select": true,
                                          disabled: !_vm.isEditable,
                                          placeholder: _vm.$tc(
                                            "common-species",
                                            1
                                          ),
                                          label: "commonName",
                                          "track-by": "id",
                                          selectLabel: "",
                                          deselectLabel: " ",
                                          "get-option-class":
                                            _vm.getSpeciesOptClass
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.speciesChanged(
                                              tag,
                                              _vm.id
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function({
                                                values,
                                                search,
                                                isOpen
                                              }) {
                                                return [
                                                  values.length && !isOpen
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "multiselect__single"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tc(
                                                                "common-speciesSelected",
                                                                values.length,
                                                                {
                                                                  num:
                                                                    values.length
                                                                }
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: tag.species,
                                          callback: function($$v) {
                                            _vm.$set(tag, "species", $$v)
                                          },
                                          expression: "tag.species"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.getTagFieldClasses(
                                        _vm.modalWidth
                                      )
                                    },
                                    [
                                      _c("multiselect", {
                                        staticClass: "input",
                                        class: _vm.getClass(tag.species, "age"),
                                        attrs: {
                                          selectAllLabel: _vm.$t(
                                            "common-selectAll"
                                          ),
                                          options: _vm.options.sex,
                                          multiple: false,
                                          "close-on-select": true,
                                          disabled: _vm.isSelectDisabled(
                                            tag.species,
                                            "sex"
                                          ),
                                          placeholder: _vm.$t(
                                            "taggingFilter-sex"
                                          ),
                                          label: "type",
                                          "track-by": "id",
                                          selectLabel: "",
                                          deselectLabel: " "
                                        },
                                        on: {
                                          input: function($event) {
                                            tag.hasChanged = true
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function({
                                                values,
                                                search,
                                                isOpen
                                              }) {
                                                return [
                                                  values.length && !isOpen
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "multiselect__single"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tc(
                                                                "common-speciesSelected",
                                                                values.length,
                                                                {
                                                                  num:
                                                                    values.length
                                                                }
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: tag.sex,
                                          callback: function($$v) {
                                            _vm.$set(tag, "sex", $$v)
                                          },
                                          expression: "tag.sex"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.getTagFieldClasses(
                                        _vm.modalWidth
                                      )
                                    },
                                    [
                                      _c("multiselect", {
                                        staticClass: "input",
                                        class: _vm.getClass(tag.species, "age"),
                                        attrs: {
                                          selectAllLabel: _vm.$t(
                                            "common-selectAll"
                                          ),
                                          options: _vm.options.age,
                                          multiple: false,
                                          "close-on-select": true,
                                          placeholder: _vm.$t(
                                            "taggingFilter-age"
                                          ),
                                          label: "type",
                                          "track-by": "id",
                                          selectLabel: "",
                                          deselectLabel: " ",
                                          disabled: _vm.isSelectDisabled(
                                            tag.species,
                                            "age"
                                          )
                                        },
                                        on: {
                                          input: function($event) {
                                            tag.hasChanged = true
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function({
                                                values,
                                                search,
                                                isOpen
                                              }) {
                                                return [
                                                  values.length && !isOpen
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "multiselect__single"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tc(
                                                                "common-speciesSelected",
                                                                values.length,
                                                                {
                                                                  num:
                                                                    values.length
                                                                }
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: tag.age,
                                          callback: function($$v) {
                                            _vm.$set(tag, "age", $$v)
                                          },
                                          expression: "tag.age"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.getTagFieldClasses(
                                        _vm.modalWidth,
                                        "1"
                                      )
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: tag.individualCount,
                                            expression: "tag.individualCount"
                                          }
                                        ],
                                        staticClass: "individual",
                                        class: _vm.getClass(
                                          tag.species,
                                          "count"
                                        ),
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t(
                                            "taggingFilter-individualNum"
                                          ),
                                          readonly: !_vm.isEditable
                                        },
                                        domProps: {
                                          value: tag.individualCount
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                tag,
                                                "individualCount",
                                                $event.target.value
                                              )
                                            },
                                            function($event) {
                                              tag.hasChanged = true
                                            }
                                          ]
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.getTagFieldClasses(
                                        _vm.modalWidth,
                                        "2"
                                      )
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col s12",
                                          class: { padtop10: !_vm.isEditable }
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "need-review",
                                              class: _vm.getClass(
                                                tag.species,
                                                "age"
                                              ),
                                              attrs: { for: "reviewed" + index }
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: tag.needsReview,
                                                    expression:
                                                      "tag.needsReview"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  disabled: !_vm.isEditable,
                                                  id: "reviewed" + index,
                                                  name: "review"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    tag.needsReview
                                                  )
                                                    ? _vm._i(
                                                        tag.needsReview,
                                                        null
                                                      ) > -1
                                                    : tag.needsReview
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a = tag.needsReview,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              tag,
                                                              "needsReview",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              tag,
                                                              "needsReview",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          tag,
                                                          "needsReview",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      tag.hasChanged = true
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    inactive: !_vm.isEditable
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "cameraTagging-review"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col s12" },
                                        [
                                          _vm.isEditable
                                            ? [
                                                _c("span", {
                                                  staticClass:
                                                    "ion ion-ios-copy-outline link",
                                                  class: _vm.getClass(
                                                    tag.species,
                                                    "age"
                                                  ),
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "recordingForms-duplicateTag"
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.duplicateTag(
                                                        tag
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("span", {
                                                  staticClass:
                                                    "ion ion-ios-list-outline link",
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "cameraTagging-showCommentField"
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggleComments(
                                                        tag
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                !_vm.isBatchSelected
                                                  ? _c("span", {
                                                      staticClass:
                                                        "ion ion-android-remove-circle link",
                                                      attrs: {
                                                        title: _vm.$t(
                                                          "aruVerificationModal-deleteTag"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteTag(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isBatchSelected &&
                                                tag.isLocked === false
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ion ion-android-delete link",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "cameraTagging-deleteTag"
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.batchDeleteTag(
                                                              tag,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "ion ion-android-delete link",
                                                          staticStyle: {
                                                            "font-size": "14px"
                                                          },
                                                          attrs: {
                                                            title: _vm.$t(
                                                              "cameraTagging-deleteTag"
                                                            )
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.isBatchSelected &&
                                                tag.isLocked == null
                                                  ? _c("span", {
                                                      staticClass:
                                                        "ion ion-android-remove-circle link",
                                                      attrs: {
                                                        title: _vm.$t(
                                                          "cameraTagging-deleteTag"
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteTag(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                tag.hasChanged
                                                  ? _c("span", [_vm._v("*")])
                                                  : _vm._e()
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isEditable
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: tag.showComments,
                                              expression: "tag.showComments"
                                            }
                                          ],
                                          staticClass: "row"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col s12" },
                                            [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: tag.comments,
                                                    expression: "tag.comments"
                                                  }
                                                ],
                                                staticClass: "tag-comments",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "common-comments"
                                                  ),
                                                  rows: "1"
                                                },
                                                domProps: {
                                                  value: tag.comments
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        tag,
                                                        "comments",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      tag.hasChanged = true
                                                    }
                                                  ]
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isEditable && tag.comments != null
                                    ? _c("div", { staticClass: "row" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(tag.comments))
                                        ])
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      }),
                      _vm._v(" "),
                      _vm.isEditable && !_vm.loading
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.hasTag,
                                  expression: "hasTag"
                                }
                              ],
                              staticClass: "row even"
                            },
                            [
                              _c("span", {
                                staticClass: "ion icon spp-new-tag"
                              }),
                              _c("div", { staticClass: "spp-column col s5" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-success add-spp",
                                    on: { click: _vm.addTag }
                                  },
                                  [_vm._v("+")]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isEditable && !_vm.loading
                        ? _c("div", { staticClass: "row" }, [
                            _vm.isBatchSelected
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-success",
                                    on: {
                                      click: function($event) {
                                        return _vm.batchSaveAll(true)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("cameraTagging-saveTagsAndClose")
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isBatchSelected
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-success",
                                    on: {
                                      click: function($event) {
                                        return _vm.batchSaveAll(false)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("cameraTagging-saveTags"))
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isBatchSelected
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false && _vm.hasTag,
                                        expression: "false && hasTag"
                                      }
                                    ],
                                    staticClass: "btn",
                                    on: { click: _vm.noTag }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("No Species Tag")))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isBatchSelected
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false && !_vm.hasTag,
                                        expression: "false && !hasTag"
                                      }
                                    ],
                                    staticClass: "btn",
                                    on: {
                                      click: function($event) {
                                        _vm.hasTag = true
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Has Species Tag")))]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col s12 m5",
                            class: {
                              l12: _vm.isBatchSelected,
                              l6: !_vm.isBatchSelected
                            }
                          },
                          [
                            _c(
                              "div",
                              { class: { "col s12 m8": _vm.isBatchSelected } },
                              [
                                _c("multiselect", {
                                  staticClass: "input",
                                  attrs: {
                                    selectAllLabel: _vm.$t("common-selectAll"),
                                    options: _vm.options.fieldOfView,
                                    multiple: false,
                                    "close-on-select": true,
                                    disabled: !_vm.isEditable,
                                    placeholder: _vm.$t(
                                      "cameraTagging-fieldOfView"
                                    ),
                                    label: "type",
                                    "track-by": "id",
                                    selectLabel: "",
                                    deselectLabel: " "
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function({
                                          values,
                                          search,
                                          isOpen
                                        }) {
                                          return [
                                            values.length && !isOpen
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "multiselect__single"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "aruVerificationTagForm-numFOVSelected",
                                                          values.length,
                                                          { num: values.length }
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2372132528
                                  ),
                                  model: {
                                    value: _vm.localTag.task.fov,
                                    callback: function($$v) {
                                      _vm.$set(_vm.localTag.task, "fov", $$v)
                                    },
                                    expression: "localTag.task.fov"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isBatchSelected &&
                            _vm.isEditable &&
                            !_vm.loading
                              ? _c("div", { staticClass: "col s12 m4" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btnimportant right btn-success",
                                      on: {
                                        click: function($event) {
                                          return _vm.batchUpdateTaskProperty(
                                            "fov"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common-update")))]
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col s12 m7",
                            class: {
                              "l2 padtop10": !_vm.isBatchSelected,
                              l12: _vm.isBatchSelected
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "col s12 m8 padtop10": _vm.isBatchSelected
                                }
                              },
                              [
                                _c("label", { attrs: { for: "featured" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.localTag.task.nice,
                                        expression: "localTag.task.nice"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "featured",
                                      name: "featured",
                                      disabled: !_vm.isEditable
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.localTag.task.nice
                                      )
                                        ? _vm._i(_vm.localTag.task.nice, null) >
                                          -1
                                        : _vm.localTag.task.nice
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.localTag.task.nice,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.localTag.task,
                                                "nice",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.localTag.task,
                                                "nice",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.localTag.task,
                                            "nice",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { class: { inactive: !_vm.isEditable } },
                                    [_vm._v(_vm._s(_vm.$t("Nice")))]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isBatchSelected &&
                            _vm.isEditable &&
                            !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "col s12 m4 right": _vm.isBatchSelected
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btnimportant right btn-success",
                                        on: {
                                          click: function($event) {
                                            return _vm.batchUpdateTaskProperty(
                                              "nice"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("common-update")))]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col s12 m6",
                            class: {
                              "l2 padtop10": !_vm.isBatchSelected,
                              l12: _vm.isBatchSelected
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "col s12 m8 padtop10": _vm.isBatchSelected
                                }
                              },
                              [
                                _c("label", { attrs: { for: "fire" } }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.localTag.task.fire,
                                        expression: "localTag.task.fire"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "fire",
                                      name: "fire",
                                      disabled: !_vm.isEditable && !_vm.loading
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.localTag.task.fire
                                      )
                                        ? _vm._i(_vm.localTag.task.fire, null) >
                                          -1
                                        : _vm.localTag.task.fire
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.localTag.task.fire,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.localTag.task,
                                                "fire",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.localTag.task,
                                                "fire",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.localTag.task,
                                            "fire",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { class: { inactive: !_vm.isEditable } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("cameraTagging-fire"))
                                      )
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isBatchSelected &&
                            _vm.isEditable &&
                            !_vm.loading
                              ? _c("div", { staticClass: "col s12 m4" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn right btn-success",
                                      on: {
                                        click: function($event) {
                                          return _vm.batchUpdateTaskProperty(
                                            "fire"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common-update")))]
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col s12 m6",
                            class: {
                              "l2 padtop10": !_vm.isBatchSelected,
                              l12: _vm.isBatchSelected
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "col s12 m8 padtop10": _vm.isBatchSelected
                                }
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "isMalfunction" } },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.localTag.task.malfunction,
                                          expression:
                                            "localTag.task.malfunction "
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "isMalfunction",
                                        name: "isMalfunction",
                                        disabled: !_vm.isEditable
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.localTag.task.malfunction
                                        )
                                          ? _vm._i(
                                              _vm.localTag.task.malfunction,
                                              null
                                            ) > -1
                                          : _vm.localTag.task.malfunction
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.localTag.task.malfunction,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.localTag.task,
                                                  "malfunction",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.localTag.task,
                                                  "malfunction",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.localTag.task,
                                              "malfunction",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { class: { inactive: !_vm.isEditable } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("cameraTagging-malfunction")
                                          ) + "\n                      "
                                        ),
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.tooltips.malfunction,
                                              expression: "tooltips.malfunction"
                                            }
                                          ],
                                          staticClass:
                                            "info-icon ion-information-circled"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isBatchSelected &&
                            _vm.isEditable &&
                            !_vm.loading
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "col s12 m4 right": _vm.isBatchSelected
                                    }
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btnimportant right btn-success",
                                        on: {
                                          click: function($event) {
                                            return _vm.batchUpdateTaskProperty(
                                              "malfunction"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("common-update")))]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col s12" }, [
                          _c(
                            "div",
                            { class: { "col m8": _vm.isBatchSelected } },
                            [
                              _vm.isEditable
                                ? _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("common-comments")) +
                                        ":\n                  "
                                    ),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.localTag.task.comments,
                                          expression: "localTag.task.comments"
                                        }
                                      ],
                                      staticClass: "tagform-textarea",
                                      domProps: {
                                        value: _vm.localTag.task.comments
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.localTag.task,
                                            "comments",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isEditable && _vm.localTag.task.comments
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.$t("common-comments")) + ": "
                                    ),
                                    !_vm.isEditable
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.localTag.task.comments)
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isBatchSelected && _vm.isEditable && !_vm.loading
                            ? _c(
                                "div",
                                {
                                  staticClass: "col s12 m4",
                                  staticStyle: { "padding-top": "40px" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-success right",
                                      on: {
                                        click: function($event) {
                                          return _vm.batchUpdateTaskProperty(
                                            "comments"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common-update")))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        !_vm.isBatchSelected && _vm.isEditable
                          ? _c(
                              "button",
                              {
                                staticClass: "dropdown-trigger btn btn-success",
                                attrs: {
                                  "data-target": "save-options",
                                  disabled: !_vm.hasFormModified
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.submitForm()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("cameraTagging-saveAllAndClose")
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isBatchSelected && _vm.isEditable
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "dropdown-trigger btn btnimportant",
                                attrs: {
                                  "data-target": "save-options",
                                  disabled: !_vm.hasFormModified
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.resetForm()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("common-reset")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-border modal-action modal-close",
                            class: { disabled: _vm.loading },
                            attrs: { id: "cancelAdd" },
                            on: {
                              click: function($event) {
                                return _vm.closeForm()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common-close")))]
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }