var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.users
    ? _c(
        "div",
        [
          _vm.errors.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "my-5 bg-red-300 border border-red-500 p-3 text-red-600 text-sm"
                },
                _vm._l(_vm.errors, function(error) {
                  return _c("span", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-client-table",
            {
              staticClass: "test",
              attrs: {
                data: _vm.data,
                columns: _vm.columns,
                options: _vm.options
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "user.name",
                    fn: function(props) {
                      return [
                        _c("div", [_vm._v(_vm._s(props.row.user.name))]),
                        _c("div", { staticClass: "text-xs" }, [
                          _vm._v(_vm._s(props.row.user.email))
                        ])
                      ]
                    }
                  },
                  {
                    key: "admin",
                    fn: function(props) {
                      return [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: props.row.admin,
                                expression: "props.row.admin"
                              }
                            ],
                            staticClass: "exclusive checkbox",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(props.row.admin)
                                ? _vm._i(props.row.admin, null) > -1
                                : props.row.admin
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = props.row.admin,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          props.row,
                                          "admin",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          props.row,
                                          "admin",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(props.row, "admin", $$c)
                                  }
                                },
                                () => _vm.onCheckboxChange(props.row)
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("span")
                        ])
                      ]
                    }
                  },
                  {
                    key: "readonly",
                    fn: function(props) {
                      return [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: props.row.readonly,
                                expression: "props.row.readonly"
                              }
                            ],
                            class: { "exclusive checkbox": true },
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.checkboxDisabled(props.row)
                            },
                            domProps: {
                              checked: Array.isArray(props.row.readonly)
                                ? _vm._i(props.row.readonly, null) > -1
                                : props.row.readonly
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = props.row.readonly,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          props.row,
                                          "readonly",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          props.row,
                                          "readonly",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(props.row, "readonly", $$c)
                                  }
                                },
                                () => _vm.onCheckboxChange(props.row)
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            class: {
                              "!cursor-default": _vm.checkboxDisabled(props.row)
                            }
                          })
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                2789246062
              )
            },
            [
              _vm._v(" "),
              _c("template", { slot: "h__admin" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("Admin")) + "\n            "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("projectForm-tooltips.admin"),
                        expression: "$t('projectForm-tooltips.admin')"
                      }
                    ]
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-info-circle text-yellow-400"
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("template", { slot: "h__readonly" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("Read Access")) +
                    "\n            "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("projectForm-tooltips.read"),
                        expression: "$t('projectForm-tooltips.read')"
                      }
                    ]
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-info-circle text-yellow-400"
                    })
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }