<template>

    <div style=" min-height: 600px;">
      <div class="row" style="position: relative;">
        <div  v-show="(ui.loading)" class="overaly" style="opacity: 0.6; position: absolute; width: 100%; height: 100%; z-index: 100; background: white;">
          <div style="text-align: center; margin-top:250px;">
            <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <div> {{ $t('aruSpeciesVerification-loadingTags') }} </div>
          </div>
        </div>
        <template v-if="!ui.loading && (!recordings || recordings.length === 0)" >
            <h5>{{ $t('aruSpeciesVerification-noTagsFound') }}</h5>
        </template>
        <div class="col s12 panel" ref="scrollBounds" id='scrollBounds'  v-if="recordings && recordings.length> 0 "> <!-- TP: removed class boxed. TODO: check effects. -->
          <div class="row" >
            <div class="col s12" :class="{fullWidth: ! ui.infoPanelVisible}" id="mainPanel">
              <div class="flex" >
                  <div class="flex-initial">
                    <select ref="limit" class="h-auto" v-model="queryParam.limit" @change="changeNumberPerPage">
                      <option v-for="(count, index) in ImagePerPageOption" :key="'countPerPage' + index" :value="count">{{$t('common-perPage', {count: count})}}</option>
                    </select>
                  </div>

                 <div class="grow"></div>
                  <pagination 
                      class="flex-initial" 
                      ref="paginationTop" 
                      v-if="totalPageNum >= 0 || pagination !== null" 
                      v-bind:active-page-number="queryParam.page" 
                      v-bind:pagination="pagination" 
                      v-bind:total-page-num="totalPageNum"
                      v-bind:time-stamp="timeStamp" 
                      v-bind:status-field="paginationStatusField" 
                      v-bind:order-display="orderDisplay"
                      v-bind:is-series="filterMode === 'series'"
                      />
              </div>

              <div class="overflow-x-scroll">
              <div class="min-w-280 " :key="queryParam.limit"  v-show="recordings && recordings.length> 0">
                  <drag-select-container ref="imageSelectArea" v-bind:input-selection ="selectedImgId" class="row less-margin pt-10"
                  selectorClass="drag-select" excludeSelectorClass ="exclude" :disable-drag-selection="filterMode === 'fov'">
                    <template slot-scope="{ selectedItems }">
                    <template v-for="(img, index) in recordings">
                      <div :key="img.tagId" class="drag-select mx-auto w-auto float-left min-w-max mr-3 ml-0 mb-2 bg-opacity-25 relative" :id="index" :data-item="img.tagId" 
                      :class="{'bg-green-500':img.isVerified, 'bg-yellow-500': img.needsReview, 'hidden': !tagVisible(img)}" v-if="index<queryParam.limit"
                      >
                        <div class="w-full h-full absolute left-0 top-0 bg-blue-800 z-10 bg-opacity-25 pointer-events-none" :class="getClasses(img, selectedItems)"></div>
                          <!-- Spectrogram Image container -->
                          <div class="min-w-60 flex border border-gray-500 items-center border-solid flex p-4 relative match-height" >
                            <div class="relative border-4 box-content mt-10 border-gray-500 border-solid bg-white" :style="{width: img.clipSpectrogramWidth+'px', height: img.clipSpectrogramHeight+'px'}">
                              <span class="absolute top-0 z-10 left-1 text-xs text-black">{{parseFloat(img.maxFreq/1000).toFixed(2)}}</span>
                              <img :src="img.tagSpectrogramURL" @load="() => onImageLoad(img)" class="absolute" :style="{width: img.clipSpectrogramWidth+'px', height: img.clipSpectrogramHeight+'px'}"  />
                              <span class="absolute bottom-0 z-20  left-1 text-xs text-black">{{parseFloat(img.minFreq/1000).toFixed(2)}}</span>

                              <div class="absolute w-px h-full bg-gray-600 top-0" :style="{left: ((img.clipStartTime/img.clipLength)*img.clipSpectrogramWidth)+'px'}"></div>
                              <div class="absolute w-px h-full bg-gray-600 top-0" :style="{left: ((img.clipEndTime/img.clipLength)*img.clipSpectrogramWidth)+'px'}"></div>
                              <div class="absolute w-px h-full bg-red-600 top-0" :style="{left: ((getAudioRef(img).currentTime/img.clipLength)*img.clipSpectrogramWidth)+'px'}"></div>
                            </div>
                            <!-- Play -->
                            <span v-if="showPlayPauseButton" class="cursor-pointer text-white absolute left-0 top-0 bg-gray-500 text-sm py-2 px-4 exclude"  
                              @click.stop="playPause(img)" :data-progress="img.animationProgress" >
                              <i :class="{'fas fa-play exclude': !getAudioRef(img) || getAudioRef(img).paused, 'fas fa-pause exclude': !getAudioRef(img).paused}"></i>
                            </span>
                            <!-- Maximize -->
                            <span class="cursor-pointer text-white absolute right-0 top-0 bg-gray-500 text-sm py-2 px-4 exclude" @click="openModal(img)">
                              <i class="far fa-window-maximize exclude"></i>
                            </span>
                          </div>
                          <!-- Toolbar -->
                          <div class="flex items-center p-3 text-center border-l border-r border-b border-gray-500 bg-white"> 
                            <i  v-tooltip="$t('aruSpeciesVerification-tooltips.verified')" class="flex-1  exclude" :class="{'!cursor-pointer':userCanModify, 'far fa-check-circle ':!img.isVerified,'fas fa-check-circle text-green-500':img.isVerified}"  @click="markVerified(img)" />
                            <!-- <i v-tooltip="$t('aruSpeciesVerification-tooltips.review')" class="flex-1 exclude" :class="{'far fa-question-circle':!img.needsReview,'fas fa-question-circle text-yellow-500':img.needsReview}" /> -->
                            
                              <i @click="$modal.show('rating-modal'); selectedImgId = [img.tagId]; " v-tooltip="$t('aruSpeciesVerification-tooltips.rating')" class="flex-1 exclude relative" :class="{'far fa-star':!img.rating,'fas fa-star text-yellow-500':img.rating}">
                                <span class="fa-layers-text fa-inverse font-light font-sans text-xs z-10 absolute inset-x-1/3 inset-y-1" data-fa-transform="shrink-6 down-2" v-if="img.rating">{{img.rating}}</span>
                              </i>
                            <!-- <div class="fa-4x">
                              <span class="fa-layers fa-fw" style="background:MistyRose">
                                <i class="fas fa-calendar"></i>
                                <span class="fa-layers-text fa-inverse" data-fa-transform="shrink-8 down-3" style="font-weight:900">27</span>
                              </span>
                            </div> -->
                            <i v-tooltip="$tc('common-task', 1)" class="flex-1 fas fa-tasks !cursor-pointer py-1 exclude" @click="()=> {window.open(recordingRowUrl(img),'_blank');}"></i>
                            
                            <i v-tooltip="birdNetTooltip(img)" class="flex-1 fas fa-brain"></i>
                            <i v-tooltip="loudnessTooltip(img)" class="flex-1 fas fa-volume-up"></i>
                            <span v-tooltip="$t('aruSpeciesVerification-tooltips.abundance')" class="flex-1" v-show="img.abundanceType!='TMTT'">{{img.abundanceType}}</span>
                            <i v-show="img.abundanceType=='TMTT'" class="flex-1 fas fa-infinity exclude"></i>

                            <audio :id="img.tagId+'-audio'" :ref="img.tagId">
                              <source :src="img.tagClipURL?img.tagClipURL:img.recordingURL" type="audio/mpeg">
                            </audio>
                          </div>
                        </div>
                        
                        <!-- Detail Modal -->
                        <tag-modal 
                          :key="index+'-modal'" 
                          :name="'modal-'+img.tagId" 
                          :tag="img" 
                          :ratingModal="() => {$modal.show('rating-modal')}" 
                          :markVerified="markVerified" 
                          :deleteModal="deleteModal" 
                          :userCanModify="userCanModify" 
                          :opened="()=>{setSelectedImgId(img.tagId)}"
                           />
                        
                        <!-- Delete Modal -->
                        <modal :key="'delete-modal-'+img.tagId" height="auto" :name="'delete-modal-'+img.tagId">
                          <div class="p-5">
                            <h5>{{$t('aruVerificationModal-deleteTag')}}</h5>
                            <span>{{$t('aruVerificationModal-deleteWarning')}}</span>
                            <div class="grid grid-cols-2 gap-4 mt-5">
                              <div @click="deleteTag(img)" class="btn"><i class="fas fa-check"></i> {{$t('common-yes')}}</div>
                              <div @click="() => { $modal.hide('delete-modal-'+img.tagId); }" class="btn"><i class="fas fa-times"></i> {{$t('common-no')}}</div>
                            </div> 
                          </div>
                        </modal>

                    </template>
                    </template>
                  </drag-select-container>
                </div>
                </div>

              <div class="" v-if="recordings && recordings.length> 0">
                <div class="w-full flex my-10">
                  <div class="grow"></div>
                  <!-- check this: https://materializecss.com/pagination.html#! -->
                  <pagination class="text-right flex-initial" ref="paginationBottom" v-if="totalPageNum >= 0 || pagination !== null"
                    v-bind:active-page-number="queryParam.page"
                    v-bind:pagination="pagination"
                    v-bind:time-stamp="timeStamp"
                    v-bind:total-page-num="totalPageNum"
                    v-bind:status-field="paginationStatusField"
                    v-bind:order-display="orderDisplay"
                    v-bind:is-series="filterMode === 'series'"/>
                </div>
              </div>

              <div class="col s12 m12 right-align" v-if="speciesId !== null && pagination!== null && userCanModify">
                  <div  class="col s12 m9 right-align" > {{messages}}</div>
                  <div  class="col s12 m3 right-align">
                    <!-- <button class="btn btnImportant" :disabled="ui.workingOnVerifySpp" @click="verifySpecies()" v-if="pagination[queryParam.page-1][paginationStatusField] !== 'full'">
                      <span class="ion-checkmark"></span> {{$t('cameraTagging-verifySpecies', {name: speciesObj.commonName})}}
                    </button> -->
                    <div v-if="pagination[queryParam.page-1][paginationStatusField] === 'full'">{{$t('cameraTagging-pageNumVerified', {num: queryParam.page})}}</div>
                  </div>
              </div>
            </div>
            <!-- -END Image PANEL- -->

          </div>
        </div>

      </div>
      <tag-form v-if="currentImgId !=null && staticOptions && staticOptions.age && staticOptions.age.length > 0"
          v-bind:id = "currentImgId"
          v-bind:form-name = "ui.tagFormName"
          v-bind:options = "staticOptions"
          v-bind:for-untagged = "forUntagged"
          v-bind:is-editable = "userCanModify" />
      <img-info v-bind:img="currentExifImgObj" v-bind:form-name="ui.exifFormName" />
      <modals-container />
      <modal name="error-modal" >
          <h5 class="px-3">Errors</h5>
           <div class="p-3 overflow-y-scroll h-48">
            <ul>
              <li class="text-sm" v-for="err in error">{{err}}</li>
            </ul> 
          </div>
          <div class="p-3">
            <button class="btn btn-default float-right" @click="()=>{$modal.hide('error-modal'); error = [];}">Close</button>
          </div>
      </modal>
    </div>
</template>

<script>
/* this component show is the camera tagging page.
*********************************** NOTE: images will not show up in localhost because of the proxy. ********************************************************
We did this so the image urls could be served from the same origin and thus we could rename image downloads appropriately
 */
// import Autocomplete from 'vuejs-auto-complete';
import {API_URL, ARU_RECORDING_URL} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import TaggingPagination from '@/components/ARU/TaggingPagination';
import TagForm from '@/components/ARU/TagForm';
import TaggingImageInfo from '@/components/ARU/TaggingImageInfo';
import TagDetailModal from './TagDetailModal.vue';
import anime from 'animejs/lib/anime.es';
import DragSelect from '@/components/utils/DragSelect.vue';

import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
export default {
  name: 'tagging-main-page',
  components: {
    'tag-form': TagForm,
    'pagination': TaggingPagination,
    'img-info': TaggingImageInfo,
    'pulse-loader': PulseLoader,
    'tag-modal': TagDetailModal,
    'drag-select-container': DragSelect

  },
  props: ['deploymentId', 'staticOptions', 'speciesObj', 'projectId', 'vocalizationId', 'filterMode', 'deploymentDetails', 'userCanModify', 'seriesGap'],
  computed: {
    areImagesLoaded () {
      if (!this.recordings) { return false; }
      return this.imagesLoaded === this.recordings.length;
    },
    errorMessages() {
      return this.error;
    }
    
  },
  /**************************************************************************************
   *                                METHODS
   **************************************************************************************/
  methods: {
    birdNetTooltip(tag) {
      return `${this.$t('aruSpeciesVerification-tooltips.birdnet')}: ${tag.birdNetConfidence} <br />
       ${this.$t('In recording BirdNET Probability')}: ${tag.birdNetMaxRecordingConfidence}
      `;
    },
    loudnessTooltip(tag) {
      return `${this.$t('aruSpeciesVerification-tooltips.peakDBFS')}: ${tag.peakDBFS} <br />
      ${this.$t('rootMeanSquarePeakDBFS')}: ${parseFloat(tag.rootMeanSquarePeakDBFS).toFixed(2)}<br />
      ${this.$t('rootMeanSquareTroughDBFS')}: ${parseFloat(tag.rootMeanSquareTroughDBFS).toFixed(2)} <br />
      ${this.$t('freqFilteredRootMeanSquarePeakDBFS')}: ${parseFloat(tag.freqFilteredRootMeanSquarePeakDBFS).toFixed(2)}<br />
      ${this.$t('freqFilteredRootMeanSquareTroughDBFS')}: ${parseFloat(tag.freqFilteredRootMeanSquareTroughDBFS).toFixed(2)}`
    },
    setSelectedImgId (id) {
      this.selectedImgId = [id];
    },
    batchEditImageTags () {
      if (!this.userCanModify) {
        return;
      }
      this.currentImgId = null;
      this.forUntagged = false;
      if (!this.selectedImgId) {
        return;
      }

      this.markVerified(this.selectedImgId);
    },
    batchEditTags(edits) {
      if (!this.userCanModify) {
        return;
      }
      this.currentImgId = null;
      this.forUntagged = false;
      if (!this.selectedImgId) {
        return;
      }
      this.edit(this.selectedImgId, edits);
    },
    batchRateTags (rating) {
      if (!this.userCanModify) {
        return;
      }
      this.currentImgId = null;
      this.forUntagged = false;
      if (!this.selectedImgId) {
        return;
      }
      this.rate(this.selectedImgId, rating);
    },
    getClasses (tag, selectedItems) {
      return {
        'hidden': !(selectedItems.find((selectedItem) => {
          return parseInt(selectedItem.dataset.item, 10) === tag.tagId
        }))
      }
    },
    aiCheckTooltip (tag) {
      let arr = [];
      arr[0] = `${tag.speciesCode}: ${tag.birdNetConfidence || '0.00'}`;
      if (tag.birdNetResults && tag.birdNetResults.length > 0) {
        tag.birdNetResults.forEach((v, i) => {
          arr[i + 1] = `${v.speciesCode}: ${v.birdNetConfidence}`;
        });
      }
      return arr.join('<br />');
    },
    aiCheckColor (tag) {
      let red = 255;
      let green = 0;
      let blue = 0;

      if (tag.birdNetConfidence) {
        green = tag.birdNetConfidence * 255;
        red = 255 - green;
      }

      if (tag.taskId === 71145) { console.log(red, green); }

      return {color: `rgb(${red}, ${green}, ${blue})`};
    },
    openModal (tag) {
      this.$modal.show('modal-' + tag.tagId);
    },
    onImageLoad (img) {
      img.loaded = true;
      this.imagesLoaded++;
    },
    tagVisible (tag) {
      return (tag.isVerified && ['all', 'verified'].includes(this.tagVisibility)) || (!tag.isVerified && ['all', 'unverified'].includes(this.tagVisibility));
    },
    verifySpecies () {
      this.recordings.forEach((v, i) => {
        this.recordings[i].isVerified = true;
      });
      const batchUpdateTagAPI = API_URL + 'update-aru-tag-species-verification-flags'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
      this.$http.put(batchUpdateTagAPI, this.recordings).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + response.data.data);
        }
      }, (err) => {
        this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + err.data);
      });
    },
    recordingRowUrl: function (tag) {
      return ARU_RECORDING_URL + tag.taskId + '&tagId=' + tag.tagId;
    },
    playPause (tag) {
      let audio = this.getAudioRef(tag);
      if (audio) {
        if (audio.paused) {
          this.play(tag);
        } else { this.pause(tag); }
        this.$forceUpdate();
        this.$forceUpdate();
      }
    },
    play (tag) {
      let audio = this.getAudioRef(tag);
      if (audio) {
        tag.animator.play();
        audio.play();
      }
    },
    pause (tag) {
      let audio = tag.audio;
      if (audio) {
        tag.animator.pause();
        audio.pause();
        this.showPlayPauseButton = false;
        this.showPlayPauseButton = true;
      }
    },
    reset (tag) {
      let audio = this.getAudioRef(tag);
      if (audio) {
        audio.currentTime = 0;
      }
    },
    getAudioRef (tag) {
      if (tag.audio) {
        return tag.audio;
      }

      let audio = this.$refs[tag.tagId];
      if (audio && audio.length === 1) {
        tag.audio = audio[0];
        return tag.audio;
      }
      return false;
    },
    findTagbyID (tagID) {
      return this.recordings.find(x => x.tagId === tagID);
    },
    changeNumberPerPage () {
      this.queryParam.page = 1;
      // this.reloadImage(true); this call was causing errors
      if (this.$refs.limit) {
        this.$refs.limit.blur();
      }
      this.totalPageNum = this.queryParam.limit;
      this.getTags();
      this.getPagination();
      this.imagesLoaded = 0;
      this.selectedImageCount = 0;
    },
    deleteModal (tag) {
      this.$modal.show('delete-modal-' + tag.tagId);
    },
    deleteTag (tag) {
      if (!this.userCanModify) { return; }
      const config = {body: {id: tag.tagId}}; // toggle isNice
      const deleteTagAPI = API_URL + 'delete-tag'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
     
      this.$http.delete(deleteTagAPI, config).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('cameraTagging-failedNice') + ' ' + response.data.data);
        } else {
          this.recordings.forEach((v, i) => {
            if (v.tagId === tag.tagId) { this.recordings.splice(i, 1); this.$modal.hide('modal-' + tag.tagId); }
          });
        }
      }, (err) => {
        this.error.push(this.$t('cameraTagging-failedNice') + ' ' + err.data);
      });
    },
    markNice (tag) {
      if (!this.userCanModify) { return; }
      const params = [{tagId: tag.tagId, isNice: !tag.isNice}]; // toggle isNice
      const batchUpdateTagAPI = API_URL + 'update-aru-tag-species-verification-flags'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
      this.$http.put(batchUpdateTagAPI, params).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('cameraTagging-failedNice') + ' ' + response.data.data);
        } else {
          tag.isNice = !tag.isNice;
        }
      }, (err) => {
        this.error.push(this.$t('cameraTagging-failedNice') + ' ' + err.data);
      });
    },
    markVerified (tag, flag = true) {
      if (!this.userCanModify) { return; }

      let params = null;
      console.log(this.recordings);

      if (Array.isArray(tag)) {
        params = [];
        tag.forEach(tagId => {
          params.push({tagId, isVerified: flag});
        });
      } else {
        params = [{tagId: tag.tagId, isVerified: !tag.isVerified}]; // toggle isNice
      }

      const batchUpdateTagAPI = API_URL + 'update-aru-tag-species-verification-flags'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
      this.$http.put(batchUpdateTagAPI, params).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + response.data.data);
        } else {
          if (Array.isArray(tag)) {
            tag.forEach(tagId => {
              this.recordings.find(v => {
                if (v.tagId === tagId) { v.isVerified = flag; if (flag === false) { v.rating = null; } }
              });
            });
          } else {
            tag.isVerified = !tag.isVerified;
            if (!tag.isVerified) {
              tag.rating = null;
            }
          }
        }
      }, (err) => {
        this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + err.data);
      });
    },
    edit (tag, edits = {}) {
      if (!this.userCanModify) { return; }

          let params = null;

          if (Array.isArray(tag)) {
            params = [];
            tag.forEach(tagId => {
              let temp = 
              params.push({ ...edits, tagId});
            });
          } else { // not array? Dont bother
            return;
          }

          const batchUpdateTagAPI = API_URL + 'update-aru-tag-species-verification-flags'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
          this.$http.put(batchUpdateTagAPI, params).then(function (response) {
            if (Array.isArray(response.body) && response.body.length > 0) { //This is the error state ["SOME ERROR MESSAGE"]
              response.body.forEach(err => {
                this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + err);
              });
            } else {
              if (Array.isArray(tag)) {
                tag.forEach(tagId => {
                  this.recordings.find(v => {
                    if (v.tagId === tagId) { 
                      if(typeof edits.rating != 'undefined') v.rating = rating; 
                      if(typeof edits.isVerified != 'undefined') { v.isVerified = true; }
                    }
                  });
                });
              } 
              if (edits.newSpeciesId || edits.newVocalizationId) 
                eventBus.$emit('refresh');
            }
          }, (err) => {
            this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + err.data);
          });
    },
    rate (tag, rating) {
      if (!this.userCanModify) { return; }

      let params = null;

      if (Array.isArray(tag)) {
        params = [];
        tag.forEach(tagId => {
          params.push({ tagId, 'rating': rating, isVerified: true });
        });
      } else { // not array? Dont bother
        return;
      }

      const batchUpdateTagAPI = API_URL + 'update-aru-tag-species-verification-flags'; // ? taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'
      this.$http.put(batchUpdateTagAPI, params).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + response.data.data);
        } else {
          if (Array.isArray(tag)) {
            tag.forEach(tagId => {
              this.recordings.find(v => {
                if (v.tagId === tagId) { v.rating = rating; v.isVerified = true; }
              });
            });
          } 
        }
      }, (err) => {
        this.error.push(this.$t('aruSpeciesVerification-failedToUpdateVerifiedIndicator') + ' ' + err.data);
      });
    },
    matchHeights () {
      let heighest = 0;
      setTimeout(() => {
      document.querySelectorAll('.match-height').forEach(v => {
        if (v.clientHeight > heighest) { heighest = v.clientHeight; }
      });
      document.querySelectorAll('.match-height').forEach(v => {
        v.style.height = `${heighest}px`;
      });
      }, 100);
    },
    getTags () {
      this.ui.loading = true;
      this.$http.get(this.getImageListUrl, {params: this.queryParam}).then(
        function (response) {
          this.ui.loading = false;
          if (response.body.hasOwnProperty('error')) {
            this.error = response.body.error;
            this.recordings = [];
          } else {
          // depends on whether untagged image exists, toggle update all untagged button
            response.body.forEach((v, i) => {
              let that = this;
              response.body[i] = {
                ...v,
                loaded: this.recordings && this.recordings[i] !== 'undefined',
                audio: null,
                animationProgress: 0,
                animator: new anime({
                  targets: '#' + v.tagId + ' .spectro-line',
                  left: v.clipSpectrogramWidth + 'px',
                  autoplay: false,
                  duration: (v.clipLength * 1000),
                  easing: 'linear',
                  update: ani => {
                    that.findTagbyID(v.tagId).animationProgress = ani.progress;
                    that.$forceUpdate();
                  },
                  complete: (ani) => {
                    v.animator = ani;
                    ani.restart();
                    this.pause(that.findTagbyID(v.tagId));
                    this.reset(that.findTagbyID(v.tagId));
                  }
                })
              };
            });
            this.recordings = response.body;
            
            eventBus.$emit('tags-loaded');
            
          }
        },
        function () {
          this.ui.loading = false;
          this.recordings = [];
        }
      );
    },
    getPagination () {
      this.$http.get(this.getPaginationUrl, {params: this.queryParam}).then(
        response => {
          this.pagination = response.body;
        }, () => {});
    },
    selectAll () {
      this.selectedImgId = this.recordings.map(item => item.tagId);
      this.selectedImageCount = this.selectedImgId.length;
      this.$forceUpdate();
    },
    unselectAll () {
      this.selectedImgId = [];
      this.selectedImageCount = 0;
      this.$forceUpdate();
    },
    selectAllUntagged () {
      // console.log(this.imageList.filter(item => !item.species || item.species.length === 0));
      this.selectedImgId = this.recordings.filter(item => !item.isVerified).map(item => item.tagId);
      this.selectedImageCount = this.selectedImgId.length;
      this.$forceUpdate();
      return this.selectedImgId.length;
    },
    keyPressed (e) { //
      if (e.target.nodeName === 'TEXTAREA' ||
        e.target.nodeName === 'INPUT' ||
        e.target.nodeName === 'SELECT' ||
        e.target.nodeName === 'CHECKBOX' ||
        e.target.nodeName === 'DIV') { /* may need to remove div if other divs become selectable */
        return;
      }
      if (this.ui.loading) {
        return;
      }

      if (e.code === 'Escape' || e.code === 'ArrowRight' || e.code === 'ArrowLeft') { /* these 3 keys are used for image navigation as well, don't use it when image lightbox is showing */
        let lightBox = document.getElementById('mylightbox');
        // console.log(e.target, lightBox, document.body.style.overflow)
        if (lightBox && lightBox.childNodes.length > 0 && lightBox.childNodes[0] && lightBox.childNodes[0].classList && lightBox.childNodes[0].classList.contains('lightbox')) { // no use when in lightbox view
          return;
        }
      }

      if (e.code === 'KeyA' && e.ctrlKey) { /* for control A or ctrl a select all */
        e.preventDefault();
        this.selectAll();
      } else if (e.code === 'KeyQ' && e.ctrlKey) { /* for control q or ctrl Q select all untagged images */
        e.preventDefault();
        this.selectAllUntagged();
      } else if (e.code === 'KeyD' && e.ctrlKey) { /* for control q or ctrl Q select all untagged images */
        e.preventDefault();
        this.unselectAll();
      } else if (e.code === 'KeyS' && e.ctrlKey) { /* for control T toggle tagged only */
        e.preventDefault();
        this.tagVisibility = this.tagVisibility === 'all' ? 'verified' : 'all';
      } else if (e.code === 'KeyE' && e.ctrlKey) { /* for control E toggle untagged only */
        e.preventDefault();
        this.tagVisibility = this.tagVisibility === 'all' ? 'unverified' : 'all';
      } else if (e.code === 'KeyB' && e.ctrlKey) { /* for control B toggle partial only */
        e.preventDefault();
        this.ui.showImageType = this.ui.showImageType === 'all' ? 'partial' : 'all';
      } else if (e.code === 'Escape') { // escape, clear selection
        e.preventDefault();
        this.unselectAll();
      } else if (e.code === 'ArrowRight' || e.code === 'KeyD') { // right arrow 39, next page or D
        e.preventDefault();
        if (this.queryParam.page >= this.pagination.length - 1) { // if it is already on first page do nothing.
          return;
        }
        // this.ui.loading = true;
        // this.queryParam.page++;
        // this.reloadImage();
        eventBus.$emit('pick-page', this.queryParam.page + 1);
      } else if (e.code === 'ArrowLeft' || e.code === 'KeyA') { // left arrow 37, previous page
        e.preventDefault();
        if (this.queryParam.page <= 1) { // if it is already on first page do nothing.
          return;
        }
        // this.queryParam.page--;
        // this.ui.loading = true;
        eventBus.$emit('pick-page', this.queryParam.page - 1);
      } else if (e.code === 'KeyS') { // w scroll down
        window.scrollBy(0, 200);
      } else if (e.code === 'KeyW') { // w scroll up
        window.scrollBy(0, -200);
      } else if (e.shiftKey && e.ctrlKey && ['Digit1', 'Digit2','Digit3','Digit4', 'Digit5'].includes(e.code)) {
        this.batchRateTags(e.code[e.code.length-1]);
      }
    }
  },
  /**************************************************************************************
   *                                CREATED
   **************************************************************************************/
  created () {
    // this.reloadImage(false);
    const self = this;
    this.queryParam.projectId = this.projectId;
    this.queryParam.speciesId = this.speciesObj.id;
    this.queryParam.vocalizationId = this.vocalizationId;

    this.totalPageNum = this.queryParam.limit;

    if (this.speciesObj) {
      this.speciesId = this.speciesObj.id;
      this.paginationStatusField = 'verified';
      this.orderDisplay = ['', ''];
    } else {
      this.paginationStatusField = 'status';
    }

    this.userSeriesGap = this.userSeriesOptions[0];

    /* mouse drag and draw selection on left number and right panel image change selection
    use this the synchronize them, and move the firs image into view */
    eventBus.$on('selection-change', function (selection, name) {
      // console.log('parent selection changed')
      self.selectedImageCount = selection.length;
      self.ui.showSelectAll = (self.selectedImageCount < self.recordings.length); // when selected < total record

      self.selectedImgIndex = selection;
      self.selectedImgId = selection.map(item => parseInt(item.dataset.item, 10));
      /* make the first selection to scroll into view when user uses left side panel to select */
      const newVal = self.selectedImgId;
      // try {
      if (newVal && newVal.length > 0 && name === 'numberArea') {
        const dragArea = self.$refs.numberPanel;
        let imgIndex = -1;
        for (let i = 0; i < dragArea.length; i++) {
          // console.log(dragArea[i].classList);
          if (dragArea[i].classList.contains('selected')) {
            imgIndex = i;
            break;
          }
        }

        if (this.currentInViewImageIndex !== imgIndex && imgIndex > -1 && imgIndex < self.$refs.imagePanel.length) {
          this.currentInViewImageIndex = imgIndex;
          self.$refs.imagePanel[imgIndex].scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
        }
      }
    });
    /* when user click a page in pagination */
    eventBus.$on('pick-page', function (pageNumber) {
      if (self.queryParam.page !== pageNumber) {
        self.queryParam.page = pageNumber;
        self.getTags();
        self.selectedImageCount = 0;
      }
    });

    /* when user click apply filter,
      assign filter paramter to query params, they are in different format
      remove empty filters, and then send a flag to top menu to show filtered icon
 */
    eventBus.$on('apply-filter', function (queryParam) {
      // clear parameters that is not in initial list, otherwise, if previous set as nice
      // after removed from filter ui, you still have the nice flag here.
      Object.keys(self.queryParam).forEach(key => {
        if (!self.initalParamNames.includes(key)) {
          this.$delete(self.queryParam, key);
        }
      });
      self.queryParam.page = 1; // reset page to the 1
      self.queryParam = Object.assign(self.queryParam, queryParam); // (target, source)
      // console.log(Object.keys(queryParam));
      const queryForFalseFields = ['isVerified'];
      const alwaysPassFields = ['excludeAutoTag'];
      Object.keys(queryParam).forEach(key => {
        if (!alwaysPassFields.includes(key)) { // for some fields, we want to pass true or false all the time.
          const nonAcceptedValue = queryForFalseFields.includes(key); // default: remove false, for query false field, remove true values
          const value = queryParam[key];
          if (value == null || value === nonAcceptedValue || value === '' || value.length === 0 || key.startsWith('tmp')) { // remove empty or false values
            if (self.queryParam.hasOwnProperty(key)) {
              this.$delete(self.queryParam, key);
            }
            this.$delete(queryParam, key);
          }
        }
      });
      /* don't load total count for series view */
      // self.reloadImage(true);
      // console.log(queryParam);
      let alwaysRemoveFields = ['limit', 'page', 'useSeriesView'];
      if (self.filterMode === 'series') { /* hide auto tagger and show motion only */
        alwaysRemoveFields.push('triggerModeId');
      }
      alwaysRemoveFields.forEach(key => { this.$delete(queryParam, key); });

      self.getTags();

      eventBus.$emit('update-filter-flag', queryParam); // let main page know if there is filter or not
    });
    /* when user updated tag information */
    eventBus.$on('refresh-tag', function (queryParam) {
      // self.reloadImage(true, true);
    });

    eventBus.$on('refresh', function() {
      self.getTags();
    });

    eventBus.$on('close-tagging-form', function (formName, toClose) {
      self.currentImgId = null; // remove selected ID
      eventBus.$emit('toggle-modal-form', formName, false);
    });

    this.ui.loading = true;

    this.getTags();
    this.getPagination();
  },
  /**************************************************************************************
   *                                MOUNTED
   **************************************************************************************/
  mounted () {
    const that = this;
    window.addEventListener('keydown', function (event) { /* don't use keyup, otherwise the default action will not be prevented */
      that.keyPressed(event);
    });

    eventBus.$on('all-images-loaded', function (event) {
      that.matchHeights();
    });

    eventBus.$on('tags-loaded', (event) => {
      // set the current time for each recording to tag start.
      that.$nextTick(() => {
        that.matchHeights();
        if (that.$refs['imageSelectArea']) {
          that.$refs['imageSelectArea'].contentUpdated();
        }
      });
      
    });
  },
  /**************************************************************************************
   *                                WATCH
   **************************************************************************************/
  watch: {
    errorMessages(newVal, oldVal) {
      if (newVal.length > 0) {
        this.$modal.show('error-modal');
      }
    },
    filterMode (newVal, oldVal) { // when user switch tabs, use different field for pagination status
      if (newVal !== oldVal) {
        this.orderDisplay = [this.$t('common-oldest'), this.$t('common-newest')];
        if (newVal === 'fov') {
          this.paginationStatusField = 'is_fov status';
          this.orderDisplay = [this.$t('common-newest'), this.$t('common-oldest')];
        } else if (this.filterMode === 'tag') {
          this.paginationStatusField = 'status';
        }

        if (newVal === 'series') {
          this.orderDisplay = ['', ''];
        }
      }
    },
    areImagesLoaded (newVal, oldVal) {
      if (newVal === true) {
        eventBus.$emit('all-images-loaded');
      }
    },
    selectedImageCount (newVal, oldVal) {
      if (newVal !== oldVal) { this.$emit('imageCountChange', newVal); }
    }
  },
  /**************************************************************************************
   *                                DATA
   **************************************************************************************/
  data () {
    return {
      error: [],
      getImageListUrl: API_URL + 'get-aru-species-verification-view', // 'get-camera-task-for-proj-user-deploy', // static options such as sex age
      // no longer used getImageCountUrl: API_URL + 'get-camera-image-count', // 'get-camera-task-for-proj-user-deploy', // static options such as sex age
      getPaginationUrl: API_URL + 'get-aru-species-verification-pagination', // get pagination
      queryParam: {
        limit: 80,
        page: 1,
        vocalizationId: 0,
        speciesId: 0,
        projectId: this.projectId
      },
      initalParamNames: ['sortAsc', 'limit', 'sort', 'vocalizationId', 'speciesId', 'projectId'],
      currentImgId: null,
      forUntagged: false,
      appliedUserSeriesGap: null,
      userSeriesOptions: [
        {
          id: 0,
          value: null,
          text: this.$t('cameraTagging-seriesOptions[0]')
        },
        {
          id: 1,
          value: 60,
          text: this.$t('cameraTagging-seriesOptions[1]')
        },
        {
          id: 2,
          value: 120,
          text: this.$t('cameraTagging-seriesOptions[2]')
        },
        {
          id: 3,
          value: 300,
          text: this.$t('cameraTagging-seriesOptions[3]')
        },
        {
          id: 4,
          value: 600,
          text: this.$t('cameraTagging-seriesOptions[4]')
        },
        {
          id: 5,
          value: 1800,
          text: this.$t('cameraTagging-seriesOptions[5]')
        },
        {
          id: 6,
          value: 3600,
          text: this.$t('cameraTagging-seriesOptions[6]')
        },
        {
          id: 7,
          value: 18000,
          text: this.$t('cameraTagging-seriesOptions[7]')
        },
        {
          id: 8,
          value: 36000,
          text: this.$t('cameraTagging-seriesOptions[8]')
        }
      ],
      userSeriesGap: null,
      selectedImgId: [],
      recordings: null,
      imagesLoaded: 0,
      lightboxImages: [],
      ImagePerPageOption: [12, 24, 48, 60, 80, 200, 500],
      pagination: null,
      totalPageNum: 0,
      totalImageCount: 1000,
      selectedImageCount: 0,
      ui: {
        infoPanelVisible: true,
        numberPanelClass: 'numberCol',
        imagePanelClass: 'imgWrap',
        /* form name */
        tagFormName: 'tagging-form',
        infoFormName: 'image-info-form',
        exifFormName: 'exif-form-name',
        loading: true,
        showImageType: 'all',
        showSelectAll: true,
        workingOnVerifySpp: false

      },
      currentInViewImageIndex: -1,
      currentExifImgObj: null,

      speciesId: null,
      messages: null,
      paginationStatusField: null,
      timeStamp: null,
      untaggedImageCount: 0,
      orderDisplay: [this.$t('common-oldest'), this.$t('common-newest')],
      selected: [],
      tagVisibility: 'all',
      showPlayPauseButton: true
    }
  }
}
</script>
<style scoped>
/* Custom styling */
.numberCol.selected {
  border:#91efef solid 1px;
}

.selected.active {
    background-color: #91efef;
}

#infoPanel .vue-drag-select {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}
.lightbox-fade-enter-active,
    .lightbox-fade-leave-active {
        transition: none!important;
    }

.lightbox-fade-enter,
    .lightbox-fade-leave-to {
        opacity: 1!important;
    }

.lightbox-slide-next-enter-active,
    .lightbox-slide-next-leave-active,
    .lightbox-slide-prev-enter-active,
    .lightbox-slide-prev-leave-active{
        transition: all 0s ease;
}

.multipleSpecies { /* top right red triangle */
      width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #b73333;
    border-top: 5px solid #b73333;
    border-left: 5px solid transparent;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
 .multipleCount, .multipleIndividual{/* top left blue triangle */
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #337ab7;
    border-left: 5px solid #337ab7;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
/* .outOfRange {/* top left blue triangle *
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-left: 22px solid transparent;
    border-bottom: 22px solid #e6e92e87;
    border-right: 22px solid #e6e92e8f;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
} */

  .outOfRange:before {
    content: "";
    position: absolute;
    top: 0%;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left top, rgba(224, 226, 97, 0.6) 50%, transparent 50%)
}

.remote-camera #infoPanel .numberRow .numberCol.untagged .number{
    border:1px solid #ccc;
}
.remote-camera #infoPanel .numberRow .numberCol .number{
  position: relative;
}

#selectable-wrapper .delete {
  opacity: 1!important;
  -webkit-transform: scale(1)!important;
  -ms-transform: scale(1)!important;
  transform: scale(1)!important;
}
.tag-details {
    height: 1.8em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.remote-camera #mainPanel .row .capture .vidData .number {
    border: #c3c3bf solid 1px;
}
.remote-camera #mainPanel .row .capture.partial .number,
.remote-camera #infoPanel .numberRow .numberCol.partial .number {

    background: rgb(203, 221, 206);
    color: #666;
}

.hidden {
  display: none;
}
.remote-camera #infoPanel .numberRow .numberCol.hidden {
  display: none;
}
.red-orange-colored {
  color: #C94412;
}

.remote-camera #mainPanel .row .capture .imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    /* -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
     transform: scale(0.1); */
     transition-delay: 0.8s;

}
.remote-camera #mainPanel .row .capture .imgWrap:hover .imgOverlay {
    opacity: 1;
    display:flex;
    /* -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); */
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    /* transition-delay: 0.5s!important; */
}
.remote-camera #mainPanel .row .capture .imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    /* -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
     transform: scale(0.1); */
     /* transition-delay: 0.8s; */

}
.remote-camera #mainPanel .row .capture .imgWrap .imgOverlay .magnify{
    font-size: 1px;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.5s!important;
}
.remote-camera #mainPanel .row .capture .imgWrap:hover .imgOverlay span{
    font-size: 2.3rem;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.8s!important;
}

.imgWrap {
  width: 100%;
}
.lightbox {
  z-index: 20000!important;
}

.remote-camera #infoPanel .numberRow .numberCol.selected.untagged .number {
   color: #999;
}
.remote-camera #infoPanel .numberRow .numberCol.need-review .number {
 border: #FF9800 4px solid;
}

.less-margin {
  margin: 0!important;
}

.remote-camera #mainPanel { /* reduce padding on left side */
    padding-left: 2%!important;
}

.vue-drag-select {
  width: 100%;
}

@media only screen and (min-width: 601px) {
 #scrollBounds .row .col.m2.custom {
    width: 12%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
 #scrollBounds .row .col.m10.custom {
    width: 88%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

.tagSpp {
  font-weight: 600;
  color: #227cc3;
}

.remote-camera #mainPanel .row .capture .vidData .info .tag-text {
  font-size: 15px;
}

.numberSelectArea {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.vue-drag-select.numberSelectArea.scroller {
  overflow-y: scroll!important;
}

select.record-per-page {
  display: inline;
  width: unset;
}
.remote-camera #mainPanel .row .capture .vidData .moreInfo {
  top: 2px;
  right: -5px;
}
.remote-camera #mainPanel .row .capture .vidData .delete {
    right: 10px;
    top: 2px;
}
select:focus {
    box-shadow: 0 0 3pt 2pt #FFEB3B;
}

.tag-isNice {
  z-index: 5;
  color: #fbc218;
  font-size: 1.2em;
}

.location{
  color: #FFA622;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  padding: 0 32px;
}

.user-series-gap{
  display: inline;
  color: #C94412;
  padding-right: 12px;
}

.user-series-gap.select{
  display: inline;
}

.user-series-gap input{
  width: 90px !important;
  height: auto !important;
  padding-right: 0px !important;
}

#series-apply-btn {
  padding: 5px 9px !important;
}

.series-gap-select{
  display: inline !important;
  width: 55%;
}

.top-item-wrapper{
  align-items: center;
}
</style>
