var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.project
      ? _c("div", { staticClass: "stickyWrap" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "filterDrop", attrs: { id: "filterDrop" } },
              [
                _c(
                  "div",
                  { staticClass: "container !mx-0 !w-full overflow !mb-5" },
                  [
                    _c("div", { staticClass: "filterWrap !p-0 !shadow-none" }, [
                      _c("form", { attrs: { id: "filterForm" } }, [
                        _c("div", { class: `flex gap-2` }, [
                          _vm.filterMode !== "series"
                            ? _c(
                                "div",
                                { staticClass: "input !pt-0 flex-grow" },
                                [
                                  _c("multiselect", {
                                    class: _vm.multiselectClasses(
                                      "triggerModes"
                                    ),
                                    attrs: {
                                      selectAllLabel: _vm.$t(
                                        "common-selectAll"
                                      ),
                                      options: _vm.staticOptions.triggerMode,
                                      multiple: true,
                                      "close-on-select": false,
                                      "clear-on-select": false,
                                      placeholder: _vm.$t(
                                        "taggingFilter-triggerMode"
                                      ),
                                      label: "type",
                                      "track-by": "type",
                                      selectLabel: "",
                                      deselectLabel: "",
                                      limit: 3,
                                      "limit-text": count => `+${count}`
                                    },
                                    on: {
                                      open: () => {
                                        _vm.ui.dropdownStates.triggerModes = true
                                      },
                                      close: () => {
                                        _vm.ui.dropdownStates.triggerModes = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.triggerModeId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "triggerModeId",
                                          $$v
                                        )
                                      },
                                      expression: "filter.triggerModeId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.speciesId == null && _vm.options.species
                            ? _c(
                                "div",
                                { staticClass: "input !pt-0 flex-grow" },
                                [
                                  _c("multiselect", {
                                    class: _vm.multiselectClasses("species"),
                                    attrs: {
                                      selectAllLabel: _vm.$t(
                                        "common-selectAll"
                                      ),
                                      options: _vm.options.species,
                                      multiple: true,
                                      "close-on-select": false,
                                      placeholder: _vm.$tc("common-species", 2),
                                      label: "commonName",
                                      "track-by": "id",
                                      selectLabel: "",
                                      deselectLabel: " "
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateSpeciesFilter()
                                      },
                                      open: () => {
                                        _vm.ui.dropdownStates.species = true
                                      },
                                      close: () => {
                                        _vm.ui.dropdownStates.species = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.speciesId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filter, "speciesId", $$v)
                                      },
                                      expression: "filter.speciesId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.useSpeciesSex
                            ? _c(
                                "div",
                                { staticClass: "input !pt-0 flex-grow" },
                                [
                                  _c("multiselect", {
                                    class: _vm.multiselectClasses("sex"),
                                    attrs: {
                                      selectAllLabel: _vm.$t(
                                        "common-selectAll"
                                      ),
                                      options: _vm.staticOptions.sex,
                                      multiple: true,
                                      "close-on-select": false,
                                      placeholder: _vm.$t("taggingFilter-sex"),
                                      label: "type",
                                      "track-by": "type",
                                      selectLabel: "",
                                      deselectLabel: " "
                                    },
                                    on: {
                                      open: () => {
                                        _vm.ui.dropdownStates.sex = true
                                      },
                                      close: () => {
                                        _vm.ui.dropdownStates.sex = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.sexId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filter, "sexId", $$v)
                                      },
                                      expression: "filter.sexId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.project.useSpeciesAge
                            ? _c(
                                "div",
                                { staticClass: "input !pt-0 flex-grow" },
                                [
                                  _c("multiselect", {
                                    class: _vm.multiselectClasses("age"),
                                    attrs: {
                                      selectAllLabel: _vm.$t(
                                        "common-selectAll"
                                      ),
                                      options: _vm.staticOptions.age,
                                      multiple: true,
                                      "close-on-select": false,
                                      "clear-on-select": false,
                                      placeholder: _vm.$t("taggingFilter-age"),
                                      label: "type",
                                      "track-by": "type",
                                      selectLabel: "",
                                      deselectLabel: ""
                                    },
                                    on: {
                                      open: () => {
                                        _vm.ui.dropdownStates.age = true
                                      },
                                      close: () => {
                                        _vm.ui.dropdownStates.age = false
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.ageId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filter, "ageId", $$v)
                                      },
                                      expression: "filter.ageId"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "!pt-0 flex flex-grow max-w-xl -mt-4 grid grid-cols-4"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "overflow-hidden",
                                  attrs: { for: "searchReview" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filter.needsReview,
                                        expression: "filter.needsReview"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "searchReview"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.filter.needsReview
                                      )
                                        ? _vm._i(_vm.filter.needsReview, null) >
                                          -1
                                        : _vm.filter.needsReview
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.filter.needsReview,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filter,
                                                "needsReview",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filter,
                                                "needsReview",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filter,
                                            "needsReview",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "!normal-case whitespace-nowrap"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common-needsReview"))
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "block overflow-hidden flex items-center",
                                  attrs: { for: "isVerified" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.tempVerified,
                                        expression: "tempVerified"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "isVerified"
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.tempVerified)
                                        ? _vm._i(_vm.tempVerified, null) > -1
                                        : _vm.tempVerified
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.tempVerified,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.tempVerified = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.tempVerified = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.tempVerified = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "whitespace-nowrap normal-case"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Verified")))]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.project.useNice
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "block overflow-hidden flex items-center",
                                      attrs: { for: "searchNice" }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filter.isNice,
                                            expression: "filter.isNice"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "searchNice"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filter.isNice
                                          )
                                            ? _vm._i(_vm.filter.isNice, null) >
                                              -1
                                            : _vm.filter.isNice
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.filter.isNice,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "isNice",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "isNice",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filter,
                                                "isNice",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "whitespace-nowrap normal-case"
                                        },
                                        [_vm._v(_vm._s(_vm.$t("common-nice")))]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "block overflow-hidden flex items-center",
                                  attrs: { for: "isUnverified" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.tempUnverified,
                                        expression: "tempUnverified"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "isUnverified"
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.tempUnverified)
                                        ? _vm._i(_vm.tempUnverified, null) > -1
                                        : _vm.tempUnverified
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.tempUnverified,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.tempUnverified = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.tempUnverified = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.tempUnverified = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "whitespace-nowrap normal-case"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Unverified")))]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "overflow-hidden",
                                  attrs: { for: "autoTagged" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filter.excludeTagged,
                                        expression: "filter.excludeTagged"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "autoTagged"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.filter.excludeTagged
                                      )
                                        ? _vm._i(
                                            _vm.filter.excludeTagged,
                                            null
                                          ) > -1
                                        : _vm.filter.excludeTagged
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.filter.excludeTagged,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.filter,
                                                "excludeTagged",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.filter,
                                                "excludeTagged",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.filter,
                                            "excludeTagged",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "!normal-case whitespace-nowrap"
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Exclude Tagged")))]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.project.autoTaggerConfig &&
                              _vm.project.autoTaggerConfig.filter(
                                c => c.autoTaggerId == 1
                              ).length == 1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "overflow-hidden",
                                      attrs: { for: "hideAutoNone" }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.filter.hideAuto[1].enabled,
                                            expression:
                                              "filter.hideAuto[1].enabled"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "hideAutoNone"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filter.hideAuto[1].enabled
                                          )
                                            ? _vm._i(
                                                _vm.filter.hideAuto[1].enabled,
                                                null
                                              ) > -1
                                            : _vm.filter.hideAuto[1].enabled
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.filter.hideAuto[1].enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[1],
                                                    "enabled",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[1],
                                                    "enabled",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filter.hideAuto[1],
                                                "enabled",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "!normal-case whitespace-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Hide Auto None"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.project.autoTaggerConfig &&
                              _vm.project.autoTaggerConfig.filter(
                                c => c.autoTaggerId == 3
                              ).length == 1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "overflow-hidden",
                                      attrs: { for: "hideAutoHuman" }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.filter.hideAuto[3].enabled,
                                            expression:
                                              "filter.hideAuto[3].enabled"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "hideAutoHuman"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filter.hideAuto[3].enabled
                                          )
                                            ? _vm._i(
                                                _vm.filter.hideAuto[3].enabled,
                                                null
                                              ) > -1
                                            : _vm.filter.hideAuto[3].enabled
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.filter.hideAuto[3].enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[3],
                                                    "enabled",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[3],
                                                    "enabled",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filter.hideAuto[3],
                                                "enabled",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "!normal-case whitespace-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Hide Auto Human"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.project.autoTaggerConfig &&
                              _vm.project.autoTaggerConfig.filter(
                                c => c.autoTaggerId == 2
                              ).length == 1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "overflow-hidden",
                                      attrs: { for: "hideAutoVehicle" }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.filter.hideAuto[2].enabled,
                                            expression:
                                              "filter.hideAuto[2].enabled"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "hideAutoVehicle"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filter.hideAuto[2].enabled
                                          )
                                            ? _vm._i(
                                                _vm.filter.hideAuto[2].enabled,
                                                null
                                              ) > -1
                                            : _vm.filter.hideAuto[2].enabled
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.filter.hideAuto[2].enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[2],
                                                    "enabled",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[2],
                                                    "enabled",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filter.hideAuto[2],
                                                "enabled",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "!normal-case whitespace-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Hide Auto Vehicle"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.project.autoTaggerConfig &&
                              _vm.project.autoTaggerConfig.filter(
                                c => c.autoTaggerId == 4
                              ).length == 1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "overflow-hidden",
                                      attrs: {
                                        for: "hideAutoSpeciesClassifier"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.filter.hideAuto[4].enabled,
                                            expression:
                                              "filter.hideAuto[4].enabled"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "hideAutoSpeciesClassifier"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filter.hideAuto[4].enabled
                                          )
                                            ? _vm._i(
                                                _vm.filter.hideAuto[4].enabled,
                                                null
                                              ) > -1
                                            : _vm.filter.hideAuto[4].enabled
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.filter.hideAuto[4].enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[4],
                                                    "enabled",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[4],
                                                    "enabled",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filter.hideAuto[4],
                                                "enabled",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "!normal-case whitespace-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Hide Auto MegaClassifier")
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.project.autoTaggerConfig &&
                              _vm.project.autoTaggerConfig.filter(
                                c => c.autoTaggerId == 5
                              ).length == 1
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "overflow-hidden",
                                      attrs: { for: "hideAutoStaff" }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.filter.hideAuto[5].enabled,
                                            expression:
                                              "filter.hideAuto[5].enabled"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "hideAutoStaff"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filter.hideAuto[5].enabled
                                          )
                                            ? _vm._i(
                                                _vm.filter.hideAuto[5].enabled,
                                                null
                                              ) > -1
                                            : _vm.filter.hideAuto[5].enabled
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.filter.hideAuto[5].enabled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[5],
                                                    "enabled",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filter.hideAuto[5],
                                                    "enabled",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filter.hideAuto[5],
                                                "enabled",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "!normal-case whitespace-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Hide Auto Staff"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ui.showMoreFilters,
                                expression: "ui.showMoreFilters"
                              }
                            ],
                            class: `flex gap-2 items-center mt-1`
                          },
                          [
                            _vm.project.useIndividualCounts
                              ? _c(
                                  "div",
                                  { staticClass: "!pt-0 overflow-hidden" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter.individualCount,
                                          expression: "filter.individualCount"
                                        }
                                      ],
                                      staticClass:
                                        "individualCount !mt-0 !h-[40px] !text-[14px] !mb-0",
                                      attrs: {
                                        type: "text",
                                        step: "any",
                                        placeholder: _vm.$t("Count"),
                                        title: _vm.$t(
                                          "taggingFilter-numberOfIndividuals"
                                        )
                                      },
                                      domProps: {
                                        value: _vm.filter.individualCount
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.filter,
                                            "individualCount",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.tagOptions, function(attributes, name) {
                              return [
                                _vm.project[attributes.enabledKey]
                                  ? _c(
                                      "div",
                                      {
                                        key: name,
                                        staticClass:
                                          "flex-initial flex-grow !pt-0"
                                      },
                                      [
                                        _c("multiselect", {
                                          class: _vm.multiselectClasses(name),
                                          attrs: {
                                            selectAllLabel: _vm.$t(
                                              "common-selectAll"
                                            ),
                                            options: _vm.localOptions[name],
                                            multiple: attributes.multiple,
                                            placeholder: attributes.label
                                              ? attributes.label
                                              : _vm.$t(`taggingFilter-${name}`),
                                            label: attributes.labelKey,
                                            customLabel: attributes.customLabel,
                                            "close-on-select":
                                              attributes.closeOnSelect,
                                            "track-by": attributes.trackBy,
                                            limit: 3,
                                            "limit-text": count => `+${count}`
                                          },
                                          on: {
                                            open: () => {
                                              _vm.ui.dropdownStates[name] = true
                                            },
                                            close: () => {
                                              _vm.ui.dropdownStates[
                                                name
                                              ] = false
                                            }
                                          },
                                          model: {
                                            value: _vm.filter[attributes.key],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                attributes.key,
                                                $$v
                                              )
                                            },
                                            expression: "filter[attributes.key]"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ui.showMoreFilters,
                                expression: "ui.showMoreFilters"
                              }
                            ],
                            staticClass: "flex gap-2 items-center mt-4"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "!pt-0 !-mt-3 flex-initial flex-grow"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "grid grid-cols-3 col-span-2"
                                  },
                                  [
                                    _vm.project.useSpeciesHasCollar
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "overflow-hidden",
                                            attrs: { for: "collar" }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.filter.hasCollar,
                                                  expression: "filter.hasCollar"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "collar"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filter.hasCollar
                                                )
                                                  ? _vm._i(
                                                      _vm.filter.hasCollar,
                                                      null
                                                    ) > -1
                                                  : _vm.filter.hasCollar
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.filter.hasCollar,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasCollar",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasCollar",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "hasCollar",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "!normal-case whitespace-nowrap"
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Collar")))]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.project.useSpeciesHasTag
                                      ? _c(
                                          "label",
                                          {
                                            staticClass: "overflow-hidden",
                                            attrs: { for: "tag" }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.filter.hasTag,
                                                  expression: "filter.hasTag"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "tag"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filter.hasTag
                                                )
                                                  ? _vm._i(
                                                      _vm.filter.hasTag,
                                                      null
                                                    ) > -1
                                                  : _vm.filter.hasTag
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.filter.hasTag,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasTag",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasTag",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "hasTag",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "!normal-case whitespace-nowrap"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Ear Tag"))
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.project.useSpeciesTineTracking
                                      ? _c(
                                          "label",
                                          {
                                            staticClass:
                                              "block overflow-hidden flex items-center",
                                            attrs: { for: "searchHasTines" }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.filter.hasTines,
                                                  expression: "filter.hasTines"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "searchHasTines"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filter.hasTines
                                                )
                                                  ? _vm._i(
                                                      _vm.filter.hasTines,
                                                      null
                                                    ) > -1
                                                  : _vm.filter.hasTines
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.filter.hasTines,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasTines",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasTines",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "hasTines",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "whitespace-nowrap normal-case"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Has Tines"))
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.project.useFire
                                      ? _c(
                                          "label",
                                          {
                                            staticClass:
                                              "block overflow-hidden flex items-center",
                                            attrs: { for: "searchFire" }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.filter.isFire,
                                                  expression: "filter.isFire"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "searchFire"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filter.isFire
                                                )
                                                  ? _vm._i(
                                                      _vm.filter.isFire,
                                                      null
                                                    ) > -1
                                                  : _vm.filter.isFire
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a = _vm.filter.isFire,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "isFire",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "isFire",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "isFire",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "whitespace-nowrap normal-case"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("cameraTagging-fire")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.project
                                      .useSpeciesInteractingWithHumanFeature
                                      ? _c(
                                          "label",
                                          {
                                            staticClass:
                                              "block overflow-hidden flex items-center",
                                            attrs: {
                                              for: "interactingWithHumanFeature"
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filter
                                                      .interactingWithHumanFeature,
                                                  expression:
                                                    "filter.interactingWithHumanFeature"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "interactingWithHumanFeature"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filter
                                                    .interactingWithHumanFeature
                                                )
                                                  ? _vm._i(
                                                      _vm.filter
                                                        .interactingWithHumanFeature,
                                                      null
                                                    ) > -1
                                                  : _vm.filter
                                                      .interactingWithHumanFeature
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.filter
                                                        .interactingWithHumanFeature,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "interactingWithHumanFeature",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "interactingWithHumanFeature",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "interactingWithHumanFeature",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "whitespace-nowrap normal-case"
                                              },
                                              [_vm._v(_vm._s(_vm.$t("IHF")))]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.project.useSnowDepth
                                      ? _c(
                                          "label",
                                          {
                                            staticClass:
                                              "block overflow-hidden flex items-center",
                                            attrs: { for: "hasSnow" }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filter.hasSnowOnGround,
                                                  expression:
                                                    "filter.hasSnowOnGround"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id: "hasSnow"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filter.hasSnowOnGround
                                                )
                                                  ? _vm._i(
                                                      _vm.filter
                                                        .hasSnowOnGround,
                                                      null
                                                    ) > -1
                                                  : _vm.filter.hasSnowOnGround
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.filter
                                                        .hasSnowOnGround,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasSnowOnGround",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.filter,
                                                          "hasSnowOnGround",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "hasSnowOnGround",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "whitespace-nowrap normal-case"
                                              },
                                              [_vm._v(_vm._s(_vm.$t("Snow")))]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.project.useFieldOfView
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "input !pt-0 flex-initial flex-grow max-w-sm"
                                  },
                                  [
                                    _c("multiselect", {
                                      class: _vm.multiselectClasses("fov"),
                                      attrs: {
                                        selectAllLabel: _vm.$t(
                                          "common-selectAll"
                                        ),
                                        options: _vm.staticOptions.fieldOfView,
                                        multiple: true,
                                        "close-on-select": false,
                                        "clear-on-select": false,
                                        placeholder: _vm.$t(
                                          "cameraTagging-fieldOfView"
                                        ),
                                        label: "type",
                                        "track-by": "type",
                                        selectLabel: "",
                                        deselectLabel: ""
                                      },
                                      on: {
                                        open: () => {
                                          _vm.ui.dropdownStates.fov = true
                                        },
                                        close: () => {
                                          _vm.ui.dropdownStates.fov = false
                                        }
                                      },
                                      model: {
                                        value: _vm.filter.fovId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filter, "fovId", $$v)
                                        },
                                        expression: "filter.fovId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input !pt-0 flex-initial flex-grow"
                              },
                              [
                                _c("VueCtkDateTimePicker", {
                                  class: { dtpicker: true },
                                  attrs: {
                                    locale: _vm.$root.$i18n.locale,
                                    id: "startTime2",
                                    overlay: true,
                                    format: "YYYY-MM-DD HH:mm",
                                    formatted: "llll",
                                    hint: _vm.$t(
                                      "taggingFilter-selectStartTime"
                                    ),
                                    label: _vm.$t(
                                      "taggingFilter-selectStartTime"
                                    ),
                                    minDate:
                                      _vm.filterMode === "tag" &&
                                      _vm.deploymentInfo.imageSetSummary &&
                                      _vm.deploymentInfo.imageSetSummary
                                        .startDate
                                        ? _vm.deploymentInfo.imageSetSummary.startDate.substring(
                                            0,
                                            10
                                          )
                                        : null,
                                    maxDate:
                                      _vm.filterMode === "tag" &&
                                      _vm.deploymentInfo.imageSetSummary &&
                                      _vm.deploymentInfo.imageSetSummary.endDate
                                        ? _vm.deploymentInfo.imageSetSummary.endDate.substring(
                                            0,
                                            10
                                          )
                                        : null
                                  },
                                  model: {
                                    value: _vm.startTimeStr,
                                    callback: function($$v) {
                                      _vm.startTimeStr = $$v
                                    },
                                    expression: "startTimeStr"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input !pt-0 flex-initial flex-grow"
                              },
                              [
                                _c("VueCtkDateTimePicker", {
                                  class: { dtpicker: true },
                                  attrs: {
                                    locale: _vm.$root.$i18n.locale,
                                    id: "endTime",
                                    format: "YYYY-MM-DD HH:mm",
                                    hint: _vm.$t("taggingFilter-selectEndTime"),
                                    label: _vm.$t(
                                      "taggingFilter-selectEndTime"
                                    ),
                                    minDate:
                                      _vm.filterMode === "tag" &&
                                      _vm.deploymentInfo.imageSetSummary &&
                                      _vm.deploymentInfo.imageSetSummary
                                        .startDate
                                        ? _vm.deploymentInfo.imageSetSummary.startDate.substring(
                                            0,
                                            10
                                          )
                                        : null,
                                    maxDate:
                                      _vm.filterMode === "tag" &&
                                      _vm.deploymentInfo.imageSetSummary &&
                                      _vm.deploymentInfo.imageSetSummary.endDate
                                        ? _vm.deploymentInfo.imageSetSummary.endDate.substring(
                                            0,
                                            10
                                          )
                                        : null
                                  },
                                  model: {
                                    value: _vm.endTimeStr,
                                    callback: function($$v) {
                                      _vm.endTimeStr = $$v
                                    },
                                    expression: "endTimeStr"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "overflow-hidden flex-initial flex-grow"
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "whitespace-nowrap" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "Bounding box display threashold"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c("span", [_vm._v("0")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.filter.megaDetectorThreshold,
                                          expression:
                                            "filter.megaDetectorThreshold"
                                        }
                                      ],
                                      staticClass: "flex-grow !m-0",
                                      attrs: {
                                        type: "range",
                                        min: "0",
                                        max: "1",
                                        step: "0.01"
                                      },
                                      domProps: {
                                        value: _vm.filter.megaDetectorThreshold
                                      },
                                      on: {
                                        __r: function($event) {
                                          return _vm.$set(
                                            _vm.filter,
                                            "megaDetectorThreshold",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("1")])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-full text-center -mt-[5px]"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.filter.megaDetectorThreshold)
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.megaDetectorCategories
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-initial flex-grow max-w-sm"
                                  },
                                  [
                                    _c("multiselect", {
                                      class: _vm.multiselectClasses(
                                        "aiCategories"
                                      ),
                                      attrs: {
                                        selectAllLabel: _vm.$t(
                                          "common-selectAll"
                                        ),
                                        options: _vm.megaDetectorCategories,
                                        multiple: true,
                                        "close-on-select": false,
                                        "clear-on-select": false,
                                        placeholder: _vm.$t("AI Categories"),
                                        label: "type",
                                        "track-by": "id",
                                        selectLabel: "",
                                        deselectLabel: ""
                                      },
                                      on: {
                                        open: () => {
                                          _vm.ui.dropdownStates.aiCategories = true
                                        },
                                        close: () => {
                                          _vm.ui.dropdownStates.aiCategories = false
                                        }
                                      },
                                      model: {
                                        value: _vm.filter.aiCategories,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "aiCategories",
                                            $$v
                                          )
                                        },
                                        expression: "filter.aiCategories"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-4 gap-2 w-1/2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn w-full !block !float-none !mb-0 !h-[41.6px]",
                                attrs: { type: "button", id: "applyFilters" },
                                on: {
                                  click: function($event) {
                                    return _vm.filterImages()
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "ion-android-funnel"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common-update")) +
                                    "\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "btn !mb-0 !text-base !text-white !px-3 !py-2 !h-[41.6px]",
                                on: {
                                  click: function($event) {
                                    return _vm.clearFilter()
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa-solid fa-delete-left !text-sm text-white"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common-clear")) +
                                    "\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "btn !mb-0 !text-base !text-white !px-3 !py-2 !h-[41.6px]",
                                on: {
                                  click: function($event) {
                                    return _vm.resetFilter()
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa-solid fa-arrows-rotate !text-sm text-white"
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common-reset")) +
                                    "\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "btn !mb-0 !px-3 !py-2 cursor-pointer !text-base !h-[41.6px]",
                                on: {
                                  click: () => {
                                    _vm.ui.showMoreFilters = !_vm.ui
                                      .showMoreFilters
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  class: {
                                    "fa-solid text-sm": true,
                                    "fa-caret-down": !_vm.ui.showMoreFilters,
                                    "fa-caret-up": _vm.ui.showMoreFilters
                                  }
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("More Filters")) +
                                    "\n                  "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }