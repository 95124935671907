import i18n from '@/i18n';
import Buffer from './Buffer';

/**
 * info from https://exiftool.org/TagNames/Reconyx.html
 * 
 */

export function decodeMakerNote(data, model) {

    // List of vendor specific Makernote tags found on
    // tagKey is the position/offset of the information
    var type1_index = 2;
    var tags_type1 = {
      0x00 : {
        name: 'MakerNoteVersion'
      },
      0x01 : { name: 'FirmwareVersion', size: 3 },
      0x04 : { name: 'FirmwareDate', size: 2 },
      0x06 : { name: 'TriggerMode', type: 'string', size: 2, options: {
        'C': i18n.t('reconyxExif-triggerMode.codeLocNotEntered'),
        'E': i18n.t('reconyxExif-triggerMode.externalSensor'),
        'M': i18n.t('reconyxExif-triggerMode.motionDetection'),
        'T': i18n.t('reconyxExif-triggerMode.timeLapse')
      } } ,
      0x07 : { name: 'Sequence', size: 2},
      0x09 : { name: 'EventNumber', size: 2},
      0x0b : { name: 'DateTimeOriginal', size: 6},
      0x12 : { name: 'MoonPhase', options: [
        i18n.t('reconyxExif-moonPhase.new'),
        i18n.t('reconyxExif-moonPhase.newCrescent'),
        i18n.t('reconyxExif-moonPhase.firstQuarter'),
        i18n.t('reconyxExif-moonPhase.waxingGibbous'),
        i18n.t('reconyxExif-moonPhase.full'),
        i18n.t('reconyxExif-moonPhase.waningGibbous'),
        i18n.t('reconyxExif-moonPhase.lastQuarter'),
        i18n.t('reconyxExif-moonPhase.oldCrescent')
       ] },
      0x13 : { name: 'AmbientTemperatureFahrenheit' , type: 'sshort' },
      0x14 : { name: 'AmbientTemperature',  type: 'sshort'},
      0x15 : { name: 'SerialNumber', type:'undefined', size: 30, decode: 'UCS2'},
      0x24 : { name: 'Contrast'},
      0x25 : { name: 'Brightness'},
      0x26 : { name: 'Sharpness'},
      0x27 : { name: 'Saturation'},
      0x28 : { name: 'InfraredIlluminator', options: [i18n.t('common-off'), i18n.t('common-on')]},
      0x29 : { name: 'MotionSensitivity'},
      0x2a : { name: 'BatteryVoltage'},
      0x2b : { name: 'UserLabel', type: 'string', size: 22  }
    };

      const type2_index = 1;
      var tags_type2 = {  // not tested

        0x18 : { name: 'FirmwareVersion', type:'undefined', size: 7 },
        0x1f : { name: 'Micro1Version', type:'undefined', size: 7 },
        0x26 : { name: 'BootLoaderVersion', type:'undefined', size: 7 },
        0x2d : { name: 'Micro2Version', type:'undefined', size: 7 },
        0x34 : { name: 'TriggerMode',  type: 'undefined', size: 1, options: {
          'P': i18n.t('reconyxExif-triggerMode.pointAndShoot'),
          'M': i18n.t('reconyxExif-triggerMode.motionDetection'),
          'T': i18n.t('reconyxExif-triggerMode.timeLapse')
        } } ,
        0x35 : { name: 'Sequence', type: 'int8u', size: 2},
        0x37 : { name: 'EventNumber', type: 'int32u'},
        0x3b : { name: 'DateTimeOriginal', type: 'int8u', size: 7},
        0x42 : { name: 'DayOfWeek', type: 'int8u',  options: [
                i18n.t('reconyxExif-dayOfWeek.sunday'),
                i18n.t('reconyxExif-dayOfWeek.monday'),
                i18n.t('reconyxExif-dayOfWeek.tuesday'),
                i18n.t('reconyxExif-dayOfWeek.wednesday'),
                i18n.t('reconyxExif-dayOfWeek.thursday'),
                i18n.t('reconyxExif-dayOfWeek.friday'),
                i18n.t('reconyxExif-dayOfWeek.saturday')]	  },
        0x43 : { name: 'MoonPhase', type: 'int8u', options: [
            i18n.t('reconyxExif-moonPhase.new'),
            i18n.t('reconyxExif-moonPhase.newCrescent'),
            i18n.t('reconyxExif-moonPhase.firstQuarter'),
            i18n.t('reconyxExif-moonPhase.waxingGibbous'),
            i18n.t('reconyxExif-moonPhase.full'),
            i18n.t('reconyxExif-moonPhase.waningGibbous'),
            i18n.t('reconyxExif-moonPhase.lastQuarter'),
            i18n.t('reconyxExif-moonPhase.oldCrescent')
        ] },
        0x44 : { name: 'AmbientTemperatureFahrenheit' , type: 'sshort' },
        0x46 : { name: 'AmbientTemperature',  type: 'sshort'},
        0x48 : { name: 'Illumination', type: 'int8u', options: [i18n.t('common-off'), i18n.t('common-on')]},
        0x49 : { name: 'BatteryVoltage'},
        0x4b : { name: 'SerialNumber', type:'string', size: 15},
        0x5a : { name: 'UserLabel', type: 'string', size: 21  }
    };

    const type3_index = 1;
    var tags_type3 = {

      0x10 : { name: 'FileNumber'         },
      0x12 : { name: 'DirectoryNumber'        },
      0x2a : { name: 'FirmwareVersion', size: 3 },
      0x30 : { name: 'FirmwareDate', size: 2 },
      0x34 : { name: 'TriggerMode',  type: 'string', size: 2, options: {
        'P': i18n.t('reconyxExif-triggerMode.pointAndShoot'),
        'M': i18n.t('reconyxExif-triggerMode.motionDetection'),
        'T': i18n.t('reconyxExif-triggerMode.timeLapse')
        } } ,
      0x36 : { name: 'Sequence', type: 'int8u', size: 2},
      0x3a : { name: 'EventNumber', size: 2},
      0x3e : { name: 'DateTimeOriginal', size: 6, format: [5,4,3,1,2,0]},
      0x4a : { name: 'DayOfWeek',  options: [
              i18n.t('reconyxExif-dayOfWeek.sunday'),
              i18n.t('reconyxExif-dayOfWeek.monday'),
              i18n.t('reconyxExif-dayOfWeek.tuesday'),
              i18n.t('reconyxExif-dayOfWeek.wednesday'),
              i18n.t('reconyxExif-dayOfWeek.thursday'),
              i18n.t('reconyxExif-dayOfWeek.friday'),
              i18n.t('reconyxExif-dayOfWeek.saturday')]	  },
      0x4c : { name:   'MoonPhase',  options: [
        i18n.t('reconyxExif-moonPhase.new'),
        i18n.t('reconyxExif-moonPhase.newCrescent'),
        i18n.t('reconyxExif-moonPhase.firstQuarter'),
        i18n.t('reconyxExif-moonPhase.waxingGibbous'),
        i18n.t('reconyxExif-moonPhase.full'),
        i18n.t('reconyxExif-moonPhase.waningGibbous'),
        i18n.t('reconyxExif-moonPhase.lastQuarter'),
        i18n.t('reconyxExif-moonPhase.oldCrescent')
        ] },
      0x4e : { name: 'AmbientTemperatureFahrenheit' , type: 'sshort' },
      0x50 : { name: 'AmbientTemperature',  type: 'sshort'},
      0x52 : { name: 'Contrast'},
      0x54 : { name: 'Brightness'},
      0x56 : { name: 'Sharpness'},
      0x58 : { name: 'Saturation'},
      0x5a : { name: 'Flash', options: [i18n.t('common-off'), i18n.t('common-on')]},
      0x5c : { name: 'AmbientInfrared'},
      0x5e : { name: 'AmbientLight'},
      0x60 : { name: 'MotionSensitivity'},
      0x62 : { name: 'BatteryVoltage'},
      0x64 : { name: 'BatteryVoltageAvg'},
      0x66 : { name: 'BatteryType'},
      0x68 : { name: 'UserLabel', type: 'string', size: 22  }  ,
      0x7e : { name: 'SerialNumber',       type: 'unicode', size:15}
    };

    var offset = 0;
    var tagInfo  = [];
    var tags, index_size;
    // console.log('makernote', data);
    if (!model || model == 'unknown' || model.indexOf('PC')!= -1 || model.indexOf('HC')!= -1) {
      tags = tags_type1;
      index_size = type1_index;
    } else if (model.indexOf('HF2 PRO')!= -1 || model.indexOf('HYPERFIRE 2 COVERT') != -1) {
      tags = tags_type3;
      index_size = type3_index;
    } else if (model.indexOf('UltraFire')!= -1) {
      tags = tags_type2;
      index_size = type2_index;
    } 
    
    for (let key in tags) {

      var tagInput = tags[key];

      offset = key*index_size; // offset x 2 for bytes

      var size = tagInput.size?tagInput.size: 1;
      var byteEach = 1;

      var tagItem = {name: tagInput.name, value: null};
      if (tagInput.type == 'string') {

          tagItem.value = data.getString(offset, size);
          if (tagItem.value.indexOf('\u0000') != -1) {
            tagItem.value = tagItem.value.replace(/\0/g, '');
          }

      } else if (tagInput.type == 'unicode') {

        tagItem.value = data.getUnicode(offset, size);

    } else if (tagInput.type == 'undefined') {
        tagItem.value = data.slice(offset, offset + size).toString('UCS2');

      } else if (tagInput.type == 'sshort') { // signed short
        tagItem.value = [];
        for (let i = 0; i < size; i++) {
          tagItem.value.push(data.getSignedShort(offset + 2*i, false));
        }
        tagItem.value = tagItem.value.join(' ');
        byteEach = 2;

      } else if (tagInput.type == 'int8u') {  //  int8u
        tagItem.value = [];
        for (let i = 0; i < size; i++) {
          tagItem.value.push(data.getByte(offset + 2*i, false)); // not tested
        }
        tagItem.value = tagItem.value.join(' ');
        byteEach = 2;
    }
      else {  // default int16u
          tagItem.value = [];
          for (let i = 0; i < size; i++) {
            tagItem.value.push(data.getShort(offset + 2*i, false));
          }
          tagItem.value = tagItem.value.join(' ');
          byteEach = 2;
      }
      if (tagInput.options) {
        tagItem.value = tagInput.options[tagItem.value];
      }
      // tagItem.buffer = JSON.stringify(data.slice(offset, offset + size*byteEach));
      offset += size*byteEach;
      try {
        if (tagItem.value.indexOf('\u0000') != -1) {
            const newVal = tagItem.value.replace(/\0/g, '');
            tagItem.value = newVal;
        }
      } catch(e) {
      }
      tagInfo.push(tagItem);
      // console.log ('makenote data', key, 'tag ', tagItem, ' value ', tagItem.value);
    }
    // console.log(typeof data);
    return tagInfo;

  };

