import {API_URL} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
// shared by species verification and tagging page
export default {
  data () {
    return {
      getStaticOptionUrl: API_URL + 'aru-task-options', // static options such as sex age
      getSpeciesDetailUrl: API_URL + 'get-species-details?speciesId=', // static options such as sex age
      isFullyTaggedUrl: API_URL + 'camera-is-pud-fully-tagged?pudId=',
      isFullyTagged: null,
      staticOptions: null,
      speciesObj: null,
      warning: null
    }
  },
  created () {
    const self = this;
    eventBus.$on('toggle-modal-form', function (name, show) {
      self.toggleDialog(name, show);
    });
    /* when user selection of images chagned, update number in button */
    // eventBus.$on('selection-change', function (selection, name) {
    //   self.selectedImageCount = selection.length;
    // });
    // eventBus.$on('manual-selection-change', function (selection, name) {
    //   self.selectedImageCount = selection.length;
    // });
    /* show or hide form by setting ui parameter */
    eventBus.$on('toggle-form', function (name, show) {
      self.ui[name] = show;
    });
  },
  methods: {
    toggleDialog (dialogName, toShow) {
      console.log(dialogName);
      if (toShow) {
        this.$modal.show(dialogName);
      } else {
        this.$modal.hide(dialogName);
      }
    },

    tagSelected () {
      this.$refs.tag.batchEditImageTags();
    },
    loadSpeciesObj () {
      this.$http.get(this.getSpeciesDetailUrl + this.speciesCode).then(resp => {
        this.speciesObj = resp.data;
      }, err => {
        console.log(err);
      });
    },
    loadStaticOption (bRefersh) {
      if (bRefersh && !this.userDeploymentId) { // don't do refresh when there is no user deployment id
        return;
      }
     
      this.$http.get(this.getStaticOptionUrl + '?projectId=' + this.projectId).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.staticOptions = response.data;
            // combine species: bird, domestic, animal and others into one big array
          }
        });
    }
  }
}
