<template>
          <div class="row filter-wrapper">
        <div class="col s6 m3 input" v-if="speciesId ==null && options.species">
          <multiselect :selectAllLabel="$t('common-selectAll')" v-model="filter.speciesId" :options="options.species" :multiple="true" :close-on-select="false"
            @input="updateSpeciesFilter()" :placeholder="$tc('common-species', 2)" label="commonName" track-by="id" selectLabel="" deselectLabel=" " class="input">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="initSpeciesSelected">{{initSpeciesName}}</span>
                  <template v-else>
                    <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{$tc('common-numSpecies', values.length, {num: values.length})}}</span>
                    <span class="multiselect__single" v-if="values.length == 1 && !isOpen">{{values[0].commonName }}</span>
                  </template>
              </template>
            </multiselect>
        </div>
        <span class="ion-android-refresh left clear-filter-btn">
            <span class="filter-link" @click="clearFilter()"> {{$t('common-clear')}}</span>
        </span>
        <div class="input">
            <button type="button" id="applyFilters" class="btn" @click="filterImages()">
                <span class="ion-android-funnel"></span> {{$t('common-update')}}
            </button>
        </div>
      </div>
</template>

<script>
/* this component is the filter for camera photos in landowner reports
this filter is only for species
*/
import { eventBus } from '@/lib/eventbus';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
// register globally
// Vue.component('multiselect', Multiselect)

export default {
  name: 'report-filter',
  props: ['options', 'speciesId', 'initSpeciesId'],
  components: {'multiselect': Multiselect},
  methods: {
    /* filter functions */
    filterImages () {
      /* convert selections object into arraies of ids */
      this.queryParam = {};

      this.queryParam = Object.assign(this.queryParam, this.filter);
      /* when it is at species view */
      if (this.speciesId) {
        this.queryParam.speciesId = [];
        this.queryParam.speciesId.push(this.speciesId);
      } else {
        this.queryParam.speciesId = this.filter.speciesId ? this.filter.speciesId.map(opt => opt.id) : null;
      }
      this.queryParam.sexId = this.filter.sexId ? this.filter.sexId.map(opt => opt.id) : null;

      this.queryParam.ageId = this.filter.ageId ? this.filter.ageId.map(opt => opt.id) : null;
      this.queryParam.triggerModeId = this.filter.triggerModeId ? this.filter.triggerModeId.map(opt => opt.id) : null;
      this.queryParam.fovId = this.filter.fovId ? this.filter.fovId.map(opt => opt.id) : null;
      /* filter needs verified on UI, but filter isVerified on backend, use opposite value */
      if (this.filter.tmpNotIsVerified) {
        this.queryParam.isVerified = !this.filter.tmpNotIsVerified;
      }

      /*  if (this.deploymentInfo && this.deploymentInfo.imageSetSummary) {
        // don't put start in query considtion when it is same a the time range.
        if (this.deploymentInfo.imageSetSummary.startDate && this.deploymentInfo.imageSetSummary.startDate.substring(0, this.deploymentInfo.imageSetSummary.startDate.length - 3) !== this.startTimeStr) {
          this.queryParam.startTimeStr = this.startTimeStr;
        }
        if (this.deploymentInfo.imageSetSummary.endDate && this.deploymentInfo.imageSetSummary.endDate.substring(0, this.deploymentInfo.imageSetSummary.endDate.length - 3) !== this.endTimeStr) {
          this.queryParam.endTimeStr = this.endTimeStr;
        }
      } else { */
      this.queryParam.startTimeStr = this.startTimeStr;
      this.queryParam.endTimeStr = this.endTimeStr;
      //  }

      /*  if (this.useSeriesView) {
        this.queryParam.useSeriesView = true;
        this.queryParam.limit = 999;
      } else { */
      this.queryParam.limit = 80;
      //  }
      // const self = this;

      // this.queryParam.individualCount = this.filter.individual;
      // this.queryParam.isNice = this.filter.isFeatured;
      // this.queryParam.isFire = this.filter.isFire;
      // this.queryParam.needReview = this.filter.needReview;
      // this.queryParam.startTimeStr = this.filter.startTime;
      // this.queryParam.endTimeStr = this.filter.endTime;

      eventBus.$emit('apply-filter', this.queryParam);
    //  this.closeForm();
    },
    clearFilter () {
      this.filter = {triggerModeId: [], speciesId: []};
      // if (this.speciesId) {
      //  try {
      //     this.filter.speciesId.push(this.staticOptions.allSpecies.find(x => x.id == this.speciesId));
      //   } catch (e) { console.log(e)}
      // }
      /* if (this.deploymentInfo.imageSetSummary && this.deploymentInfo.imageSetSummary.startDate) {
        this.startTimeStr = this.deploymentInfo.imageSetSummary.startDate.substring(0, this.deploymentInfo.imageSetSummary.startDate.length - 3); // remove second info
      }
      if (this.deploymentInfo.imageSetSummary && this.deploymentInfo.imageSetSummary.endDate) {
        this.endTimeStr = this.deploymentInfo.imageSetSummary.endDate.substring(0, this.deploymentInfo.imageSetSummary.endDate.length - 3); // remove second info this.deploymentInfo.end_date;
      } */
      //    console.log('species obj', this.speciesId, ' matched ' this.staticOptions.allSpecies.find(x => x.id == this.speciesId));
    },
    resetFilter () {
      this.clearFilter();
      /* no filter for FOV mode */
      if (this.speciesId || this.filterMode === 'fov') { /* fov show all images */
        this.filter.excludeAutoTag = false;
      } else if (this.filterMode === 'tag' || this.filterMode === 'series') { /* hide auto tagger and show motion only */
        this.filter.excludeAutoTag = true;
        this.filter.triggerModeId = this.getDefaultTriggerMode();
      }
    },
    updateSpeciesFilter () {
      this.initSpeciesSelected = false;
      let params = new URLSearchParams(window.location.search);
      // remove all params except the id
      window.history.pushState({}, document.title, window.location.href.split('?')[0] + '?UUID=' + params.get('UUID') + '&pudId=' + params.get('pudId'));
    }
  },
  mounted () { // set default filter
    this.resetFilter();
    /* for initial query parameters */
    // this.initSpeciesId ? this.filter.speciesId = [{id: this.initSpeciesId}] : null;
    if (this.initSpeciesId) {
      this.filter.speciesId = [{id: this.initSpeciesId}];
    }
    if (this.initSpeciesId) {
      // set the name in the filter
      this.initSpeciesName = this.getInitSpeciesName();
      this.initSpeciesSelected = true;
    }
    this.filterImages();
  },
  data () {
    return {
      filter: {triggerModeId: [], speciesId: []},
      queryParam: {},
      /* need this two parameters to track start end time from calendar component
        if use filter.startTime the components won't update the display. although values were
        set but on page, still shows old time.
      */
      startTimeStr: null,
      endTimeStr: null,
      initSpeciesName: null,
      initSpeciesSelected: false
    }
  }
}
</script>

<style scoped>
.remote-camera .filterDrop, .remote-camera .searchDrop {
  display: block;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.dtpicker input{
  font-size: 0.9em!important;
}
.dtpicker label.field-label  {
  color: #C94412!important;
  font-size: 0.9em!important;
  top: 0px!important;
}
input.individualCount {
    width: 150px!important;
    height: 45px!important;
    margin-top: 10px!important;
}

.input {
  height: 60px;
}

.clear-filter-btn{
    padding-right:10px;
    cursor: pointer;
}

.filter-link{
  color:#C94412;
}

</style>
