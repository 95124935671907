var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue container overflow" }, [
    _vm.error != null
      ? _c("div", { staticClass: "container error padded60" }, [
          _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.error == null
      ? _c("div", [
          _c("div", { staticClass: "large boxed padded20" }, [
            _c("div", { staticClass: "row" }, [
              _vm.reportLocation
                ? _c(
                    "div",
                    { staticClass: "col s12 m12 l8" },
                    [
                      _c("h1", { staticStyle: { display: "inline" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("landOwnerHome-title", {
                              name:
                                _vm.reportLocation && _vm.reportLocation.name
                            })
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("common-latitude")) +
                              ": " +
                              _vm._s(_vm.reportLocation.latitude) +
                              " " +
                              _vm._s(_vm.$t("common-longitude")) +
                              ": " +
                              _vm._s(_vm.reportLocation.longitude)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          !_vm.reportLocation.isTrueCoordinates
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("landOwnerHome-hiddenLocation", {
                                      num: _vm.reportLocation.bufferRadius
                                    })
                                  )
                                ),
                                _c("br")
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.reportLocation
                        ? _c("single-visit-table", {
                            attrs: {
                              "is-editable": false,
                              "organization-id": null,
                              uuid: _vm.userUUID,
                              "location-label": null,
                              "creating-visit": false,
                              "location-id": _vm.reportLocation.id
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("section", [
            _c(
              "div",
              { staticClass: "tabbed" },
              [
                _c("ul", { staticClass: "tabs" }, [
                  _vm.reportPuds && _vm.reportPuds.length > 0
                    ? _c("li", { staticClass: "tab" }, [
                        _c(
                          "a",
                          {
                            staticClass: "hyperlink CAM",
                            class: { active: _vm.activeTab === "CAM" },
                            on: {
                              click: function($event) {
                                return _vm.clickTab("CAM")
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "ion-ios-camera" }),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("common-cam")) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reportAruTasks && _vm.reportAruTasks.length > 0
                    ? _c("li", { staticClass: "tab" }, [
                        _c(
                          "a",
                          {
                            staticClass: "hyperlink ARU",
                            class: { active: _vm.activeTab === "ARU" },
                            on: {
                              click: function($event) {
                                return _vm.clickTab("ARU")
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "ion-ios-mic" }),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("common-aru")) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reportLocation &&
                  _vm.reportLocation.latitude &&
                  _vm.reportLocation.longitude
                    ? _c(
                        "li",
                        {
                          staticClass: "tab",
                          on: {
                            click: function($event) {
                              return _vm.clickTab("map")
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { class: { active: _vm.activeTab == "map" } },
                            [
                              _c("span", { staticClass: "ion-map" }),
                              _vm._v(" " + _vm._s(_vm.$t("common-map")))
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasVisitPhotos
                    ? _c(
                        "li",
                        {
                          staticClass: "tab",
                          on: {
                            click: function($event) {
                              return _vm.clickTab("location-photos")
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              class: {
                                active: _vm.activeTab == "location-photos"
                              }
                            },
                            [
                              _c("span", { staticClass: "ion-ios-camera" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("common-locationPhotos"))
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.activeTab != null &&
                _vm.activeTab != "map" &&
                _vm.activeTab != "location-photos" &&
                _vm.reportLocation != null
                  ? _c("sensor-table", {
                      ref: "project",
                      attrs: {
                        uuid: _vm.userUUID,
                        sensor: _vm.activeTab,
                        puds: _vm.reportPuds,
                        aruTasks: _vm.reportAruTasks,
                        location: _vm.reportLocation
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeTab === "map"
                  ? _c(
                      "div",
                      { staticClass: "col s12 m12 l8" },
                      [
                        _vm.reportLocation &&
                        _vm.reportLocation.latitude &&
                        _vm.reportLocation.longitude
                          ? _c("point-map", {
                              attrs: {
                                latitude: parseFloat(
                                  _vm.reportLocation.latitude
                                ),
                                longitude: parseFloat(
                                  _vm.reportLocation.longitude
                                )
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasVisitPhotos &&
                _vm.allVisitsInfo &&
                _vm.staticVisitOptions &&
                _vm.activeTab === "location-photos"
                  ? _c(
                      "div",
                      { staticClass: "col s12 panel padded60 boxed active" },
                      [
                        _c("location-photos", {
                          attrs: {
                            "location-id": _vm.reportLocation.id,
                            uuid: _vm.userUUID,
                            "all-visits-info": _vm.allVisitsInfo,
                            options: _vm.staticVisitOptions
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }