<template>
<div class="PCTable overflow-scroll">
  <div class="error" v-if="error!=null">
  {{error}}
  </div>
  <div class="pc-loader" v-show="tableReady === false">
    <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
  </div>
  <table v-show="tableReady">
    <tr class="row-seperator">
      <template v-for="heading in headings">
        <th :class="heading.class" v-bind:key="heading.id">{{heading.heading}}</th>
      </template>
      </tr>
    <template >
      <tr v-for="row in tableData" v-bind:key="row.id">
        <td v-if="row.speciesInfo != null" v-bind:key="row.id + '-' + row.speciesInfo.name" :rowspan="tableMap.get(row.code).length" class="row-seperator">
          <div class="species-info-container">
            <img class="round-img flex-initial" :src="row.speciesInfo.icon" @error="imgUrlAlt($event, row.speciesInfo.icon)">
            <div class="text-wrapper flex-1">
              <div>{{capitalize($tc('common-code', 1))}}: {{row.speciesCode}}</div>
              <div class="english-name">{{row.speciesInfo.commonName}}</div>
              <div class="italic">{{row.speciesInfo.scientificName.charAt(0).toUpperCase() + row.speciesInfo.scientificName.slice(1)}}</div>
            </div>
          </div>
        </td>
        <td :id="row.distance.id" v-bind:key="row.id + '-' + row.distance.id" :class="{'row-seperator': row.subRowNum === uniqueSpecies.get(row.code).rowCount}">{{row.distance.typeLong}}</td>
        <template v-for="(interval, index) in intervals">
          <td v-if="row['i' + index].abundance != null" :class="{'cell-has-tooltip':row['i' + index].comments != null, 'row-seperator': row.subRowNum === uniqueSpecies.get(row.code).rowCount}" v-bind:key="row.id + '-i' + index" v-tooltip="row['i' + index].comments">
              <span :class="{'cell-comment':row['i' + index].comments != null}">{{row['i' + index].abundance}}</span>
            </td>
          <td v-else v-bind:key="row.id + '-i' + index" :class="{'row-seperator': row.subRowNum === uniqueSpecies.get(row.code).rowCount}">{{row['i' + index].abundance}}</td>
        </template>
        <td :class="{'row-seperator': row.subRowNum === uniqueSpecies.get(row.code).rowCount}"><span>{{row.isSeen}}</span></td>
        <td :class="{'row-seperator': row.subRowNum === uniqueSpecies.get(row.code).rowCount}"><span>{{row.isHeard}}</span></td>
      </tr>
    </template>
  </table>

    <v-dialog/>
</div>
</template>

<script>
import {API_URL, capitalizeString} from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'pc-log-table',
  props: ['surveyId', 'isAdminView', 'options', 'bands', 'intervals'],
  components: {'pulse-loader': PulseLoader},
  created () {
    // set columns & headings
    this.intervals.forEach((interval, index) => {
      this.options.durationIntervals.forEach(duration => {
        if (interval === duration.id) {
          this.columns.push('i' + index);
          this.headings.push({heading: duration.type, id: 'i' + index, class: 'interval'});
        }
      });
    });
    // add seen and heard into headings
    this.columns.push('isSeen');
    this.columns.push('isHeard');
    this.headings.push({heading: this.$t('survey-headings.seen'), id: 'isSeen', class: 'seen'});
    this.headings.push({heading: this.$t('survey-headings.heard'), id: 'isHeard', class: 'heard'});

    this.distanceBandsMap = new Map();
    this.options.distanceBands.forEach(band => {
      this.distanceBandsMap.set(band.id, {type: band.type, typeLong: band.typeLong});
    });

    this.uniqueSpecies = new Map()
    this.setUpTable();
  },
  methods: {
    setUpTable () {
      this.$http.get(this.getTableDataUrl + this.surveyId).then(
        async (response) => {
        // get body data
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            // put data into a format we can use
            this.tableMap = new Map();
            for (let item of response.body) {
              if (!this.uniqueSpecies.has(item.speciesCode)) {
              // doesnt have this species in the table yet
                await this.getSpeciesInfo(item.speciesCode, item.speciesId);
                this.tableMap.set(item.speciesCode, []);
              }
              // check to see if a row exists with the same band and seen/heard -- special case
              let match = this.tableMap.get(item.speciesCode).find(row => {
                if (row.isSeen === item.isSeen && row.isHeard === item.isHeard && row.distance.id === item.distanceBandId) {
                  return row;
                }
              });
              if (match) {
                // can add this sighting to existing row
                this.intervals.forEach((interval, intervalIndex) => {
                  if (item.durationIntervalId === interval) {
                    match['i' + intervalIndex] = item;
                  }
                });
                continue;
              }
              let tmp = {};
              // this creates 1 row
              tmp.code = item.speciesCode;
              tmp.speciesCode = item.speciesCode;
              tmp.id = item.speciesId + '-' + this.bands.indexOf(item.distanceBandId) + '-' + item.isSeen + '-' + item.isHeard;
              tmp.distance = {id: item.distanceBandId, type: this.distanceBandsMap.get(item.distanceBandId).type, typeLong: this.distanceBandsMap.get(item.distanceBandId).typeLong};
              for (let interval of this.intervals) {
                if (item.durationIntervalId === interval) {
                  tmp['i' + this.intervals.indexOf(interval)] = item;
                  tmp.isHeard = item.isHeard;
                  tmp.isSeen = item.isSeen;
                } else {
                  tmp['i' + this.intervals.indexOf(interval)] = {};
                }
              }
              this.tableMap.get(item.speciesCode).push(tmp);
              tmp = {};
            }
            this.finalizeRows();
          }
        }, (err) => {
          // error callback
          this.error = err;
        });
    },
    getSpeciesInfo (code, id) {
      return new Promise((resolve, reject) => {
        this.$http.get(this.getSpeciesDetailsUrl + id).then(
          (response) => {
            if (response.data.hasOwnProperty('error')) {
              this.error = response.data.error;
            } else {
              let tmp = response.body;
              tmp.icon =`https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${tmp.scientificName}`;
              this.uniqueSpecies.set(code, tmp);
              resolve();
            }
          }, (err) => {
          // error callback
            this.error = err;
          });
      });
    },
    getIntervalName () {
      for (let interval of this.options.durationIntervals) {
        if (this.selectedCell['i' + this.selectedIndex].durationIntervalId === interval.id) {
          return interval.type;
        }
      };
    },
    /* when image load error use place holder image */
    imgUrlAlt (event, url) {
      const placeHolderImageUrl = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder-icon.png';
      if (placeHolderImageUrl !== event.target.src) {
        event.target.src = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder-icon.png';
      }
    },
    finalizeRows () {
      // only keep rows with a sighting in them
      // next, sort by species
      this.tableMap = new Map([...this.tableMap.entries()].sort());
      // this.tableData.sort((a, b) => (a.code > b.code ? 1 : ((b.code > a.code) ? -1 : 0)));
      // and within species, sort by band
      for (let key of this.tableMap.keys()) {
        this.tableMap.get(key)[0].speciesInfo = this.uniqueSpecies.get(key);
        // put the data into the table
        this.tableData = this.tableData.concat(this.tableMap.get(key));
      }
      this.tableReady = true;
    },
    capitalize (str) {
      return capitalizeString(str);
    }
  },
  data () {
    return {
      getTableDataUrl: API_URL + 'get-observation-summary?surveyId=',
      getSpeciesDetailsUrl: API_URL + 'get-species-details?speciesId=',
      columns: ['speciesInfo', 'distance'],
      nMinute: 9,
      tableData: [],
      tableReady: false,
      uniqueSpecies: Map, // (code, speciesInfo)
      tableMap: Map, // (code, [array of tabledata rows for this species])
      distanceBandsMap: Map,
      selectedCell: null,
      selectedIndex: -1,
      error: null,
      headings: [
        {
          heading: this.$tc('common-species', 1),
          id: 'speciesInfo',
          class: 'species'

        },
        {
          heading: this.$t('survey-headings.distances'),
          id: 'distance',
          class: 'bands'
        }
      ]
    }
  }
}
</script>
<style lang="scss">

.PCTable table {
  width: auto;
  margin: auto;
}

.PCTable table th {
  /*padding: 0px 0px;*/
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  color: #5e615a; /*#6c7065;*/
  font-weight: 600;
}

.PCTable div.min, .PCTable div.min span{
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.PCTable table td {
    text-align: center;
    color: #5e615a; /*#6c7065;*/
}
.PCTable td>div, .PCTable table td.content {
  padding: 15px 5px;
}

.PCTable table .vt-1:hover {
    background-color: #d7e3d9;
    color:#7c7c7c;
}
.PCTable table .vt-2 {
    background-color: #ef7d52;
    color: #ef7d52;
}

.PCTable table .vt-2:hover {
    background-color: #fce6de;
    color:#7c7c7c;
}
.PCTable table .vt-1 {
    background-color: #b9cebd;
    color: #b9cebd;
}

.PCTable table .vt-2:hover {
    background-color: #fce6de;
    color:#7c7c7c;
}
.PCTable table .vt-3 {
    background-color: #6d9dd2;
    color: #6d9dd2;
}

.PCTable table .vt-3:hover {
    background-color: #6d9dd2;
    color:#7c7c7c;
}
.PCTable table .vt-4 {
    background-color: #eac070;
    color: #eac070;
}

.PCTable table .vt-4:hover {
    background-color: #efe0c5;
    color:#7c7c7c;
}
.PCTable table .vt-5 {
    background-color: #dee4a0;
    color: #dee4a0;
}

.PCTable table .vt-5:hover {
    background-color: #dee4a0;
    color:#7c7c7c;
}
.PCTable table .vt-6 {
    background-color: #ec7f90;
    color: #ec7f90;
}
.PCTable table .vt-6:hover {
    background-color: #f3ccd2;
    color:#7c7c7c;
}

table.vue-bordered.PCTable .time {
    line-height: normal;
}

.table-min1.PCTable .time {
  width: 100px;
}

.table-min3.PCTable .time {
  width: 75px;
}

.table-min9.PCTable .time {
  width: 75px;
}

.table-min1.PCTable th.optional.content,
.table-min1.PCTable td.optional.content {
  width: 250px;
}

.table-min3.PCTable th.optional.content {
  width: 200px;
}

.table-min9.PCTable th.optional.content {
  width: 200px;
}
.PCTable td {
    border-bottom: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    padding: 9px 10px;
    font-size: .83em;
}
/*
  table.table-bordered tr td .tableToggle {
    width: auto;
    border: 1px solid #d9d9d9;
    display: inline-block;
    padding: 3px 10px;
    float: right;
    color: #C94412; }

  table.table-bordered tr td .VueTables__child-row-toggler--closed::before {
    content: "\f104";
    display: inline-block;
    font-family: "Ionicons";
    color: #C94412;
    border: 1px solid #d9d9d9;
    padding: 5px 10px;
  }
  table.table-bordered tr td .VueTables__child-row-toggler--open::before {
    content:"\f10d";
    display: inline-block;
    font-family: "Ionicons";
    color: #C94412;
    border: 1px solid #d9d9d9;
    padding: 5px 10px;
  }
*/
.PCTable .italic{
    font-style: italic;
  }

.PCTable .text-wrapper{
    text-align:left;
    padding-left:10px;
  }

.PCTable .round-img{
    border-radius: 50%;
    width: 85px;
    height: 85px;
  }

.PCTable .species-info-container{
    flex-direction: row;
    align-items: center;
  }
/*
.cell-value{
  cursor:pointer;
}

.cell-value:hover{
  background: #E0E0E0;
}*/

.PCTable .cell-has-tooltip{
  cursor: help;
}

.PCTable .cell-comment{
  border-bottom: 1px dotted black;
}

.PCTable .category-items-wrapper{
  padding-left:10px;
}

.PCTable .category-title,
  .english-name {
    font-weight: 600;
  }

.PCTable .species, .PCTable .bands, .PCTable .interval {
    padding: 0px 20px 0px 20px;
  }

.PCTable .row-seperator{
  border-bottom: solid #5e615a 1px !important;
}

.PCTable .pc-loader{
  text-align: center;
}
</style>
