<template>
  <mapbox ref="discoverMap"
    :access-token="accessToken"
    :map-options="{
      center: [-110, 50.8],
      zoom: 13,
      style: 'mapbox://styles/mapbox/light-v10'
    }"
    :geolocate-control="{
      show: true
    }"
    :scale-control="{
    show: true
    }"
    :fullscreen-control="{
      show: true
    }"
    @map-load="loadLayers"
    @map-data="dataEvent"
    @map-move="updateMarkers"
    @map-moveend="updateMarkers"
    @map-click:unclustered-point="clickPoint"
    @map-mouseleave:unclustered-point="mouseClickLeft"
    @map-click:clusters="clickCluster"
    @map-mouseenter:clusters="mouseEnteredCluster"
    @map-mouseleave:clusters="mouseLeft"
  />
</template>

<script>
/* --------------------------------------------------------
static options:
  organizationInfo: orgnazation name and colors
queried options:
  organizationId:
  mapPoint: map dots
  sensors: has ARU or has Camera so display map icons

Map Layers:
  cluster layers
    - defines how to cluster points
    - click to expand
    - hover over to show information about locations
    - with donut markers
    - default circle is opacity 0.1
  single points:
    - default circle is opacity 0.1
    - display using camera/ARU/combined markers
    - click circle to display popup (markers only have single point information)
  single point buffer layer

Known Issues:
1. the mouse cursors won't change to pointer on mouse enter. however if do debug, they do changes.
guess other events change the cursors.
-------------------------------------------------------- */

import { eventBus } from '@/lib/eventbus';
import Mapbox from 'mapbox-gl-vue'; // can't be combined import
// import PopupContent from 'mapbox-gl-vue';
import mapboxgl from 'mapbox-gl';
// import {Map, MapLayerMouseEvent, GeolocateControl } from 'mapbox-gl';
// import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import {MAP_ACCESS_TOKEN} from '@/lib/common';
import { checkSourceUnload, fitToPoints, setLanguage } from '@/components/mapGL/mapUtil';
import PopupContent from './PopupContent.vue'

export default {
  name: 'data-discover-map',
  components: {
    'mapbox': Mapbox
  },
  // computed: mapGetters(['mapPoint', 'organizationIdList', 'organizationInfo']),
  data: function () {
    /* Storing Map object
    Take note that it's generally bad idea to add to Vuex or component's data anything
    but primitive types and plain objects. Vue adds getters and setters to every property,
    so if you add Map object to Vuex store or component data, it may lead to weird bugs.
    If you want to store map object, store it as non-reactive property like in example below.

    don't put map, or markere here. it will crush vue dev tool, and mess up the map;
    */
    return {
      accessToken: MAP_ACCESS_TOKEN,
      pointMapName: 'locations',
      maxZoom: 12
    }
  },
  created () {
    /* don't put map, or markere in the data. it will crush vue dev tool, and mess up the map;
      declare them as non-reactive data here.
    */
    this.markers = {};
    this.markersOnScreen = {};
    this.hoverPopup = null;
    this.clickPopup = null;
    this.mapPoint = [];
    this.organizationInfo = {};
    this.organizationIdList = [];
  },
  methods: {
    loadLayers (map) {
      this.addClusterLayer(map);
      setLanguage(map);
    },
    /* copied from mapping portal */
    manualUpdate (bInital) {
      this.hoverPopup && this.hoverPopup.remove(); // remove /hide pop up box
      this.clickPopup && this.clickPopup.remove();
      this.mapPoint = this.$store.getters.mapPoint || [];
      this.organizationInfo = this.$store.getters.organizationInfo || {};
      this.organizationIdList = this.$store.getters.organizationList;
      const sensorCodes = this.$store.getters.sensorCodes;
      this.hasARU = sensorCodes.includes('ARU');
      this.hasCamera = sensorCodes.includes('CAM');

      this.clearMap(bInital);
      this.addClusterLayer(this.$refs.discoverMap.map);
    },
    async clearMap (bInital) {
      const map = this.$refs.discoverMap.map;
      this.hoverPopup && this.hoverPopup.remove();
      const allLayers = ['clusters', 'cluster-count', 'unclustered-point', 'unclustered-point-buffer'];
      allLayers.forEach(l => {
        if (map.getLayer(l)) {
          map.removeLayer(l);
        }
      });
      /* remove markers form map and then clear the collections */
      for (let id in this.markers) {
        this.markers[id].remove();
      }
      for (let id in this.markersOnScreen) {
        this.markersOnScreen[id].remove();
      }
      this.markers = {};
      this.markersOnScreen = {};
      /* remove source data
        the easy way should be just replace map points,
        however, the cluster property defines how to cluster data can't
        be updated dynamically, so I remove and add the source instead.
      */
      if (map.getSource(this.pointMapName)) {
        map.removeSource(this.pointMapName);
        await checkSourceUnload(this.pointMapName, map);
      }
      if (bInital) {
        map.easeTo({
          center: [-110, 50.8],
          zoom: 3
        });
        map.on('idle', function () { // use plain js to mount event
          eventBus.$emit('map-loading', false);
        });
      }
    },
    async addClusterLayer (map) {
      if (!this.organizationIdList || this.organizationIdList.length === 0 || !this.mapPoint.features || !this.mapPoint.features.length === 0) {
        eventBus.$emit('map-loading', false);
        return;
      }

      let clusterProperties = {};
      //   let circleColor = ['case'];
      this.organizationIdList.forEach(x => {
        const match = ['==', ['get', 'organizationId'], x];
        clusterProperties[x] = ['+', ['case', match, 1, 0]];
        //     circleColor.push(match);
        //   const orgColor = this.organizationInfo[x].color;
        //    circleColor.push(orgColor);
      });
      //   circleColor.push('#ffa0a0'); // other color
      /* set map extent */
      const coordinates = this.mapPoint.features;
      if (coordinates.length === 0) { // don't do anything when no point
        eventBus.$emit('map-loading', false);
        return;
      }
      fitToPoints(map, coordinates, 50);

      const bufferMatch = ['==', ['has', 'buffer'], true];
      clusterProperties['isBuffered'] = ['+', ['case', bufferMatch, 1, 0]];

      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.addSource(this.pointMapName, {
        type: 'geojson',
        // Point to GeoJSON data. This example visualizes all M1.0+ locations
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: this.mapPoint,
        cluster: true,
        clusterMaxZoom: 10, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50),
        clusterProperties // keep separate counts for each fuel category in a cluster
        // example https://blog.mapbox.com/clustering-properties-with-mapbox-and-html-this.markers-bb353c8662ba
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: this.pointMapName,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-opacity': 0.1,
          'circle-color': '#ffffff',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: this.pointMapName,
        filter: ['has', 'point_count'],
        /* filter: ["==", "type", "Open water"]
          0: "=="
          1: "type"
          2: "Open water" */
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-opacity': 0.1,
          'circle-color': '#ffffff', // circleColor,
          'circle-radius': 15
          //  [
          //   'step',
          //   ['get', 'point_count'],
          //   15,
          //   100,
          //   20,
          //   750,
          //   30,
          //   2000,
          //   40
          // ]
        }
      });
      // map.addLayer({
      //   id: 'unclustered-point-buffer',
      //   type: 'circle',
      //   source: this.pointMapName,
      //   filter: ['all', ['!has', 'point_count'], ['has', 'buffer']],
      //   paint: {
      //     'circle-color': 'rgba(17, 180, 218, 0.2)',
      //     'circle-stroke-color': 'rgba(17, 180, 218, 1)',
      //     'circle-radius': 20,
      //     'circle-stroke-width': 1
      //   }
      // });
    },

    // inspect a cluster on click
    clickCluster (map, e) {
      eventBus.$emit('map-loading', false);
      // inspect a cluster on click
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource(this.pointMapName).getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    },
    clickPoint (map, e) {
      const self = this;
      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      const coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let html = [];
      e.features.forEach((feature, index) => {
        let sensor = '';
        if (feature.properties.hasARU) {
           sensor = this.$t('common-aru');
        } else if (feature.properties.hasCAM) {
          sensor = this.$t('common-camera');
        } else if (feature.properties.hasPC) {
          sensor = this.$t('common-pointCount');
        }

        html.push({
          Organization: self.organizationInfo[feature.properties.organizationId].name,
          Location: feature.properties.name,
          Sensor: sensor,
          Buffer: feature.properties.buffer ? (feature.properties.buffer + this.$t('commonUnits-metre')) : null,
          Project: feature.properties.projectNames,
          Visits: feature.properties.visitDates
        });
      });

      this.clickPopup && this.clickPopup.remove();
      this.clickPopup = new mapboxgl.Popup({ closeButton: true,
        closeOnClick: false })
        .setLngLat(coordinates)
        .setMaxWidth('250px')
        .setHTML('<div id="vue-popup-content" style="width: 250px; height: 280px;"></div').addTo(map);

      new PopupContent({
        propsData: { feature: html }
      }).$mount('#vue-popup-content')
    },
    mouseEnteredCluster (map, e) {
      map.getCanvas().style.cursor = 'pointer';
      let html = '';
      this.organizationIdList.forEach(x => {
        html += (e.features[0].properties[x] > 0 ? `<p><span class="cb" style="background-color:${this.organizationInfo[x].color}"></span>${this.organizationInfo[x].name}: ${e.features[0].properties[x]} ${this.$tc('common-location', e.features[0].properties[x])} </p>` : '')
      });
      html += '<p>' + this.$t('dataDiscoverMap-clickToZoom') + '</p>';
      this.hoverPopup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      }).setLngLat(e.lngLat)
        .setHTML(html)
        .addTo(map);
    },
    mouseLeft (map, e) {
      map.getCanvas().style.cursor = '';
      this.hoverPopup && this.hoverPopup.remove();
    },
    // mouseEntered (map) {
    //   map.getCanvas().style.cursor = 'pointer';
    // },
    mouseClickLeft (map) {
      map.getCanvas().style.cursor = ''
    },
    dataEvent (map, data) {
      if (data.dataType === 'source' && data.isSourceLoaded) {
        this.updateMarkers(map);
        eventBus.$emit('map-loading', false);
      }
    },
    updateMarkers (map) {
      eventBus.$emit('map-loading', true);
      this.hoverPopup && this.hoverPopup.remove();
      let newMarkers = {};
      const features = map.querySourceFeatures(this.pointMapName);

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (let i = 0; i < features.length; i++) {
        const coords = features[i].geometry.coordinates;
        const props = features[i].properties;
        const id = !props.cluster ? 'point' + props.id : 'cluster' + props.cluster_id;
        let marker = this.markers[id];
        if (!marker) {
          const el = !props.cluster ? this.getPointSvg(props) : this.createDonutChart(props);
          marker = this.markers[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords)
          /* add pop up for clustered points only.
            for points, if they are overlapped, the marker click only can show single point information.
            use layer click eventn to show point information. */
          // if (props.cluster) {
          //   let html = '';
          //   this.organizationIdList.forEach(x => { html += (features[0].properties[x] > 0 ? `${this.organizationInfo[x].name}: ${features[0].properties[x]} location <br/>` : '') });
          //   marker.setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          //   .setHTML(html));
          // }
        }
        newMarkers[id] = marker;

        if (!this.markersOnScreen[id]) marker.addTo(map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (let id in this.markersOnScreen) {
        if (!newMarkers[id]) this.markersOnScreen[id].remove();
      }
      this.markersOnScreen = newMarkers;
      // eventBus.$emit('map-loading', false);
    },

    /* create donut circle */
    // code for creating an SVG donut chart from feature properties
    createDonutChart (props) {
      let offsets = [];
      let counts = [];
      this.organizationIdList.forEach(x => props[x] > 0 && counts.push({orgId: x, count: props[x]}));
      let total = 0;
      for (let i = 0; i < counts.length; i++) {
        offsets.push(total);
        total += counts[i].count;
      }
      const fontSize =
      total >= 1000 ? 18 : total >= 100 ? 16 : total >= 10 ? 16 : 14;
      const r = total >= 1000 ? 40 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      const r0 = Math.round(r * 0.6);

      const rBuffer = r * 2.5;
      const r1 = Math.round(rBuffer * 0.6);
      const w = props.isBuffered ? rBuffer * 2 : r * 2;
      const center = w * 0.5;
      let html =
        `<div><svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font:${fontSize}px sans-serif">`;

      for (let i = 0; i < counts.length; i++) {
        html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i].count) / total, center, r, r0, this.organizationInfo[counts[i].orgId].color);
      }
      if (props.isBuffered > 0) {
        html += `<circle cx="${center}" cy="${center}" r="${r1}" fill="#044B94" fill-opacity="0.2" />`;
        // `<circle cx="${center}" cy="${center}" r="${r1}" fill="${counts.length > 1 ? '#044B94': this.organizationInfo[this.organizationIdList[0]].color}" fill-opacity="0.4" />`;
      }
      html +=
        `<circle cx="${center}" cy="${center}" r="${r0}" fill="white" /><text dominant-baseline="central" transform="translate(${center}, ${center})">
        ${total.toLocaleString()}
        </text></svg></div>`;

      const el = document.createElement('div');
      el.innerHTML = html;
      return el.firstChild;
    },

    donutSegment (start, end, center, r, r0, color) {
      if (end - start === 1) end -= 0.00001;
      const a0 = 2 * Math.PI * (start - 0.25);
      const a1 = 2 * Math.PI * (end - 0.25);
      const x0 = Math.cos(a0);
      const y0 = Math.sin(a0);
      const x1 = Math.cos(a1);
      const y1 = Math.sin(a1);
      const largeArc = end - start > 0.5 ? 1 : 0;

      return [
        '<path d="M',
        center + r0 * x0,
        center + r0 * y0,
        'L',
        center + r * x0,
        center + r * y0,
        'A',
        r,
        r,
        0,
        largeArc,
        1,
        center + r * x1,
        center + r * y1,
        'L',
        center + r0 * x1,
        center + r0 * y1,
        'A',
        r0,
        r0,
        0,
        largeArc,
        0,
        center + r0 * x0,
        center + r0 * y0,
        '" fill="' + color + '" />'
      ].join(' ');
    },
    getPointSvg (props) {
      let html;
      if (props.hasARU && props.hasCAM) {
        html = this.getCameraMicSvg(this.organizationInfo[props.organizationId].color, 25, 25, !!props.buffer);
      } else if (props.hasARU) {
        html = this.getMicrphoneSvg(this.organizationInfo[props.organizationId].color, 25, 25, !!props.buffer);
      } else if (props.hasCAM) {
        html = this.getCameraSvg(this.organizationInfo[props.organizationId].color, 25, 25, !!props.buffer);
      } else if (props.hasPC) {
        html = this.getPointCountSVG(this.organizationInfo[props.organizationId].color, 25, 25, !!props.buffer);
      } else {
        html = this.getDotSvg(this.organizationInfo[props.organizationId].color, 10, 10, !!props.buffer);
      }
      const el = document.createElement('div');
      el.innerHTML = html;
      return el.firstChild;
    },
    getMicrphoneSvg (color, w, h, isBuffered) {
      /* for buffered icons
      move center to  a quarter of viewbox size, and scale to half
      add a circle position at half of view box size
      */
      const scale = isBuffered ? 2 : 1;
      let svgHtml = `<svg width="${w * scale}" height="${w * scale}"
      version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 435.2 435.2" style="enable-background:new 0 0 ${w} ${h};" xml:space="preserve">`;
      if (isBuffered) {
        svgHtml += `<g transform="translate(107 107) scale(0.5 0.5) ">`;
      }
      svgHtml += `<g>
        <g>
        <path fill="${color}" d="M356.864,224.768c0-8.704-6.656-15.36-15.36-15.36s-15.36,6.656-15.36,15.36c0,59.904-48.64,108.544-108.544,108.544
          c-59.904,0-108.544-48.64-108.544-108.544c0-8.704-6.656-15.36-15.36-15.36c-8.704,0-15.36,6.656-15.36,15.36
          c0,71.168,53.248,131.072,123.904,138.752v40.96h-55.808c-8.704,0-15.36,6.656-15.36,15.36s6.656,15.36,15.36,15.36h142.336
          c8.704,0,15.36-6.656,15.36-15.36s-6.656-15.36-15.36-15.36H232.96v-40.96C303.616,355.84,356.864,295.936,356.864,224.768z"/>
        </g>
      </g>
      <g>
        <g>
        <path fill="${color}" d="M217.6,0c-47.104,0-85.504,38.4-85.504,85.504v138.752c0,47.616,38.4,85.504,85.504,86.016
          c47.104,0,85.504-38.4,85.504-85.504V85.504C303.104,38.4,264.704,0,217.6,0z"/>
        </g>
      </g>`;
      if (isBuffered) {
        svgHtml += `</g><circle cx="215" cy="215" r="200" fill="#044B94" fill-opacity="0.2" />`;
        // `<circle cx="${center}" cy="${center}" r="${r1}" fill="${counts.length > 1 ? '#044B94': this.organizationInfo[this.organizationIdList[0]].color}" fill-opacity="0.4" />`;
      }
      svgHtml += `</svg>`;
      return svgHtml;
    },
    getCameraSvg (color, w, h, isBuffered) {
      const scale = isBuffered ? 2 : 1;
      let svgHtml = `<svg id="Capa_1" enable-background="new 0 0 ${w} ${h}" width="${w * scale}" height="${h * scale}" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">`;
      if (isBuffered) {
        svgHtml += `<g transform="translate(128 128) scale(0.5 0.5) ">`;
      }
      svgHtml += `<g> <path fill="${color}"
        d="m497 121h-111.729l-25.854-51.708c-2.542-5.082-7.735-8.292-13.417-8.292h-180c-5.682 0-10.875 3.21-13.417 8.292l-25.854
        51.708h-111.729c-8.284 0-15 6.716-15 15v300c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-300c0-8.284-6.716-15-15-15z"/>
        </g>
        <g stroke="#ffffff" stroke-width="15">
        <path fill="#ffffff" d="m436 181h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-180 0c-57.897
        0-105 47.103-105 105s47.103 105 105 105 105-47.103 105-105-47.103-105-105-105zm0 150c-24.813 0-45-20.187-45-45s20.187-45 45-45 45
        20.187 45 45-20.187 45-45 45z"/>
        </g>`;
      if (isBuffered) {
        svgHtml += `</g><circle cx="256" cy="256" r="200" fill="#044B94" fill-opacity="0.2" />`;
        // `<circle cx="${center}" cy="${center}" r="${r1}" fill="${counts.length > 1 ? '#044B94': this.organizationInfo[this.organizationIdList[0]].color}" fill-opacity="0.4" />`;
      }
      svgHtml += `</svg>`;
      return svgHtml;
    },
    getCameraMicSvg (color, w, h, isBuffered) {
      const scale = isBuffered ? 2 : 1;
      let svgHtml = `<svg id="Capa_1"  viewBox="0 0 74.207 74.207" width="${w * scale}" height="${h * scale}" style="enable-background:new 0 0 74.207 74.207;"  xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">`;
      if (isBuffered) {
        svgHtml += `<g transform="translate(18 18) scale(0.5 0.5) ">`;
      }
      svgHtml += `<g>
        <path fill="${color}" d="M57.746,14.658h-2.757l-1.021-3.363c-0.965-3.178-3.844-5.313-7.164-5.313H28.801c-3.321,0-6.201,2.135-7.165,5.313
        l-1.021,3.363h-4.153C7.385,14.658,0,22.043,0,31.121v20.642c0,9.077,7.385,16.462,16.462,16.462h41.283
        c9.077,0,16.462-7.385,16.462-16.462V31.121C74.208,22.043,66.823,14.658,57.746,14.658z M68.208,51.762
        c0,5.769-4.693,10.462-10.462,10.462H16.462C10.693,62.223,6,57.53,6,51.762V31.121c0-5.769,4.693-10.462,10.462-10.462h8.603
        l2.313-7.621c0.192-0.631,0.764-1.055,1.423-1.055h18.003c0.659,0,1.23,0.424,1.423,1.057l2.314,7.619h7.204
        c5.769,0,10.462,4.693,10.462,10.462L68.208,51.762L68.208,51.762z"/>

        <g transform="
          translate(17 18)
          scale(0.09 0.09)">
          <g>
          <path fill="${color}" d="M356.864,224.768c0-8.704-6.656-15.36-15.36-15.36s-15.36,6.656-15.36,15.36c0,59.904-48.64,108.544-108.544,108.544
            c-59.904,0-108.544-48.64-108.544-108.544c0-8.704-6.656-15.36-15.36-15.36c-8.704,0-15.36,6.656-15.36,15.36
            c0,71.168,53.248,131.072,123.904,138.752v40.96h-55.808c-8.704,0-15.36,6.656-15.36,15.36s6.656,15.36,15.36,15.36h142.336
            c8.704,0,15.36-6.656,15.36-15.36s-6.656-15.36-15.36-15.36H232.96v-40.96C303.616,355.84,356.864,295.936,356.864,224.768z"/>
          </g>

          <g>
          <path fill="${color}" d="M217.6,0c-47.104,0-85.504,38.4-85.504,85.504v138.752c0,47.616,38.4,85.504,85.504,86.016
            c47.104,0,85.504-38.4,85.504-85.504V85.504C303.104,38.4,264.704,0,217.6,0z"/>
          </g>
        </g>
      </g>`;

      if (isBuffered) {
        svgHtml += `</g><circle cx="37" cy="37" r="37" fill="#044B94" fill-opacity="0.2"></circle>`;
      }
      svgHtml += ` </svg>`;
      return svgHtml;
    },
     getPointCountSVG (color, w, h, isBuffered) {
      const scale = isBuffered ? 2 : 1;
      return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="${w*scale}" height="${h*scale}">
        <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path stroke="${color}" d="M240 96c26.5 0 48-21.5 48-48S266.5 0 240 0C213.5 0 192 21.5 192 48S213.5 96 240 96zM80.01 287.1c7.31 0 13.97-4.762 15.87-11.86L137 117c.3468-1.291 .5125-2.588 .5125-3.866c0-7.011-4.986-13.44-12.39-15.13C118.4 96.38 111.7 95.6 105.1 95.6c-36.65 0-70 23.84-79.32 59.53L.5119 253.3C.1636 254.6-.0025 255.9-.0025 257.2c0 7.003 4.961 13.42 12.36 15.11L76.01 287.5C77.35 287.8 78.69 287.1 80.01 287.1zM368 160h-15.1c-8.875 0-15.1 7.125-15.1 16V192h-34.75l-46.75-46.75C243.4 134.1 228.6 128 212.9 128C185.9 128 162.5 146.3 155.9 172.5L129 280.3C128.4 282.8 128 285.5 128 288.1c0 8.325 3.265 16.44 9.354 22.53l86.62 86.63V480c0 17.62 14.37 32 31.1 32s32-14.38 32-32v-82.75c0-17.12-6.625-33.13-18.75-45.25l-46.87-46.88c.25-.5 .5-.875 .625-1.375l19.1-79.5l22.37 22.38C271.4 252.6 279.5 256 288 256h47.1v240c0 8.875 7.125 16 15.1 16h15.1C376.9 512 384 504.9 384 496v-320C384 167.1 376.9 160 368 160zM81.01 472.3c-.672 2.63-.993 5.267-.993 7.86c0 14.29 9.749 27.29 24.24 30.89C106.9 511.8 109.5 512 112 512c14.37 0 27.37-9.75 30.1-24.25l25.25-101l-52.75-52.75L81.01 472.3z"/></svg>`;
    },
    getDotSvg (color, w, h, isBuffered) {
      const scale = isBuffered ? 2 : 1;
      let svgHtml = `<svg width="${w * scale}" height="${h * scale}">`;
      if (isBuffered) {
        svgHtml += `<g transform="translate(20 20) scale(0.5 0.5) ">`;
      }

      svgHtml += `<circle cx="${w}" cy="${h}" r="${w - 5}" stroke="${color}" stroke-width="3" fill="${color}" />`;
      if (isBuffered) {
        svgHtml += `</g><circle  cx="${w}" cy="${h} r="${w}" fill="#044B94" fill-opacity="0.2"></circle>`;
      }
      svgHtml += ` </svg>`;
      return svgHtml;
    }
  }
}
</script>
<style scoped>
  .cb {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 20px;
  }
</style>
