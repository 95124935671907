var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "pagination" },
      [
        _vm.total > 1
          ? _c("span", { staticClass: "red-font" }, [
              _vm._v(_vm._s(_vm.orderDisplay[0]))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activePageNumber > 1,
                expression: "activePageNumber > 1"
              }
            ],
            staticClass: "first-last-arrows",
            on: {
              click: function($event) {
                return _vm.setPage(_vm.activePageNumber - 1)
              }
            }
          },
          [_vm._v("«")]
        ),
        _vm._v(" "),
        _vm._l(_vm.displayPageList, function(item, topIndex) {
          return [
            item === 0
              ? [
                  _c(
                    "div",
                    { key: "first" + topIndex, staticClass: "allPages" },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              _vm.ui.showPages = !_vm.ui.showPages
                            }
                          }
                        },
                        [_vm._v("...")]
                      ),
                      _vm._v(" "),
                      item === 0
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.ui.showPages,
                                  expression: "ui.showPages"
                                }
                              ],
                              staticClass: "pageList"
                            },
                            [
                              _c("RecycleScroller", {
                                staticClass: "pageListWrap",
                                attrs: { items: _vm.pageObj, "item-size": 35 },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(props) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "page-row",
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-around"
                                              }
                                            },
                                            [
                                              _vm._l(props.item.data, function(
                                                pageItem,
                                                index
                                              ) {
                                                return [
                                                  pageItem.page > 0
                                                    ? _c(
                                                        "a",
                                                        {
                                                          key: "page" + index,
                                                          class: {
                                                            active:
                                                              pageItem.page ===
                                                              _vm.activePageNumber,
                                                            [pageItem.className]: true
                                                          },
                                                          staticStyle: {
                                                            width: "35px",
                                                            height: "35px",
                                                            float: "none"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.setPage(
                                                                pageItem.page
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          pageItem.className &&
                                                          pageItem.className.indexOf(
                                                            "out-of-range"
                                                          ) !== -1
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "triangle"
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            _vm._s(
                                                              pageItem.page
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c("a", {
                                                        key: "page" + index,
                                                        staticClass:
                                                          "placeholder",
                                                        staticStyle: {
                                                          width: "35px",
                                                          height: "35px",
                                                          float: "none"
                                                        }
                                                      })
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              : [
                  _c(
                    "a",
                    {
                      key: "first" + topIndex,
                      staticClass: "pageNum",
                      class: {
                        active: item === _vm.activePageNumber,
                        [_vm.pageClass[item - 1]]: true
                      },
                      on: {
                        click: function($event) {
                          return _vm.setPage(item)
                        }
                      }
                    },
                    [
                      _vm.pageClass[item - 1] &&
                      _vm.pageClass[item - 1].indexOf("out-of-range") !== -1
                        ? _c("div", { staticClass: "triangle" })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(item))
                    ]
                  )
                ]
          ]
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activePageNumber < _vm.total,
                expression: "activePageNumber < total"
              }
            ],
            staticClass: "first-last-arrows",
            on: {
              click: function($event) {
                return _vm.setPage(_vm.activePageNumber + 1)
              }
            }
          },
          [_vm._v("»")]
        ),
        _vm._v(" "),
        _vm.total > 1
          ? _c("span", { staticClass: "red-font" }, [
              _vm._v(_vm._s(_vm.orderDisplay[1]))
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }