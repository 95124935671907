var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.formName,
        scrollable: true,
        adaptive: true,
        transition: "nice-modal-fade",
        classes: "modal-form",
        draggable: ".drag-handle",
        delay: 100,
        width: 1000,
        height: "80%",
        minWidth: 200,
        minHeight: 200,
        clickToClose: false
      }
    },
    [
      _c(
        "div",
        { staticClass: "iconButton link", on: { click: _vm.closeForm } },
        [_c("span", { staticClass: "ion-close" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-header drag-handle" }, [
        _c("h6", [_vm._v(_vm._s(_vm.$t("common-help")))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-scroller" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("ul", { staticClass: "vue collapsible getHelp" }, [
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showHelp = !_vm.showHelp
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("common-keyboardShortcuts")) + " "),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showHelp,
                        "ion-arrow-up-b": !_vm.showHelp
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showHelp
                ? _c("div", { staticClass: "collapsible-body" }, [
                    _c("div", { staticClass: "row p-5" }, [
                      _c("div", { staticClass: "col s12 m12" }, [
                        _c("div", { staticClass: "col s12 m6" }, [
                          _c("h6", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-onTaggingPage")))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("common-left")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-leftDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(" " + _vm._s(_vm.$t("common-right")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-rightDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlA")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlADesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlD")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlDDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlQ")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlQDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-escape")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-escapeAltDesc"))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-space")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-spaceDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon double-line" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-shiftSpace")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-shiftSpacecDesc"))
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col s12 m6" }, [
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon double-line" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-shiftCtrl")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-shiftCtrlDesc"))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlE")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlEAltDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlS")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlSAltDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon double-line" }, [
                              _vm._v(_vm._s(_vm.$t("Ctrl Shift 1-5")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("Rate selected tags 1-5")))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showMouse = !_vm.showMouse
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("taggingHelp-tagSelection"))),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showMouse,
                        "ion-arrow-up-b": !_vm.showMouse
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMouse,
                      expression: "showMouse"
                    }
                  ],
                  staticClass: "collapsible-body"
                },
                [
                  _c("div", { staticClass: "row px-5" }, [
                    _c("div", { staticClass: "col s12 m12" }, [
                      _c("div", { staticClass: "keyIcon" }, [
                        _c("div", { staticClass: "big icon" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-mouseDrag")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-mouseDragDesc")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "keyIcon" }, [
                        _c("div", { staticClass: "big icon" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlMouseDrag")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("taggingHelp-ctrlMouseDragDesc"))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "keyIcon" }, [
                        _c("div", { staticClass: "big icon" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-singleLeftClick"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("taggingHelp-singleLeftClickDesc"))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "keyIcon" }, [
                        _c("div", { staticClass: "big icon" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-ctrlSingleLeftClick"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("taggingHelp-ctrlSingleLeftClickDesc")
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "keyIcon" }, [
                        _c("div", { staticClass: "big icon" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-rightClick")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-rightClickDesc")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "keyIcon" }, [
                        _c("div", { staticClass: "big icon" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-shiftSingleClick"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("taggingHelp-shiftSingleClickDesc"))
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showLegend = !_vm.showLegend
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("common-legend"))),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showLegend,
                        "ion-arrow-up-b": !_vm.showLegend
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showLegend,
                      expression: "showLegend"
                    }
                  ],
                  staticClass: "collapsible-body"
                },
                [
                  _c("div", { staticClass: "row px-5" }, [
                    _c("div", { staticClass: "col s12 m12" }, [
                      _c("div", {}, [
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass: "far fa-check-circle flex-initial mr-3"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-unverified")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle flex-initial mr-3 text-green-500"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-verified")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass: "far fa-star flex-initial mr-3"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-tagNotMarkedNice"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass:
                              "fas fa-star text-yellow-500 mr-3 flex-initial"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-niceTag")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass: "fas fa-tasks mr-3 flex-initial"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-goToOriginalTask"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c(
                            "span",
                            { staticClass: "font-bold mr-3 flex-initial" },
                            [_vm._v("#")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(_vm._s(_vm.$t("recordingForms-abundance")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass: "fas fa-infinity mr-3 flex-initial"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-abundanceTooMany"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass:
                              "far fa-window-maximize mr-3 flex-initial"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-openDetailedWindow"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c("i", {
                            staticClass: "fas fa-play mr-3 flex-initial"
                          }),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-playSoundClip")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex w-full p-3" }, [
                          _c(
                            "span",
                            { staticClass: "font-bold mr-3 flex-initial" },
                            [_vm._v(_vm._s(_vm.$t("taggingHelp-blackLines")))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-initial grow" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("taggingHelp-tagDimensionsBuffered")
                              )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }