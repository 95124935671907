var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.formName,
        scrollable: true,
        adaptive: true,
        transition: "nice-modal-fade",
        classes: "modal-form scroll",
        draggable: ".drag-handle",
        clickToClose: false,
        delay: 100,
        width: "80%",
        height: "80%",
        minWidth: 200,
        minHeight: 200
      }
    },
    [
      _c(
        "div",
        { staticClass: "iconButton link", on: { click: _vm.closeForm } },
        [_c("span", { staticClass: "ion-close" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-header drag-handle" }, [
        _c("h6", [_vm._v(_vm._s(_vm.$t("imageInfo-imageExifInformation")))])
      ]),
      _vm._v(" "),
      _vm.img != null
        ? _c("div", { staticClass: "modal-scroller" }, [
            _c("div", { staticClass: "modal-content container" }, [
              _c("div", { staticClass: "input" }, [
                _c("span", { staticClass: "key" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("imageInfo-filename")) + ":")
                  ])
                ]),
                _vm._v(" "),
                _vm.img != null
                  ? _c("span", { staticClass: "value" }, [
                      _c("span", [_vm._v(_vm._s(_vm.img.fileURL))])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: {
                  id: "imageForExif",
                  src: _vm.img.fileURL,
                  crossorigin: "use-credentials"
                },
                on: {
                  load: function($event) {
                    return _vm.readExifInfo()
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "exif" },
                [
                  _vm._l(_vm.exifInfo, function(value, key) {
                    return [
                      _c("div", { key: "md" + key, staticClass: "input" }, [
                        _c("span", { staticClass: "key" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatMetadatKey(key)) + ":")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "value" }, [
                          _c("span", [_vm._v(_vm._s(value))])
                        ])
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }