var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "remote-camera": _vm.pudId != null },
      attrs: { id: "report-wrapper" }
    },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? [
            _c(
              "div",
              {
                staticClass: "container overflow padded60 !pb-0 projectHeader"
              },
              [
                _c("p", [
                  _vm.userUUID && _vm.overview
                    ? _c("a", { attrs: { href: _vm.getHref() } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("landOwnerHome-title", {
                              name: _vm.overview.location.name
                            })
                          ) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" / ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col s12 m12 l12 xl8" }, [
                    _vm.overview
                      ? _c("h1", { staticStyle: { display: "inline" } }, [
                          _vm._v(_vm._s(_vm.overview.location.name))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("common-year")) + ": "),
                  _vm.overview
                    ? _c("strong", [
                        _vm._v(_vm._s(_vm.overview.project.year) + " ")
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.overview
                  ? _c("p", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("common-description")) + ":")
                      ]),
                      _vm._v(" " + _vm._s(_vm.overview.project.description))
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("section", [
              _c("div", { staticClass: "container overflow" }, [
                _c("ul", { staticClass: "tabs" }, [
                  _vm.pudId
                    ? _c(
                        "li",
                        {
                          staticClass: "tab",
                          class: {
                            active: _vm.ui.activeTab == "cam",
                            cam: true
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchTab("cam")
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { class: { active: _vm.ui.activeTab == "cam" } },
                            [
                              _c("span", { staticClass: "ion-ios-camera" }),
                              _vm._v(_vm._s(_vm.$tc("common-imageSet", 1)))
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.taskId
                    ? _c(
                        "li",
                        {
                          staticClass: "tab",
                          class: {
                            active: _vm.ui.activeTab == "aru",
                            aru: true
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchTab("aru")
                            }
                          }
                        },
                        [
                          _c(
                            "a",
                            { class: { active: _vm.ui.activeTab == "aru" } },
                            [
                              _c("span", { staticClass: "ion-ios-mic" }),
                              _vm._v(
                                _vm._s(_vm.$t("landOwnerReport-aruRecording"))
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "padded60 boxed dataGroup tab-content",
                    class: { [_vm.ui.activeTab]: true }
                  },
                  [
                    _vm.pudId &&
                    _vm.staticOptions &&
                    _vm.overview &&
                    _vm.ui.activeTab === "cam"
                      ? _c("image-gallery", {
                          attrs: {
                            uuid: _vm.userUUID,
                            pudId: _vm.pudId,
                            staticOptions: _vm.staticOptions,
                            "mega-detector-categories":
                              _vm.megaDetectorCategories
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.taskId &&
                    _vm.staticOptions &&
                    _vm.ui.activeTab === "aru"
                      ? _c("audio-log", {
                          attrs: {
                            uuid: _vm.userUUID,
                            taskId: _vm.taskId,
                            vocalizationOpt: _vm.staticOptions.vocalization
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }