var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col s12 panel boxed padded60 dataGroup tab-content" },
        [
          _c(
            "dropdown-button",
            { staticClass: "right" },
            [
              _c("template", { slot: "menuTitle" }, [
                _vm._v(_vm._s(_vm.$t("common-manage")))
              ]),
              _vm._v(" "),
              _c("template", { slot: "dropdownButtons" }, [
                _vm.tableLoaded
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-success popover-menu-item",
                        class: { downloading: _vm.downloading },
                        attrs: { download: "" },
                        on: { click: _vm.downloadLocationCSV }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _c("span", { staticClass: "spin-icon" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$refs.table.data.length > 0
                                ? _vm.$t("locationTable-downloadCSV")
                                : _vm.$t("common-downloadCsvTemplate")
                            )
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.tableLoaded
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-success popover-menu-item",
                        class: { downloading: _vm.downloadingCodes },
                        attrs: { download: "" },
                        on: { click: _vm.downloadCodes }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _c("span", { staticClass: "spin-icon" }),
                        _vm._v(_vm._s(_vm.$t("common-downloadCodes")))
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isEditable
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: _vm.tooltips.disabledUpload,
                              show: _vm.isEditable,
                              classes: "front"
                            },
                            expression:
                              "{content: tooltips.disabledUpload, show: isEditable, classes: 'front'}"
                          }
                        ]
                      },
                      [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "close-popover",
                                rawName: "v-close-popover"
                              }
                            ],
                            staticClass: "btn btn-success popover-menu-item",
                            attrs: { disabled: !_vm.isEditable },
                            on: { click: _vm.uploadLocationCSV }
                          },
                          [
                            _c("span", {
                              staticStyle: { "font-size": "20px" }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("locationTable-uploadCSV"))
                            )
                          ]
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        directives: [
                          { name: "close-popover", rawName: "v-close-popover" }
                        ],
                        staticClass: "btn btn-success popover-menu-item",
                        on: { click: _vm.uploadLocationCSV }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _vm._v(" " + _vm._s(_vm.$t("locationTable-uploadCSV")))
                      ]
                    )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _vm.isEditable
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success locationAdd",
                  on: { click: _vm.createLocation }
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }),
                  _vm._v(" " + _vm._s(_vm.$t("locationTable-addLocation")))
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isEditable
            ? [
                _vm.missingCount > 0
                  ? _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t(
                            "locationTable-tooltips.missingLatLong",
                            { count: _vm.missingCount }
                          ),
                          expression:
                            "$t('locationTable-tooltips.missingLatLong', {count: missingCount})"
                        }
                      ],
                      staticClass: "info-icon ion-android-warning"
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.tableOptionLoaded
            ? _c("v-server-table", {
                ref: "table",
                staticClass: "table vue-bordered w-full",
                attrs: {
                  url: _vm.url,
                  columns: _vm.columns,
                  options: _vm.tableOptions
                },
                on: {
                  "row-click": _vm.onRowClick,
                  loaded: function($event) {
                    _vm.tableLoaded = true
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "id",
                      fn: function(props) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass:
                                "editProject modal-trigger projectAdd",
                              on: {
                                click: function($event) {
                                  return _vm.updateLocation(props.row)
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "link",
                                class: {
                                  "ion-edit": _vm.isEditable,
                                  "ion-eye": !_vm.isEditable
                                }
                              })
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "child_row",
                      fn: function(props) {
                        return _vm.toLoadSubRow
                          ? _c("div", { staticClass: "row no-bottom-margin" }, [
                              _c(
                                "div",
                                { staticClass: "subTable col s9" },
                                [
                                  _vm._l(props.row.subDetails, function(
                                    project,
                                    index
                                  ) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          key:
                                            "subrow-" +
                                            project.projectId +
                                            "" +
                                            index,
                                          staticClass: "row link brown-color",
                                          on: {
                                            click: function($event) {
                                              return _vm.openProjectLink(
                                                project.projectId,
                                                project.sensor
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col s4 red-font" },
                                            [
                                              _vm._v(
                                                _vm._s(project.projectName) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col s4" }, [
                                            _vm._v(_vm._s(project.sensor) + " ")
                                          ]),
                                          _vm._v(" "),
                                          project.sensor === "PC"
                                            ? _c(
                                                "div",
                                                { staticClass: "col s4" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      project.projectLocationCount
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "common-survey",
                                                          project.projectLocationCount
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          project.sensor === "CAM"
                                            ? _c(
                                                "div",
                                                { staticClass: "col s4" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      project.projectLocationCount
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "common-imageSet",
                                                          project.projectLocationCount
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          project.sensor === "ARU"
                                            ? _c(
                                                "div",
                                                { staticClass: "col s4" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      project.projectLocationCount
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "common-recording",
                                                          project.projectLocationCount
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              !props.row.childRowLoading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row col s12 no-bottom-margin"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btns-wrapper right" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    content: _vm.isEditable
                                                      ? null
                                                      : _vm.$t(
                                                          "org-adminAccessRequired"
                                                        )
                                                  },
                                                  expression:
                                                    "{content: isEditable ? null: $t('org-adminAccessRequired')}"
                                                }
                                              ],
                                              staticClass: "single-btn-wrapper"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn",
                                                  class: {
                                                    "btn-success":
                                                      _vm.isEditable,
                                                    "btn-border": !_vm.isEditable
                                                  },
                                                  attrs: {
                                                    disabled: !_vm.isEditable
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openMergeLocationModal(
                                                        props.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticStyle: {
                                                      "font-size": "20px"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "locationTable-mergeLocation"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: {
                                                    content: _vm.deleteBtnTooltip(
                                                      props.row,
                                                      "location"
                                                    )
                                                  },
                                                  expression:
                                                    "{content: deleteBtnTooltip(props.row, 'location')}"
                                                }
                                              ],
                                              staticClass:
                                                "single-btn-wrapper right delete-location-btn"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn",
                                                  class: {
                                                    "btn-border": !_vm.isEditable
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      !_vm.isEditable ||
                                                      !_vm.canBeDeleted(
                                                        props.row
                                                      )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteLocation(
                                                        props.row
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", {
                                                    staticStyle: {
                                                      "font-size": "20px"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "locationTable-deleteLocation"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "location",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "55%",
            height: "80%",
            minWidth: 400,
            minHeight: 500,
            clickToClose: false
          }
        },
        [
          _vm.locationId != -1
            ? _c("location-form", {
                ref: "locationForm",
                attrs: {
                  "organization-obj": _vm.organizationObj,
                  "organization-id": _vm.organizationId,
                  "location-id": _vm.locationId,
                  "is-editable": _vm.isEditable,
                  options: _vm.options
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "merge-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "55%",
            height: "auto",
            minWidth: 400,
            minHeight: 500,
            clickToClose: false
          }
        },
        [
          _vm.mergeSourceInfo
            ? _c("merge-locations-form", {
                ref: "mergeLocationForm",
                attrs: {
                  organizationId: _vm.organizationId,
                  mergeSourceInfo: _vm.mergeSourceInfo,
                  "merge-type": "location"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "location-upload-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("upload-location-csv", {
            attrs: {
              "organization-id": _vm.organizationId,
              "organization-name": _vm.organizationObj.name,
              "visibility-options": _vm.options.visibility,
              type: "location"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog"),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }