<template>
<div class="deployment-table">
  <div class="row col s12 m9 l8 xl6 left" v-if="isManager && !(statusId === 3 || statusId === 1 || statusId === 4)">
      <multiselect :selectAllLabel="$t('common-selectAll')" v-model="randomAssignUsers" :options="userList" :multiple="true" :close-on-select="false"
        :placeholder="$t('taskTable-selectTaggersToAssign')" label="text" track-by="id" selectLabel="" deselectLabel=""
        :showSelectAll="true" class="tagger-select">
          <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{$t('taskTable-taggersSelected', {num: values.length})}}</span>
              <span class="multiselect__single" v-if="values.length && values.length == 1 && !isOpen">{{$t('singleTagger')}}</span>
          </template>
      </multiselect>
      <a class='btn btn-success btnimportant right assign-btn' :class="{'downloading':randomAssignLoading}" @click="randomAssign"><span class="spin-icon"></span>{{$t('taskTable-randomAssign')}}</a>
  </div>
  <div v-show="loading">{{$t('common-loading')}}</div>
  <div class="error">{{message}}</div>
  <v-server-table ref="table" :url="url" :columns="columns" :options="tableOptions" class="table w-full vue-bordered speciesTable"
    @loading="loading=true" @loaded="loading=false" v-show="!loading" @row-click="onRowClick">
    <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
    <template slot="speciesCommonName" slot-scope="props">
      <a :href="recordingRowUrl(props.row)">
        <span class="indicator" :class="getRowClass(props.row.status)"></span>{{props.row.speciesCommonName}}</a>
    </template>
    <template slot="status" slot-scope="props">
        {{props.row.status}}
    </template>
    <template slot="userId" slot-scope="props">
        <select v-model="props.row.userId" @focus="clearMessage" @change="updateUser(props.row.id, props.row.userId, $event)" v-if="isManager && !(statusId === 3 || statusId === 1 || statusId === 4)">
          <option value="null"> -- {{$t('taskTable-notAssigned')}} -- </option>
          <option v-for="(user, index) in userList" v-bind:key="'user' + index" :value="user.id"> {{user.text}}</option>
        </select>
        <span v-else>
          {{getUserName(props.row.userId)}}
        </span>
    </template>

  </v-server-table>
</div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/

import {API_URL, CAMERA_VERIFY_PAGE_URL, alertDialog} from '@/lib/common';
import { createLink } from '@/lib/language.js';
import { eventBus } from '@/lib/eventbus';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';

export default {
  name: 'camera-species-table',
  props: ['projectId', 'filterOptions', 'userList', 'isManager', 'statusId'],
  components: {'multiselect': Multiselect},
  methods: {
    getUserName (userId) {
      const tagger = this.userList.find(x => x.id === userId);
      return (tagger ? tagger.text : '');
    },
    recordingRowUrl: function (row) {
      return createLink(CAMERA_VERIFY_PAGE_URL + this.projectId + '&speciesId=' + row.speciesId);
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.id;
    },
    getRowClass (status) {
      try {
        return status.text ? status.text.replace(/\s/g, '-') : '';
      } catch (e) {}
      return '';
    },
    getStatus (statusObj) {
      try {
        return statusObj.text;
      } catch (e) {}
      return '';
    },
    clearMessage () {
      this.message = '';
    },
    updateUser (id, value, $event) {
      this.message = '';
      const params = {id: id, newUserId: (value === 'null' ? -1 : value)}; // deploymentId, and user Id
      this.$http.post(this.updateUserUrl, params).then((response) => {
        if (response.error) {
          this.message = this.$tc('common-error', 1) + ':' + response.error;
        } else {
          this.message = response.body.message;
        }
      }, (err) => {
        // error callback
        this.message = this.$t('taskTable-messages.updateUserError') + ' ' + err.message;
      });
    },
    randomAssignDone (msg) {
      this.randomAssignLoading = false;
      this.message = msg;
    },
    randomAssign () {
      if (!this.randomAssignUsers || this.randomAssignUsers.length === 0) {
        alertDialog(this.$modal, this.$t('taskTable-taggerEmpty'), this.$t('taskTable-mustassign'));
        return;
      }
      this.randomAssignLoading = true;
      const params = {
        projectId: this.projectId,
        userIds: this.randomAssignUsers.map(x => x.id)
      }

      this.$http.post(this.randomSpeicesAssignUserUrl, params).then(
        (response) => {
          this.randomAssignLoading = false;
          if (response.data.hasOwnProperty('error')) {
            this.randomAssignDone(response.data.error);
          } else {
            this.randomAssignDone(this.$t('taskTable-userAssignmentDone'));
            eventBus.$emit('reload-task-table-option');
            if (this.$refs.table) {
              this.$refs.table.refresh();
            }
          }
        },
        (error) => this.randomAssignDone(error)
      ).catch((error) => this.randomAssignDone(error));
    }
  },
  created () {
    const self = this;
    eventBus.$on('reload-task-table', function (show) {
      if (self.$refs.table) {
        self.$refs.table.refresh();
      }
    });
  },
  watch: {
    projectId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.url = API_URL + 'get-project-species?projectId=' + this.projectId;
      }
    }
  },
  data () {
    const pageSize = 25;
    return {
      url: API_URL + 'get-project-species?projectId=' + this.projectId,
      updateUserUrl: API_URL + 'update-project-species-user',
      message: null,
      columns: ['speciesCommonName', 'taggedNumber', 'status', 'userId'],
      loading: true,
      randomAssignLoading: false,
      randomSpeicesAssignUserUrl: API_URL + 'camera-randomly-assign-users-not-assigned-species',
      randomAssignUsers: [],
      tableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        filterable: ['speciesCommonName', 'taggedNumber', 'status', 'userId'],
        listColumns: {
          'status': this.filterOptions ? this.filterOptions.ProjectSpeciesStatus : [],
          'userId': this.userList
        },
        sortable: ['speciesCommonName', 'taggedNumber'],
        headings: {
          speciesCommonName: this.$tc('common-species', 2),
          taggedNumber: this.$t('taskTable-SPPheadings.taggedNumber'),
          status: this.$t('common-status'),
          userId: this.$t('taskTable-SPPheadings.userId')
        },
        columnsClasses: {
          status: 'status',
          userId: 'tagger'
        },
        perPage: 25,
        perPageValues: [15, 25, 50, 75, 100],
        pagination: { nav: 'fixed', edge: true }, /* nav: scroll, fixed has bug on navigation */
        customFilters: [{
          name: 'status',
          callback: function (row, query) {
            return query.code.includes(row.code);
          }
        }],
        texts: this.$t('common-tableTexts'),
        orderBy: {column: 'speciesCommonName', ascending: true},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.speciesTable select {
  margin-bottom: 0px;
}

.speciesTable tr a {
    position: relative;
}

.speciesTable tr a {
padding-left: 0px;
}

.deployment-table .multiselect {
width: 50%;
float: left;
}

.deployment-table .assign-btn {
float: right;
display: block;
width: 45%;
}

</style>
