<template>
  <div class="statWrap" :class="customClass" v-if="stats!=null">
      <template v-if="isWrapped">
        <div v-for="(row, index) in stats" v-bind:key="'wrap' + index" :class="customDivClass">
            <div class="stat">
                <span class="wrapText" v-html="getPrefixLabel(row.label)"></span>
                <span class="number">{{row.value}}</span>
                <span class="wrapText">{{getSuffixLabel(row.label)}}</span>
            </div>
        </div>
      </template>
      <template v-else>
        <div v-for="(row, index) in stats" v-bind:key="index" class="stat">
            <span class="wrapText" v-html="getPrefixLabel(row.label)"></span>
            <span class="number">{{row.value}}</span>
            <span class="wrapText">{{getSuffixLabel(row.label)}}</span>
        </div>
      </template>
  </div>
</template>

<script>
/* this component show the stats box for pages: projects, tasks
*/
import {attachAuthorizaitonHeader} from '@/lib/common'
import {eventBus} from '@/lib/eventbus';
export default {
  name: 'stats-box',
  props: {
    url: { type: String },
    customClass: { type: String, default: '' },
    customDivClass: { type: String, default: '' },
    isWrapped: {
      type: Boolean, default: false
    }
  },
  watch: {
    url: function (oldVal, newVal) {
      this.loadStats();
    }
  },
  created () {
    attachAuthorizaitonHeader();
    this.loadStats();
  },
  methods: {
    loadStats: function () {
      this.$http.get(this.url).then(
        function (response) {
          // if(response.data.hasOwnProperty('error')){
          // can get error: 'invalid user to see organization info.' when theres no stats to display
          // }
          // else{
          if (response.data && !response.data.hasOwnProperty('error')) {
            this.stats = response.data;
            eventBus.$emit('show-stats');
            (this.stats || []).forEach((stat) => {
              if (stat.showWarning) {
                eventBus.$emit(stat.label, stat.value);
              }
            })
          }
          // }
        });
    },
    getPrefixLabel: function (fullLabel) {
      let label = fullLabel.substring(0, fullLabel.indexOf('%s'));
      return label.trim().length > 0 ? label : '<br />';
    },
    getSuffixLabel: function (fullLabel) {
      return fullLabel.substring(fullLabel.indexOf('%s') + 2);
      let label = val.trim().match(/(\w*)/)[0];
      return this.$tc(`common-${label.toLowerCase()}`, 2);
    }
  },
  data () {
    return {
      stats: null
      // labels: {
      //   'completedTasks': ['Completed', 'Task(s)'],
      //   'assignedTasks': ['You Have', 'Open Task(s)'],
      //   'distinctSpeciesIdentified': ['Identified', 'Species'],
      //   'percentComplete': ['Completed', 'Task(s)']
      // }
    }
  }
}
</script>
<style scoped>
.userPanel .userWelcome .statWrap .stat {
    width: 120px;
    margin-right: 10px;
    padding: 10px;
}
</style>
