var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "fov-preview-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroller",
            draggable: ".drag-handle",
            delay: 100,
            width: 700,
            height: 700,
            minWidth: 200,
            minHeight: 200,
            clickToClose: false
          }
        },
        [
          _c(
            "div",
            { staticClass: "iconButton link", on: { click: _vm.closeForm } },
            [_c("span", { staticClass: "ion-close" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-header drag-handle" }, [
            _c("h6", [_vm._v(_vm._s(_vm.$t("fovPreview-updatePreview")))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-scroller" },
            [
              _vm.fovResult && _vm.fovResult.length > 0
                ? _c("div", { staticClass: "message-row" }, [
                    _vm._v(_vm._s(_vm.$t("fovPreview-pleaseReview")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.fovResult, function(item, index) {
                return _c(
                  "div",
                  { key: "fov" + index, staticClass: "message-row" },
                  [
                    item.errorMessage
                      ? _c("div", [
                          _c(
                            "span",
                            { staticClass: "red-font ion-android-warning" },
                            [_vm._v(" " + _vm._s(item.errorMessage))]
                          )
                        ])
                      : _c("div", [
                          _c("span", {
                            staticClass: "red-font ion-android-checkmark-circle"
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("fovPreview-changing"))
                          ),
                          _c("span", { staticClass: "orange-font" }, [
                            _vm._v(" " + _vm._s(item.count))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "orange-font" }, [
                            _vm._v(_vm._s(item.action))
                          ])
                        ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("fovPreview-startTime")) + ":")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "blue-font" }, [
                        _vm._v(" " + _vm._s(item.startTime))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("fovPreview-endTime")) + ":")
                      ]),
                      _c("span", { staticClass: "blue-font" }, [
                        _vm._v(_vm._s(item.endTime))
                      ])
                    ])
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12 error padded20" }, [
                  _vm._v("\n          " + _vm._s(_vm.message) + "\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.hasError,
                        expression: "!hasError"
                      }
                    ],
                    staticClass: "col s12 m6 center"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btnimportant btn-success",
                        on: { click: _vm.verifyFOV }
                      },
                      [_vm._v(_vm._s(_vm.$t("fovPreview-verifyFov")))]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasError,
                        expression: "hasError"
                      }
                    ],
                    staticClass: "col s12 center"
                  },
                  [
                    _c("p", { staticClass: "error" }, [
                      _vm._v(_vm._s(_vm.$t("fovPreview-pleaseFixErrors")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col s12 center",
                    class: { m12: _vm.hasError, m6: !_vm.hasError }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btnimportant btn-success",
                        on: { click: _vm.closeForm }
                      },
                      [_vm._v(_vm._s(_vm.$t("common-close")))]
                    )
                  ]
                )
              ])
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }