<template>
  <div class="form">
    <modal 
      :name="formName"
      :scrollable="true" 
       :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form"
   
      :delay="100"
      :resizable="false"
      width = "95%"
      height = "90%"
      :minWidth="400"
      :top="0"
      :clickToClose = "false"
      ref="tagForm"
      @resize="getModalWidth">
      <div id="tagging-form" class="h-full relative">
        
        

        <div class="modal-scroller !h-[100vh]" ref="scroller">
         
          <div class="modal-content container image-tagging !w-full  p-1 !max-w-full flex !h-max overflow-x-hidden" v-if="options.allSpecies!=null && localTag != null" >
            
            <div class="w-full max-h-fit">
            <div v-if="loading">
              <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
              <div>{{$t('cameraTagging-updating')}}</div>
            </div>
            <span @click="error=[]; validationMsg=[]; message=[];" v-show="error.length > 0 || message.length > 0 || validationMsg.length > 0" class="link red-font">
              {{$t('cameraTagging-clearMessages')}}
              <span class="ion-android-close" ></span>
            </span>
            <div>
              <template v-for="(msg, index) in message">
                <div class="message" :key="'msg' + index"> {{msg}}</div>
              </template>
            </div>
            <div class="row">
              <template v-for="(msg, index) in error">
                <div class="error" :key="'error' + index"> {{msg}}</div>
              </template>
              <template v-for="(msg, index) in validationMsg">
                <div class="validation" :key="'validation' + index"> {{msg}}</div>
              </template>
            </div>
            <template v-for="(tag, index) in localTag.speciesTags">
              <div  v-if="!tag.isLocked" class="row "
                :class="{'!p-1 block  border-b border-b-gray-400': true,  'even': index%2 ==0, 'odd':index%2==1, 'batch-update': tag.hasOwnProperty('isLocked') && isBatchSelected}"
                :key="'tagRow' + index">

                <div class="flex">
                  <div class="flex-initial">
                    <div class="flex grid grid-cols-5 gap-0.5">
                      <!--- SPECIES DROPDOWN -->
                      <div class="flex-initial flex-grow">
                        <label class="overflow-hidden whitespace-nowrap block">{{$tc('common-species', 1)}}</label>
                        <multiselect :selectAllLabel="$t('common-selectAll')" v-model="tag.species" :options="options.allSpecies" :multiple="false" :close-on-select="true"
                          :disabled="localTag.isLocked" :placeholder="$tc('common-species', 1)" label="commonName" track-by="id" selectLabel="" deselectLabel=" " 
                          :class="{'input border-b border-gray-200':true, 'overflow-hidden':!tag.ui.dropdownStates.species}"
                          :get-option-class="getSpeciesOptClass"
                          @open="()=>{tag.ui.dropdownStates.species=true;}"
                          @close="()=>{tag.ui.dropdownStates.species=false}"
                          @input="e=>speciesInput(e,index)">
                          <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc('common-speciesSelected', values.length, {num: values.length}).toLowerCase() }}</span>
                          </template>
                        </multiselect>
                      </div>

                      <!-- GROUP -->
                      <div class="grid grid-cols-3 gap-2 col-span-2" v-if="project.useIndividualCounts || project.useSpeciesAge || project.useSpeciesSex"> 
                        <!-- INDIVIDUAL NUM TEXTBOX -->
                        <div v-if="project.useIndividualCounts" >
                          <label class="overflow-hidden whitespace-nowrap block">{{$t('Count')}}</label>
                          <input class="individual w-full" :placeholder="$t('Count')" :class="getClass(tag.species, 'count')"
                            :readonly ="localTag.isLocked"
                            :disabled="localTag.isLocked"
                            min="0"
                            @input="tag.hasChanged = true"
                            v-model="tag.individualCount"/>
                        </div>

                        <template v-for="(attributes, name) in attributesOptions">
                          <div :key="name" class="flex-initial flex-grow" v-if="project[attributes.enabledKey]">
                            <label class="overflow-hidden whitespace-nowrap block">{{name}}</label>
                            <multiselect :selectAllLabel="$t('common-selectAll')" v-model="tag[attributes.key]" :options="options[name]" :multiple="attributes.multiple" :close-on-select="attributes.closeOnSelect"
                            :placeholder="$t(`taggingFilter-${name}`)" label="type" track-by="id" selectLabel="" deselectLabel=" " class="input"
                            @input="(e)=>{ tag[attributes.key+'Id']=e?e.id:null; tag.hasChanged = true;}"
                            @open="()=>{tag.ui.dropdownStates[attributes.key]=true;}"
                            @close="()=>{tag.ui.dropdownStates[attributes.key]=false}"
                            :class="{'border-b border-gray-200':true, 'overflow-hidden ':!tag.ui.dropdownStates[attributes.key]}" :disabled="localTag.isLocked">
                              <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc(`taggingFilter-num-${name}`, values.length, {num: values.length}) }}</span>
                              </template>
                            </multiselect>
                          </div>
                        </template>

                      </div>

            
              
                      <!-- TAG OPTIONS DROPDOWN LOOP -->
                      <!-- <template v-for="(attributes, name) in tagOptions"> -->

                        
                        <div class="flex-initial flex-grow" v-if="project.useSpeciesBehaviour">
                          <label class="overflow-hidden whitespace-nowrap block">{{ $t('Behaviour')}}</label>
                          <multiselect 
                          :selectAllLabel="$t('common-selectAll')" 
                          v-model="tag.behaviours" 
                          :options="localOptions.speciesBehaviour" 
                          :multiple="true" 
                          :placeholder="$t('Behaviour')" 
                          :label="'type'"
                          :close-on-select="false"
                          :track-by="'behaviourId'" 
                          @input="tag.hasChanged=true"
                          :class="tagOptionClasses('speciesBehaviour',tag)"
                          :limit="3"
                          :limit-text="(count)=>`+${count}`"
                          @open="()=>{tag.ui.dropdownStates.speciesBehaviour=true;}"
                          @close="()=>{tag.ui.dropdownStates.speciesBehaviour=false}"
                          ></multiselect>
                        </div>

                        <div class="flex-initial flex-grow" v-if="project.useSpeciesHealth">
                          <label class="overflow-hidden whitespace-nowrap block">{{$t('Health')}}</label>
                          <multiselect 
                          :selectAllLabel="$t('common-selectAll')" 
                          v-model="tag.healthConditions" 
                          :options="localOptions.health" 
                          :multiple="true" 
                          :placeholder="$t('Health')" 
                          :label="'type'"
                          :close-on-select="false"
                          :track-by="'healthId'" 
                          @input="tag.hasChanged=true"
                          :class="tagOptionClasses('healthConditions',tag)"
                          :limit="3"
                          :limit-text="(count)=>`+${count}`"
                          @open="()=>{tag.ui.dropdownStates.healthConditions=true;}"
                          @close="()=>{tag.ui.dropdownStates.healthConditions=false}"
                          ></multiselect>
                        </div>
                       </div>
                       <div class="flex grid grid-cols-6 gap-0.5">

                         <div class="flex-initial flex-grow" v-if="project.useSpeciesDirection">
                          <label class="overflow-hidden whitespace-nowrap block">{{$t('Direction')}}</label>
                          <multiselect 
                          :selectAllLabel="$t('common-selectAll')" 
                          v-model="tag.direction" 
                          :options="localOptions.speciesDirection" 
                          :multiple="false" 
                          :placeholder="$t('Direction')" 
                          :customLabel="(e)=>`${e} ${$t('-o- Clock')}`"
                          :close-on-select="true"
                          @input="tag.hasChanged=true"
                          :class="tagOptionClasses('direction',tag)"
                          :limit="3"
                          :limit-text="(count)=>`+${count}`"
                          @open="()=>{tag.ui.dropdownStates.direction=true;}"
                          @close="()=>{tag.ui.dropdownStates.direction=false}"
                          ></multiselect>
                        </div>
                         <div class="flex-initial flex-grow" v-if="project.useSpeciesCoatColor">
                          <label class="overflow-hidden whitespace-nowrap block">{{$t('Coat Colour')}}</label>
                          <multiselect 
                          :selectAllLabel="$t('common-selectAll')" 
                          v-model="tag.coatColors" 
                          :options="localOptions.coatColor" 
                          :multiple="true" 
                          :placeholder="$t('Coat Colour')" 
                          :label="'type'"
                          :close-on-select="false"
                          :track-by="'coatColorId'" 
                          @input="tag.hasChanged=true"
                          :class="tagOptionClasses('coatColors',tag)"
                          :limit="3"
                          :limit-text="(count)=>`+${count}`"
                          @open="()=>{tag.ui.dropdownStates.coatColors=true;}"
                          @close="()=>{tag.ui.dropdownStates.coatColors=false}"
                          ></multiselect>
                        </div>
                        <div class="flex-initial flex-grow" v-if="project.useSpeciesCoatAttributes">
                          <label class="overflow-hidden whitespace-nowrap block">{{$t(`taggingFilter-coatAttribute`)}}</label>
                          <multiselect 
                          :selectAllLabel="$t('common-selectAll')" 
                          v-model="tag.coatAttributes" 
                          :options="localOptions.coatAttribute" 
                          :multiple="true" 
                          :placeholder="$t(`taggingFilter-coatAttribute`)" 
                          :label="'type'"
                          :close-on-select="false"
                          :track-by="'coatAttributeId'" 
                          @input="tag.hasChanged=true"
                          :class="tagOptionClasses('coatAttributes',tag)"
                          :limit="3"
                          :limit-text="(count)=>`+${count}`"
                          @open="()=>{tag.ui.dropdownStates.coatAttributes=true;}"
                          @close="()=>{tag.ui.dropdownStates.coatAttributes=false}"
                          ></multiselect>
                        </div>

                      <!-- </template> -->

                      <div class="relative overflow-hidden" v-if="project.useSpeciesTineTracking" >
                        <label>{{$t('Tines')}}</label>
                        <div @click="()=> {if(!localTag.isLocked)tineClick(index)}" :class="{'cursor-pointer': !localTag.isLocked, 'relative bg-white border-gray-200 border rounded p-2 absolute z-20 w-full tinedropdown max-h-[39px] overflow-hidden':true}" ref="tineDropdown" >
                          
                          <div class="flex z-20"> 
                            <span  class=" flex-1">
                              <span v-if="!tag.tines || tag.tines.length==0" class="text-sm">{{$t('No tines. Click to add.')}}</span>
                              
                              <span v-else>
                                <template  v-for="(tine, tineIndex) in tag.tines">
                                  <span :key="tineIndex" :class="{'rounded-md  text-sm text-white py-0.5 pl-2 pr-1 ml-1 font-light':true, 'bg-yellow-400/60':localTag.isLocked, 'bg-yellow-400':!localTag.isLocked}">
                                      <span v-html="formatTineLabel(tag.tines[tineIndex])"></span>
                                      <span @click.stop="if(!localTag.isLocked) removeTine(tineIndex, index)" :class="{'-mr-1  text-gray-600  rounded-md py-0.5 px-2 text-center':true, 'cursor-pointer hover:bg-emerald-600 hover:text-white': !localTag.isLocked}">
                                        <i class="fa-solid fa-xmark text-xs" ></i>
                                      </span>
                                  </span>
                                </template>
                              </span>
                              
                            </span>
                          </div>
                          <!-- <div v-if="localTag.isLocked" class="bg-gray-100/75 absolute top-0 left-0 right-0 bottom-0 z-10"></div> -->
                          
                          <div class="absolute right-[15px] top-[15px] text-sm" v-if="!localTag.isLocked">
                            <i class="fa-solid fa-caret-down"></i>
                          </div>
                          
                        
                        </div>
                      </div>
                      <div class="grid grid-cols-2 gap-2 mt-6">
                       
                        <div class="flex-initial overflow-hidden text-ellipsis" v-if="project.useSpeciesHasCollar">
                          <label class=" flex items-start">
                            <input :disabled="localTag.isLocked" type="checkbox" v-model="tag.hasCollar" @change="tag.hasChanged=true" />
                            <span></span>
                            <div>{{$t('Collar')}}</div>
                          </label>
                          <label class=" flex items-start">
                            <input :disabled="localTag.isLocked" type="checkbox" v-model="tag.hasTag" @change="tag.hasChanged=true" />
                            <span></span>
                            <div>{{$t('Tag')}}</div>
                          </label>
                        </div>
                    
                        <div class="flex-initial overflow-hidden ">
                          <label :for="'reviewed' + index" :class="getClass(tag.species, 'age')" class="need-review flex items-start">
                            <input :disabled="localTag.isLocked" type="checkbox" :id="'reviewed' + index" name="review" v-model="tag.needsReview" @change="tag.hasChanged = true">
                            <span></span>
                            <div :class="{inactive: !isEditable}">{{$t('cameraTagging-review')}}</div>
                          </label>
                          
                          <label v-if="project.useSpeciesInteractingWithHumanFeature" class="overflow-hidden block flex items-start">
                            <input :disabled="localTag.isLocked" type="checkbox" v-model="tag.interactingWithHumanFeature" @change="tag.hasChanged=true" />
                            <span></span>
                            <div>{{$t('IHF')}}</div>
                          </label>
                        
                        </div>
                      </div>
                    
                     
                      <div class="" v-if="isEditable">
                        <label>{{$t('cameraTagging-comments')}}</label>
                          <input  @input="tag.hasChanged = true" :disabled="localTag.isLocked" :placeholder="$t('cameraTagging-comments')" class="tag-comments mt-1" rows ="1"  v-model="tag.comments"/>
                      </div>
                    
                    </div>
                   
                  </div>
                   <div class="flex flex-1 flex-col ml-1">
                      <label>
                        <i v-if="localTag.isLocked" class="fa-sharp fa-solid fa-lock text-xl mx-auto"></i>
                      </label>
                      <template v-if="!localTag.isLocked">
                        <!-- <span class="!text-xs !font-bold btn btn-success rounded !px-2 !py-1" v-if="tag.hasChanged">*</span> -->
                        <button @click="deleteTag(index)" class="btn btn-error !px-2 !py-1 !text-xs" v-if="!isBatchSelected" :title="$t('cameraTagging-deleteTag')"><i class="fa-solid fa-trash !text-xs"></i></button>

                        <!-- <span @click="batchDeleteTag(tag, index)" class="ion ion-android-delete link" :title="$t('cameraTagging-deleteTag')" v-if="isBatchSelected && tag.isLocked === false">
                          <span class="ion ion-android-delete link" :title="$t('cameraTagging-deleteTag')" style="font-size:14px"></span>
                        </span> -->
                        <button @click="batchDeleteTag(tag, index)" class="btn btn-error  !px-2 !py-1  !text-xs" v-if="isBatchSelected && tag.id"><i class="fa-solid fa-trash !text-xs"></i></button>
                        <button @click="deleteTag(index)" class="btn btn-error  !px-2 !py-1  !text-xs" v-if="isBatchSelected && !tag.id"><i class="fa-solid fa-trash !text-xs"></i></button>
                        <button @click="duplicateTag(tag)" class="btn btn-border  !px-2 !py-1 !text-xs" :class="getClass(tag.species, 'age')" :title="$t('cameraTagging-duplicateTag')"><i class="fa-solid fa-copy !text-xs"></i></button>

                      </template>
                    </div>
                </div>

              </div>
            </template>

  <!-- ADD TAG BUTTON -->

            <div class="flex row even p-1" v-if="isEditable && !loading" v-show="hasTag">
                  <div class="flex-grow"></div>
              <a class="btn btn-success" @click="() => {if (!localTag.isLocked) addTag()}" :disabled="localTag && localTag.isLocked">+ Add Tag</a>
          
  <!-- TASK BOX -->
              
            </div>
            <div class="flex grid grid-cols-8 gap-3 bg-gray-200 p-3" >
             
              <div class="flex-initital col-span-2" v-if="project.useFieldOfView">
            
                <div :class="{'col s12 m8': isBatchSelected}">
                  <label class="overflow-hidden whitespace-nowrap block">{{$t('Field of View')}}</label>
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="localTag.task.fovId" :options="options.fieldOfView" :multiple="false" :close-on-select="true"
                    :disabled="localTag.isLocked || !localTag.canUpdateFOV"
                    :placeholder="$t('cameraTagging-fieldOfView')" label="type" track-by="id" selectLabel="" deselectLabel=" "
                    :class="{'input':true, 'overflow-hidden': !ui.task.dropdownStates.fieldOfView}"
                    @input="hasChanged=true"
                    @open="()=>{ui.task.dropdownStates.fieldOfView=true;}"
                    @close="()=>{ui.task.dropdownStates.fieldOfView=false}"
                    >
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc('taggingFilter-numFieldOfViews', values.length, {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                  <!-- <select name="category" v-model="localTag.task.fov" required="true" class="modified-select">
                    <option :value="null" disabled>Field Of View</option>
                    <option v-for="opt in options.fieldOfView" :value="opt.id" :key="'spp' + opt.id">{{opt.type}}
                    </option>
                  </select>  -->
                </div>
              </div>
                   <div v-if="project.useSnowDepth">
                    <label class="overflow-hidden whitespace-nowrap block">{{$t('Snow Depth (m)')}} </label>
                        <input type="number" class="individual w-full" :placeholder="$t('Snow Depth')" :disabled="localTag.isLocked || !localTag.canUpdateSnowDepth"
                          :readonly ="!isEditable"
                          min="0"
                          v-model="localTag.task.snowDepthM"/>
                         
              </div>
              <div v-if="project.useWaterDepth">
                <label class="overflow-hidden whitespace-nowrap block">{{$t('Water Depth (m)')}} </label>
                        <input type="number" class="individual w-full" :placeholder="$t('Water Depth')" :disabled="localTag.isLocked || !localTag.canUpdateWaterDepth"
                          :readonly ="!isEditable"
                          min="0"
                          v-model="localTag.task.waterDepthM"/>
                         
              </div>
              <div class="flex-initial" v-if="project.useSnowTracking">
                <label>&nbsp;</label>
                        <label class="overflow-hidden whitespace-nowrap block flex items-start">
                          <input type="checkbox" v-model="localTag.task.hasSnowOnGround" :disabled="localTag.isLocked || !localTag.canUpdateHasSnowOnGround" />
                          <span></span>
                          <div>{{$t('Snow')}}</div>
                        </label>
              </div>
              <div class="flex-initial" v-if="project.useNice">
                <div >
                    <label>&nbsp;</label>
                  <label class="overflow-hidden whitespace-nowrap block flex items-start" for="featured">
                    <input type="checkbox" id="featured" name="featured" :disabled="localTag.isLocked ||  !localTag.canUpdateNice" v-model="localTag.task.nice">
                    <span></span>
                    <div :class="{inactive: !isEditable}">{{$t('common-nice')}}</div>
                  </label>
                </div>
               
              </div>
              <div class="flex-initial" v-if="project.useFire">
                <div >
                  <label>&nbsp;</label>
                  <label class="overflow-hidden whitespace-nowrap block flex items-start" for="fire">
                    <input type="checkbox" id="fire" name="fire" :disabled="localTag.isLocked ||  !localTag.canUpdateFire" v-model="localTag.task.fire">
                    <span></span>
                    <div :class="{inactive: !isEditable}">{{$t('cameraTagging-fire')}}</div>
                  </label>
                </div>
              </div>
              <div class="flex-initial">
                <div>
                  <label>&nbsp;</label>



                  <label class="overflow-hidden whitespace-nowrap block flex items-start" for="isMalfunction">
                    <input type="checkbox" id="isMalfunction" name="isMalfunction" :disabled="localTag.isLocked ||  !localTag.canUpdateMalfunction" v-model="localTag.task.malfunction ">
                    <span></span>
                    <div :class="{inactive: !isEditable}">{{$t('cameraTagging-malfunction')}}
                        <span class="info-icon ion-information-circled" v-tooltip="tooltips.malfunction"></span>
                    </div>
                  </label>
<!-- 
                    <label class="flex items-start">
                      <input type="checkbox" v-model="project[item.name]" />
                      <span/>
                        <div>
                          {{item.label}}
                          <span class="info-icon ion-information-circled" v-tooltip ="item.tooltip"></span>
                        </div>
                    </label> -->

                </div>
              </div>
              <div class="col-span-7">
                <label v-if="isEditable" >{{$t('Comments')}}:
                    <input  v-model="localTag.task.comments" :disabled="localTag.isLocked || !localTag.canUpdateComments" />
                </label>
                <div v-if="!isEditable && localTag.task.comments">{{$t('cameraTagging-comments')}}: <span v-if="!isEditable">{{localTag.task.comments}}</span></div>
              </div>
            </div>
            </div>
            <div class="!min-w-[25%] relative p-1   h-[99vh] overflow-x-hidden">
              
              <div class="bg-white w-full h-14">
                <div @click="closeForm" class="iconButton link" :class="{disabled: loading}">
                  <span class="ion-close"></span>
                </div>

                <div class="pt-4" >
                  <span v-if="id.length">{{id.length}}</span><span v-else>1</span> IMAGE<span v-if="this.id.length">S</span>
                </div>
              </div>
              
              <div class="!sticky top-0">
                <template v-if="id.length">
                  <template v-for="(value, index) in imageList">
                    <img v-if="id.indexOf(value.task.id)!=-1"  :key="index" class="cursor-pointer min-w-[25%] mb-2" @click.stop.prevent="showLightbox(value.fileURL)" :src="value.fileThumbURL" />
                  </template>
                </template>
                <img class="cursor-pointer" style="min-width: 25%"  v-else @click.stop.prevent="showLightbox(imageList.find(i=>i.task.id==id).fileURL)" :src="imageList.find(i=>i.task.id==id).fileThumbURL" />
                <!-- <div v-if="id.length>1" class="flex mt-1 items-center">
                  <button class="btn btn-border" @click="()=> { if(ui.gallery.currentIndex-1>=0) ui.gallery.currentIndex--;}"> <i class="fa-solid fa-backward"></i></button> 
                  <div class="flex-grow text-center">Image {{ui.gallery.currentIndex+1}} of {{id.length?id.length:1}}</div>
                  <button class="btn btn-border" @click="()=>{ if (ui.gallery.currentIndex+1<id.length) ui.gallery.currentIndex++; }"> <i class="fa-solid fa-forward"></i></button> 
                </div>     -->
              </div>
              
            </div>
          
          </div>
          </div>
        <div class="flex p-2 gap-1 absolute bottom-0 right-0 w-full bg-white items-center" >
            
            <div class="flex-grow"></div>
            <div v-show="hasFormModified" class="text-red-500 font-bold mr-5">You have unsaved changes</div>
            <button class="btn btn-success !mb-0 !px-10" @click="save" :disabled="localTag && localTag.isLocked || !hasFormModified ">{{$t('Save')}}</button>
            <button class="btn btn-border modal-action modal-close !px-10 !mb-0" :class="{disabled: loading}" id="cancelAdd" @click="closeForm()">{{$t('common-close')}}</button>
        </div>
      </div>
    </modal>

    <tine-modal
      name="tineModal"
      :tines="selectedTagTines"
      :tagIndex="selectedTagIndex"
      :options="options"
      @tine-update="storeTineData"
    ></tine-modal>

    <lightbox v-if="imageList" 
      id="lightbox2" 
      ref="lightbox2"
      v-bind:images="generateLightboxImageObjects()"
      v-bind:mega-detector-categories="megaDetectorCategories"
      :timeoutDuration="10000" 
      v-bind:can-download="isEditable" 
      :z-index="1000"
      :project="project">
      </lightbox>

  </div>
</template>
<script>
/* this component show update and batch upload from
update single image: user enters all informaiton and click submit button to save all
update batch images:
  first: pass in id array,
  then update tags one by one,
  and task information one by one.
*/
import {API_URL, confirmDialog, confirmDialog3Button, alertDialog, copyObject, compareObject, createLightboxImageObject} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import TineModal from './tineModalForm.vue';
import Lightbox from '@/components/utils/lightbox.vue';

export default {
  name: 'camera-tagging-form',
  props: {
    id: { type: [Number, String, Array], required: true },
    formName: {type: String},
    options: {type: Object},
    forUntagged: {
      type: Boolean, default: false
    },
    deploymentId: {type: [Number, String]},
    isEditable: {
      type: Boolean,
      default: false
    },
    filterMode: {
      type: String,
      default: 'tag'
    },
    project: {type: Object},
    imageList: {type: Array},
    megaDetectorCategories:{type:Array}
  },
  components: {'multiselect': Multiselect, 'pulse-loader': PulseLoader, 'tineModal': TineModal,'lightbox': Lightbox,},
  created () {
    window.taggingFormContext = this;
    
    if (this.id != null) {
      // check if it is batch operation or not
      if (Array.isArray(this.id) && this.id.length > 1) {
        this.isBatchSelected = true;
      } else {
        this.isBatchSelected = false;
      }
     // if (!this.forUntagged) { // don't load data for untagged.
        this.loadTagDetails(this.id);
      // } else { // for all untagged
      //   this.localTag = {speciesTags: [], task: {id: this.id}}; // initial value
      //   this.orgTag = {speciesTags: [], task: {id: this.id}}; // initial value
      //   this.addBlankTagRow();
      // }
    }

    this.localOptions = copyObject(this.options);

    this.localOptions.speciesDirection.forEach((e,i)=>{
      let id = e.id;
      this.localOptions.speciesDirection[i] = id;
    });

    this.localOptions.speciesBehaviour.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.behaviourId = e.id;
      delete temp.id;
      this.localOptions.speciesBehaviour[i] = temp;
    });

    this.localOptions.coatColor.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.coatColorId = e.id;
      delete temp.id;
      this.localOptions.coatColor[i] = temp;
    });
    this.localOptions.coatAttribute.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.coatAttributeId = e.id;
      delete temp.id;
      this.localOptions.coatAttribute[i] = temp;
    });
    this.localOptions.health.forEach((e,i)=>{
      let temp = copyObject(e);
      temp.healthId = e.id;
      delete temp.id;
      this.localOptions.health[i] = temp;
    });

    this.localOptions.countAttribute = copyObject(this.options.tineCountAttribute);
    delete this.localOptions.tineCountAttribute;

    this.localOptions.countAttribute.forEach((e,i)=> {
      let temp = copyObject(e);
      temp.countAttributeId = temp.id;
      this.localOptions.countAttribute[i] = temp;
    });

    this.localOptions.tineProperty.forEach((e,i)=> {
      let temp = copyObject(e);
      temp.tinePropertyId = temp.id;
      this.localOptions.tineProperty[i] = temp;
    });

  },
  mounted () { // manually open modal box when mounted
    this.$modal.show(this.formName);
    this.modalWidth = window.document.body.clientWidth * 0.65;
  },
  watch: {
    localTag: {
      handler (newVal, oldVal) {
        if (newVal == null) {
          return;
        }
        if (oldVal == null) { // when it is inital load setting
          this.formModified(false);
          return;
        }
        if (this.isBatchSelected) {
          // console.log('newVal =', newVal.speciesTags, 'orgTag',this.orgTag.speciesTags)
          if (compareObject(newVal.speciesTags, this.orgTag.speciesTags)) { // when new value equals orginal tag, it is not modified
            this.formModified(false);
            return;
          }
        } else {
          // console.log('newVal = all', newVal, 'orgTag',this.orgTag)
          if (compareObject(newVal.task, this.orgTag.task) && compareObject(newVal.speciesTags, this.orgTag.speciesTags, ['showComments', 'hasChanged'])) { // when new value equals orginal tag, it is not modified
            this.formModified(false);
            return;
          }
        }
        this.formModified(true);
      },
      deep: true
    }
  },

  methods: {
    async loadTagDetails () { // load tag details, can be batch load or single load
      let response;
      try {
        if (this.isBatchSelected) {
          response = await this.$http.post(this.getBatchTagAPI, {taskIds: this.id});
        } else {
          response = await this.$http.get(this.getTagAPI, {params: {taskId: this.id}});
        }
      } catch (err) {
        this.error = err;
        // this.orgTag = {};
        // this.localTag = {};
        this.addBlankTagRow();
        return;
      }
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
        // this.orgTag = {};
        // this.localTag = {};
        this.addBlankTagRow();
        return;
      }
      this.copyTag(response.data);
      if (!this.orgTag.speciesTags || this.orgTag.speciesTags.length === 0) { // put in default blank values
        this.addBlankTagRow();
      } else if (this.isBatchSelected) {
        if (!this.orgTag.speciesTags.length) { // to check if there are any common tags, if not, add blank row,
          this.addBlankTagRow();
        }
      }
      

        this.localTag.task.fovId = this.localOptions.fieldOfView.find(fov=>fov.id==this.localTag.task.fovId);

        this.localTag.speciesTags.forEach((speciesTag,i)=>{
           this.$set(this.localTag.speciesTags, i, this.speciesTagDecorator(speciesTag,true));
        });
      
   
    },
    tagOptionClasses(name,tag) {
      return {'':true, 'overflow-hidden':!tag.ui.dropdownStates[name]};
    },

    showLightbox (imgUrl) {
      this.$refs.lightbox2.show(imgUrl);
    },
    generateLightboxImageObjects() {
      if (this.id.length) {
        return this.imageList.map(i=>createLightboxImageObject(i));
        return this.id.map(id=>this.imageList.find(i=>i.task.id==id)).map(i=>createLightboxImageObject(i));
      }
      
      return [createLightboxImageObject(this.imageList.find(i=>i.task.id==this.id))];
    },
    /**
     *  try to find where form gets marked modified use this instead. Combination getter/setter.
     */
    formModified(flag = null) {
      if (flag === null) return this.hasFormModified;
      this.hasFormModified = flag;
      return flag;
    },
    speciesInput(e, tagIndex) {
      let tag = this.localTag.speciesTags[tagIndex];
      this.localTag.speciesTags[tagIndex].speciesId=e?e.id:null; tag.hasChanged = true;
      
      // Default wild animals to adult unknown
      if (e.className == 'MAMMALIA' && !e.commonName.match(/domestic/i)) {
          this.localTag.speciesTags[tagIndex].sexId = 1;
          this.localTag.speciesTags[tagIndex].ageId = 1;
          this.localTag.speciesTags[tagIndex].age = this.options.age.find(x=> x.id == this.localTag.speciesTags[tagIndex].ageId);
          this.localTag.speciesTags[tagIndex].sex = this.options.sex.find(x=>x.id==this.localTag.speciesTags[tagIndex].sexId);
          this.localTag.speciesTags[tagIndex].individualCount = 1;
      } else {
          this.localTag.speciesTags[tagIndex].sexId = 4;
          this.localTag.speciesTags[tagIndex].ageId = 0;
          this.localTag.speciesTags[tagIndex].age = this.options.age.find(x=> x.id == this.localTag.speciesTags[tagIndex].ageId);
          this.localTag.speciesTags[tagIndex].sex = this.options.sex.find(x=>x.id==this.localTag.speciesTags[tagIndex].sexId);
          this.localTag.speciesTags[tagIndex].individualCount = 'VNA';
      }
    }
    ,
    copyObject(obj) {
      return copyObject(obj);
    },
    /**
     *  Combine batchSaveall submitForm and batchUpdateTaskProperties all into one button.
     */
    save() {
      if (this.isBatchSelected) {
        this.batchUpdateTaskProperties().then(()=> {
          this.batchSaveAll(true);
        });
      } else {
        this.submitForm();
      }
    },
    removeTine(tineIndex, tagIndex) {
            let temp = [...this.localTag.speciesTags[tagIndex].tines];
            temp.splice(tineIndex, 1);
            this.localTag.speciesTags[tagIndex].tines = temp; 
    },
    storeTineData(event) {
      if (typeof this.localTag.speciesTags[event.tagIndex] == 'undefined') 
        this.$set(this.localTag.speciesTags, event.tagIndex, {tines: []});
        let data = event.localTineData.map((tine, index) => {
        
        tine.tinePropertyId = tine.tineProperty.id;
        tine.countAttributeId = tine.countAttribute.id;
        tine.tagId = this.localTag.speciesTags[event.tagIndex].id;
      
      });

      
      this.$set(this.localTag.speciesTags[event.tagIndex], 'tines', event.localTineData);

    },
    formatTineLabel(tine) {
      let tineCountAttribute = '';
      let tineProperty = '';
      
        switch(tine.tinePropertyId) {
          case 1: case 4: tineProperty = tine.tineProperty.type; break;
          case 2: tineProperty = this.$t('Left'); break;
          case 3: tineProperty = this.$t('Right'); break;

        } 

        switch(tine.countAttributeId) {
          case 1: tineCountAttribute = '='; break;
          case 2: tineCountAttribute = '>='; break;
          case 3: tineCountAttribute = '~='; break;
          case 4: tineCountAttribute = '?='; break;
        }

      let count = tine.tineCount?tine.tineCount:this.$t('Unknown');
      return `${tineProperty} ${tineCountAttribute} ${count}`;
    },
    tineClick(index) {
      this.$set(this, 'selectedTagTines', this.localTag.speciesTags[index].tines);
      this.$set(this, 'selectedTagIndex', index);
      this.$modal.show('tineModal');
    },

    getSpeciesOptClass (option) {
      if (option.className === 'AVES') return 'species AVES ion-social-twitter';
      if (option.className === 'NONE') return 'species NONE ion-android-walk';
      return 'species OTHER ion-ios-paw';
    },
    /* tag opertaion */
    addTag () {
      // this.localTag.speciesTags.push({id: null, age: null, sex: null, species: null, taskId: this.id, hasChanged: true, useSubsample: this.useSubsample}); // need to put task id in.
      this.addBlankTagRow();

      //scroll to bottom of div.
      this.$refs.scroller.scrollTo(0, this.$refs.scroller.scrollHeight);
    },

    addBlankTagRow () {
      // this.orgTag.speciesTags = [{id: null, age: null, sex: null, species: null, taskId: this.id, isBlank: true, hasChanged: true}];
      if (!this.isEditable) {
        return;
      }

      if (this.localTag == null) {
        this.localTag = {speciesTags: [], task: {}};
      }

      this.localTag.speciesTags.push(
        this.speciesTagDecorator(
          {id: null, age: null, ageId: null, sex: null, sexId: null, species: null, taskId: this.localTag.task.id, isBlank: true, isAuto: false, hasChanged: true, tines: [], ui: {dropdownStates:this.dropdownStates}}, true
          )
        );
      
    },
    getLocation () {
      if (this.localTag && this.localTag.locations && this.localTag.locations.name) {
        return ' - ' + this.localTag.locations.name;
      }
    },
    getModalWidth () {
      this.modalWidth = this.$refs.tagForm.$refs.modal.clientWidth;
    },
    getScrollClass () {
      if (this.$refs.tagForm.$refs.modal.clientHeight < this.$refs.scroller.clientHeight + 40) {
      }
    },
    /* based on modal size, set class */
    getTagFieldClasses (width, colWidth) {
      let classes = {
        'col': true,
        's4': width >= 600 & width < 800,
        's6': width < 600 && width > 400,
        's12': width <= 400
      };
      if (colWidth) {
        classes['s' + colWidth] = (width >= 800);
      } else {
        classes['s2'] = (width >= 800);
      }
      return classes;
    },
    closeForm () {
      if (this.loading) {
        return;
      }
      this.validationMsg = []; // clear validation message
      this.error = [];
      this.message = [];
      const self = this;
      const unsavedWarning = this.$t('cameraTagging-unsavedWarning');
      const unsavedWarningBatch = this.$t('cameraTagging-unsavedWarningBatch');
      if (this.formModified() && !this.isBatchSelected) { // when form modified (only in single tag mode), confirm if want to be saved
        confirmDialog3Button(this.$modal, this.$t('common-unsavedChanges'), unsavedWarning, this.$t('common-save'), this.submitForm,
          this.$t('common-close'), function () { eventBus.$emit('close-tagging-form', self.formName, false); },
          this.$t('common-cancel'));
        // return;
      } else if (this.formModified() && this.isBatchSelected) {
        // this.localTag = copyObject(this.orgTag); // reset it to original value
        confirmDialog(this.$modal, this.$t('common-unsavedChanges'), unsavedWarningBatch,
          this.$t('common-close'), function () { eventBus.$emit('close-tagging-form', self.formName, false); },
          this.$t('common-cancel'));
        // return;
      } else {
        eventBus.$emit('close-tagging-form', self.formName, false);
      }
    },
    refreshImages () {
      eventBus.$emit('refresh-tag');
    },
    copyTag (data) {
      this.orgTag = copyObject(data);
      this.localTag = copyObject(data);
    },
  
    /**
     * Decorate species tag to update/insert.
     * @param any speciesTag
     * @param bool ui Populate values only for UI purposes 
     */
    speciesTagDecorator(speciesTag) {
          speciesTag = copyObject(speciesTag);
          speciesTag.age = this.options.age.find(x=> x.id == speciesTag.ageId);
          speciesTag.sex = this.options.sex.find(x=>x.id==speciesTag.sexId);
          speciesTag.species = this.options.species.find(x=>x.id==speciesTag.speciesId);
        
          if (typeof speciesTag.species == 'undefined') {
            speciesTag.species = this.options.allSpecies.find(x=>x.id==speciesTag.speciesId);
          }

          //decorate for ui state. dropdown state is to control overflow
         
            speciesTag.ui = {
              dropdownStates: this.dropdownStates
            };
         

          if (!speciesTag.behaviours) {
            speciesTag.behaviours = [];
          } else {
            speciesTag.behaviours.forEach((e,j)=>{
              speciesTag.behaviours[j].type = this.localOptions.speciesBehaviour.find(x=>x.behaviourId==e.behaviourId).type;
            });
          }

          if (!speciesTag.coatAttributes)  {
            speciesTag.coatAttributes = [];
          } else {
            speciesTag.coatAttributes.forEach((e,j)=>{
              speciesTag.coatAttributes[j].type = this.localOptions.coatAttribute.find(x=>x.coatAttributeId==e.coatAttributeId).type;
            });
          }

          if (!speciesTag.coatColors){
            speciesTag.coatColors = [];
          } else {
            speciesTag.coatColors.forEach((e,j)=>{
              speciesTag.coatColors[j].type = this.localOptions.coatColor.find(x=>x.coatColorId==e.coatColorId).type;
            });
          }
          
          if (!speciesTag.healthConditions) {
            speciesTag.healthConditions = [];
          } else {
            speciesTag.healthConditions.forEach((e,j)=>{
              speciesTag.healthConditions[j].type = this.localOptions.health.find(x=>x.healthId==e.healthId).type;
            });
          }

          if (!speciesTag.tines) {
            speciesTag.tines = [];
            } else {
            speciesTag.tines.map((tine, j) => {
              tine.tineProperty = this.localOptions.tineProperty.find(x=>x.id==tine.tinePropertyId);
              tine.countAttribute = this.localOptions.countAttribute.find(x=>x.id==tine.countAttributeId);
              speciesTag.tines[j] = tine;
            });
          }

          // if (speciesTag.direction) {
          //   speciesTag.direction = speciesTag.direction.id;
          // }
          return speciesTag;
    },
    noTag () { // when marked as no tag, only one species tag is allowed.
      this.hasTag = false;
      const noSpecies = this.options.allSpecies.find(x => { return x.commonName === 'NONE'; });
      const noneTag = {
        taskId: this.id,
        species: noSpecies,
        sex: this.options.sex.find(x => { return x.type === 'VNA' }),
        age: this.options.age.find(x => { return x.type === 'VNA'; }),
        individualCount: 'VNA'
      };
      this.localTag.speciesTags = [];
      this.localTag.speciesTags.push(noneTag);
      // this.localTag.speciesTags = [{taskId: this.id, species: species, sex: this.options.sex.find(x => { return x.type=='VNA'}),
      //   age: this.options.age.find(x => { return x.type=='VNA'; }),
      //   individualCount: 'VNA'
      // }];
    },

    duplicateTag (tag) {
      if (!this.isEditable) {
        return;
      }
      let copiedTag = copyObject(tag);
      copiedTag.id = null;
      copiedTag.hasChanged = true;

      copiedTag.behaviours.map((e,i)=>{ delete copiedTag.behaviours[i].tagId; delete copiedTag.behaviours[i].id; });
      copiedTag.coatAttributes.map((e,i)=>{ delete copiedTag.coatAttributes[i].tagId; delete copiedTag.coatAttributes[i].id; });
      copiedTag.coatColors.map((e,i)=>{ delete copiedTag.coatColors[i].tagId; delete copiedTag.coatColors[i].id; });

      this.$delete(copiedTag, 'isLocked'); // for batch updated don't use this flag for unsaved item
      this.localTag.speciesTags.push(copiedTag); // copy tag, however, remove tag id
    },
    deleteTag (index) {
      if (!this.isEditable) {
        return;
      }
      if (this.localTag.speciesTags[index].isBlank) { // when delete default blank row, delete orginal tag blank row too
        this.orgTag.speciesTags.splice(index, 1);
      }
      let delTag = this.localTag.speciesTags.splice(index, 1);
      this.removedTags.push(delTag[0]);
    },
    validationForm () {
      /* step 1: form validation:
        3. tags required all values filled
        2. tags can't be duplicated.
        1. minimum one tag, if anyone tag is none, you can't have other tags.
      */
      this.validationMsg = []; // clear validation message
      this.error = [];
      if (!this.localTag.speciesTags || this.localTag.speciesTags.length === 0) {
        this.validationMsg.push(this.$t('cameraTagging-errors.needTag'));
      } else {
        // if any species tag misses sex or age, or count, leave a warning
        if (this.localTag.speciesTags.find(spp => { return !spp.isBlank && (!spp.species || !spp.sex || !spp.age || !spp.individualCount) })) {
          this.error.push(this.$t('cameraTagging-errors.requiredAttributes'))
        }
        if (this.localTag.speciesTags.find(spp => { return spp.isBlank && (!spp.species || !spp.sex || !spp.age || !spp.individualCount) })) {
          this.validationMsg.push(this.$t('cameraTagging-errors.blankShouldBeNone'))
        }
        // if any species tag is none, you can't have other tags.
        if (this.localTag.speciesTags.find(spp => { return spp.species && spp.species.commonName === 'NONE' }) && this.localTag.speciesTags.length > 1) {
          this.error.push(this.$t('cameraTagging-errors.noneMeansOthersNotAllowed'))
        }
        if (this.localTag.speciesTags.find(spp => { return !isNaN(parseInt(spp.individualCount)) && parseInt(spp.individualCount) < 1; })) {
          this.error.push(this.$t('cameraTagging-errors.speciesCountMustBeNumber'));
        }
        if (!(!this.localTag.speciesTags || this.localTag.speciesTags[0].isBlank)) {
        // must keep 1 equal sign or else you cant submit a tag vvv
        // eslint-disable-next-line eqeqeq
          if (this.localTag.speciesTags.find(spp => { return spp.individualCount && spp.individualCount.length > 0 && (parseInt(spp.individualCount) != spp.individualCount || isNaN(parseInt(spp.individualCount))) && (spp.individualCount !== 'VNA'); })) {
            this.error.push(this.$t('cameraTagging-errors.speciesCountCanBeVna'));
          }
        }
        //
        // // if anyone tag is none, you can't have other tags.
        // if (this.localTag.speciesTags.find(spp => { return !spp.species })) {
        //   this.error.push('Species is required for all tags')
        // }
        //
       // const tags = this.localTag.speciesTags.map(spp => { return (spp.species ? spp.species.id : -1) + '-' + (spp.sex ? spp.sex.id : -1) + '-' + (spp.age ? spp.age.id : -1); });
        // if ((new Set(tags)).size !== tags.length) {
        //   this.error.push(this.$t('cameraTagging-errors.removeDuplicatedTags'));
        // }

        if (this.localTag.task.fovId == null) {
          this.validationMsg.push(this.$t('cameraTagging-errors.fovRequiredDefaultIsWithin'))
        }
      }
    },
    /* form submission */
    submitForm () {
      this.validationForm();
      if (this.error.length > 0) {
        alertDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationErrorMsg);
        return;
      }
      if (this.validationMsg.length > 0) {
        confirmDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationWarningMsg + '<br><span class="red-font">' + this.validationMsg + '</span>', this.$t('common-yes'), this.processForm); // function () {
        return;
      }
      // no error or warning, send update
      this.processForm();
    },

    processForm () {
      this.message = [];
      this.validationMsg = [];
      this.error = [];
      this.loading = true;
    
      let closeModal = false;
      let promises = [];

      let tag = copyObject(this.localTag);
      /* filter out, blank rows, with all blank values */
      
      tag.speciesTags = tag.speciesTags.filter(spp => !(spp.isBlank && (!spp.species && !spp.sex && !spp.age && !spp.individualCount)));

      // If there are species defined as NONE then set the review flag to false.
      tag.speciesTags.forEach(e => {
        // None is id 39
        if (e.species && e.species.id === 39) { e.needsReview = false; }
      });

      delete tag.task.fov;

      tag.task.fovId = tag.task.fovId?tag.task.fovId.id:0;


      promises.push(this.$http.post(this.updateCameraTaskAPI, tag.task).then(function (response) {
        this.loading = false;
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('cameraTagging-updateFailed') + ':' + response.data.error);
        } else {
          //this.copyTag(response.data);
          
          this.localTag.task = response.data;
          this.orgTag.task = response.data;
          
          this.formModified(false);
          
          this.reloadOptions();
          this.refreshImages();
          closeModal = true;
          
        }
      }, (err) => {
        console.log(err);
      // error callback
        this.loading = false;
        this.orgTag = {};
        this.localTag = {};

        this.formModified(false);
        this.error.push(this.$t('cameraTagging-formUpdateFailed') + ' ' + err);
      }));

      if (tag.speciesTags.length > 0) {
        tag.speciesTags.forEach((sTag,i)=>{
          let temp = this.speciesTagDecorator(sTag);

          if (this.validationSingleTag(temp, i, () => {})) { //validated
            promises.push(this.$http.post(this.cameraSpeciesTagAPI, temp).then( resp=> {
              
              if (resp.body && resp.body.error) {
                this.error.push(this.$t('cameraTagging-updateFailed') + ':' + resp.body.error);
              } else {
                this.formModified(false);  
                closeModal = true;
              }
            }));
          }


        });
      }

      if (this.removedTags.length > 0) {
        promises.push(this.$http.post(this.deleteSpeciesTagsAPI, this.removedTags.map(tag=>tag.id)).then(resp=> {
          //Reset array so we dont attempt to delete again.
          this.removedTags = [];
        }));

      }

      Promise.all(promises).then(()=> {
        if (closeModal) {
          this.message.push(this.$t('cameraTagging-formUpdated'));
          eventBus.$emit('close-tagging-form', this.formName, false);
        }
      });

     
      
    },
 
    // copy server tag to local tag, to reset
    resetForm () {
      // this.localTag = null;
      this.resetMsg();
      this.localTag = copyObject(this.orgTag); // reset it to original value
    },
    getClass (species, selectType) { // when selected NONE, hide sex, age, and count
      if (species && species.id === 1362) {
        return {hidden: true};
      }
    },
    isSelectDisabled (species, selectType) { // when select NONE, ATV, etc, disable sex and age
      if (!this.isEditable || (species && species.id === 1362)) {
        return true;
      }
    },
    /*
      when use select an species, when sex, age, individual not entered, fill in default values
      all previous values will be erased except switching to mammals.
    */
    speciesChanged (event, tag) {
      this.$set(tag, 'hasChanged', true);
      if (tag.species) {
        
      }
    },
    toggleComments (tag) {
      this.$set(tag, 'showComments', !tag.showComments);
    },
    validationSingleTag (spp, index, callback) {
      
      /* step 1: form validation:
        3. tags required all values filled
        2. tags can't be duplicated.
        1. minimum one tag, if anyone tag is none, you can't have other tags. */

      if (!spp.id && !spp.species && !spp.sex && !spp.age && !spp.individualCount) {
        // if new record with no info a spp has no infor, ignore
        return;
      }
      // if any species tag misses sex or age, or count, leave a warning

      if (!spp.species || !spp.sex || !spp.age || !spp.individualCount) {
        this.error.push(this.$t('cameraTagging-errors.requiredAttributes'));
      }

      this.error = [...new Set(this.error)];
      
      if (callback) { // if action provided, do confirmation and then call the action
        if (this.error.length > 0) {
          alertDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationErrorMsg);
          return false;
        }
        if (this.validationMsg.length > 0) {
          confirmDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationWarningMsg, this.$t('common-yes'), callback); // function () {
          return false;
        }

        callback();
      }
      return true;
    },
    /* for batch */
    batchUpdateTag (tag, index) { // batch update validation
      const self = this;
      this.resetMsg();
      this.validationSingleTag(tag, index, function () { self._batchUpdateTag(tag, index); });
    },

    async _batchUpdateTag (tag, index, bNoRefresh) { // batch update action
      /* copy from tag, only including ones that is not null */
      // let params =  params = Object.assign({}, tag); // copy tag to params
      tag = {
       speciesTag: this.speciesTagDecorator(tag),
       cloneSpeciesTagIds: this.localTag.speciesTagMatchList[tag.id]
      };

      const url = (this.forUntagged ? this.updateAllUntaggedAPI : this.cameraSpeciesTagsAPI);
      return new Promise((resolve, reject) => {
        if (!tag.speciesTag.id && !tag.speciesTag.species && !tag.speciesTag.sex && !tag.speciesTag.age && !tag.speciesTag.individualCount) { // skip all empty fields
          resolve();
        } else {
          this.$http.put(url, tag).then(function (response) {
            if (response.data.hasOwnProperty('error')) {
              this.error.push(this.$t('cameraTagging-batchUpdateFailed', {name: (tag.speciesTag.species && tag.speciesTag.species.commonName), error: response.data.error}));
            } else {
              // this.orgTag.speciesTags.push(response.data); // always update orgTag first. as watched is on localTag only
              let newTag = this.speciesTagDecorator(response.data);
              this.$set(this.orgTag.speciesTags, index, newTag);
              this.$set(this.localTag.speciesTags, index, newTag);
              this.message.push(this.$t('cameraTagging-batchTagSuccessful', {name: tag.speciesTag.species.commonName}));
              this.reloadOptions();
            }
            if (!bNoRefresh) {
              this.refreshImages();
            }
            this.formModified(false);
            resolve();
          }, (err) => {
            this.error.push(this.$t('cameraTagging-batchUpdateFailed', {name: tag.speciesTag.species.commonName, error: err}));
             this.formModified(false);
            reject();
          });
        }
      });
    },

    batchInsertTag (tag, index) { //
      const self = this;
      this.resetMsg();
      this.validationSingleTag(tag, index, function () { self._batchInsertTag(tag, index); });
    },
    async _batchInsertTag (tag, index, bNoRefresh) {
      // console.log('insert tag ==', tag)


      tag = {
        insertTaskIds: this.id.filter(id=>id!=this.localTag.task.id),
        speciesTag: this.speciesTagDecorator(tag)
      };


      return new Promise((resolve, reject) => {
        if (!tag.speciesTag.species) { // skip all empty fields
          resolve();
        } else {
          
          // insert-camera-species-tags?taskId[]=8609404&taskId[]=13297316&ageId=1&sexId=7&needsReview=false&speciesId=17
          this.$http.put(this.cameraSpeciesTagsAPI, tag).then(function (response) {
            if (response.data.hasOwnProperty('error')) {
              this.error.push(this.$t('cameraTagging-batchInsertFailed', {name: (tag.speciesTag.species && tag.speciesTag.species.commonName), error: response.data.error}));
            } else {
              if (this.orgTag.speciesTags.length === 1 && this.orgTag.speciesTags[0].hasChanged) { // remove default blank row
                this.orgTag.speciesTags = [];
              }
              let newTag = this.speciesTagDecorator(response.data);
              this.$set(this.orgTag.speciesTags, index, newTag);
              this.$set(this.localTag.speciesTags, index, newTag);
              this.reloadOptions();
            }
            if (!bNoRefresh) {
              this.refreshImages();
            }
            resolve();
          }, (err) => {
            this.error.push(this.$t('cameraTagging-batchInsertFailed', {name: tag.speciesTag.species.commonName, error: err}));
            reject();
          });
        }
      });
    },
    _batchDeleteTag (tag, index) {
      this.loading = true;
      this.$http.delete(this.batchDeleteTagAPI, {body: this.localTag.speciesTagMatchList[tag.id]}).then(function (response) { // {params tag} => url request param, {body: tag} => body
        this.loading = false;
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('cameraTagging-batchDeleteFailed', {name: tag.species.commonName, error: response.data.error}));
        } else {
          this.orgTag.speciesTags.splice(index, 1);
          this.localTag.speciesTags.splice(index, 1);

          this.message.push(this.$t('cameraTagging-batchDeleteSuccessful', {name: tag.species.commonName}));
        }
        this.reloadOptions();
        this.refreshImages();
      }, (err) => {
        this.loading = false;
        this.error.push(this.$t('cameraTagging-batchDeleteFailed', {name: tag.species.commonName, error: err}));
      });
    },
    batchDeleteTag (tag, index) {
      this.resetMsg();
      let self = this;
      
      confirmDialog(this.$modal, this.$t('cameraTagging-batchDelete'), this.$t('cameraTagging-batchDeleteWarning'), this.$t('common-yes'),
        function () { self._batchDeleteTag(tag, index); });
    },
    resetMsg () {
      this.message = [];
      this.error = [];
      this.validationMsg = [];
    },
    batchUpdateTaskProperties () {
      let params = { id: this.orgTag.task.id };
      /* when values not null, include them */
      let task = copyObject(this.localTag.task);

      task.fovId = task.fovId.id;

      if (this.localTag.task != null) {
        params = {
          task,
          cloneToIds: this.id,
          updateSnowDepth: this.localTag.task.snowDepthM!=this.orgTag.task.snowDepthM,
          updateComments: this.localTag.task.comments!=this.orgTag.task.comments,
          updateHasSnowOnGround: this.localTag.task.hasSnowOnGround!=this.orgTag.task.hasSnowOnGround,
          updateFire: this.localTag.task.fire!=this.orgTag.task.fire,
          updateWaterDepth: this.localTag.task.waterDepthM!=this.orgTag.task.waterDepthM,
          updateFOV: this.localTag.task.fovId!=this.orgTag.task.fovId,
          updateNice: this.localTag.task.nice!=this.orgTag.task.nice,
          updateMalfunction: this.localTag.task.malfunction!=this.orgTag.task.malfunction
        };
      } else {
        this.validationMsg.push(this.$t('cameraTagging-nothingToBeUpdated'));
        return;
      }
      return new Promise((resolve, reject) => {
        this.$http.put(this.updateCameraTasksAPI, params).then(function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.error.push(this.$t('Batch Update Failed') +' '+ response.data.error);
            reject();
          } else {
            this.orgTag.task = this.localTag.task;
            this.message.push(this.$t('Updated tasks'));
            this.formModified(false);
            resolve();
            /* if update FOV, need to update image/pagination */
            // if (propertyName === 'fov') {
            //   this.refreshImages();
            // }
          }
        }, (err) => {
          this.error.push(this.$t('Batch Update Failed'));
          reject();
        });
      });
    },
    /* try find all batchSaveButton and click them all */
    batchSaveAll (bCloseForm) {
      this.resetMsg()
      /* option 1, find buttons from DOM, and trigger click */
      // batchButtons = document.querySelectorAll('#tagging-form .batchSaveButton');
      // batchButtons.forEach(function (submitBtn) {
      //   submitBtn.click();
      // });
      /* option 2: loop through tags, and save them all */
      let hasAnyChange = false;
      this.localTag.speciesTags.forEach((tag, index) => {
        if (tag.hasChanged) {
          this.validationSingleTag(tag, index, null);
          hasAnyChange = true;
        }
      })

      if (!hasAnyChange) { // nothing changed, do nothing;
        if (bCloseForm) {
          this.closeForm();
        }
        return;
      }
      if (this.error.length > 0) {
        alertDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationErrorMsg);
        return;
      }
      const self = this;
      if (this.validationMsg.length > 0) {
        confirmDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationWarningMsg, this.$t('common-yes'), function () { self._batchUpdate(bCloseForm); });
        return;
      }
      return self._batchUpdate(bCloseForm);
    },

    async _batchUpdate (bCloseForm) {
      let self = this;
      let updateTasks = [];

      await self.localTag.speciesTags.forEach(async (tag, index) => {
        if (tag.hasChanged) { // don't update when not changed
          if (tag.id) { // update
            updateTasks.push(self._batchUpdateTag(tag, index, true));
          } else { // insert
            updateTasks.push(self._batchInsertTag(tag, index, true));
          }
        }
      });
      this.loading = true;
      return Promise.all(updateTasks).then((values) => {
        // console.log('all uploads are done!!', values, updateTasks);
        this.loading = false;
        this.formModified(false);
        self.refreshImages();
        if (self.error.length > 0 || self.validationMsg.length > 0) { // don't close form when error occurs
          return;
        }
        self.orgTag = copyObject(self.localTag);
        this.reloadOptions();
        if (bCloseForm) {
          self.closeForm();
        }
      });
    },
    reloadOptions () {
      eventBus.$emit('reload-static-option');
      eventBus.$emit('update-tagging-status'); // after batch update, check if all fully tagged.
    }
  },
  data () {
    return {
      ui: {
        gallery: {
          currentIndex: 0
        },
        task: {
          dropdownStates: {
            fieldOfView: false
          }
        }
      },
      dropdownStates: {sex: false, age: false, species: false, speciesBehaviour: false, coatAttributes: false,  coatColors: false, healthConditions: false, direction: false},
      removedTags:[], //Delete tag will put the tags into this array.
      selectedTagTines:null,
      selectedTagIndex:null,
      tagOptions:{
     
      },
      attributesOptions: {
        'age': {key: 'age', multiple: false, closeOnSelect: true, enabledKey: 'useSpeciesAge'},
        'sex': {key: 'sex', multiple: false, closeOnSelect: true, enabledKey: 'useSpeciesSex'},
      },
      //New APIs for v8
      updateCameraTaskAPI: API_URL + 'update-camera-task',
      updateCameraTasksAPI: API_URL + 'update-camera-tasks',
      cameraSpeciesTagAPI: API_URL + 'insert-update-camera-species-tag',
      cameraSpeciesTagsAPI: API_URL + 'insert-update-camera-species-tags',
      deleteSpeciesTagsAPI: API_URL + 'delete-camera-species-tags',
      getTagAPI: API_URL + 'get-species-for-task',
      getBatchTagAPI: API_URL + 'get-species-for-tasks',
      batchDeleteTagAPI: API_URL + 'delete-camera-species-tags',

      //Old API endpoints. Remove as needed.

      updateAllUntaggedAPI: API_URL + 'update-camera-species-tasks-all-untagged',
      insertAllUntaggedAPI: API_URL + 'insert-camera-species-tags-all-untagged',

      message: [],
      validationMsg: [],
      error: [],
      orgTag: {},
      localTag: null,
      hasTag: true,
      hasFormModified: false,
      isFormValid:true,
      isBatchSelected: false,
      modalWidth: null, // modal width for responsive classes
      isBatchUpdateAll: false,
      loading: false,
      validationErrorMsg: this.$t('cameraTagging-validationMessage'),
      validationWarningMsg: this.$t('cameraTagging-validationWarningMessage'),
      tooltips: {
        malfunction: this.$t('cameraTagging-tooltips.malfunction')
      }
    }
  }
}
</script>
<style scoped>
.message {
  color: rgb(255, 217, 0);
}
.message {
  color: rgb(255, 123, 0);
}
.info-icon {
  font-size: 18px;
  color: #FF9800;
}
.tooltip {
  max-width: 600px;
}
.modal-form {
  background-color: #fff;
}
.modal-content   .padded10 {
  padding: 10px 5px;
  margin-bottom: 10px;
}
/* alignment for naming section display */
#batchUploadModal .modal-content .naming .row .col   {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 15px;
  font-size: 1rem;
}

#batchUploadModal .modal-content .label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 1rem;
}

.verticl-align {
  display: flex;
  align-items: center;
  height: 50px;
}
#batchUploadModal .modal-content input,
#batchUploadModal .modal-content select {
  height: 50px;
}

@media only screen and (min-width: 1px) {
  .modal-form {
  max-height: 600px;
  min-height: 450px;
  width: 60%;
  }
  .modal-form .modal-scroller {
    min-height: 400px;
  }
  .modal-form .modal-header {
    max-height: 50px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
}

/* @media only screen and (max-width: 991px) {
  .modal-form {
  max-height: 600px;
  width: 80%;
  }

  .modal-form .modal-scroller {
    max-height: 550px;
  }
  .modal-form .modal-header {
    max-height: 50px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
} */

.modal-form .modal-scroller {
  overflow: auto;
  margin: 0px;
}

.row .margin-bottom-20 {
  margin-bottom: 20px!important;
}

.image-tagging .row.even {
  background-color:#f7f7f7;
  position: relative;
}
.image-tagging .row.odd {
  background-color:#e7e7e7;
  position: relative;
}
.image-tagging .row {
  padding: 4px 0 2px 0;
  margin: 2px;
}

.image-tagging select,
.ion {
  font-size: 20px;
  color: #C94412;
}
textarea.tag-comments {
  min-height: 1em!important;
  background: #fff;
  }
.modal-content input.individual{
  padding-left: 5px;
  height: 40px;
  font-size: 0.8em;
  }

  input.individual:read-only{
      cursor: default;
  }

  input.location:read-only{
      cursor: default;
  }

 .form :deep(.multiselect__placeholder) {
    margin-bottom: 2px!important;
    padding-top: 0px!important;
  }
  /* label.need-review span{
    font-size: 12px;
  } */
  span.link.new {
    color:rgb(202, 123, 67);

  }

.right {
  float: right;
}

.spp-new-tag {
  font-size: 12px;
  display: block;
  float: left;
  position: absolute;
}

.hidden {
  display: none!important;
}
.batchSaveButton {
  height: 20px; padding: 0px 5px;
}
.tagform-textarea {
  min-height: 50px;
  width: 100%;
}
.form :deep(.multiselect__option.species:before) {
  padding-right: 8px;
}

.form :deep(.multiselect__option.species.AVES:before) {
  color: #4caf50;
}

.form :deep( .multiselect__option.species.NONE:before) {
  color: #607D8B;
}

.form :deep( .multiselect__option.species.OTHER:before) {
  color: #905844;
}

.form [type=checkbox]:not(:checked):disabled+span:not(.lever):before {
  border: 1px solid #948b8b;
  background-color: rgba(173, 173, 173, 0.42);
  cursor: auto;
}
.padtop10 {
  padding-top: 10px!important;
}

.btn.add-spp {
  margin-top: 5px;
  font-size: 25px;
  padding: 0 10px;
}

.disabled {
  cursor: not-allowed!important;
}

.inactive{
  cursor: default !important;
}

</style>
