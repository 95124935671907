<template>
  <div id="locationVisitEquipmentEditFormModal" >
    <div>
      <!-- parent and child tables to show detailed visit information -->
      <div class="modal-content padded40 main">
        <div @click="close" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        <div class="row">
          <div><h5>{{locationLabel}}</h5></div>
          <!-- add equipment form -->
          <div class="newEquipmentForm padded20" v-if="toShowNewEquipmentForm">
            <h5>{{$t('visitEquipForm-fields.quickRegister')}}</h5>
            <div class="col s12">
              <label>{{$tc('common-code', 1)}}</label>
              <div class="vue-select-wrapper">
                <input :disabled="!isEditable" v-model="insertEquipmentRow.code" >
              </div>
            </div>
            <div class="col s12">
              <label>{{$t('common-make')}}</label>
              <div class="vue-select-wrapper">
                <input :disabled="!isEditable" v-model="insertEquipmentRow.make">
              </div>
            </div>
            <div class="col s12">
              <label>{{$t('common-model')}}</label>
              <div class="vue-select-wrapper">
                <input :disabled="!isEditable" v-model="insertEquipmentRow.model">
              </div>
            </div>
            <div class="col s12">
              <label>{{$t('common-serialNumber')}}</label>
              <div class="vue-select-wrapper">
                <input :disabled="!isEditable" v-model="insertEquipmentRow.serialNumber">
              </div>
            </div>
            <div class="col s12">
              <label>{{$t('common-purchaseDate')}}</label>
              <div class="vue-select-wrapper">
                <date-picker :disabled="!isEditable" type="date" :lang="locales[$root.$i18n.locale]" valueType="format" v-model="insertEquipmentRow.purchaseDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></date-picker>
              </div>
            </div>
            <div class="col s12">
              <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
              <button v-if="isEditable" class="btn btn-success btn-border modal-action modal-close" @click="saveEquipmentRow">{{$t('visitEquipForm-fields.insertNew')}}</button>
              <button class="btn btn-success btn-border modal-action modal-close" @click="toShowNewEquipmentForm=false; insertEquipmentRow={};">{{$t('common-close')}}</button>
            </div>
          </div>
          <div class="col s12">
            <h6 v-if="!isFullForm && equipmentRow.currentEquipment && equipmentRow.currentEquipment.equipmentType">
              {{$t('visitEquipForm-fields.originalEquip')}}: {{equipmentRow.currentEquipment.equipmentType.type}} - {{$t('common-make')}}: {{equipmentRow.currentEquipment.make}} -
              {{$t('common-model')}}: {{equipmentRow.currentEquipment.model}} {{capitalize($tc('common-code', 1))}}: {{equipmentRow.currentEquipment.code}}
              </h6>
            <!-- <h6 v-if="equipmentObj && equipmentObj.equipmentType">
              {{equipmentObj.equipmentType.type}} - {{$t('common-make')}}: {{equipmentObj.make}} - {{$t('common-model')}}: {{equipmentObj.model}} {{capitalize($tc('common-code', 1))}}: {{equipmentObj.code}}
              <span v-if="isEditable" @click="clearEquipment" class="link ion-close-circled"></span>
            </h6> -->
            <h6 v-if="!equipmentObj || !equipmentObj.equipmentType">
              <span v-if="isFullForm">
              {{$t('visitEquipForm-fields.selectEquipType')}}
              </span>
              <span v-else>
                {{$t('visitEquipForm-fields.selectCode')}}
              </span>
            </h6>
          </div>
          <div class="col s4" v-if="isFullForm">
            <label>{{$t('visitEquipForm-fields.equipType')}}</label>
              <div class="vue-select-wrapper">
                <select :disabled="!isEditable" v-model="equipmentTypeId" @change="changeDeviceType()">
                  <template v-for="(option, index) in options.EquipmentType">
                    <option :key="index" :value="option.id" v-if="isMainDevice(equipmentRow.parentLocationDeploymentEquipmentId, option.id)">{{option.type}} </option>
                  </template>
                </select>
              </div>
          </div>
          <div class="col s4">
            <label>{{$t('visitEquipForm-fields.equipCode')}}</label>
            <div class="vue-select-wrapper">
              <auto-complete
                  ref="autocomplete"
                  :placeholder="$t('visitEquipForm-fields.codePlaceholder')"
                  :source= "getEquipmentCodeUrl"
                  initialValue=""
                  :initialDisplay="equipmentObj.code"
                  clearButtonIcon="ion-close"
                  name="id"
                  results-display="code"
                  :request-headers="authHeaders"
                  @selected="displayEquipmentDetails"
                  :disableInput = "!equipmentTypeId || !isEditable"
                  @noResults="saveInvalidAutoFill"
                  @results="hasResults"
                >
                </auto-complete>
            </div>
          </div>
          <div class="col s4">
            <div style="margin-top: 30px;"><button v-if="equipmentTypeId && isEditable" class="btn btn-success btn-border modal-action modal-close" @click="toShowNewEquipmentForm=true;  insertEquipmentRow={code: invalidEntry};">{{$t('visitEquipForm-fields.registerNew')}}</button></div>
          </div>
          <template v-if="isFullForm">
            <div class="col s12 m4" v-if="false">
              <div v-if="isAttacheableDevice(equipmentTypeId)">
                <label>{{$t('visitEquipForm-fields.attachedTo')}}</label>
                <div class="vue-select-wrapper">
                  <select :disabled="!isEditable" v-model="equipmentRow.parentLocationDeploymentEquipmentId" :required ="true">
                    <option value=""> -- </option>
                    <template v-for="(option, index) in equipmentOption">
                      <option :key="index" :value="option.id" v-if="isMainDevice(option.id, equipmentTypeId)">{{option.text}} </option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="col s12"><h5>{{$t('visitEquipForm-fields.deployment')}}</h5></div>
            <div class="grid grid-cols-5 gap-3 col s12">
            <div class="">
              <label>{{$t('Deployment Visit Start Date')}}
                <span class="info-icon ion-information-circled" v-tooltip="tooltips.visit"></span>
              </label>
              <div class="vue-select-wrapper">
                <select :disabled="!isEditable" v-model="equipmentRow.deployVisitId" :required ="true">
                  <option value=""> -- </option>
                  <option v-for="(option, index) in options.deployVisitId" :key="index" :value="option.id">{{option.text}}</option>
                </select>
              </div>
            </div>
            <div class="">
              <label>{{$t('Deployment Visit Start Time')}}</label>
              <div class="vue-select-wrapper">
                <date-picker :disabled="!isEditable" type="time" :lang="locales[$root.$i18n.locale]" v-model="equipmentRow.exactDeployTime" format="HH:mm" value-type="HH:mm:ss"></date-picker>
              </div>
            </div>

          

   

                <div class=""  v-if="isSensorDevice">
                  <label>{{$t('Attachment')}}
                    <span class="info-icon ion-information-circled" v-tooltip="tooltips.deploymentType"></span> </label>
                  <div class="vue-select-wrapper">
                    <select :disabled="!isEditable" v-model="equipmentRow.typeId" :required ="true">
                      <option value=""> -- </option>
                      <option v-for="(option, index) in options.locationDeploymentEquipmentType" :key="index" :value="option.id">{{option.type}}</option>
                    </select>
                  </div>
                </div>
                <div class="" v-if="isCamera && isSensorDevice">
                  <label>{{$t('Target Feature')}}
                    <span class="info-icon ion-information-circled" v-tooltip="tooltips.target"></span> </label>
                  <div class="vue-select-wrapper">
                    <select :disabled="!isEditable" v-model="equipmentRow.targetId" :required ="true">
                      <option value=""> -- </option>
                      <option v-for="(option, index) in options.locationDeploymentEquipmentTarget" :key="index" :value="option.id">{{option.type}}</option>
                    </select>
                  </div>
                </div>
                <div class="" v-if="isCamera && isSensorDevice">
                  <label>{{$t('visitEquipForm-fields.stakeDistance')}}
                    <span class="info-icon ion-information-circled" v-tooltip="tooltips.stakeDistance"></span> </label>
                  <div class="vue-select-wrapper">
                    <input :disabled="!isEditable" v-model.number="equipmentRow.stakeDistanceMeters" step="0.1" min="0" max="10" type="number" class="validate">
                  </div>
                </div>

            </div>
            <div :class="{'grid gap-3 grid-cols-5 col s12':true }">

                <div class=""  v-if="isSensorDevice">
                  <label for="form_depth">{{$t('Walktest Height (m)')}}
                    <span class="info-icon ion-information-circled" v-tooltip="tooltips.height"></span>
                  </label>
                  <input :disabled="!isEditable" id="form_depth" v-model.number="equipmentRow.heightMeters" step="0.1" min="0" max="999.999" type="number" class="validate" required :placeholder="$t('visitEquipForm-fields.heightPlaceholder')">
                </div>
                <div class=""  v-if="isSensorDevice">
                  <label for="direction">{{$t('Equipment direction (degrees)')}}
                    <span class="info-icon ion-information-circled" v-tooltip="tooltips.direction"></span>
                  </label>
                  <input :disabled="!isEditable" id="direction" v-model.number="equipmentRow.directionDegrees" step="1" min="0" max="359" type="number" class="validate"
                  required>
                </div>
              <div class="">
                <label for="workingTestDone">
                  <!-- <input :disabled="!isEditable" id="workingTestDone" type="checkbox" v-model.number="equipmentRow.workingTestDone"> -->
                  <span :class="{inactive: !isEditable}">{{$t('visitEquipForm-fields.sensorTest')}}
                    <span class="info-icon ion-information-circled" v-tooltip="tooltips.sensorTest"></span>
                  </span>
                </label>

                   <div>
                    <i v-if="equipmentRow.workingTestDone" @click="()=>{$set(equipmentRow, 'workingTestDone', false);}" 
                      class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                    <i v-if="!equipmentRow.workingTestDone" @click="()=>{$set(equipmentRow, 'workingTestDone', true);}" 
                      class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                    </div>
              </div>
        

              <div>
                <label>{{$t('Walktest Height(m)')}} <span  class="info-icon ion-information-circled" v-tooltip="tooltips.walkTestHeight"></span>
                  <input type="number" min="0" v-model="equipmentRow.walkTestHeight" />
                </label>
              </div>
            
              <div>
                <label>{{$t('WalkTest Distance(m)')}} <span  class="info-icon ion-information-circled" v-tooltip="tooltips.walkTestDistance"></span>
                  <input type="number" min="0" v-model="equipmentRow.walkTestDistance" />
                </label>
              </div>

              <div >
                  <label>{{$t('Facing Human Feature')}} <span  class="info-icon ion-information-circled" v-tooltip="tooltips.facingHumanFeature"></span></label>
                    <div>
                    <i v-if="equipmentRow.containsHumanFeature" @click="()=>{$set(equipmentRow, 'containsHumanFeature', false);}" 
                      class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                    <i v-if="!equipmentRow.containsHumanFeature" @click="()=>{$set(equipmentRow, 'containsHumanFeature', true);}" 
                      class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                    </div>
              </div>

              <div class="grid grid-cols-2 gap-1">
                <div class="col-span-2">
                <label>{{$t('Trigger Mode')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('Something about triggers')"></span></label>
                </div>
                <div>
                    
                      <div class="flex items-center gap-2">
                        <i v-if="equipmentRow.timeLapseEnabled" @click="()=>{$set(equipmentRow, 'timeLapseEnabled', false);}" 
                          class="fas fa-toggle-on !block text-4xl text-green-400 flex-initial"></i>
                        <i v-if="!equipmentRow.timeLapseEnabled" @click="()=>{$set(equipmentRow, 'timeLapseEnabled', true);}" 
                          class="fas fa-toggle-off !block text-4xl text-red-400 flex-initial"></i>
                        <label class="flex-1">{{$t('Time Lapse')}} </label>
                      </div>
                </div>
                <div>
                    
                      <div class="flex items-center gap-2">
                        <i v-if="equipmentRow.triggersEnabled" @click="()=>{$set(equipmentRow, 'triggersEnabled', false);}" 
                          class="fas fa-toggle-on !block text-4xl text-green-400 flex-initial"></i>
                        <i v-if="!equipmentRow.triggersEnabled" @click="()=>{$set(equipmentRow, 'triggersEnabled', true);}" 
                          class="fas fa-toggle-off !block text-4xl text-red-400 flex-initial"></i>
                          <label class="flex-1">{{$t('Trigger')}} </label>
                      </div>
                </div>
              </div>
            
              <div class="">
                <label for="direction">{{$t('Seconds Between Pictures')}}
                  <span class="info-icon ion-information-circled" v-tooltip="tooltips.secondsBetweenPictures"></span>
                </label>
                <input :disabled="!isEditable" id="direction" v-model.number="equipmentRow.triggerSecondsBetweenPictures" step="1" min="0" type="number" class="validate"
                >
              </div>
            <div v-if="equipmentRow.triggersEnabled">
                <label for="direction">{{$t('Quiet Period (Seconds)')}}
                  <span class="info-icon ion-information-circled" v-tooltip="tooltips.quietPeriod"></span>
                </label>
                <input :disabled="!isEditable" id="direction" v-model.number="equipmentRow.quietPeriodSeconds" step="1" min="0" type="number" class="validate"
                >
              </div>
            </div>



            <div class="p-3 grid grid-cols-2 gap-3">
            
            </div>
  
    
            <div class="col s12"><h5>{{$t('visitEquipForm-fields.retrieval')}}</h5></div>
            <div class="col s12 m4">
              <div class="vue-select-wrapper">
                <label>{{$t('Retrieval Visit Date')}}
                  <span class="info-icon ion-information-circled" v-tooltip="tooltips.visit"></span>
                </label>
                <select :disabled="!isEditable" v-model="equipmentRow.retrieveVisitId" :required ="true">
                  <option value=""> -- </option>
                  <template v-for="(option, index) in getRetrievalOptions">
                    <option :key="index" :value="option.id">{{option.text}}</option>
                  </template>
                </select>
              </div>
            </div>
            <div class="col s12 m6 l4">
              <label>{{$t('Retrieval Visit Time')}}</label>
              <div class="vue-select-wrapper">
                <date-picker :disabled="!isEditable" type="time" :lang="locales[$root.$i18n.locale]" v-model="equipmentRow.exactRetrieveTime" format="HH:mm" value-type="HH:mm:ss"></date-picker>
              </div>
            </div>
            <div class="col s12 m6 l4">
              <label>{{$t('visitEquipForm-fields.equipCondition')}}
                <span class="info-icon ion-information-circled" v-tooltip="tooltips.condition"></span> </label>
              <div class="vue-select-wrapper">
                <select :disabled="!isEditable" v-model="equipmentRow.equipmentConditionId" :required ="true">
                  <option value=""> -- </option>
                  <option v-for="(option, index) in options.equipmentCondition" :key="index" :value="option.id">{{option.type}}</option>
                </select>
              </div>
            </div>

         

          </template>
        </div>
        <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
        <div class="row sticky">
          <div class="col s12 modal-footer">
            <button v-if="isFullForm && isEditable" class="btn btn-success btn-border modal-action modal-close" @click="updateRow">{{$t('common-save')}}</button>
            <button v-if="!isFullForm && isEditable" class="btn btn-success btn-border modal-action modal-close" @click="pickDevice">{{$t('visitEquipForm-fields.swap')}}</button>
            <button class="btn btn-border modal-action modal-close" id="formcancelAdd" @click="close">{{$t('common-close')}}</button>
              <!-- only for sensor not sor non  sensor -->
            <button v-if="!equipmentRow.parentLocationDeploymentEquipmentId && isEditable" class="btn btn-border btn-success modal-action modal-close"
            :class="{'disabled': !(equipmentRow.id)}" @click="addEquipment">{{$t('visitEquipForm-fields.addEquip')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from '@/lib/eventbus';
import {API_URL, copyObject, alertDialog, getAuthorizaitonHeader, capitalizeString} from '@/lib/common';
import FormMixin from '@/components/common/FormMixin.js';
import DatePickerMixin from '@/components/common/DatePickerMixin';
import DatePicker from 'vue2-datepicker'; // new one can pick time
import Autocomplete from 'vuejs-auto-complete';

export default {
  name: 'single-equipment-form',
  props: ['organizationId', 'locationDeploymentId', 'locationLabel', 'options', 'visitId', 'initEquipmentRow', 'isEditable', 'equipmentOption', 'isFullForm'],
  components: {DatePicker, 'auto-complete': Autocomplete},
  mixins: [FormMixin, DatePickerMixin],
  data () {
    return {
      // url: API_URL + 'get-location-by-id',
      // locationUpdateUrl: API_URL + 'insert-update-location',
      // deleteUrl: API_URL + 'delete-location',
      // // formName:'location',
      equipmentRow: {containsHumanFeature: false, triggersEnabled: true, timeLapseEnabled: true, workingTestDone: false},
      equipmentObj: null,
      equipmentTypeId: null,
      toShowNewEquipmentForm: false,
      insertEquipmentRow: {},
      errors: [],
      recordName: this.$t('common-equipment').toLowerCase(),
      invalidEntry: null,
      tooltips: {
        visit: this.$t('visitEquipForm-tooltips.visit'),
        deploymentType: this.$t('visitEquipForm-tooltips.deploymentType'),
        target: this.$t('visitEquipForm-tooltips.target'),
        stakeDistance: this.$t('visitEquipForm-tooltips.stakeDistance'),
        height: this.$t('visitEquipForm-tooltips.height'),
        direction: this.$t('visitEquipForm-tooltips.direction'),
        condition: this.$t('visitEquipForm-tooltips.condition'),
        sensorTest: this.$t('visitEquipForm-tooltips.sensorTest'),
        facingHumanFeature: this.$t('The "Facing Human Feature" flag is a binary field (True/False) that can be enabled to allow users to identify whether or not a) the camera is facing a human feature or b) the ARU in the range of a human feature, depending on the sensor type. The options include: True or False (default)'),
        quietPeriod: this.$t('Quiet Period is a numerical field indicating the time (seconds) between firing initially and firing a second time (i.e. the recovery time). The Quiet Period is preset in the camera’s settings by the user. If a Quiet Period was not specified, this field should be set to “0” (i.e., “NO DELAY” in Reconyx cameras; default).'),
        walkTestDistance: this.$t('WalkTest Distance is a numerical field that indicates the horizontal distance (m) at which the crew members perform the WalkTest (using the WalkTest mode; if applicable), which helps to standardize camera’s detection zones by ensuring that the camera height, tilt, etc. adequately captures the desired detection zone. The user will 1) activate the WalkTest mode, 2) attach the camera at the desired height/angle, 3) walk in front of the camera to a specified distance (i.e., the "WalkTest distance" e.g., 5 m), and 4) wave their hand in front of the camera (usually at ground level and at a chosen height [i.e., the "WalkTest height", e.g., 0.8 m]) to determine if the camera is activating. If the camera is set correctly (based on the user’s criteria), an indicator light will flash to signal that the sensor is detecting heat and/or motion (thus indicating the camera’s active motion detection zone). Users can leave this field blank if they did not perform a WalkTest or if they are not interested in collecting this information.'),
        walkTestHeight: this.$t('WalkTest Height is a numerical field that indicates the vertical distance (height; m) at which the crew members perform the WalkTest (using the WalkTest mode; if applicable), which helps to standardize camera’s detection zones by ensuring that the camera height, tilt, etc. adequately captures the desired detection zone. The user will 1) activate the WalkTest mode, 2) attach the camera at the desired height/angle, 3) walk in front of the camera to a specified distance (i.e., the "WalkTest distance" e.g., 5 m), and 4) wave their hand in front of the camera (usually at ground level and at a chosen height [i.e., the "WalkTest height", e.g., 0.8 m]) to determine if the camera is activating. If the camera is set correctly (based on the user’s criteria), an indicator light will flash to signal that the sensor is detecting heat and/or motion (thus indicating the camera’s active motion detection zone). Users can leave this field blank if they did not perform a WalkTest or if they are not interested in collecting this information.'),
        secondsBetweenPictures: this.$t('“Motion Image Interval” is a numeric field that provides the time (seconds) between events (triggers) that occur due to motion, heat, or triggering of external trigger devices.  The Motion Interval is pre-set in the camera’s settings by the user; the time at which the camera collects images as a result of this setting IS influenced by the presence of movement or heat. For example, if the settings for a camera included 3 images per detection event (“trigger”) and a Motion Image Interval of 3s, if the camera’s sensor detects motion, the first image will be collected (e.g., at 09:00:00), the second images will be collected 3 seconds afterwards (09:00:03), and the third will be collected 3 seconds after the second image (09:00:06). <br /><br />This setting differs from the Quiet Period in that for the Motion Image Interval, the delay in collecting an image occurs between images within a multi-image sequence, rather than between multi-image sequences (Quiet Period). If a Motion Interval was not specified, this field should be set to “0” (i.e., “Rapidfire” for Reconyx camera; default)')
      },
      msg: {
        visitRequired: this.$t('visitEquipForm-messages.visitRequired'),
        equipmentRequired: this.$t('visitEquipForm-messages.equipmentRequired')
      },
      isCamera: null,
      isSensorDevice: null
    }
  },
  created () {
    console.log(this.initEquipmentRow);
    this.equipmentRow = {...this.equipmentRow, ...copyObject(this.initEquipmentRow)};
    this.equipmentObj = (this.equipmentRow || {}).equipment || {};
    this.equipmentTypeId = this.equipmentObj.type;

   // if (typeof this.equipmentRow.containsHumanFeature == 'undefined') this.equipmentRow.containsHumanFeature = false;
  },
  computed: {
  /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    },
    // getAttachable () {
    //   try {
    //     return this.options.EquipmentType.find(x => x.equipmentRow.type).attachment;
    //   } catch (e) {
    //     return true;
    //   }
    // },
    getEquipmentCodeUrl () {
      return API_URL + 'get-equipment-filtered?organizationId=' + this.organizationId +
        (this.equipmentTypeId ? '&equipmentTypeId=' + this.equipmentTypeId : '') + '&filterText=';
    },
    /* filter retrieval Options, that is later than deployment options */
    getRetrievalOptions () {
      if (!this.equipmentRow.deployVisitId) { // if deployment not selected, return all
        return this.options.retreiveVisitId;
      }
      // otherwise, return retrieval that is later than deployment

      const deploymentOpt = this.options.retreiveVisitId.find(x => this.equipmentRow.deployVisitId === x.id);
      if (!deploymentOpt) {
        return this.options.retreiveVisitId;
      }

      return this.options.retreiveVisitId.filter(x => x.text > deploymentOpt.text);
    }
  },
  watch: {
    initEquipmentRow: function (newVal, oldVal) {
      this.equipmentRow = copyObject(newVal);
      this.equipmentObj = (this.equipmentRow || {}).equipment || {};
      this.equipmentTypeId = this.equipmentObj.type;
    },
    equipmentTypeId: function (newVal, oldVal) {
      this.isCamera = this.equipmentRow.parentLocationDeploymentEquipmentId == null && (this.equipmentTypeId == null || this.equipmentTypeId === 2);
      this.isSensorDevice = this.equipmentRow.parentLocationDeploymentEquipmentId == null && (this.equipmentTypeId == null || this.equipmentTypeId === 1 || this.equipmentTypeId === 2);
    }
  },
  methods: {
    close () {
      this.errors = [];
      this.showTab = 'main';
      this.clearEquipment();
      this.equipmentTypeId = null;
      eventBus.$emit('close-equipment-detail-form');
    },

    pickDevice () {
      if (!this.equipmentRow.equipmentId) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.selectionRequired);
        return;
      }
      eventBus.$emit('switch-to-equipment-id', this.equipmentRow.equipmentId);
    },
    updateRow () {
      
      if (!this.isEditable) {
        alertDialog(this.$modal, this.commonMsg.deny.update, this.$t('common-denied'), 600);
        return;
      }

      this.errors = [];
      if (!this.equipmentRow.deployVisitId) {
        this.errors.push(this.msg.visitRequired);
      }

      if (!this.equipmentRow.equipmentId) {
        this.errors.push(this.msg.equipmentRequired);
      }
      if (this.equipmentRow.heightMeters > 999.999) {
        this.errors.push(this.$t('visitEquipForm-messages.height'));
      }

      if (this.errors.length > 0) {
        return;
      }

      if (!this.equipmentRow.locationDeploymentId) {
        this.equipmentRow.locationDeploymentId = this.locationDeploymentId;
      }

      if (typeof this.equipmentRow.containsHumanFeature == 'undefined') {
        this.equipmentRow.containsHumanFeature = false;
      }
      const self = this;
      console.log(this.equipmentRow);
      this.commonInsertUpdate(API_URL + 'insert-update-location-equipment', this.equipmentRow, (this.equipmentRow.id ? 'update' : 'add'), function (response) {
        self.equipmentRow = response.data;
        eventBus.$emit('reload-visit-equipment-table');
      });
    },
    displayEquipmentDetails (row) {
      /** to do check equipment types  */
      if (row) {
        this.equipmentObj = copyObject(row.selectedObject);
        this.equipmentTypeId = this.equipmentObj.equipmentType.id;
        this.equipmentRow.equipmentId = this.equipmentObj.id;
        this.invalidEntry = null;
        this.isCamera = this.equipmentRow.parentLocationDeploymentEquipmentId == null && (this.equipmentTypeId == null || this.equipmentTypeId === 2);
        this.isSensorDevice = this.equipmentRow.parentLocationDeploymentEquipmentId == null && (this.equipmentTypeId == null || this.equipmentTypeId === 1 || this.equipmentTypeId === 2);
      }
    },
    /* check if the device can have an attachement with selected device type */
    isMainDevice (mainDeviceId, attachableDeivceTypeId) {
      if (mainDeviceId) {
        try {
          const mainDeviceTypeId = this.equipmentOption.find(x => x.id === mainDeviceId).typeId;
          return (this.options.EquipmentTypeAttachments || []).findIndex(x => x.primaryEquipmentTypeId === mainDeviceTypeId && x.attachableEquipmentTypeId === attachableDeivceTypeId) !== -1;
        } catch (e) {}
      }
      return (this.options.EquipmentTypeAttachments || []).findIndex(x => x.primaryEquipmentTypeId === attachableDeivceTypeId) !== -1;
    },
    /* in general if the device is attachable to a sensor */
    isAttacheableDevice (deviceId) {
      return (this.options.EquipmentTypeAttachments || []).findIndex(x => x.attachableEquipmentTypeId === deviceId) !== -1;
    },
    clearEquipment () {
      this.equipmentObj = {};
      this.equipmentRow.equipmentId = null;
      this.$refs.autocomplete && this.$refs.autocomplete.clear();
      this.invalidEntry = null;
    },
    addEquipment () {
      this.close();
      eventBus.$emit('add-equipment', this.equipmentRow);
    },
    changeDeviceType () { // when change sensor clear previously filled values for sensor specified fields
      this.clearEquipment();
      if (this.isSensorDevice) {
        if (!this.isCamera) {
          this.equipmentRow.targetId = null;
          this.equipmentRow.stakeDistanceMeters = null;
          this.equipmentRow.retrievalBatterPercent = null;
        }
      }
    },
    hasResults () {
      this.invalidEntry = null;
    },
    saveInvalidAutoFill (obj) {
      this.invalidEntry = this.$refs.autocomplete.display;
    },
    /* register a new equipment */
    saveEquipmentRow () {
      const self = this;
      let params = { action: 'inserted',
        data: {...{organization: self.organizationId, type: self.equipmentTypeId, status: 1}, ...this.insertEquipmentRow},
        id: new Date()
      };
      this.commonInsertUpdate(API_URL + 'insert-update-delete-equipment', params, 'add', function (response) {
        const newId = response.data.tid;
        self.equipmentObj = {code: self.insertEquipmentRow.code, id: newId, make: self.insertEquipmentRow.make, model: self.insertEquipmentRow.model, organization: self.organizationId, type: self.equipmentTypeId};
        self.equipmentRow.equipmentId = newId;
        if (self.$refs.autocomplete) {
          self.$refs.autocomplete.display = self.insertEquipmentRow.code;
          self.commonGet(self.getEquipmentCodeUrl + self.insertEquipmentRow.code, null, false, (response) => {
            self.displayEquipmentDetails({selectedObject: response.data && response.data[0]})
          });
        }
        self.toShowNewEquipmentForm = false; // close form
      });
    },
    capitalize (str) {
      return capitalizeString(str);
    }
  }
}
</script>
<style scoped>
.newEquipmentForm {
  width: 300px;
  position: absolute;
  background: #dbdce0;
  z-index: 3;
  right: 20px;
  top: 30px;
  padding: 15px;
}

textarea:disabled{
  resize:none;
}

.inactive{
  cursor: default !important;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding-top: 10px;
}

</style>
