var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      class: { open: _vm.showForm },
      staticStyle: {
        "z-index": "1003",
        width: "65%",
        display: "block",
        opacity: "1",
        transform: "scaleX(1)",
        top: "10%"
      },
      attrs: { id: "agreementModal" }
    },
    [
      _c(
        "div",
        { staticClass: "iconButton close-modal", on: { click: _vm.close } },
        [_c("span", { staticClass: "ion-close" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "container overflow" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s12 padded20 dataGroup" }, [
            _c("h5", [_vm._v(_vm._s(_vm.$t("agreementForm-title")))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("agreementForm-fields.name")))]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row.name,
                  expression: "row.name"
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t("agreementForm-fields.name")
              },
              domProps: { value: _vm.row.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.row, "name", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("common-startDate")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vue-select-wrapper" },
              [
                _c("date-picker", {
                  attrs: {
                    type: "date",
                    valueType: "format",
                    lang: _vm.locales[_vm.$root.$i18n.locale],
                    format: "YYYY-MM-DD",
                    "use-utc": true
                  },
                  model: {
                    value: _vm.row.startDate,
                    callback: function($$v) {
                      _vm.$set(_vm.row, "startDate", $$v)
                    },
                    expression: "row.startDate"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m6" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("common-endDate")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vue-select-wrapper" },
              [
                _c("date-picker", {
                  attrs: {
                    type: "date",
                    valueType: "format",
                    lang: _vm.locales[_vm.$root.$i18n.locale],
                    format: "YYYY-MM-DD",
                    "use-utc": true
                  },
                  model: {
                    value: _vm.row.endDate,
                    callback: function($$v) {
                      _vm.$set(_vm.row, "endDate", $$v)
                    },
                    expression: "row.endDate"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12" }, [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("agreementForm-fields.constraints")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vue-select-wrapper" }, [
              _vm.options.projectUsageAgreement != null
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.row.uaoId,
                          expression: "row.uaoId"
                        }
                      ],
                      staticClass: "initialized",
                      attrs: { type: "number" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.row,
                            "uaoId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.options.projectUsageAgreement, function(
                      option,
                      index
                    ) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: option.id } },
                        [_vm._v(_vm._s(option.type))]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12" }, [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("agreementForm-fields.signeeInfo")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m6 l4" }, [
            _c("label", { attrs: { for: "full-name" } }, [
              _vm._v(_vm._s(_vm.$t("agreementForm-fields.fullName")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row.signeeUserName,
                  expression: "row.signeeUserName"
                }
              ],
              attrs: {
                id: "full-name",
                type: "text",
                placeholder: _vm.$t("agreementForm-fields.fullName")
              },
              domProps: { value: _vm.row.signeeUserName },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.row, "signeeUserName", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m6 l4" }, [
            _c("label", { attrs: { for: "signeeEmail" } }, [
              _vm._v(_vm._s(_vm.$t("agreementForm-fields.email")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row.signeeEmail,
                  expression: "row.signeeEmail"
                }
              ],
              attrs: {
                id: "signeeEmail",
                type: "text",
                placeholder: _vm.$t("agreementForm-fields.email")
              },
              domProps: { value: _vm.row.signeeEmail },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.row, "signeeEmail", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m6 l4" }, [
            _c("label", [_vm._v(_vm._s(_vm.$tc("common-organization", 1)))]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row.signeeOrganization,
                  expression: "row.signeeOrganization"
                }
              ],
              attrs: {
                id: "signeeOrganization",
                type: "text",
                placeholder: _vm.$tc("common-organization", 1)
              },
              domProps: { value: _vm.row.signeeOrganization },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.row, "signeeOrganization", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 m6 l4" }, [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("agreementForm-fields.signedDate")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vue-select-wrapper" },
              [
                _c("date-picker", {
                  attrs: {
                    lang: _vm.locales[_vm.$root.$i18n.locale],
                    valueType: "format",
                    format: "YYYY-MM-DD",
                    "use-utc": true
                  },
                  model: {
                    value: _vm.row.signeeSignedDate,
                    callback: function($$v) {
                      _vm.$set(_vm.row, "signeeSignedDate", $$v)
                    },
                    expression: "row.signeeSignedDate"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.agreement.fileName != null
            ? _c("div", { staticClass: "col s12" }, [
                _c("p", [
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          return _vm.downloadStreamFile(
                            _vm.getAgreementFileDownload + _vm.agreement.id,
                            _vm.agreement.fileName,
                            "application/octet-stream"
                          )
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "icon ion-android-attach" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("agreementForm-fields.viewAgreement"))
                      )
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col s12" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("agreementForm-fields.uploadAgreement")) + " "
              ),
              _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t("agreementForm-tooltip"),
                    expression: "$t('agreementForm-tooltip')"
                  }
                ],
                staticClass: "ion-information-circled"
              })
            ]),
            _vm._v(" "),
            _c("input", {
              ref: "chooseFile",
              staticClass: "input-file",
              staticStyle: { display: "none" },
              attrs: {
                type: "file",
                enctype: "multipart/form-data",
                name: "file",
                accept: ".jpg,doc,.docx,.pdf,application/msword"
              },
              on: {
                change: function($event) {
                  return _vm.trackUploadFile(
                    $event.target.name,
                    $event.target.files
                  )
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                staticStyle: { width: "30%" },
                attrs: { type: "button", id: "browseFileBtn" },
                on: { click: _vm.browse }
              },
              [
                _c("span", { staticClass: "glyphicon glyphicon-refresh" }),
                _vm._v(" " + _vm._s(_vm.$t("common-fileBtn")) + "\n        ")
              ]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.uploadFilename))])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col s12" },
            _vm._l(_vm.messages, function(message, index) {
              return _c("div", { key: "msg" + index, staticClass: "error" }, [
                _vm._v(" " + _vm._s(message))
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col s12 padded20" }, [
            _c(
              "button",
              { staticClass: "btn inverted", on: { click: _vm.saveAgreement } },
              [_vm._v(_vm._s(_vm.$t("common-save")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-border", on: { click: _vm.close } },
              [_vm._v(" " + _vm._s(_vm.$t("common-close")) + " ")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }