import {API_URL} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
// shared by species verification and tagging page
export default {
  data () {
    return {
      getStaticOptionUrl: API_URL + 'get-camera-project-user-deployment-static-options', // static options such as sex age
      isFullyTaggedUrl: API_URL + 'camera-is-pud-fully-tagged?pudId=',
      isFullyTagged: null,
      staticOptions: null,
      speciesObj: null,
      warning: null
    }
  },
  created () {
    const self = this;
    eventBus.$on('toggle-modal-form', function (name, show) {
      self.toggleDialog(name, show);
    });
    /* when user selection of images chagned, update number in button */
    // eventBus.$on('selection-change', function (selection, name) {
    //   self.selectedImageCount = selection.length;
    // });
    // eventBus.$on('manual-selection-change', function (selection, name) {
    //   self.selectedImageCount = selection.length;
    // });
    /* show or hide form by setting ui parameter */
    eventBus.$on('toggle-form', function (name, show) {
      self.ui[name] = show;
    });
  },
  methods: {
    toggleDialog (dialogName, toShow) {
      if (toShow) {
        this.$modal.show(dialogName);
      } else {
        this.$modal.hide(dialogName);
      }
    },

    tagSelected () {
      this.$refs.tag.batchEditImageTags();
    },
    loadStaticOption (bRefersh) {
      if (bRefersh && !this.userDeploymentId) { // don't do refresh when there is no user deployment id
        return;
      }
      this.$http.get(this.getStaticOptionUrl + (this.userDeploymentId ? '?pudId=' + this.userDeploymentId : '?projectId=' + this.projectId)).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.staticOptions = response.data;
            // combine species: bird, domestic, animal and others into one big array
            this.staticOptions.allSpecies = [];
            if (!this.staticOptions.priority || this.staticOptions.priority.length === 0) {
              this.staticOptions.priority = [];
            }

            const combinded = [].concat(...this.staticOptions.priority, ...this.staticOptions.species, ...this.staticOptions.domestic, ...this.staticOptions.bird, ...this.staticOptions.none);
            let uniqueSet = new Set([]);
            combinded.forEach(x => { uniqueSet.add(JSON.stringify(x)); });
            this.staticOptions.allSpecies = [...uniqueSet].map(x => JSON.parse(x));

            if (this.speciesId) { // only for spp verificaiton
              this.speciesObj = this.staticOptions.allSpecies.find(x => x.id === this.speciesId)
            }
          }
        });
    }
  }
}
