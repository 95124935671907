<template>
  <div>
    <modal name="fov-preview-form"
      :scrollable="true"   :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroller"
      draggable=".drag-handle"
      :delay="100"
      :width = "700"
      :height = "700"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "false"
    >
      <div @click="closeForm" class="iconButton link">
        <span class="ion-close"></span>
      </div>
      <div class="modal-header drag-handle">
        <h6>{{$t('fovPreview-updatePreview')}}</h6>
      </div>

      <div class="modal-scroller">
        <div class="message-row" v-if="fovResult && fovResult.length > 0">{{$t('fovPreview-pleaseReview')}}</div>

        <div class="message-row" v-for="(item, index) in fovResult"  :key="'fov' + index">
          <div v-if="item.errorMessage">
            <span class="red-font ion-android-warning"> {{item.errorMessage}}</span>
          </div>
          <div v-else>
            <span class="red-font ion-android-checkmark-circle"/>
              {{$t('fovPreview-changing')}}<span class="orange-font"> {{item.count}}</span> <span class="orange-font">{{item.action}}</span>
          </div>
          <div><label>{{$t('fovPreview-startTime')}}:</label> <span class="blue-font"> {{item.startTime}}</span></div>
          <div><label>{{$t('fovPreview-endTime')}}:</label><span class="blue-font">{{item.endTime}}</span></div>
        </div>
        <div class="row">
          <div class="col s12 error padded20">
            {{message}}
          </div>
          <div class="col s12 m6 center " v-show="!hasError">
            <button  class="btn btnimportant btn-success" @click="verifyFOV">{{$t('fovPreview-verifyFov')}}</button>
          </div>
          <div class="col s12 center " v-show="hasError">
            <p class="error">{{$t('fovPreview-pleaseFixErrors')}}</p>
          </div>
          <div class="col s12 center " :class="{'m12':hasError, 'm6':!hasError}">
            <button class="btn btnimportant btn-success" @click="closeForm">{{$t('common-close')}}</button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
/* this component show batch upload from
*/
import {API_URL} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';

export default {
  name: 'camera-fov-preview-form',
  props: ['fovResult', 'userDeploymentId'],

  created () {
  },
  watch: {
    fovResult (newVal, oldVal) {
      this.hasError = (this.fovResult.findIndex(function (element) {
        return element.errorMessage != null;
      }) !== -1);
    }
  },
  methods: {
    closeForm () {
      eventBus.$emit('toggle-modal-form', 'fov-preview-form', false);
    },
    verifyFOV () {
      this.message = '';
      const params = {
        pudId: this.userDeploymentId
      };
      this.$http.post(this.verifyFOVUrl, params).then(
        (response) => {
          if (response.data.hasOwnProperty('error')) {
            this.message = this.$t('fovPreview-failedToVerify') + ' ' + response.data.error;
          } else {
            if (response.data.isComplete) {
              this.message = this.$t('fovPreview-fovVerified');
              eventBus.$emit('update-fov-verified-flag', true);
              eventBus.$emit('refresh-tag');
            }
          }
        },
        (error) => {
          this.message = this.$t('fovPreview-failedToVerify') + ' ' + error;
        });
    }
  },
  data () {
    return {
      hasError: null,
      message: null,
      verifyFOVUrl: API_URL + 'camera-complete-field-of-view'
    }
  }
}
</script>
<style scoped>
.message-row {
  padding: 15px;
}
</style>
