/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
'use strict'

// import VueResource from 'vue-resource'
import { API_URL, alertDialog, capitalizeString } from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import UploadCSVForm from '@/components/common/UploadCSVForm';

export default {
  props: ['project', 'projectId', 'isManager', 'filterOptions', 'userList', 'isUserProjectMember', 'statusId', 'isPublished', 'hasAssignedTasks'],
  components: {'upload-location-csv': UploadCSVForm},
  methods: {
    downloadLocationCSV () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.$http.get(this.apiUrls.downloadLocationUrl, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.getFileName();
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloading = false;
      },
      (err) => {
        this.downloading = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    uploadLocationCSV () {
      this.$modal.show('location-upload-form');
    }, 
      getLocationOptions () {
      let self = this;
      this.$http.get(API_URL + 'get-location-options').then(resp => {
        self.options = resp.data;
      });
    },
    switchTaskView () {
      // switch between showing all tasks and only the tasks where the user is assigned
      this.toggleInitSetting = !this.toggleInitSetting;
      console.log(this.toggleInitSetting);
      this.tableOptions.showOwnOnly = !this.tableOptions.showOwnOnly;
      this.$refs.table.refresh();
    },
    recordingRowUrl: function (id) {
      return this.apiUrls.idUrl + id;
    },


    onRowClick (event) {
      let rowId = parseInt(event.row.id);
      event.event.target.closest('tr').querySelectorAll('input[data-id]')[0].click();

    },
    resetCheckBoxes() {
      //this.$set(this.tableOptions, 'activeRowId', []);
      this.$refs.table.$el.querySelectorAll('input[data-id]:checked').forEach((n)=>{
          n.click();
         // this.onCheckClick(n.getAttribute('data-id'))          
        });
    },
    onCheckAllClick(event) {
      if (this.tableOptions.activeRowId.length == 0) {
        this.$refs.table.$el.querySelectorAll('input[data-id]').forEach((n)=>{
          n.click();
          //this.onCheckClick(n.getAttribute('data-id'))          
        });
      } else {
        this.$refs.table.$el.querySelectorAll('input[data-id]:checked').forEach((n)=>{
          n.click();
         // this.onCheckClick(n.getAttribute('data-id'))          
        });
      }
    },
    onCheckClick(row) {

        let rowId = parseInt(row.id);
        let activeRows = [...this.tableOptions.activeRowId];
        let exists = activeRows.indexOf(rowId);

        if (exists > -1) { // Remove
          //this.tableOptions.activeRowId.splice(exists,1);
          activeRows.splice(exists, 1);
          this.$set(this.tableOptions, 'activeRowId',  activeRows);

          let index = this.activeRows.findIndex(activeRow=>activeRow.id == row.id);
          if (index >= 0)
            this.activeRows.splice(index, 1);

        } else { // Add
          this.$set(this.tableOptions, 'activeRowId', [...this.tableOptions.activeRowId, rowId]);
          //this.tableOptions.activeRowId.push(rowId);
          this.activeRows.push(row);
        }
    },



    clearMessage () {
      this.message = '';
    },

    randomAssignDone (msg) {
      this.randomAssignLoading = false;
      this.message = msg;
    },
    randomAssign () {
      if (!this.randomAssignUsers || this.randomAssignUsers.length === 0) {
        alertDialog(this.$modal, this.$t('taskTable-taggerEmpty'), this.$t('taskTable-mustAssign'));
        return;
      }
      this.randomAssignLoading = true;
      const params = {
        projectId: this.projectId,
        userIds: this.randomAssignUsers.map(x => x.id)
      }

      this.$http.post(this.apiUrls.assignUser, params).then(
        (response) => {
          this.randomAssignLoading = false;
          if (response.data.hasOwnProperty('error')) {
            this.randomAssignDone(response.data.error);
          } else {
            this.randomAssignDone(this.$t('taskTable-userAssignmentDone'));
            eventBus.$emit('reload-task-table-option');
            if (this.$refs.table) {
              this.$refs.table.refresh();
            }
          }
        },
        (error) => this.randomAssignDone(error)
      ).catch((error) => this.randomAssignDone(error));
    },

    getClass (status) {
      if (typeof status === 'undefined') { return; }

      /* 'ion-checkmark':props.row.statusType =='Transcribed','ion-alert':props.row.statusType =='Open',
      'ion-ios-pulse':props.row.statusType == 'In Progress',
      'ion-ios-pricetag': props.row.statusType =='New', 'ion-ios-thunderstorm font24': props.row.statusType =='Bad Weather',
      'ion-bug font14': props.row.statusType =='Malfunction',
      'ion-clipboard': props.row.statusType =='Review' */
      let className;
      /*
      NOTE these are not visible strings, these are unique ids
      */
      switch (status.toLowerCase()) {
        case 'transcribed':
        case 1:
        case 'tag-completed':
          className = 'ion-checkmark';
          break;
        case 'open':
          className = 'ion-alert';
          break;
        case 'in progress':
          className = 'ion-ios-pulse';
          break;
        case 'new':
        case 'ready for tagging':
        case 'Ready for auto-tagger':
          className = 'ion-ios-pricetag';
          break;
        case 'bad weather':
          className = 'ion-ios-thunderstorm';
          break;
        case 'malfunction':
          className = 'ion-bug';
          break;
        case 'review':
          className = 'ion-clipboard';
          break;
        case 'Pending auto-tagger':
          className = 'ion-clock';
          break;
        case 'uploading':
          className = 'ion-cloud-upload';
          break;
      }
      return { [className]: true, [status.replace(/\s/g, '-')]: true };
    },
    tableLoaded () {
      /*
      Only do this the first time table is loaded
      if user has at least 1 task, can set toggle to view only theirs
      If not, see all tasks
          */
      // if (!this.tableLoadedOnce) {
      //   this.toggleInitSetting = this.hasAssignedTasks;
      // }

      // if (!this.tableLoadedOnce) {
      //   this.tableLoadedOnce = true;
      //   if (this.$refs.table.data.length > 0) {
      //     // user has at least one task
      //     // this.tableOptions.showOwnOnly = true;
         
      //     console.log(this.hasAssignedTasks);
      //   } else {
      //     console.log('ere too');
      //     // this.tableOptions.showOwnOnly = false;
      //   }
      //   // this.$refs.table.refresh();
      // }
    },
    showDeleteTaskForm (tasks) {
      console.log(tasks);
      this.taskIds = tasks.map(task=>task.id);
      this.taskRows = tasks;
      this.showDeleteForm = true;
      this.$modal.show(this.deleteFormName);
    },
    hideDeleteTaskForm () {
      this.taskIds = [];
      this.taskRows = [];
      this.showDeleteForm = false;
      this.$modal.hide(this.deleteFormName);
    },
    // for the manage button functions:
    downloadTags () {
      if (this.downloadingTags) {
        return;
      }

      this.downloadingTags = true;
      this.$http.get(this.apiUrls.downloadTagsCSVUrl + this.projectId, {
        headers: { 'Content-Type': 'application/zip', 'Accept': '*/*' },
        responseType: 'arraybuffer'
      }).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], { type: 'text/csv;charset=utf-8' });
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.getFileName('tag') + (this.sensorId === 'CAM' ? '.zip' : '.csv');
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingTags = false;
      },
      (err) => {
        this.downloadingTags = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    getFileName (isTags) {
      let type;
      if (this.sensorId === 'PC') {
        type = this.$tc('common-survey', 2);
      } else {
        type = isTags ? this.$tc('common-tag', 2) : this.$tc('common-task', 2);
      }
      let now = new Date();
      let filename = this.projectName + '_' + type + '_' + now.getFullYear();
      if ((now.getMonth() + 1) < 10) {
        filename += '0' + (now.getMonth() + 1) + now.getDate();
      } else {
        filename += (now.getMonth() + 1) + now.getDate();
      }
      return filename;
    },
    uploadTagsCSV () {
      this.uploadingTags = true;
      this.$modal.show('project-upload-form');
    },
    downloadCodes () {
      if (this.downloadingCodes) {
        return;
      }
      let name;
      switch (this.sensorId) {
        case 'ARU': {
          name = this.$t('common-aru') + '_' + capitalizeString(this.$tc('common-code', 2)) + '.zip';
          break;
        }
        case 'PC': {
          name = this.$t('common-pointCount') + '_' + capitalizeString(this.$tc('common-code', 2)) + '.zip';
          break;
        }
        case 'CAM': {
          name = this.$t('common-camera') + '_' + capitalizeString(this.$tc('common-code', 2)) + '.zip';
          break;
        }
      }
      this.downloadingCodes = true;
      this.$http.get(this.apiUrls.downloadCodesURL, {
        headers: { 'Content-Type': 'application/zip', 'Accept': '*/*' },
        responseType: 'arraybuffer'
      }).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], { type: 'text/csv;charset=utf-8' });
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = name;
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingCodes = false;
      },
      (err) => {
        this.downloadingCodes = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    openBatchUpload () {
      eventBus.$emit('open-batch-upload');
    },
    uploadTasks () {
      this.uploadingTags = false;
      this.$modal.show('project-upload-form');
    },
    downloadTasks () {
      if (this.downloadingTasks) {
        return;
      }
      this.downloadingTasks = true;
      this.$http.get(this.apiUrls.downloadTasksUrl + this.projectId, {
        headers: { 'Content-Type': 'application/zip', 'Accept': '*/*' },
        responseType: 'arraybuffer'
      }).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], { type: 'text/csv;charset=utf-8' });
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.getFileName('task') + '.csv';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingTasks = false;
      },
      (err) => {
        this.downloadingTasks = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    }
  },
  created () {
    // this.tableOptions = {...this.commonTableOptions, ...this.tableOptions};
    // randomAssignUrl = API_URL + 'randomly-assign-users-not-assigned-aru-tasks',
    // API_URL + 'randomly-assign-users-not-assigned-camera-puds',
    const self = this;

    // view only the user's tasks by default
    this.tableOptions.showOwnOnly = this.hasAssignedTasks;
    this.toggleInitSetting = this.hasAssignedTasks;

    eventBus.$on('reload-task-table', function () {
      if (self.$refs.table) {
        self.$refs.table.refresh();
      }
    });
    eventBus.$on('close-delete-task-form', function (show) {
      self.hideDeleteTaskForm();
    });

    eventBus.$on('hide-csv-upload-form', (modalName) => {
      this.uploadingTags = null;
      this.$modal.hide(!modalName?this.csvUploadFormName:modalName);
    });
    eventBus.$on('showing-dropdown', () => {
      if (!(this.isPublished || !this.hasProjectWriteAccess)) {
        this.canUpload = true;
      }
    });

    this.getLocationOptions();

  },
  watch: {
    projectId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.message = null;
        this.editUserId = null;
        this.url = API_URL + this.apiUrls.list;
      }
    }
  },
  computed: {
      
      checkAllBoxActive() {
        return this.tableOptions.activeRowId.length == this.tableOptions.perPage;
      },
    toLoadSubRow () {
      /* can't pass in row id, so use table openChildRows:Array to load all
        ideal solution will handle this for subrow toggler click event, however, the click event is not exposed
      */
      const openedRows = this.$refs.table.openChildRows;
      const data = this.$refs.table.data;
      if (openedRows && openedRows.length > 0) {
        openedRows.forEach((rowId) => {
          const row = data.find(x => x.id === rowId);
          // console.log(row);
          if (row && !row.hasOwnProperty('subDetails')) { // bug from table, when move to different page, the openChildRows remain same for the current page,
            row.childRowLoading = true;
            this.$http.get(this.apiUrls.taskDetailsUrl + rowId).then(
              (response) => {
                row.childRowLoading = false;
                this.$set(row, 'subDetails', response.data);
              },
              () => { this.$set(row, 'subDetails', []); }

            ).catch(() => { this.$set(row, 'subDetails', []); });
          }
        });
      }
      return true;
    }
  },
  data () {
    return {
      activeRows:[],
      downloading: false,
      options: null,
      csvUploadFormName: 'location-upload-form',
      apiUrls: {
        downloadLocationUrl: API_URL + 'download-location?projectId=' + this.projectId
      },
      //   initColumns: ['deploymentName', 'startDate', 'motionImageCount', 'taggedCount', 'status', 'tagger'],
      //   initUserColumns: ['deploymentName', 'startDate', 'motionImageCount', 'taggedCount', 'status'],
      editUserId: -1,
      taskId: -1,
      taskRow: null,
      showDeleteForm: false,
      deleteFormName: 'delete_task',
      message: '',
      url: API_URL + 'get-camera-tasks-for-project?projectId=' + this.projectId,
      randomAssignUrl: null,
      toggleInitSetting: null,
      tableLoadedOnce: false,
      randomAssignUsers: [],
      randomAssignLoading: false,
      optionsLoaded: false,
      downloadingTags: false,
      downloadingCodes: false,
      downloadingLocationCSV: false,
      uploadingCodes: false,
      downloadingTasks: false,
      uploadingTags: null, // null: neither. true: tags. false: tasks
      canUpload: false,
      tooltips: {
        disabledUpload: this.$t('taskTable-tooltips.disabledUpload')
      }
    }
  }
}
