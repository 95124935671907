<template>
    <table class="relative" :id="ID" :ref="ID">
        <thead>
            <tr class="w-full ">
                <th class="w-1/2 p-0 px-1 sticky top-0 bg-white">{{ $tc('common-species', 1) }} <input
                        v-model="filter.commonName" /></th>
                <th class="w-1/4 p-0 px-1 sticky top-0 bg-white">{{ $t('common-class') }} <input
                        v-model="filter.className" /></th>
                <th class="w-1/4 p-0 px-1 sticky top-0 bg-white">{{ $t('common-order') }} <input v-model="filter.order" />
                </th>
            </tr>
        </thead>
        <tbody onmousedown="return false" onselectstart="return false">
            <template v-for="(specie, index) in mutableSpecies">
                <tr :key="index" 
                    :data-id="specie.id"
                    :class="{ 'exists': specie.exists, 'hidden': isRowHidden(specie.commonName, specie.className, specie.order), '!border-b !border-gray-300': true, 'cursor-pointer': selectable && !specie.exists, 'bg-gray-300 text-gray-400': specie.exists, 'selected bg-blue-100': selectedSpecies.get(specie.id) }"
                    @mouseover="mouseOver(specie)" @click="addToList(specie)">
                    <td :class="{ 'p-0 px-1 w-1/2 overflow-ellipsis': true }">
                        <div class="flex items-center w-full">
                            <i v-if="specie.exists" class="fas fa-lock mr-1 text-xs flex-initial"
                                v-tooltip="$t('This species cannot be removed because it is being used in the project.')"></i>
                            <span :class="{ 'hidden': showScientificName }">{{ specie.commonName }}</span>
                            <span :class="{ 'hidden': !showScientificName }">{{ specie.scientificName }}</span>
                        </div>
                    </td>
                    <td class="p-0 px-1 w-1/4 overflow-ellipsis">
                        {{ specie.className }}
                    </td>
                    <td class="p-0 px-1 w-1/4 overflow-ellipsis">
                        {{ specie.order }}
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>

export default {
    name: 'species-sort-table',
    props: {
        species: Array,
        showScientificName: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ID: null, // unique ID for the table.
            filter: { commonName: '', className: '', order: '' },
            selectedSpecies: new Map(),
            selectMultiple: false,
            dragSelect: false,
            mutableSpecies: []
        };

    },
    created() {
        this.ID = "speciesSortTable-" + this._uid;
        //Copy props to internal data structure to manipulate
        this.mutableSpecies = this.species;
    },
    mounted() {
        let widget = document.getElementById(this.ID);
        if (widget !== null) {
            widget.addEventListener('mousedown', e => {
                this.dragSelect = true;
            });

            widget.addEventListener('mouseup', e => {
                this.dragSelect = false;
            });

            widget.addEventListener('keydown', function (event) {
                if (event.key === 'Shift') {
                    this.selectMultiple = true;
                }
            });
            widget.addEventListener('keyup', function (event) {
                if (event.key === 'Shift') {
                    this.selectMultiple = false;
                }
            });

            widget.addEventListener('mouseleave', e => {
                this.dragSelect = false;
            });

            widget.addEventListener('blur', e => {
                this.selectMultiple = false;
                this.dragSelect = false;
            });
        }
    },
    watch: {
        species(newVal, oldVal) {
            console.log(newVal);
            if (newVal !== oldVal && newVal !== this.mutableSpecies) {
                this.mutableSpecies = newVal;
            }
        }
    },
    computed: {

    },
    methods: {
        removeSpecies(list) {
            if (!Array.isArray(list)) {
                list = [list];
            }
            this.mutableSpecies = this.mutableSpecies.filter(specie => !list.includes(specie.id));
        },
        addSpecies(list) {
            if (!Array.isArray(list)) {
                list = [list];
            }
            list.forEach(item => {
                this.mutableSpecies.push(this.$store.state.Species.species.find(specie => specie.id === item));
            });
        },
        refresh() {
            this.$forceUpdate();
        },
        getSelectedSpecies() {
            return this.selectedSpecies;
        },
        selectAll() {
            document.querySelectorAll(`#${this.ID} tr:not(.hidden,.exists)`).forEach(node => {
                if (node.hasAttribute('data-id') && node.getAttribute('data-exists') != "1") {
                    this.selectedSpecies.set(parseInt(node.getAttribute('data-id')), 1);
                    node.classList.add('selected');
                    node.classList.add('bg-blue-100');
                }
            });
        },
        unselectAll() {
            document.querySelectorAll(`#${this.ID} tr:not(.hidden)`).forEach(node => {
                if (node.hasAttribute('data-id')) {
                    this.selectedSpecies.delete(parseInt(node.getAttribute('data-id')));
                    node.classList.remove('selected');
                    node.classList.remove('bg-blue-100');
                }
            });
        },
        clearFilter() {
            this.filter = { commonName: '', className: '', order: '' };
        },
        mouseOver(specie) {
            if (this.dragSelect) {
                this.addToList(specie);
            }
        },
        isRowHidden(commonName, className, order) {
            let commonNameExists = this.filter.commonName !== null && this.filter.commonName.length > 0;
            let classNameExists = this.filter.className !== null && this.filter.className.length > 0;
            let orderExists = this.filter.order !== null && this.filter.order.length > 0;

            let commonNameFound = ((new RegExp(`${this.filter.commonName}`, 'i')).exec((commonName || '').toLowerCase()));
            let classNameFound = ((new RegExp(`${this.filter.className}`, 'i')).exec((className || '').toLowerCase()));
            let orderFound = ((new RegExp(`${this.filter.order}`, 'i')).exec((order || '').toLowerCase()));

            if (commonNameExists) {
                if (classNameExists && orderExists) {
                    return !(commonNameFound && classNameFound && orderFound);
                } else if (classNameExists) {
                    return !(commonNameFound && classNameFound);
                } else if (orderExists) {
                    return !(commonNameFound && orderFound);
                }
                return !commonNameFound;
            } else if (classNameExists) {
                if (orderExists) {
                    return !(classNameFound && orderFound);
                }
                return !classNameFound;
            } else if (orderExists) {
                return !orderFound;
            }

            return false;
        },
        addToList(specie) {
            if (specie.exists || !this.selectable) return;
            let id = specie.id;

            if (specie && id) {
                if (this.selectedSpecies && this.selectedSpecies.get(id)) {
                    this.selectedSpecies.delete(id);
                    //  this.$delete(this.selectedSpecies.leftList, id);
                } else {
                    this.selectedSpecies.set(id, 1);
                    // this.$set(this.selectedSpecies.leftList, id, 1);
                }
            }
            this.$forceUpdate();

            this.$nextTick(function () {
                if (this.selectMultiple) {
                    let first, last = null;
                    document.querySelectorAll(`#${this.ID} tr:not(.hidden)`).forEach(node => {
                        if (node.classList.contains('selected')) {
                            if (!first) {
                                first = node.getAttribute('data-id');
                            } else {
                                last = node.getAttribute('data-id');
                            }
                        }
                    });
                    if (first && last) {
                        let record = false;
                        document.querySelectorAll(`#${this.ID} tr:not(.hidden)`).forEach(node => {
                            if (node.getAttribute('data-id') === last) record = false;
                            if (record && node.getAttribute('data-exists') != "1") {
                                this.selectedSpecies[list].set(parseInt(node.getAttribute('data-id')), 1);
                                node.classList.add('selected');
                                node.classList.add('bg-blue-100');
                            }
                            if (node.getAttribute('data-id') === first) record = true;
                        });
                        this.$forceUpdate();
                    }
                }
            });
        },
    }
};
</script>

<style scoped>
</style>