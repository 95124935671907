var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row filter-wrapper" }, [
    _vm.speciesId == null && _vm.options.species
      ? _c(
          "div",
          { staticClass: "col s6 m3 input" },
          [
            _c("multiselect", {
              staticClass: "input",
              attrs: {
                selectAllLabel: _vm.$t("common-selectAll"),
                options: _vm.options.species,
                multiple: true,
                "close-on-select": false,
                placeholder: _vm.$tc("common-species", 2),
                label: "commonName",
                "track-by": "id",
                selectLabel: "",
                deselectLabel: " "
              },
              on: {
                input: function($event) {
                  return _vm.updateSpeciesFilter()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "selection",
                    fn: function({ values, search, isOpen }) {
                      return [
                        _vm.initSpeciesSelected
                          ? _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(_vm._s(_vm.initSpeciesName))
                            ])
                          : [
                              values.length && values.length > 1 && !isOpen
                                ? _c(
                                    "span",
                                    { staticClass: "multiselect__single" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tc(
                                            "common-numSpecies",
                                            values.length,
                                            { num: values.length }
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              values.length == 1 && !isOpen
                                ? _c(
                                    "span",
                                    { staticClass: "multiselect__single" },
                                    [_vm._v(_vm._s(values[0].commonName))]
                                  )
                                : _vm._e()
                            ]
                      ]
                    }
                  }
                ],
                null,
                false,
                2671709226
              ),
              model: {
                value: _vm.filter.speciesId,
                callback: function($$v) {
                  _vm.$set(_vm.filter, "speciesId", $$v)
                },
                expression: "filter.speciesId"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "ion-android-refresh left clear-filter-btn" }, [
      _c(
        "span",
        {
          staticClass: "filter-link",
          on: {
            click: function($event) {
              return _vm.clearFilter()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("common-clear")))]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input" }, [
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: { type: "button", id: "applyFilters" },
          on: {
            click: function($event) {
              return _vm.filterImages()
            }
          }
        },
        [
          _c("span", { staticClass: "ion-android-funnel" }),
          _vm._v(" " + _vm._s(_vm.$t("common-update")) + "\n      ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }