<template>
  <div>
    <carousel :per-page="1" :mouse-drag="false" :pagination-padding="3">
    <slide v-for="(slide, index) in feature" :key="'slide' + index">
      <p class="short" v-for="(row, key) in slide" :key="'slidecontent' + key">
      <span v-if="row != null">{{key}}: <strong>{{row}}</strong></span>
      </p>
    </slide>
  </carousel>
  </div>
</template>

<script>
import Vue from 'vue'
import { Carousel, Slide } from 'vue-carousel';
export default Vue.extend({
  props: {
    feature: {
      required: true,
      type: Array
    }
  },
  components: {
    Carousel,
    Slide
  },
  methods: {
  }
})
</script>
<style scoped>
p.short {
  line-height: 15px;
}
</style>
