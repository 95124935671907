var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "project-table" } },
    [
      _c(
        "div",
        {
          staticClass:
            "col s12 panel boxed padded60 dataGroup tab-content !border-gray-400",
          class: { [_vm.sensor.id]: true }
        },
        [
          _c("div", { staticClass: "button-section-wrapper" }, [
            _vm.canCreateProject
              ? _c("span", { attrs: { id: "people", vcloak: "" } }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t("project-newProjectButton-tooltip"),
                          expression: "$t('project-newProjectButton-tooltip')"
                        }
                      ],
                      staticClass: "btn btn-success projectAdd",
                      on: { click: _vm.createProject }
                    },
                    [
                      _c("span", {
                        class: {
                          "ion-ios-mic": _vm.sensor.id == "ARU",
                          "ion-ios-camera": _vm.sensor.id == "CAM"
                        },
                        staticStyle: { "font-size": "20px" }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              `project-new-${_vm.sensor.id.toLowerCase()}-button`
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.toggleInitSetting !== null
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t(
                          "Toggle on to see all of the projects you have access to. Toggle off to see a list of all projects. Click the dropdown beside the project to request access."
                        ),
                        expression:
                          "$t('Toggle on to see all of the projects you have access to. Toggle off to see a list of all projects. Click the dropdown beside the project to request access.')"
                      }
                    ],
                    staticClass: "project-toggle"
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("project-onlyMine")) + " "),
                    _c("ToggleButton", {
                      attrs: {
                        value: _vm.toggleInitSetting,
                        sync: true,
                        color: "#C94412"
                      },
                      on: { change: _vm.switchProjectView }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.tableOptions.activeRowId.length > 0
              ? _c(
                  "span",
                  { staticClass: "ml-3" },
                  [
                    _c(
                      "dropdown-button",
                      [
                        _c("template", { slot: "menuTitle" }, [
                          _vm._v(
                            _vm._s(_vm.$t("common-manage")) +
                              " (" +
                              _vm._s(_vm.tableOptions.activeRowId.length) +
                              ")"
                          )
                        ]),
                        _vm._v(" "),
                        _c("template", { slot: "dropdownButtons" }, [
                          _c("a", [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "close-popover",
                                    rawName: "v-close-popover"
                                  }
                                ],
                                staticClass:
                                  "btn btn-success popover-menu-item",
                                on: { click: _vm.deleteProjects }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("Delete Projects")) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.optionLoaded
            ? _c(
                "v-server-table",
                {
                  ref: "projectTable",
                  staticClass: "table vue-bordered",
                  attrs: {
                    url: _vm.url,
                    columns: _vm.columns,
                    options: _vm.tableOptions
                  },
                  on: {
                    loaded: function($event) {
                      return _vm.tableLoaded()
                    },
                    limit: _vm.resetCheckBoxes,
                    pagination: _vm.resetCheckBoxes
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "shortNm",
                        fn: function(props) {
                          return [
                            _vm.isSensorAdmin || props.row.requestingUserAdmin
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "editProject modal-trigger projectAdd cursor-pointer",
                                    on: {
                                      click: function($event) {
                                        return _vm.updateProject(props.row)
                                      }
                                    }
                                  },
                                  [_c("span", { staticClass: "ion-edit" })]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "check",
                        fn: function(props) {
                          return [
                            _c("label", [
                              _c("input", {
                                attrs: {
                                  type: "checkbox",
                                  "data-id": props.row.id
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onCheckClick(props.row.id)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span")
                            ])
                          ]
                        }
                      },
                      {
                        key: "fullNm",
                        fn: function(props) {
                          return [
                            props.row.hasProjectAccess
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.taskRowUrl(props.row.id)
                                    }
                                  },
                                  [_vm._v(_vm._s(props.row.fullNm))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !props.row.hasProjectAccess
                              ? _c("span", [_vm._v(_vm._s(props.row.fullNm))])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "statusId",
                        fn: function(props) {
                          return [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.options.status.find(
                                    item => item.id == props.row.statusId
                                  ).type
                                ) +
                                "\n      "
                            )
                          ]
                        }
                      },
                      {
                        key: "child_row",
                        fn: function(props) {
                          return _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col s12 m12 l12" }, [
                              _c("p", { staticClass: "left-align" }, [
                                _vm._v(_vm._s(props.row.description))
                              ]),
                              _vm._v(" "),
                              props.row.canRequestAccess
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "float-right btn btn-border",
                                      on: {
                                        click: function($event) {
                                          return _vm.requestAccess(props.row)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common-requestAccess"))
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              props.row.requestingUserAdmin
                                ? _c("div", { staticClass: "right" }, [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content: !(
                                                props.row.requestingUserAdmin &&
                                                props.row.statusId === 2
                                              )
                                                ? _vm.$t(
                                                    "project-tooltips.mergeButton"
                                                  )
                                                : null
                                            },
                                            expression:
                                              "{ content: !(props.row.requestingUserAdmin && props.row.statusId === 2) ? $t('project-tooltips.mergeButton') : null }"
                                          }
                                        ],
                                        staticClass: "merge-wrapper"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            attrs: {
                                              disabled: !(
                                                props.row.requestingUserAdmin &&
                                                props.row.statusId === 2
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openMergeModal(
                                                  props.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("project-mergeButton")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content:
                                                props.row.tasksCount > 0
                                                  ? _vm.$t(
                                                      "project-tooltips.deleteButton"
                                                    )
                                                  : null
                                            },
                                            expression:
                                              "{ content: props.row.tasksCount > 0 ? $t('project-tooltips.deleteButton') : null }"
                                          }
                                        ],
                                        staticClass: "single-btn-wrapper"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              disabled: props.row.tasksCount > 0
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteProject(
                                                  props.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticStyle: {
                                                "font-size": "20px"
                                              }
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("project-deleteButton")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        }
                      }
                    ],
                    null,
                    false,
                    2092206918
                  )
                },
                [
                  _vm._v(" "),
                  _c("template", { slot: "h__tasksCount" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("project-tableHeadings.tasksCount")) +
                        "\n        "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.$t(
                              "project-tableHeadings.tasksCount-tooltip"
                            ),
                            expression:
                              "$t('project-tableHeadings.tasksCount-tooltip')"
                          }
                        ]
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-info-circle text-yellow-400"
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "h__tasksCompletedCount" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("project-tableHeadings.tasksCompleted")) +
                        "\n        "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.$t(
                              "project-tableHeadings.tasksCompleted-tooltip"
                            ),
                            expression:
                              "$t('project-tableHeadings.tasksCompleted-tooltip')"
                          }
                        ]
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-info-circle text-yellow-400"
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("template", { slot: "h__check" }, [
                    _c("span", { on: { click: _vm.onCheckAllClick } }, [
                      _vm.tableOptions.activeRowId.length ==
                      _vm.tableOptions.perPage
                        ? _c("i", {
                            staticClass: "fa fa-check text-2xl cursor-pointer"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tableOptions.activeRowId.length <
                      _vm.tableOptions.perPage
                        ? _c("i", {
                            staticClass:
                              "fa-regular fa-square-full text-xl cursor-pointer"
                          })
                        : _vm._e()
                    ])
                  ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "project",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            delay: 100,
            width: "99%",
            height: "99%",
            minWidth: 400,
            clickToClose: false,
            shiftY: 0.25
          }
        },
        [
          _vm.projectId != -1
            ? _c("project-form", {
                ref: "projectForm",
                attrs: {
                  "project-id": _vm.projectId,
                  "active-sensor": _vm.sensor.id,
                  options: _vm.options
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "requestAccess",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "75%",
            height: "620",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.projectId != -1
            ? _c("project-access-request-form", {
                ref: "projectAccessRequestForm",
                attrs: {
                  "project-id": _vm.projectId,
                  projectName: _vm.projectName,
                  organizationId: _vm.organizationId,
                  organizationName: _vm.organizationName
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "merge-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "55%",
            height: "auto",
            minWidth: 400,
            minHeight: 500,
            clickToClose: false
          }
        },
        [
          _vm.mergeSourceInfo
            ? _c("merge-locations-form", {
                ref: "mergeLocationForm",
                attrs: {
                  mergeSourceInfo: _vm.mergeSourceInfo,
                  "merge-type": "project"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }