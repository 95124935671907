<template>
    <div v-if="users">
        <div v-if="errors.length > 0" class="my-5 bg-red-300 border border-red-500 p-3 text-red-600 text-sm">
            <span v-for="(error) in errors">{{ error }}</span>
        </div>
        <v-client-table :data="data" :columns="columns" :options="options">
           <template slot="h__Admin">
                {{$t('Admin')}}
                <span v-tooltip="$t('projectForm-tooltips.admin')"> <i class="fas fa-info-circle text-yellow-400"></i></span>
            </template>
            <template slot="h__Read Access">
                {{$t('Read Access')}}
                <span v-tooltip="$t('projectForm-tooltips.read')"> <i class="fas fa-info-circle text-yellow-400"></i></span>
            </template>
            <template slot="name" slot-scope="props">
                <div>{{props.row.name}}</div><div class="text-xs">{{props.row.email}}</div>
            </template>
            <template v-for="(role) in roles" :slot="role.type" slot-scope="props">
                <label>
                    <input type="checkbox" :class="{'exclusive checkbox': true}" :disabled="disabledCheckbox(role.id, props.row)" :checked="props.row.roles.includes(role.id)"
                        @change="() => onCheckboxChange(props.row, role.id)" />
                    <span :class="{'!cursor-default':disabledCheckbox(role.id, props.row)}" />
                </label>
            </template>
            <!-- <template slot="readonly" slot-scope="props">
                <label>
                    <input type="checkbox" class="exclusive checkbox" @change="() => onCheckboxChange(props.row)"
                        v-model="props.row.readonly" />
                    <span />
                </label>
            </template> -->
        </v-client-table>


    </div>
</template>

<script>
import { API_URL } from '@/lib/common';
const ROLE_ADMIN = 1;
const ROLE_READ = 4;
export default {
    name: 'organization-user-assignment-table',
    mixins: [],
    props: {
        'users': { type: Array, required: true },
        'parentId': { type: Number, required: true },
        'parentIdLabel': { type: String, required: true }, //label to send the parent id as. i.e. projectID
        'roles': {type: Array, required: true }
    },
    components: {},
    created() {
        this.roles.reverse().forEach(role=> {
           // this.columns.push(role.type);
            this.options.sortable.push(role.type);
            this.options.headings[role.type] = this.$t(role.type);
            this.options.customSorting[role.type] = (ascending) => {
                return (a,b) => {
                    let incA = a.roles.includes(role.id);
                    let incB = b.roles.includes(role.id);

                    if (ascending) {
                        return incA >= incB?1:-1;
                    }
                    return incA <= incB?1:-1;
                };
            };
            
          
        });
        this.options.cellClasses['Admin'] = [{class:'p-1 w-1/4', condition: ()=> true}];
        this.options.cellClasses['Read Access'] = [{class:'p-1 w-1/6', condition: ()=> true}];

    },
    data() {
        return {
            filterTable: '',
            data: [],
            columns: ['name', 'Read Access','Admin'],
            errors: [],
            options: {
                skin: "assignment-table",
                sortable: ['name'],
                filterable: ['name'],
                customSorting: {
                    'name': function (ascending) {
                        return function (a, b) {
                            var lastA = a.name.trim()[0].toLowerCase();
                            var lastB = b.name.trim()[0].toLowerCase();

                            if (ascending)
                                return lastA >= lastB ? 1 : -1;

                            return lastA <= lastB ? 1 : -1;
                        }
                    }
                },
                cellClasses: {
                    'name': [
                        { class: 'w-1/6 p-1', condition: () => true }
                    ]
                },
                headings: {
                    'name': this.$t('User'),
                },
                perPage: 10,
                perPageValues: [10, 15, 20],
                texts: {
                    count: this.$t('common-tableTexts.user-count'),
                    limit: this.$t('common-tableTexts.user-limit'),
                    noResults: this.$t('dataDiscoverDownload-tableTexts.noResults'),
                    first: this.$t('common-tableTexts.first'),
                    last: this.$t('common-tableTexts.last'),
                    filter: this.$t('common-tableTexts.filter'),
                    filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
                    page: this.$t('common-tableTexts.page'),
                    noRequest: this.$t('common-tableTexts.noRequest'),
                    filterBy: this.$t('common-tableTexts.filterBy'),
                    loading: this.$t('common-tableTexts.loading'),
                    defaultOption: this.$t('common-tableTexts.defaultOption'),
                    columns: this.$t('common-tableTexts.columns')
                },
            }

        }
    },
  
    mounted() {
        this.data = this.users;
    },
    watch: {
        users(newVal, oldVal) {
            this.data = newVal;
        }
    },
    computed: {

    },
    methods: {
        disabledCheckbox(roleId, row) {
             return (roleId === ROLE_READ && row.roles.includes(ROLE_READ) && row.roles.includes(ROLE_ADMIN));
        },
        roleTypeToPropName(role) { // map role type from roles to dataprop sent in to data. i.e. Read Access to readonly
            switch(role.id) {
                case ROLE_ADMIN: return 'admin';
                case ROLE_READ: return 'readonly';
            }
            return null;
        },
        getUserDataObject(userId) {
            return this.data.find(u => userId === u.id) || {};
        },
        onCheckboxChange(user, roleId) {

            // Role exists needs to be removed.
            if (user.roles.includes(roleId)) {
                this.post(user, 'delete', roleId);
                this.getUserDataObject(user.id).roles = this.getUserDataObject(user.id).roles.filter(id => id!=roleId);
            } else {
                this.post(user, 'insert', roleId);
                this.getUserDataObject(user.id).roles.push(roleId);
            }

           


            // if (!user.inProject) { //User not in project

            //     if ((user.admin || user.readonly)) { //Checked admin or readonly or both
            //         this.post(user, 'insert', roleId);
            //         this.getUserDataObject(user.id).admin = user.admin;
            //         this.getUserDataObject(user.id).readonly = user.readonly || user.admin;
            //     }
            //     this.getUserDataObject(user.id).inProject = true;
            // } else {

            //     if (!user.admin && !user.readonly) { //Both admin and readonly unchecked. Remove user from thinger
            //         this.post(user, 'delete', roleId);
            //         this.getUserDataObject(user.id).inProject = false;
            //     } else { //Admin has been unchecked from both being checked.
            //         this.post(user, 'update', roleId);
            //     } //
            //     this.getUserDataObject(user.id).admin = user.admin;
            //     this.getUserDataObject(user.id).readonly = user.readonly;

            // }
        },
        clearErrors() {
            this.errors = [];
        },
        post(user, action, roleId) {

            let endpoint = 'insert-organization-user-role';
            if (action === 'delete' || action === 'update')
                endpoint = 'delete-organization-user-role';

            let params = {
                roleId: roleId,
                userId: user.id
            };
            params[this.parentIdLabel] = this.parentId;
            this.clearErrors();

            this.$http.post(API_URL + endpoint, params).then((response) => {
                if (response.data.hasOwnProperty('error')) {
                    this.errors.push(this.$t('projectForm-userAddError') + response.data.error);
                } else {

                }
            });
        }

    }
};

</script>

<style>
.VueTables__limit {
    bottom: 10px;
    right: 10px;
}
.VueTables__table.assignment-table tr:nth-child(even){
    background-color:rgb(230, 230, 230);
}
</style>