var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "project-download-table" } }, [
    _c(
      "div",
      { staticClass: "col s12 panel boxed padded60 dataGroup" },
      [
        _vm.activeSensor != null && _vm.optionLoaded
          ? _c(
              "v-server-table",
              {
                ref: "table",
                staticClass: "table vue-bordered",
                attrs: {
                  url: _vm.activeSensorUrl,
                  columns: _vm.columns,
                  options: _vm.options
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "checkbox",
                      fn: function(props) {
                        return [
                          _c("label", { attrs: { for: props.row.id } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selected,
                                  expression: "selected"
                                }
                              ],
                              attrs: { type: "checkbox", id: props.row.id },
                              domProps: {
                                value: props.row.id,
                                checked: Array.isArray(_vm.selected)
                                  ? _vm._i(_vm.selected, props.row.id) > -1
                                  : _vm.selected
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selected,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = props.row.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selected = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selected = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selected = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span")
                          ])
                        ]
                      }
                    },
                    {
                      key: "child_row",
                      fn: function(props) {
                        return _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col s12 m12 l3" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Purpose and Methods")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  props.row.description ||
                                    _vm.$t("Not Provided")
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s12 m12 l3" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Results Summary")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  props.row.results || _vm.$t("Not Provided")
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s12 m6 l3 stats" }, [
                            _c("p", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("projectDownload-subtable.status")
                                  )
                                )
                              ]),
                              _vm._v(" " + _vm._s(props.row.status))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "projectDownload-subtable.speciesCount"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" " + _vm._s(props.row.speciesCount))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s12 m6 l3 stats" }, [
                            _c("p", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "projectDownload-subtable.totalTasks"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" " + _vm._s(props.row.tasksCount))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "projectDownload-subtable.completedTasks"
                                    )
                                  )
                                )
                              ]),
                              _vm._v(
                                " " + _vm._s(props.row.tasksCompletedCount)
                              )
                            ])
                          ])
                        ])
                      }
                    }
                  ],
                  null,
                  false,
                  2499946456
                )
              },
              [
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: { slot: "filter__checkbox" },
                    slot: "filter__checkbox"
                  },
                  [
                    _c("label", { attrs: { for: "toggleAll" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedAll,
                            expression: "selectedAll"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "checkbox", id: "toggleAll" },
                        domProps: {
                          checked: Array.isArray(_vm.selectedAll)
                            ? _vm._i(_vm.selectedAll, null) > -1
                            : _vm.selectedAll
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.selectedAll,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedAll = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedAll = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedAll = $$c
                              }
                            },
                            function($event) {
                              return _vm.toggleAll()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("span")
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }