var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "iconButton link", on: { click: _vm.closeForm } },
      [_c("span", { staticClass: "ion-close" })]
    ),
    _vm._v(" "),
    _c("div", { ref: "modalHeader", staticClass: "modal-header drag-handle" }, [
      _c("h6", [
        _vm._v(
          _vm._s(_vm.$t("mergeForm-merge", { type: _vm.translatedMergeType }))
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-content padded40" },
      [
        _vm.success === false
          ? [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col s12" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("mergeForm-mainMsg", {
                            type: _vm.translatedMergeType
                          })
                        ) +
                        " "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("mergeForm-strongMsg")))
                    ]),
                    _vm._v(" "),
                    _vm.mergeType === "project"
                      ? [_vm._v(_vm._s(_vm.$t("mergeForm-projectMsg")))]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12 m6" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("mergeForm-source", {
                          type: _vm.translatedMergeType
                        })
                      ) + " "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.source,
                          expression: "tooltips.source"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.mergeSourceInfo.locationName
                        ? [_vm._v(_vm._s(_vm.mergeSourceInfo.locationName))]
                        : [_vm._v(_vm._s(_vm.mergeSourceInfo.fullNm))]
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col s12 m6" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("mergeForm-target", {
                          type: _vm.translatedMergeType
                        })
                      ) + " "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.target,
                          expression: "tooltips.target"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ]),
                  _vm._v(" "),
                  _vm.mergeType === "location"
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.target,
                              expression: "target"
                            }
                          ],
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.target = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.getTargetDetails
                            ]
                          }
                        },
                        [
                          _vm._l(_vm.targetList, function(item, index) {
                            return [
                              item.id !== _vm.mergeSourceInfo.id
                                ? _c(
                                    "option",
                                    { key: index, domProps: { value: item } },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.locationName) +
                                          "\n                      "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.target,
                              expression: "target"
                            }
                          ],
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.target = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.getTargetDetails
                            ]
                          }
                        },
                        [
                          _vm._l(_vm.targetList, function(item, index) {
                            return [
                              item.id !== _vm.mergeSourceInfo.id &&
                              item.organizationId ===
                                _vm.mergeSourceInfo.organizationId &&
                              item.status === "Active"
                                ? _c(
                                    "option",
                                    { key: index, domProps: { value: item } },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.fullNm) +
                                          "\n                      "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _vm.mergeSourceInfo.subDetails
                  ? _c(
                      "div",
                      { staticClass: "col s12 m6" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("mergeForm-summary", {
                                name: _vm.mergeSourceInfo.locationName
                              })
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _vm.mergeSourceInfo.subDetails.length > 0
                          ? _vm._l(_vm.mergeSourceInfo.subDetails, function(
                              project,
                              index
                            ) {
                              return _c("div", { key: `srcproject` + index }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(project.projectName) +
                                      " - " +
                                      _vm._s(project.sensor)
                                  )
                                ])
                              ])
                            })
                          : _c("div", [
                              _vm._v(_vm._s(_vm.$t("mergeForm-noProjects")))
                            ])
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col s12 m6" }, [
                  _vm.target && _vm.targetDetails
                    ? _c(
                        "div",
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("mergeForm-summary", {
                                  name: _vm.target.locationName
                                })
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _vm.targetDetails.length > 0
                            ? _vm._l(_vm.targetDetails, function(
                                project,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: `trgtproject` + index },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(project.projectName) +
                                          " - " +
                                          _vm._s(project.sensor)
                                      )
                                    ])
                                  ]
                                )
                              })
                            : _c("div", [
                                _vm._v(_vm._s(_vm.$t("mergeForm-noProjects")))
                              ])
                        ],
                        2
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "status",
                    class: { error: _vm.error, message: !_vm.error }
                  },
                  [_vm._v(_vm._s(_vm.message))]
                )
              ])
            ]
          : [
              _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t("mergeForm-successfullyMerged", {
                        source: _vm.mergeSourceInfo.locationName
                          ? _vm.mergeSourceInfo.locationName
                          : _vm.mergeSourceInfo.fullNm,
                        target: _vm.target.locationName
                          ? _vm.target.locationName
                          : _vm.target.fullNm
                      })
                    ) +
                    "\n            "
                )
              ])
            ],
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer" }, [
          _vm.success === false
            ? _c(
                "button",
                {
                  staticClass:
                    "btn btn-success modal-trigger modal-action modal-close",
                  attrs: { id: "sendRequest" },
                  on: { click: _vm.merge }
                },
                [[_vm._v(_vm._s(_vm.$t("mergeForm-mergeBtn")))]],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-border modal-action modal-close",
              attrs: { id: "cancelAdd" },
              on: { click: _vm.closeForm }
            },
            [_vm._v(_vm._s(_vm.$t("common-close")))]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }