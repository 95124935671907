<template>
  <div>
    <div class="col s12" :class="{'no-padding': creatingVisit}">
      <div class="table-responsive">
        <template v-if="isEditable">
          <h6 v-if="visitId">{{$t('visitEquipTable-selected', {label: visitId && displayOptionLabel(visitId, 'deployVisitId', 'text')})}}</h6>
          <h6 v-else>{{$t('visitEquipTable-instructions')}}</h6>
          <button class="btn btn-success  equipmentDetailAdd" @click="updateEquipmentDetail(null)">
            <span class="info-icon ion-information-circled" v-tooltip="tooltips.addSensor"></span>
            {{$t('visitEquipTable-addSensor')}} </button>
        </template>
        <table class="VueTables__table table equipment-table table-striped table-bordered table-hover">
          <thead><tr>
            <th></th><th>
            <span title="" class="VueTables__heading">{{$t('visitEquipTable-type')}}</span>
            <span class="VueTables__sort-icon pull-right glyphicon glyphicon-sort "></span></th>
            <th><span title="" class="VueTables__heading">{{capitalize($tc('code', 1))}}</span><span class="VueTables__sort-icon pull-right glyphicon glyphicon-sort "></span></th>
            <th><span title="" class="VueTables__heading">{{$t('visitEquipTable-condition')}}</span><span class="VueTables__sort-icon pull-right glyphicon glyphicon-sort "></span></th>
            <th><span title="" class="VueTables__heading">{{$t('visitEquipTable-height')}}</span><span class="VueTables__sort-icon pull-right glyphicon glyphicon-sort "></span></th>
            <th><span title="" class="VueTables__heading">{{$t('visitEquipTable-deploymentDate')}}</span><span class="VueTables__sort-icon pull-right glyphicon glyphicon-sort "></span></th>
            <th>
              <span title="" class="VueTables__heading">{{$t('visitEquipTable-retrievalDate')}}</span>
              <span class="VueTables__sort-icon pull-right glyphicon glyphicon-sort "></span>
            </th>
            <td><span @click="expandAllRows()" class="VueTables__child-row-toggler"
                :class="{'VueTables__child-row-toggler--open': isAllRowOpen, 'VueTables__child-row-toggler--closed': !isAllRowOpen}"></span></td>
          </tr></thead>
          <tbody>
            <!-- display content here -->
            <template v-if="error">
              <tr><td class="error" colspan="10">{{error}}</td></tr>
            </template>
            <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
            <template v-for="(row, index) in equipmentList">
              <tr class="main-device" :key="'equipment-id' + index">
                <td> <span class="ion-ios-home"></span> </td>
                <td class="">{{row.equipment.type && displayOptionLabel(row.equipment.type, 'EquipmentType', 'type')}}</td>
                <td class="">{{row.equipment.code}}</td>
                <td class="">{{row.equipmentConditionId && displayOptionLabel(row.equipmentConditionId, 'equipmentCondition', 'type')}}</td>
                <td class="">{{row.heightMeters}}</td>
                <td :class="{'highlight': visitId == row.deployVisitId}">{{row.deployVisitId && displayOptionLabel(row.deployVisitId, 'deployVisitId', 'text')}}</td>
                <td :class="{'highlight': visitId == row.retrieveVisitId}">{{row.retrieveVisitId && displayOptionLabel(row.retrieveVisitId, 'retrieveVisitId', 'text')}}
                    {{ row.retrieveVisitId?options.retreiveVisitId.find(v=>v.id===row.retrieveVisitId).text:''}}
                </td>
                <td>
                  <a @click="updateEquipmentDetail(row)" class="editProject modal-trigger projectAdd"><span class="link" :class="{'ion-edit': isEditable, 'ion-eye': !isEditable}"></span></a>
                  <a v-if="isEditable" @click="deleteEquipmentDetails(row)"><span class="link ion-ios-trash"></span></a>
                  <a v-if="row.child && row.child.length > 0" @click="row.isOpen = !row.isOpen" > <span class="VueTables__child-row-toggler"
                    :class="{'VueTables__child-row-toggler--open': row.isOpen, 'VueTables__child-row-toggler--closed': !row.isOpen}"></span></a></td>
              </tr>
              <template v-if="row.isOpen">
                <template v-if="row.child && row.child.length > 0">
                  <template v-for="(subRow) in row.child">
                    <tr class="attachment child" :key="'child-equipment-id' + subRow.id">
                      <td><span class="ion-android-attach"></span></td>
                      <td class="">{{subRow.equipment.type && displayOptionLabel(subRow.equipment.type, 'EquipmentType', 'type')}}</td>
                      <td class="">{{subRow.equipment.code}}</td>
                      <td class="">{{subRow.equipmentConditionId && displayOptionLabel(subRow.equipmentConditionId, 'equipmentCondition', 'type')}}</td>
                      <td class="">{{subRow.heightMeters}}</td>
                      <td :class="{'highlight': visitId == subRow.deployVisitId}">{{subRow.deployVisitId && displayOptionLabel(subRow.deployVisitId, 'deployVisitId', 'text')}}</td>
                      <td :class="{'highlight': visitId == subRow.retrieveVisitId}">{{subRow.retrieveVisitId && displayOptionLabel(subRow.retrieveVisitId, 'retrieveVisitId', 'text')}}
                          {{ row.retrieveVisitId?options.retreiveVisitId.find(v=>v.id===row.retrieveVisitId).text:''}}
                      </td>
                      <td class=""><a @click="updateEquipmentDetail(subRow)" class="editProject modal-trigger projectAdd"><span class="link" :class="{'ion-edit': isEditable, 'ion-eye': !isEditable}"></span></a>
                        <a v-if="isEditable" @click="deleteEquipmentDetails(subRow)"><span class="link ion-ios-trash"></span></a></td>
                    </tr>
                  </template>
                </template>
                <tr class="attachment child" :key="'addNew' + index">
                  <td colspan=12><button v-if="isEditable" class="btn btn-success" @click="attachEquipment(row)"> {{$t('visitEquipTable-addEquipmentTo', {type: row.equipment.type && displayOptionLabel(row.equipment.type, 'EquipmentType', 'type'), code: row.equipment.code})}} </button></td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <modal name="equipment-detail-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      :delay="100"
      width = "99%"
      height = "85%"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <equipment-detail-form  v-bind:is-editable="isEditable"
      v-bind:organization-id="organizationId"
      v-bind:equipment-option="equipmentListOption"
      v-bind:options="options" v-bind:visit-id="visitId" v-bind:init-equipment-row="equipmentRow" v-bind:location-label="locationLabel"
      v-bind:is-full-form="showAllFieldsInForm"/>
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all equipmentDetails
under each EquipmentDetail tab, show a table listing all equipmentDetails. users can also update EquipmentDetail properties + users
or remove equipmentDetails if they have no associated data.
*/
import { eventBus } from '@/lib/eventbus';
import { API_URL, alertDialog, copyObject, capitalizeString } from '@/lib/common';
import LocationSingleVisitEquipmentForm from './LocationSingleVisitEquipmentForm';
import FormMixin from '@/components/common/FormMixin.js';

export default {
  name: 'location-visit-equipment-table',
  components: {
    'equipment-detail-form': LocationSingleVisitEquipmentForm
  },
  props: ['organizationId', 'visitId', 'isEditable', 'options', 'locationLabel', 'locationId', 'creatingVisit'],
  mixins: [FormMixin],
  created () {
    const that = this;
    this.loadEquipmentList();
    eventBus.$on('reload-visit-equipment-table', function () {
      that.loadEquipmentList();
    });
    eventBus.$on('close-equipment-detail-form', function () {
      that.$modal.hide('equipment-detail-form');
    });
    eventBus.$on('switch-to-equipment-id', function (newEquipmentId) {
      that.$modal.hide('equipment-detail-form');
      that._swapDevice(newEquipmentId);
    });
    eventBus.$on('add-equipment', function (row) { // attach equipment to the row, if row is from visit form, it will be a main sensor
      that.equipmentRow = {
        deployVisitId: row.deployVisitId,
        locationId: row.locationId,
        exactDeployTime: row.exactDeployTime,
        workingTestDone: row.workingTestDone
      };
      if (row.id) {
        that.equipmentRow.parentLocationDeploymentEquipmentId = row.id;
      }
      that.showAllFieldsInForm = true;
      that.showEquipmentDetailForm();
    });
  },
  methods: {
    isLaterDate (visitId1, visitId2) { // if first date is later than the second date
      const opt1 = this.options.retreiveVisitId.find(x => visitId1 === x.id);
      const opt2 = this.options.retreiveVisitId.find(x => visitId2 === x.id);
      if (!opt1 || !opt2) {
        return null;
      }
      return opt1.text >= opt2.text;
    },
    updateEquipmentDetail (row) {
      if (row) {
        this.equipmentRow = row;
      } else {
        this.equipmentRow = { deployVisitId: this.visitId, locationId: this.locationId };
      }
      this.showAllFieldsInForm = true;
      this.showEquipmentDetailForm();
    },
    attachEquipment (sensorRow) {
      if (!this.isEditable) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.add);
        return;
      }

      this.equipmentRow = {deployVisitId: sensorRow.deployVisitId || this.visitId,
        exactDeployTime: sensorRow.exactDeployTime,
        locationId: this.locationId,
        parentLocationDeploymentEquipmentId: sensorRow.id};
      this.showAllFieldsInForm = true;
      this.showEquipmentDetailForm();
    },

    deleteEquipmentDetails (row) { // remove selected equipmentDetails
      this.commonDelete(this.equipmentDetailRemoveUrl, row, () => {
        eventBus.$emit('reload-visit-equipment-table');
      }, this.$t('visitEquipTable-deleteMsg'));
    },
    showEquipmentDetailForm () {
      this.$modal.show('equipment-detail-form');
    },
    displayOptionLabel (input, optionName, valueField) {
      try {
        const match = this.options[optionName].find(o => o.id === input);
        if (match && match[valueField]) {
          return match[valueField];
        }
      } catch (e) {}
      return '';
    },
    loadEquipmentList () {
      this.error = '';

      const url = API_URL + 'get-location-equipment-by-location-id?locationId=' + this.locationId + '&visitId=' + this.visitId;
      this.$http.get(url).then((response) => {
        const data = response.body;
        this.equipmentList = [];
        this.equipmentListOption = [];
        if (data) {
          if (data && data.total_row_count === 0) {
            this.error = this.$t('visitEquipTable-errorNoEquipment');
          } else if (data.total_row_count > 0) {
            const dataResult = data.results;
            let mainList = dataResult.filter(x => !x.parentLocationDeploymentEquipmentId);
            mainList.forEach(x => { x.isOpen = true; x.child = dataResult.filter(y => y.parentLocationDeploymentEquipmentId === x.id); });
            // this.options.rowIds = mainList.map(x => x.id);
            /* package data result so, parents are main devices, children are attached devices */
            this.equipmentList = mainList;
            this.equipmentListOption = mainList.map(x => { return {'id': x.id, 'text': this.displayOptionLabel(x.equipment.type, 'EquipmentType', 'type') + ' ' + x.equipment.code, typeId: x.equipment.type} });
          } else if (data.hasOwnProperty('error')) {
            this.error = this.$tc('common-error', 1) + ': ' + data.error;
          }
        } else {
          this.error = this.$tc('common-error', 1) + ': ' + this.$t('visitEquipTable-errorNoEquipment');
        }
      });
    },
    _updateEquipmentRetrievalId (updateRow, orgRow) {
      const self = this;
      this.commonInsertUpdate(API_URL + 'insert-update-location-equipment', updateRow, (updateRow.id ? 'update' : 'add'), function (response) {
        self.$set(orgRow, 'retrieveVisitId', response.data.retrieveVisitId);
      });
    },
    retrieveDevice (row) {
      if (!this.visitId) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('visitEquipTable-selectVisit'));
        return;
      }
      /* retrieve main device plus child device */
      let copyRow = copyObject(row);
      if (!copyRow.retrieveVisitId) {
        copyRow.retrieveVisitId = this.visitId;
        this._updateEquipmentRetrievalId(copyRow, row);
        if (row.child && row.child.length > 0) {
          row.child.forEach(x => {
            let copySubRow = copyObject(x);
            if (!copySubRow.retrieveVisitId) {
              copySubRow.retrieveVisitId = this.visitId;
              this._updateEquipmentRetrievalId(copySubRow, x);
            }
          });
        }
      } else {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('visitEquipTable-equipRetrieved'));
      }
      // eventBus.$emit('reload-visit-equipment-table');
    },
    swapDeviceUI (row) {
      this.currentEquipment = row;
      // this.equipmentRow = {equipment: {type: (row.equipment || {}).type}}; // limit equipment type
      this.equipmentRow = {currentEquipment: row.equipment || {}, equipment: {type: (row.equipment || {}).type}}; // limit equipment type
      this.showAllFieldsInForm = false;
      this.showEquipmentDetailForm();
    },
    _swapDevice (newDeviceId) {
      this.message = '';
      // first retreive the old device
      this.retrieveDevice(this.currentEquipment);
      // insert the new device
      const newDevice = {
        deployVisitId: this.visitId,
        locationId: this.locationId,
        parentLocationDeploymentEquipmentId: this.currentEquipment.parentLocationDeploymentEquipmentId,
        equipmentId: newDeviceId
      };

      this.commonInsertUpdate(API_URL + 'insert-update-location-equipment', newDevice, 'add', function (response) {
        eventBus.$emit('reload-visit-equipment-table');
      });
    },
    expandAllRows () {
      this.equipmentList.forEach(x => { x.isOpen = !x.isOpen; });
      this.isAllRowOpen = !this.isAllRowOpen;
    },
    capitalize (str) {
      return capitalizeString(str);
    }
  },
  watch: {
    visitId (newVal, oldVal) {
      // this.url = this.baseUrl + newVal;
      // console.log(this.url);
      this.loadEquipmentList();
    },
    options (newVal, oldVal) {
      this.loadEquipmentList();
    }
  },
  data () {
    return {
      baseUrl: API_URL + 'get-location-equipment-by-location-id?locationId=' + this.locationId + '&visitId=',
      url: API_URL + 'get-location-equipment-by-location-id?locationId=' + this.locationId + '&visitId=' + this.visitId,
      error: null,
      errors: [],
      equipmentList: [],
      equipmentRow: null, // for editing
      // equipmentDetailUpdateUrl: API_URL + 'insert-update-equipmentDetail',
      equipmentDetailRemoveUrl: API_URL + 'delete-location-equipment',
      selected: [],
      isAllRowOpen: true,
      equipmentListOption: null,
      showAllFieldsInForm: true,
      currentEquipment: null,
      recordName: this.$t('common-equipment').toLowerCase(),
      tooltips: {
        addSensor: this.$t('visitEquipTable-tooltips.addSensor')
      }
    }
  }
}
</script>
<style scoped>
table.equipment-table tr.attachment{
  background-color: #f7f3f3;
  font-style: italic;

}
table.equipment-table tr.main-device{
  background-color: #d6e0ca;
  font-weight: 600;

}
table.equipment-table tr.attachment td{
  border: 1px solid #fff;
}
.highlight {
  color: #f3740c;
}

.no-padding{
  padding: 0;
}
</style>
