<template>
  <section>
    <div class="container overflow boxed padded60">
      <div class="bordered"></div>
      <div class="container small center-align">
        <h4>{{$t('common-results')}}</h4>
      </div>
      <div class="break"></div>
      <div class="container center-align ddFilters">
        <v-client-table ref="table" :columns="columns" :data="displayData" :options="options">
          <template slot="checkbox" slot-scope="props" v-if="false">
              <label :for="props.row.id">
                <input type=checkbox :id="props.row.id" :value="props.row.id" v-model="selected"/>
                <span></span>
              </label>
          </template>
          <div slot="filter__checkbox" v-if="false">
              <label for="toggleAll">
                <input type="checkbox" id="toggleAll" class="form-control" v-model="selectedAll" @change="toggleAll()">
                <span></span>
              </label>
          </div>
          <template slot="name" slot-scope="props">
            <span v-if="props.row.hasDetailedAccess" >
              <a target=_blank :href="getProjectLink(props.row)">{{props.row.name}} </a>
            </span>
            <span v-else>
              {{props.row.name}}
            </span>
          </template>
          <template slot="organizationColor" slot-scope="props">
            <span class="" :style="'display:inline-block; width: 25px; height: 25px; background-color:' + props.row.organizationColor" />
          </template>
          <div class="row subTable" slot="child_row" slot-scope="props">
            <v-client-table  v-if="props.row.examples && props.row.examples.length > 0" :columns="subTableOptions.columns" :data="props.row.examples" :options="subTableOptions">
              <template slot="locationName" slot-scope="subProps">
                <span v-if="props.row.hasDetailedAccess" >
                  <a target=_blank :href="getTaskPageLink(subProps.row, props.row)">{{subProps.row.locationName}} </a>
                </span>
                <span v-else>
                  {{subProps.row.locationName}}
                </span>
              </template>
            </v-client-table>
            </div>
        </v-client-table>
      </div>
      <div class="break"></div>
      <div class="container center-align" v-if="false">
          <a @click="downloadRaw" download :class="{'downloading':isDownloading, 'disabled': selected.length == 0}"
            class="btn btn-large download"><span class="spin-icon"></span><span class="ion-ios-download-outline"></span>{{'dataDiscoverDownload-downloadData'}}</a>
          <button class="btn btn-border btn-large">{{$t('dataDiscoverDownload-remove')}} <span class="ion-ios-trash-outline"></span></button>
      </div>

    </div>
  </section>
</template>

<script>
/* data query result table, download */
import {API_URL, PROJECT_URLS, TASK_URLS} from '@/lib/common';
import { createLink } from '@/lib/language.js';
import { mapGetters } from 'vuex';

export default {
  name: 'data-discover-result',
  props: ['staticOptions'],
  computed: mapGetters(['projectTableData', 'organizationInfo']),
  watch: {
    projectTableData (newVal, oldVal) {
      if (!newVal && newVal.length === 0) {
        return;
      }
      this.displayData = newVal.map(x => {
        try {
          x.organizationName = this.organizationInfo[x.organizationId].name;
          x.organizationColor = this.organizationInfo[x.organizationId].color;
        } catch (e) {}
        return x;
      });
      // sort table alphabetically by org
      this.displayData.sort((a, b) => {
        if (a.organizationName < b.organizationName) {
          return -1;
        }
        if (a.organizationName > b.organizationName) {
          return 1;
        }
        return 0;
      });
    }
  },
  data () {
    return {
      downloadRawUrl: API_URL + 'download-report',
      displayData: [],
      selected: [],
      selectedAll: false,
      isDownloading: false,
      columns: ['sensorId', 'organizationName', 'name', 'year', 'statusId', 'distinctSpecies', 'organizationColor'],
      options: {
        uniqueKey: 'projectId',
        filterByColumn: true,
        headings: {
          sensorId: this.$tc('common-sensor', 1),
          organizationId: this.$tc('common-organization', 1),
          name: this.$t('dataDiscoverDownload-headings.name'),
          year: this.$t('common-year'),
          statusId: this.$t('dataDiscoverDownload-headings.statusId'),
          organizationName: this.$t('dataDiscoverDownload-headings.organizationName'),
          organizationColor: this.$t('dataDiscoverDownload-headings.organizationColor'),
          distinctSpecies: this.$t('dataDiscoverDownload-headings.distinctSpecies')
        },
        // editableColumns:['sensorId', 'name', 'year'],
        sortable: ['sensorId', 'name', 'year', 'statusId', 'organizationName'],
        filterable: ['sensorId', 'name', 'year', 'statusId', 'organizationName'],
        listColumns: {
          statusId: this.staticOptions.projectStatus
        },
        childRowTogglerFirst: false,
        texts: {
          count: this.$t('dataDiscoverDownload-tableTexts.count'),
          limit: this.$t('dataDiscoverDownload-tableTexts.limit'),
          noResults: this.$t('dataDiscoverDownload-tableTexts.noResults'),
          first: this.$t('common-tableTexts.first'),
          last: this.$t('common-tableTexts.last'),
          filter: this.$t('common-tableTexts.filter'),
          filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
          page: this.$t('common-tableTexts.page'),
          noRequest: this.$t('common-tableTexts.noRequest'),
          filterBy: this.$t('common-tableTexts.filterBy'),
          loading: this.$t('common-tableTexts.loading'),
          defaultOption: this.$t('common-tableTexts.defaultOption'),
          columns: this.$t('common-tableTexts.columns')
        },
        tabbable: false, // default true will make the focus style look ugly; seems not work tabindex still 0
        rowClassCallback: row => {
          if (!row.examples || row.examples.length === 0) {
            return 'hideChildToggler'
          }
        }
      },
      subTableOptions: {
        columns: ['locationName', 'speciesName', 'date'],
        filterByColumn: true,
        headings: {
          locationName: this.$t('dataDiscoverDownload-headings.locationName'),
          speciesName: this.$t('dataDiscoverDownload-headings.speciesName')
        },
        // editableColumns:['sensorId', 'name', 'year'],
        sortable: ['locationName', 'speciesName', 'date'],
        filterable: ['locationName', 'speciesName', 'date'],
        tabbable: false, // default true will make the focus style look ugly; seems not work tabindex still 0
        texts: {
          count: this.$t('dataDiscoverDownload-tableTexts.subTableCount'),
          limit: this.$t('dataDiscoverDownload-tableTexts.subTableLimit'),
          noResults: this.$t('dataDiscoverDownload-tableTexts.subTableNoResults'),
          filter: this.$t('common-tableTexts.filter'),
                    filterBy: this.$t('common-tableTexts.filterBy'),
          filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
        }
      }
    }
  },
  methods: {
    getProjectLink (row) {
      if (row.hasDetailedAccess) {
        return PROJECT_URLS[row.sensorId] ? createLink(PROJECT_URLS[row.sensorId] + row.projectId) : '#';
      }
    },
    getTaskPageLink (row, parentRow) {
      if (parentRow.hasDetailedAccess) {
        return TASK_URLS[row.sensorId] ? createLink(TASK_URLS[row.sensorId] + row.taskId + '&speciesId=' + row.speciesId) : '#';
      }
    },
    toggleAll () {
      this.selected = this.selectedAll ? this.$refs.table.data.map(row => row.id) : [];
      // this.selectedAll = !this.selectedAll; the click action will do this don't need to call here.
    },
    downloadRaw () {
      this.isDownloading = true;
      const url = this.downloadRawUrl + '?' + this.selected.map(x => 'projectIds=' + x).join('&') + '&';
      this.$http.get(url, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'application/zip'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.$t('dataDiscoverDownload-speciesFilename') + '.zip';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.isDownloading = false;
      },
      (err) => {
        this.isDownloading = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    }
  }
}
</script>
<style lang="scss">
  .ddFilters {
    .hideChildToggler .VueTables__child-row-toggler {
        display: none;
    }
    th:focus, td:focus { /* when tabindex included in table cells, default outline is too strong, make it none*/
        outline: none;
    }
    .VueTables__limit {
      position: absolute;
      bottom: 0px;
      right: 60px;
    }
    .subTable {
      border: 1px solid #333;
      margin: 10px 30px 0 30px;
      padding: 0 20px;
      position: relative;
    }
  }
</style>
