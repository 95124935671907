var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.visible
      ? _c(
          "div",
          {
            ref: "lightbox",
            staticClass: "lightbox",
            style: `${_vm.zIndex ? `z-index: ${_vm.zIndex};` : ""}`,
            attrs: { tabindex: "0" },
            on: {
              mousedown: function($event) {
                $event.stopPropagation()
                return _vm.hide.apply(null, arguments)
              },
              touchdown: function($event) {
                $event.stopPropagation()
                return _vm.hide.apply(null, arguments)
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                )
                  return null
                return _vm.hide.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.controlsVisible,
                    expression: "controlsVisible"
                  }
                ],
                staticClass: "lightbox-download",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    return _vm.downloadPhoto.apply(null, arguments)
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                    return _vm.downloadPhoto.apply(null, arguments)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("lightbox-downloadPhoto")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn fixed top-[9px] right-[208px] z-[210]",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleMDVisible.apply(null, arguments)
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                    return _vm.toggleMDVisible.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Toggle Detection")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lightbox-close",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    return _vm.hide.apply(null, arguments)
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                    return _vm.hide.apply(null, arguments)
                  }
                }
              },
              [_vm._v("×")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lightbox-arrow lightbox-arrow-left",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.prev.apply(null, arguments)
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.prev.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.has_prev() && _vm.controlsVisible,
                        expression: "has_prev() && controlsVisible"
                      }
                    ],
                    staticClass: "lightbox-arrow-left-icon"
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          height: "48",
                          viewBox: "0 0 24 24",
                          width: "48",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "12",
                            cy: "12",
                            r: "12",
                            fill: "rgba(20, 20, 20, 0.4)"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z",
                            fill: "white"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: { d: "M0-.5h24v24H0z", fill: "none" }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lightbox-arrow lightbox-arrow-right",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.next.apply(null, arguments)
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.next.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.has_next() && _vm.controlsVisible,
                        expression: "has_next() && controlsVisible"
                      }
                    ],
                    staticClass: "lightbox-arrow-right-icon"
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          height: "48",
                          viewBox: "0 0 24 24",
                          width: "48",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "12",
                            cy: "12",
                            r: "12",
                            fill: "rgba(20, 20, 20, 0.4)"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d:
                              "M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z",
                            fill: "white"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: { d: "M0-.25h24v24H0z", fill: "none" }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.controlsVisible && _vm.filteredImages[_vm.index].alt,
                    expression: "controlsVisible && filteredImages[index].alt"
                  }
                ],
                staticClass: "lightbox-caption",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("span", { attrs: { unselectable: "on" } }, [
                  _vm._v(_vm._s(_vm.filteredImages[_vm.index].alt))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "lightbox-main",
                on: {
                  mousedown: function($event) {
                    $event.stopPropagation()
                    return _vm.hide.apply(null, arguments)
                  },
                  touchdown: function($event) {
                    $event.stopPropagation()
                    return _vm.hide.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "lightbox-image-container",
                    on: {
                      mousedown: function($event) {
                        $event.stopPropagation()
                      },
                      touchdown: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { display: "none" },
                      attrs: {
                        src: this.directory + _vm.filteredImages[_vm.index].name
                      },
                      on: { load: _vm.imageLoad }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: _vm.index,
                        ref: "lightboxImage",
                        staticClass:
                          "lightbox-image relative flex justify-content items-center"
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "boundingbox",
                            staticClass: "w-full h-full m-auto relative"
                          },
                          [
                            _vm.filteredImages[_vm.index].megaDetector
                              ? _c(
                                  "span",
                                  [
                                    _vm._l(
                                      _vm.filteredImages[_vm.index]
                                        .megaDetector,
                                      function(MDResult, index) {
                                        return [
                                          MDResult.confidence >
                                          parseFloat(_vm.filters.MDConfidence)
                                            ? _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.megadetectorVisible,
                                                      expression:
                                                        "megadetectorVisible"
                                                    }
                                                  ],
                                                  key: index,
                                                  staticClass:
                                                    "absolute border border-red-500",
                                                  style: {
                                                    "z-index": index + 100,
                                                    left: `${MDResult.box1 *
                                                      100}%`,
                                                    top: `${MDResult.box2 *
                                                      100}%`,
                                                    width: `${MDResult.box3 *
                                                      100}%`,
                                                    height: `${MDResult.box4 *
                                                      100}%`
                                                  },
                                                  attrs: {
                                                    title: `${
                                                      _vm.megaDetectorCategories.find(
                                                        v =>
                                                          v.id ===
                                                          MDResult.category
                                                      ).type
                                                    } ${MDResult.confidence}`
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "absolute -top-[16px] pl-1 text-white bg-red-500/25 w-full text-xs overflow-hidden whitespace-nowrap"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.megaDetectorCategories.find(
                                                            v =>
                                                              v.id ===
                                                              MDResult.category
                                                          ).type
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            MDResult.confidence
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }