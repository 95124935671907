<template>
    <div style=" min-height: 600px;">
      <report-filter ref="filter" v-if="pudId && species != null"
                  v-bind:options="species"
                  v-bind:init-species-id="initialSpeciesId"/>
      <div class="row" style="position: relative;">
        <div  v-show="ui.loading" class="overaly" style="opacity: 0.6; position: absolute; width: 100%; height: 100%; z-index: 100; background: white;">
          <div style="text-align: center; margin-top:250px;">
            <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <div> {{$t('common-loadingImages')}} </div>
          </div>
        </div>

        <template v-if="!ui.loading && (!imageList || imageList.length === 0)" >
            <h5>{{$t('reportGallery-noImgsFound')}}</h5>
        </template>
        <div class="col s12 panel" ref="scrollBounds" id='scrollBounds'  v-if="imageList && imageList.length> 0"> <!-- TP: removed class boxed. TODO: check effects. -->
          <div class="row" >

            <div class="col s12" :class="{fullWidth: ! ui.infoPanelVisible}" id="mainPanel">
              <div class="row" >
                <div class="toggleWrap no-left-margin col s12 m6 l4">
                  <template><!--  v-if="filterMode !== 'series'">-->
                    <select ref="limit" class="record-per-page" v-model="imageQueryParam.limit" @change="changeNumberPerPage">
                      <option v-for="(count, index) in ImagePerPageOption" :key="'countPerPage' + index" :value="count">{{$t('common-perPage' , {count: count})}}</option>
                    </select>
                    <span class="total-image" v-if="totalImageCount > 0"> [ {{ $tc('common-image', totalImageCount, {num: totalImageCount})}} ] </span>
                  </template>
                </div>
                <div class="toggleWrap col s12 m6 l6 right-align">
                  <!-- check this: https://materializecss.com/pagination.html#! -->
                  <pagination v-if="totalPageNum >= 0 || pagination !== null" v-bind:active-page-number="imageQueryParam.page" v-bind:pagination="pagination" v-bind:total-page-num="totalPageNum"
                      v-bind:time-stamp="timeStamp" v-bind:status-field="paginationStatusField" v-bind:order-display="orderDisplay"
                      />
                </div>
              </div>

              <div class="row selectable" id="selectable-wrapper" v-show="imageList && imageList.length> 0">
                  <template v-for="(img, index) in imageList">
                    <div ref="imagePanel" :key="'image' + img.task.id" class="col capture img-selectable" :id="index"
                      :data-item="img.id" :class="getClasses(img)">
                      <div class="imgWrap"  :data-item="img.task.id" :id="index">
                        <span v-if="img.megaDetector">
                          <template  v-for="(MDResult,index) in img.megaDetector">
                            <div v-if="( MDResult.confidence > ui.filter.MDConfidence)" :key="index" class="absolute border border-red-500" :style="{ left:`${MDResult.box1*100}%`, top: `${MDResult.box2*100}%`, width: `${MDResult.box3*100}%`, height: `${MDResult.box4*100}%`}">
                             
                            </div>
                          </template>
                        </span>
                        <img v-if="typeof img.provided == 'undefined'"  v-lazy="img.fileThumbURL" :id="'img' + img.task.id" @error="imgUrlAlt($event, img.fileURL)"/>
                        <img v-else class="imageNotFound"  />

                        <div class="imgOverlay">
                          <div class="magnify" >
                            <span class="ion-android-search exclude" @click.stop.prevent="showLightbox(img.fileURL)"></span>
                          </div>
                        </div>
                      </div>

                      <div class="vidData" style="position: relative;">
                        <div class="number">
                          <div class="multipleSpecies" v-if="img.hasMultipleSpecies"></div>
                          <div class="multipleIndividual" v-if="img.hasMultipleIndividuals"></div>
                          <div class="outOfRange" v-if="img.isOutOfRange"></div>
                          {{index+1}}
                        </div>
                         <template v-if="img.speciesTags && img.speciesTags.length > 0">
                            <div v-for="(tag, index) in img.speciesTags" :key="'species' + tag.id" class="info exclude">
                              <div class="tag-details exclude" :name="'species'+tag.id">
                                <span  class="link exclude tag-text break-words">
                                  <span class="tagSpp !cursor-default">{{getTagSpp(tag)}}</span> {{getTagDisplay(tag)}}
                                  <span v-if="index === 0 && img.task.fovId!==0"> {{getFOVType(img.task.fovId)}} </span></span>
                                <div class="moreInfo" v-if="index ==0">
                                  <span class="ion-ios-information exclude" @click="showImageInfoForm(img)"></span>
                                </div>
                              </div>
                            </div>
                            <!-- Location info, in spp verification only -->
                            <div v-if="speciesId !== null" class="location">{{getLocation(index)}}</div>
                          </template>

                          <template v-else>

                            <div class="info link exclude" >
                              <div class="tag-details">
                              </div>
                              <div class="moreInfo exclude">
                                <span class="ion-ios-information exclude" @click="showImageInfoForm(img)"></span>
                              </div>
                            </div>
                          </template>
                      </div>
                    </div>
                  </template>
                </div>

              <div class="" v-if="imageList && imageList.length> 0">
                <div class="toggleWrap col s12 m12 right-align">
                  <!-- check this: https://materializecss.com/pagination.html#! -->
                  <pagination v-if="totalPageNum >= 0 || pagination !== null"
                    v-bind:active-page-number="imageQueryParam.page"
                    v-bind:pagination="pagination"
                    v-bind:time-stamp="timeStamp"
                    v-bind:total-page-num="totalPageNum"
                    v-bind:status-field="paginationStatusField"
                    v-bind:order-display="orderDisplay"/>
                </div>
              </div>

            </div>
            <!-- -END Image PANEL- -->

          </div>
        </div>

      </div>
      <!-- popup and modal boxes -->
      <lightbox id="mylightbox" ref="lightbox"
      v-bind:images="lightboxImages"
      v-bind:mega-detector-categories="megaDetectorCategories"
      :timeoutDuration="10000" v-bind:can-download="true"></lightbox>

    <exif-info v-bind:img="currentExifImgObj" v-bind:form-name="ui.exifFormName" />
    </div>
</template>

<script>
/* page that the land owner will look at specific imageset/audio */
import {API_URL, getParameterByName, createLightboxImageObject} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import Lightbox from '@/components/utils/lightbox.vue';
import Pagination from '../camera/TaggingGalleryPagination'; // shared with camera
import ReportFilter from './ReportFilter';
import TaggingImageInfoVue from '../camera/TaggingImageInfo.vue';

export default {
  name: 'report-gallery',
  props: ['uuid', 'pudId', 'staticOptions', 'filterMode', 'megaDetectorCategories'],
  components: {'pulse-loader': PulseLoader, 'lightbox': Lightbox, 'pagination': Pagination, 'report-filter': ReportFilter, 'exif-info': TaggingImageInfoVue},
  created () {
    window.vueContext = this;
    this.initialSpeciesId = parseInt(getParameterByName('speciesId'));
    if (this.initialSpeciesId) {
      this.hasFilter = true;
    }
    this.reloadImage(true);
    this.paginationStatusField = 'status';
    this.loadFilterOptions();

    let self = this;
    /* when user click a page in pagination */
    eventBus.$on('pick-page', function (pageNumber) {
      if (self.imageQueryParam.page !== pageNumber) {
        self.imageQueryParam.page = pageNumber;
        self.reloadImage();
      }
    });

    /* when user click apply filter,
      assign filter paramter to query params, they are in different format
      remove empty filters, and then send a flag to top menu to show filtered icon
 */
    eventBus.$on('apply-filter', function (queryParam) {
      // clear parameters that is not in initial list, otherwise, if previous set as nice
      // after removed from filter ui, you still have the nice flag here.
      Object.keys(self.imageQueryParam).forEach(key => {
        if (!self.initalParamNames.includes(key)) {
          this.$delete(self.imageQueryParam, key);
        }
      });
      self.imageQueryParam.page = 1; // reset page to the 1
      self.imageQueryParam = Object.assign(self.imageQueryParam, queryParam); // (target, source)
      // console.log(Object.keys(queryParam));
      const queryForFalseFields = ['isVerified'];
      const alwaysPassFields = ['excludeAutoTag'];
      Object.keys(queryParam).forEach(key => {
        if (!alwaysPassFields.includes(key)) { // for some fields, we want to pass true or false all the time.
          const nonAcceptedValue = queryForFalseFields.includes(key); // default: remove false, for query false field, remove true values
          const value = queryParam[key];
          if (value == null || value === nonAcceptedValue || value === '' || value.length === 0 || key.startsWith('tmp')) { // remove empty or false values
            if (self.imageQueryParam.hasOwnProperty(key)) {
              this.$delete(self.imageQueryParam, key);
            }
            this.$delete(queryParam, key);
          }
        }
      });
      /* don't load total count for series view */
      self.reloadImage(true);
      // console.log(queryParam);
      let alwaysRemoveFields = ['limit', 'page', 'useSeriesView'];
      if (self.filterMode === 'series') { /* hide auto tagger and show motion only */
        alwaysRemoveFields.push('triggerModeId');
      }
      alwaysRemoveFields.forEach(key => { this.$delete(queryParam, key); });

      eventBus.$emit('update-filter-flag', queryParam); // let main page know if there is filter or not
    });
  },
  methods: {
    getTagSpp(tag) {
      let species = (this.deploymentOptions?this.deploymentOptions.species.find((v)=>v.id === tag.speciesId):this.staticOptions.species.concat(this.staticOptions.none).concat(this.staticOptions.bird).concat(this.staticOptions.domestic).find(v=>v.id===tag.speciesId));
      return (species||{commonName: "NONE"}).commonName
    },
    async loadFilterOptions () {
      let response = await this.$http.get(this.getCameraDeploymentOptions + this.pudId + '&uuid=' + this.uuid);
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.species = response.data;
      }
    },
    /* call api to get a new image set,
    when it is only navigation no update, don' t call pagination APIs
    */
    reloadImage (bLoadPagination, bKeepScreenPosition) {
      /* ------------------------------------------------------------------
          first reset all
      -------------------------------------------------------------------- */
      this.ui.loading = true;
      /* ------------------------------------------------------------------
        then reload images
      -------------------------------------------------------------------- */
      this.imageQueryParam.sortAsc = true; // (this.filterMode !== 'fov');
      // backend doesnt accept arrays
      let {speciesId, triggerModeId, ...params} = this.imageQueryParam;
      let speciesParams = '';
      let triggerParams = '';
      if (speciesId && speciesId.length > 0) {
        this.imageQueryParam.speciesId.forEach(id => {
          speciesParams += '&speciesId=' + id;
        });
      }
      if (triggerModeId && triggerModeId.length > 0) {
        this.imageQueryParam.triggerModeId.forEach(id => {
          triggerParams += '&triggerModeId=' + id;
        });
      }
      this.$http.get(this.getLocationReportImagesUrl + this.uuid + '&pudId=' + this.pudId + speciesParams + triggerParams, {params: params}).then(
        function (response) {
          this.ui.loading = false;
          if (response.body.hasOwnProperty('error')) {
            this.error = response.body.error;
            this.imageList = [];
          } else {
            // depends on whether untagged image exists, toggle update all untagged button
            this.imageList = response.data;
            // if (!bImageOnly) { // when not only update tags, update pagination and selections.
            // alwasy reload light box and number selection, just in case when user filtered on some critiera, and the updates made
            // may yield different images sets
            this.populateLightBoxImages(this.imageList);
            /* if (this.imageList.length > 0) {
              if (that.$refs.imageSelectArea) {
                that.$nextTick(() => {
                  that.$refs.imageSelectArea.contentUpdated();
                });
              }
            } */
            // }
          }
        },
        function () {
          this.ui.loading = false;
          this.imageList = [];
        }
      );

      if (bLoadPagination) { // page query updates, load pagination information. don't call it when it is only navigating
        // get pagination
        this.ui.paginationLoading = true;
        // this.pagination = null;
        // this.totalPageNum = 0;

        this.$http.get(this.getLocationReportPaginationUrl + this.uuid + '&pudId=' + this.pudId + speciesParams + triggerParams, {params: this.imageQueryParam}).then(
          function (response) {
            this.ui.paginationLoading = false;
            if (response.body.error) {
              this.error = response.body.error;
              this.pagination = null;
              this.totalPageNum = 0;
            } else {
              let pageList = response.data;
              const total = pageList.pop();
              this.totalImageCount = total.count;
              this.pagination = pageList;
              this.totalPageNum = this.pagination.length;
              this.timeStamp = new Date().getTime(); // a time stamp to trigger pagination changes
              // if (this.deploymentId && this.pagination && this.pagination.length > 0) { // when used for deployment tagging, check if any untagged image existing
              //   const allTagged = this.pagination.every(item => item.status == 'full')
              //   eventBus.$emit('toggle-form', 'showUntaggedButton', !allTagged);
              // } else {
              //   eventBus.$emit('toggle-form', 'showUntaggedButton', false);
              // }
            }
          },
          function () {
            this.ui.paginationLoading = false;
            this.pagination = null;
            this.timeStamp = new Date().getTime(); // a time stamp to trigger pagination changes
          }
        );
      //  eventBus.$emit('reload-filter-options'); // when updated tag, reload options
      }
      // eventBus.$emit('manual-selection-change', this.selectedImgId); // update parent page button
      if (!bKeepScreenPosition) { // when reload whole page (navigation), scroll to top, don't scroll when editing tag refreshing
        window.scrollTo(0, 0); // scroll to top
      }
    },
    changeNumberPerPage () {
      this.imageQueryParam.page = 1;
      this.reloadImage(true);
      if (this.$refs.limit) {
        this.$refs.limit.blur();
      }
    },
    /* drag selected item style, number panel and image panel classes.
    can control how many images per row */
    getClasses (image) {
      const self = this;
      //  const imgId = image.id;
      /*  const isActive = !!(selectedItems.find((selectedItem) => {
        return parseInt(selectedItem.dataset.item, 10) === imgId
      })) */

      let tagClass = {
        // selected: isActive,
        // active: isActive,
        's6 xl6': this.imageQueryParam.limit === this.ImagePerPageOption[0],
        's4 xl4': this.imageQueryParam.limit === this.ImagePerPageOption[1] || this.imageQueryParam.limit === this.ImagePerPageOption[2],
        's3 xl3': this.imageQueryParam.limit === this.ImagePerPageOption[3] || this.imageQueryParam.limit === this.ImagePerPageOption[4],
        's3 xl3 e': this.imageQueryParam.limit > this.ImagePerPageOption[4]
      };

      if (image.species && image.species.find(spp => { return spp.needsReview === true })) {
        tagClass['need-review'] = true;
      }

      if (!this.speciesId) { // if it is for tagging, not species verification
        if (image.species && image.species.length > 0) {
        // loop through each spp tag row, and check if species name, sex, and age exist, if any is missing make it as untagged.
          if (image.species.find(spp => { return !spp.commonName || !spp.sexId || !spp.ageId })) {
            tagClass.partial = true;
            // tagClass.tagged = false;
            tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'partial';
          } else {
            tagClass.tagged = true;
            tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'tagged';
          }
        } else {
          tagClass.untagged = true;
          tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'untagged';
        }
      } else { // species verificaiton view
        if (image.species && image.species.find(spp => { return spp.commonName === self.speciesObj.commonName && spp.isVerified === true })) {
          tagClass.tagged = true;
          tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'tagged';
        } else {
          tagClass.untagged = true;
          tagClass.hidden = this.ui.showImageType !== 'all' && this.ui.showImageType !== 'untagged';
        }
      }
      return tagClass;
    },
    populateLightBoxImages (imgs) {
      let lbImages = [];
      this.lightboxImages = [];
      if (!imgs || imgs.length === 0) {
        return;
      }
      imgs.forEach((img, index) => {
        lbImages.push({
          ...createLightboxImageObject(img),
          'alt': this.$t('common-imgNumber', {num: (index + 1), total: imgs.length}),
          'id': 'image' + index,
        });
      });

      this.lightboxImages = lbImages;
    },
    /* used for tag display under each image */
    getTagDisplay (speciesRow) {
      const sexValue = this.staticOptions.sex.find(item => { return item.id === parseInt(speciesRow.sexId) });
      const ageValue = this.staticOptions.age.find(item => { return item.id === parseInt(speciesRow.ageId) });

      // sexTypeID = 4 VNA ageValueID = 0 VNA
      const display = (sexValue ? (sexValue.id === 4 ? '' : sexValue.type + ' | ') : '') + (ageValue ? (ageValue.id === 0 ? '' : ageValue.type + ' | ') : '') + (['VNA',''].includes(speciesRow.individualCount.trim()) ? '' : speciesRow.individualCount + ' | ');
      return (display.length > 1 ? ' | ' + display : '');
    },
    showLightbox (imgUrl) {
      this.$refs.lightbox.show(imgUrl);
    },
    showImageInfoForm (img) {
      this.currentExifImgObj = img;
      this.$modal.show(this.ui.exifFormName);
    },
    /* when image load error use place holder image */
    imgUrlAlt (event, url) {
      const placeHolderImageUrl = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      if (placeHolderImageUrl !== event.target.src) {
        event.target.src = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      }
    }
  },
  data () {
    return {
      getLocationReportImagesUrl: API_URL + 'get-location-report-images-by-pud?UUID=',
      getLocationReportCountUrl: API_URL + 'get-location-report-count-by-pud?UUID=',
      getLocationReportPaginationUrl: API_URL + 'get-location-report-pagination-by-pud?UUID=',
      getCameraDeploymentOptions: API_URL + 'get-camera-project-user-deployment-options?id=', // species
      imageList: null,
      currentExifImgObj: null,
      lightboxImages: [],
      ImagePerPageOption: [12, 24, 48, 60, 80],
      totalImageCount: 1000,
      totalPageNum: 0,
      pagination: null,
      timeStamp: null,
      paginationStatusField: null,
      initalParamNames: ['sortAsc', 'limit', 'sort', 'pudId', 'projectId'],
      orderDisplay: ['Oldest', 'Newest'],
      speciesId: null,
      species: null, // for filter
      initialSpeciesId: null, // for filter
      imageQueryParam: {
        sortAsc: true,
        limit: 60,
        page: 1,
        sort: 'imageDate'
      //  pudId: this.deploymentId,
      //  projectId: this.projectId
      },
      ui: {
        infoPanelVisible: true,
        numberPanelClass: 'numberCol',
        imagePanelClass: 'imgWrap',
        /* form name */
        tagFormName: 'tagging-form',
        infoFormName: 'image-info-form',
        exifFormName: 'exif-form-name',
        loading: true,
        showImageType: 'all',
        showSelectAll: true,
        workingOnVerifySpp: false,
        filter: {
          MDConfidence: 0
        }
      }
    }
  }
}
</script>

<style scoped>
.numberCol.selected {
  border:#91efef solid 1px;
}

.selected.active {
    background-color: #91efef;
}

  .lightbox-fade-enter-active,
    .lightbox-fade-leave-active {
        transition: none!important;
    }

.lightbox-fade-enter,
    .lightbox-fade-leave-to {
        opacity: 1!important;
    }

.lightbox-slide-next-enter-active,
    .lightbox-slide-next-leave-active,
    .lightbox-slide-prev-enter-active,
    .lightbox-slide-prev-leave-active{
        transition: all 0s ease;
}

.multipleSpecies { /* top right red triangle */
      width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #b73333;
    border-top: 5px solid #b73333;
    border-left: 5px solid transparent;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
 .multipleCount, .multipleIndividual{/* top left blue triangle */
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #337ab7;
    border-left: 5px solid #337ab7;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

  .outOfRange:before {
    content: "";
    position: absolute;
    top: 0%;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left top, rgba(224, 226, 97, 0.6) 50%, transparent 50%)
}
/*
#infoPanel .numberRow .numberCol.untagged .number{
    border:1px solid #ccc;
}
#infoPanel .numberRow .numberCol .number{
  position: relative;
}*/

#selectable-wrapper .delete {
  opacity: 1!important;
  -webkit-transform: scale(1)!important;
  -ms-transform: scale(1)!important;
  transform: scale(1)!important;
}
.tag-details {
    height: 1.8em;
    overflow: hidden;
    text-overflow: ellipsis;
}

#mainPanel .capture .vidData .number {
    border: #c3c3bf solid 1px;
}
#mainPanel .capture.partial .number
/*#infoPanel .numberRow .numberCol.partial .number*/ {

    background: rgb(203, 221, 206);
    color: #666;
}

#mainPanel .capture.tagged .number {
    color: rgb(255, 255, 255);
    background: rgb(125, 164, 132);
}

#mainPanel .capture .vidData .number {
    width: 25px;
    height: 25px;
    display: inline-block;
    position: absolute;
    font-size: 13px;
    text-align: center;
    top: 0px;
}

#mainPanel .capture .vidData .info {
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: 12px;
    padding: 0px 32px;
}

.hidden {
  display: none;
}
/*
#infoPanel .numberRow .numberCol.hidden {
  display: none;
}*/
.red-orange-colored {
  color: #C94412;
}

#mainPanel .capture .vidData {
    position: relative;
    display: inline-block;
    width: 100%;
}

#mainPanel .capture .imgWrap .imgOverlay {
    position: absolute;
    display: none;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(50, 50, 50, 0.4);
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 2.3em;
    cursor: pointer;
}

#mainPanel .capture .imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    /* -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
     transform: scale(0.1); */
     transition-delay: 0.8s;

}

#mainPanel .capture .imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    /* -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
     transform: scale(0.1); */
     /* transition-delay: 0.8s; */

}
#mainPanel .capture .imgWrap .imgOverlay .magnify{
    font-size: 1px;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.5s!important;
}

#mainPanel .capture .imgWrap .imgOverlay .magnify {
    color: rgb(255, 255, 255);
    display: inline-block;
    cursor: pointer;
    text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 2px;
    padding: 4px 6px;
}

#mainPanel .capture .imgWrap:hover .imgOverlay span{
    font-size: 2.3rem;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.8s!important;
}

#mainPanel .capture .imgWrap:hover .imgOverlay {
    opacity: 1;
    display:flex;
    /* -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); */
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    /* transition-delay: 0.5s!important; */
}

#mainPanel .capture {
    margin-left: 0px;
    margin-bottom: 0.75rem;
}

#mainPanel .row .imgWrap {
    position: relative;
    display: inline-block;
    float: left;
}

#mainPanel .capture .imgWrap img {
    width: 100%;
    display: inline-block;
    float: left;
}

#mainPanel .row {
    display: flex;
    flex-wrap: wrap;
}

.imgWrap {
  width: 100%;
}
.lightbox {
  z-index: 20000!important;
}
/*
#infoPanel .numberRow .numberCol.selected.untagged .number {
   color: #999;
}
#infoPanel .numberRow .numberCol.need-review .number {
 border: #FF9800 4px solid;
}*/

.less-margin {
  margin: 0!important;
}

#mainPanel { /* reduce padding on left side */
    padding-left: 2%!important;
    border: none !important;
}

.vue-drag-select {
  width: 100%;
}
/*
@media only screen and (min-width: 601px) {
 #scrollBounds .row .col.m2.custom {
    width: 12%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
 #scrollBounds .row .col.m10.custom {
    width: 88%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}
*/
.tagSpp {
  font-weight: 600;
  color: #227cc3;
}

#mainPanel .capture .vidData .info .tag-text {
  font-size: 15px;
}

.numberSelectArea {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.vue-drag-select.numberSelectArea.scroller {
  overflow-y: scroll!important;
}

select.record-per-page {
  display: inline;
  width: unset;
}
#mainPanel .capture .vidData .moreInfo {
  top: 2px;
  right: -5px;
}
#mainPanel .capture .vidData .delete {
    right: 10px;
    top: 2px;
}
select:focus {
    box-shadow: 0 0 3pt 2pt #FFEB3B;
}

.multiselect--disabled {
    background: transparent;
}
.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
    background: #eae7e7;
    display: none;
    margin-top: 10px;
}

.multiselect--disabled .multiselect__tags {
  cursor: not-allowed;
}
.tag-isNice {
  z-index: 5;
  color: #fbc218;
  font-size: 1.2em;
}

.location{
  color: #FFA622;
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  padding: 0 32px;
}

.no-left-margin{
  margin-left: 0 !important;
}
.imageNotFound {
  background-size: cover;
  width: 100px;
  height: 200px;
  background-image: url('../../assets/image-not-found.jpg');
}
</style>
