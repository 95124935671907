<template>
    <div v-if="users">
        <div v-if="errors.length > 0" class="my-5 bg-red-300 border border-red-500 p-3 text-red-600 text-sm">
            <span v-for="(error) in errors">{{ error }}</span>
        </div>
        <v-client-table class="test" :data="data" :columns="columns" :options="options">
            <template slot="user.name" slot-scope="props">
                <div>{{props.row.user.name }}</div><div class="text-xs">{{props.row.user.email}}</div>
            </template>
            <template slot="h__admin">
                {{$t('Admin')}}
                <span v-tooltip="$t('projectForm-tooltips.admin')"> <i class="fas fa-info-circle text-yellow-400"></i></span>
            </template>
            <template slot="h__readonly">
                {{$t('Read Access')}}
                <span v-tooltip="$t('projectForm-tooltips.read')"> <i class="fas fa-info-circle text-yellow-400"></i></span>
            </template>
            <template slot="admin" slot-scope="props">
                <label>
                    <input type="checkbox" class="exclusive checkbox" v-model="props.row.admin"
                        @change="() => onCheckboxChange(props.row)" />
                    <span />
                </label>
            </template>
            <template slot="readonly" slot-scope="props">
                <label>
                    <input 
                        type="checkbox" 
                        :class="{'exclusive checkbox': true}"
                        :disabled="checkboxDisabled(props.row)"
                        @change="() => onCheckboxChange(props.row)"
                        v-model="props.row.readonly" />
                    <span :class="{'!cursor-default':checkboxDisabled(props.row)}" />
                </label>
            </template>
        </v-client-table>


    </div>
</template>

<script>
import { API_URL } from '@/lib/common';
import { eventBus } from '@/lib/eventbus';

export default {
    name: 'project-user-assignment-table',
    mixins: [],
    props: {
        'users': { type: Array, required: true },
        'parentId': { type: Number, required: true },
        'parentIdLabel': { type: String, required: true } //label to send the parent id as. i.e. projectID
    },
    components: {},
    data() {
        return {
            filterTable: '',
            data: [],
            columns: ['user.name', 'user.email', 'readonly','admin'],
            errors: [],
            options: {
                skin: "assignment-table",
                sortable: ['user.name', 'readonly', 'admin'],
                visibleColumns: ['user.name', 'readonly', 'admin'],
                filterable: ['user.name', 'user.email'],
                customSorting: {
                    'user.name': function (ascending) {
                        return function (a, b) {
                            var lastA = a.user.name.trim()[0].toLowerCase();
                            var lastB = b.user.name.trim()[0].toLowerCase();

                            if (ascending)
                                return lastA >= lastB ? 1 : -1;

                            return lastA <= lastB ? 1 : -1;
                        }
                    }
                },
                cellClasses: {
                    'user.name': [
                        { class: 'w-1/6 p-1', condition: () => true }
                    ],
                    'admin': [
                        { class: 'w-1/4 p-1', condition: () => true }
                    ],
                    'readonly': [
                        { class: 'w-1/6 p-1', condition: () => true }
                    ]
                },
                headings: {
                    admin: this.$t('projectForm-admin'),
                    readonly: this.$t('projectForm-read'),
                    'user.name': this.$t('User'),
                },
                perPage: 10,
                perPageValues: [10, 15, 20],
                texts: {
                    count: this.$t('common-tableTexts.user-count'),
                    limit: this.$t('common-tableTexts.user-limit'),
                    noResults: this.$t('dataDiscoverDownload-tableTexts.noResults'),
                    first: this.$t('common-tableTexts.first'),
                    last: this.$t('common-tableTexts.last'),
                    filter: this.$t('common-tableTexts.filter'),
                    filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
                    page: this.$t('common-tableTexts.page'),
                    noRequest: this.$t('common-tableTexts.noRequest'),
                    filterBy: this.$t('common-tableTexts.filterBy'),
                    loading: this.$t('common-tableTexts.loading'),
                    defaultOption: this.$t('common-tableTexts.defaultOption'),
                    columns: this.$t('common-tableTexts.columns')
                },
            }

        }
    },
    created() {

    },
    mounted() {
        this.data = this.users;
    },
    watch: {
        users(newVal, oldVal) {
            this.data = newVal;
        }
    },
    computed: {

    },
    methods: {
        checkboxDisabled(row) {
            return row.admin && row.readonly && row.inProject;
        },
        getUserDataObject(userId) {
            return this.data.find(u => userId === u.user.id) || {};
        },
        onCheckboxChange(user) {
            if (!user.inProject) { //User not in project

                if ((user.admin || user.readonly)) { //Checked admin or readonly or both
                    this.post(user, 'insert');
                    this.getUserDataObject(user.user.id).admin = user.admin;
                    this.getUserDataObject(user.user.id).readonly = user.readonly || user.admin;
                }
                this.getUserDataObject(user.user.id).inProject = true;
            } else {

                if (!user.admin && !user.readonly) { //Both admin and readonly unchecked. Remove user from thinger
                    this.post(user, 'delete');
                    this.getUserDataObject(user.user.id).inProject = false;
                } else { //Admin has been unchecked from both being checked.
                    this.post(user, 'update');
                } //
                this.getUserDataObject(user.user.id).admin = user.admin;
                this.getUserDataObject(user.user.id).readonly = user.readonly;

            }
            eventBus.$emit('project-user-assignment-list-changed');
        },
        clearErrors() {
            this.errors = [];
        },
        post(user, action) {
            let params = {
                users: [{ ...user }],
                action
            };
            params[this.parentIdLabel] = this.parentId;
            this.clearErrors();

            this.$http.post(API_URL + 'alter-project-users', params).then((response) => {
                if (response.data.hasOwnProperty('error')) {
                    this.errors.push(this.$t('projectForm-userAddError') + response.data.error);
                } else {

                }
            });
        }

    }
};

</script>

<style>
.VueTables__limit {
    bottom: 10px;
    right: 10px;
}
.VueTables__table.assignment-table tr:nth-child(even){
    background-color:rgb(230, 230, 230);
}

</style>