<template>
    <div class="row weather-wrapper">
      <!-- weather row -->
      <div v-if="!weatherLoaded" class="large-weather-box col m8 s12">
        <div class="col s12 m6 weatherbox">
          {{$t('weatherSegment-hourlyPending')}}
          </div>
        <div class="col s12 m6 weatherbox">
          {{$t('weatherSegment-dailyPending')}}
        </div>
      </div>
      <template v-else>
        <div v-if="weather.errorMsg" class="large-weather-box col m8 s12" >
          {{weather.errorMsg}}
        </div>
        <div v-else class="large-weather-box col s12" @click="openWeatherModal()" :class="getWrapperClass()">
          <div v-if="showDate" :class="classObj.dateSize" class="weatherbox col centered">{{$t('weather-data.dateTime')}} <div class="data"><template v-if="weather && weather.hourlyTimestamp">{{weather.hourlyTimestamp}}</template><template v-else>--</template></div></div>
          <div v-if="weather && weather.hourlyWeather && weather.hourlyWeather[0] !== $t('common-na')" :class="classObj.conditions">
              <div class="icon-text-wrap">
              <template v-for="(condition, index) in weather.hourlyWeather">
                <div v-if="condition === $t('common-na')" :key="'weather-icon-' + index">-</div>
                <img v-else class="weather-icon mx-auto" :key="'weather-icon-' + index" :src="weatherIcons[index]"/>
                <div :key="'condition-' + index">
                  {{condition}}
                </div>
              </template>
              </div>
            </div>
        <div :class="classObj.hourly" class="col s12 weatherbox">
              <div class="row">
                <div class="col s6">{{$t('weather-data.temperature')}} <div class="data"><template v-if="weather && weather.hourlyTemperature">{{weather.hourlyTemperature}}</template><template v-else>--</template></div></div>
                <div class="col s6">{{$t('weather-data.windSpeed')}} <div class="data"><template v-if="weather && weather.hourlyWindSpeed">{{weather.hourlyWindSpeed}}</template><template v-else>--</template></div></div>
              </div>
          </div>
          <div :class="classObj.daily" class="col s12 weatherbox">
            <div class="row">
              <div class="col s4">{{$t('weather-data.min')}} <div class="data"><template v-if="weather && weather.dailyMinTemp">{{weather.dailyMinTemp}}</template><template v-else>--</template></div></div>
              <div class="col s4">{{$t('weather-data.max')}} <div class="data"><template v-if="weather && weather.dailyMaxTemp">{{weather.dailyMaxTemp}}</template><template v-else>--</template></div></div>
              <div class="col s4">{{$t('weather-data.mean')}} <div class="data"><template v-if="weather && weather.dailyMeanTemp">{{weather.dailyMeanTemp}}</template><template v-else>--</template></div></div>
            </div>
            </div>
            <div :class="classObj.distSize" class="centered weatherbox col">{{$t('weather-data.distance')}}: <div class="data">
              <div class="row">
              <template v-if="weather && weather.hourlyStationDistance">
                <template v-if="weather.hourlyStationName === weather.dailyStationName">{{weather.hourlyStationDistance}}</template>
                <template v-else><div>{{$t('weatherSegment-hourlyShort')}}: {{weather.hourlyStationDistance}}</div><div>{{$t('weatherSegment-dailyShort')}}: {{weather.dailyStationDistance}}</div></template>
              </template>
              <template v-else>--</template>
                </div></div></div>
          </div>
      </template>
    <modal name="weatherModal"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      height = "90%"
      :minWidth="400"
      :minHeight="440"
      :clickToClose = "false"
      ref="tagForm">
      <weather-modal v-if="dateString && locationId" :projectId="projectId" v-bind:icons-src="weatherIcons" v-bind:date-string="dateString" v-bind:location-id="locationId" v-bind:site-latitude="siteLatitude" v-bind:site-longitude="siteLongitude"></weather-modal>
      </modal>
  <v-dialog/>
      </div>
</template>

<script>
import {API_URL} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import WeatherModal from '@/components/common/WeatherModal';

export default {
  name: 'weather-segment',
  props: ['dateString', 'locationId', 'siteLatitude', 'siteLongitude', 'showDate', 'projectId'],
  components: {'weather-modal': WeatherModal},
  async created () {
    await this.getWeather();
    if (this.weather && this.weather.hourlyWeather) {
      this.getWeatherIcons();
    }
    this.weatherLoaded = true;

    eventBus.$on('close-weather-modal', () => {
      this.$modal.hide('weatherModal');
    });
  },
  watch: {
    async dateString (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.weatherLoaded = false;
        this.weatherIcons = [];
        await this.getWeather();
        if (this.weather && this.weather.hourlyWeather) {
          this.getWeatherIcons();
        }
        this.weatherLoaded = true;
      }
    }
  },
  methods: {
    getWrapperClass () {
      let wrapperClasses = {};
      if (this.weather && (((this.weather.dailyMaxTemp || this.weather.dailyMinTemp || this.weather.dailyMeanTemp)) || this.weather.hourlyTemperature)) {
        wrapperClasses.clickable = true;
      }
      if (this.showDate && this.weather && this.weather.hourlyWeather && this.weather.hourlyWeather[0] !== 'NA') {
        wrapperClasses.m10 = true;
        this.classObj.conditions = 'col condition-wrapper m1';
        this.classObj.dateSize = 'm2';
        this.classObj.distSize = 'm2';
        this.classObj.hourly = 'm3';
        this.classObj.daily = 'm4';
      }
      if ((this.showDate && this.weather && (this.weather.hourlyWeather == null || (this.weather.hourlyWeather && this.weather.hourlyWeather[0] === 'NA'))) ||
    (!this.showDate && this.weather && this.weather.hourlyWeather && this.weather.hourlyWeather[0] !== 'NA')) {
        wrapperClasses.m7 = true;
        this.classObj.conditions = 'col condition-wrapper m1';
        this.classObj.dateSize = 'm2';
        this.classObj.distSize = 'm2';
        this.classObj.hourly = 'm4';
        this.classObj.daily = 'm4';
      }
      if (!this.showDate && this.weather && (this.weather.hourlyWeather == null || (this.weather.hourlyWeather && this.weather.hourlyWeather[0] === 'NA'))) {
        wrapperClasses.m7 = true;
        this.classObj.distSize = 'm3';
        this.classObj.hourly = 'm4';
        this.classObj.daily = 'm5';
      }
      
      if (Object.keys(wrapperClasses).length == 0) { 
        return {
          'flex': true,
          'items-start': true
        }
       }

      return wrapperClasses;
    },
    getWeatherIcons () {
      /* For reference, the weather svgs come from here: https://www.pixeden.com/icon-fonts/the-icons-font-set-weather
      note these will NOT show up locally */
      // since these are coming from an external site, its not possible to know when a new type of weather condition may be added.
      // this.isDay();
      for (let condition of this.weather.hourlyWeather) {
          switch (condition.toLowerCase()) {
          case ('mostly cloudy'): {
            // TODO: check if day or night
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/partly-cloudy-1.svg');
            break;
          }
          case ('clear'):
          case ('mainly clear'): {
          // TODO: check if day or night
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/sun-1.svg');
            break;
          }
          case ('moderate drizzle'):
          case ('drizzle'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/drizzle.svg');
            break;
          }
          case ('rain showers'):
          case ('moderate rain showers'):
          case ('heavy rain showers'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/heavy-rain-2.svg');
            break;
          }
          case ('moderate rain'):
          case ('heavy rain'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/heavy-rain-1.svg');
            break;
          }
          case ('rain'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/rain-1.svg');
            break;
          }
          case ('thunderstorms'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/thunderstorm.svg');
            break;
          }
          case ('cloudy'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/mostly-cloudy-2.svg');
            break;
          }
          case ('ice crystals'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/snowflake.svg');
            break;
          }
          case ('moderate snow'):
          case ('snow showers'):
          case ('heavy snow'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/blizzard.svg');
            break;
          }
          case ('snow'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/snow.svg');
            break;
          }
          case ('heavy hail'):
          case ('hail'):
          case ('snow pellets'):
          case ('snow grains'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/hail-2.svg');
            break;
          }
          case ('blowing snow'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/wind.svg');
            break;
          }
          case ('moderate freezing drizzle'):
          case ('freezing drizzle'):
          case ('freezing rain'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/rain-and-snow.svg');
            break;
          }
          case ('freezing fog'):
          case ('fog'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/fog-1.svg');
            break;
          }
          case ('smoke'):
          case ('haze'): {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/mist.svg');
            break;
          }
        /*  default: {
            this.weatherIcons.push('/.resources/wildtrax-content/webresources/weather-icons/mostly-cloudy-1.svg');
            break;
          } */
        }
      }
    },
    isDay () {
      /*
      Using this algorithm https://edwilliams.org/sunrise_sunset_algorithm.htm
      */
      // step 1: get day # of year
    /*  let date = new Date(this.weather.hourlyTimestamp);
      console.log(date);
      let dayOfMonth = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      console.log(year, month, dayOfMonth);
      let N1, N2, N3, N;
      N1 = Math.floor(275 * month / 9);
      N2 = Math.floor((month + 9) / 12);
      N3 = (1 + Math.floor((year - 4 * Math.floor(year / 4) + 2) / 3));
      N = N1 - (N2 * N3) + dayOfMonth - 30;
      console.log('N: ', N);
      // step 2: convert longitude to hour value
      let lngHr = this.taskOverview.location.longitude / 15;
      // get rising/setting time
      let risingTime = N + ((6 - lngHr) / 24);
      let settingTime = N + ((18 - lngHr) / 24);
      console.log('rising: ', risingTime, 'setting: ', settingTime);
      // step 3: calculate sun's mean anomaly
      let risingAnomaly = (0.9856 * risingTime) - 3.289;
      let settingAnomaly = (0.9856 * settingTime) - 3.289;
      // step 4: get sun's true longitude
      let risingSunLongitude = risingAnomaly + (1.916 * (180 / Math.PI) * Math.sin(risingAnomaly * (Math.PI / 180))) + (0.020 * (180 / Math.PI) * Math.sin(2 * (risingAnomaly * (Math.PI / 180)))) + 282.634;
      let settingSunLongitude = risingAnomaly + (1.916 * (180 / Math.PI) * Math.sin(settingAnomaly * (Math.PI / 180))) + (0.020 * (180 / Math.PI) * Math.sin(2 * (settingAnomaly * (Math.PI / 180)))) + 282.634;
      // next ensure they are within the range 0-360
      console.log('risingLongitude: ', risingSunLongitude, 'settingLongitude: ', settingSunLongitude);
      risingSunLongitude = this.getInRange(risingSunLongitude, 360);
      settingSunLongitude = this.getInRange(settingSunLongitude, 360);
      console.log('risingLongitude: ', risingSunLongitude, 'settingLongitude: ', settingSunLongitude);
      // step 5a: calculate sun's right ascension
      let risingSunAscension = (180 / Math.PI) * Math.atan(0.91764 * ((180 / Math.PI) * Math.tan((Math.PI / 180) * risingSunLongitude)));
      let settingSunAscension = (180 / Math.PI) * Math.atan(0.91764 * ((180 / Math.PI) * Math.tan((Math.PI / 180) * settingSunLongitude)));
      risingSunAscension = this.getInRange(risingSunAscension, 360);
      settingSunAscension = this.getInRange(settingSunAscension, 360);
      // Step 5b: right ascension needs to be in same quadrant as its longitude
      let risingLQuadrant = (Math.floor(risingSunLongitude / 90)) * 90;
      let risingRQuadrant = (Math.floor(risingSunAscension / 90)) * 90;
      risingSunAscension = risingSunAscension + (risingLQuadrant - risingRQuadrant);
      let settingLQuadrant = (Math.floor(settingSunLongitude / 90)) * 90;
      let settingRQuadrant = (Math.floor(settingSunAscension / 90)) * 90;
      settingSunAscension = settingSunAscension + (settingLQuadrant - settingRQuadrant);
      // step 5c: convert into hours
      risingSunAscension = risingSunAscension / 15;
      settingSunAscension = settingSunAscension / 15;
      console.log('rising final: ', risingSunAscension, 'setting final: ', settingSunAscension);
      // step 6: get sun's declination
      let risingSinDec = 0.39782 * ((180 / Math.PI) * Math.sin((Math.PI / 180) * risingSunLongitude));
      let risingCosDec = (180 / Math.PI) * Math.cos((180 / Math.PI) * Math.asin((Math.PI / 180) * risingSinDec));
      let settingSinDec = 0.39782 * ((180 / Math.PI) * Math.sin((Math.PI / 180) * settingSunLongitude));
      let settingCosDec = (180 / Math.PI) * Math.cos((180 / Math.PI) * Math.asin((Math.PI / 180) * settingSinDec));
      // step 7a: get sun's hour angle
      const zenith = 90;
      let risingCosH = ((180 / Math.PI) * Math.cos(zenith) - (risingSinDec * (180 / Math.PI) * Math.sin((Math.PI / 180) * this.taskOverview.location.latitude))) / (risingCosDec * (180 / Math.PI) * Math.cos((Math.PI / 180) * this.taskOverview.location.latitude));
      let settingCosH = ((180 / Math.PI) * Math.cos(zenith) - (settingSinDec * (180 / Math.PI) * Math.sin((Math.PI / 180) * this.taskOverview.location.latitude))) / (settingCosDec * (180 / Math.PI) * Math.cos((Math.PI / 180) * this.taskOverview.location.latitude));
      console.log('are these the same?? ', risingCosH, settingCosH);
      // step 7b: convert into hours
      let risingHour = 360 - (180 / Math.PI) * Math.acos((Math.PI / 180) * risingCosH);
      let settingHour = (180 / Math.PI) * Math.acos((Math.PI / 180) * settingCosH);
      risingHour = risingHour / 15;
      settingHour = settingHour / 15;
      // step 8: convert to local time
      let risingLocalMean = risingHour + risingSunAscension - (0.06571 * risingSunLongitude) - 6.622;
      let settingLocalMean = settingHour + settingSunAscension - (0.06571 * settingSunLongitude) - 6.622;
      console.log('hows this?', risingLocalMean, settingLocalMean);
      // step 9: back to UTC
      let risingUT = risingLocalMean - lngHr;
      let settingUT = settingLocalMean - lngHr;
      risingUT = this.getInRange(risingUT, 24);
      settingUT = this.getInRange(settingUT, 24);
      console.log(risingUT, settingUT); */
      // step 10: get it in local time
    },
    getInRange (num, rangeNum) {
      // helper function to get value between [0-rangeNum)
      while (num >= rangeNum) {
        num = num - rangeNum;
      }
      while (num < 0) {
        num = num + rangeNum;
      }
      return num;
    },
    async getWeather () {

      return this.$http.get(this.getWeatherInformationUrl + this.dateString.substring(0,19) + '&locationId=' + this.locationId).then((response) => {
        if (response.body.hasOwnProperty('error')) {
          this.weather.errorMsg = response.body.error;
        } else {
          this.weather = response.body;
        }
      }, (err) => {
        // error callback
        console.error(err);
        this.weather.errorMsg = this.$t('weatherSegment-errorMsg');
      });
    },
    openWeatherModal () {
      if (this.weather == null) {
        return;
      }
      this.$modal.show('weatherModal');
    }
  },
  data () {
    return {
      getWeatherInformationUrl: API_URL + 'get-weather-station-data-for-location?date=',
      weatherLoaded: false,
      weather: {},
      weatherIcons: [],
      classObj: {}
    }
  }

}
</script>

<style scoped>

.weather-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 0px !important;
}

.weatherbox{
  padding: 1em;
  text-align: center;
  margin-left: 0px !important;
}

.large-weather-box .data{
  color: #1369c5;
}

.large-weather-box.clickable:hover{
  cursor: pointer;
  background-color: #7d7d7d1A;
}

.large-weather-box{
  border: solid #7d7d7d 1px;
  border-radius: 2px;
  margin-left: 0px !important;
  color: #C94412;
}

.weather-title{
  clear: left;
}

.weather-icon{
  width: 35px;
}

.weather-icon::before{
  transform-origin: left top;
  transform: scale(3) translate(-50%, -25%);
}

.condition-wrapper{
  margin-top: 5px;
  text-align: center;
  padding: 0px !important;
}

.icon-text-wrap{
  float: left;
}

</style>
