<template>
    <div>
        <!-- <transition name="lightbox-fade"> -->
            <div ref="lightbox" tabindex="0" class="lightbox" :style="`${zIndex?`z-index: ${zIndex};`:''}`" v-if="visible" @mousedown.stop="hide" @touchdown.stop="hide" @keydown.esc="hide">
                <div v-show="controlsVisible" class="lightbox-download" @mousedown.stop="downloadPhoto" @touchdown.stop="downloadPhoto">{{ $t('lightbox-downloadPhoto') }}</div>
                <div class="btn fixed top-[9px] right-[208px] z-[210]" @mousedown.stop="toggleMDVisible" @touchdown.stop="toggleMDVisible">Toggle Detection</div>
                    <div class="lightbox-close" @mousedown.stop="hide" @touchdown.stop="hide">&times;</div>
                    <div class="lightbox-arrow lightbox-arrow-left" @mousedown.stop.prevent="prev" @touchdown.stop.prevent="prev">
                        <!-- <transition name="lightbox-fade"> -->
                            <div class="lightbox-arrow-left-icon" v-show="has_prev() && controlsVisible">
                                <svg height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="rgba(20, 20, 20, 0.4)" />
                                    <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" fill="white"/>
                                    <path d="M0-.5h24v24H0z" fill="none"/>
                                </svg>
                            </div>
                        <!-- </transition> -->
                    </div>
                    <div class="lightbox-arrow lightbox-arrow-right" @mousedown.stop.prevent="next" @touchdown.stop.prevent="next" >
                        <!-- <transition name="lightbox-fade"> -->
                            <div class="lightbox-arrow-right-icon" v-show="has_next() && controlsVisible" >
                                <svg height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="rgba(20, 20, 20, 0.4)" />
                                    <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" fill="white" />
                                    <path d="M0-.25h24v24H0z" fill="none"/>
                                </svg>
                            </div>
                        <!-- </transition> -->
                    </div>
                    <!-- <transition name="lightbox-fade"> -->
                        <div class="lightbox-caption" v-show="controlsVisible && filteredImages[index].alt" @mousedown.stop @touchdown.stop>
                            <span unselectable="on">{{ filteredImages[index].alt }}</span>
                        </div>
                    <!-- </transition> -->
                <div class="lightbox-main" @mousedown.stop="hide" @touchdown.stop="hide">
                    <div class="lightbox-image-container" @mousedown.stop @touchdown.stop>
                        <!-- <transition name="lightbox-slide" mode="out-in"> -->

                            <img @load="imageLoad" :src="this.directory + filteredImages[index].name" style="display:none" />
                            <div ref="lightboxImage" class="lightbox-image relative flex justify-content items-center" :key="index" >
                                    <div ref="boundingbox" class="w-full h-full m-auto relative">
                                        <span v-if="filteredImages[index].megaDetector">
                                            <template  v-for="(MDResult,index) in filteredImages[index].megaDetector">
                                                <div :title="`${megaDetectorCategories.find(v=>v.id===MDResult.category).type} ${MDResult.confidence}`" 
                                                     v-show="megadetectorVisible"
                                                     v-if="(MDResult.confidence) > parseFloat(filters.MDConfidence)"
                                                     :key="index" 
                                                     class="absolute border border-red-500" 
                                                     
                                                     :style="{  'z-index': (index+100),
                                                                left:`${MDResult.box1*100}%`, 
                                                                top: `${MDResult.box2*100}%`, 
                                                                width: `${MDResult.box3*100}%`, 
                                                                height: `${MDResult.box4*100}%`
                                                             }">
                                                    <div class="absolute -top-[16px] pl-1 text-white bg-red-500/25 w-full text-xs overflow-hidden whitespace-nowrap" >{{megaDetectorCategories.find(v=>v.id===MDResult.category).type}} {{MDResult.confidence}}</div>
                                                </div>
                                            </template>
                                        </span>
                                    </div>
                                   
                            </div>
                        <!-- </transition> -->
                    </div>
                </div>
            </div>
        <!-- </transition> -->
    </div>
</template>

<script>
import { eventBus } from '@/lib/eventbus';

    export default {
        props: {
            // images = [{ name:'image1.jpg', alt:'Redwoods', filter:'nature' }, ...]
            images: {
                type: Array,
                required: true
            },
            // Used to display a subset of photos. If used, images array must contain a property
            // titled 'filter' which contains the desired filter string
            filter: {
                type: String,
                default: 'all'
            },
            // Used if images are located in a separate folder (e.g. '/images/')
            directory: {
                type: String,
                default: ''
            },
            // Used to set the duration in miliseconds of key/mouse inactivity before caption
            // and arrows disappear
            timeoutDuration: {
                type: Number,
                default: 3000
            },
            canDownload: {
                type: Boolean,
                ddefault: false
            },
            megaDetectorCategories: {
                type: Array
            },
            project: {required:false},
            zIndex: {type:Number, required:false}
        },
        data() {
            return {
                filters: {MDConfidence: 0},
                visible: false,       // Lightbox not visible by default
                controlsVisible: true, // Lightbox controls (arrows, caption, close button)
                index: 0,             // Index indicates which photo to display. Default to 1st photo
                timer: null, // Timer to show/hide lightbox controls
                imageWidth: 0,
                imageHeight: 0,
                imageRatio: 0, //ratio of currently displayed image
                windowWidth: 0,
                windowHeight: 0,
                windowRatio: 0,
                megadetectorVisible: true
            }
        },
        watch: {
            visible: function (newVal, oldVal) {
                if (oldVal !== newVal && newVal === true) {
                    window.addEventListener('resize', this.windowResizeEvent);
                    
                } else if  (oldVal !== newVal && newVal === false) {
                    window.removeEventListener('resize', this.windowResizeEvent);
                }
            },
            windowWidth: function(newVal, oldVal) {
                this.resizeBoundingBox();
            },
            windowHeight: function(newVal, oldVal) {
                this.resizeBoundingBox();
            }
        },
        created() {
            let that = this;
            eventBus.$on('MDConfidence-change', function (MDConfidence) {
                    that.filters.MDConfidence = MDConfidence;
            });
        },
        mounted() {
            window.addEventListener('keydown', this.keyEventListener);
            window.addEventListener('mousemove',this.mouseEventListener);
            window.addEventListener('touchmove',this.mouseEventListener);
            window.addEventListener('mouseup',this.mouseEventListener);
            
        },
        destroyed() {
            window.removeEventListener('keydown', this.keyEventListener);
            window.removeEventListener('mousemove',this.mouseEventListener);
            window.removeEventListener('touchmove',this.mouseEventListener);
            window.removeEventListener('mouseup',this.mouseEventListener);
        },
        methods: {
            toggleMDVisible() {
                this.megadetectorVisible = !this.megadetectorVisible;
            },
            resizeBoundingBox() {
                    if (this.windowRatio > this.imageRatio) {
                        this.$refs['boundingbox'].style.height = `${this.imageRatio * this.windowWidth}px`;
                        this.$refs['boundingbox'].style.width = `${this.windowWidth}px`;
                    } else {
                        this.$refs['boundingbox'].style.height = `${this.windowHeight}px`;
                        this.$refs['boundingbox'].style.width = `${ this.windowHeight/this.imageRatio}px`;
                    }
            },
            imageLoad(event) {
                this.$refs.lightboxImage.style.backgroundImage = `url(${event.target.src})`;
                this.imageRatio = event.target.height /  event.target.width;
                this.windowResizeEvent();
                this.resizeBoundingBox(event.target.height);
                
            },
            windowResizeEvent() {
                this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                this.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;  
                this.windowRatio = this.windowHeight / this.windowWidth;
                
            },
            show(imageName) {
                this.visible = true;
                this.controlsVisible = true;
                const that = this;

                // Find the index of the image passed to Lightbox
                for(let i = 0; i < this.filteredImages.length; i++) {
                    if(this.filteredImages[i].name == imageName) {
                        this.index = i;
                        break;
                    }
                }
                clearTimeout(this.timer);
                this.timer = setTimeout(function () {that.controlsVisible = false}, that.timeoutDuration);
                this.preloadNextImage();
                /* to disable scrolling on main screen
                otherwise, when the lightbox is open, users can still scroll background content
                */
                document.body.style.overflow = 'hidden';
                /* important,
                    when main screen has another esc key event,
                    we need to make the escape key only apply on the lightbox, not on both
                    1. add tabindex to the div, to make the div selectable.
                    2. make focus ont the lightbox div, so we know if keys are pressed on
                    the div or document body.
                */
                this.$nextTick(()=> { this.$refs.lightbox.focus(); });

            },
            hide(e) {
                this.visible = false;
                this.index = 0;
                clearTimeout(this.timer);
                document.body.style.overflow = 'auto';
            },
            has_next() {
                return (this.index + 1 < this.filteredImages.length);
            },
            has_prev() {
                return (this.index - 1 >= 0);
            },
            prev() {
                if (this.has_prev()) {
                    this.index -= 1;
                }
            },
            next() {
                if (this.has_next()) {
                    this.index += 1;
                    this.preloadNextImage();
                }
            },
            keyEventListener(e) {
                if (this.visible) {
                    const that = this;
                    this.controlsVisible = true;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(function () {that.controlsVisible = false}, that.timeoutDuration);

                    switch (e.key) {
                        case 'ArrowRight':
                            this.next();
                            break;
                        case 'ArrowLeft':
                            this.prev();
                            break;
                        case 'ArrowDown':
                        case 'ArrowUp':
                        case ' ':
                            e.preventDefault();
                            break;
                        case 'Escape':
                            this.hide();
                            break;
                    }
                }
            },
            // This event shows the arrows and caption on the lightbox when the mouse is moved or clicked.
            // Also used for touch events on touchscreen devices. The elements are set to disappear
            // after a given duration via a timer.
            mouseEventListener(e) {
                if (this.visible) {
                    const that = this;
                    this.controlsVisible = true;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(function () {that.controlsVisible = false}, that.timeoutDuration);
                }
            },
            preloadNextImage () {
                if (this.has_next()) {
                    try {
                        let _img = new Image();
                        _img.src = this.directory + this.filteredImages[this.index + 1].name;
                    } catch (e) { }
                }
            },
            async downloadPhoto () {
                /*
                    we are using a proxy to redirect our calls to be from the same origin, so we can rename file download names
                    bc you cant rename a file download if the source is not the same as the origin
                */
                let imgObj = this.filteredImages[this.index];
                let urlObj = new URL(imgObj.name);
                let href = ['/s3/', urlObj.host.split('.')[0], urlObj.pathname].join('');
                let downloadName = [];

                if (imgObj.hasOwnProperty('fileName')) {
                    // special case for visit photos, they are named differently on download
                    href += '/'+ imgObj.fileName;
                    downloadName = [imgObj.fileName];
                } else {
                    href  += '/' + 
                    [imgObj.orgName,
                    imgObj.locationName,
                    imgObj.date].join('_') + '.' + imgObj.extension;
                    downloadName = [
                        imgObj.orgName,
                        imgObj.locationName,
                        imgObj.date
                    ].join('_') +'.'+  imgObj.extension; 
                }

                let link = document.createElement('a');
                    link.href = href;
                    link.download =  downloadName
                    document.body.appendChild(link); // for firefox if link not added into body, click won't work
                    link.click();
                    link.remove();
            }
        },
        computed: {
            filteredImages: function () {
                const that = this;
                if (that.filter === 'all' || !that.filter.length) {
                         
                    return that.images;
                }
                else {
                   return that.images.filter(function (item) {
                
                        return item.filter === that.filter;
                    });

                }
            }
        }
    }
</script>

<style scoped>
.lightbox-close {
    font-size: 50px;
}
    .lightbox {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .9);
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 200;
        color: rgba(255,255,255,0.8);
    }

    .lightbox-close {
        position: fixed;
        z-index: 210;
        right: 0;
        top: 0;
        padding: 1rem;
        /* font-size: 1.7rem; */
        cursor: pointer;
        width: 4rem;
        height: 4rem;
        color: white;
    }

    .lightbox-download {
        text-decoration: underline;
        position: fixed;
        z-index: 210;
        right: 40px;
        top: 0;
        padding: 1rem;
        /* font-size: 1.7rem; */
        cursor: pointer;
        width: 11rem;
        height: 4rem;
        color: white;
    }

    .lightbox-main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .lightbox-arrow {
        padding: 0 2rem;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0 2rem;
        
        width: 2rem;
        z-index: 1000;
    }

    .lightbox-arrow-right { right: 0; }

    .lightbox-arrow-left { left: 0; }

    .lightbox-image-container {
        -webkit-box-flex: 1;
        width: 20%;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .lightbox-image {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .lightbox-caption {
        position: absolute;
        bottom: 15px;
        width: 100%;
        z-index: 100;
        text-align: center;
        text-shadow: 1px 1px 3px rgb(26, 26, 26);
    }

    .lightbox-caption span {
        border-radius: 12px;
        background-color: rgba(0, 0, 0, .6);
        padding: 2px 10px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .lightbox-slide-enter-active,
    .lightbox-slide-leave-active {
        transition: all 0.3s ease;
    }

    .lightbox-slide-enter {
        -webkit-transform: translateX(100px);
        -ms-transform: translateX(100px);
        transform: translateX(100px);
        opacity: 0;
    }

    .lightbox-slide-leave-to {
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
        opacity: 0;
    }

    .lightbox-fade-enter-active,
    .lightbox-fade-leave-active {
        transition: all 0.4s ease;
    }

    .lightbox-fade-enter,
    .lightbox-fade-leave-to {
        opacity: 0;
    }

</style>