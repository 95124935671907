<template>
  <div id="everythingTab" class="" style="position: relative;">
    <div v-if="loading" class="loading">
      <pulse-loader :loading="true" color="#127916" size="25px" :width="100" :height="50"></pulse-loader>
    </div>
    <mapbox ref="map"
    :access-token="accessToken"
    :map-options="{
    center: [-96, 37.8],
    zoom: 3,
    style: 'mapbox://styles/mapbox/light-v10' //'mapbox://styles/mapbox/light-v9'
    }"
    :geolocate-control="{
      show: true,
      position: 'top-left',
    }"
    :scale-control="{
      show: true,
      position: 'top-left',
    }"
    :fullscreen-control="{
      show: true,
      position: 'top-left',
    }"
    @map-load="mapLoaded"
    @map-init="initalized"
    @map-data="dataEvent"
    @map-click:clusters="clickCluster"
    @map-click:unclustered-point="clickPoint"
    @map-mouseenter:unclustered-point="mouseEntered"
    @map-mouseleave:unclustered-point="mouseLeft"
    @geolocate-error="geolocateError"
    @geolocate-geolocate="geolocate"
    />
  </div>
</template>

<script>
/* dislay a cluster of point
  Can't update points when input changes. rely on parent components to handle.

  The weather features (no clustering) have been added to this code.
  In the future, you may want to create a new component for weather maps, but for now the functionality is similar so i kept it here.
*/
/* https://github.com/phegman/vue-mapbox-g
  need to include mapbox gl js and css in html as well.
*/
import Mapbox from 'mapbox-gl-vue'; // can't be combined import; i.e. import {Mapbox} will fail
import mapboxgl from 'mapbox-gl';
import { API_URL, MAP_ACCESS_TOKEN } from '@/lib/common';
import { fitToPoints, checkSourceUnload, setLanguage } from '@/components/mapGL/mapUtil';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading
export default {
  name: 'location-maps',
  components: {
    'mapbox': Mapbox,
    'pulse-loader': PulseLoader
  },
  props: {
    activeProject: { type: String, default: 'unknown' },
    categoryId: { type: Number, default: 5 },
    mapType: { type: String, default: 'organization' }},
  data: function () {
    return {
      accessToken: MAP_ACCESS_TOKEN,
      maxZoom: 12,
      map: null,
      pointSourceName: 'points',
      loading: true,
      apiLinks: {
        organization: 'organization-map-cluster-info?organizationId=',
        project: 'project-map-cluster-info?projectId='
      }
    }
  },
  watch: {
    categoryId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.manualUpdate();
      }
    }
  },
  methods: {
    dataEvent (map, data) {
      if (data.dataType === 'source' && data.isSourceLoaded) {
        this.loading = false;
      } else {
        // this.loading = true;
      }
    },
    loadCustomStyle (map) {
      // map.setStyle('mapbox://styles/mapbox/light-v10');
      // return;
      // // something wrong with the style layers on vue mapbox, can't show other layers with this cached layer
      // const topographUrl = 'https://upsckv7isg.execute-api.us-west-2.amazonaws.com/dev2/layers/3602?type=basemap&id=3602&grid_id=1'; // grey scale
      // // const topographUrl = 'https://api.mapbox.com/styles/v1/mapbox/light-v9';

      // let headers = {};
      // headers['x-api-key'] = 'NSgyFqN2n31UQPd3uLA8o2w0A7TJ0Gk29JPVK041';
      // this.$http.get(topographUrl, { headers }).then(function (styleResponse) {
      //   const style = styleResponse.data && styleResponse.data.data && styleResponse.data.data.style;
      //   if (style) {
      //     map.setStyle(style);
      //   } else {
      //     map.setStyle('mapbox://styles/mapbox/light-v10')
      //   }
      // });
    },
    mapLoaded (map) {
      this.addClusterLayer(map);
      setLanguage(map);
    },
    /* copied from mapping portal */
    manualUpdate () {
      this.clearMap(true);
      this.addClusterLayer(this.$refs.map.map);
    },
    async clearMap (bInital) {
      const map = this.$refs.map.map;
      this.hoverPopup && this.hoverPopup.remove();
      const allLayers = ['clusters', 'clusters-buffer', 'cluster-count', 'unclustered-point', 'unclustered-point-buffer'];
      allLayers.forEach(l => {
        if (map.getLayer(l)) {
          map.removeLayer(l);
        }
      });

      /* remove markers form map and then clear the collections */
      for (let id in this.markers) {
        this.markers[id].remove();
      }
      for (let id in this.markersOnScreen) {
        this.markersOnScreen[id].remove();
      }
      this.markers = {};
      this.markersOnScreen = {};
      /* remove source data
        the easy way should be just replace map points,
        however, the cluster property defines how to cluster data can't
        be updated dynamically, so I remove and add the source instead.
      */
      if (map.getSource(this.pointSourceName)) {
        map.removeSource(this.pointSourceName);
        await checkSourceUnload(this.pointSourceName, map);
      }
      if (bInital) {
        map.easeTo({
          center: [-110, 50.8],
          zoom: 3
        });
      }
    },
    async addClusterLayer (map) {
      let response;
      try {
        response = await this.$http.get(API_URL + this.apiLinks[this.mapType] + this.categoryId);
      } catch (e) {
        this.error = this.$t('map-loadingError');
      }

      const coordinates = response.data.features;
      if (!coordinates || coordinates.length === 0) { // don't do anything when no point
        return;
      }

      fitToPoints(map, coordinates);
      // handle cluster general info -- weather doesnt cluster
      let clusterProperties = {};

      const match = ['==', ['has', 'buffer'], true];
      clusterProperties['isBuffered'] = ['+', ['case', match, 1, 0]];

      map.addSource(this.pointSourceName, {
        type: 'geojson',
        // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
        // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
        data: response.data,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: this.pointSourceName,
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#ffc107',
            50,
            '#ffa400',
            100,
            '#ff9800',
            250,
            '#dd6606',
            750,
            '#c94412'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.addLayer({
        id: 'clusters-buffer',
        type: 'circle',
        source: this.pointSourceName,
        filter: ['all', ['has', 'point_count'], ['>', 'isBuffered', 0]],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            'rgba(255, 193, 07, 0.5)',
            50,
            'rgba(255, 164, 00, 0.5)',
            100,
            'rgba(255, 150, 00, 0.5)',
            250,
            'rgba(221, 102, 06, 0.5)',
            750,
            'rgba(201, 68, 18, 0.5)'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            30,
            100,
            40,
            750,
            50
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: this.pointSourceName,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: this.pointSourceName,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': // '#11b4da',
          ['match', ['get', 'selected'], 'true', '#C94412', '#11b4da'],
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
      map.addLayer({
        id: 'unclustered-point-buffer',
        type: 'circle',
        source: this.pointSourceName,
        filter: ['all', ['!has', 'point_count'], ['has', 'buffer']],
        paint: {
          'circle-color': 'rgba(17, 180, 218, 0)',
          'circle-stroke-color': 'rgba(17, 180, 218, 1)',
          'circle-radius': 15,
          'circle-stroke-width': 1
        }
      });
    },
    initalized (map) {
      this.loadCustomStyle(map);
      // const Draw = new MapboxDraw()
      // map.addControl(Draw, 'top-right')
    },

    // inspect a cluster on click
    clickCluster (map, e) {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource(this.pointSourceName).getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;
          // console.log('zoom is ', map.getZoom(), zoom);
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom // potential errors: Math.min(self.maxZoom, features[0].properties.point_count < 5 ? map.getZoom() : zoom)
          });
        }
      );
    },

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    clickPoint (map, e) {
      this.displayInfoBox(map, e);
    },
    displayInfoBox (map, e) {
      const features = e.features;
      const coordinates = e.features[0].geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let htmlStr = features.reduce((htmlStr, feature) => {
        return htmlStr + `<li><b>${this.$t('map-locationName')}</b>: ${feature.properties.name}
        <br/><b>${this.$t('map-bufferSize')}</b>: ${feature.properties.buffer}</li>`;
      }, '<ul>') + '</ul>';
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(htmlStr)
        .addTo(map);
    },
    mouseEntered (map) {
      map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeft (map) {
      map.getCanvas().style.cursor = ''
    },
    geolocateError (control, positionError) {
      console.log(positionError)
    },
    geolocate (control, position) {
      console.log(
        `User position: ${position.coords.latitude}, ${position.coords.longitude}`
      )
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 500px;
}
@media only screen and (min-width: 993px) {
  #map {
    height: 600px;
  }
}

@media only screen and (min-width: 1024px) {
  #map {
    height: 700px;
  }
}

.loading {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 3;
    /* background: white; */
}
</style>
