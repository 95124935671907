var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container overflow agreement" },
    [
      !(_vm.statusId === 3 || _vm.statusId === 1 || _vm.statusId === 4)
        ? _c("div", { staticClass: "col s12" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success projectAdd",
                on: { click: _vm.createBlankAgreement }
              },
              [_vm._v(_vm._s(_vm.$t("agreement-addBtn")))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.agreementList, function(agreement, index) {
        return _c(
          "ul",
          { key: "agreement" + index, staticClass: "vue collapsible" },
          [
            _c("li", [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      return _vm.toggleTab(index)
                    }
                  }
                },
                [
                  _c("p", [
                    _c("strong", [_vm._v(_vm._s(agreement.name))]),
                    _vm._v(" "),
                    _c("span", { staticClass: "ion-arrow-down-b" })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.collapseTabStatus[index] == true,
                      expression: "collapseTabStatus[index]==true"
                    }
                  ],
                  staticClass: "collapsible-body"
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12 m6 l8" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("agreementForm-fields.name")))
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" " + _vm._s(agreement.name))])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m3 l2" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("common-startDate")))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" " + _vm._s(agreement.startDate))])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m3 l2" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("common-endDate")))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" " + _vm._s(agreement.endDate))])
                    ]),
                    _vm._v(" "),
                    _vm.options.projectUsageAgreement != null &&
                    agreement.uaoId != null
                      ? _c(
                          "div",
                          { staticClass: "col s12 m6 l12" },
                          [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("agreementForm-fields.constraints")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.options.projectUsageAgreement, function(
                              opt,
                              index
                            ) {
                              return _c("p", { key: "UAO" + index }, [
                                opt.id == agreement.uaoId
                                  ? _c("span", [_vm._v(_vm._s(opt.type))])
                                  : _vm._e()
                              ])
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.$t("agreementForm-fields.signeeInfo"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l4" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("agreementForm-fields.fullName")))
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" " + _vm._s(agreement.signeeUserName))])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l4" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("agreementForm-fields.email")))
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(" " + _vm._s(agreement.signeeEmail))])
                    ]),
                    _vm._v(" "),
                    agreement.signeeOrganization != null
                      ? _c("div", { staticClass: "col s12 m6 l4" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tc("common-organization", 1)))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(" " + _vm._s(agreement.signeeOrganization))
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l4" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("agreementForm-fields.signedDate"))
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(" " + _vm._s(agreement.signeeSignedDate))
                      ])
                    ]),
                    _vm._v(" "),
                    agreement.fileName != null
                      ? _c("div", { staticClass: "col s12" }, [
                          _c("h5", [
                            _c(
                              "a",
                              {
                                staticClass: "link",
                                on: {
                                  click: function($event) {
                                    return _vm.downloadStreamFile(
                                      _vm.getAgreementFileDownload +
                                        agreement.id,
                                      agreement.fileName,
                                      "application/octet-stream"
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "icon ion-android-attach"
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("agreement-view")))
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn projectDeleteConfirm",
                          on: {
                            click: function($event) {
                              _vm.agreementRow = agreement
                              _vm.showForm = true
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ion-edit" }),
                          _vm._v(" " + _vm._s(_vm.$t("common-update")))
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn projectDeleteConfirm",
                          on: {
                            click: function($event) {
                              return _vm.deleteAgreement(agreement.id)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "ion-trash-a" }),
                          _vm._v(" " + _vm._s(_vm.$t("agreement-delete")))
                        ]
                      )
                    ])
                  ])
                ]
              )
            ])
          ]
        )
      }),
      _vm._v(" "),
      _vm.showForm
        ? _c("agreement-form", {
            attrs: {
              agreement: _vm.agreementRow,
              "show-form": _vm.showForm,
              options: _vm.options
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }