<template>
  <div>
    <div id="org-recording-table" class="col s12 panel boxed padded60 dataGroup tab-content">
      <div class="right">
        <dropdown-button>
          <template slot="menuTitle">{{$t('common-manage')}}</template>
          <template slot="dropdownButtons">
            <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloadingRecordings }" @click="downloadRecordings">
              <span style="font-size: 20px;"></span><span class="spin-icon"></span><template v-if="tableLoadedOnce && $refs.table.data.length > 0">{{$t('org-downloadRecordings')}}</template><template v-else>{{$t('org-downloadRecordingsTemplate')}}</template>
            </a>
            <a v-close-popover class="btn btn-success popover-menu-item" @click="uploadRecordings">
              {{ $t('Upload Recordings') }}
            </a>
          </template>
        </dropdown-button>
      </div>
      <v-server-table ref="table" :url="url" :columns="columns" :options="tableOptions" class="table vue-bordered w-full"
        @loaded="loaded"  @loading="loading">
        <template slot="beforeBody" v-if="isLoading">
          <pulse-loader></pulse-loader>
        </template>
        <div class="row taskTable" slot="child_row" slot-scope="props" v-if="toLoadSubRow">
          <pulse-loader v-if="props.row.childRowLoading" :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="child-row-load"></pulse-loader>
          <div v-if="!props.row.childRowLoading && props.row.subDetails && props.row.subDetails.length > 0">
            <div class="row">
              <div class="col s4">{{$t('org-recordingChildHeadings.projectName')}}</div>
              <div class="col s2">{{$t('org-recordingChildHeadings.assignedUser')}}</div>
              <div class="col s2">{{$t('org-recordingChildHeadings.method')}}</div>
              <div class="col s2">{{$t('org-recordingChildHeadings.uniqueBirdSpecies')}}</div>
              <div class="col s2">{{$t('org-recordingChildHeadings.uniqueBirdIndividual')}}</div>
            </div>
            <div class="subTable">
              <template v-for="(task, index) in props.row.subDetails">
                <div class="row link brown-color" :key="'subrow-' + task.taskId + '' + index" @click="openChildRowLink(task.taskId)">
                  <div class="col s4 red-font">{{task.projectName}} </div>
                  <div class="col s2">{{task.tagger}} </div>
                  <div class="col s2">{{task.method}} </div>
                  <div class="col s2">{{task.uniqueAVESSpecies}} </div>
                  <div class="col s2">{{task.uniqueAVESIndividuals}} </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="!props.row.childRowLoading && props.row.subDetails.length === 0">
            <p>{{$t('common-noTaskFound')}}</p>
          </div>
              <div v-if="!props.row.childRowLoading && showDeleteMediaButton" class="row top-margin">
                <div class="col s9"></div>
                <div class="col s3">
                  <div class="single-btn-wrapper right delete-recording-btn" v-tooltip="{content: deleteBtnTooltip(props.row, $tc('common-recording', 1).toLowerCase())}">
                    <button class="btn no-bottom-margin" :class="{'btn':showDeleteMediaButton}" :disabled="!showDeleteMediaButton || props.row.subDetails.length > 0" @click="deleteRecording(props.row)"> <span style="font-size: 20px;"></span>{{$t('org-deleteRecording')}}</button>
                  </div>
                </div>
              </div>
        </div>
      </v-server-table>
    </div>
    <v-dialog/>

    <audio-upload-form :organization-id="organizationId" :method-option="allMethodList" :organization-name="organizationName"></audio-upload-form>

  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all locations
under each Location tab, show a table listing all locations. users can also update Location properties + users
or remove locations if they have no associated data.
*/
import OrganizationMixin from './OrganizationMixin';
import { API_URL, ARU_RECORDING_URL } from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';
import AudioUploadForm from '../../components/task/audioUpload/AudioUploadForm.vue';


export default {
  name: 'Recording-Table',
  components: { 'pulse-loader': PulseLoader, 'dropdown-button': DropdownButtonWithOptionsVue, 'audio-upload-form': AudioUploadForm },
  mixins: [OrganizationMixin, FormMixin],
  props: {
    isEditable: {
      default: null,
      type: Boolean
    },
    organizationId: {
      default: null,
      type: Number
    },
    showDeleteMediaButton: {
      default: null,
      type: Boolean
    },
    organizationName: {
      default: null,
      type: String
    }
  },
  created() {
    this.loadAllMethodOptions();
  },
  watch: {
    organizationId () {
      if (this.$refs.table) {
        this.url = API_URL + 'get-recording-summary?organizationId=' + this.organizationId; // this will auto reload table
      }
    }
  },
  methods: {
    loading () {
      this.isLoading = true;
    },
    loaded () {
      this.isLoading = false;
      if (!this.tableLoadedOnce) {
        this.tableLoadedOnce = true;
      }
    },
    deleteRecording (row) {
      this.commonDelete(this.deleteRecordingUrl + row.id, null, () => {
        this.$refs.table.refresh();
      });
    },
    uploadRecordings() {
      this.$modal.show('upload-audio-form');
    },
    downloadRecordings () {
      if (this.downloadingRecordings) {
        return;
      }
      this.downloadingRecordings = true;
      this.$http.get(this.downloadRecordingsUrl + this.organizationId, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.organizationName + '_' + this.$tc('common-recording', 2) + '.zip';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingRecordings = false;
      },
      (err) => {
        this.downloadingRecordings = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
     loadAllMethodOptions () {
      this.$http.get(API_URL + 'get-recording-options').then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.allMethodList = null;
        } else {
          this.allMethodList = response.data.method;
        }
      }, (err) => {
        // error callback
        this.warning = this.$t('tasks-methodWarning') + err;
      });
    }
  },
   
  data () {
    const pageSize = 100;
    return {
      allMethodList: [],
      url: API_URL + 'get-recording-summary?organizationId=' + this.organizationId,
      deleteRecordingUrl: API_URL + 'delete-recording?recordingId=',
      downloadRecordingsUrl: API_URL + 'download-recordings-by-org-id?orgId=',
      childRowUrl: ARU_RECORDING_URL,
      getDetailUrl: ['get-recording-details-view?currentOrganizationId=', 'recordingId'],
      downloadingRecordings: false,
      columns: ['locationName', 'recordingDate', 'taskCount', 'length'],
      deleteRecordingMsg: this.$t('org-deleteRecordingWarning'),
      options: {},
      isLoading: false,
      tableLoadedOnce: false,
      recordName: this.$tc('common-recording', 1).toLowerCase(),
      tableOptions: {
        highlightMatches: true,
        childRowTogglerFirst: false,
        // childRow: function (h, row) {
        //   return
        // },
        filterByColumn: true,
        filterable: ['locationName', 'recordingDate'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['locationName', 'recordingDate', 'length'],
        listColumns: {
        },
        headings: {
          checkbox: '',
          locationName: this.$t('org-recordingHeadings.locationName'),
          recordingDate: this.$t('org-recordingHeadings.recordingDate'),
          length: this.$t('org-recordingHeadings.length'),
          taskCount: this.$t('org-recordingHeadings.taskCount')
        },
        columnsClasses: {
          locationName: 'name'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'locationName', ascending: true},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style scoped>
  .subrow-info p {
    color: #C94412;
  }

#org-recording-table :deep( .VueTables__table td),
#org-recording-table :deep( .VueTables__table th) {
    text-align: center;
  }
#org-recording-table :deep( .VueTables__table td.name),
#org-recording-table :deep( .VueTables__table th.name) {
    text-align: left;
  }

#org-recording-table :deep( .VueTables__child-row-toggler) {
    cursor: pointer;
  }
  #org-recording-table :deep( .VueTables__table .v-spinner) {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 100%;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.5);
  }

#org-recording-table :deep( .VueTables__table .taskTable){
    border: 1px solid #cecec6;
    background-color: #f7f7f7;
    padding: 10px;
  }
#org-recording-table :deep( .VueTables__table .subTable) {
    max-height: 200px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
#org-recording-table :deep( .VueTables__table td.name) {
    color: #C94412;
  }
  .brown-color {
    color: #ca7c0b;
  }
.no-bottom-margin{
  margin-bottom:0;
}
.top-margin{
  margin-top:10px;
}

.delete-location-btn{
  margin-left: 10px;
}

.single-btn-wrapper{
  /* this element and css needed in order to show tooltip on disabled btn */
  display: inline-block;
}
.red-font {
    color: red;
}
</style>
