import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import {API_URL} from '@/lib/common';

Vue.use(Vuex);
Vue.use(VueResource);

export default ({
    namespaced: true,
    state: {
        species: []
    },
    mutations: {
        setSpecies (state, species) {
            state.species = species;
        }
    },
    actions: {
        getAllSpecies ({state, commit, rootState}) {
            if (state.species.length === 0) {
                this._vm.$http.get(API_URL + 'get-all-species').then( resp => {
                    if (resp.body) {
                        commit('setSpecies', resp.body);
                        window.species = resp.body;
                    }
                });
            }
        },
        getAllSpeciesForce({state, commit, rootState}) {
            this._vm.$http.get(API_URL + 'get-all-species').then( resp => {
                if (resp.body) {
                    commit('setSpecies', resp.body);
                }
            });
        }
    }
});