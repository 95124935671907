var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-client-table", {
        attrs: { data: _vm.data, columns: _vm.columns, options: _vm.options },
        scopedSlots: _vm._u([
          {
            key: "checkbox",
            fn: function(props) {
              return [
                _c("label", [
                  props.row.sizeExceeded ||
                  props.row.validNamingFormat === false ||
                  props.row.isNewRecording === false ||
                  props.row.status == "uploaded" ||
                  props.row.status === "uploading" ||
                  _vm.status.uploading
                    ? _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: props.row.message,
                            expression: "props.row.message"
                          }
                        ],
                        staticClass:
                          "fa-solid fa-circle-exclamation text-red-500 text-lg"
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: props.row.includeInUpload,
                            expression: "props.row.includeInUpload"
                          }
                        ],
                        staticClass: "exclusive checkbox",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(props.row.includeInUpload)
                            ? _vm._i(props.row.includeInUpload, null) > -1
                            : props.row.includeInUpload
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = props.row.includeInUpload,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      props.row,
                                      "includeInUpload",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      props.row,
                                      "includeInUpload",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(props.row, "includeInUpload", $$c)
                              }
                            },
                            function($event) {
                              return _vm.updateRowStatus(props.row)
                            }
                          ]
                        }
                      }),
                  _vm._v(" "),
                  _c("span")
                ])
              ]
            }
          },
          {
            key: "location",
            fn: function(props) {
              return undefined
            }
          },
          {
            key: "fileName",
            fn: function(props) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(props.row.recordingName || props.row.fileName) +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "methodId",
            fn: function(props) {
              return [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: props.row.methodId,
                        expression: "props.row.methodId"
                      }
                    ],
                    attrs: {
                      disabled:
                        props.row.sizeExceeded ||
                        props.row.validNamingFormat === false ||
                        props.row.isNewRecording === false ||
                        props.row.status == "uploaded" ||
                        props.row.status === "uploading" ||
                        _vm.status.uploading
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            props.row,
                            "methodId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function($event) {
                          return _vm.updateMethodText(props.row)
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.methodOption, function(opt, optIndex) {
                    return !opt.deprecated
                      ? _c(
                          "option",
                          {
                            key: "real-method" + optIndex,
                            domProps: { value: opt.id }
                          },
                          [_vm._v(" \n                    " + _vm._s(opt.type))]
                        )
                      : _vm._e()
                  }),
                  0
                )
              ]
            }
          },
          {
            key: "isTriggered",
            fn: function(props) {
              return [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: props.row.isTriggered,
                        expression: "props.row.isTriggered"
                      }
                    ],
                    staticClass: "exclusive checkbox",
                    attrs: {
                      type: "checkbox",
                      disabled:
                        props.row.sizeExceeded ||
                        props.row.validNamingFormat === false ||
                        props.row.isNewRecording === false ||
                        props.row.status == "uploaded" ||
                        props.row.status === "uploading" ||
                        _vm.status.uploading
                    },
                    domProps: {
                      checked: Array.isArray(props.row.isTriggered)
                        ? _vm._i(props.row.isTriggered, null) > -1
                        : props.row.isTriggered
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = props.row.isTriggered,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  props.row,
                                  "isTriggered",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  props.row,
                                  "isTriggered",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(props.row, "isTriggered", $$c)
                          }
                        },
                        function($event) {
                          return _vm.updateIsTriggered(props.row)
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span")
                ])
              ]
            }
          },
          {
            key: "taskLength",
            fn: function(props) {
              return [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: props.row.taskLength,
                      expression: "props.row.taskLength"
                    }
                  ],
                  attrs: {
                    type: "number",
                    disabled:
                      props.row.sizeExceeded ||
                      props.row.validNamingFormat === false ||
                      props.row.isNewRecording === false ||
                      props.row.status == "uploaded" ||
                      props.row.status === "uploading" ||
                      _vm.status.uploading,
                    min: "1",
                    max: "9999",
                    placeholder: _vm.$t("Default")
                  },
                  domProps: { value: props.row.taskLength },
                  on: {
                    keyup: e => {
                      if (e.target.value < 1 || e.target.value > 9999)
                        e.target.value = ""
                    },
                    change: function($event) {
                      return _vm.updateTaskLength(props.row)
                    },
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(props.row, "taskLength", $event.target.value)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "sampleRate",
            fn: function(props) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(props.row.sampleRate || "--") +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "fileSize",
            fn: function(props) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(props.row.fileSize) +
                    " " +
                    _vm._s(_vm.$t("commonUnits-megabyte")) +
                    "\n        "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }