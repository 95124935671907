import { API_URL } from '@/lib/common';
import i18n from '@/i18n';

const audioTypePattern = /[.wav|.wac|.mp3]$/;
export const statusOption = {
  SKIP: i18n.t('audioUploader-statusOptions.skip'),
  // IGNORE: 'not include',
  QUEUE: i18n.t('audioUploader-statusOptions.queue'),
  UPLOADING: i18n.t('common-uploading'),
  UPLOADED: i18n.t('audioUploader-statusOptions.uploaded'),
  FAILED: i18n.t('audioUploader-statusOptions.failed')
  // ALLCOMPLETED: 'all'
};
export const ALLCOMPLETED = 'all';
export let audioUploader = {
  audioList: [],
  processingAudioList: {},
  // processedAudioId: [],

  onUpdateCallback: null,

  reset: function () {
    this.audioList = [];
    this.processingAudioList = [];
    // this.processedAudioId = [];
  },
  /*	parseFiles: async function (files, settings, http) {
      this.reset();
      let fileCount = files.length;
      for (let i = 0; i < fileCount; i++) {
        let file = files[i];

        if (!audioTypePattern.test(file.name) || file.size <= 1 ) {	 // don't process none supported Audio files or Audio too small
          continue;
        }
        const fileName = file.name.split('.').slice(0, -1).join('.'); // remove extension
        let fileProperty = {
          fileName,
          file,
          methodId: settings.defaultMethodId,
          includeInUpload: true
        };

        this.audioList.push(fileProperty);
      }

      /* after collection all file names, send them to API to check *
      if (this.audioList.length == 0 ){
        return {error: 'no audio file found'};
      }
      try {
        let response = await http.get( API_URL + 'check-recording-names',
        { params: { projectId: settings.projectId,
          recordingNames: this.audioList.filter( x => !x.duplicated).map(x => x.fileName) }
        });

        if (response.status !== 200|| response.data.hasOwnProperty('error')) {
          return {error: response.status !== 200 ? response.body && response.body.message: response.data.error};
        } else {
          response.data.forEach( checkedAudioObj => {
            let matchedRecording = this.audioList.find( l => l.fileName == checkedAudioObj.recordingName);
            if (matchedRecording != null) {
              matchedRecording = Object.assign(matchedRecording, checkedAudioObj); // append newly added fields to list

              matchedRecording.includeInUpload = matchedRecording.isNewRecording && matchedRecording.validNamingFormat; // only include when it is valid, and not existed before in db
              matchedRecording.status =   !matchedRecording.includeInUpload ? statusOption.SKIP : statusOption.QUEUE;
              if (matchedRecording.validNamingFormat == false) {
                matchedRecording.message = 'Invalid File Name';
              }
              if (matchedRecording.isNewRecording == false) {
                matchedRecording.message = 'File already exists in system.';
              }
            } else {
              console.log('error: can not mtach ',checkedAudioObj );
            }
          });
          return {successful: 'parsed'};
        }

      }catch(e) {
        return {error: e};
      };

    },
  */
  parseFolder: async function (files, settings, http) {
    this.reset();
    let fileCount = files.length;
    for (let i = 0; i < fileCount; i++) {
      let file = files[i].file;

      // for now this functionlaity works in chrome only, so webkitRelativePath *must* exist
      if (!file.webkitRelativePath) {
        console.error('webkitRelativePath NOT supported!');
        continue;
      }
      let parts = file.webkitRelativePath.split('/');
      parts.pop();
      if (parts.length < 1) {
        continue; // this should never happen as long as it's a folder upload
      }
      if (!settings.includeSubFolder && parts.length !== 1) { // only first level
        continue;
      }
      if (!audioTypePattern.test(file.name.toLowerCase()) || file.size <= 1) {	 // don't process none supported Audio files or Audio too small
        continue;
      }
      let tempNameArray = file.name.split('.');
      const fileName = tempNameArray.slice(0, -1).join('.'); // remove extension

      let duration = files[i].duration;
      let fileProperty = {
        fileName,
        file,
        fileSize: (file.size / (1024 * 1024)).toFixed(2),
        fileType: tempNameArray[tempNameArray.length - 1],
        methodId: settings.defaultMethodId,
        methodText: settings.defaultMethodText,
        includeInUpload: true,
        taskLength: settings.taskLength?settings.taskLength:files[i].duration,
        isTriggered: settings.isTriggered,
        sampleRate: files[i].sampleRate,
        duration,
        uuid: files[i].uuid
      };
      if (fileProperty.fileSize > 320) {
        
        fileProperty.sizeExceeded = true;
        fileProperty.status = statusOption.SKIP;
        fileProperty.message = i18n.t('audioUploader-messages.tooLarge');
        fileProperty.includeInUpload = false;
        console.log('ere', JSON.parse(JSON.stringify(fileProperty)));
      }
      else if (this.audioList.findIndex(l => l.fileName === fileName) !== -1) {
        fileProperty.duplicated = true;
        fileProperty.status = statusOption.SKIP;
        fileProperty.message = i18n.t('audioUploader-messages.duplicate');
        fileProperty.includeInUpload = false;
      } else {
        fileProperty.status = statusOption.QUEUE;
      }
      this.audioList.push(fileProperty);
    }

    /* after collection all file names, send them to API to check */
    if (this.audioList.length === 0) {
      return { error: i18n.t('audioUploader-noFileError') };
    }

    return { successful: 'parsed' };
    try {
      /* change from get to post, as get has content size limit, when users upload large
      numbers of files, that will exceed the size limit
      get post controller, need both parameters and bodys */
      
      let urlParams = [];
      for (let key in settings) {
        if (['projectId','organizationId','removeLeadingZeros'].includes(key) && settings[key]) {
          urlParams.push(`${key}=${settings[key]}`);
        }
      }

      let response = await http.post(API_URL + 'check-recording-names?'+urlParams.join('&'),
        { recordingNamesObj: this.audioList.filter(x => !x.duplicated).map(x => x.fileName) }
      );

      if (response.status !== 200 || response.data.hasOwnProperty('error')) {
        return { error: response.status !== 200 ? response.body && response.body.message : response.data.error };
      } else {
        response.data.forEach(checkedAudioObj => {
          let matchedRecording = this.audioList.find(l => l.fileName === checkedAudioObj.recordingName);
          if (matchedRecording != null) {
            matchedRecording = Object.assign(matchedRecording, checkedAudioObj); // append newly added fields to list
            matchedRecording.includeInUpload = matchedRecording.isNewRecording && matchedRecording.validNamingFormat && !matchedRecording.sizeExceeded; // only include when it is valid, and not existed before in db
            matchedRecording.status = !matchedRecording.includeInUpload ? statusOption.SKIP : statusOption.QUEUE;
            if (matchedRecording.validNamingFormat === false) {
              matchedRecording.message = i18n.t('audioUploader-messages.invalidName');
            }
            if (matchedRecording.isNewRecording === false) {
              matchedRecording.message = i18n.t('audioUploader-messages.alreadyInSystem');
            }

            // matchedRecording.includeInUpload = true; // TODO: for debug only, need to remove.
          } else {
            console.log('error: can not mtach ', checkedAudioObj);
          }
        });
        return { successful: 'parsed' };
      }
    } catch (e) {
      return { error: e };
    }
  },

  registerUpdateCallback(next) {
    if (typeof next === 'function') {
      this.onUpdateCallback = next;
    }
  },
  /* acutally uploading for a single file */
  async processSingleFile(http, settings) {
    if (this.processingAudioList.length === 0 || settings.stopUpload) {
      this.onUpdateCallback({ status: ALLCOMPLETED, endTime: Date.now() });
      return;
    }
    const fileObj = this.processingAudioList.shift();

    const uploadUrl = API_URL + settings.uploadUrl;
    let formData = new FormData();
    if (settings.projectId)
    formData.append('projectId', settings.projectId);
    if (settings.organizationId)
    formData.append('organizationId', settings.organizationId);
    formData.append('removeLeadingZeros', settings.removeLeadingZeros);
    formData.append('createTask', settings.createTask);
    formData.append('methodId', fileObj.methodId);
    if (fileObj.taskLength) formData.append('taskLength', fileObj.taskLength);
    formData.append('isTriggered', fileObj.isTriggered);
    formData.append('file', fileObj.file);
    
   
    this.onUpdateCallback({ fileName: fileObj.fileName, startTime: Date.now() });
    // let self = this;
    let uploadStatus = '';
    let message = '';
    let audioLength;
    try {
      const response = await http.post(`${uploadUrl}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

      if (response.data.hasOwnProperty('error')) {
        uploadStatus = statusOption.FAILED;
        message = response.data.error;
      } else {
        uploadStatus = statusOption.UPLOADED;
        audioLength = response.data.audioLength
      }
    } catch (response) {
      uploadStatus = statusOption.FAILED;
      message = i18n.t('audioUploader-unknownError');
    }
    this.onUpdateCallback({ fileName: fileObj.fileName, status: uploadStatus, message, endTime: Date.now(), audioLength });
    this.processSingleFile(http, settings); // move to next file
  },

  /* rewrite script to try upload Audios asynchronzely */
  /* entry to upload procedure
    processing sequentially, not asynchorinized
  */
  async upload(settings, http, fileStatus) {
    this.processingAudioList = this.audioList.filter(x => x.includeInUpload === true && x.status === fileStatus); // first put all file in a queue
    // this.processingAudioList = this.audioList.filter( x => x.status); // used for testing,
    this.onUpdateCallback({ startTime: Date.now(), totalFile: this.processingAudioList.length });
    /* need to have the number first, otherwise, the processingAudioList size is changed. */
    const concurrentTaskCount = Math.min(this.processingAudioList.length, settings.parallelUploadCount);
    for (let i = 0; i < concurrentTaskCount; i++) { // start with given number of files, and each file will recursively call another one
      this.processSingleFile(http, settings);
    }
  }
};
