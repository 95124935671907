var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "col s12 panel boxed padded60 dataGroup tab-content",
        class: { [_vm.sensor]: true }
      },
      [
        _c("v-client-table", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sensor === "ARU" && _vm.aruTableData.length > 0,
              expression: "sensor === 'ARU' && aruTableData.length > 0"
            }
          ],
          attrs: {
            data: _vm.aruTableData,
            columns: _vm.aruColumns,
            options: _vm.aruTableOptions
          },
          scopedSlots: _vm._u([
            {
              key: "location",
              fn: function(props) {
                return [
                  _c("a", { attrs: { href: _vm.getReportUrl(props.row) } }, [
                    _vm._v(_vm._s(props.row.location))
                  ])
                ]
              }
            },
            {
              key: "child_row",
              fn: function(props) {
                return _c(
                  "div",
                  { staticClass: "row no-bottom-margin taskTable" },
                  [
                    _c("div", { staticClass: "row s12" }, [
                      _c("div", { staticClass: "col center s4" }, [
                        _vm._v(_vm._s(_vm.$t("landOwnerTable-speciesFound")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "subtable" },
                      [
                        [
                          _c(
                            "div",
                            { staticClass: "row s12 brown-color" },
                            [
                              props.row.speciesFound.length === 0
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "landOwnerTable-noSpeciesFoundRecording"
                                        )
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(props.row.speciesFound, function(
                                species,
                                index
                              ) {
                                return [
                                  _vm._v(_vm._s(species)),
                                  index != props.row.speciesFound.length - 1
                                    ? [_vm._v(", ")]
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      ],
                      2
                    )
                  ]
                )
              }
            }
          ])
        }),
        _vm._v(" "),
        _c("v-client-table", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sensor === "CAM" && _vm.camTableData.length > 0,
              expression: "sensor === 'CAM' && camTableData.length > 0"
            }
          ],
          attrs: {
            data: _vm.camTableData,
            columns: _vm.camColumns,
            options: _vm.camTableOptions
          },
          scopedSlots: _vm._u([
            {
              key: "location",
              fn: function(props) {
                return [
                  _c("a", { attrs: { href: _vm.getReportUrl(props.row) } }, [
                    _vm._v(_vm._s(props.row.location))
                  ])
                ]
              }
            },
            {
              key: "child_row",
              fn: function(props) {
                return _c(
                  "div",
                  { staticClass: "row no-bottom-margin taskTable" },
                  [
                    _c("div", { staticClass: "row s12" }, [
                      _c("div", { staticClass: "col center s4" }, [
                        _vm._v(_vm._s(_vm.$t("landOwnerTable-speciesFound")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "subtable" },
                      [
                        [
                          _c(
                            "div",
                            { staticClass: "row s12 brown-color" },
                            [
                              props.row.speciesFound.length === 0
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "landOwnerTable-noSpeciesFoundImg"
                                        )
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(props.row.speciesFound, function(
                                species,
                                index
                              ) {
                                return [
                                  _vm._v(_vm._s(species)),
                                  index != props.row.speciesFound.length - 1
                                    ? [_vm._v(", ")]
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      ],
                      2
                    )
                  ]
                )
              }
            }
          ])
        }),
        _vm._v(" "),
        _vm.aruTableData.length === 0 && _vm.camTableData.length === 0
          ? _c("div", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.$t("landOwnerTable-noData")))
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }