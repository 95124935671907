<template>
    <div class="vue remote-camera camera-tagging no-top-sticky-menu">
      <div class="container error padded60" v-if="error != null">
        <span v-if="error.statusText != null">
          {{error.statusText}}
        </span>
        <span v-else>
          {{error}}
        </span>
      </div>
      <div><span v-if="message" class="error" style="pading-right:20px;">{{message}} <span class="ion-android-close link" @click="message = null"/></span></div>
      <template v-if="error == null">
        <div v-if="deploymentDetails!=null">
            <div class="container large ">
                <div class="row abmi-breadcrumb">
                    <div class="col s7">
                        <p><a :href="breadcrumbHomeLink()">{{$t('common-home')}}</a> / <a :href="breadcrumbBaseLink() + 'CAM'">{{$t('common-camera')}}</a> /
                        <a :href="breadcrumbCAMTAGGINGProjectLink()">{{deployment && deployment.project.fullNm}}</a></p>
                    </div>
                    <div class="col s5 pull-right">
                      <p>
                        <span>{{$t('common-status')}}: {{deploymentDetails.status && deploymentDetails.status.type}} </span>
                        <!-- only in tagging, and field of view -->
                        <template v-if="deploymentDetails.canUserModify && deploymentDetails.status && (ui.activeTab=='tag' || ui.activeTab=='fov') && isFullyTagged">
                          <span class="btn btn-success " v-if="deploymentDetails.status.type == 'Ready for Tagging'" @click="moveToPhase('Tagging Complete')">{{ $t('cameraTagging-moveToTaggingComplete') }}</span>
                          <span class="btn btn-success " v-if="deploymentDetails.status.type == 'Tagging Complete'" @click="moveToPhase('Ready for Tagging')">{{ $t('cameraTagging-moveToReadyForTagging') }}</span> <!-- auto done = 2-->
                          <!--span class="btn btn-success " v-if="deploymentDetails.status.type == 'Tagging Complete'" @click="moveToPhase('Species Verified')">Move to Species Verified</span>  phase 1 = 1-->
                        </template>
                      </p>
                    </div>
                </div>
            </div>

            <div class="stickyWrap"  id="topStickyBar">
              <div id="topStickyBarContent" class="sticky" style="position:static; "
              v-sticky="{zIndex: 10, stickyTop: 0, stickTopOnly: true, positionType: 'fixed', stickyContainerId: 'topStickyBar'}">
                <div class="container large taskHeader">
                    <div class="row"  >
                        <div class="col s12 m12 l8 title">
                          <div>
                            <h1 v-if="deploymentDetails != null && deployment != null">{{getDeploymentName}}</h1>
                            <div class="actionGroup">
                          
                                <div class="editLink" @click="toggleDialog(ui.helpFormName, true)">
                                    <span class="ion-help-circled"></span>{{ $t('common-help') }}</div>
                            </div>
                          </div>
                          <!-- <div>
                            <p>Date & Time: {{deployment && deployment.end_date}}</p>
                          </div> -->
                        </div>
                        <div class="col s12 m12 l4 filters" v-if="deploymentDetails.canUserModify">
                            <div class="row">
                              <div v-if="ui.activeTab === 'fov'" :class="{'col s6 right': $refs.tag && $refs.tag.selectedImageCount > 0, 'col s6 offset-s6': !$refs.tag || $refs.tag.selectedImageCount == 0 }">
                                    <button v-if="isFOVVerified !== true" class="btn btn-border fullWidth modal-trigger projectAdd" @click="markFOVAsVerified()">
                                        <span class="ion-ios-pricetag-outline"></span>{{ $t('cameraTagging-verifyFieldOfView') }}</button>
                                    <div class="right green-font" v-else><span class="ion-checkmark"></span>{{ $t('cameraTagging-fieldOfViewVerified') }}</div>
                                </div>
                                <div :class="{'col s6': !isFullyTagged, 'col s6 offset-s6': isFullyTagged }">
                                    <button class="btn btnImportant fullWidth modal-trigger projectAdd" href="#tagCameraModal" v-if="$refs.tag && $refs.tag.selectedImageCount > 0"  @click="tagSelected()">
                                        <span class="ion-ios-pricetag-outline"></span> {{ $t('common-tagSelected') }}
                                        <span class="qty">{{$refs.tag.selectedImageCount}}</span>
                                    </button>
                                </div>
                                <div v-if="!isFullyTagged && ui.activeTab !=='fov'" :class="{'col s6': $refs.tag && $refs.tag.selectedImageCount > 0, 'col s6 offset-s6': !$refs.tag || $refs.tag.selectedImageCount == 0 }">
                                    <button  class="btn btn-border fullWidth modal-trigger projectAdd" @click="tagAllUntagged()">
                                        <span class="ion-ios-pricetag-outline"></span> {{ $t('cameraTagging-updateAllUntagged') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
              </div>
            </div>
            <div class="container large taskheader">
                <weather-segment 
                  :projectId="deployment.project.id"
                  v-if=" ui.activeTab != 'map' && ui.activeTab != 'photos' && deployment  && $refs.tag && $refs.tag.imageList && $refs.tag.imageList.length > 0" v-bind:date-string="$refs.tag.imageList[0].date.split('.')[0]"
                  v-bind:location-id="deployment.location.id" v-bind:site-latitude="deployment.location.latitude" v-bind:site-longitude="deployment.location.longitude" v-bind:show-date="true"></weather-segment>
            
            </div>
        </div>

          <!-- tab area -->
        <div class="container overflow" v-if="deploymentDetails!=null">
          <section class="tabbed">
            <ul class="tabs">
              <!-- <template v-for="(tab, index) in tabs">
                <li :key="'tab' + index" v-bind:class="{[tab.id]: true, 'tab': true, active: ui.activeTab == tab.id}" @click="switchTab(tab.id)"><a v-bind:class="{ active: ui.activeTab == tab.id}">
                  <span :class="{[tab.icon]:true}"></span>{{tab.label}}</a>
                </li>
              </template>   -->
              <li class="tab" @click="switchTab('tag')" v-bind:class="{ '!border-gray-400 !border-2 !border-b-0': ui.activeTab == 'tag', 'tag': true}"><a v-bind:class="{ '': ui.activeTab == 'tag'}"><span class="ion-ios-camera"></span>{{ $t('cameraTagging-fullTagging') }} <template v-if="isFullyTagged === true"> <span class="ion-checkmark"></span></template></a></li>
              <li class="tab" @click="switchTab('series')" v-bind:class="{ '!border-gray-400 !border-2 !border-b-0': ui.activeTab == 'series', 'series': true}"><a v-bind:class="{ '': ui.activeTab == 'series'}"><span class="ion-ios-camera"></span>{{ $t('cameraTagging-seriesView') }} <template v-if="isFullyTagged === true"> <span class="ion-checkmark"></span> </template> </a></li>
              <li class="tab" v-if="isFOVVisible" @click="switchTab('fov')" v-bind:class="{ '!border-gray-400 !border-2 !border-b-0': ui.activeTab == 'fov', 'fov': true}" ><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': ui.activeTab == 'fov', 'fov': true}"><span class="ion-ios-camera"></span>{{ $t('cameraTagging-fieldOfView') }} <template v-if="isFOVVerified === true"> <span class="ion-checkmark"></span></template></a></li>
              <li class="tab" v-if="deployment && deployment.location.latitude && deployment.location.longitude" @click="switchTab('map')" v-bind:class="{ '!border-gray-400 !border-2 !border-b-0': ui.activeTab == 'map', 'map': true}" ><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': ui.activeTab == 'map', 'map': true}"><span class="ion-map"></span>{{ $t('common-map') }}</a></li>
              <li class="tab" v-if="deployment && deployment.hasLocationVisitImages" @click="switchTab('photos')" v-bind:class="{ '!border-gray-400 !border-2 !border-b-0': ui.activeTab == 'photos', 'photos': true}" ><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': ui.activeTab == 'photos', 'photos': true}"><span class="ion-ios-camera"></span>{{ $t('common-locationPhotos') }}</a></li>
                <!--li class="tab" @click="switchTab('pole')"><a v-bind:class="{ active: ui.activeTab == 'pole'}"><span class="ion-ios-camera"></span>Pole Analysis</a></li>
                <li class="tab" @click="switchTab('gap')"><a v-bind:class="{ active: ui.activeTab == 'gap'}"><span class="ion-ios-camera"></span>Gap Analysis</a></li> -->
            </ul>
            <div class="padded boxed dataGroup !border-gray-400 border-2 tab-content" v-bind:class="{[ui.activeTab]: true}">
               <tagging-filter ref="filter" v-if="staticOptions!=null && deploymentOptions && deployment && typeof deploymentDetails.project != 'undefined'" v-bind:options="deploymentOptions"
                  v-bind:static-options="staticOptions"
                  
                  v-bind:init-species-id="initialSpeciesId"
                  v-bind:filter-mode="ui.activeTab"
                  v-bind:deployment-info="deployment"
                  v-bind:project="deploymentDetails.project"
                  v-bind:use-series-view="ui.activeTab=='series'"
                  v-bind:mega-detector-categories="megaDetectorCategories"
                  />
              <tagging-gallery v-if="deployment && staticOptions" ref="tag" v-show="ui.activeTab =='tag' || ui.activeTab =='fov' || ui.activeTab == 'series'"
                v-bind:deployment-id="userDeploymentId"
                v-bind:static-options="staticOptions"
                v-bind:filter-mode="ui.activeTab"
                v-bind:is-tagger="deploymentDetails.canUserModify"
                v-bind:project="deploymentDetails.project"
                v-bind:series-gap ="seriesGap"
                v-bind:mega-detector-categories="megaDetectorCategories"
                />
                <div  v-show="!deployment || !staticOptions" class="overaly" style="">
                  <div style="text-align: center;" class="my-24">
                    <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
                    <div> {{ $t('Loading Images') }} </div>
                  </div>
                </div>


              <div class="modal-content padded60" v-show="ui.activeTab =='map'">
                <div class="row">
                  <point-map v-if="deployment && deployment.location.latitude && deployment.location.longitude" v-bind:is-show=" ui.activeTab =='map'" v-bind:latitude="parseFloat(deployment.location.latitude)" v-bind:longitude="parseFloat(deployment.location.longitude)" />
                </div>
              </div>
              <div v-if="ui.activeTab === 'photos'" id="location-photos" class="col s12 padded60">
                <location-photos v-if="deploymentDetails" v-bind:location-id="deploymentDetails.deployment.locationId" v-bind:project-id="deployment.project.id"/>
            </div>
        <!-- END LOCATION PHOTOS PANEL -->
            </div>
          </section>
        </div>
        <div v-if="deploymentDetails==null">
          <h4>{{ $t('cameraTagging-cantFindTask') }}</h4>
        </div>
        <tagging-help v-bind:form-name="ui.helpFormName" />
        <fov-preview v-bind:fov-result="previewResult" v-bind:user-deployment-id="userDeploymentId" />
      </template>
      <v-dialog/>
    </div>
</template>

<script>
/* this component show is the camera tagging page.
*/
import Autocomplete from 'vuejs-auto-complete';
import {API_URL, CAM_PROJECT_URL, getParameterByName, alertDialog, confirmDialog, getAuthorizaitonHeader} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import TaggingGallery from './TaggingGallery';
import TaggingFilter from './TaggingFilter';
import TaggingHelp from './TaggingHelp';
import TaggingFOVPreviewForm from './TaggingFOVPreviewForm';
import TaggingMixin from './TaggingMixin.js';
import SinglePointMap from '@/components/mapGL/SinglePointMap';
import VisitLocationPhotos from '@/components/common/VisitLocationPhotos';
import WeatherSegmentVue from '../common/WeatherSegment.vue';
import CommonPageMixin from '@/components/common/CommonPageMixin';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading


export default {
  name: 'tagging-page',
  mixins: [TaggingMixin, CommonPageMixin],
  components: {
    'auto-complete': Autocomplete,
    'tagging-gallery': TaggingGallery,
    'tagging-filter': TaggingFilter,
    'tagging-help': TaggingHelp,
    'fov-preview': TaggingFOVPreviewForm,
    'point-map': SinglePointMap,
    'location-photos': VisitLocationPhotos,
    'weather-segment': WeatherSegmentVue,
     'pulse-loader': PulseLoader,
  },
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    },

    getDeploymentName () {
      try {
        // let deploymentName = '';
        const station = this.deploymentDetails.deployment.station;
        if (station) {
          const stationName = station ? station.name : '';
          const site = station.site;
          const siteName = site ? site.name : '';
          return `${siteName}-${stationName}`;
        } else {
          return this.deployment.location.name;
        }
      } catch (e) {}
      return this.deployment.location.name;
    }
  },
  methods: {
    showFilterUI () {
      if (this.ui.activeTab !== 'fov') {
        this.ui.showFilter = !this.ui.showFilter;
      }
    },
    switchTab (tabName) {
      this.ui.activeTab = tabName;
      /* reload image */
      let self = this;
      if (self.$refs.filter) {
        this.$nextTick(() => {
          self.$refs.filter.filterImages();
        });
      }
    },
    /* editDeployment () {
      alert('this function has not been implemented yet!');
    }, */
    tagAllUntagged () {
      this.$refs.tag.updateAllUntaggedImages();
    },
    /* always push to tag complete here */
    moveToPhase (phaseName) {
      if (!this.userDeploymentId) {
        return;
      }
      /* check if FOV verified
      if there is fov tab, and fov is not verified.
      */
      if (this.isFOVVisible && !this.isFOVVerified) {
        alertDialog(this.$modal, this.$t('cameraTagging-fovRequired'), this.$t('cameraTagging-pleaseFovReview'), 600);
        return;
      }

      const params = {
        id: this.userDeploymentId
        // status: { id: status.id } // automatically change status instead of setting statudid
      };
      const self = this;
      if (phaseName.includes('complete') || phaseName.includes('Complete')) {
        const warningMsg = this.$t('cameraTagging-changeDeploymentPhaseWarning', {oldPhase: this.deploymentDetails.status.type, newPhase: phaseName});
        confirmDialog(this.$modal, this.$t('cameraTagging-markTaggingTaskComplete'), warningMsg, this.$t('common-yes'), function () { self._updatePhase(params); });
      } else {
        this._updatePhase(params);
      }
    },
    _updatePhase (params) {
      this.$http.post(this.updateDeploymentStatusUrl, params).then(
        (response) => {
          if (response.data.hasOwnProperty('error')) {
            alertDialog(this.$modal, this.$t('cameraTagging-updateDeploymentStatusFailed') + ': ' + response.data.error);
          } else {
            this.deploymentDetails = response.data;
            alertDialog(this.$modal, this.$t('cameraTagging-updateDeploymentStatusCompleted'));
          }
        },
        (error) => {
          alertDialog(this.$modal, this.$t('cameraTagging-updateDeploymentStatusFailed') + ': ' + error);
        }
      );
    },
    loadFilterOption () { // when user updated tags, reload options
      this.$http.get(this.getDeploymentOptionUrl + this.userDeploymentId).then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.deploymentOptions = response.data;
        }
      }, (err) => {
        // error callback
        this.error = err;
      });
    },
    checkFullyTaggedStatus () {
      if (this.userDeploymentId) {
        this.$http.get(this.isFullyTaggedUrl + this.userDeploymentId).then((response) => {
          if (response.data.hasOwnProperty('error')) {
            // this.error = response.data.error;
            this.isFullyTagged = false;
          } else {
            this.isFullyTagged = response.data.isFullyTagged;
          }
        });
      }
    },
    /* get two flags to show tab, and verified button */
    checkFOVVisible () {
      if (this.userDeploymentId) {
        this.$http.get(this.isFOVVisibleUrl + this.userDeploymentId).then((response) => {
          if (response.data.hasOwnProperty('error')) {
            // this.error = response.data.error;
            this.isFOVVisible = false;
          } else {
            this.isFOVVisible = response.data.isAltered;
          }
        });
        this.$http.get(this.isFOVVerifiedUrl + this.userDeploymentId).then((response) => {
          if (response.data.hasOwnProperty('error')) {
            // this.error = response.data.error;
            this.isFOVVerified = false;
          } else {
            this.isFOVVerified = response.data.isValid;
          }
        });
      }
    },
    /* FOV */
    previewFOVUpdates () {
      this.$http.get(this.previewFOVUrl + this.userDeploymentId).then((response) => {
        if (response.data.hasOwnProperty('error')) {
          alert(response.data.error);
        } else {
          this.previewResult = response.data;
          this.toggleDialog(this.ui.fovPreviewFormName, true);
        }
      }, (err) => {
        // error callback
        this.error = err;
      });
    },
    markFOVAsVerified () {
      this.previewFOVUpdates();
    }
  },
  created () {
    this.userDeploymentId = parseInt(getParameterByName('id'));
    if (!this.userDeploymentId) {
      this.error = this.$t('cameraTagging-missingUserDeploymentId');
      return;
    }
    this.initialSpeciesId = parseInt(getParameterByName('speciesId'));
    if (this.initialSpeciesId) {
      this.hasFilter = true;
    }
    this.loadStaticOption(false);
    this.checkFullyTaggedStatus();
    this.checkFOVVisible();
    /* get project details: name, year, description */
    this.loadFilterOption();
    this.$http.get(this.getDeploymentOverviewUrl + this.userDeploymentId).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.deployment = response.data;
      }
    }, (err) => {
    // error callback
      this.error = err;
      // return;
    });

    /* get deployment detail */
    this.$http.get(this.getDeploymentDetailUrl + this.userDeploymentId).then((response) => {
      this.deploymentDetails = response.data;
      this.showSubsamplingView = this.deploymentDetails.status.type.includes('Tagging'); // ready for tagging or tagging completed.
      this.seriesGap = this.deploymentDetails.project.seriesGap;
      /* default view should be always be the tag window
        this.switchTab(this.showSubsamplingView ?  'subsample' : 'tag');
      */
    }, (err) => {
    // error callback
      this.error = err;
    });
    /* get deployment status option */
    this.$http.get(this.getDeploymentStatusOptionUrl).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.statusOptions = response.data.status;
        this.megaDetectorCategories = response.data.megaDetectorCategories;
      }
    }, (err) => {
    // error callback
      this.error = err;
    });

    const self = this;

    /* always have specie filtered */
    eventBus.$on('update-filter-flag', function (queryParam) {
      let count = 0;
      if (queryParam.excludeAutoTag === false) {
        count++;
      }
      // console.log(Object.keys(queryParam).length, count, queryParam);
      self.hasFilter = (Object.keys(queryParam).length > count);
    });

    eventBus.$on('reload-filter-options', function () {
      self.loadFilterOption();
    });

    eventBus.$on('reload-static-option', function () {
      self.loadStaticOption(true);
    });

    /* deployment status only, to refresh the flag to  */
    eventBus.$on('update-tagging-status', function () {
      self.checkFullyTaggedStatus();
      self.checkFOVVisible();
    });
    eventBus.$on('update-fov-verified-flag', function (isVerifiedFlag) {
      self.isFOVVerified = isVerifiedFlag;
    });
  },
  data () {
    return {
      projectPageUrl: CAM_PROJECT_URL,
      userDeploymentId: -1, // current deployment + user assignment id
      // getStaticOptionUrl: API_URL + 'get-camera-project-user-deployment-static-options', // static options such as sex age
      // staticOptions: null,
      getDeploymentOptionUrl: API_URL + 'get-camera-project-user-deployment-options?id=', // dynamic options such as species name
      deploymentOptions: null,
      getDeploymentOverviewUrl: API_URL + 'get-camera-project-user-deployment-overview?id=', // basic information about this deployment
      deployment: null,
      getDeploymentDetailUrl: API_URL + 'get-camera-pud?id=', // detailed information about this deployment /project
      deploymentDetails: {},
      /* update deployment information */
      updateDeploymentUrl: API_URL + 'update-camera-pud',

      getTaskDescriptionUrl: API_URL + 'get-project',
      project: {},
      getDeploymentStatusOptionUrl: API_URL + 'get-camera-pud-options',
      updateDeploymentStatusUrl: API_URL + 'update-camera-pud-push-forward', // update-camera-pud-option',
      statusOptions: null, // { taskStatus: [] },
      /* FOV */
      previewFOVUrl: API_URL + 'camera-field-of-view-analysis?pudId=',
      previewResult: null,
      isFOVVerifiedUrl: API_URL + 'camera-is-valid-field-of-view?pudId=',
      isFOVVerified: false,
      isFOVVisibleUrl: API_URL + 'camera-has-altered-field-of-view?pudId=',
      isFOVVisible: null,
      /* end of FOV */
      hasFilter: false, // for UI, show whether filter has values;
      ui: {
        activeTab: 'tag',
        showFilter: false,
        helpFormName: 'help-form',
        showSelectAll: true,
        fovPreviewFormName: 'fov-preview-form'
      },
      error: null,
      message: null,
      showSubsamplingView: false,
      seriesGap: null,
      megaDetectorCategories: [],
      // tabs: [{id: 'tag', label: 'Full Tag', optionalLabel: '(All Tagged)', conditionField: 'isFullyTagged', icon: 'ion-ios-camera'},
      //   {id: 'subsample', label: '2-Stage Subsampling', optionalLabel: '(All Tagged)', conditionField: 'isFullyTagged', icon: 'ion-ios-camera'},
      //   {id: 'fov', label: 'Field of View Review', optionalLabel: '(Verified)', conditionField: 'fovVerified', icon: 'ion-ios-camera'},
      //   // {id: 'pole', label: 'Pole Analysis', icon: 'ion-ios-camera'},
      //   // {id: 'gap', label: 'Gap Analysis', icon: 'ion-ios-camera'}
      // ]
    }
  }
}
</script>
<style scoped>
.remote-camera .actionGroup .editLink.has-filter {
    color: #308233;
}
.padded.boxed.dataGroup {
  padding: 40px 20px;
}
.remote-camera #filterDrop .container.overflow {
    margin-bottom: 0px;
}
.vue .tabbed li.tab.active {
/*  border: #d6a491 2px solid; */
  border-bottom: 0px;
}
.vue .tab-content {
/*  border: #d6a491 2px solid; */
}
/*
.vue .tabbed li.tab.active.subsample {
  border: #d4de75 2px solid;
  border-bottom: 0px;
}
.vue .tab-content.subsample {
  border: #d4de75 2px solid;
}*/
.vue .tabbed li.tab.active.fov {
  border: #71b3e8 2px solid;
  border-bottom: 0px;
}
.vue .tab-content.fov {
  border: #71b3e8 2px solid;
}
.vue .tabbed li.tab.active.series {
  border: #ffc107 2px solid;
  border-bottom: 0px;
}
.vue .tab-content.series {
  border: #ffc107 2px solid;
}

.alert {
  font-size: 1.1em;
}

.highlight {
  font-weight: 600;
  color: #cc0000;
}

#location-photos{
  padding-top: 0px;
}

.disabled-filter{
  color: #7d7d7d !important;
  cursor: default !important;
}

</style>
