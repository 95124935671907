<template>
  <!-- -FORM PANEL- -->
  
  <div ref="tagform" class="col s12 m12 l4 xl3" id="infoPanel">
    <div  v-if="showTagForm && tag!= null" class="panel ltgrey padded40" id="newSpecies" style="display: block;">
      <span @click="closeTagForm" class="ion-close closeButton"></span>
      <h3 v-if="tagId <= 0">{{ $t('recordingForms-addNewTag') }}</h3>
      <h3 v-if="tagId > 0">{{ $t('recordingForms-updateTag') }}</h3>
      <div class="duplicateForm" v-if="showDuplicateForm && !disableDuplicateForm">
        <div>
          <label> {{ $t('recordingForms-duplicateTag') }}</label>
          <select ref="duplicatedSppInput" class="initialized" v-model="duplicateSppId" type="number" @change="onDupeTagSelect($event.target)">
            <option v-for="(option, index) in speciesIndividuals" :key="'duplicatspp' +index" :value2="option.speciesCode" :value="option.id">{{option.speciesCode}} | {{option.uniqueName}}</option>
          </select>
          <span class="invalid_msg">{{duplicateFormError}}</span>
        </div>
        <div>
          <label>{{ $t('recordingForms-vocalizationIfDiffers') }}</label>
          <select class="initialized" v-model="inputVocalizationId" :disabled="tagOptions.vocalization.length == 0" type="number">
            <option disabled="" selected="">{{ $t('recordingForms-chooseType') }}</option>
            <template v-for="(option, index) in tagOptions.vocalization">
              <option :key="'vol' +index" :value="option.id"
                v-if="!option.deprecated"  >
                {{option.type}}</option>
            </template>
          </select>
        </div>
        <div style="margin-bottom:10px">
          <label for="needsreview">
                    <input type="checkbox" id="needsreview"  :disabled="!isEditable"  name="needsreview" v-model="inputNeedsReview">
                    <span :class="{inactive: !isEditable}">{{ $t('common-needsReview') }}</span>
                  </label>
        </div>
        <div class="row">
          <div class="col s6 m6 l12 xl6">
            <input type="button" v-if="isEditable" @click="() => {duplicateTag()}" id="btnLock" class="btn btn-success fullWidth" :value="$t('recordingForms-duplicate')">
          </div>
          <div class="col s6 m6 l12 xl6">
            <input type="button" v-if="isEditable" @click="switchToNewForm" id="btnLock" class="btn btn-action fullWidth" :value="$t('recordingForms-new')">
          </div>
        </div>
      </div> <!-- duplicate form-->

      <div class="newForm autofilterInput" v-show="!showDuplicateForm || disableDuplicateForm">
        <div>
          <div v-if="!editParent || !isEditable">{{ $t('recordingForms-numTaggedOnIndividual', {num: tag.speciesIndividualTagCount}) }} </div>
          <label for="autocomplete-input" class="active">{{ $tc('common-species', 1 )}}</label>
          <span class="displayValue" v-if="!editParent || !isEditable">:{{species[tag.speciesIndividual.speciesId].code}} | {{species[tag.speciesIndividual.speciesId].commonName}}</span>
          <!-- {{!this.tag.speciesIndividual.species.inAlberta?'(Out of Alberta)':''}} -->
          <auto-complete v-show="editParent && isEditable" ref="speciesInput"
            :placeholder="$t('recordingForms-spaceListsAllSpecies')"
            :source= "getSpeciesAPIUrl"
            initialValue=""
            initialDisplay=""
            name="code"
            resultsValue="code"
            :results-display="formattedSpeciesDisplay"
            clearButtonIcon="ion-close"
            excludeCharacter ='|'
            :request-headers="authHeaders"
            :custom-result="autoIdSpecies"
            @selected="setSpeciesId"
            @clear="clearSpeciesInput"
            @focus="appendAutoSpeciesIds"
          >
          </auto-complete>
          <span class="invalid_msg" v-if="editParent && invalidSpecies">{{ $t('recordingForms-enterValidSpeciesName') }} </span>
        </div>

        <div>
          <label>{{ $t('recordingForms-individualOptional') }}<span  class="ion-information-circled" v-tooltip="$t('recordingForms-tooltips.autogenIndividual')"></span></label>
          <span class="displayValue" v-if="!editParent || !isEditable">: {{tag.speciesIndividual.uniqueName}}</span>
          <input v-if="editParent && isEditable" type="number" :placeholder="$t('recordingForms-individualAutoGenerated')" step="1" min="0" max="100" v-model.number="tag.speciesIndividual.uniqueName"
            @input="loadSppIndividualInfo" :disabled=" tag.speciesIndividual && this.species && this.species[tag.speciesIndividual.speciesId] && this.species[tag.speciesIndividual.speciesId].allowMultipleIndividuals === false" >
            <span class="invalid_msg" v-if="editParent && tagId && existingIndividual">{{ $t('recordingForms-individualExistsWarning') }}</span>
            <span class="invalid_msg" v-if="editParent && !tagId && existingIndividual">{{ $t('recordingForms-individualExistsWillUpdate') }}</span>
        </div>
        <div>
          <label>{{ $t('recordingForms-abundance') }} <span  class="ion-information-circled" v-tooltip="$t('recordingForms-tooltips.abundance')"></span></label>
          <template  v-if="!editParent || !isEditable">
            <span v-for="(option, index) in tagOptions.abundance" :key="'abund' +index">
              <span class="displayValue" v-if="option.id === tag.speciesIndividual.abundanceId">{{option.type}}</span>
            </span>
          </template>
          <template v-else>
            <select class="initialized" v-model="tag.speciesIndividual.abundanceId" type="number">
              <!--<option disabled="" selected="">Choose </option> -->
              <option v-for="(option, index) in tagOptions.abundance" :key="'abundanceSel' +index" :value="option.id">{{option.type}}</option>
            </select>
            <span v-if="tagOptionsError" class="invalid_msg">{{ $t('recordingForms-unableToGetAbundance') }}</span>
          </template>
        </div>
        <div v-if="!editParentOnly">
          <label> {{ $t('recordingForms-vocalization') }}</label>
          <template v-if="!isEditable">
            <span v-for="(option, index) in tagOptions.vocalization" :key="'vocal' + index">
              <span class="displayValue" v-if="option.id === tag.vocalizationId">{{option.type}}</span>
            </span>
          </template>
          <template v-else>
            <select v-if="isEditable" class="initialized" v-model="tag.vocalizationId" type="number">
              <!--<option disabled="" selected="">Choose Type</option> -->
              <template v-for="(option, index) in tagOptions.vocalization" >
                <option :key="'vocalSel' + index" :value="option.id"
                v-if="!option.deprecated || isVocalizationDeprecated"  :disabled="option.deprecated">{{option.type}}</option>
              </template>
            </select>
            <span v-if="tagOptionsError" class="invalid_msg">{{ $t('recordingForms-unableToGetVocalization') }}</span>
          </template>
          <span class="invalid_msg" v-if="formIncomplete">{{this.incompleteTagMsg}}</span>
        </div>
        <div>
          <label for="needsreview">
                    <input type="checkbox" id="needsreview"  :disabled="!isEditable"  name="needsreview" v-model="tag.needsReview">
                    <span :class="{inactive: !isEditable}">{{ $t('common-needsReview') }}</span>
                  </label>
        </div>
        <div>
          <label> {{ $t('common-comments') }}</label>
          <span v-if="!editParent || !isEditable" class="displayValue">{{tag.speciesIndividual.comments}}</span>
          <textarea v-if="editParent && isEditable" v-model="tag.speciesIndividual.comments"></textarea>
        </div>
        <hr class="divider"/>
        <div class="row">
          <div class="col s6 m6 l12 xl6">
            <input v-if="isEditable" type="button" @click="() => {insertUpdateTag();}" class="btn btn-action fullWidth" :value="$t('common-save')">
          </div>
          <div class="col s4 m6 l12 xl6" v-if="tagId > 0">
            <input v-if="isEditable" type="button" @click="removeTag()"  class="btn btn-outline fullWidth w-full" :value="$t('common-delete')">
          </div>

         
          <div class="col s6 m6 l12 xl6">
            <input type="button"  @click="closeTagForm(false)" class="btn btn-border fullWidth" :value="$t('common-cancel')">
          </div>
          <div class="col s4" v-if="tag.speciesIndividualTagCount > 1">
            <input type="button" v-if="!editParent && isEditable " @click="editParent=true" id="btnLock" class="btn btn-action fullWidth" :value="$t('recordingForms-editAllFields')">
            <input type="button" v-if="editParent && isEditable" @click="editParent=false" id="btnLock" class="btn btn-action fullWidth" :value="$t('recordingForms-editCurrentFields')">
          </div>
        </div>
      </div>
    </div> <!-- tag form -->

    <div class="panel ltgrey padded40">
      <span @click="toggleTaskForm(false)" class="ion-close closeButton"></span>
      <h3>{{ $t('recordingForms-taskInfo') }}</h3>
      <div>
        <label> {{ $t('recordingForms-rain') }}</label>
          <select :disabled="!modify" class="initialized" v-model="task.rainId" type="number">
          <option value="-100" disabled selected>{{ $t('recordingForms-level') }}</option>
          <option v-for="(option, index) in options.rain" :key="'rainSel' + index" :value="option.id">{{option.type}}</option>
        </select>
      </div>
      <div>
        <label> {{ $t('recordingForms-wind') }}</label>
        <select :disabled="!modify" class="initialized" v-model="task.windId" type="number">
          <option value="-100" disabled selected>{{ $t('recordingForms-level') }}</option>
          <option v-for="(option, index) in options.wind" :key="'windSel' + index" :value="option.id">{{option.type}}</option>
        </select>
      </div>

      <div>
        <label> {{ $t('recordingForms-industry') }}</label>
            <select :disabled="!modify" class="initialized" v-model="task.industrialNoiseId" type="number">
          <option value="-100" disabled selected>{{ $t('recordingForms-level') }}</option>
          <option v-for="(option, index) in options.industrialNoise" :key="index" :value="option.id">{{option.type}}</option>
        </select>
      </div>

      <div>
        <label> {{ $t('recordingForms-noise') }}</label>
            <select :disabled="!modify" class="initialized" v-model="task.otherNoiseId" type="number">
          <option value="-100" disabled selected>{{ $t('recordingForms-level') }}</option>
          <option v-for="(option, index) in options.noise" :key="index" :value="option.id">{{option.type}}</option>
        </select>
      </div>

      <div>
        <label> {{ $t('recordingForms-audioQuality') }}</label>
          <select :disabled="!modify" class="initialized" v-model="task.audioQualityId" type="number">
          <option value="-100" disabled selected>{{ $t('recordingForms-areThereIssues') }}</option>
          <option v-for="(option, index) in options.audioQuality" :key="index" :value="option.id">{{option.type}}</option>
        </select>
      </div>

      <div>
        <label> {{ $t('common-comments') }}</label>
        <textarea :disabled="!modify" v-model="task.comments"></textarea>
        <span class="invalid_msg">{{taskValidateMessage}}</span>
        <span class="invalid_msg">{{taskUpdateMessage}}</span>
      </div>
      <div class="row">
        <div class="col s6">
          <input v-if="modify"  @click="updateTask" id="btnSubmit" type="submit" :value="$t('common-update')" class="btn btn-success fullWidth">
        </div>
        <div class="col s6">
          <input type="button" @click="toggleTaskForm(false)" class="btn btn-border fullWidth" :value="$t('common-close')">
        </div>
      </div>
    </div>
  </div>
<!-- -END FORM PANEL- -->
</template>

<script>
import Autocomplete from 'vuejs-auto-complete';
import { eventBus } from '@/lib/eventbus.js'
import {getAuthorizaitonHeader, API_URL} from '@/lib/common';

export default {
  name: 'recording-form',
  props: ['showTagForm', 'taskOverview', 'tagId', 'task', 'drawBox', 'options', 'isEditable', 'toScroll', 'editParentOnly', 'taskUpdateMessage', 'modify', 'species'],
  components: {'auto-complete': Autocomplete},
  data () {
    return {
      incompleteTagMsg: this.$t('recordingForms-tagIncompleteMsg'),
      deleteTagMsg: this.$t('recordingForms-deleteTagMsg'),
      taskValidateMessage: '',
      formIncomplete: false,
      getTagDetailUrl: API_URL + 'get-tag-details',
      // updateTagUrl: API_URL + 'insert-update-tag-details',
      getSpeciesAPIUrl: API_URL + 'get-species?projectId=' + this.taskOverview.projectId + '&filter=',
      getSpeciesIndividualUrl: API_URL + 'get-species-individual-list-by-task',
      getSpeciesDetailsByIndividualUrl: API_URL + 'get-species-individual-by-id-uniquename',
      getTagOptionsUrl: API_URL + 'aru-tag-options?speciesId=',
      tag: {
        speciesIndividual: {
          species: {}
        }
      },
      tagOptions: {
        abundance: [],
        vocalization: []
      },
      tagOptionsError: false,
      invalidSpecies: false,
      existingIndividual: false,
      showDuplicateForm: false,
      disableDuplicateForm: false,
      duplicateSppId: -1,
      editParent: true,
      speciesIndividuals: [],
      duplicateFormError: '',
      inputVocalizationId: null,
      inputNeedsReview: false,
      orgTag: null,
      autoIdSpecies: [],
      isVocalizationDeprecated: false
    }
  },
  mounted () {
    this.loadTag();
    // if speciesPerTask = 1 OR (speciesPerMinute = 1 AND minLength = 1)
    this.disableDuplicateForm = this.taskOverview.method && (this.taskOverview.method.speciesPerTaskLimit === 1 || (this.taskOverview.method.speciesPerMinuteLimit === 1 && this.taskOverview.method.minLength === 1));
  },
  watch: {
    tagId: function (newVal, oldVal) {
      if (this.tagId !== null) {
        this.loadTag();
      }
    },
    showDuplicateForm: function (newVal, oldVal) {
      this.autoFocus();
    },
    showTagForm: function (newVal, oldVal) {
      this.autoFocus();
    },
    drawBox: function (newVal, oldVal) {
      this.autoIdSpecies = [];
    }
  },
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  methods: {
    onDupeTagSelect (target) {
      this.getTagOptions(target.options[target.selectedIndex].attributes.value2.value);
    },
    getTagOptions (specie = null) {
      let speciesId = null;

      if (typeof specie === 'object' && specie && specie.selectedObject.id) {
        speciesId = specie.selectedObject.id;
      } else {
        let code = specie;
        speciesId = (code && this.species.length > 0 && this.species.find((v, i) => v && v.code === code).id) || this.tag.speciesId;
      }

      // first reset tagOptions
      this.tagOptions = {
        abundance: [],
        vocalization: []
      };

      this.$set(this.tag.speciesIndividual, 'speciesId', speciesId);

      this.tagOptionsError = false;
      
      this.$http.get(this.getTagOptionsUrl + speciesId).then((response) => {
        if (response.body.hasOwnProperty('error')) {
          this.tagOptionsError = true;
        } else {
          this.tagOptionsError = false;
          this.tagOptions = response.body;
         
          if (this.tagId > 0) {
            if (this.tagOptions.vocalization.length > 0) {
              //if vocalizationID does not exist if changing the tagOptions. Set it to first one.
              if (!this.tagOptions.vocalization.filter((vocalObj, index)=>{
                return vocalObj.id === this.tag.vocalizationId;
              }).length) {
                this.$set(this.tag, 'vocalizationId', this.tagOptions.vocalization[0].id);
              }
            }

            if (this.tagOptions.abundance.length > 0) {
              this.tag.speciesIndividual.abundanceId = this.tagOptions.abundance[0].id;

              if (!this.tagOptions.abundance.filter((abundObj, index)=>{
                return abundObj.id === this.tag.speciesIndividual.abundanceId;
              }).length) {
                //vocalID 1 is SONG
                this.tag.speciesIndividual.abundanceId = this.tagOptions.abundance[0].id;
              }
            }

          } else { 
            if (this.tagOptions.abundance.length > 0) {
              this.tag.speciesIndividual.abundanceId = this.tagOptions.abundance[0].id;
            } else {
              alert("There were no abundances returned for this species. Please contact support");
            }
              
            if (this.tagOptions.vocalization.length > 0) {
              this.$set(this.tag, 'vocalizationId', this.tagOptions.vocalization[0].id);
            } else {
              alert("There were no vocalizations returned for this species. Please contact support");
            }
          }
        }
      });
    },
    autoFocus: function () {
      let that = this;
      this.$nextTick(() => {
        if (that.showTagForm) {
          that.$refs.duplicatedSppInput && that.$refs.duplicatedSppInput.focus();
          try {
            that.$refs.speciesInput && that.$refs.speciesInput.$el.querySelector('input').focus();
          } catch (e) {
            console.log(e);
          };
        }
      });
    },
    scrollTop: function () {
      window.scrollTo(0, this.$refs.tagform.offsetTop * 2);
    },
    async loadTag () {
      const self = this;
      this.editParent = true;
      if (this.tagId > 0) { // get current from database if id is provided.
        /* get tag form details */
        this.$http.get(this.getTagDetailUrl, {params: { tagId: this.tagId }}).then(function (response) {
          if (!response.data.hasOwnProperty('error')) {
            this.tag = response.data;
            this.orgTag = {};
            try {
              this.orgTag.uniqueName = this.tag.speciesIndividual.uniqueName;
              this.orgTag.sppCode = this.tag.speciesIndividual.species.code;
            } catch (e) {}

            const speciesDisplay = this.species[this.tag.speciesIndividual.speciesId].code + ' | ' + this.species[this.tag.speciesIndividual.speciesId].commonName;
            if (this.$refs.speciesInput) {
              this.$refs.speciesInput.display = speciesDisplay;
            }
            if (this.tag.speciesIndividualTagCount > 1) {
              this.editParent = false;
            }
            /* check if vocalization type is deprecated */
            this.isVocalizationDeprecated = this.options.vocalization.findIndex(function (element) {
              return element.deprecated === true && element.id === self.tag.vocalizationId;
            }) >= 0;
            // need to get vocalization and abundance options for this species
            this.getTagOptions();
          }
        });
        // check if there are other existing species, so in case users want to update sppCode, and unique name we can check them.
        this.$http.get(this.getSpeciesIndividualUrl, {params: {taskId: this.task.id}}).then(function (response) {
          if (!response.data.hasOwnProperty('error')) {
            this.speciesIndividuals = response.data;
          }
        });
        this.showDuplicateForm = false;
      } else { // it is a new tag to be inserted
        // for new tag box, send API to get AutoTag results
        const params = {
          params: {
            taskId: this.taskOverview.taskId,
            startX: Math.floor(this.drawBox.boxStartX),
            startY: Math.floor(this.drawBox.boxStartY),
            width: Math.floor(this.drawBox.boxWidth),
            height: Math.floor(this.drawBox.boxHeight)
          }
        };
        const that = this;
       

        // check if there are other existing species, if so hide the duplicated form
        await this.$http.get(this.getSpeciesIndividualUrl, {params: {taskId: this.task.id}}).then(function (response) {
          if (!response.data.hasOwnProperty('error')) {
            this.speciesIndividuals = response.data;
            let method = this.options.method.find(m=>m.id === this.taskOverview.methodId);
            if (this.speciesIndividuals.length > 0 && (method && method.allowDuplicates)) {
              // console.log('no previous spp exists');
              this.showDuplicateForm = true;
            } else {
              this.showDuplicateForm = false;
            }
          }
        });

        if (this.showDuplicateForm) {
          /* duplciated form only enter spp and behavior, dont't pass the rest values
            as they are the same spp,
          */
          this.tag = {
            speciesIndividual: {
              abundanceId: null,
              confidenceId: null,
              species: {}
            }
          };
        } else {
          /* set default tag values for full form enter
            CONFIDENCE 0 | Confident
            ABUNDANCE 1 | ONE
          */
          this.tag = {
            vocalizationId: 1,
            speciesIndividual: {
              abundanceId: 1,
              confidenceId: 0,
              species: {}
            }
          };
        }


        this.orgTag = this.tag;
        this.tag.isMarker = false;
        if (this.$refs.speciesInput) {
          this.$refs.speciesInput.display = '';
        }
      }
      this.tag.taskId = this.task.id;
      const that = this;
      Object.keys(that.drawBox).forEach(function (key) { if (key.startsWith('box')) { that.tag[key] = Math.floor(that.drawBox[key]); } });

      this.invalidSpecies = false;

      if (this.toScroll) {
        this.scrollTop();
      }
    },
    switchToNewForm () {
      this.showDuplicateForm = false;
      this.tag.speciesIndividual.species = {};
      this.$refs.speciesInput.clear();
      // show defaults in form
      this.tag.vocalizationId = 1;
      this.tag.speciesIndividual.abundanceId = 1;
      this.tag.speciesIndividual.confidenceId = 0;
    },
    insertUpdateTag () { // insert a tag, do validatation
      this.formIncomplete = false;
      if ((this.species[this.tag.speciesIndividual.speciesId] && this.species[this.tag.speciesIndividual.speciesId].code == null)) {
        this.invalidSpecies = true;
        return;
      }

      if (typeof this.tag.vocalizationId === 'undefined' || this.tag.speciesIndividual.abundanceId == null) {
        this.formIncomplete = true;
        return;
      }

      const newTag = {...this.tag};
      newTag.hasAIResult = (this.autoIdSpecies.length > 0);

      if (this.tag.speciesIndividual.species) { // add
        newTag.speciesIndividual.speciesId = this.tag.speciesIndividual.species.id;
        this.$set(this.species, this.tag.speciesIndividual.species.id, this.tag.speciesIndividual.species);
      } else { // edit
        newTag.speciesIndividual.speciesId = this.tag.speciesIndividual.speciesId;
        newTag.speciesIndividual.id = this.tag.speciesIndividualId;
      }
      delete newTag.speciesIndividual.id;
      eventBus.$emit('update-tag', newTag); // update tag details
    },
    removeTag () { // remove tag and hide tag form
      if (this.tagId > 0) {
        if (!confirm(this.deleteTagMsg)) {
          return;
        }
        eventBus.$emit('delete-tag', this.tagId);
      }
      this.closeTagForm();
    },

    duplicateTag () {
      // duplicates have abundance and confidence as null and no vocId
      //this.tag.vocalizationId = null;
      //this.tag.speciesIndividual.abundanceId = null;
      delete this.tag.speciesIndividual.confidenceId;
      delete this.tag.speciesIndividual.species;

      this.duplicateFormError = '';
      if (!this.duplicateSppId || this.duplicateSppId < 0) {
        this.duplicateFormError = this.$t('recordingForms-missingDuplicatedSpeciesInfo');
        return;
      }
      this.tag.speciesIndividual.id = this.duplicateSppId;
      if (this.inputVocalizationId && this.inputVocalizationId > 0) {
        this.tag.vocalizationId = this.inputVocalizationId;
      }
      if (this.inputNeedsReview) {
        this.tag.needsReview = this.inputNeedsReview;
      }
      eventBus.$emit('update-tag', this.tag);
    },
    closeTagForm () {
      eventBus.$emit('show-tag-form', false);
    },
    toggleTaskForm (show) {
      eventBus.$emit('toggle-form', show);
    },
    updateTask () {
      this.taskValidateMessage = '';
      let keys = ['otherNoiseId','industrialNoiseId','windId','rainId','audioQualityId'];
      if (keys.filter(key=>this.task[key]===null).length > 0 && keys.filter(key=>this.task[key]===null).length !== keys.length) {
        this.taskValidateMessage = this.$t('recordingForms-allFieldsRequiredExceptComments');
        return;
      }
      eventBus.$emit('update-task');
    },
    hideTaskForm () {
      eventBus.$emit('toggle-form', false);
    },
    formattedSpeciesDisplay (result) {
      return result.code + ' | ' + result.commonName + ' | ' + (result.prediction ? ' ' + Math.floor(result.prediction * 100) + '%' : '');
    },
    appendAutoSpeciesIds (response) {
      return response;
    },
    setSpeciesId (speciesRow) {
      if (speciesRow.selectedObject) {
        this.tag.speciesIndividual.species = speciesRow.selectedObject;
      } else {
        this.tag.speciesIndividual.species = speciesRow;
      }
      // console.log(this.tag.speciesIndividual.species);
      this.invalidSpecies = false;
      if (this.tag.speciesIndividual && this.species && this.species[this.tag.speciesIndividual.speciesId] && this.species[this.tag.speciesIndividual.speciesId].allowMultipleIndividuals === false) {
        this.$set(this.tag.speciesIndividual, 'uniqueName', 1);
      }
      this.getTagOptions(speciesRow);
      if (this.tag.uniqueName != null) {
        this.loadSppIndividualInfo();
      }
    },
    clearSpeciesInput () {
      this.tag.speciesIndividual.species = {};
    },
    /**
     * using spp code and unique number combination to check if same individual already exists in DB,
     * if yes, so invalid msg.
     */
    loadSppIndividualInfo () {
      this.existingIndividual = false;
      // when there is no species individuals don't call api
      if (this.speciesIndividuals.length === 0) {
        return;
      }
      let code = null;
      if (this.tag.speciesIndividual.species) {
        code = this.tag.speciesIndividual.species.code;
      } else {
        code = this.species[this.tag.speciesIndividual.speciesId].code;
      }
      
      const uniqueName = this.tag.speciesIndividual.uniqueName;
      if (code !== null && code !== '' && uniqueName != null && uniqueName !== '') {
        // search for this code combination in the speciesIndividual list
        for (let i = 0; i < this.speciesIndividuals.length; i++) {
          if (this.speciesIndividuals[i].uniqueName === uniqueName &&
          this.speciesIndividuals[i].speciesCode === code) {
            // for new tag, load tag information
            if (!this.tagId) { // new tag
              this.$http.get(this.getSpeciesDetailsByIndividualUrl, {params: {taskId: this.task.id, code: code, uniqueName: uniqueName}}).then(function (response) {
                if (!response.data.hasOwnProperty('error')) {
                  const data = response.data;
                  this.existingIndividual = true;
                  this.tag.speciesIndividual.abundanceId = data.abundanceId;
                  this.tag.speciesIndividual.confidenceId = data.confidenceId;
                  this.tag.speciesIndividual.comments = data.comments;
                }
              });
            } else { // existing tag
              if (this.orgTag.uniqueName !== uniqueName ||
                this.orgTag.sppCode !== code) {
                this.existingIndividual = true;
              }
            }
            return;
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.closeButton {
  float: right;
}
.btn.btn-action {
  background-color: #C94412;
  border: 1px solid #ad3a0f;
  color: #fff;
}
.btn.btn-outline {
  border: 1px solid #9e9e9e;
  background-color: #C94412;
}

.displayValue {
  color:#f1516d;
}

.btn:focus {
  border: 2px solid #cccccc;
}
hr.divider {
  margin:10px 0;
}
.margin0 {
  margin: 0;
}

li.autocomplete__results__item.customResult {
    background-color: #e4ab47;
    border-bottom: 1px dotted #867862;
}
select option[disabled] { color: #ccc; }

textarea:disabled{
  resize:none;
}

/* .autocomplete__inputs input:focus{
  background-color: #dee7ee;
} */
</style>
