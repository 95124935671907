var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stats != null
    ? _c(
        "div",
        { staticClass: "statWrap", class: _vm.customClass },
        [
          _vm.isWrapped
            ? _vm._l(_vm.stats, function(row, index) {
                return _c(
                  "div",
                  { key: "wrap" + index, class: _vm.customDivClass },
                  [
                    _c("div", { staticClass: "stat" }, [
                      _c("span", {
                        staticClass: "wrapText",
                        domProps: {
                          innerHTML: _vm._s(_vm.getPrefixLabel(row.label))
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(row.value))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "wrapText" }, [
                        _vm._v(_vm._s(_vm.getSuffixLabel(row.label)))
                      ])
                    ])
                  ]
                )
              })
            : _vm._l(_vm.stats, function(row, index) {
                return _c("div", { key: index, staticClass: "stat" }, [
                  _c("span", {
                    staticClass: "wrapText",
                    domProps: {
                      innerHTML: _vm._s(_vm.getPrefixLabel(row.label))
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(row.value))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "wrapText" }, [
                    _vm._v(_vm._s(_vm.getSuffixLabel(row.label)))
                  ])
                ])
              })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }