var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "600px" } },
    [
      _vm.pudId && _vm.species != null
        ? _c("report-filter", {
            ref: "filter",
            attrs: {
              options: _vm.species,
              "init-species-id": _vm.initialSpeciesId
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", staticStyle: { position: "relative" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ui.loading,
                  expression: "ui.loading"
                }
              ],
              staticClass: "overaly",
              staticStyle: {
                opacity: "0.6",
                position: "absolute",
                width: "100%",
                height: "100%",
                "z-index": "100",
                background: "white"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "250px" }
                },
                [
                  _c("pulse-loader", {
                    attrs: {
                      loading: true,
                      color: "#C94412",
                      size: "20px",
                      width: 400,
                      height: 400
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.$t("common-loadingImages")) + " ")
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          !_vm.ui.loading && (!_vm.imageList || _vm.imageList.length === 0)
            ? [_c("h5", [_vm._v(_vm._s(_vm.$t("reportGallery-noImgsFound")))])]
            : _vm._e(),
          _vm._v(" "),
          _vm.imageList && _vm.imageList.length > 0
            ? _c(
                "div",
                {
                  ref: "scrollBounds",
                  staticClass: "col s12 panel",
                  attrs: { id: "scrollBounds" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col s12",
                        class: { fullWidth: !_vm.ui.infoPanelVisible },
                        attrs: { id: "mainPanel" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "toggleWrap no-left-margin col s12 m6 l4"
                            },
                            [
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.imageQueryParam.limit,
                                        expression: "imageQueryParam.limit"
                                      }
                                    ],
                                    ref: "limit",
                                    staticClass: "record-per-page",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.imageQueryParam,
                                            "limit",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        _vm.changeNumberPerPage
                                      ]
                                    }
                                  },
                                  _vm._l(_vm.ImagePerPageOption, function(
                                    count,
                                    index
                                  ) {
                                    return _c(
                                      "option",
                                      {
                                        key: "countPerPage" + index,
                                        domProps: { value: count }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("common-perPage", {
                                              count: count
                                            })
                                          )
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.totalImageCount > 0
                                  ? _c("span", { staticClass: "total-image" }, [
                                      _vm._v(
                                        " [ " +
                                          _vm._s(
                                            _vm.$tc(
                                              "common-image",
                                              _vm.totalImageCount,
                                              { num: _vm.totalImageCount }
                                            )
                                          ) +
                                          " ] "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "toggleWrap col s12 m6 l6 right-align"
                            },
                            [
                              _vm.totalPageNum >= 0 || _vm.pagination !== null
                                ? _c("pagination", {
                                    attrs: {
                                      "active-page-number":
                                        _vm.imageQueryParam.page,
                                      pagination: _vm.pagination,
                                      "total-page-num": _vm.totalPageNum,
                                      "time-stamp": _vm.timeStamp,
                                      "status-field": _vm.paginationStatusField,
                                      "order-display": _vm.orderDisplay
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.imageList && _vm.imageList.length > 0,
                                expression: "imageList && imageList.length> 0"
                              }
                            ],
                            staticClass: "row selectable",
                            attrs: { id: "selectable-wrapper" }
                          },
                          [
                            _vm._l(_vm.imageList, function(img, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "image" + img.task.id,
                                    ref: "imagePanel",
                                    refInFor: true,
                                    staticClass: "col capture img-selectable",
                                    class: _vm.getClasses(img),
                                    attrs: { id: index, "data-item": img.id }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "imgWrap",
                                        attrs: {
                                          "data-item": img.task.id,
                                          id: index
                                        }
                                      },
                                      [
                                        img.megaDetector
                                          ? _c(
                                              "span",
                                              [
                                                _vm._l(
                                                  img.megaDetector,
                                                  function(MDResult, index) {
                                                    return [
                                                      MDResult.confidence >
                                                      _vm.ui.filter.MDConfidence
                                                        ? _c("div", {
                                                            key: index,
                                                            staticClass:
                                                              "absolute border border-red-500",
                                                            style: {
                                                              left: `${MDResult.box1 *
                                                                100}%`,
                                                              top: `${MDResult.box2 *
                                                                100}%`,
                                                              width: `${MDResult.box3 *
                                                                100}%`,
                                                              height: `${MDResult.box4 *
                                                                100}%`
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        typeof img.provided == "undefined"
                                          ? _c("img", {
                                              directives: [
                                                {
                                                  name: "lazy",
                                                  rawName: "v-lazy",
                                                  value: img.fileThumbURL,
                                                  expression: "img.fileThumbURL"
                                                }
                                              ],
                                              attrs: {
                                                id: "img" + img.task.id
                                              },
                                              on: {
                                                error: function($event) {
                                                  return _vm.imgUrlAlt(
                                                    $event,
                                                    img.fileURL
                                                  )
                                                }
                                              }
                                            })
                                          : _c("img", {
                                              staticClass: "imageNotFound"
                                            }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "imgOverlay" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "magnify" },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "ion-android-search exclude",
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.showLightbox(
                                                        img.fileURL
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vidData",
                                        staticStyle: { position: "relative" }
                                      },
                                      [
                                        _c("div", { staticClass: "number" }, [
                                          img.hasMultipleSpecies
                                            ? _c("div", {
                                                staticClass: "multipleSpecies"
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          img.hasMultipleIndividuals
                                            ? _c("div", {
                                                staticClass:
                                                  "multipleIndividual"
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          img.isOutOfRange
                                            ? _c("div", {
                                                staticClass: "outOfRange"
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(index + 1) +
                                              "\n                    "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        img.speciesTags &&
                                        img.speciesTags.length > 0
                                          ? [
                                              _vm._l(img.speciesTags, function(
                                                tag,
                                                index
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: "species" + tag.id,
                                                    staticClass: "info exclude"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "tag-details exclude",
                                                        attrs: {
                                                          name:
                                                            "species" + tag.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "link exclude tag-text break-words"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "tagSpp !cursor-default"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getTagSpp(
                                                                      tag
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getTagDisplay(
                                                                    tag
                                                                  )
                                                                ) +
                                                                "\n                              "
                                                            ),
                                                            index === 0 &&
                                                            img.task.fovId !== 0
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getFOVType(
                                                                          img
                                                                            .task
                                                                            .fovId
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        index == 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "moreInfo"
                                                              },
                                                              [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "ion-ios-information exclude",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.showImageInfoForm(
                                                                        img
                                                                      )
                                                                    }
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }),
                                              _vm._v(" "),
                                              _vm.speciesId !== null
                                                ? _c(
                                                    "div",
                                                    { staticClass: "location" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getLocation(index)
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          : [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "info link exclude"
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "tag-details"
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "moreInfo exclude"
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "ion-ios-information exclude",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.showImageInfoForm(
                                                              img
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.imageList && _vm.imageList.length > 0
                          ? _c("div", {}, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "toggleWrap col s12 m12 right-align"
                                },
                                [
                                  _vm.totalPageNum >= 0 ||
                                  _vm.pagination !== null
                                    ? _c("pagination", {
                                        attrs: {
                                          "active-page-number":
                                            _vm.imageQueryParam.page,
                                          pagination: _vm.pagination,
                                          "time-stamp": _vm.timeStamp,
                                          "total-page-num": _vm.totalPageNum,
                                          "status-field":
                                            _vm.paginationStatusField,
                                          "order-display": _vm.orderDisplay
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("lightbox", {
        ref: "lightbox",
        attrs: {
          id: "mylightbox",
          images: _vm.lightboxImages,
          "mega-detector-categories": _vm.megaDetectorCategories,
          timeoutDuration: 10000,
          "can-download": true
        }
      }),
      _vm._v(" "),
      _c("exif-info", {
        attrs: { img: _vm.currentExifImgObj, "form-name": _vm.ui.exifFormName }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }