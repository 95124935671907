<!-- simplified tabel to show project -->
<template>
<div id="project-download-table">
  <div class="col s12 panel boxed padded60 dataGroup">
    <v-server-table ref="table" :url="activeSensorUrl" :columns="columns" :options="options" class="table vue-bordered" v-if="activeSensor != null && optionLoaded">
      <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
      <template slot="checkbox" slot-scope="props">
          <label :for="props.row.id">
            <input type=checkbox :id="props.row.id" :value="props.row.id" v-model="selected"/>
            <span></span>
          </label>
      </template>
      <div slot="filter__checkbox">
          <label for="toggleAll">
            <input type="checkbox" id="toggleAll" class="form-control" v-model="selectedAll" @change="toggleAll()">
            <span></span>
          </label>
      </div>
      <div class="row" slot="child_row" slot-scope="props">
        <div class="col s12 m12 l3">
          <label>{{$t('Purpose and Methods')}}</label>
          <p>{{props.row.description||$t('Not Provided')}}</p>
        </div>
        <div class="col s12 m12 l3">
          <label>{{$t('Results Summary')}}</label>
          <p>{{props.row.results||$t('Not Provided')}}</p>
        </div>
        <div class="col s12 m6 l3 stats">
          <p><label>{{$t('projectDownload-subtable.status')}}</label> {{props.row.status}}</p>
          <p><label>{{$t('projectDownload-subtable.speciesCount')}}</label> {{props.row.speciesCount}}</p>
        </div>
        <div class="col s12 m6 l3 stats">
          <p><label>{{$t('projectDownload-subtable.totalTasks')}}</label> {{props.row.tasksCount}}</p>
          <p><label>{{$t('projectDownload-subtable.completedTasks')}}</label> {{props.row.tasksCompletedCount}}</p>
        </div>
      </div>
    </v-server-table>
  </div>
</div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
import {API_URL} from '@/lib/common';
import FormMixin from '@/components/common/FormMixin.js';

export default {
  name: 'ProjectTableSelection',
  props: ['activeSensor', 'preSelected', 'showAdminOnlyProjects'],
  mixins: [FormMixin],
  async created () {
    await this.$http.get(API_URL + 'project-options').then(
      function (response) {
        let status = [];
        response.data.status.forEach(function (e) {
          status.push({id: e.type, text: e.type});
        })
        this.$set(this.options.listColumns, 'status', status);
      });
    this.optionLoaded = true;
  },
  methods: {
    toggleAll () {
      this.selected = this.selectedAll ? this.$refs.table.data.map(row => row.id) : [];
      // this.selectedAll = !this.selectedAll; the click action will do this don't need to call here.
    }
  },
  computed: {
    activeSensorUrl () {
      // http://localhost:8080/bis/get-download-summary?sort=id&order=desc&limit=10&page=1&sensorId=ARU
      return API_URL + 'get-download-summary?sensorId=' + this.activeSensor; // + '&adminOnlyProjects=' + this.showAdminOnlyProjects;
    }
  },
  watch: {
    preSelected: function (newVal, oldVal) {
      this.selected = newVal;
    }
  },
  data () {
    const pageSize = 100;
    return {
      // url: API_URL + 'get-project-summary', // ?adminOnlyProjects=' + this.showAdminOnlyProjects,
      columns: ['checkbox', 'sensorId', 'organizationName','fullNm','tasks', 'status'],
      customFilters: ['projectType'],
      selected: this.preSelected,
      selectedAll: false,
      optionLoaded: false,
      options: {
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        filterable: ['sensorId', 'fullNm', 'organizationName', 'status'],
        sortable: ['fullNm', 'organizationName', 'tasks', 'status'],
        listColumns: {},
         texts: {
          count: this.$t('dataDiscoverDownload-tableTexts.count'),
          limit: this.$t('dataDiscoverDownload-tableTexts.limit'),
          noResults: this.$t('dataDiscoverDownload-tableTexts.noResults'),
          first: this.$t('common-tableTexts.first'),
          last: this.$t('common-tableTexts.last'),
          filter: this.$t('common-tableTexts.filter'),
          filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
          page: this.$t('common-tableTexts.page'),
          noRequest: this.$t('common-tableTexts.noRequest'),
          filterBy: this.$t('common-tableTexts.filterBy'),
          loading: this.$t('common-tableTexts.loading'),
          defaultOption: this.$t('common-tableTexts.defaultOption'),
          columns: this.$t('common-tableTexts.columns')
        },
        headings: {
          checkbox: '',
          sensorId: this.$tc('common-sensor', 1),
          fullNm: this.$t('project-tableHeadings.fullNmDownload'),
          organizationName: this.$tc('common-organization', 1),
          tasks: this.$t('project-tableHeadings.numTasks'),
          status: this.$t('common-status')
        },
        columnsClasses: {
          checkbox: 'checkbox',
          sensorId: 'sensor',
          fullNm: 'name',
          tasks: 'tasks',
          status: 'completed'
        },
        perPage: 100,
        perPageValues: [25,100,1000],
        pagination: {
          nav: 'fixed',
          edge: true},
        initFilters: {},
        orderBy: {column: 'fullNm', ascending: true},
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : '',
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
        /*  created_at(h, row) {
            return this.formatDate(row.created_at);
          },
          updated_at(h, row) {
            return this.formatDate(row.updated_at);
          },
          pushed_at(h, row) {
            return this.formatDate(row.pushed_at);
          } */
      }
    }
  }
}
</script>

<style scoped>

</style>
