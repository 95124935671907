<template>
    <div>
      <div>
        <div id="jwplayer_wrap"></div>
      </div>
      <!-- control -->
      <div class="controlWrap row">
        <div class="report-tool-items col s12 m3">
          <a id="btnPlay" data-toggle="tooltip" data-placement="bottom" :title="$t('recordingPlayers-play')" @click="play"><span :class="{'ion-play':!playMode, 'ion-pause':playMode}"></span></a>
          <a id="btnReplay" data-toggle="tooltip" data-placement="bottom" :title="$t('recordingPlayers-replay')" @click="jwp_replay" ><span :class="{'ion-refresh': !playMode}"></span></a>
          <div id="media_position">{{mediatPositonText}} <span class="error link" v-if="!canPlayAudio" @click="playAudioIssue">{{$t('common-audioProblems')}}</span></div>
        </div>
        <div class="play-area col s12 m9" ref="playBar" @click="moveProgress">
          <div class="report-player-progress" :style="'width:' + progressPixels + 'px'"></div>
        </div>
      </div>
       <v-dialog/>
    </div>
</template>

<script>
import {setJwplayer} from '@/components/utils/external-lib-wrapper';
import {API_URL, alertDialog} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';

export default {
  name: 'report-audio-gallery',
  props: ['taskId', 'uuid'],
  created () {
    let that = this;
    eventBus.$on('edit-tag', function (tagObj) {
      that.jwp_scroll_pixelX(tagObj.tagStartX);
    });
  },
  mounted () {
    let that = this;

    this.$http.get(this.getRecordingUrl + this.taskId + '&uuid=' + this.uuid).then(function (response) {
      if (response.data.hasOwnProperty('error')) {
        // alert('Failed to get Task Details');
        return;
      }
      this.recording = response.data.recording;
      this.maxAudioLength = this.recording.audioLength;
      /* if (this.recording.channels) {
        this.isStereo = (this.recording.channels !== 1);
      } */
      try {
        // this.singleImageWidth = this.recording.spectrogramWidth / this.recording.leftSpectrogramImages.length;
      } catch (e) { console.error(e); }
      // axis
      //  this.horizontalAxis = '/.resources/wildtrax-content/webresources/legend/xaxis-r80L' + 60 * this.recording.leftSpectrogramImages.length + 's.png';
      //  this.verticalAxis = '/.resources/wildtrax-content/webresources/legend/yaxis-c1r12h' + this.recording.spectrogramHeight + '.png';
      // TODO: uncomment this
      // load mp3 files, and spectrum images
      // $('#spectrogram-wrap, #spectrogram-wrap2').addClass('busy');
      const a = document.createElement('audio');
      this.canPlayAudio = !!(a.canPlayType && a.canPlayType('audio/mpeg;').replace(/no/, ''));
      try {
        that.set_media_position(0.0, that.recording.audioLength);
        // jwplayer(that.playerDiv).setup({
        //   file: that.recording.url,
        //   // testing mp4 file: 'https://www2.iis.fraunhofer.de/AAC/ChID-BLITS-EBU-Narration441AOT2.mp4',
        //   height: 24,
        //   width: 310,
        //   title: '',
        //   autostart: false,
        //   primary: 'html5',
        //   flashplayer: '/.resources/wildtrax-content/webresources/bundle/recording-page/jwplayer.flash.swf',
        //   html5player: '/.resources/wildtrax-content/webresources/bundle/recording-page/jwplayer.html5.js',
        //   repeat: false
        // });
        // that.jwPlayer = jwplayer(that.playerDiv);

        that.jwPlayer = setJwplayer(that.playerDiv, {
          file: that.recording.url,
          // testing mp4 file: 'https://www2.iis.fraunhofer.de/AAC/ChID-BLITS-EBU-Narration441AOT2.mp4',
          height: 24,
          width: 310,
          title: '',
          autostart: false,
          primary: 'html5',
          flashplayer: 'https://www.wildtrax.ca/.resources/wildtrax-content/webresources/js/jwplayer.flash.swf',
          html5player: 'https://www.wildtrax.ca/.resources/wildtrax-content/webresources/js/jwplayer.html5.js',
          repeat: false
        });
        that.jwPlayer.onSeek(function () {
          if (!that.playMode) {
          // console.log('position = ' + this.getPosition());
            that.jwp_pause();
          }
        });
        that.jwPlayer.onPlay(function () {
          if (!that.playMode) {
            that.jwp_pause();
          }
          if (that.audioPosition >= (that.maxAudioLength)) {
            that.jwp_pause();
          }
        });
        that.jwPlayer.onTime(that.jwp_cb_spectro_scroll);
        that.jwPlayer.setVolume(50);
        that.jwPlayer.onReady(function () {
          if (!that.silent && that.bAutoStart) {
            this.play(true);
          }
        });
        that.jwPlayer.onComplete(function () {
          that.playMode = false;
        });
      } catch (e) {
        console.log('error here ', e);
      }
    })
  },
  methods: {
    /* to display media position on status bar
    pos: second
    len: total length of audio
    */
    set_media_position (pos, len) {
      let playedPosition = this.seconds_to_minutes_seconds(pos.toFixed(1));
      const endPosition = this.seconds_to_minutes_seconds(len.toFixed(1));

      if (pos >= len) {
        playedPosition = endPosition;
      }
      this.mediatPositonText = this.$t('common-audioProgress', {position: playedPosition, length: endPosition})
    //  this.playerXPosition = (len === 0 ? 0 : pos / len);
    },
    seconds_to_minutes_seconds (ss) { // converting seconds to readable minutes and second text
      if (isNaN(ss)) {
        return '0.0' + this.$t('commonUnits-seconds');
      }
      if (ss < 60) {
        return ss + this.$t('commonUnits-seconds');
      }
      const m = Math.floor(ss / 60);
      const s = ss % 60;
      return s === 0 ? m + this.$t('commonUnits-minutes') : m + this.$t('commonUnits-minutes') + s.toFixed(1) + this.$t('commonUnits-seconds');
    },
    jwp_cb_spectro_scroll (e) {
      // regular play
      this.audioPosition = this.jwPlayer.getPosition();
      if (this.audioPosition > this.maxAudioLength) {
        this.jwp_pause();
        return;
      }
      this.set_media_position(e.position, this.recording.audioLength);
      const r = Math.round(e.position * this.playSpeed);
      if (r !== this.audioSeekPoint) {
        this.audioSeekPoint = r;
        // need to set div width
        if (e.position >= this.recording.audioLength) {
          this.progressPixels = this.$refs.playBar.offsetWidth;
          return;
        }
        this.progressPixels = (e.position / this.recording.audioLength) * this.$refs.playBar.offsetWidth;
      }
    },
    jwp_pause () {
      this.playMode = false;
      this.jwPlayer.pause(true);
    },
    jwp_play () {
      this.playMode = true;
      this.jwPlayer.play(true);
    },
    jwp_replay () {
      this.playMode = true;
      //  this.jwp_scroll_pixelX(0);
      this.set_media_position(0, this.recording.audioLength);
      this.jwPlayer.seek(0);
      this.audioPosition = 0;
      this.progressPixels = 0;
      this.play();
    },
    play () {
      const state = this.jwPlayer.getState();
      if (state === 'PLAYING') {
        this.jwp_pause();
        this.playMode = false;
      } else {
        this.jwp_play();
        this.playMode = true;
      }
    //  this.drawBox = {boxStartX: -1, boxStartY: -1, boxWidth: 0, boxHeight: 0}
    //  this.isDrawingBox = false;
    },
    playAudioIssue () {
      const audioText = `
        <ul>
          <li><span class="error">${this.$t('common-firefoxAudioIssue.knownIssue')}</span>
          ${this.$t('common-firefoxAudioIssue.issueMsg')}
          <div style="padding:5px 20px"><b>${this.$t('common-firefoxAudioIssue.issueHowTo')}</b>
            <br><a target=_blank href="${this.$t('common-firefoxAudioIssue.mozillaAudioVideoLink')}">${this.$t('common-firefoxAudioIssue.audioVideo')}</a>
            <br><a target=_blank href="${this.$t('common-firefoxAudioIssue.mozillaFixLink')}">${this.$t('common-firefoxAudioIssue.fixAudioVideo')}</a>
            <br><span class="warning">${this.$t('common-firefoxAudioIssue.useChrome')}</span>
          </div>
          </li>

          <li><span class="error">${this.$t('common-firefoxAudioIssue.otherIssues')}</span> ${this.$t('common-firefoxAudioIssue.pleaseContact', {name: `<a href="mailto:${this.$t('common-wildtraxEmail')}">${this.$t('common-firefoxAudioIssue.wildtraxTeam')}</a>`})} </li>
        </ul>
      `;
      alertDialog(this.$modal, this.$t('common-audioProblems'), audioText, 600);
    },
    moveProgress (event) {
      // jump to a time when playbar clicked
      let playerRect = this.$refs.playBar.getBoundingClientRect();
      let offset = playerRect.left + window.scrollX;
      let newProg = event.pageX - offset;
      let jwPoint = newProg * this.maxAudioLength / this.$refs.playBar.offsetWidth;
      this.progressPixels = newProg;
      this.jwPlayer.seek(jwPoint);
      this.audioPosition = jwPoint;
      this.set_media_position(jwPoint, this.recording.audioLength);
    },
    jwp_scroll_pixelX (x) {
      // jump to a certain time when a specific call was clicked in the log
      // if (this.jwPlayer.getState() === 'PLAYING') return;
      const second = x / this.recording.spectrogramWidth * this.recording.audioLength;
      this.progressPixels = (second * this.$refs.playBar.offsetWidth) / this.maxAudioLength;
      this.set_media_position(second, this.recording.audioLength);
      this.audioPosition = second;
      this.jwPlayer.seek(second);
      // this.play();
    }
  },
  data () {
    return {
      getRecordingUrl: API_URL + 'get-task-recording-no-auth?taskId=',
      jwPlayer: null,
      playerDiv: 'jwplayer_wrap',
      playMode: false,
      mediatPositonText: '',
      canPlayAudio: null,
      maxAudioLength: 0, // for testing audio trim, same as the audio length returned by API
      silent: false,
      bAutoStart: false,
      audioPosition: 0,
      playSpeed: 80.0,
      audioSeekPoint: 0,
      progressPixels: 0
    }
  }
}

</script>

<style scoped>

.controlWrap .report-tool-items{
  display: inline-block;
  padding: 0px !important;
}

.controlWrap .report-tool-items #btnPlay {
    height: 64px;
    line-height: 64px;
    width: 64px;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    background-color: #7DA484;
    float: left;
    color: #fff;
}
.controlWrap .report-tool-items #btnReplay {
    height: 64px;
    line-height: 64px;
    width: 64px;
    font-size: 1.5em;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    color: #7d7d7d;
}

.controlWrap .report-tool-items div#media_position {
    display: inline-block;
    font-size: .8em;
}
/*
#media_position{
  padding-right: 15px;
}*/

.play-area{
  height: 64px;
  padding: 0px !important;
}

.report-player-progress{
  background-color: #7DA484;
  height: 100%;
}

</style>
