var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue" }, [
    _c(
      "div",
      { staticClass: "container overflow" },
      [
        _vm.errorMessage
          ? _c("div", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.errorMessage))
            ])
          : [
              _vm.projectName || _vm.organizationName
                ? _c("div", { staticClass: "row grant-access-wrapper boxed" }, [
                    _c("div", { staticClass: "col s3" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col s6 center" },
                      [
                        !_vm.userAlreadyPermitted
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "span",
                                  [
                                    _vm.projectId
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "grantAccess-requestingProject",
                                                {
                                                  userName: _vm.userName,
                                                  projectName: _vm.projectName
                                                }
                                              )
                                            )
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "grantAccess-requestingOrganization",
                                                {
                                                  userName: _vm.userName,
                                                  organizationName:
                                                    _vm.organizationName
                                                }
                                              )
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              !_vm.decided
                                ? [
                                    _c("div", { staticClass: "row" }, [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("grantAccess-includeComment")
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.comment,
                                            expression: "comment"
                                          }
                                        ],
                                        domProps: { value: _vm.comment },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.comment = $event.target.value
                                          }
                                        }
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "row btn-wrapper" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-success",
                                            on: {
                                              click: function($event) {
                                                return _vm.doAccept(true)
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticStyle: {
                                                "font-size": "20px"
                                              }
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("grantAccess-approve")
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-border",
                                            on: {
                                              click: function($event) {
                                                return _vm.doAccept(false)
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticStyle: {
                                                "font-size": "20px"
                                              }
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("grantAccess-reject")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                : _c(
                                    "div",
                                    [
                                      !_vm.message
                                        ? _c("pulse-loader", {
                                            attrs: {
                                              loading: true,
                                              color: "#C94412",
                                              size: "20px",
                                              width: 400,
                                              height: 400
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "message" }, [
                                        _vm._v(_vm._s(_vm.message))
                                      ])
                                    ],
                                    1
                                  )
                            ]
                          : [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "span",
                                  [
                                    _vm.projectId
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "grantAccess-alreadyHasAccessProject",
                                                {
                                                  userName: _vm.userName,
                                                  projectName: _vm.projectName
                                                }
                                              )
                                            )
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "grantAccess-alreadyHasAccessOrganization",
                                                {
                                                  userName: _vm.userName,
                                                  organizationName:
                                                    _vm.organizationName
                                                }
                                              )
                                            )
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ])
                            ]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s3" })
                  ])
                : _vm._e()
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }