<template>
<div class="trackingTable">
  <report-aru-player v-if="uuid" v-bind:taskId="taskId" v-bind:uuid="uuid"></report-aru-player>
  <div class="row">
    <h4 v-if="uuid">{{ $t('recordingLog-loggedSounds') }}</h4>
    <div v-if="!uuid" class="col s6 m6 l5 xl4">
      <div v-if="taggers != null && taggers.length > 1 && isAdminView">
        <select v-model="selectedUserTaskId" >
          <option v-for="(user, index) in taggers" v-bind:key="'tag' + index" :value="user.taskId"> {{user.userName}}</option>
        </select>
      </div>
    </div>
    <div v-if="!uuid" class="col s4 l2 xl4">
    </div>
    <div class="col s6 m6 l5 xl4">
      <table class="legendTable" :class="{'right':!uuid}">
        <tr>
          <td v-tooltip="$t(`vocalization-legend-tooltips.${row.id}`)" v-for="(row, index) in vocalizationOpt" v-bind:key="'vocalization' + index" :class="'min vt-'+ row.id">
          <span class="legend"> {{row.type}}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
<v-server-table 
 v-if="(taskId != null && taggers && selectedUserTaskId != null && bTableConfigLoaded) || (taskId != null && uuid && bTableConfigLoaded)"
 ref="table" :url="tableUrl" 
 :columns="columns" 
 :options="options"
 :class="'table-min'+ nMinute" 
 class="table vue-bordered " 
 @loaded="setSpeciesInfo()">
  <template slot="min0" slot-scope="props" v-if="props.row.min0 != null">
   
    <div @click="editTag(props.row.min0)" :class="'min vt-'+ props.row.min0.vocalizationId" >
      <span>{{ props.row.min0.seconds}}</span>
    </div>
  </template>
  <template slot="min1" slot-scope="props" v-if="props.row.min1 != null">
    <div @click="editTag(props.row.min1)" :class="'min vt-'+ props.row.min1.vocalizationId" >
      <span>{{ props.row.min1.seconds}}</span>
    </div>
  </template>
  <template slot="min2" slot-scope="props" v-if="props.row.min2 != null">
    <div @click="editTag(props.row.min2)" :class="'min vt-'+ props.row.min2.vocalizationId">
      <span>{{ props.row.min2.seconds}}</span>
    </div>
  </template>
  <template slot="min3" slot-scope="props" v-if="props.row.min3 != null">
    <div @click="editTag(props.row.min3)" :class="'min vt-'+ props.row.min3.vocalizationId" >
      <span>{{ props.row.min3.seconds}}</span>
    </div>
  </template>
  <template slot="min4" slot-scope="props" v-if="props.row.min4 != null">
    <div @click="editTag(props.row.min4)" :class="'min vt-'+ props.row.min4.vocalizationId">
      <span>{{ props.row.min4.seconds}}</span>
    </div>
  </template>
  <template slot="min5" slot-scope="props" v-if="props.row.min5 != null">
    <div @click="editTag(props.row.min5)" :class="'min vt-'+ props.row.min5.vocalizationId">
      <span>{{ props.row.min5.seconds}}</span>
    </div>
  </template>
  <template slot="min6" slot-scope="props" v-if="props.row.min6 != null">
    <div @click="editTag(props.row.min6)" :class="'min vt-'+ props.row.min6.vocalizationId">
      <span>{{ props.row.min6.seconds}}</span>
    </div>
  </template>
  <template slot="min7" slot-scope="props" v-if="props.row.min7 != null">
    <div @click="editTag(props.row.min7)" :class="'min vt-'+ props.row.min7.vocalizationId">
      <span>{{ props.row.min7.seconds}}</span>
    </div>
  </template>
  <template slot="min8" slot-scope="props" v-if="props.row.min8 != null">
    <div @click="editTag(props.row.min8)" :class="'min vt-'+ props.row.min8.vocalizationId">
      <span>{{ props.row.min8.seconds}}</span>
    </div>
  </template>
  <template slot="min9" slot-scope="props" v-if="props.row.min9 != null">
    <div @click="editTag(props.row.min9)" :class="'min vt-'+ props.row.min9.vocalizationId">
      <span>{{ props.row.min9.seconds}}</span>
    </div>
  </template>
  <!-- <template slot="firstTagId" slot-scope="props">
    <span @click="editParentTag(props.row)" class="ion-compose"></span>
  </template> -->
  <div class="row" slot="child_row" slot-scope="props">
    <div class="col s12 m12 l6 offset-l2 offset-m1">
        <img class="round-img" :src="speciesMap.get(props.row.speciesCode).icon" @error="imgUrlAlt($event, speciesMap.get(props.row.speciesCode).icon)">
          <div class="text-wrapper">
            <div>{{ capitalize($tc('common-code', 1 ))}}: {{props.row.speciesCode}}</div>
            <div class="english-name">{{speciesMap.get(props.row.speciesCode).commonName}}</div>
            <div class="italic">{{speciesMap.get(props.row.speciesCode).scientificName.charAt(0).toUpperCase() + speciesMap.get(props.row.speciesCode).scientificName.slice(1)}}</div>
          </div>
    </div>
    <div class="col s12 m6 l3 stats">
      <p>{{props.row.comments}}</p>
    </div>
  </div>
</v-server-table>
</div>
</template>

<script>

import { eventBus } from '@/lib/eventbus';
import {API_URL, capitalizeString} from '@/lib/common';
import ReportAruPlayerVue from '../landowner-reports/ReportAruPlayer.vue';
export default {
  name: 'log-table',
  components: {'report-aru-player': ReportAruPlayerVue},
  props: ['taskId', 'vocalizationOpt', 'taggers', 'isAdminView', 'uuid', 'species'], // uuid from landowner report page
  methods: {
    editTag (tag) {
      if (tag.tagId != null && tag.tagId > 0) {
        eventBus.$emit('edit-tag', tag);
      }
    },
    updateUser () {
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
    },
    getTableUrl: function () {
      if (this.uuid) {
        return API_URL + 'get-task-logged-sounds-no-auth?taskId=' + this.taskId + '&uuid=' + this.uuid;
      }
      return API_URL + 'get-task-logged-sounds?taskId=' + (this.selectedUserTaskId ? this.selectedUserTaskId : this.taskId);
    },
    getTableConfig () { 
      
      // query data to get table header
      // let self = this;
      this.$http.get(this.getTableUrl()).then(function (response) {
        if (response.data.template) { // get table header information
          this.nMinute = response.data.template.minutes;
          this.optionalColumns = response.data.template.optionalColumns;
          for (let i = 0; i < this.nMinute; i++) {
            this.columns.splice(2 + i, 0, 'min' + i); // add mintues
          }
          for (let i = 0; i < this.optionalColumns.length; i++) {
            this.columns.splice(2 + this.nMinute + i, 0, this.optionalColumns[i]); // add optional columns
            this.options.headings[this.optionalColumns[i]] = this.optionalColumns[i]; // add header text
            this.options.columnsClasses[this.optionalColumns[i]] = 'optional content'; // set column css style
          }
          this.bTableConfigLoaded = true;
        }
      });
    },
    setSpeciesInfo () {
      this.$refs.table.data.forEach(async item => {
        if (!this.speciesMap.has(item.speciesId)) {

          

              let tmp = this.species[item.speciesId] || {scientificName: 'UNKNONW'};
               // tmp.scientificName = tmp.scientificName.toLowerCase();
                tmp.icon = `https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${tmp.scientificName}`;
                this.speciesMap.set(item.speciesCode, tmp);

          // await this.$http.get(this.getSpeciesDetailsUrl + item.speciesId).then(
          //   (response) => {
          //     if (response.data.hasOwnProperty('error')) {
          //       this.error = response.data.error;
          //     } else {
          //       let tmp = response.body;
          //       tmp.scientificName = tmp.scientificName.toLowerCase();
          //       tmp.icon = 'https://abmi.ca/FileDownloadServlet?dir=WEB_GRAPH&filename=/profiles/' + tmp.genus + '-' + tmp.name + '-small.jpg';
          //       this.speciesMap.set(item.speciesCode, tmp);
          //     }
          //   }, (err) => {
          //     // error callback
          //     this.error = err;
          //   });
        }
      })
    },
    /* when image load error use place holder image */
    imgUrlAlt (event, url) {
      const placeHolderImageUrl = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder-icon.png';
      if (placeHolderImageUrl !== event.target.src) {
        event.target.src = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder-icon.png';
      }
    },
    capitalize (str) {
      return capitalizeString(str);
    }
  },
  mounted () {
    this.speciesMap = new Map();
    this.getTableConfig();
    const self = this;
    eventBus.$on('reload-log-table', function (tag) {
      if (self.$refs.table) {
        self.$refs.table.refresh();
      }
    });
    this.selectedUserTaskId = this.taskId;
  },
  computed: {
    tableUrl: function () {
      return this.getTableUrl();
    }
  },
  watch: {
    isAdminView: function (newVal, oldVal) {
      if (!newVal && this.selectedUserTaskId !== this.taskId) { // transcribe view
        this.selectedUserTaskId = this.taskId; // default using current passed in taskId
      }
    } 
  },
  data () {
    const pageSize = 25;
    return {
      getSpeciesDetailsUrl: API_URL + 'get-species-details?speciesId=',
      // columns: ['speciesCode', 'uniqueName', 'min0', 'min1', 'min2', 'min3', 'min4', 'min5', 'min6', 'min7', 'min8', 'min9', 'abundanceType', 'confidenceType', 'firstTagId'],
      columns: ['speciesCode', 'uniqueName', 'abundanceType'],
      selectedUserTaskId: this.taskId,
      speciesMap: null, // (code, {other info})
      nMinute: 9,
      optionalColumns: [],
      bTableConfigLoaded: false,
      options: {
        childRowTogglerFirst: false,
        filterable: false,
        sortable: ['speciesCode', 'uniqueName', 'abundanceType'],
        headings: {
          speciesCode: 'Code',
          uniqueName: 'INDIV_ID',
          min0: this.$t('recordingLog-headings.0min'),
          min1: this.$t('recordingLog-headings.1min'),
          min2: this.$t('recordingLog-headings.2min'),
          min3: this.$t('recordingLog-headings.3min'),
          min4: this.$t('recordingLog-headings.4min'),
          min5: this.$t('recordingLog-headings.5min'),
          min6: this.$t('recordingLog-headings.6min'),
          min7: this.$t('recordingLog-headings.7min'),
          min8: this.$t('recordingLog-headings.8min'),
          min9: this.$t('recordingLog-headings.9min'),
          abundanceType: this.$t('recordingLog-headings.AC'),
        },
        columnsClasses: {
          speciesCode: 'species',
          uniqueName: 'individual',
          min0: 'time',
          min1: 'time',
          min2: 'time',
          min3: 'time',
          min4: 'time',
          min5: 'time',
          min6: 'time',
          min7: 'time',
          min8: 'time',
          min9: 'time',
          abundanceType: 'ac content'
        },
        perPage: 50,
        perPageValues: [],
        pagination: { nav: 'scroll' },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: '*' + query[key] + '*'});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : 'speciesCode',
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.trackingTable div.min, .trackingTable div.min span{
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.trackingTable table {
    table-layout: fixed;
}

.trackingTable table td {
    text-align: center;
}
.trackingTable td>div, .trackingTable table td.content {
  padding: 15px 5px;
}
.trackingTable table td.time, .trackingTable th {
  padding: 0px 0px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
}

/* colors */
/* original */

$v1: #b9cebd;
$v2: #ef7d52;
$v3: #6d9dd2;
$v4: #eac070;
$v5: #dee4a0;
$v6: #ec7f90;
$v7: #c8a4f0;
$v8: #f3b5f3;

/* my attempt at fixing the old one */
/*$v1: #ABC4AF;
$v2: #EF7D52;
$v3: #26507D;
$v4: #F2D7A6;
$v5: #309C62;
$v6: #D5203B;
*/
/* better viridis: */
/*$v1: #FBE726;
$v2: #B0DD2E;
$v3: #20928C;
$v4: #5BC862;
$v5: #453781;
$v6: #450E57;
*/

/* alex 1 */
/*
$v1: #F2D7A6;
$v2: #C3D5C6;
$v3: #F3C6B4;
$v4: #73D4A0;
$v5: #B7461F;
$v6: #73A2D4;
*/
/* alex 2 */
/*
$v1: #C7C7BB;
$v2: #D8D843;
$v3: #33320A;
$v4: #A0A036;
$v5: #151505;
$v6: #2F2F2F;
*/
.trackingTable table .vt-1:hover {
    background-color: #d7e3d9;
    color:#7c7c7c;
}
.trackingTable table .vt-2 {
    background-color: $v2;
    color: $v2;
}

.trackingTable table .vt-2:hover {
    background-color: #fce6de;
    color:#7c7c7c;
}
.trackingTable table .vt-1 {
    background-color: $v1;
    color: $v1;
}

.trackingTable table .vt-2:hover {
    background-color: #fce6de;
    color:#7c7c7c;
}
.trackingTable table .vt-3 {
    background-color: $v3;
    color: $v3;
}

.trackingTable table .vt-3:hover {
    background-color: #6d9dd2;
    color:#7c7c7c;
}
.trackingTable table .vt-4 {
    background-color: $v4;
    color: $v4;
}

.trackingTable table .vt-4:hover {
    background-color: #efe0c5;
    color:#7c7c7c;
}
.trackingTable table .vt-5 {
    background-color: $v5;
    color: $v5;
}

.trackingTable table .vt-5:hover {
    background-color: #dee4a0;
    color:#7c7c7c;
}
.trackingTable table .vt-6 {
    background-color: $v6;
    color: $v6;
}
.trackingTable table .vt-6:hover {
    background-color: #f3ccd2;
    color:#7c7c7c;
}
.trackingTable table .vt-7 {
    background-color: $v7;
    color: $v7;
}
.trackingTable table .vt-7:hover {
    background-color: #E8D3FF;
    color:#7c7c7c;
}
.trackingTable table .vt-8 {
    background-color: $v8;
    color: $v8;
}
.trackingTable table .vt-8:hover {
    background-color: #F4D9F4;
    color:#7c7c7c;
}
.trackingTable table td span.legend {
  color: #333;
}

.trackingTable table.legendTable {
  width: 500px;
/*  float: right;*/
  margin-bottom: 20px;
}
.trackingTable  table.vue-bordered.trackingTable .time {
    line-height: normal;
}

.trackingTable  .table-min1.trackingTable .time {
  width: 100px;
}

.trackingTable  .table-min3.trackingTable .time {
  width: 75px;
}

.trackingTable .table-min9.trackingTable .time {
  width: 75px;
}

.trackingTable .table-min1.trackingTable th.optional.content,
.trackingTable .table-min1.trackingTable td.optional.content {
  width: 250px;
}

.trackingTable .table-min3.trackingTable th.optional.content {
  width: 200px;
}

.trackingTable .table-min9.trackingTable th.optional.content {
  width: 200px;
}
.trackingTable td {
    border-bottom: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    padding: 9px 10px;
    font-size: .8em;
}
.trackingTable .table-min1.trackingTable th.species {
    width: 125x;
}
.trackingTable .table-min1.trackingTable th.individual {
    width: 125px;
}

.trackingTable .table-min3.trackingTable th.species {
    width: 100px;
}
.trackingTable .table-min3.trackingTable th.individual {
    width: 100px;
}

.trackingTable .table-min9.trackingTable th.species {
    width: 59px;
}
.trackingTable .table-min9.trackingTable th.individual {
    width: 79px;
}

.trackingTable .glyphicon-chevron-up:before {
    content: "\f10d";
    display: inline-block;
    font-family: "Ionicons";
    color: #C94412;
    padding: 0px 10px;
  }

  .trackingTable .glyphicon-chevron-down:before {
    content: "\f104";
    display: inline-block;
    font-family: "Ionicons";
    color: #C94412;
    padding: 0px 10px;
  }

  .trackingTable .VuePagination nav {
    background-color:#fff;
    color: #444;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0)
  }
  .trackingTable .pagination {
    display: inline-block;
  }
  .trackingTable .pagination li {
    display: inline-block;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
    height: 30px; }
  .trackingTable .pagination li a {
    color: #444;
    display: inline-block;
    font-size: 1.2rem;
    padding: 0 10px;
    line-height: 30px; }
  .trackingTable .pagination li.active a {
    color: #fff; }
  .trackingTable .pagination li.active {
    background-color: #7DA484;
    color: white;
  }
  .trackingTable .pagination li.disabled a {
    cursor: default;
    color: #999; }
  .trackingTable .pagination li i {
    font-size: 2rem; }

.trackingTable .pagination li.pages ul li {
  display: inline-block;
  float: none; }

@media only screen and (max-width: 992px) {
  .trackingTable .pagination {
    width: 100%; }
    .trackingTable .pagination li.VuePagination__pagination-item-prev-page,
    .trackingTable .pagination li.VuePagination__pagination-item-next-page {
      width: 10%; }
    .trackingTable .pagination li.pages {
      width: 80%;
      overflow: hidden;
      white-space: nowrap; }
}

  .trackingTable table.table-bordered tr td .tableToggle {
    width: auto;
    border: 1px solid #d9d9d9;
    display: inline-block;
    padding: 3px 10px;
    float: right;
    color: #C94412; }

  .trackingTable table.table-bordered tr td .VueTables__child-row-toggler--closed::before {
    content: "\f104";
    display: inline-block;
    font-family: "Ionicons";
    color: #C94412;
    border: 1px solid #d9d9d9;
    padding: 5px 10px;
  }
  .trackingTable table.table-bordered tr td .VueTables__child-row-toggler--open::before {
    content:"\f10d";
    display: inline-block;
    font-family: "Ionicons";
    color: #C94412;
    border: 1px solid #d9d9d9;
    padding: 5px 10px;
  }

  .trackingTable .round-img{
    border-radius: 50%;
    width: 85px;
    height: 85px;
    float: left;
    margin-right: 10px;
  }

  .trackingTable .text-wrapper{
    text-align:left;
    padding-top: 21px;
  }
</style>
