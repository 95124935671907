<template>
    <div id="org-csv-upload">
        <div @click="closeForm" class="iconButton link">
            <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
            <h6>
              <template v-if="type === 'location'">{{$t('orgCSVUpload-locationTitle', {organizationName: organizationName})}}</template>
              <template v-if="type === 'project-location'">{{$t('orgCSVUpload-locationTitle', {organizationName: project.fullNm})}}</template>
              <template v-if="type === 'equipment'">{{$t('orgCSVUpload-equipmentTitle', {organizationName: organizationName})}}</template>
              <template v-if="type === 'visits'">{{$t('orgCSVUpload-visitTitle', {organizationName: organizationName})}}</template>
              </h6>
        </div>
        <div class="modal-content container csvUpload" >
          <div class="flex-row">
            <span @click="showSettings = !showSettings" class="link" :class="{'ion-arrow-expand': !showSettings, 'ion-arrow-shrink': showSettings}">
              {{showSettings ? $t('common-hide') : $t('common-show')}} {{$t('common-fileUpload')}}
            </span>
          </div>

          <div v-show="showSettings" id="upload-box">
            <div class="padded20 center">
              <template v-if="type === 'location'">{{$t('orgCSVUpload-locationInstructions', {organizationName: organizationName})}}</template>
              <template v-if="type === 'project-location'">{{$t('csvUpload-subHeaderLocations',{projectName: project.fullNm})}}</template>
              <template v-if="type === 'equipment'">{{$t('orgCSVUpload-equipmentInstructions', {organizationName: organizationName})}}</template>
              <template v-if="type === 'visits'">{{$t('orgCSVUpload-visitInstructions', {organizationName: organizationName})}}</template>
            </div>
            <div class="row">
              <div class="col s1 m3 xl4"></div>
              <!-- for Better UI, hide the actual folder selection tool, and use a custom button instead -->
              <div class="col s5 m5 xl3">
                <input type="file" ref="chooseFile" id="chooseFile" name="equipCSV"
                    accept=".csv"
                    class="form-control hidden file-chooser"
                    @change="chooseFile"
                    >
                <button type="button" style="width: 100%" class="btn btn-default" id="browseButton"  @click="clickChooseFile()" >
                  <span class="glyphicon glyphicon-refresh"></span> <span class="info-icon ion-information-circled" v-tooltip="tooltips.csvUpload"></span> {{$t('common-csvUpload')}}
                </button>
              </div>
              <div class="baseline-wrapper col s3 m2 xl3">
                <span class="baseliner" v-if="selectedFile">{{selectedFile.name}}</span>
              </div>
              <div class="col s1 m2 xl2"></div>
            </div>
            <div class="row">
              <div class="col s1 m3 xl4"></div>
              <div class="col s5 m3 xl3">
                <button type="button" class="btn btn-border btn-success" id="submitButton" :disabled="selectedFile == null" @click="previewFile">
                  {{$t('orgCSVUpload-previewChanges')}}
                </button>
              </div>
              <div class="col s6 m6 xl5"></div>
            </div>
          </div>

          <div v-if="message" class="error">{{message}}</div>
          <pulse-loader v-if="previewLoading" :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="preview-pulse"></pulse-loader>
          <template v-if="showPreview && !message">
            <div v-if="tableData.length === 0 && !finishedUpload && !previewLoading && !uploading">{{$t('orgCSVUpload-noChanges')}}</div>
            <div v-else id="csv-upload" class="orange-border padded40 bottom-margin">
              <v-client-table v-if="tableData.length > 0 && !uploading && !finishedUpload" :data="tableData" :columns="columns" :options="tableOptions">
                  <template slot="icon" slot-scope="props">
                    <!-- for visits -->
                    <span class="info-icon" :class="{'ion-android-warning':props.row.isError === true, 'ion-information-circled':props.row.isError === false}"></span>
                  </template>
                <div slot="action" slot-scope="props">
                  <span class="info-icon" :class="{'ion-android-warning':props.row.action === -1, 'ion-information-circled':props.row.action !== -1}" v-tooltip="props.row.reasoning"></span> {{uploadOptions.get(props.row.action).type}}
                </div>
                <div slot="visibilityId" slot-scope="props">
                  <template v-for="option in visibilityOptions">
                  <template v-if="props.row.visibilityId === option.id">{{option.type}}</template>
                  </template>
                </div>
              </v-client-table>
              <template v-if="uploading">
                <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="preview-pulse"></pulse-loader>
                <div v-if="type === 'visits'" class="progress-text">{{$t('common-uploading').toLowerCase()}}...</div>
                <div v-else class="progress-text">{{$t('orgCSVUpload-updatedProgress', {num: numSuccess, total: totalRows})}}</div>
              </template>
              <!-- table for upload errors vvv -->
              <v-client-table v-if="type !== 'visits' && uploadErrors.length > 0 && finishedUpload" :data="uploadErrors" :columns="columns" :options="tableOptions">
                <div slot="action" slot-scope="props">
                  <span class="info-icon ion-android-warning error" v-tooltip="props.row.error"></span> {{$t('orgCSVUpload-errorUploading')}}
                </div>
                <div slot="visibilityId" slot-scope="props">
                  <template v-for="option in visibilityOptions">
                  <template v-if="props.row.visibilityId === option.id">{{option.type}}</template>
                  </template>
                </div>
              </v-client-table>
              <div class="row no-bottom-margin">
                <div class="finishMsg">
                  <span v-if="finishedUpload && !entireFileUploadStatus">{{$t('orgCSVUpload-numUpdated', {num: numSuccess})}}</span>
                  <span v-if=" finishedUpload && !entireFileUploadStatus && uploadErrors.length > 0" class="error">{{$tc('common-numErrors', uploadErrors.length, {num: uploadErrors.length})}}</span>
                  <!-- for visits only -->
                  <div v-if="finishedUpload && entireFileUploadStatus != null" :class="{error: entireFileUploadStatus.success === false}">{{entireFileUploadStatus.message}}</div>
                </div>
                <template v-if="tableData.length > 0">
                  <div v-if="hasPreviewErrors">{{$t('orgCSVUpload-hasErrors')}}</div>
                <div v-if="!finishedUpload" class="col">
                  <button type="button" :disabled="hasPreviewErrors || uploadErrors.length > 0 || uploading" class="btn btn-border btn-success" id="uploadButton" @click="uploadFile">
                    {{$t('orgCSVUpload-applyChanges')}}
                  </button>
                </div>
                <div class="col">
                  <button v-if="!uploading && !finishedUpload" type="button" class="btn btn-border btn-success" id="cancelButton" @click="cancelUpload">
                    {{$t('common-cancel')}}
                  </button>
                  <button v-if="finishedUpload" type="button" class="btn btn-border btn-success" @click="closeForm">
                    {{$t('common-close')}}
                  </button>
                </div>
                </template>
              </div>
            </div>
          </template>
        </div>
    </div>
</template>

<script>

import { API_URL } from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';

/*
This component displays a form for the user to upload their csv file at the organization level. For task level, see TaskTableUploadCSVForm
*/

export default {
  props: ['organizationId', 'organizationName', 'visibilityOptions', 'type', 'projectId', 'project'],
  components: {'pulse-loader': PulseLoader},
  mixins: [FormMixin],
  created () {
    this.$http.get(this.codeOptionsUrl).then(
      function (response) {
        this.uploadOptions = new Map();
        response.data.syncCodes.forEach(item => {
          this.uploadOptions.set(item.id, {type: item.type, isError: item.isError});
        });
      });

    switch (this.type) {
      case ('equipment'): {
        this.previewUrl = API_URL + 'upload-equipment-csv-for-sync?orgId=';
        this.tooltips.csvUpload = this.$t('orgCSVUpload-tooltips.uploadYourLocationCSV');
        this.updateUrl = API_URL + 'insert-update-delete-equipment';
        this.columns = ['action', 'code', 'serialNumber', 'purchaseDate', 'statusStr', 'typeStr', 'model', 'make'];
        this.tableOptions.sortable = ['action', 'code', 'serialNumber', 'purchaseDate', 'statusStr', 'typeStr', 'model', 'make'];
        this.tableOptions.headings = {
          action: this.$t('orgCSVUpload-headings.action'),
          code: this.$t('common-code'),
          serialNumber: this.$t('common-serialNumber'),
          purchaseDate: this.$t('orgCSVUpload-headings.purchaseDate'),
          statusStr: this.$t('common-status'),
          typeStr: this.$t('orgCSVUpload-headings.type'),
          model: this.$t('common-model'),
          make: this.$t('common-make')
        };
        this.tableOptions.orderBy = {column: 'make', ascending: true};
        break;
      }
      case ('location'): {
        this.previewUrl = API_URL + 'upload-location-csv-for-sync?orgId=';
        this.tooltips.csvUpload = this.$t('orgCSVUpload-tooltips.uploadYourLocationCSV');
        this.updateUrl = API_URL + 'insert-update-location';
        this.columns = ['action', 'location', 'latitude', 'longitude', 'elevationMeters', 'bufferRadius', 'visibilityId', 'isTrueCoordinates'];
        this.tableOptions.sortable = ['action', 'location', 'latitude', 'longitude', 'elevationMeters', 'bufferRadius', 'visibilityId', 'isTrueCoordinates'];
        this.tableOptions.headings = {
          action: this.$t('orgCSVUpload-headings.action'),
          location: this.$t('orgCSVUpload-headings.location'),
          latitude: this.$t('common-latitude'),
          longitude: this.$t('common-longitude'),
          elevationMeters: this.$t('orgCSVUpload-headings.elevationMeters'),
          bufferRadius: this.$t('orgCSVUpload-headings.bufferRadius'),
          visibilityId: this.$t('orgCSVUpload-headings.visibilityId'),
          isTrueCoordinates: this.$t('orgCSVUpload-headings.isTrueCoordinates')
        };
        this.tableOptions.orderBy = {column: 'make', ascending: true};
        break;
      }
      case ('project-location'): {
        this.previewUrl = API_URL + 'upload-location-csv-for-sync?projectId=';
        this.tooltips.csvUpload = this.$t('orgCSVUpload-tooltips.uploadYourLocationCSV');
        this.updateUrl = API_URL + 'insert-update-location';
        this.columns = ['action', 'location', 'latitude', 'longitude', 'elevationMeters', 'bufferRadius', 'visibilityId', 'isTrueCoordinates'];
        this.tableOptions.sortable = ['action', 'location', 'latitude', 'longitude', 'elevationMeters', 'bufferRadius', 'visibilityId', 'isTrueCoordinates'];
        this.tableOptions.headings = {
          action: this.$t('orgCSVUpload-headings.action'),
          location: this.$t('orgCSVUpload-headings.location'),
          latitude: this.$t('common-latitude'),
          longitude: this.$t('common-longitude'),
          elevationMeters: this.$t('orgCSVUpload-headings.elevationMeters'),
          bufferRadius: this.$t('orgCSVUpload-headings.bufferRadius'),
          visibilityId: this.$t('orgCSVUpload-headings.visibilityId'),
          isTrueCoordinates: this.$t('orgCSVUpload-headings.isTrueCoordinates')
        };
        this.tableOptions.orderBy = {column: 'make', ascending: true};
        break;
      }
      /* case ('point-counts'): {
        this.previewUrl = API_URL + 'upload-point-count-csv-for-sync?projectId=' + this.projectId + '&previewOnly=true';
        this.tooltips.csvUpload = 'Upload your point-counts CSV file';
        this.updateUrl = API_URL + 'upload-point-count-csv-for-sync?projectId=' + this.projectId + '&previewOnly=false';
        this.columns = ['icon', 'row', 'error'];
        this.tableOptions.sortable = ['row'];
        this.tableOptions.headings = {
          icon: '',
          row: 'Row',
          error: 'Error Message'
        };
        this.tableOptions.orderBy = {column: 'row', ascending: true};
        break;
      } */
      case ('visits'): {
        this.previewUrl = API_URL + 'upload-location-visits-csv-for-sync?orgId=' + this.organizationId + '&previewOnly=true';
        this.tooltips.csvUpload = this.$t('orgCSVUpload-tooltips.uploadYourLocationCSV');
        this.updateUrl = API_URL + 'upload-location-visits-csv-for-sync?orgId=' + this.organizationId + '&previewOnly=false';
        this.columns = ['icon', 'row', 'message']; // ['action', 'location', 'visitDate', 'visitDeployDate', 'snowDepthMeters', 'waterDepthMeters', 'crew', 'bait', 'accessMethod', 'landFeatures', 'comments'];
        this.tableOptions.sortable = ['row'];
        this.tableOptions.headings = {
          icon: '',
          row: this.$t('orgCSVUpload-headings.row'),
          message: this.$t('common-message')
        };
        this.tableOptions.orderBy = {column: 'row', ascending: true};
        break;
      }
    }
  },
  computed: {
    capitalizedType: function () {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    }
  },
  methods: {
    closeForm () {
      eventBus.$emit('hide-csv-upload-form', 'location-upload-form');
    },
    clickChooseFile () {
      this.$refs.chooseFile.value = null; // so the user can reupload same file without a problem
      this.$refs.chooseFile.click();
    },
    chooseFile ($event) {
      this.selectedFile = null;
      this.selectedFile = $event.target.files[0];
      this.showPreview = false;
      this.message = null;
    },
    async previewFile () {
      this.tableData = [];
      this.finishedUpload = false;
      this.previewLoading = true;
      this.hasPreviewErrors = false;
      this.tableOptions.headings.action = this.$t('orgCSVUpload-headings.action');
      this.uploadErrors = [];
      this.numSuccess = 0;
      let formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        const response = await this.$http.post(this.getPreviewParams(), formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.data.hasOwnProperty('error')) {
          this.message = response.data.error;
        } else {
          if (this.type === 'visits') {
            this.totalRows = response.body.length;
            response.body.forEach(row => {
              if (row.isError === true) {
                this.hasPreviewErrors = true;
              }
              this.tableData.push(row);
            });
          } else {
            this.totalRows = response.body.total_row_count;
            response.body.results.forEach(row => {
              this.tableData.push(row);
              if (row.action === -1) {
                this.hasPreviewErrors = true;
              }
            });
          }
          this.showPreview = true;
        }
      } catch (er) {
        console.log(er);
        this.showPreview = true;
        this.message = this.$t('audioUploader-unknownError');
      }
      this.previewLoading = false;
      this.showSettings = false;
    },
    getPreviewParams () {
      // api params are slightly different here
      switch (this.type) {
        case ('equipment'): {
          return this.previewUrl + this.organizationId + '&page=1&orderBy=make&isDesc=true';
        }
        case ('location'): {
          return this.previewUrl + this.organizationId + '&page=1&orderBy=location&isDesc=true';
        }
        case ('project-location'): {
          return this.previewUrl + this.projectId + '&page=1&orderBy=location&isDesc=true';
        }
        case ('visits'): {
          return this.previewUrl;
        }
      }
    },
    uploadFile () {
      this.uploading = true;
      if (this.type === 'visits') {
        // in this case we send the entire file at once
        this.uploadEntireFile();
      } else {
      // row by row -- want to limit the number of calls being sent to the server at once
        const concurrentTaskCount = Math.min(this.totalRows, this.parallelUploadCount);
        this.getRows(concurrentTaskCount, 0);
      }
    },
    async uploadEntireFile () {
      let formData = new FormData();
      formData.append('file', this.selectedFile);
      try {
        const response = await this.$http.post(this.updateUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.data.hasOwnProperty('error')) {
          this.entireFileUploadStatus = {success: false, message: response.data.error};
        } else {
          // no errors were found in the file, ok to upload
          this.entireFileUploadStatus = {success: true, message: this.$t('orgCSVUpload-fileUploaded')};
          this.uploading = false;
          this.finishedUpload = true;
        }
      } catch (er) {
        console.log(er);
        this.entireFileUploadStatus = {success: false, message: er};
        this.uploading = false;
        this.finishedUpload = true;
        // this.message = 'Upload Failed (Unknown API Error)';
      }
    },
    async getRows (concurrentTaskCount, startIndex) {
      let rows = [];

      for (let index = startIndex; index < this.totalRows; index++) {
        rows.push({row: this.tableData[index], id: index});
        if (rows.length === concurrentTaskCount) {
          this.handleConcurrentGroup(rows, concurrentTaskCount, index);
          return;
        }
      }
      // if the loop finishes and theres still some rows to do
      if (rows.length > 0) {
        await this.handleConcurrentGroup(rows, concurrentTaskCount, -1);
      }
    },
    async handleConcurrentGroup (rows, concurrentTaskCount, index) {
      let promises = [];
      rows.forEach(row => {
        promises.push(this.handleRow(row));
      });
      Promise.all(promises).then(async (results) => {
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            this.numSuccess++;
          } else {
            rows[index].row.error = result.reason;
            this.uploadErrors.push(rows[index].row);
          }
        });
        if (this.numSuccess + this.uploadErrors.length === this.totalRows) {
          // then we're done
          if (this.uploadErrors.length > 0) {
            this.tableOptions.headings.action = this.$tc('common-error', 1);
          }
          this.uploading = false;
          this.finishedUpload = true;
        } else {
          // if (index !== -1 && index !== this.totalRows) {
          this.getRows(concurrentTaskCount, ++index);
        }
      });
    },
    handleRow (row) {
      return new Promise((resolve, reject) => {
        this.postAndPassBack(this.updateUrl, this.getUploadParams(row.row), (response) => {
          resolve({status: 'fulfilled', value: response.body});
        }, (failed) => {
          // it shouldve worked but didnt, so need to tell the user that something happened
          resolve({status: 'rejected', reason: failed.error});
        })
      });
    },
    getUploadParams (row) {
      // api parameters are different for each call
      switch (this.type) {
        case ('equipment'): {
          let {action, reasoning, ...tmp} = row;
          return { action: this.uploadOptions.get(row.action).type,
            data: tmp
          };
        }
        case ('project-location'):
        case ('location'): {
          let {action, reasoning, ...tmp} = row;
          tmp.name = tmp.location;
          return tmp;
        }
      }
    },
    cancelUpload () {
      this.showPreview = false;
      this.selectedFile = null;
      this.tableData = [];
      this.message = null;
      this.showSettings = true;
      this.$refs.chooseFile.value = null; // so the user can reupload same file without a problem
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.uniqueId;
    }
  },
  data () {
  //  const pageSize = 100;
    return {
      previewUrl: null,
      codeOptionsUrl: API_URL + 'get-sync-code-options',
      updateUrl: null,
      selectedFile: null,
      showSettings: true,
      tooltips: {},
      hasPreviewErrors: false,
      uploadErrors: [],
      uploadOptions: null,
      showPreview: false,
      message: null,
      tableData: [],
      totalRows: 0,
      numSuccess: 0,
      uploading: false,
      previewLoading: false,
      finishedUpload: false,
      parallelUploadCount: 5,
      callsBegan: false,
      entireFileUploadStatus: null,
      columns: [],
      tableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: false,
        filterable: false,
        sortable: [],
        listColumns: {
        },
        headings: {},
        perPage: 100,
        perPageValues: [50, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        /*  columnsClasses: {
          action: 'action',
          code: 'code',
          serialNumber: 'serialNumber',
          purchaseDate: 'purchaseDate',
          statusStr: 'status',
          typeStr: 'type',
          model: 'model',
          make: 'make'
        }, */
        texts: this.$t('common-tableTexts'),
        rowClassCallback (row) {
          return (row.uniqueId === this.activeRowId ? 'activeTableRow' : '');
        }
      }
    };
  }
}
</script>
<style lang="scss">

#org-csv-upload .csvUpload{
  padding: 40px 5px 0px 5px;

  table {
    width: 100% !important;
  }
}

#org-csv-upload .file-chooser{
  width:90%;
  display:none;
}

#org-csv-upload .baseline-wrapper{
  position:relative;
}

#org-csv-upload .baseliner{
  position: absolute;
  top:20px;
}

@media only screen and (min-width: 741px){  /*768px) {*/
#org-csv-upload .baseliner{
  top: 16px;
  }
}

@media only screen and (min-width: 1370px) {
  #org-csv-upload .baseliner{
    top: 20px;
  }
}

#org-csv-upload .pulse{
  position: absolute;
	left: 50%;
	right: 50%;
  top: 30px;
  z-index: 1000;
/*bottom: 60%;
	top: 40%; */
}

#org-csv-upload .preview-pulse{
  text-align: center;
}

#org-csv-upload .loader {
  position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.8;
	filter: blur(5px);
}

#org-csv-upload .finishMsg{
  height: 2rem;
  padding-left: 0.75rem;
}

#org-csv-upload #csv-upload .VuePagination {
margin-bottom: 0px;
}

#org-csv-upload .progress-text{
  text-align: center;
  padding-top: 10px;
}

#org-csv-upload .orange-border
{
  border: 1px solid #d89b9b;
}

#org-csv-upload .no-bottom-margin{
  margin-bottom: 0px;
}

#org-csv-upload .bottom-margin{
  margin-bottom: 70px;
}

#org-csv-upload #upload-box{
  border: 1px solid #d89b9b;
  margin-bottom: 20px;
}

#org-csv-upload .flex-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#org-csv-upload span.link {
  color: #C94412;
  font-size: 17px;
}

</style>
