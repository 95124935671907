<template>
  <div>
    <div class="pagination">
      <span class="red-font" v-if="total > 1">{{orderDisplay[0]}}</span>
      <span class="first-last-arrows" v-show="activePageNumber > 1" @click="setPage(activePageNumber - 1)"><i class="fas fa-angle-double-left"></i></span>
      <template v-for="(item, topIndex) in displayPageList"   >
        <template v-if="item === 0">
            <div :key="'first' + topIndex" class="allPages">
            <span @click="ui.showPages = !ui.showPages" ><i class="fas fa-ellipsis-h"></i></span>
            <div class="pageList" v-show="ui.showPages" v-if="item === 0">
              <RecycleScroller
              class="pageListWrap"
              :items="pageObj"
              :item-size="35"
              >  <template v-slot="props">
                <div class="page-row" style="display: flex; justify-content: space-around;">
                  <template v-for="(pageItem, index) in props.item.data">
                    <a style="width:35px; height: 35px; float:none;"  :key="'page' + index"  v-if="pageItem.page > 0"
                    v-bind:class="{active: (pageItem.page) === activePageNumber, [pageItem.className]: true}"
                    @click="setPage(pageItem.page)" > <div class="triangle" v-if="pageItem.className && pageItem.className.indexOf('out-of-range') !== -1"></div>{{pageItem.page}}</a>
                    <a class="placeholder" style="width:35px; height: 35px; float:none;" :key="'page' + index"  v-else></a>
                  </template>
                </div>
              </template>
              </RecycleScroller>
            </div>
            </div>
        </template>
        <template  v-else ><a :key="'first' + topIndex"  class="pagination-btn" v-bind:class="{'selected': ((item) === activePageNumber), [pageClass[item-1]]: true}"
          @click="setPage(item)" ><div class="triangle" v-if="pageClass[item-1] && pageClass[item-1].indexOf('out-of-range') !== -1"></div> {{item}}</a></template>
      </template>
      <span class="first-last-arrows" v-show="activePageNumber < total-1" @click="setPage(activePageNumber + 1)"><i class="fas fa-angle-double-right"></i></span>
      <span class="red-font" v-if="total > 1">{{orderDisplay[1]}}</span>
    </div>
  </div>
</template>
<script>
/* this component show batch upload from
*/
import { eventBus } from '@/lib/eventbus';
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  name: 'aru-tagging-pagination',
  components: {'RecycleScroller': RecycleScroller},
  props: [ 'activePageNumber', 'pagination', 'totalPageNum', 'statusField', 'timeStamp', 'orderDisplay', 'isSeries' ], // 'pagination',
  created () {
    this.updateNavigationPage();
    if (this.pagination) {
      this.setClasses();
    }
  },
  watch: {
    pagination (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateNavigationPage();
        this.setClasses();
      }
    },
    activePageNumber (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.updateNavigationPage();
      }
    },
    timeStamp (newVal, oldVal) { //
      if (oldVal !== newVal) {
        // console.log('pagination updated')
        this.updateNavigationPage();
        this.setClasses();
      }
    },
    totalPageNum (newVal, oldVal) {
      this.total = newVal;
      if (oldVal !== newVal && newVal > 0 && this.pagination == null) { // don't call it twice when there is pagination value
        this.updateNavigationPage();
      }
    }
  },
  methods: {
    /* calcuate class and store them in a class to save time */
    setClasses () {
      const self = this;
      this.total = this.pagination.length;
      /* use to get an array to track each pages' status */
      this.pageClass = [];
      const fields = this.statusField.split(' ');
      for (let i = 0; i < this.total; i++) {
        let pageStatus = [];
        if (this.pagination && this.pagination[i]) {
          fields.forEach(field => {
            if (field === 'is_fov') {
              pageStatus.push(self.pagination[i]['is_fov'] ? 'within' : 'out-of-range');
            } else {
              pageStatus.push(self.pagination[i][field]);
            }
          });
        }
        this.pageClass.push(pageStatus.join(' '));
      }
      /* pageObj used to display all page in a table: using partial rendering technicals, so when page numbers are huge
      it won't take seconds to load them */
      let row = [];
      this.pageObj = [];
      for (let i = 0; i < this.total; i++) {
        let pageStatus = [];
        if (this.pagination && this.pagination[i]) {
          fields.forEach(field => {
            if (field === 'is_fov') {
              pageStatus.push(self.pagination[i]['is_fov'] ? 'within' : 'out-of-range');
            } else {
              pageStatus.push(self.pagination[i][field]);
            }
          });
        }
        row.push({id: i, className: pageStatus.join(' '), page: i + 1});
        if (row.length === 6) {
          this.pageObj.push({id: i, data: row});
          row = [];
        }
      }

      const rowSize = row.length;
      if (rowSize > 0) {
        for (let i = 0; i < 6 - rowSize; i++) {
          row.push({id: this.total + i, page: 0});
        }
      }

      this.pageObj.push({id: this.total, data: row});
    },
    setPage (pageNumber) {
      if (pageNumber <= 0 || pageNumber > this.total) {
        // null
      } else {
        eventBus.$emit('pick-page', pageNumber);
        this.ui.showPages = false;
      }
    },
    updateNavigationPage () {
      this.ui.showPages = false;
      this.displayPageList = [];
      this.displayPageList.push(1); // always have page 1

      if (this.activePageNumber > 3) { // 0, will be displayed as ... in navigation bar
        this.displayPageList.push(0);
      }
      let pageCount;
      if (this.pagination != null) {
        pageCount = this.pagination.length - 1;
      } else {
        pageCount = this.totalPageNum;
      }

      /* display prev, current, next */
      if (this.activePageNumber > 2) {
        this.displayPageList.push(this.activePageNumber - 1);
      }
      if (this.activePageNumber > 1) {
        this.displayPageList.push(this.activePageNumber);
      }

      if (this.activePageNumber < pageCount) {
        this.displayPageList.push(this.activePageNumber + 1);
      }
      if (this.activePageNumber < pageCount - 1) {
        /* ... */
        if (pageCount > 3) {
          this.displayPageList.push(0);
        }
        /* last page */
        this.displayPageList.push(pageCount);
      }
    }
  },
  data () {
    return {
      ui: {
        showPages: false
      },
      displayPageList: [],
      pageClass: [],
      pageObj: [],
      total: this.totalPageNum
    }
  }
}
</script>
<style scoped>
.info-icon {
  font-size: 18px;
  color: #FF9800;
}
.tooltip {
  max-width: 600px;
}

.row .margin-bottom-20 {
  margin-bottom: 20px!important;
}

  .triangle:before {
  content: "";
  position: absolute;
  top: 0%;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left top, #e6e92e8f 50%, transparent 50%)
}

.pagination .empty, .pageListWrap a.empty, .pagination .within, .pageListWrap a.within  {
  background-color: #fff!important;
  border:#ccc 1px solid;
  color: #333;
}

.pagination a.within, .pagination a.out-of-range{
  border: #f1f1f1 2px solid;
}
.pagination a.active {
  border: #00ffff 2px solid;
  /* background-color:; */
  font-weight: bold;
}

.page-row {
  width: 100%;
  position: absolute!important;
  top: 0;
  left: 0;
  will-change: transform;
  cursor: pointer;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.allPages {display:inline; cursor:pointer; position:relative}
.allPages .pageList {position:absolute; width:282px; top:50px; height:240px; right:0; background:#fff; z-index:2; -webkit-box-shadow:1px 1px 10px rgba(0, 0, 0, 0.2); box-shadow:1px 1px 10px rgba(0, 0, 0, 0.2); padding:18px; }
.allPages .pageList:before {content:" "; width:0px; height:0px; border-left:10px solid transparent; border-right:10px solid transparent; border-bottom:10px solid .999; position:absolute; top:-10px; right:84px}
.allPages .pageList .pageListWrap {overflow:scroll; height:100%; width:100%}
.allPages .pageList .pageListWrap a, .pagination a.pageNum{width:35px; height:35px; text-align:center; padding:6px 0;  position: relative; }

.first-last-arrows{
  cursor:pointer;
}
</style>
