<template>
  <div id="everythingTab" class="" style="position: relative;">
    <div v-if="loading" class="loading">
      <pulse-loader :loading="true" color="#127916" size="25px" :width="100" :height="50"></pulse-loader>
    </div>
    <mapbox 
    ref="map" 
    :class="{'short-map':mapType === 'weather'&&!mapboxClasses, ...mapboxClasses}"
    :access-token="accessToken"
    :map-options="{
    center: [-96, 37.8],
    zoom: 3,
    style: 'mapbox://styles/mapbox/light-v10'// 'mapbox://styles/mapbox/light-v9'
    }"
    :geolocate-control="{
      show: true,
      position: 'top-left',
    }"
    :scale-control="{
      show: true,
      position: 'top-left',
    }"
    :fullscreen-control="{
      show: true,
      position: 'top-left',
    }"
    @map-load="mapLoaded"
    @map-init="initalized"
    @map-data="dataEvent"
    @map-click:unclustered-point="clickPoint"
    @map-mouseenter:unclustered-point="mouseEntered"
    @map-mouseleave:unclustered-point="mouseLeft"
    @geolocate-error="geolocateError"
    @geolocate-geolocate="geolocate"
    />
  </div>
</template>

<script>
/* display many points
  Can't update points when input changes. rely on parent components to handle.

*/
/* https://github.com/phegman/vue-mapbox-g
  need to include mapbox gl js and css in html as well.
*/
// import Vue from 'vue';
import Mapbox from 'mapbox-gl-vue'; // can't be combined import; i.e. import {Mapbox} will fail
import mapboxgl from 'mapbox-gl';
import { API_URL, MAP_ACCESS_TOKEN, copyObject } from '@/lib/common';
import { fitToPoints, checkSourceUnload, setLanguage } from '@/components/mapGL/mapUtil';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading
import {eventBus} from '@/lib/eventbus';
export default {
  name: 'location-maps',
  components: {
    'mapbox': Mapbox,
    'pulse-loader': PulseLoader
  },
  props: {
    categoryString: {type: String, default: null}, // for weather
    siteLocation: {type: Object, default: null}, // for weather
    mapType: { type: String, default: 'organization' },
    mapboxClasses: {type: Object, default: null}
  },
  data: function () {
    return {
      accessToken: MAP_ACCESS_TOKEN,
      maxZoom: 12,
      map: null,
      pointSourceName: 'points',
      topLayerName: 'top',
      loading: true,
      apiLinks: {
        weather: 'get-weather-stations-for-location?date='
      },
      pointData: null,
      selected: {}
    }
  },
  watch: {},
  methods: {
    dataEvent (map, data) {
      if (data.dataType === 'source' && data.isSourceLoaded) {
        this.loading = false;
      } else {
        // this.loading = true;
      }
    },
    loadCustomStyle (map) {
      // map.setStyle('mapbox://styles/mapbox/light-v10');
      // return;
      // // something wrong with the style layers on vue mapbox, can't show other layers with this cached layer
      // const topographUrl = 'https://upsckv7isg.execute-api.us-west-2.amazonaws.com/dev2/layers/3602?type=basemap&id=3602&grid_id=1'; // grey scale
      // // const topographUrl = 'https://api.mapbox.com/styles/v1/mapbox/light-v9';

      // let headers = {};
      // headers['x-api-key'] = 'NSgyFqN2n31UQPd3uLA8o2w0A7TJ0Gk29JPVK041';
      // this.$http.get(topographUrl, { headers }).then(function (styleResponse) {
      //   const style = styleResponse.data && styleResponse.data.data && styleResponse.data.data.style;
      //   if (style) {
      //     map.setStyle(style);
      //   } else {
      //     map.setStyle('mapbox://styles/mapbox/light-v10')
      //   }
      // });
    },
    mapLoaded (map) {
      this.addLayers(map);
      setLanguage(map);
    },
    /* copied from mapping portal */
    manualUpdate () {
      this.clearMap(true);
      this.addLayers(this.$refs.map.map);
    },
    async clearMap (bInital) {
      const map = this.$refs.map.map;
      this.hoverPopup && this.hoverPopup.remove();
      const allLayers = ['unclustered-point', 'top-layer'];
      allLayers.forEach(l => {
        if (map.getLayer(l)) {
          map.removeLayer(l);
        }
      });

      /* remove markers form map and then clear the collections */
      for (let id in this.markers) {
        this.markers[id].remove();
      }
      for (let id in this.markersOnScreen) {
        this.markersOnScreen[id].remove();
      }
      this.markers = {};
      this.markersOnScreen = {};
      /* remove source data
        the easy way should be just replace map points,
        however, the cluster property defines how to cluster data can't
        be updated dynamically, so I remove and add the source instead.
      */
      if (map.getSource(this.pointSourceName)) {
        map.removeSource(this.pointSourceName);
        await checkSourceUnload(this.pointSourceName, map);
      }
      if (bInital) {
        map.easeTo({
          center: [-110, 50.8],
          zoom: 3
        });
      }
    },
    async addLayers (map) {
      let response;
      try {
        response = await this.$http.get(API_URL + this.apiLinks[this.mapType] + this.categoryString);
        this.pointData = response.data;
      } catch (e) {
        this.error = this.$t('map-loadingError');
      }

      if (this.pointData.features.length === 0) { // don't do anything when no point
        return;
      }
      let climateIds = this.pointData.features.reduce((tmpArray, item) => {
        tmpArray.push(item.properties.climateId);
        return tmpArray;
      }, []);
      eventBus.$emit('stations-loaded', climateIds);
      // set the first (closest) station as 'selected'
      this.selected = copyObject(this.pointData);
      this.selected.features = this.selected.features.slice(0, 1);
      // include the station for centering
      let tmpCoords = copyObject(this.pointData.features);
      tmpCoords.push({geometry: {type: 'Point', coordinates: [this.siteLocation.longitude, this.siteLocation.latitude]}});
      fitToPoints(map, tmpCoords);

      map.addSource(this.pointSourceName, {
        type: 'geojson',
        data: this.pointData,
        cluster: false
      });

      map.addSource(this.topLayerName, {
        type: 'geojson',
        data: this.selected,
        cluster: false
      });
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: this.pointSourceName,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
      map.addLayer({
        id: 'top-layer',
        type: 'circle',
        source: this.topLayerName,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#C94412',
          'circle-radius': 8,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      // for weather, add a marker for the site's location
      new mapboxgl.Marker({'color': '#C94412'})
        .setLngLat([this.siteLocation.longitude, this.siteLocation.latitude])
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML('<p>' + this.$t('common-longitude').toLowerCase() + ': ' + this.siteLocation.longitude + '</p><p>' + this.$t('common-latitude').toLowerCase() + ': ' + this.siteLocation.latitude + '</p>'))
        .addTo(map);
    },
    initalized (map) {
      this.loadCustomStyle(map);
      // const Draw = new MapboxDraw()
      // map.addControl(Draw, 'top-right')
    },

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    clickPoint (map, e) {
      this.switchSelected(e, map);
      this.displayInfoBox(map, e)
    },
    displayInfoBox (map, e) {
      const features = e.features;
      const coordinates = e.features[0].geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      let htmlStr = features.reduce((htmlStr, feature) => {
        return htmlStr + `<li><b>${this.$t('map-stationName')}</b>: ${feature.properties.stationName}
        <br/><b>${this.$t('map-distance')}</b>: ${feature.properties.distance}</li>`;
      }, '<ul>') + '</ul>';
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(htmlStr)
        .addTo(map);
    },
    switchSelected (e, map) {
      // remove old point from selected and add new point
      let idx;
      this.pointData.features.forEach((point, index) => {
        if (e.features[0].properties.climateId === point.properties.climateId) {
          idx = index;
        }
      });
      this.selected.features.pop();
      this.selected.features.push(e.features[0]);
      map.getSource(this.pointSourceName).setData(this.pointData);
      map.getSource(this.topLayerName).setData(this.selected);
      // emit so the rest of the modal can update
      eventBus.$emit('station-update', {id: e.features[0].properties.climateId, index: idx});
    },
    getNextStation () {
      for (let i = 0; i < this.pointData.features.length; i++) {
        if (this.pointData.features[i].properties.climateId === this.selected.features[0].properties.climateId) {
          if (i < this.pointData.features.length - 1) {
            this.selected.features.pop();
            this.selected.features.push(copyObject(this.pointData.features[i + 1]));
            this.clearPopup();
            this.$refs.map.map.getSource(this.pointSourceName).setData(this.pointData);
            this.$refs.map.map.getSource(this.topLayerName).setData(this.selected);
            return this.pointData.features[i + 1].properties.climateId;
          } else {
            // already at the last point
            return null;
          }
        }
      };
    },
    getPreviousStation () {
      for (let i = 0; i < this.pointData.features.length; i++) {
        if (this.pointData.features[i].properties.climateId === this.selected.features[0].properties.climateId) {
          if (i >= 1) {
            this.selected.features.pop();
            this.selected.features.push(copyObject(this.pointData.features[i - 1]));
            this.clearPopup();
            this.$refs.map.map.getSource(this.pointSourceName).setData(this.pointData);
            this.$refs.map.map.getSource(this.topLayerName).setData(this.selected);
            return this.pointData.features[i - 1].properties.climateId;
          } else {
            // already at the last point (0)
            return null;
          }
        }
      };
    },
    clearPopup () {
      const popup = document.getElementsByClassName('mapboxgl-popup');
      if (popup.length) {
        popup[0].remove();
      }
    },
    mouseEntered (map) {
      map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeft (map) {
      map.getCanvas().style.cursor = ''
    },
    geolocateError (control, positionError) {
      console.log(positionError)
    },
    geolocate (control, position) {
      console.log(
        `User position: ${position.coords.latitude}, ${position.coords.longitude}`
      )
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 500px;
}
@media only screen and (min-width: 993px) {
  #map {
    height: 600px;
  }
}

@media only screen and (min-width: 1024px) {
  #map {
    height: 700px;
  }
}

.short-map{
  height: 485px !important;
}

.loading {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 3;
    /* background: white; */
}
</style>
