<template>
  <div class="batchUploadForm">
    <modal name="upload-camera-form"
      :scrollable="true"   :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form !overflow-y-auto"
      draggable=".drag-handle"
      :delay="100"
      width = "80%"
      height = "90%"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "false">
      <div>
        <div @click="closeForm" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
          <h6>{{$t('cameraUploadForm-title')}}</h6>
        </div>
        <div>
          <div class="modal-content container" >
            <div class="row">
              <p v-if="! batchUpload.folderUploadSupported">{{$t('cameraUploadForm-BrowserNotSupported')}}</p>
            </div>
            <div v-show="batchUpload.folderUploadSupported" id="batchForm" class="container-fluid">
              <div>
                <template v-for="(msg, index) in message">
                  <div class="error" :key="'msg' + index"> {{msg}}</div>
                </template>
              </div>

              <div  class="margin-bottom-20">
                <div class="tabbed" style="margin-left: 0">
                  <ul class="tabs">
                    <li class="tab" @click="tab = 'upload'"><a v-bind:class="{ active: tab === 'upload'}"><span class="ion-android-upload"></span>{{$t('common-uploading')}}</a></li>
                    <!-- <li class="tab" @click="tab = 'name'"><a v-bind:class="{ active: tab === 'name'}"><span class="ion-ios-copy-outline"></span> File Naming</a></li>         -->
                  </ul>
                  <!-- first tab for more used, upload parameters -->
                  <div class="padded20 boxed dataGroup" v-show="tab === 'upload'">
                    <div class="row folderFormat"  v-show="!batchUpload.status.uploading">
                      <div class="col s12" v-if="isAbmi">
                        <p>{{$t('cameraUploadForm-formatting')}}
                          <span class="info-icon ion-information-circled"
                          v-tooltip="$t('cameraUploadForm-tooltips.formats')"/>
                          <!-- '<li>Organization and Station can not contain dash (-) or underscore (_). </li>'   -->
                          <!-- '<li>Site-Station (OG-ABMI-1600-NE, site1234-station1)</li>' <li>Organization-Site-Station (ABMI-OG-ABMI-1600-NE, CITY-site1234-station1)</li>' + -->
                        </p>
                        <div class="col s12" >
                          <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf1" value="site-only" v-on:change="changeFolderFormatting"><span><span>{{$t('cameraUploadForm-nonABMILocation')}}</span></span></label>
                          <!-- only apply for non-abmi data -->
                          <div class="sub-item-indent" :class="{'blocked': !(batchUpload.settings.folderNamingFormat === null || !batchUpload.settings.folderNamingFormat.includes('abmi'))}">
                            <span class="label col-md-2 col-sm-3" for="settings-usefoldername">{{$t('cameraUploadForm-level')}}
                              <span class="info-icon ion-information-circled" v-tooltip ="{content: (batchUpload.settings.folderNamingFormat === null || !batchUpload.settings.folderNamingFormat.includes('abmi')) ? $t('cameraUploadForm-tooltips.levels') : ''}"></span>
                            </span>
                            <span>
                              <input type="number" class="form-control folderBaseInput" id="settings-usefoldername" value="" min="1" v-model.number="batchUpload.settings.baseLevel" :disabled="batchUpload.settings.folderNamingFormat && batchUpload.settings.folderNamingFormat.includes('abmi')"/>
                            </span>
                          </div>
                        </div>
                        <!-- <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf2" value="site-station" v-on:change="changeFolderFormatting"><span><span>Site-Station</span></span></label>
                        <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf3" value="org-site-station" v-on:change="changeFolderFormatting"><span><span>Organization-Site-Station</span></span></label> -->
                        <div class="col s12 folderFormatHeight">
                          <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf4" value="abmi-station" v-on:change="changeFolderFormatting"><span>
                            <span>{{$t('cameraUploadForm-ABMIStation')}}</span></span>
                          </label>
                        </div>
                        <div class="col s12 folderFormatHeight">
                          <label><input type="radio" v-model="batchUpload.settings.folderNamingFormat" id="fnf4" value="abmi-nostation" v-on:change="changeFolderFormatting"><span>
                            <span>{{$t('cameraUploadForm-ABMINoStation')}}</span></span>
                          </label>
                        </div>
                      </div>

                      <div class="col s12 grid grid-cols-3" v-else> <!-- non abmi -->

                        <div>
                        <span class="label col-md-2 col-sm-3" for="settings-usefoldername">{{$t('cameraUploadForm-level')}}
                          <span class="info-icon ion-information-circled" v-tooltip ="$t('cameraUploadForm-tooltips.levels')"></span>
                        </span>
                        <span class="flex items-center">
                          <span class="mr-2">{{batchUpload.settings.baseLevel}}</span>
                          <input type="range" class="form-control folderBaseInput !m-0" step="1" id="settings-usefoldername" value="" min="1" max="5" v-model.number="batchUpload.settings.baseLevel" :disabled="batchUpload.settings.folderNamingFormat && batchUpload.settings.folderNamingFormat.includes('abmi')"/>
                        </span>
                        </div>

                        <label>
                          <input type="checkbox" class="exclusive checkbox" id="settings-skipdeployment" v-model="batchUpload.settings.skipExisting" />
                          <span>
                            <span class="control-label col-md-2 col-sm-3 !normal-case" for="settings-skipdeployment">{{$t('cameraUploadForm-skipExisting')}}</span>
                            <span  class="info-icon ion-information-circled" v-tooltip="$t('cameraUploadForm-tooltips.skipExisting')"></span>
                          </span>
                        </label>

                      </div>
                    </div>
                    <form v-show="batchUpload.folderUploadSupported" method="GET" role="form" enctype="multipart/form-data" id="batchForm" class="container-fluid">
                      <div class="row" v-show="!batchUpload.status.uploading">
                        <!-- <h6 class="centered">Choose a folder to upload</h6> -->
                        <div class="col s4 offset-s2">

                          <!-- for Better UI, hide the actual folder selection tool, and use a custom button instead -->

                          <input type="file" ref="chooseFolder" id="chooseFolder" name="picture"
                            :value="fileInputVal" accept="image/*"
                            class="form-control hidden" webkitdirectory="true"
                            multiple="true"
                            @change="parseUploadFolders" style="display: none;" />

                          <button type="button" style="width: 90%" class="btn btn-default" id="browseButton"  @click="browseFolder()" :disabled="!batchUpload.settings.folderNamingFormat">
                            <span class="glyphicon glyphicon-refresh"></span> {{$t('common-folderBtn')}}
                          </button>
                        </div>
                        <div class="col s4">
                          <button type="button" style="width: 90%" class="btn btn-secondary" :disabled="!batchUpload.status.totalFileCount" @click="resetBatchUpload">Clear Folder Selection</button>
                        </div>
                      </div>
                    </form>



                    <div id="batchUploadPreview" v-if="batchUpload.status.filesParsed">
                      <!-- show preview data as vue components -->
                      <!-- <p> Total of <b>{{batchUpload.status.totalFileCount}}</b> files found.</p> -->
                      <p v-for="(w, index) in batchUpload.status.warnings" class="text-danger" :key="'key' + index">{{w}}</p>

                      <p v-html="$tc('cameraUploadForm-foundLocations', batchUpload.status.locationCount, {numLocations: batchUpload.status.locationCount}) + ' ' + $tc('cameraUploadForm-foundFiles', batchUpload.status.imageFileCount, {numFiles: batchUpload.status.imageFileCount})"></p>
                      <ol><!-- , numFiles: batchUpload.status.imageFileCount -->
                        <li v-for="(deployment, dname) in batchUpload.status.deploymentStatus" :id="dname" :key="'deployment' + dname">
                          {{$t('Location')}} <span v-if="deployment.site==deployment.name"></span><span v-else> <a>{{deployment.site}}</a> {{$t('Subfolder')}}:</span> <a>{{deployment.name}}</a>
                          <template v-if="deployment.station">, {{$t('cameraUploadForm-station')}} <b>{{deployment.station}}</b></template>, {{$t('cameraUploadForm-images')}} <a>{{deployment.total}}</a> 
                          <a>
                            <span class="ion ion-android-done-all" v-if="deployment.uploadedStatus" ></span>
                            <span class="ion ion-android-done" v-if="deployment.isAllSkipped">{{$t('cameraUploadForm-skipped')}}</span>
                            <span class="ion ion-android-done" v-if="deployment.readingMetadata">{{$t('cameraUploadForm-scanMetadata')}} <pulse-loader></pulse-loader></span>
                          </a>
                          <div v-if="batchUpload.status.uploading || batchUpload.status.allUploaded" v-show="!deployment.isAllSkipped">
                            <span><a>{{ deployment.completed}}</a> {{$t('cameraUploadForm-uploadSuccess')}},</span>
                            <span v-show="deployment.skipped > 0"><a>{{ deployment.skipped}}</a> {{$t('cameraUploadForm-skipped').toLowerCase()}},</span>
                            <span v-show="deployment.failed > 0 "><a>{{ deployment.failed}}</a> {{$t('cameraUploadForm-failed')}}, </span>
                            <span>{{$t('cameraUploadForm-running')}} {{ Math.floor(0.001*(deployment.endTime - deployment.startTime))}} {{$t('commonUnits-secondsFull')}} </span>
                          </div>
                          <template v-if="deployment.message.length > 0">
                            <p class="error" v-for="(message,index2) in deployment.message" :id="dname" :key="'mesage' + dname + index2">
                              {{message}}
                            </p>
                          </template>
                        </li>
                      </ol>

                      <div v-if="batchUpload.status.ignoredFiles.length > 0">
                        <p><b>{{batchUpload.status.ignoredFiles.length}}</b> {{$t('cameraUploadForm-ignored')}}</p>
                        <ul v-for="(f, index) in batchUpload.status.ignoredFiles" :key="'file' + index">
                          <li><b>{{f.error}}</b>: {{f.path}}</li>
                        </ul>
                      </div>

                      <div id="parsingDiv" v-show="filePreChecking"> <!-- not working here, as can't easily detect upload file cancel button -->
                        <span class="warning">{{$t('cameraUploadForm-reading')}}</span>
                      </div>

                      <button type="button" v-if="!batchUpload.status.uploading && !batchUpload.status.allUploaded && batchUpload.status.imageFileCount > 0" class="btn btn-default" @click="processBatchUpload">
                        {{$t('common-upload')}}
                      </button>
                      <span class="error" v-if="batchUpload.status.allUploaded">{{$t('cameraUploadForm-uploadComplete')}}</span>
                      <div v-if="batchUpload.status.hasError && !batchUpload.status.uploading">
                        <button type="button" @click="showFailedItems" class="btn btn-default btn-success" >{{$t('cameraUploadForm-viewFailed')}}</button>
                        <button type="button" @click="processFailedItems" class="btn btn-default" >{{$t('cameraUploadForm-uploadFailed')}}</button>
                      </div>
                      <button type="button" v-if="batchUpload.status.uploading" class="btn btn-default">
                        {{$t('common-uploading')}}
                      </button>

                    </div>


                  </div>
                  <div class="padded20 boxed dataGroup" v-show="tab === 'setting'">
                    <div class="row padded10">
                      <div class="col s12 ">
                        <label>
                          <input type="checkbox" class="exclusive checkbox" id="settings-skipdeployment" v-model="batchUpload.settings.skipExisting" />
                          <span>
                            <span class="control-label col-md-2 col-sm-3" for="settings-skipdeployment">{{$t('cameraUploadForm-skipExisting')}}</span>
                            <span  class="info-icon ion-information-circled" v-tooltip="$t('cameraUploadForm-tooltips.skipExisting')"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="row padded10">
                      <div class="col s12">
                        <label>
                          <input type="checkbox" name="settings.overwrite" id="settings-overwrite" v-model="batchUpload.settings.overwriteExisting" />
                          <span>
                            <span>{{$t('cameraUploadForm-overwriteExisting')}}</span>
                            <span class="info-icon ion-information-circled" v-tooltip="$t('cameraUploadForm-tooltips.overwriteExisting')"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <!-- <div class="row padded10">
                      <div class="col s12">
                        <label>
                          <input type="checkbox" id="settings-nostation" v-model="batchUpload.settings.noStationinFolderName" />
                          <span>No station in folder names <span class="info-icon ion-information-circled" v-tooltip ="'whether station names are included in folder'"></span></span>
                        </label>
                      </div>
                    </div>     -->
                  </div>
                </div>
                <!-- end of tabs -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal name="view-failed-form"
      :scrollable="true"   
      :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form"
      draggable=".drag-handle"
      :delay="100"
      width = "60%"
      height = "auto"
      :minWidth="200"
      :minHeight="200"
      :clickToClose = "true">
        <div @click="closeFailedModal" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
          <h6>{{$t('Failed Upload Log')}}</h6>
        </div>
        <ol class="p-5">
          <template v-for="(deployment, dname) in batchUpload.status.deploymentStatus" :id="dname" >
            <li :key="'deploymentfailed' + dname" v-if="deployment.failed > 0">
              {{$t('cameraUploadForm-location')}} <a>{{deployment.name}}</a>
              <template v-if="deployment.station">, {{$t('cameraUploadForm-station')}} <b>{{deployment.station}}</b></template>, {{$t('cameraUploadForm-failedImages')}} <a>{{deployment.failed}}</a>
              <p  v-for="(failedItem,index2) in deployment.failedUploadImages"  :key="'failedItem' + dname + index2">
                <span>{{failedItem.file.webkitRelativePath}} </span> <span class="error">{{failedItem.error}}</span>
              </p>
              <p v-if="deployment.pushError">
                {{deployment.pushError}}
              </p>
            </li>
          </template>
        </ol>
    </modal>
  </div>
</template>
<script>
/* this component show camera batch upload from
* TODO: Need to add trim leading zeros *
*/
import {API_URL} from '@/lib/common';
import { s3Uploader } from './cameraUploader';
import { eventBus } from '@/lib/eventbus';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading

export default {
  name: 'camera-batch-upload-form',
  components: { 'pulse-loader': PulseLoader },
  props: {
    'projectId': {
      type: Number,
      requried: true
    },
    'awsParam': {type: Object},
    'isAbmi': {type: Boolean}
  },
  created () {
    this.resetBatchUpload();
    this.resetBatchUploadSetting(); // set setting params
  },
  mounted () {
  // this.$modal.show('upload-form');
  },
  methods: {
    changeFolderFormatting () {
      if (this.batchUpload.settings.folderNamingFormat && this.batchUpload.settings.folderNamingFormat.includes('abmi')) {
        this.batchUpload.settings.baseLevel = null;
      } else if (this.batchUpload.settings.baseLevel === null || this.batchUpload.settings.baseLevel === '') {
        this.batchUpload.settings.baseLevel = 0;
      }
    },
    closeFailedModal() {
      this.$modal.hide('view-failed-form');
    },
    closeForm () {
      eventBus.$emit('show-upload-form', false);
    },
    deplYearRange () {
      let range = [];
      let y = new Date().getFullYear();
      const lowerBoundary = y - 10;
      while (y >= lowerBoundary) {
        range.push(y);
        y -= 1;
      }
      return range;
    },
    updateAllUploadStatus () {
      if (this.batchUpload.status.deploymentStatus) {
        let allUploaded = true;
        for (let value of Object.entries(this.batchUpload.status.deploymentStatus)) {
          if (!value[1].uploadedStatus && !value[1].isAllSkipped) {
            allUploaded = false;
          }
        }
        if (!allUploaded) {
          return;
        }
        // if all uploaded
        if (this.batchUpload.status.uploading) {
          this.batchUpload.status.uploading = false;
          this.batchUpload.status.allUploaded = true;
        }
      }
    },
    initializeBatchUpload () {
      // TODO:  WHY does document.getElementById('chooseFolder') return null?
      this.set(this.batchUpload, 'folderUploadSupported', true);
      this.batchUpload.show = true;
    },

    browseFolder ($event) {
      if (this.batchUpload.settings.folderNamingFormat === null) {
        alert(this.$t('cameraUploadForm-namingFormat'));
        return;
      }
      if ((this.batchUpload.settings.baseLevel === null || this.batchUpload.settings.baseLevel === '') && !this.batchUpload.settings.folderNamingFormat.includes('abmi')) { // when didn't choose abmi, base folder level is requiredbundleRenderer.renderToStream
        alert(this.$t('cameraUploadForm-folderLevelFormat'));
        return;
      }
      this.fileParsing = true;
      this.$refs.chooseFolder.click();
    },
    resetBatchUpload () {
      s3Uploader.reset();
      this.message = [];
      this.batchUpload.status.uploading = false;

      this.$set(this.batchUpload, 'status', {});
      this.$set(this.batchUpload, 'allUploaded', false);
      this.$set(this, 'fileInputVal', '');
      this.$set(this.batchUpload, 'folderUploadSupported', true);
    },
    resetBatchUploadSetting () {
      const settings = {
        skipExisting: null,
        addFilesToExisting: null, // TODO: fix
        overwriteExisting: null, // TODO: fix
        uploadChunkSize: 0.01, // GB,
        folderNamingFormat: 'site-only',
        baseLevel: 1, // updated to be 1 instead of 0
        parallelUploadCount: 5,
        numberImagesToCheck:100, // Number of images to send to create-or-retrieve-camera-image-for-upload-batch at a time
        failedRetryCount: 10,
        imageDBRegisterSize: 100,

        joinedNaming: {
          startLevel: null,
          separator: null,
          leftPadChar: null,
          maxLen: null
        },

        trimOccurrence: {
          at: 0,
          regex: null
        },

        case: null,
        noStationinFolderName: false,
        awsParam: this.awsParam,
        projectId: this.projectId
      };
      this.$set(this.batchUpload, 'settings', settings);
    },

    processBatchUpload () {
      /* do a validation */
      this.message = [];
      const vm = this;

      s3Uploader.registerUpdateCallback(function (deploymentName, statusObject) {
        if (deploymentName) {
          const fileStatus = statusObject.fileStatus;
          const timeType = statusObject.timeType;
          const timeStamp = statusObject.timeStamp;
          const isDeploymentCompelted = statusObject.isDeploymentCompelted;
          const isAllSkipped = statusObject.isAllSkipped;
          const readingMetadata = statusObject.readingMetadata;
          const deploymentId = statusObject.deploymentId;
          const pudIds = statusObject.pudIds;
          if (deploymentId && (vm.batchUpload.status.deploymentStatus[deploymentName].id == null)) {
            vm.batchUpload.status.deploymentStatus[deploymentName].id = deploymentId;
          }

          //console.log(statusObject);

          if (isDeploymentCompelted) {
            vm.batchUpload.status.deploymentStatus[deploymentName].uploadedStatus = true;
            if (pudIds) {
              vm._updatePhase(deploymentName, pudIds);
            }
            if (deploymentId) {
              vm.$http.post(`${vm.ensureHasImagesUrl}?projectId=${vm.projectId}&deploymentId=${deploymentId}`, {}).then(
                  (response) => {
                    if (response.data.hasOwnProperty('error')) {
                    } else {
                    }
                  },
                  (error) => {
                    this.uploadErrors.push(error);
                  }
                );
            }
          } 

          //do ensure-deployment-has-images-else-delete

          if (fileStatus === 'failed') { // error message attach to single files
            vm.batchUpload.status.deploymentStatus[deploymentName].failedUploadImages.push({file: statusObject.file, error: statusObject.message});
            vm.batchUpload.status.hasError = true;
            vm.uploadErrors.push(statusObject);
          } else { // other messages
            if (statusObject.message) {
              vm.batchUpload.status.deploymentStatus[deploymentName].message.push((statusObject.file || '') + ' ' + statusObject.message);
            }
          }

          if (statusObject.failedDBUpdates) {
            vm.batchUpload.status.deploymentStatus[deploymentName].failedDBUpdates = vm.batchUpload.status.deploymentStatus[deploymentName].failedDBUpdates.concat(statusObject.failedDBUpdates);
          }
          // status.deploymentsWithParseErrors = status.deploymentsWithParseErrors.splice(status.deploymentsWithParseErrors.indexOf(deploymentName), 1); // quick fix not sure about this line
          if (fileStatus) {
            // console.log(deploymentName, ' +1 ', fileStatus)
            vm.batchUpload.status.deploymentStatus[deploymentName][fileStatus]++;
            if (vm.batchUpload.status.deploymentStatus[deploymentName].completed +
                vm.batchUpload.status.deploymentStatus[deploymentName].skipped +
                vm.batchUpload.status.deploymentStatus[deploymentName].failed ===
                vm.batchUpload.status.deploymentStatus[deploymentName].total) {
              vm.batchUpload.status.deploymentStatus[deploymentName].uploadedStatus = true;
              vm.updateAllUploadStatus();
            }
          }
          /* track running time */
          if (timeType != null) {
            if (timeType === 'start') {
              vm.batchUpload.status.deploymentStatus[deploymentName].id = deploymentId;
              vm.batchUpload.status.deploymentStatus[deploymentName].startTime = timeStamp;
              vm.batchUpload.status.deploymentStatus[deploymentName].endTime = timeStamp;
            } else {
              if (vm.batchUpload.status.deploymentStatus[deploymentName].endTime == null || timeStamp > vm.batchUpload.status.deploymentStatus[deploymentName].endTime) {
                vm.batchUpload.status.deploymentStatus[deploymentName].endTime = timeStamp;
              }
            }
          }

          vm.batchUpload.status.deploymentStatus[deploymentName].readingMetadata = readingMetadata;

          if (isAllSkipped) {
            vm.batchUpload.status.deploymentStatus[deploymentName].isAllSkipped = true;
            vm.batchUpload.status.deploymentStatus[deploymentName].skipped = vm.batchUpload.status.deploymentStatus[deploymentName].total;
            vm.updateAllUploadStatus();
          }
        } else { // if deployment is null, for all deployments, it is done;
          if (statusObject.isAllCompleted === true) { // all completed, reload table
            eventBus.$emit('reload-task-table');
            eventBus.$emit('load-project-info');
          }

          if (statusObject.preChecking === true) {
            vm.filePreChecking = true;
          } else {
            vm.filePreChecking = false;
          }
        }
      });

      if (Object.entries(s3Uploader.deployments).length > 0) {
        this.batchUpload.status.uploading = true;
        s3Uploader.uploadV2(this.batchUpload.settings, this.$http);

      } else {
        this.message.push(this.$t('cameraUploadForm-cannotUpload'));
      }
    }, // done batch upload

  
    showFailedItems () {
      this.$modal.show('view-failed-form');
    },
    processFailedItems () {
      // const self = this;
      s3Uploader.deployments = this.batchUpload.status.deploymentStatus; // move failed items to deployments
      let deploymentCount = 0;
      let imgFileCount = 0;
      let dNameStats = {};
      for (let dname in s3Uploader.deployments) {
        /* for one deployment, no failed item, remove them */
        if (s3Uploader.deployments[dname].failedUploadImages.length === 0) {
          this.$delete(s3Uploader.deployments, dname);
          continue;
        }
        /* deployment with failed item, count the deployment in */
        deploymentCount += 1;
        /* queue failed image */
        s3Uploader.deployments[dname].images = [];
        s3Uploader.deployments[dname].failedUploadImages.forEach(x => s3Uploader.deployments[dname].images.push(x.file));

        imgFileCount += s3Uploader.deployments[dname].images.length;
        dNameStats[dname] = {
          total: s3Uploader.deployments[dname].images.length,
          startTime: null,
          endTime: null,
          uploadedStatus: false,
          isAllSkipped: false,
          readingMetadata: false,
          // status: this.uploadStatusParam.notStarted,
          failed: 0,
          completed: 0,
          skipped: 0,
          message: [],
          site: s3Uploader.deployments[dname].site,
          station: s3Uploader.deployments[dname].station,
          failedUploadImages: [],
          failedDBUpdates: []
        };
      }

      if (Object.entries(s3Uploader.deployments).length > 0) {
        // reverse the list as it is more efficient to get the element off the end of an array

        this.batchUpload.status = {
          deploymentCount: deploymentCount,
          imageFileCount: imgFileCount,
          // deployments: Object.assign({}, s3Uploader.deployments), // used for display them
          deploymentStatus: dNameStats,
          warnings: new Set(s3Uploader.warnings),
          ignoredFiles: s3Uploader.ignored.slice(0),
          filesParsed: true,
          deploymentsWithParseErrors: s3Uploader.deploymentsWithParseErrors,
          allUploaded: false,
          hasError: false,
          uploading: true
        }
        s3Uploader.uploadV2(this.batchUpload.settings, this.$http);
      } else {
        this.batchUpload.status = {
          warnings: new Set(s3Uploader.warnings),
          ignoredFiles: s3Uploader.ignored.slice(0),
          deploymentsWithParseErrors: s3Uploader.deploymentsWithParseErrors,
          allUploaded: true,
          hasError: false,
          uploading: false
        }
        this.message.push(this.$t('cameraUploadForm-noData'));
      }
    },
    /* parse selected folders */
    parseUploadFolders (e) {
      this.resetBatchUpload();
      
      const self = this;
      self.fileParsing = true;

      e.stopPropagation();
      e.preventDefault();
      // parse files
      //s3Uploader.parseFiles(e.target.files, this.batchUpload.settings);
      s3Uploader.parseFilesV2(e.target.files, this.batchUpload.settings);

      let deploymentCount = 0;
      let locations = [];
      let dNameStats = {}
      let imgFileCount = 0;
      // got from old code: should have been part of s3Uploader
      for (const dname in s3Uploader.deployments) {
        
        if (s3Uploader.deployments[dname].images.length == 0) 
          continue;

        if (locations.indexOf(s3Uploader.deployments[dname].site) == -1) {
          locations.push(s3Uploader.deployments[dname].site);
        }

        deploymentCount += 1;
        imgFileCount += s3Uploader.deployments[dname].images.length;
        dNameStats[dname] = {
          total: s3Uploader.deployments[dname].images.length,
          startTime: null,
          endTime: null,
          uploadedStatus: false,
          isAllSkipped: false,
          readingMetadata: false,
          // status: this.uploadStatusParam.notStarted,
          failed: 0,
          completed: 0,
          skipped: 0,
          message: [],
          name: s3Uploader.deployments[dname].name,
          site: s3Uploader.deployments[dname].site,
          station: s3Uploader.deployments[dname].station,
          failedUploadImages: [],
          failedDBUpdates: []
        };
        s3Uploader.orderList.push({name: dname});
      }
      // reverse the list as it is more efficient to get the element off the end of an array
      // s3Uploader.orderList.reverse();
      this.batchUpload.status = {
        totalFileCount: e.target.files.length,
        deploymentCount: deploymentCount,
        locationCount: locations.length,
        imageFileCount: imgFileCount,
        // deployments: Object.assign({}, s3Uploader.deployments), // used for display them
        deploymentStatus: dNameStats,
        warnings: new Set(s3Uploader.warnings), // copy warnings
        ignoredFiles: s3Uploader.ignored.slice(0), // do a shallow copy of array
        filesParsed: true,
        deploymentsWithParseErrors: s3Uploader.deploymentsWithParseErrors,
        allUploaded: false,
        hasError: false,
        uploading: false

      }
      this.fileInputVal = '';
      this.fileParsing = false;
      // console.log('fileParsing=', this.fileParsing);
    },
    _updatePhase (depName, pudIds) {
      pudIds.forEach(id => {
        this.$http.post(this.updateDeploymentOptionUrl, {id: id}).then(
          (response) => {
            if (response.data.hasOwnProperty('error')) {
              this.batchUpload.status.deploymentStatus[depName].pushError = response.data.error;
            } else {

            }
          },
          (error) => {
            this.batchUpload.status.deploymentStatus[depName].pushError = error;
          }
        );
      })
    }
  },
  data () {
    return {
      uploadErrors: [],
      batchUpload: { status: {} },
      message: [],
      fileInputVal: '',
      fileParsing: false,
      tab: 'upload',
      updateDeploymentOptionUrl: API_URL + 'update-camera-pud-push-forward',
      ensureHasImagesUrl: API_URL + 'ensure-deployment-has-images-else-delete',
      filePreChecking: false
      // uploadStatus: {},
      // uploadStatusParam: uploadStatusParam,
    }
  }
}

</script>
<style scoped>
.info-icon {
  font-size: 18px;
  color: #FF9800;
}
.tooltip {
  max-width: 600px;
}
.modal-form {
  background-color: #fff;
}
.modal-content   .padded10 {
  padding: 10px 5px;
  margin-bottom: 10px;
}
/* alignment for naming section display */
#batchUploadModal .modal-content .naming .row .col   {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 15px;
  font-size: 1rem;
}

#batchUploadModal .modal-content .label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 1rem;
}

.verticl-align {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 10px
}
#batchUploadModal .modal-content input,
#batchUploadModal .modal-content select {
  height: 50px;
}
.modal-form {
  overflow: hidden;
  top:10%;
}
@media only screen and (min-width: 992px) {
  .modal-form {

  width: 60%;
  }

  .modal-form .modal-header {
    max-height: 100px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 991px) {

  .modal-form .modal-header {
    max-height: 50px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
}

.row .margin-bottom-20 {
  margin-bottom: 20px!important;
}

.batchUploadForm .v--modal-overlay .v--modal-box {
  overflow: auto;
}

.batchUploadForm [type=radio]:checked+span, .batchUploadForm [type=radio]:not(:checked)+span {
  font-size: 0.8rem;
  padding-left: 30px;

}
.folderFormatHeight {
  height: 2em;
}
.folderFormat input.folderBaseInput {
  height: 2em;
  margin-left: 10px;
  width: 100px;
}

.sub-item-indent{
  margin-left: 20px;
}

.blocked{
  opacity: 0.5;
}
</style>
