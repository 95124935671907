<template>
  <div>
    <div class="col s12 panel boxed padded60 dataGroup tab-content">
      <v-server-table ref="table" :url="url" :columns="columns" :options="tableOptions" class="table vue-bordered w-full"
      @loaded="loaded"  @loading="loading" @row-click="onRowClick">
        <template slot="beforeBody" v-if="isLoading">
          <pulse-loader></pulse-loader>
        </template>
        <div class="row taskTable" slot="child_row" slot-scope="props" v-if="toLoadSubRow">
          <div v-if="props.row.subDetails && props.row.subDetails.length > 0">
            <div class="row">
              <div class="col s4">{{$t('orgImageSets-subTable.projectName')}}</div>
              <div class="col s3">{{$t('orgImageSets-subTable.assignedUser')}}</div>
              <div class="col s2">{{$t('orgImageSets-subTable.uniqueSpecies')}}</div>
              <div class="col s2">{{$t('orgImageSets-subTable.seriesGap')}}</div>
            </div>
            <div class="subTable">
              <template v-for="(task, index) in props.row.subDetails">
                <div class="row link brown-color" :key="'subrow-' + task.pudId + '' + index" @click="openChildRowLink(task.pudId)">
                  <div class="col s4 red-font">{{task.projectName}} </div>
                  <div class="col s3">{{task.transcriber}} </div>
                  <div class="col s2">{{task.uniqueMammalCount}} </div>
                  <div class="col s2">{{task.seriesGap}} </div>
                </div>
              </template>
            </div>
            <!--<div class="row top-margin">
              <div class="col s9"></div>
              <div class="col s3">
                <button class="btn no-bottom-margin" :class="{'btn-success':props.row.showDeleteButton, 'btn-border': !props.row.showDeleteButton}"
                v-tooltip="{content: props.row.showDeleteButton ? null: 'You cannot delete an image set while tasks for that location exist. Go into each project and manually delete the tasks first.'}"
                :disabled="!props.row.showDeleteButton" @click="deleteDeployment(props.row)">
                  <span style="font-size: 20px;"></span>Delete Image Set</button>
              </div>
            </div>-->
          </div>
          <div v-else>
            <p>{{$t('common-noTaskFound')}}</p>
          </div>
        </div>
      </v-server-table>
    </div>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all locations
under each Location tab, show a table listing all locations. users can also update Location properties + users
or remove locations if they have no associated data.
*/

import OrganizationMixin from './OrganizationMixin';
import { API_URL, CAMERA_RECORDING_URL } from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading

export default {
  name: 'Camera-Image-Table',
  components: { 'pulse-loader': PulseLoader },
  mixins: [OrganizationMixin],
  props: {
    isEditable: {
      default: null,
      type: Boolean
    },
    organizationId: {
      default: null,
      type: Number
    },
    showDeleteMediaButton: {
      default: null,
      type: Boolean
    }
  },
  watch: {
    organizationId () {
      if (this.$refs.table) {
        this.url = this.baseUrl + this.organizationId; // this will auto reload table
      }
    }
  },
  methods: {
    loading () {
      this.isLoading = true;
    },
    loaded () {
      this.isLoading = false;
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.id;
    },
    deleteDeployment (row) {
      console.log(row);
      this.deleteError = null;
      if (!confirm(this.deleteRecordingMsg)) {
        return;
      }
      this.$http.post(this.deleteRecordingUrl + row.id).then((response) => {
        this.$refs.table.refresh();
      }, (err) => {
      // error callback
        this.deleteError = err;
      });
    }
  },
  data () {
    const pageSize = 100;
    return {
      baseUrl: API_URL + 'get-image-deployment-summary?organizationId=',
      url: API_URL + 'get-image-deployment-summary?organizationId=' + this.organizationId,
      childRowUrl: CAMERA_RECORDING_URL,
      deleteImageSetUrl: API_URL + 'delete-camera-image-set?deploymentId=',
      getDetailUrl: ['/get-image-deployment-details-view?currentOrganizationId=', 'deploymentId'],
      columns: ['locationName', 'startDate', 'endDate', 'allImages', 'motionImages', 'taskCount'],
      options: {},
      isLoading: false,
      deleteError: null,
      tableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        // childRow: function (h, row) {
        //   return
        // },
        filterByColumn: true,
        filterable: ['locationName', 'startDate', 'endDate'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['locationName', 'startDate', 'endDate', 'allImages', 'motionImages'],
        listColumns: {
        },
        headings: {
          checkbox: '',
          locationName: this.$t('orgImageSets-headings.locationName'),
          startDate: this.$t('orgImageSets-headings.startDate'),
          endDate: this.$t('orgImageSets-headings.endDate'),
          allImages: this.$t('orgImageSets-headings.allImages'),
          motionImages: this.$t('orgImageSets-headings.motionImages'),
          taskCount: this.$t('orgImageSets-headings.taskCount'),
          id: ''
        },
        columnsClasses: {
          locationName: 'name'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'locationName', ascending: true},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.subrow-info p {
  color: #C94412;
}

div :deep( .VueTables__table td),
div :deep( .VueTables__table th ){
  text-align: center;
}
div :deep( .VueTables__table td.name),
div :deep( .VueTables__table th.name) {
  text-align: left;
}

div :deep( .VueTables__child-row-toggler) {
  cursor: pointer;
}
div :deep( .VueTables__table .v-spinner) {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.5);
}

div :deep( .VueTables__table .taskTable){
  border: 1px solid #cecec6;
  background-color: #f7f7f7;
  padding: 10px;
}
div :deep( .VueTables__table .subTable) {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
div :deep( .VueTables__table td.name) {
  color: #C94412;
}
.brown-color {
  color: #ca7c0b;
}

.no-bottom-margin{
  margin-bottom:0;
}
.red-font {
    color: red;
}
</style>
