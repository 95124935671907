import {attachAuthorizaitonHeader} from '@/lib/common';
import {createdataProcessor} from '@/components/utils/external-lib-wrapper';
// shared by species verification and tagging page
export default {
  data () {
    return {
      filterParam: '',
      selectedRowId: -99999999,
      dateFormat: '%Y-%m-%d',
      dp: null,
      addBlankRowCB: null,
      loading: false
    }
  },
  created () {
    attachAuthorizaitonHeader();
  },
  methods: {
    initGrid (mygrid, statusBar, configArray, colConfigFunction, updateCB) {
      const self = this;
      mygrid.setSkin('material');
      mygrid.setAwaitedRowHeight(33); // after checking actaully row height, set here as 32px + 1px border;
      mygrid.setColumnIds(configArray.map(x => x[0]).toString());
      mygrid.setHeader(configArray.map(x => x[1]).toString(), ',', []);
      mygrid.setInitWidthsP(configArray.map(x => x[2]).toString());
      mygrid.setColTypes(configArray.map(x => x[3]).toString());
      // filter
      mygrid.attachHeader(configArray.map(x => x[4]).toString());
      // sort
      mygrid.setColSorting(configArray.map(x => x[5]).toString());

      // mygrid
      // .setColValidators(getConcatString(myConfigArray, validationInd));

      mygrid.setStyle('', '', '', 'background-color: rgb(150, 255, 255)');

      mygrid.setDateFormat(this.dateFormat);
      // enable editing by single click and by pressing F2 key
      mygrid.enableEditEvents(true, false, true);

      // You can use 'onDhxCalendarCreated' event
      mygrid.attachEvent('onDhxCalendarCreated', function (calendar) {
        // calendar - reference to the dhtmlxCalendar object
        calendar.setDateFormat(this.dateFormat);
        // calendar.hideTime();
      });

      mygrid.attachEvent('onXLS', function () { self.loading = true; });
      mygrid.attachEvent('onXLE', function () { self.loading = false; });

      mygrid.attachEvent('onFilterStart', function (columnsIndexes, filterValues) {
        self.filterGridOnServer(mygrid, statusBar, columnsIndexes, filterValues);
      });
      mygrid.attachEvent('onBeforeSorting', function (ind, type, direct) { self.sortGridOnServer(mygrid, statusBar, ind, type, direct); });

      mygrid.attachEvent('onEditCell', function (stage, rId, cInd, nValue, oValue) {
        if (stage === 2) { // after edit is done
          // Check if the row is the last, if yes, add a new blank row
          if (mygrid.getRowIndex(rId) === mygrid.getRowsNum() - 1) {
            if (updateCB) {
              updateCB();
            }
          }
        }
        return true;
      });
      // mygrid.enableBlockSelection(true);
      mygrid.enableUndoRedo();
      mygrid.enableBlockSelection(true);
      mygrid.enableAutoSaving(this.cookieName);
      mygrid.enableAutoHiddenColumnsSaving(this.cookieName);
      mygrid.enableEditTabOnly(true);
      mygrid.enableColumnMove(true);
      mygrid.init();
      mygrid.enableSmartRendering(true, 30);
      mygrid.enableKeyboardSupport(true);

      mygrid.loadHiddenColumnsFromCookie(this.cookieName);
      mygrid.loadSizeFromCookie(this.cookieName);

      mygrid.enableHeaderMenu(configArray.map(x => x[2] > 0).toString()); // when widht > 0 show header menu

      this.commonLoadColumnOrder(mygrid, this.cookieName);

      if (colConfigFunction) {
        colConfigFunction();
      }

      self.dp = this.linkTodp(mygrid, statusBar, self.dp, this.updateRowUrl, updateCB);
      self.addBlankRowCB = updateCB;
      // this.loadGrid(mygrid, statusBar, this.getGridUrl);
    },
    filterGridOnServer (mygrid, statusBar, index, values) {
      mygrid.clearAll();
      this.filterParam = '';
      let idStr = [];
      let valueStr = [];
      if (!values || values.join('').length === 0) {
      } else {
        if (index) {
          for (let i = 0; i < index.length; i++) {
            if (values[i]) {
              idStr.push(mygrid.getColumnId(index[i]));
              valueStr.push(values[i]);
            }
          }
        }
        this.filterParam = '&filterIndex=' + index + '&filterIdStr=' + idStr + '&filterValue=' + valueStr + '&';
      }
      this.loadGrid(mygrid, statusBar, this.getGridUrl + this.filterParam);
      this.selectedRowId = -99999999;

      return false;
    },
    commonLoadColumnOrder (grid, inCookieName) {
      const self = this;
      const cookieName = inCookieName + 'colOrder';
      let indexList = this.$cookies.get(cookieName);
      if (indexList == null) {
        grid.attachEvent('onAfterCMove', function () {
          self.commonSaveColumnOrder(grid, cookieName);
        });
        return;
      }

      const indexArray = indexList.split(',');
      const totalColumn = grid.getColumnsNum();
      for (let i = 0; i < indexArray.length; i++) { // columns on the right side
        // appear in the array first
        const currentIndex = grid.getColIndexById(indexArray[i]);
        const newIndex = totalColumn - i - 1;
        if (currentIndex !== newIndex) {
          grid.moveColumn(currentIndex, newIndex);
        }
      }

      // after column reordering, save to cookies
      grid.attachEvent('onAfterCMove', function () {
        self.commonSaveColumnOrder(grid, cookieName);
      });
    },
    commonSaveColumnOrder (grid, cookieName) {
      let indexList = '';
      for (let i = grid.getColumnsNum(); i > 0; i--) {
        indexList += grid.getColumnId(i - 1);
        if (i > 1) {
          indexList += ',';
        }
      }
      this.$cookies.set(cookieName, indexList);
    },
    getSortedGridUrl (sortParam, filterParam) {
      let queryUrl = this.getGridUrl;
      queryUrl += '&' + sortParam;
      queryUrl += filterParam;
      return queryUrl;
    },
    sortGridOnServer (grid, statusBar, ind, type, direct) {
      const idStr = grid.getColumnId(ind);
      const sortParam = '&sortIndex='	+ (ind) +
        '&sortIdStr=' + idStr + '&sortOrder=' + (direct === 'des' ? 'desc' : 'asc');
      const queryUrl = this.getSortedGridUrl(sortParam, this.filterParam);
      grid.clearAll();
      this.loadGrid(grid, statusBar, queryUrl);
      grid.setSortImgState(true, ind, direct);
      this.selectedRowId = -99999999;
      return false;
    },
    calculateGridNum (grid, statusBar, updateCB) {
      statusBar.setText(this.$tc('grid-rowsLoaded', grid.getRowsNum(), {num: grid.getRowsNum()}));
      if (updateCB) {
        updateCB();
      }
    },
    linkTodp (mygrid, statusBar, dp, apiUrl, cb) {
      if (dp) {
        return;
      }
      // dp = new dataProcessor(apiUrl);
      dp = createdataProcessor(apiUrl);
      dp.enableDataNames(true);
      // will use names instead of indexes;
      // mygrid.setColumnIds('id,year,site,quadrant,stratum,fielddate,fieldcrew,collectionStatus,envelope,scientificname,iddate,idname,deposition,chemistry,print,comments');
      // // no space in between please.
      // dp.setDataColumns(enabledEdit); // print label excluded. id
      dp.init(mygrid);
      // link dp to the grid;
      dp.setTransactionMode('JSON');
      // dp.setUpdateMode('row', true);
      dp.attachEvent('onBeforeUpdate', function (id, status, data) {
        for (let strName in data) {
          if (data[strName] === undefined) {
            data[strName] = '';
          }
          if (typeof data[strName] === 'string') {
            data[strName] = data[strName].trim();
          }
        }
        if ((status === 'updated' || status === 'inserted') && data.id === '') {
          status = 'inserted';
          // mygrid.lockRow(id, true);
        }
        let error = '';
        if (!data['code']) {
          mygrid.setCellTextStyle(id, mygrid.getColIndexById('code'), 'background-color:red;');
          error += this.$t('grid-errors.codeRequired') + '; ';
        }
        if (!data['status']) {
          mygrid.setCellTextStyle(id, mygrid.getColIndexById('status'), 'background-color:red;');
          error += this.$t('grid-statusRequired') + '; '
        }
        if (error.legnth > 0) {
          statusBar.setText();
          return false;
        }
        statusBar.setText(this.$t('grid-sendingData', {status: status}));

        // Check if the row is the last, if yes, add a new blank row
        if (status === 'inserted' && mygrid.getRowIndex(id) === mygrid.getRowsNum() - 1) {
          if (cb) {
            cb();
          }
        }
        return true;
      })
      /* sid - the original row ID (the same as gr_id);
        tid - the ID of the row after the operation, used for getting the row (usually in insertion) */
      dp.attachEvent('onAfterUpdate', function (sid, action, tid, tag) {
        // mygrid.lockRow(tid, false); // after insert enable edit
        // alert('OnafterUpdate');
        if (action === 'login') {
          mygrid.setRowTextStyle(sid, ' font-weight: bold;');
          if (confirm(this.$t('grid-sessionExpiredMsg'))) {
            window.location = '/';
          }
        }
        if (action === 'invalid' || action === 'error') {
          statusBar.setText(this.$t('grid-errors.singleRowFailed', {action: action}) + (tag.error || '') + ' ' + (tag.message || ''));
          mygrid.setRowTextStyle(tid, 'font-weight: bold; background-color: rgb(250, 155, 155)');
        } else {
          mygrid.setRowTextStyle(tid, 'font-style: normal; background-color: rgb(255, 255, 255)');
          if (action === 'inserted') { // for a newly inserted row,
            try {
              var idCell = mygrid.cellByIdStr(tid, 'id');
              if (idCell.getValue() == null ||
                idCell.getValue() === '' ||
                idCell.getValue() !== tid) {
                idCell.setValue(tid);
              }
            } catch (e) {
              console.log(e, this.$t('grid-errors.failedGetCell'))
            }
          }
          statusBar.setText(this.$t('grid-singleRowSuccess', {action: action}) + (tag.message || ''));
        }
      });
      return dp;
    },
    deleteSelectedRow (grid) {
      if (grid._selectionArea) {
        const topr = grid.getSelectedBlock().LeftTopRow;
        const botr = grid.getSelectedBlock().RightBottomRow;
        const toDelRowNum = botr - topr + 1;

        if (confirm(this.$tc('grid-deleteRowMsg', toDelRowNum, {num: toDelRowNum}))) {
          for (let i = botr; i >= topr; i--) {
            const rId = grid.getRowId(i);
            grid.deleteRow(rId);
          }
        }
      } else if (grid.getSelectedRowId()) {
        if (confirm(this.$tc('grid-deleteRowMsg', 1))) {
          grid.deleteSelectedRows();
        }
      } else {
        alert(this.$t('grid-selectRow'));
      }
    },
    /* reload original grid, remove filters */
    reloadGrid (grid, statusBar, cb) {
      grid.clearAll();
      // linkToDataProcessor();
      this.loadGrid(grid, statusBar, this.getGridUrl);
      this.selectedRowId = -99999999;
      this.filterParam = '';
      if (cb) {
        cb();
      }
    },
    loadGrid (grid, statusBar, url) {
      let self = this;
      grid.load(url, function () { self.calculateGridNum(grid, statusBar, self.addBlankRowCB); }, 'js');
    },
    getCookieExpireDate (days) {
      // var Days = 30; // This cookie will be saved for 30 days
      var exp = new Date(); // currentDate
      exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
      return 'expires =' + exp.toGMTString();
    },
    setCellValue (grid, rowId, colIdStr, newValue) {
      const colIndex = grid.getColIndexById(colIdStr);
      grid.cellById(rowId, colIndex).setValue(newValue || '');
    }
  }
}
