var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col s12", class: { "no-padding": _vm.creatingVisit } },
        [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _vm.isEditable
                ? [
                    _vm.visitId
                      ? _c("h6", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("visitEquipTable-selected", {
                                label:
                                  _vm.visitId &&
                                  _vm.displayOptionLabel(
                                    _vm.visitId,
                                    "deployVisitId",
                                    "text"
                                  )
                              })
                            )
                          )
                        ])
                      : _c("h6", [
                          _vm._v(_vm._s(_vm.$t("visitEquipTable-instructions")))
                        ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success equipmentDetailAdd",
                        on: {
                          click: function($event) {
                            return _vm.updateEquipmentDetail(null)
                          }
                        }
                      },
                      [
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.addSensor,
                              expression: "tooltips.addSensor"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("visitEquipTable-addSensor")) +
                            " "
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "table",
                {
                  staticClass:
                    "VueTables__table table equipment-table table-striped table-bordered table-hover"
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th"),
                      _c("th", [
                        _c(
                          "span",
                          {
                            staticClass: "VueTables__heading",
                            attrs: { title: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("visitEquipTable-type")))]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "VueTables__sort-icon pull-right glyphicon glyphicon-sort"
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "span",
                          {
                            staticClass: "VueTables__heading",
                            attrs: { title: "" }
                          },
                          [_vm._v(_vm._s(_vm.capitalize(_vm.$tc("code", 1))))]
                        ),
                        _c("span", {
                          staticClass:
                            "VueTables__sort-icon pull-right glyphicon glyphicon-sort"
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "span",
                          {
                            staticClass: "VueTables__heading",
                            attrs: { title: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("visitEquipTable-condition")))]
                        ),
                        _c("span", {
                          staticClass:
                            "VueTables__sort-icon pull-right glyphicon glyphicon-sort"
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "span",
                          {
                            staticClass: "VueTables__heading",
                            attrs: { title: "" }
                          },
                          [_vm._v(_vm._s(_vm.$t("visitEquipTable-height")))]
                        ),
                        _c("span", {
                          staticClass:
                            "VueTables__sort-icon pull-right glyphicon glyphicon-sort"
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "span",
                          {
                            staticClass: "VueTables__heading",
                            attrs: { title: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("visitEquipTable-deploymentDate"))
                            )
                          ]
                        ),
                        _c("span", {
                          staticClass:
                            "VueTables__sort-icon pull-right glyphicon glyphicon-sort"
                        })
                      ]),
                      _vm._v(" "),
                      _c("th", [
                        _c(
                          "span",
                          {
                            staticClass: "VueTables__heading",
                            attrs: { title: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("visitEquipTable-retrievalDate"))
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "VueTables__sort-icon pull-right glyphicon glyphicon-sort"
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", {
                          staticClass: "VueTables__child-row-toggler",
                          class: {
                            "VueTables__child-row-toggler--open":
                              _vm.isAllRowOpen,
                            "VueTables__child-row-toggler--closed": !_vm.isAllRowOpen
                          },
                          on: {
                            click: function($event) {
                              return _vm.expandAllRows()
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm.error
                        ? [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "error",
                                  attrs: { colspan: "10" }
                                },
                                [_vm._v(_vm._s(_vm.error))]
                              )
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.errors, function(error, index) {
                        return _c("div", { key: index, staticClass: "error" }, [
                          _vm._v(" " + _vm._s(error) + " ")
                        ])
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.equipmentList, function(row, index) {
                        return [
                          _c(
                            "tr",
                            {
                              key: "equipment-id" + index,
                              staticClass: "main-device"
                            },
                            [
                              _vm._m(0, true),
                              _vm._v(" "),
                              _c("td", {}, [
                                _vm._v(
                                  _vm._s(
                                    row.equipment.type &&
                                      _vm.displayOptionLabel(
                                        row.equipment.type,
                                        "EquipmentType",
                                        "type"
                                      )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", {}, [
                                _vm._v(_vm._s(row.equipment.code))
                              ]),
                              _vm._v(" "),
                              _c("td", {}, [
                                _vm._v(
                                  _vm._s(
                                    row.equipmentConditionId &&
                                      _vm.displayOptionLabel(
                                        row.equipmentConditionId,
                                        "equipmentCondition",
                                        "type"
                                      )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", {}, [_vm._v(_vm._s(row.heightMeters))]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    highlight: _vm.visitId == row.deployVisitId
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.deployVisitId &&
                                        _vm.displayOptionLabel(
                                          row.deployVisitId,
                                          "deployVisitId",
                                          "text"
                                        )
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  class: {
                                    highlight:
                                      _vm.visitId == row.retrieveVisitId
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      row.retrieveVisitId &&
                                        _vm.displayOptionLabel(
                                          row.retrieveVisitId,
                                          "retrieveVisitId",
                                          "text"
                                        )
                                    ) +
                                      "\n                  " +
                                      _vm._s(
                                        row.retrieveVisitId
                                          ? _vm.options.retreiveVisitId.find(
                                              v => v.id === row.retrieveVisitId
                                            ).text
                                          : ""
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "editProject modal-trigger projectAdd",
                                    on: {
                                      click: function($event) {
                                        return _vm.updateEquipmentDetail(row)
                                      }
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "link",
                                      class: {
                                        "ion-edit": _vm.isEditable,
                                        "ion-eye": !_vm.isEditable
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isEditable
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteEquipmentDetails(
                                              row
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass: "link ion-ios-trash"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                row.child && row.child.length > 0
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            row.isOpen = !row.isOpen
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "VueTables__child-row-toggler",
                                          class: {
                                            "VueTables__child-row-toggler--open":
                                              row.isOpen,
                                            "VueTables__child-row-toggler--closed": !row.isOpen
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          row.isOpen
                            ? [
                                row.child && row.child.length > 0
                                  ? [
                                      _vm._l(row.child, function(subRow) {
                                        return [
                                          _c(
                                            "tr",
                                            {
                                              key:
                                                "child-equipment-id" +
                                                subRow.id,
                                              staticClass: "attachment child"
                                            },
                                            [
                                              _vm._m(1, true),
                                              _vm._v(" "),
                                              _c("td", {}, [
                                                _vm._v(
                                                  _vm._s(
                                                    subRow.equipment.type &&
                                                      _vm.displayOptionLabel(
                                                        subRow.equipment.type,
                                                        "EquipmentType",
                                                        "type"
                                                      )
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", {}, [
                                                _vm._v(
                                                  _vm._s(subRow.equipment.code)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", {}, [
                                                _vm._v(
                                                  _vm._s(
                                                    subRow.equipmentConditionId &&
                                                      _vm.displayOptionLabel(
                                                        subRow.equipmentConditionId,
                                                        "equipmentCondition",
                                                        "type"
                                                      )
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", {}, [
                                                _vm._v(
                                                  _vm._s(subRow.heightMeters)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  class: {
                                                    highlight:
                                                      _vm.visitId ==
                                                      subRow.deployVisitId
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      subRow.deployVisitId &&
                                                        _vm.displayOptionLabel(
                                                          subRow.deployVisitId,
                                                          "deployVisitId",
                                                          "text"
                                                        )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  class: {
                                                    highlight:
                                                      _vm.visitId ==
                                                      subRow.retrieveVisitId
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      subRow.retrieveVisitId &&
                                                        _vm.displayOptionLabel(
                                                          subRow.retrieveVisitId,
                                                          "retrieveVisitId",
                                                          "text"
                                                        )
                                                    ) +
                                                      "\n                        " +
                                                      _vm._s(
                                                        row.retrieveVisitId
                                                          ? _vm.options.retreiveVisitId.find(
                                                              v =>
                                                                v.id ===
                                                                row.retrieveVisitId
                                                            ).text
                                                          : ""
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", {}, [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "editProject modal-trigger projectAdd",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateEquipmentDetail(
                                                          subRow
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "link",
                                                      class: {
                                                        "ion-edit":
                                                          _vm.isEditable,
                                                        "ion-eye": !_vm.isEditable
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.isEditable
                                                  ? _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteEquipmentDetails(
                                                              subRow
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "link ion-ios-trash"
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            ]
                                          )
                                        ]
                                      })
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  {
                                    key: "addNew" + index,
                                    staticClass: "attachment child"
                                  },
                                  [
                                    _c("td", { attrs: { colspan: "12" } }, [
                                      _vm.isEditable
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-success",
                                              on: {
                                                click: function($event) {
                                                  return _vm.attachEquipment(
                                                    row
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "visitEquipTable-addEquipmentTo",
                                                      {
                                                        type:
                                                          row.equipment.type &&
                                                          _vm.displayOptionLabel(
                                                            row.equipment.type,
                                                            "EquipmentType",
                                                            "type"
                                                          ),
                                                        code: row.equipment.code
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]
              )
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "equipment-detail-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            delay: 100,
            width: "99%",
            height: "85%",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("equipment-detail-form", {
            attrs: {
              "is-editable": _vm.isEditable,
              "organization-id": _vm.organizationId,
              "equipment-option": _vm.equipmentListOption,
              options: _vm.options,
              "visit-id": _vm.visitId,
              "init-equipment-row": _vm.equipmentRow,
              "location-label": _vm.locationLabel,
              "is-full-form": _vm.showAllFieldsInForm
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("span", { staticClass: "ion-ios-home" })])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("span", { staticClass: "ion-android-attach" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }