<template>
    <div class="weather-modal">
        <div @click="closeForm" class="iconButton link">
            <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle">
            <h5>{{$t('weatherModal-title')}} <i class="fas fa-question-circle"  v-tooltip="$t('weatherSegment-tooltip')"></i> </h5>
        </div>
          <div class="modal-content padded40">
             <div v-show="loading" class="col m12 s12 centered loader-wrapper">
                  <div class="loading">
                    <pulse-loader :loading="true" color="#C94412" size="25px" :width="100" :height="50"></pulse-loader>
                  </div>
                </div>
              <div v-show="!loading">
              <div class="col m12 s12">
               <div class="station-btn-wrapper" v-if="totalStations">
                  <button @click="previousStation()" :disabled="currentStationIndex <= 0" class="btn btn-border"><span class="ion-ios-arrow-thin-left"></span> {{$t('common-previous')}}</button>
                  {{$t('weatherModal-stationOfStations', {num: (currentStationIndex + 1), total: totalStations})}}
                  <button @click="nextStation()" :disabled="currentStationIndex + 1 >= totalStations" class="btn btn-border">{{$t('common-next')}} <span class="ion-ios-arrow-thin-right"></span></button>
                  </div>
                  <location-map 
                    ref="map" 
                    :mapbox-classes="{'!h-80 !text-left': true}" 
                    v-bind:map-type="'weather'" 
                    v-bind:category-string="dateString+'&locationId='+locationId+'&projectId='+projectId" 
                    v-bind:site-location="{latitude: siteLatitude, longitude: siteLongitude}">
                  </location-map>
                </div>
              </div>
              <div v-if="currentStation" class="col m12">
                <h5>
                  <template v-if="currentStation.hourlyStationName">{{currentStation.hourlyStationName}}</template>
                  <template v-else-if="currentStation.dailyStationName">{{currentStation.dailyStationName}}</template>
                  <span v-else>{{$t('weather-data-unknown')}}</span>
                </h5>
                <table>
                  <tr>
                    <th colspan="2" class="font-bold text-base">Daily</th>
                    <th colspan="2" class="font-bold text-base">Hourly</th>
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.distanceFromStation')}}</th>
                    <td><template v-if="currentStation && currentStation.dailyStationDistance">{{currentStation.dailyStationDistance}}</template><template v-else>--</template></td>
                      <th>{{$t('weather-data.windSpeed')}}</th>
                    <td><template  v-if="currentStation && currentStation.hourlyWindSpeed">{{currentStation.hourlyWindSpeed}}</template><template v-else>--</template></td>
                    
                </tr>
                  <tr>
                    <th>{{$t('weather-data.stationElevation')}}</th>
                    <td><template v-if="currentStation && currentStation.dailyStationElevation">{{currentStation.dailyStationElevation}}</template><template v-else>--</template></td>
                    <th>{{$t('weather-data.temperature')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyTemperature">{{currentStation.hourlyTemperature}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.stationCoords')}}</th>
                    <td><template v-if="currentStation && (currentStation.dailyStationLatitude || currentStation.dailyStationLongitude)">{{currentStation.dailyStationLatitude}}, {{currentStation.dailyStationLongitude}}</template><template v-else>--</template></td>
                    <th>{{$t('weather-data.dewPoint')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyDewPoint">{{currentStation.hourlyDewPoint}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.mean')}}</th>
                    <td> <template v-if="currentStation && currentStation.dailyMeanTemp">{{currentStation.dailyMeanTemp}}</template><template v-else>--</template></td>

                    <th>{{$t('weather-data.humidity')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyHumidity">{{currentStation.hourlyHumidity}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                       <th>{{$t('weather-data.snowOnGround')}} </th>
                    <td> <template v-if="currentStation && currentStation.dailySnowOnGround">{{currentStation.dailySnowOnGround}}</template><template v-else>--</template></td>
                   
                    <th>{{$t('weather-data.pressure')}} </th>
                    <td><template v-if="currentStation && currentStation.hourlyPressure">{{currentStation.hourlyPressure}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                     <th>{{$t('weather-data.totalSnow')}}</th>
                    <td><template v-if="currentStation && currentStation.dailyTotalSnow">{{currentStation.dailyTotalSnow}}</template><template v-else>--</template></td>
                    
                    <th>{{$t('weather-data.windchill')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyWindChill">{{currentStation.hourlyWindChill}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.min')}} </th>
                    <td><template v-if="currentStation && currentStation.dailyMinTemp">{{currentStation.dailyMinTemp}}</template><template v-else>--</template></td>
                    <th>{{$t('weather-data.windDirection')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyWindDirection">{{currentStation.hourlyWindDirection}}</template><template v-else>--</template></td>
                    
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.max')}} </th>
                    <td> <template v-if="currentStation && currentStation.dailyMaxTemp">{{currentStation.dailyMaxTemp}}</template><template v-else>--</template></td>
                    <th>{{$t('weather-data.humidex')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyHumidex">{{currentStation.hourlyHumidex}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.precipitation')}}</th>
                    <td><template v-if="currentStation && currentStation.dailyPrecipitation">{{currentStation.dailyPrecipitation}}</template><template v-else>--</template></td>
                  <th>{{$t('weather-data.precipitation')}} </th>
                    <td><template v-if="currentStation && currentStation.hourlyPrecipitation">{{currentStation.hourlyPrecipitation}}</template><template v-else>--</template></td>
                  </tr>
                  <tr>
                    <th>{{$t('weather-data.totalRain')}} </th>
                    <td> <template v-if="currentStation && currentStation.dailyTotalRain">{{currentStation.dailyTotalRain}}</template><template v-else>--</template></td>
                   <th>{{$t('weather-data.visibility')}}</th>
                    <td><template v-if="currentStation && currentStation.hourlyVisibility">{{currentStation.hourlyVisibility}}</template><template v-else>--</template></td>
                  </tr>
                </table>
              </div>
        </div>
        </div>
</template>

<script>

import { eventBus } from '@/lib/eventbus';
import {API_URL} from '@/lib/common';
import MultiPointMapVue from '../mapGL/MultiPointMap';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading

export default({
  name: 'camera-tagging-form',
  props: ['iconsSrc', 'dateString', 'locationId', 'siteLatitude', 'siteLongitude', 'projectId'],
  components: {'location-map': MultiPointMapVue, 'pulse-loader': PulseLoader},
  created () {
    this.stations = new Map();
    // cache info for closest station
    eventBus.$on('station-update', async (updateObj) => {
      this.currentStationIndex = updateObj.index;
      if (this.stations.has(updateObj.id)) {
        this.currentStation = this.stations.get(updateObj.id);
      } else {
        await this.getMoreStationInfo(updateObj.id);
      }
    });

    eventBus.$on('stations-loaded', (ids) => {
      // get the first station's info
      this.totalStations = ids.length;
      this.getMoreStationInfo(ids[0]);
    });
  },
  methods: {
    closeForm () {
      eventBus.$emit('close-weather-modal');
    },
    async getMoreStationInfo (id) {
      // if we havent cached this station before, call the api
      this.loading = true;
      return this.$http.get(this.getStationDataByClimateIdUrl + this.dateString + '&locationId=' + this.locationId + '&climateId=' + id).then((response) => {
        this.loading = false;
        if (response.body.hasOwnProperty('error')) {
          this.currentStation = null;
        } else {
          this.stations.set(id, response.body);
          this.currentStation = response.body;
        }
      }, (err) => {
        // error callback
        this.loading = false;
        console.error(err);
        this.currentStation = null;
      });
    },
    async nextStation () {
      if (this.currentStationIndex + 1 >= this.totalStations) {
        return;
      }
      this.currentStationIndex++;
      let id = this.$refs.map.getNextStation();
      if (this.stations.has(id)) {
        this.currentStation = this.stations.get(id);
      } else {
        await this.getMoreStationInfo(id);
      }
    },
    async previousStation () {
      if (this.currentStationIndex + 1 <= 0) {
        return;
      }
      this.currentStationIndex--;
      let id = this.$refs.map.getPreviousStation();
      if (this.stations.has(id)) {
        this.currentStation = this.stations.get(id);
      } else {
        await this.getMoreStationInfo(id);
      }
    }
  },
  data () {
    return {
      getStationDataByClimateIdUrl: API_URL + 'get-weather-station-data-for-climate?date=',
      stations: null, // (key: climateId, value: weather data)
      currentStation: null,
      totalStations: null,
      currentStationIndex: 0,
      loading: false
    }
  }
})
</script>

<style scoped>
td,th {
  padding: 5px 5px;
}
.hourly-condition-wrapper{
  text-align: center;
  color: #C94412;
  height: 75px;
}

.hourly-condition-wrapper .weather-icon {
  height: 35px;
}

.weather-item{
    color: #C94412;
    margin-left: 10px;
}

.weather-item .data{
  color: #1369c5;
  float: right;
  margin-right: 10px;
}

.weather-col h6{
  text-align: center;
}

.station-btn-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.loader-wrapper{
margin-top: 10px;
}
</style>
