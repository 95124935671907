var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue org-admin" },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.error.statusText) + "\n    ")
                ])
              : _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "mainPanel col l12" }, [
              _c("div", { staticClass: "container large userPanel" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col s12 m12" }, [
                    _c("p", [
                      _c("a", { attrs: { href: _vm.breadcrumbHomeLink() } }, [
                        _vm._v(_vm._s(_vm.$t("common-home")))
                      ]),
                      _vm._v(" / "),
                      _c(
                        "a",
                        { attrs: { href: _vm.organziationListPageUrl } },
                        [_vm._v(_vm._s(_vm.$t("org-myOrganizations")))]
                      ),
                      _vm._v(" /")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l7" }, [
                      _vm.showOrganizationEditButton
                        ? _c(
                            "a",
                            {
                              staticClass: "link",
                              on: {
                                click: function($event) {
                                  return _vm.updateOrganization()
                                }
                              }
                            },
                            [
                              _c("h4", { staticStyle: { display: "inline" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectedOrganization &&
                                      _vm.selectedOrganization.name
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", {
                                staticClass: "link ion-edit edit-project-icon"
                              })
                            ]
                          )
                        : _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm.selectedOrganization &&
                                  _vm.selectedOrganization.name
                              )
                            )
                          ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col s12 m6 l5 pull-right" },
                      [
                        _vm.organizationCount > 1 &&
                        _vm.organizationList != null
                          ? _c(
                              "multiselect",
                              {
                                attrs: {
                                  selectAllLabel: _vm.$t("common-selectAll"),
                                  "close-on-select": true,
                                  allowEmpty: false,
                                  placeholder: _vm.$t(
                                    "orgAdmin-typeToSearchOrganizations"
                                  ),
                                  label: "name",
                                  "track-by": "id",
                                  options: _vm.organizationList,
                                  multiple: false
                                },
                                on: { select: _vm.switchOrganization },
                                model: {
                                  value: _vm.selectedOrganization,
                                  callback: function($$v) {
                                    _vm.selectedOrganization = $$v
                                  },
                                  expression: "selectedOrganization"
                                }
                              },
                              [
                                _c("template", { slot: "singleLabel" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("org-switchOrganization")) +
                                      "\n                "
                                  )
                                ])
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "container overflow tabbed" },
                [
                  _c(
                    "ul",
                    { staticClass: "tabs" },
                    [
                      _vm._l(_vm.tabs, function(tab, index) {
                        return [
                          _vm.checkTabExists(tab)
                            ? _c(
                                "li",
                                {
                                  key: "tab" + index,
                                  staticClass: "tab",
                                  class: { [tab.class]: true },
                                  on: {
                                    click: function($event) {
                                      return _vm.switchTab(tab)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "hyperlink",
                                      class: {
                                        active: _vm.selectedTab == tab.id,
                                        [tab.id]: true
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            tab &&
                                              tab.id &&
                                              _vm.$tc(`common-${tab.id}`, 2)
                                          ) +
                                          " "
                                      ),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.left-start",
                                            value: {
                                              content: _vm.$tc(
                                                `orgAdmin-tooltips.${tab.id}`
                                              ),
                                              hideOnTargetClick: true,
                                              popperOptions: {
                                                flip: { enabled: false }
                                              }
                                            },
                                            expression:
                                              "{ content: $tc(`orgAdmin-tooltips.${tab.id}`), hideOnTargetClick: true, popperOptions: { flip: { enabled: false } } }",
                                            modifiers: { "left-start": true }
                                          }
                                        ],
                                        staticClass:
                                          "info-icon ion-information-circled",
                                        staticStyle: { "font-size": "12px" }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.selectedTab == "equipment" && _vm.selectedOrganization
                    ? _c("equipment-grid", {
                        attrs: {
                          "organization-id": _vm.selectedOrganization.id,
                          "organization-name": _vm.selectedOrganization.name,
                          "is-editable": _vm.showOrganizationEditButton
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedTab == "location" && _vm.selectedOrganization
                    ? _c("location-table", {
                        attrs: {
                          "missing-count": _vm.missingCorrdinateCount,
                          "organization-obj": _vm.selectedOrganization,
                          "organization-id": _vm.selectedOrganization.id,
                          "is-editable": _vm.showOrganizationEditButton
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedTab == "visit" && _vm.selectedOrganization
                    ? _c("location-visit", {
                        attrs: {
                          "organization-id": _vm.selectedOrganization.id,
                          "organization-name": _vm.selectedOrganization.name,
                          "is-editable": _vm.showOrganizationEditButton
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedTab == "recording" &&
                  _vm.showRecordings &&
                  _vm.selectedOrganization
                    ? _c("recording-table", {
                        attrs: {
                          "organization-id": _vm.selectedOrganization.id,
                          "organization-name": _vm.selectedOrganization.name,
                          "is-editable": _vm.showOrganizationEditButton,
                          "show-delete-media-button": _vm.showDeleteMediaButton
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedTab == "cameraImageSet" &&
                  _vm.showImageDeployments &&
                  _vm.selectedOrganization
                    ? _c("image-table", {
                        attrs: {
                          "organization-id": _vm.selectedOrganization.id,
                          "is-editable": _vm.showOrganizationEditButton,
                          "show-delete-media-button": _vm.showDeleteMediaButton
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedTab == "map" && _vm.selectedOrganization
                    ? _c(
                        "div",
                        { staticClass: "panel padded20 boxed" },
                        [
                          _c("location-map", {
                            attrs: {
                              "category-id": _vm.selectedOrganization.id
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showForm && _vm.showOrganizationEditButton && _vm.options != null
        ? _c("organization-form", {
            ref: "organizationForm",
            attrs: {
              "organization-id": _vm.editOrganziationId,
              "is-editable": _vm.showOrganizationEditButton,
              options: _vm.options,
              "is-global-admin": _vm.isGlobalAdmin
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }