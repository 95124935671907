var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "org-csv-upload" } }, [
    _c(
      "div",
      { staticClass: "iconButton link", on: { click: _vm.closeForm } },
      [_c("span", { staticClass: "ion-close" })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-header drag-handle" }, [
      _c(
        "h6",
        [
          _vm.type === "location"
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("orgCSVUpload-locationTitle", {
                      organizationName: _vm.organizationName
                    })
                  )
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "project-location"
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("orgCSVUpload-locationTitle", {
                      organizationName: _vm.project.fullNm
                    })
                  )
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "equipment"
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("orgCSVUpload-equipmentTitle", {
                      organizationName: _vm.organizationName
                    })
                  )
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "visits"
            ? [
                _vm._v(
                  _vm._s(
                    _vm.$t("orgCSVUpload-visitTitle", {
                      organizationName: _vm.organizationName
                    })
                  )
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-content container csvUpload" },
      [
        _c("div", { staticClass: "flex-row" }, [
          _c(
            "span",
            {
              staticClass: "link",
              class: {
                "ion-arrow-expand": !_vm.showSettings,
                "ion-arrow-shrink": _vm.showSettings
              },
              on: {
                click: function($event) {
                  _vm.showSettings = !_vm.showSettings
                }
              }
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.showSettings
                      ? _vm.$t("common-hide")
                      : _vm.$t("common-show")
                  ) +
                  " " +
                  _vm._s(_vm.$t("common-fileUpload")) +
                  "\n        "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSettings,
                expression: "showSettings"
              }
            ],
            attrs: { id: "upload-box" }
          },
          [
            _c(
              "div",
              { staticClass: "padded20 center" },
              [
                _vm.type === "location"
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("orgCSVUpload-locationInstructions", {
                            organizationName: _vm.organizationName
                          })
                        )
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "project-location"
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("csvUpload-subHeaderLocations", {
                            projectName: _vm.project.fullNm
                          })
                        )
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "equipment"
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("orgCSVUpload-equipmentInstructions", {
                            organizationName: _vm.organizationName
                          })
                        )
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "visits"
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.$t("orgCSVUpload-visitInstructions", {
                            organizationName: _vm.organizationName
                          })
                        )
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s1 m3 xl4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col s5 m5 xl3" }, [
                _c("input", {
                  ref: "chooseFile",
                  staticClass: "form-control hidden file-chooser",
                  attrs: {
                    type: "file",
                    id: "chooseFile",
                    name: "equipCSV",
                    accept: ".csv"
                  },
                  on: { change: _vm.chooseFile }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    staticStyle: { width: "100%" },
                    attrs: { type: "button", id: "browseButton" },
                    on: {
                      click: function($event) {
                        return _vm.clickChooseFile()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-refresh" }),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.csvUpload,
                          expression: "tooltips.csvUpload"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("common-csvUpload")) +
                        "\n            "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "baseline-wrapper col s3 m2 xl3" }, [
                _vm.selectedFile
                  ? _c("span", { staticClass: "baseliner" }, [
                      _vm._v(_vm._s(_vm.selectedFile.name))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s1 m2 xl2" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s1 m3 xl4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col s5 m3 xl3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-border btn-success",
                    attrs: {
                      type: "button",
                      id: "submitButton",
                      disabled: _vm.selectedFile == null
                    },
                    on: { click: _vm.previewFile }
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("orgCSVUpload-previewChanges")) +
                        "\n            "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s6 m6 xl5" })
            ])
          ]
        ),
        _vm._v(" "),
        _vm.message
          ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.message))])
          : _vm._e(),
        _vm._v(" "),
        _vm.previewLoading
          ? _c("pulse-loader", {
              staticClass: "preview-pulse",
              attrs: {
                loading: true,
                color: "#C94412",
                size: "20px",
                width: 400,
                height: 400
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showPreview && !_vm.message
          ? [
              _vm.tableData.length === 0 &&
              !_vm.finishedUpload &&
              !_vm.previewLoading &&
              !_vm.uploading
                ? _c("div", [_vm._v(_vm._s(_vm.$t("orgCSVUpload-noChanges")))])
                : _c(
                    "div",
                    {
                      staticClass: "orange-border padded40 bottom-margin",
                      attrs: { id: "csv-upload" }
                    },
                    [
                      _vm.tableData.length > 0 &&
                      !_vm.uploading &&
                      !_vm.finishedUpload
                        ? _c("v-client-table", {
                            attrs: {
                              data: _vm.tableData,
                              columns: _vm.columns,
                              options: _vm.tableOptions
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function(props) {
                                    return [
                                      _c("span", {
                                        staticClass: "info-icon",
                                        class: {
                                          "ion-android-warning":
                                            props.row.isError === true,
                                          "ion-information-circled":
                                            props.row.isError === false
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "action",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: props.row.reasoning,
                                            expression: "props.row.reasoning"
                                          }
                                        ],
                                        staticClass: "info-icon",
                                        class: {
                                          "ion-android-warning":
                                            props.row.action === -1,
                                          "ion-information-circled":
                                            props.row.action !== -1
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.uploadOptions.get(
                                              props.row.action
                                            ).type
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "visibilityId",
                                  fn: function(props) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _vm._l(_vm.visibilityOptions, function(
                                          option
                                        ) {
                                          return [
                                            props.row.visibilityId === option.id
                                              ? [_vm._v(_vm._s(option.type))]
                                              : _vm._e()
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              3592702748
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploading
                        ? [
                            _c("pulse-loader", {
                              staticClass: "preview-pulse",
                              attrs: {
                                loading: true,
                                color: "#C94412",
                                size: "20px",
                                width: 400,
                                height: 400
                              }
                            }),
                            _vm._v(" "),
                            _vm.type === "visits"
                              ? _c("div", { staticClass: "progress-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("common-uploading").toLowerCase()
                                    ) + "..."
                                  )
                                ])
                              : _c("div", { staticClass: "progress-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("orgCSVUpload-updatedProgress", {
                                        num: _vm.numSuccess,
                                        total: _vm.totalRows
                                      })
                                    )
                                  )
                                ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.type !== "visits" &&
                      _vm.uploadErrors.length > 0 &&
                      _vm.finishedUpload
                        ? _c("v-client-table", {
                            attrs: {
                              data: _vm.uploadErrors,
                              columns: _vm.columns,
                              options: _vm.tableOptions
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "action",
                                  fn: function(props) {
                                    return _c("div", {}, [
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: props.row.error,
                                            expression: "props.row.error"
                                          }
                                        ],
                                        staticClass:
                                          "info-icon ion-android-warning error"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "orgCSVUpload-errorUploading"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "visibilityId",
                                  fn: function(props) {
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _vm._l(_vm.visibilityOptions, function(
                                          option
                                        ) {
                                          return [
                                            props.row.visibilityId === option.id
                                              ? [_vm._v(_vm._s(option.type))]
                                              : _vm._e()
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              1553105118
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row no-bottom-margin" },
                        [
                          _c("div", { staticClass: "finishMsg" }, [
                            _vm.finishedUpload && !_vm.entireFileUploadStatus
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("orgCSVUpload-numUpdated", {
                                        num: _vm.numSuccess
                                      })
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.finishedUpload &&
                            !_vm.entireFileUploadStatus &&
                            _vm.uploadErrors.length > 0
                              ? _c("span", { staticClass: "error" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tc(
                                        "common-numErrors",
                                        _vm.uploadErrors.length,
                                        { num: _vm.uploadErrors.length }
                                      )
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.finishedUpload &&
                            _vm.entireFileUploadStatus != null
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      error:
                                        _vm.entireFileUploadStatus.success ===
                                        false
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.entireFileUploadStatus.message)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.tableData.length > 0
                            ? [
                                _vm.hasPreviewErrors
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.$t("orgCSVUpload-hasErrors"))
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.finishedUpload
                                  ? _c("div", { staticClass: "col" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-border btn-success",
                                          attrs: {
                                            type: "button",
                                            disabled:
                                              _vm.hasPreviewErrors ||
                                              _vm.uploadErrors.length > 0 ||
                                              _vm.uploading,
                                            id: "uploadButton"
                                          },
                                          on: { click: _vm.uploadFile }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "orgCSVUpload-applyChanges"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "col" }, [
                                  !_vm.uploading && !_vm.finishedUpload
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-border btn-success",
                                          attrs: {
                                            type: "button",
                                            id: "cancelButton"
                                          },
                                          on: { click: _vm.cancelUpload }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("common-cancel")) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.finishedUpload
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-border btn-success",
                                          attrs: { type: "button" },
                                          on: { click: _vm.closeForm }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("common-close")) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    2
                  )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }