var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "deployment-table" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "overaly",
        staticStyle: {
          opacity: "0.6",
          width: "100%",
          height: "100%",
          "z-index": "100",
          background: "white"
        }
      },
      [
        _c(
          "div",
          { staticClass: "my-20", staticStyle: { "text-align": "center" } },
          [
            _c("pulse-loader", {
              attrs: {
                loading: true,
                color: "#C94412",
                size: "20px",
                width: 400,
                height: 400
              }
            }),
            _vm._v(" "),
            _c("div", [_vm._v(" " + _vm._s(_vm.$t("common-loading")) + " ")])
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }
        ]
      },
      [
        _vm.isManager &&
        !(_vm.statusId === 3 || _vm.statusId === 1 || _vm.statusId === 4)
          ? _c(
              "div",
              { staticClass: "row col s12 m9 l8 xl6 left" },
              [
                _c("multiselect", {
                  staticClass: "tagger-select",
                  attrs: {
                    selectAllLabel: _vm.$t("common-selectAll"),
                    options: _vm.userList,
                    multiple: true,
                    "close-on-select": false,
                    placeholder: _vm.$t("taskTable-selectValidatorsToAssign"),
                    label: "text",
                    "track-by": "id",
                    selectLabel: "",
                    deselectLabel: "",
                    showSelectAll: true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function({ values, search, isOpen }) {
                          return [
                            values.length && values.length > 1 && !isOpen
                              ? _c(
                                  "span",
                                  { staticClass: "multiselect__single" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("taskTable-validatorsSelected", {
                                          num: values.length
                                        })
                                      )
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            values.length && values.length == 1 && !isOpen
                              ? _c(
                                  "span",
                                  { staticClass: "multiselect__single" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("taskTable-singleValidator")
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    818355069
                  ),
                  model: {
                    value: _vm.randomAssignUsers,
                    callback: function($$v) {
                      _vm.randomAssignUsers = $$v
                    },
                    expression: "randomAssignUsers"
                  }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btnimportant btn-success right assign-btn",
                    class: { downloading: _vm.randomAssignLoading },
                    on: { click: _vm.randomAssign }
                  },
                  [
                    _c("span", { staticClass: "spin-icon" }),
                    _vm._v(_vm._s(_vm.$t("taskTable-randomAssign")))
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "error w-full" }, [
          _vm._v(_vm._s(_vm.message))
        ]),
        _vm._v(" "),
        _c(
          "v-server-table",
          {
            ref: "table",
            staticClass: "table w-full vue-bordered speciesTable",
            attrs: {
              url: _vm.url,
              columns: _vm.columns,
              options: _vm.tableOptions
            },
            on: {
              loading: function($event) {
                _vm.loading = true
              },
              loaded: function($event) {
                _vm.loading = false
              },
              "row-click": _vm.onRowClick
            },
            scopedSlots: _vm._u([
              {
                key: "speciesCode",
                fn: function(props) {
                  return [
                    _c(
                      "a",
                      { attrs: { href: _vm.recordingRowUrl(props.row) } },
                      [
                        _vm.species
                          ? _c("span", {
                              staticClass: "indicator",
                              class: _vm.getRowClass(props.row.status)
                            })
                          : _vm._e(),
                        _vm._v(
                          _vm._s(
                            _vm.species.length > 0 &&
                              _vm.species.find(item => {
                                return item.id == props.row.speciesId
                              }).code
                          ) + " "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "vocalization",
                fn: function(props) {
                  return [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.taskOptions &&
                            _vm.taskOptions.vocalization &&
                            _vm.taskOptions.vocalization.find(
                              e => e.id == props.row.vocalizationId
                            ) &&
                            _vm.taskOptions.vocalization.find(
                              e => e.id == props.row.vocalizationId
                            ).type
                        ) +
                        "\n    "
                    )
                  ]
                }
              },
              {
                key: "verifiedCount",
                fn: function(props) {
                  return [
                    _vm._v(
                      "\n      " + _vm._s(props.row.verifiedCount) + "\n    "
                    )
                  ]
                }
              },
              {
                key: "totalCount",
                fn: function(props) {
                  return [
                    _vm._v(
                      "\n         " + _vm._s(props.row.totalCount) + "\n    "
                    )
                  ]
                }
              },
              {
                key: "assignedUserId",
                fn: function(props) {
                  return [
                    _vm.isManager
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: props.row.assignedUserId,
                                expression: "props.row.assignedUserId"
                              }
                            ],
                            on: {
                              focus: _vm.clearMessage,
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    props.row,
                                    "assignedUserId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function($event) {
                                  return _vm.updateUser(
                                    props.row.id,
                                    props.row.assignedUserId,
                                    $event
                                  )
                                }
                              ]
                            }
                          },
                          [
                            _c("option", { attrs: { value: "-1" } }, [
                              _vm._v(
                                " -- " +
                                  _vm._s(_vm.$t("taskTable-notAssigned")) +
                                  " -- "
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.userList, function(user, index) {
                              return _c(
                                "option",
                                {
                                  key: "user" + index,
                                  domProps: { value: user.id }
                                },
                                [_vm._v(" " + _vm._s(user.text))]
                              )
                            })
                          ],
                          2
                        )
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getUserName(props.row.assignedUserId)
                              ) +
                              "\n        "
                          )
                        ])
                  ]
                }
              }
            ])
          },
          [
            _c("template", { slot: "h__pendingNightlyBatchCount" }, [
              _vm._v(
                "\n       " +
                  _vm._s(
                    _vm.$t("taskTable-SPPheadings.pendingNightlyBatchCount")
                  ) +
                  "\n       "
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.$t(
                        "taskTable-SPPheadings.pendingNightlyBatchCount-tooltip"
                      ),
                      expression:
                        "$t('taskTable-SPPheadings.pendingNightlyBatchCount-tooltip')"
                    }
                  ]
                },
                [_c("i", { staticClass: "fas fa-info-circle text-yellow-400" })]
              )
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }