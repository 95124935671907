import {API_URL} from '@/lib/common';
import axios from 'axios';

const state = {
  sensorCodes: [],
  mapPoint: [],
  projectTableData: [],
  organization: [],	// options for selection
  organizationInfo: {}, // all organziations for the user won't referesh for the page
  organizationList: [] // organziation current included in the map, updated at each query
};

const actions = {
  async loadOrganization ({commit}) {
    if (!state.organization || state.organization.length === 0) { // list or organziaiton will be static, don't load mutliple times
      const response = await axios.post(API_URL + 'get-viewable-datadisc-organizations');
      commit('updateOrganization', response.data || []);
    }
  },
  queryLocations ({commit}, parameters) {
    commit('updateSensorCodes', parameters.sensors);
    commit('updateQueryResult', parameters.data);
  }
};
const getters = {
  organization: state => state.organization,
  mapPoint: state => state.mapPoint,
  projectTableData: state => state.projectTableData,
  organizationInfo: state => state.organizationInfo,
  organizationList: state => state.organizationList,
  sensorCodes: state => state.sensorCodes
};
const mutations = {
  updateOrganization (state, list) {
    state.organization = list;
    const colorCodes = ['00', '33', '66', '99', 'CC'];
    let colors = [];
    colorCodes.forEach(r => {
      colorCodes.forEach(g => {
        colorCodes.forEach(b => {
          if (!(r === g && g === b)) { // exclude grey color as it is the map background color
            colors.push('#' + r + g + b);
          }
        })
      })
    });
    state.organizationInfo = {};
    const favorColors = [...colors];
    list.forEach(x => {
      const randomIndex = Math.floor(Math.random() * favorColors.length);
      const pickedColor = favorColors.splice(randomIndex, 1)
      state.organizationInfo[x.id] = { color: pickedColor[0], name: x.name };
    });
  },
  updateSensorCodes (state, codes) {
    state.sensorCodes = codes;
  },
  updateQueryResult (state, data) {
    state.mapPoint = data.map || {};
    state.projectTableData = data.projects || [];
    state.organizationList = data.map && data.map.features && ([...new Set(data.map.features.map(item => { return item.properties.organizationId; }))] || []);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
