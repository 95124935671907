var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "batchUploadForm" },
    [
      _c(
        "modal",
        {
          attrs: {
            name: "upload-audio-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form !overflow-y-auto relative",
            draggable: "false",
            delay: 100,
            width: "90%",
            height: "auto",
            "max-width": 1500,
            "min-width": 200,
            "click-to-close": false
          }
        },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "iconButton link",
                on: {
                  click: () => {
                    if (
                      !(
                        !_vm.batchUpload.status.allUploaded &&
                        this.stepState == 5
                      )
                    ) {
                      _vm.resetBatchUpload()
                      _vm.closeForm()
                      _vm.stepState = 1
                    }
                  }
                }
              },
              [_c("span", { staticClass: "ion-close" })]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "modal-content px-10 !overflow-y-auto" },
                [
                  _vm.projectId
                    ? _c("h4", [
                        _c("i", { staticClass: "fas fa-upload" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("audioUploadForm-title")) +
                            " " +
                            _vm._s(_vm.projectName)
                        )
                      ])
                    : _c("h4", [
                        _c("i", { staticClass: "fas fa-upload" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("Upload To")) +
                            " " +
                            _vm._s(_vm.organizationName)
                        )
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-20" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.disableFirstScreen,
                            expression: "disableFirstScreen"
                          }
                        ],
                        staticClass:
                          "w-full bg-white/75 backdrop-opacity-10 z-50"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "my-[150px]",
                            staticStyle: { "text-align": "center" }
                          },
                          [
                            _c("pulse-loader", {
                              attrs: {
                                loading: true,
                                color: "#C94412",
                                size: "20px",
                                width: 400,
                                height: 400
                              }
                            }),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("common-tableTexts.loading")) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dataGroup" }, [
                      _vm.stepState == 1
                        ? _c(
                            "form",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.batchUpload.status.uploading &&
                                    !_vm.disableFirstScreen,
                                  expression:
                                    "!batchUpload.status.uploading && !disableFirstScreen"
                                }
                              ],
                              staticClass: "container-fluid",
                              attrs: {
                                method: "GET",
                                role: "form",
                                enctype: "multipart/form-data",
                                id: "batchForm"
                              }
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  [
                                    _vm._l(_vm.message, function(msg, index) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: "msg" + index,
                                            staticClass: "error"
                                          },
                                          [_vm._v(" " + _vm._s(msg))]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "row no-margin-bototm" },
                                  [
                                    _c("h5", [
                                      _vm._v(_vm._s(_vm.$t("Upload Settings")))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col s12 xl12 marginTop10"
                                      },
                                      [
                                        _c("label", { staticClass: "block" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.batchUpload.settings
                                                    .includeSubFolder,
                                                expression:
                                                  "batchUpload.settings.includeSubFolder"
                                              }
                                            ],
                                            staticClass: "exclusive checkbox",
                                            attrs: {
                                              type: "checkbox",
                                              id: "settings-includeSubFolder"
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.batchUpload.settings
                                                  .includeSubFolder
                                              )
                                                ? _vm._i(
                                                    _vm.batchUpload.settings
                                                      .includeSubFolder,
                                                    null
                                                  ) > -1
                                                : _vm.batchUpload.settings
                                                    .includeSubFolder
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.batchUpload.settings
                                                      .includeSubFolder,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "includeSubFolder",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "includeSubFolder",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.batchUpload.settings,
                                                    "includeSubFolder",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "control-label col-md-2 col-sm-3",
                                                attrs: {
                                                  for:
                                                    "settings-includeSubFolder"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "audioUploadForm-fields.subfolders"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value:
                                                    _vm.tooltips
                                                      .includeSubFolder,
                                                  expression:
                                                    "tooltips.includeSubFolder"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            })
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("label", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.batchUpload.settings
                                                    .removeLeadingZeros,
                                                expression:
                                                  "batchUpload.settings.removeLeadingZeros"
                                              }
                                            ],
                                            staticClass: "exclusive checkbox",
                                            attrs: {
                                              type: "checkbox",
                                              id: "settings-removeLeadingZeros"
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.batchUpload.settings
                                                  .removeLeadingZeros
                                              )
                                                ? _vm._i(
                                                    _vm.batchUpload.settings
                                                      .removeLeadingZeros,
                                                    null
                                                  ) > -1
                                                : _vm.batchUpload.settings
                                                    .removeLeadingZeros
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.batchUpload.settings
                                                      .removeLeadingZeros,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "removeLeadingZeros",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "removeLeadingZeros",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.batchUpload.settings,
                                                    "removeLeadingZeros",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "control-label col-md-2 col-sm-3",
                                                attrs: {
                                                  for:
                                                    "settings-removeLeadingZeros"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "audioUploadForm-fields.leadingZeros"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value:
                                                    _vm.tooltips
                                                      .removeLeadingZeros,
                                                  expression:
                                                    "tooltips.removeLeadingZeros"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            })
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("label", { staticClass: "block" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.batchUpload.settings
                                                    .isTriggered,
                                                expression:
                                                  "batchUpload.settings.isTriggered"
                                              }
                                            ],
                                            staticClass: "exclusive checkbox",
                                            attrs: {
                                              type: "checkbox",
                                              id: "settings-isTriggered"
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.batchUpload.settings
                                                  .isTriggered
                                              )
                                                ? _vm._i(
                                                    _vm.batchUpload.settings
                                                      .isTriggered,
                                                    null
                                                  ) > -1
                                                : _vm.batchUpload.settings
                                                    .isTriggered
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.batchUpload.settings
                                                      .isTriggered,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "isTriggered",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.batchUpload
                                                          .settings,
                                                        "isTriggered",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.batchUpload.settings,
                                                    "isTriggered",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "control-label col-md-2 col-sm-3",
                                                attrs: {
                                                  for: "settings-isTriggered"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Mark uploads as triggered"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.$t(
                                                    "Mark all uploaded files as being manually triggered rather than scheduled."
                                                  ),
                                                  expression:
                                                    "$t('Mark all uploaded files as being manually triggered rather than scheduled.')"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            })
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("label", { staticClass: "block" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.prescan,
                                                expression: "prescan"
                                              }
                                            ],
                                            staticClass: "exclusive checkbox",
                                            attrs: {
                                              type: "checkbox",
                                              id: "settings-isTriggered"
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.prescan
                                              )
                                                ? _vm._i(_vm.prescan, null) > -1
                                                : _vm.prescan
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a = _vm.prescan,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.prescan = $$a.concat(
                                                        [$$v]
                                                      ))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.prescan = $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        ))
                                                  }
                                                } else {
                                                  _vm.prescan = $$c
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "control-label col-md-2 col-sm-3",
                                                attrs: {
                                                  for: "settings-isTriggered"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Prescan files for Sample Rate and Duration."
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.$t(
                                                    "Uncheck this box if the initial file load is taking too long. This will disable autofill of Task Length and Sample Rate."
                                                  ),
                                                  expression:
                                                    "$t('Uncheck this box if the initial file load is taking too long. This will disable autofill of Task Length and Sample Rate.')"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            })
                                          ])
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "col s6 xl6 marginTop10"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.projectId
                                  ? _c("div", { staticClass: "row" }, [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Task Settings")) + " "
                                        ),
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: _vm.$t(
                                                "Choose the default settings for your recording upload. You can start over or change these settings later during upload."
                                              ),
                                              expression:
                                                "$t('Choose the default settings for your recording upload. You can start over or change these settings later during upload.')"
                                            }
                                          ],
                                          staticClass:
                                            "info-icon ion-information-circled"
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid grid-cols-2 gap-2 items-center"
                                        },
                                        [
                                          _c("label", {}, [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "control-label",
                                                attrs: {
                                                  for:
                                                    "settings-defaultMethodId"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Task Length")
                                                  ) +
                                                    "\n                          "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value: _vm.$t(
                                                          "WildTrax will upload and store the entire length of the recording in the organization but will only generate a task to the length you choose for this project. If left blank, the entire recording length will be used."
                                                        ),
                                                        expression:
                                                          "$t('WildTrax will upload and store the entire length of the recording in the organization but will only generate a task to the length you choose for this project. If left blank, the entire recording length will be used.')"
                                                      }
                                                    ]
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-info-circle text-yellow-400"
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: false,
                                                      expression: "false"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.tooltips
                                                          .defaultMethodId,
                                                      expression:
                                                        "tooltips.defaultMethodId"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "info-icon ion-information-circled"
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.batchUpload.settings
                                                      .taskLength,
                                                  expression:
                                                    "batchUpload.settings.taskLength"
                                                }
                                              ],
                                              class: {
                                                "!bg-gray-100":
                                                  _vm.batchUpload.settings
                                                    .recordingLength
                                              },
                                              attrs: {
                                                disabled:
                                                  _vm.batchUpload.settings
                                                    .recordingLength,
                                                type: "number",
                                                min: "1",
                                                max: "600",
                                                placeholder: _vm.$t(
                                                  "If left blank, the recording length will be used"
                                                )
                                              },
                                              domProps: {
                                                value:
                                                  _vm.batchUpload.settings
                                                    .taskLength
                                              },
                                              on: {
                                                keyup: e => {
                                                  if (
                                                    e.target.value < 1 ||
                                                    e.target.value > 600
                                                  )
                                                    e.target.value = ""
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.batchUpload.settings,
                                                    "taskLength",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c("label", {}, [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "control-label",
                                                attrs: {
                                                  for:
                                                    "settings-defaultMethodId"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Method Type")
                                                  ) +
                                                    "\n                          "
                                                ),
                                                _c("span", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: false,
                                                      expression: "false"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.tooltips
                                                          .defaultMethodId,
                                                      expression:
                                                        "tooltips.defaultMethodId"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "info-icon ion-information-circled"
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.batchUpload.settings
                                                        .defaultMethodId,
                                                    expression:
                                                      "batchUpload.settings.defaultMethodId"
                                                  }
                                                ],
                                                staticStyle: { height: "40px" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.batchUpload.settings,
                                                      "defaultMethodId",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "8" } },
                                                  [
                                                    _vm._v(
                                                      "-- " +
                                                        _vm._s(
                                                          _vm.$t("Select One")
                                                        ) +
                                                        " --"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.methodOption,
                                                  function(opt, optIndex) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value: !opt.deprecated,
                                                            expression:
                                                              "!opt.deprecated"
                                                          }
                                                        ],
                                                        key:
                                                          "default-method" +
                                                          optIndex,
                                                        domProps: {
                                                          value: opt.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " + _vm._s(opt.type)
                                                        )
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid grid-cols-2 gap-2 items-center"
                                    },
                                    [
                                      _c("h5", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "audioUploadForm-titles.chooseFiles"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "h6",
                                        {
                                          on: {
                                            click: () => {
                                              _vm.ui.states.methodHelpDropdown.visible = !_vm
                                                .ui.states.methodHelpDropdown
                                                .visible
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "Which method should I use?"
                                              )
                                            ) + " "
                                          ),
                                          _vm.ui.states.methodHelpDropdown
                                            .visible
                                            ? _c("i", {
                                                staticClass:
                                                  "fa-solid fa-caret-down"
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "fa-solid fa-caret-right"
                                              })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid grid-cols-2 gap-2 items-start"
                                    },
                                    [
                                      _c("div", {}, [
                                        _c("input", {
                                          ref: "chooseFolder",
                                          staticClass:
                                            "form-control hidden file-chooser",
                                          attrs: {
                                            type: "file",
                                            id: "chooseFolder",
                                            name: "picture",
                                            accept: "image/*",
                                            webkitdirectory: "true",
                                            multiple: "true"
                                          },
                                          on: { change: _vm.browseFolder }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-default",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "button",
                                              id: "browseButton",
                                              disabled: _vm.disableBrowseButton
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.clickBrowseFolder()
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "glyphicon glyphicon-refresh"
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value:
                                                    _vm.tooltips
                                                      .audioFileFormat,
                                                  expression:
                                                    "tooltips.audioFileFormat"
                                                }
                                              ],
                                              staticClass:
                                                "info-icon ion-information-circled"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common-folderBtn")
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.selectedFiles.length > 0 &&
                                        _vm.selectedFiles.length <
                                          _vm.totalFiles
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "border h-12 relative border-gray-300"
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "bar h-full bg-blue-100",
                                                  style: {
                                                    width: `${(_vm.selectedFiles
                                                      .length /
                                                      _vm.totalFiles) *
                                                      100}%`
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "absolute bottom-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Loading")
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.selectedFiles
                                                            .length
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$tc(
                                                            "common-file",
                                                            _vm.selectedFiles
                                                              .length
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.selectedFiles.length ===
                                          _vm.totalFiles && _vm.totalFiles > 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(_vm.$t("Loaded")) +
                                                  " " +
                                                  _vm._s(_vm.totalFiles) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "common-file",
                                                      _vm.selectedFiles.length
                                                    )
                                                  ) +
                                                  "\n                          "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.invalidFilesLog.length > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "text-red-500" },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "There were issues with {number} files.",
                                                        {
                                                          number:
                                                            _vm.invalidFilesLog
                                                              .length
                                                        }
                                                      )
                                                    ) +
                                                    "\n                            "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer text-blue-500",
                                                    on: {
                                                      click:
                                                        _vm.showInvalidFilesAlert
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Click here to see the log."
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {}, [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.ui.states
                                                    .methodHelpDropdown.visible,
                                                expression:
                                                  "ui.states.methodHelpDropdown.visible"
                                              }
                                            ],
                                            staticClass:
                                              "rounded bg-gray-100 border border-gray-200 p-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "Method type allows you to restrict how many tags you can make for an individual in each task. Click on a method type to find out more."
                                                  )
                                                ) +
                                                "\n                          "
                                            ),
                                            _c("div", { staticClass: "my-2" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "font-bold py-1"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "1SPM (1 Species-Individual per minute)"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Create one tag for each individual in each minute of the task. Creates standardized duration limited point counts of different lengths and allows analyses that account for detection error based on the occupancy concept."
                                                      )
                                                    ) +
                                                    "\n                            "
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "type_1spm w-full h-24 bg-contain bg-no-repeat"
                                                })
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "my-2" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "font-bold py-1"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "1SPT (1 species-individual tag allowed per task)"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Create one tag for each individual sound at the time of first detection in the task. Creates a standardized duration limited point count to census wildlife or other sounds, and allows analyses that account for detection error based on time to first detection concept."
                                                      )
                                                    ) +
                                                    "\n                            "
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "type_1spt w-full h-24 bg-contain bg-no-repeat"
                                                })
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "my-2" }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "font-bold py-1"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "None (Unlimited species-individual tags per task)"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Create an unlimited amount of tags. Upload recognizer hits or tagging each time an individual sound is heard."
                                                      )
                                                    ) +
                                                    "\n                            "
                                                ),
                                                _c("div", {
                                                  staticClass:
                                                    "type_none w-full h-24 bg-contain bg-no-repeat"
                                                })
                                              ])
                                            ])
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row flex justify-end m-0 gap-2 mt-3"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-success flex-initial",
                                        attrs: {
                                          type: "button",
                                          id: "browseButton",
                                          disabled:
                                            _vm.selectedFiles.length === 0 ||
                                            _vm.selectedFiles.length !==
                                              _vm.totalFiles ||
                                            _vm.buttonLoading.step1.next
                                        },
                                        on: {
                                          click: () => {
                                            _vm.checkFileNames()
                                            _vm.buttonLoading.step1.next = true
                                          }
                                        }
                                      },
                                      [
                                        !_vm.buttonLoading.step1.next
                                          ? _c(
                                              "span",
                                              { staticClass: "!text-sm" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("Next")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c("pulse-loader")
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.stepState == 2
                        ? _c("div", [
                            _c("div", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "There are a few files that don't have valid names. You may continue to proceed, the uploader will disregard these files."
                                    )
                                  ) +
                                  "\n                  "
                              ),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.batchUpload.fileNameErrors &&
                                        _vm.selectedFiles.length ==
                                          _vm.batchUpload.fileNameErrors.length,
                                      expression:
                                        "batchUpload.fileNameErrors && selectedFiles.length == batchUpload.fileNameErrors.length"
                                    }
                                  ],
                                  staticClass:
                                    "bg-red-100 border border-red-200 p-2 text-red-500 text-base my-5"
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-exclamation-triangle"
                                  }),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.$t(
                                          "There are files that already exist in the organization. You may continue to proceed, the uploader will disregard these files"
                                        )
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "table",
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("Recording")]),
                                  _c("th", [_vm._v("Issue")])
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.batchUpload.fileNameErrors, function(
                                  error,
                                  index
                                ) {
                                  return [
                                    _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(_vm._s(error.recordingName))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(error.errorMessage))
                                      ])
                                    ])
                                  ]
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row flex justify-end gap-2 mt-3"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default flex-initial",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.buttonLoading.step2.prev
                                    },
                                    on: {
                                      click: () => {
                                        _vm.stepState = 1
                                        _vm.buttonLoading.step2.prev = true
                                      }
                                    }
                                  },
                                  [
                                    !_vm.buttonLoading.step2.prev
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common-previous")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success flex-initial",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        (_vm.batchUpload.fileNameErrors &&
                                          _vm.selectedFiles.length ==
                                            _vm.batchUpload.fileNameErrors
                                              .length) ||
                                        _vm.buttonLoading.step2.next
                                    },
                                    on: {
                                      click: () => {
                                        _vm.checkFileNamesForLocation()
                                        _vm.buttonLoading.step2.next = true
                                      }
                                    }
                                  },
                                  [
                                    !_vm.buttonLoading.step2.next
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("Next")) + " "
                                            )
                                          ]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.stepState == 3
                        ? _c("div", {}, [
                            _c("h5", [
                              _vm._v(
                                _vm._s(_vm.$t("Enter Missing Coordinates"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "p-3 bg-gray-100" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.$t(
                                      "Once saved the location will be saved in the organization even if you do not proceed with uploading your recordings"
                                    )
                                  ) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", {}, [
                              _c(
                                "table",
                                [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.$tc("common-location", 1))
                                      )
                                    ]),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("common-latitude")))
                                    ]),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("common-longitude")))
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.batchUpload.locations, function(
                                    location,
                                    index
                                  ) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          key: index,
                                          staticClass: "!border-b"
                                        },
                                        [
                                          _c("td", { staticClass: "p-0" }, [
                                            _vm._v(_vm._s(location.name)),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-red-500 text-sm"
                                              },
                                              [_vm._v(" ")]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: location.latitude,
                                                  expression:
                                                    "location.latitude"
                                                }
                                              ],
                                              class: {
                                                highlight:
                                                  (location.latitude == null ||
                                                    location.latitude.length ==
                                                      0) &&
                                                  location.longitude
                                              },
                                              attrs: {
                                                type: "text",
                                                min: "-90",
                                                max: "90"
                                              },
                                              domProps: {
                                                value: location.latitude
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  return _vm.latlongValidation(
                                                    location,
                                                    "lat"
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.insertUpdateLocation(
                                                    index
                                                  )
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    location,
                                                    "latitude",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-red-500 text-sm"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(location.error || " ")
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: location.longitude,
                                                  expression:
                                                    "location.longitude"
                                                }
                                              ],
                                              class: {
                                                highlight:
                                                  (location.longitude == null ||
                                                    location.longitude.length ==
                                                      0) &&
                                                  location.latitude
                                              },
                                              attrs: {
                                                type: "text",
                                                min: "-180",
                                                max: "180"
                                              },
                                              domProps: {
                                                value: location.longitude
                                              },
                                              on: {
                                                keyup: function($event) {
                                                  return _vm.latlongValidation(
                                                    location,
                                                    "long"
                                                  )
                                                },
                                                blur: function($event) {
                                                  return _vm.insertUpdateLocation(
                                                    index
                                                  )
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    location,
                                                    "longitude",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-red-500 text-sm"
                                              },
                                              [_vm._v(" ")]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  btn: true,
                                                  "btn-default": !location.id,
                                                  "btn-success": location.id
                                                },
                                                attrs: {
                                                  disabled:
                                                    location.longitude ==
                                                      null ||
                                                    location.longitude.length ==
                                                      0 ||
                                                    location.latitude == null ||
                                                      location.latitude
                                                        .length == 0
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.insertUpdateLocation(
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  class: {
                                                    fas: true,
                                                    "fa-save": !location.loading,
                                                    "fa-spinner fa-spin":
                                                      location.loading
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-red-500 text-sm"
                                              },
                                              [_vm._v(" ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row flex justify-end gap-2 mt-3"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default flex-initial",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.buttonLoading.step3.prev
                                    },
                                    on: {
                                      click: () => {
                                        _vm.checkFileNames(true)
                                        _vm.buttonLoading.step3.prev = true
                                      }
                                    }
                                  },
                                  [
                                    !_vm.buttonLoading.step3.prev
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("common-previous")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success flex-initial",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.buttonLoading.step3.next
                                    },
                                    on: {
                                      click: () => {
                                        _vm.parseUploadFolders()
                                        _vm.buttonLoading.step3.next = true
                                      }
                                    }
                                  },
                                  [
                                    !_vm.buttonLoading.step3.next
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("Next")) + " "
                                            )
                                          ]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.stepState == 4
                        ? _c(
                            "div",
                            {
                              staticClass: "tabbed",
                              staticStyle: { margin: "20px 0" },
                              attrs: { id: "batchUploadPreview" }
                            },
                            [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(_vm.$t("Confirm Files to Upload"))
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "padded20 overflow-auto boxed dataGroup tab-content tag file-table"
                                },
                                [
                                  _vm.error
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "warning",
                                            attrs: { colspan: "10" }
                                          },
                                          [_vm._v(_vm._s(_vm.error))]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.batchUpload.counts[_vm.filterTable] === 0
                                    ? _c("div", [
                                        _c("h6", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "audioUploadForm-noFilesTab",
                                                { tab: _vm.filterTable }
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    : _c("batch-table-upload-preview", {
                                        attrs: {
                                          files: _vm.batchUpload.files,
                                          status: _vm.batchUpload.status,
                                          counts: _vm.batchUpload.counts,
                                          locations: _vm.batchUpload.locations,
                                          methodOption: _vm.methodOption,
                                          hiddenColumns: !_vm.projectId
                                            ? ["methodId", "taskLength"]
                                            : []
                                        }
                                      })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "row flex justify-end gap-2 mt-3"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-default flex-initial",
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.buttonLoading.step4.prev
                                      },
                                      on: {
                                        click: () => {
                                          _vm.checkFileNamesForLocation(true)
                                          _vm.buttonLoading.step4.prev = true
                                        }
                                      }
                                    },
                                    [
                                      !_vm.buttonLoading.step4.prev
                                        ? _c(
                                            "span",
                                            { staticClass: "!text-sm" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("common-previous")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _c("pulse-loader")
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success flex-initial",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          _vm.numFilestoUpload == 0 ||
                                          _vm.buttonLoading.step4.next
                                      },
                                      on: {
                                        click: () => {
                                          _vm.processBatchUpload()
                                          _vm.buttonLoading.step4.next = true
                                        }
                                      }
                                    },
                                    [
                                      !_vm.buttonLoading.step4.next
                                        ? _c(
                                            "span",
                                            { staticClass: "!text-sm" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("Begin Upload")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _c("pulse-loader")
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.stepState == 5
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "padded20 dataGroup tab-content tag file-table"
                              },
                              [
                                _vm.batchUpload.status.allUploaded
                                  ? _c("p", [
                                      _vm.batchUpload.counts["Uploaded"] > 0
                                        ? _c(
                                            "span",
                                            { staticClass: "text-green-500" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.batchUpload.counts[
                                                      "Uploaded"
                                                    ]
                                                  ) + " "
                                                )
                                              ]),
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "audioUploadForm-finishUploadMsg2",
                                                      _vm.batchUpload.counts[
                                                        "Uploaded"
                                                      ]
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.batchUpload.counts["Failed"] > 0
                                        ? _c(
                                            "span",
                                            { staticClass: "text-red-500" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.batchUpload.counts[
                                                        "Failed"
                                                      ]
                                                    ) + " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "audioUploadForm-finishUploadMsg3",
                                                      _vm.batchUpload.counts[
                                                        "Failed"
                                                      ]
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _c("p", [
                                      _c("i", {
                                        staticClass: "fas fa-spinner fa-spin"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("Upload in progress...")
                                          ) +
                                          "\n                  "
                                      )
                                    ]),
                                _vm._v(" "),
                                _vm.error
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "warning",
                                          attrs: { colspan: "10" }
                                        },
                                        [_vm._v(_vm._s(_vm.error))]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  [
                                    _c("tr", [
                                      _c("th", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "VueTables__heading",
                                            attrs: { title: "" }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Status")))]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "VueTables__heading",
                                            attrs: { title: "" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "audioUploadForm-headings.fileName"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "VueTables__heading",
                                            attrs: { title: "" }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("File Type")))]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "VueTables__heading",
                                            attrs: { title: "" }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Triggered")))]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "VueTables__heading",
                                            attrs: { title: "" }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("Length(s)")))]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "VueTables__heading",
                                            attrs: { title: "" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "audioUploadForm-headings.size"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.audioUploader.audioList,
                                      function(row, index) {
                                        return [
                                          row.fileName
                                            ? _c(
                                                "tr",
                                                {
                                                  key:
                                                    "file-id" +
                                                    _vm.filterTable +
                                                    index
                                                },
                                                [
                                                  _c("td", [
                                                    row.status ===
                                                    _vm.statusOption.SKIP
                                                      ? _c("i", {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                row.message,
                                                              expression:
                                                                "row.message"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "fas fa-forward"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    row.status ===
                                                    _vm.statusOption.QUEUE
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fas fa-hourglass-end"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    row.status ===
                                                    _vm.statusOption.UPLOADING
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fas fa-spinner fa-pulse text-blue-500"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    row.status ===
                                                    _vm.statusOption.UPLOADED
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fas fa-check-circle text-green-500"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    row.status ===
                                                    _vm.statusOption.FAILED
                                                      ? _c("i", {
                                                          directives: [
                                                            {
                                                              name: "tooltip",
                                                              rawName:
                                                                "v-tooltip",
                                                              value:
                                                                row.message,
                                                              expression:
                                                                "row.message"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "fas fa-times-circle text-red-500"
                                                        })
                                                      : _vm._e()
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.recordingName ||
                                                          row.fileName
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(_vm._s(row.fileType))
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    row.isTriggered
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fas fa-square-check"
                                                        })
                                                      : _c("i", {
                                                          staticClass:
                                                            "fa-regular fa-square"
                                                        })
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        row.taskLength ||
                                                          _vm.$t("Default")
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(row.fileSize) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "commonUnits-megabyte"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn btn-default mt-3",
                                    attrs: {
                                      disabled:
                                        !_vm.batchUpload.status.allUploaded &&
                                        !_vm.batchUpload.status.hasError
                                    },
                                    on: { click: _vm.downloadLog }
                                  },
                                  [
                                    _vm.batchUpload.status.allUploaded ||
                                    _vm.batchUpload.status.hasError
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "audioUploadForm-downloadLog"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                )
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "row flex justify-end gap-2 mt-3"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-default flex-initial",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        !_vm.batchUpload.status.allUploaded &&
                                        !_vm.batchUpload.status.hasError
                                    },
                                    on: {
                                      click: () => {
                                        _vm.stepState = 1
                                        _vm.resetBatchUpload()
                                        _vm.closeForm()
                                      }
                                    }
                                  },
                                  [
                                    _vm.batchUpload.status.allUploaded ||
                                    _vm.batchUpload.status.hasError
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [_vm._v(_vm._s(_vm.$t("Close")))]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success flex-initial",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        !_vm.batchUpload.status.allUploaded &&
                                        !_vm.batchUpload.status.hasError
                                    },
                                    on: {
                                      click: () => {
                                        _vm.stepState = 1
                                        _vm.resetBatchUpload()
                                        _vm.buttonLoading.step5.next = true
                                      }
                                    }
                                  },
                                  [
                                    !_vm.buttonLoading.step5.next &&
                                    (_vm.batchUpload.status.allUploaded ||
                                      _vm.batchUpload.status.hasError)
                                      ? _c(
                                          "span",
                                          { staticClass: "!text-sm" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Start Over")) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("pulse-loader")
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }