var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "remote-camera camera-tagging no-top-sticky-menu" },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.error.statusText) + "\n    ")
                ])
              : _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.warning != null
        ? _c("div", { staticClass: "warning" }, [
            _vm._v("\n      " + _vm._s(_vm.warning) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c("div", [
            _c("div", { staticClass: "container large" }, [
              _c("div", { staticClass: "row breadCrumb" }, [
                _c("div", { staticClass: "col s12" }, [
                  _c("p", [
                    _c("a", { attrs: { href: _vm.breadcrumbHomeLink() } }, [
                      _vm._v(_vm._s(_vm.$t("common-home")))
                    ]),
                    _vm._v(" / "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbBaseLink() + "ARU" } },
                      [_vm._v(_vm._s(_vm.$t("common-aru")))]
                    ),
                    _vm._v(" /\n                "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbARUSPPProjectLink() } },
                      [_vm._v(_vm._s(_vm.project && _vm.project.fullNm))]
                    ),
                    _vm._v(
                      " / " +
                        _vm._s(_vm.$t("aruSpeciesVerification-breadcrumb"))
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "stickyWrap", attrs: { id: "topStickyBar" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "sticky",
                        rawName: "v-sticky",
                        value: {
                          zIndex: 21,
                          stickyTop: 0,
                          stickTopOnly: true,
                          positionType: "fixed",
                          stickyContainerId: "topStickyBar"
                        },
                        expression:
                          "{zIndex: 21, stickyTop: 0, stickTopOnly: true, positionType: 'fixed', stickyContainerId: 'topStickyBar'}"
                      }
                    ],
                    staticClass: "sticky",
                    staticStyle: { position: "static" },
                    attrs: { id: "topStickyBarContent" }
                  },
                  [
                    _c("div", { staticClass: "container large taskHeader" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col s12 m12 l8 title" }, [
                          _c("div", [
                            _c("h1", [
                              _vm.speciesObj && !_vm.imageNotFound
                                ? _c("img", {
                                    staticClass: "rounded-full w-12 inline",
                                    attrs: {
                                      src: `https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${_vm.speciesObj.scientificName}`
                                    },
                                    on: {
                                      error: function($event) {
                                        _vm.imageNotFound = true
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.imageNotFound
                                ? _c("i", {
                                    staticClass:
                                      "fas fa-question-circle text-5xl",
                                    attrs: {
                                      "data-src": `https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${_vm.speciesObj.scientificName}`
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.speciesObj && _vm.speciesObj.commonName
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.vocalizationId && _vm.staticOptions
                                      ? "- " +
                                          _vm.staticOptions.vocalization.find(
                                            x => x.id == _vm.vocalizationId
                                          ).type
                                      : ""
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "actionGroup" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "editLink",
                                  class: { "has-filter": _vm.hasFilter },
                                  attrs: { id: "filterToggle" },
                                  on: {
                                    click: function($event) {
                                      _vm.ui.showFilter = !_vm.ui.showFilter
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "ion-android-funnel"
                                  }),
                                  _vm._v(" "),
                                  !_vm.hasFilter
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("common-filter")))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasFilter
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("common-filtered"))
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "editLink",
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleDialog(
                                        _vm.ui.helpFormName,
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "ion-help-circled"
                                  }),
                                  _vm._v(_vm._s(_vm.$t("common-help")))
                                ]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.projectSppInfo.canUserModify
                          ? _c(
                              "div",
                              { staticClass: "col s12 m12 l4 filters" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col m12" }, [
                                    _vm.selectedImageCount > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-3 grid grid-cols-3 gap-4"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "btn",
                                                attrs: {
                                                  role: "menuitem",
                                                  tabindex: "-1",
                                                  id: "menu-item-0"
                                                },
                                                on: {
                                                  click: () => {
                                                    _vm.toggleDropdown()
                                                    _vm.$modal.show(
                                                      "edit-modal"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "fas fa-pencil"
                                                }),
                                                _vm._v(
                                                  "  " +
                                                    _vm._s(_vm.$t("Edit")) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.selectedImageCount
                                                    ) +
                                                    ")"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "btn",
                                                attrs: {
                                                  role: "menuitem",
                                                  tabindex: "-1",
                                                  id: "menu-item-0"
                                                },
                                                on: {
                                                  click: () => {
                                                    _vm.tagSelected()
                                                    _vm.toggleDropdown()
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "fas fa-check"
                                                }),
                                                _vm._v(
                                                  "  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "common-verifySelected"
                                                      )
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.selectedImageCount
                                                    ) +
                                                    ")"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "btn",
                                                attrs: {
                                                  role: "menuitem",
                                                  tabindex: "-1",
                                                  id: "menu-item-1"
                                                },
                                                on: {
                                                  click: () => {
                                                    _vm.toggleDropdown()
                                                    _vm.$modal.show(
                                                      "rating-modal"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-star"
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("common-rate")
                                                    ) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.selectedImageCount
                                                    ) +
                                                    ")"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.projectSppInfo.canUserModify,
                              expression: "!projectSppInfo.canUserModify"
                            }
                          ]
                        },
                        [
                          _c("i", { staticClass: "fas fa-lock" }),
                          _vm._v(" " + _vm._s(_vm.$t("common-readOnly")))
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.staticOptions != null &&
                    _vm.project.id != null &&
                    _vm.speciesCode != null
                      ? _c("verify-filter", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ui.showFilter == true,
                              expression: "ui.showFilter==true"
                            }
                          ],
                          attrs: {
                            "static-options": _vm.staticOptions,
                            "species-code": _vm.speciesCode,
                            vocalizationId: _vm.vocalizationId
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c(
            "div",
            {
              staticClass: "container overflow",
              staticStyle: { "margin-top": "20px" }
            },
            [
              _c(
                "div",
                { staticClass: "padded boxed dataGroup" },
                [
                  _vm.project &&
                  _vm.project.id &&
                  _vm.speciesCode &&
                  _vm.speciesObj
                    ? _c("recording-verify-list", {
                        ref: "tag",
                        attrs: {
                          "project-id": _vm.project.id,
                          speciesObj: _vm.speciesObj,
                          "static-options": _vm.staticOptions,
                          userCanModify: _vm.projectSppInfo.canUserModify,
                          vocalizationId: _vm.vocalizationId
                        },
                        on: { imageCountChange: _vm.imageCountChange }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("tagging-help", { attrs: { "form-name": _vm.ui.helpFormName } }),
      _vm._v(" "),
      _c("v-dialog"),
      _vm._v(" "),
      _c(
        "modal",
        {
          staticClass: "relative",
          attrs: { name: "rating-modal", height: "auto", width: 400 }
        },
        [
          _c("div", { staticClass: "text-center p-3 w-full h-full" }, [
            _c("h2", { staticClass: "mt-4" }, [
              _vm._v(_vm._s(_vm.$t("common-rate")))
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-3xl" },
              _vm._l(5, function(i) {
                return _c("i", {
                  key: i,
                  staticClass: "cursor-pointer far fa-star",
                  class: {
                    "fas fa-star text-yellow-400":
                      _vm.starRating > i - 1 && _vm.starRating <= 5
                  },
                  on: {
                    mouseover: function($event) {
                      return _vm.mouseOverStar(i)
                    },
                    mouseleave: _vm.mouseLeaveStar,
                    click: function($event) {
                      return _vm.clickStar(i)
                    }
                  }
                })
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              _vm._l(5, function(i) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.ratingModal.hoverValue === i,
                        expression: "ratingModal.hoverValue===i"
                      }
                    ],
                    key: i,
                    staticClass:
                      "text-sm h-auto -mx-3 fixed bg-white z-20 w-[400px] p-5"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.ratingModal.examples[i - 1]) +
                        "\n          "
                    ),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", {
                        class: `star${i}_example1 w-24 h-24 flex-1 bg-contain`
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-1" }),
                      _vm._v(" "),
                      _c("div", {
                        class: `star${i}_example2 w-24 h-24 flex-1 bg-contain`
                      })
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn mt-6",
                on: {
                  click: () => {
                    _vm.rateSelected()
                    _vm.$modal.hide("rating-modal")
                    _vm.starRating = null
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("common-submit")))]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "edit-modal", height: "auto", width: "75%" },
          on: { closed: _vm.editModalClose }
        },
        [
          _c("div", { staticClass: "p-3 w-full h-full" }, [
            _c("h5", { staticClass: "mt-4" }, [
              _vm._v(_vm._s(_vm.$t("Batch Edit Tags")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-1 mb-3" }, [
              _c(
                "div",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Target Species")))]),
                  _vm._v(" "),
                  _vm.speciesObj
                    ? _c("auto-complete", {
                        ref: "speciesInput",
                        attrs: {
                          placeholder: _vm.$t(
                            "recordingForms-spaceListsAllSpecies"
                          ),
                          source: _vm.getSpeciesAPIUrl,
                          initialDisplay: _vm.formattedSpeciesDisplay(
                            this.speciesObj
                          ),
                          initialValue: this.speciesObj.id,
                          name: "code",
                          resultsValue: "code",
                          "results-display": _vm.formattedSpeciesDisplay,
                          excludeCharacter: "|",
                          "request-headers": _vm.authHeaders
                        },
                        on: {
                          selected: _vm.setSpeciesId,
                          clear: _vm.clearSpeciesInput
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editModal.loadingTagOptions,
                    expression: "!editModal.loadingTagOptions"
                  }
                ],
                staticClass: "grid grid-cols-2 gap-4"
              },
              [
                _c("div", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Vocalization Type")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editModal.vocalizationId,
                          expression: "editModal.vocalizationId"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.editModal,
                            "vocalizationId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("-- " + _vm._s(_vm.$t("Unchanged")) + " --")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.editModal.tagOptions.vocalization, function(
                        value
                      ) {
                        return _c("option", { domProps: { value: value.id } }, [
                          _vm._v(_vm._s(value.type))
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("label", [_vm._v(_vm._s(_vm.$t("Abundance")))]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editModal.abundanceId,
                          expression: "editModal.abundanceId"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.editModal,
                            "abundanceId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("-- " + _vm._s(_vm.$t("Unchanged")) + " --")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.editModal.tagOptions.abundance, function(
                        value
                      ) {
                        return _c("option", { domProps: { value: value.id } }, [
                          _vm._v(_vm._s(value.type))
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editModal.loadingTagOptions,
                    expression: "!editModal.loadingTagOptions"
                  }
                ],
                staticClass: "grid grid-cols-1 gap4"
              },
              [
                _c("div", [
                  _c("label", { attrs: { for: "verify" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editModal.verify,
                          expression: "editModal.verify"
                        }
                      ],
                      attrs: { type: "checkbox", id: "verify", name: "verify" },
                      domProps: {
                        checked: Array.isArray(_vm.editModal.verify)
                          ? _vm._i(_vm.editModal.verify, null) > -1
                          : _vm.editModal.verify
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.editModal.verify,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.editModal,
                                  "verify",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.editModal,
                                  "verify",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.editModal, "verify", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Verify")))])
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "notify" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editModal.notify,
                          expression: "editModal.notify"
                        }
                      ],
                      attrs: { type: "checkbox", id: "notify", name: "notify" },
                      domProps: {
                        checked: Array.isArray(_vm.editModal.notify)
                          ? _vm._i(_vm.editModal.notify, null) > -1
                          : _vm.editModal.notify
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.editModal.notify,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.editModal,
                                  "notify",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.editModal,
                                  "notify",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.editModal, "notify", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Notify Tag Creator")))])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editModal.loadingTagOptions,
                    expression: "editModal.loadingTagOptions"
                  }
                ],
                staticClass: "text-center mt-3"
              },
              [
                _c("pulse-loader", {
                  attrs: {
                    loading: true,
                    color: "#C94412",
                    size: "20px",
                    width: 400,
                    height: 50
                  }
                }),
                _vm._v(" "),
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.$t("Loading Tag Options")) + " ")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn mt-6",
                attrs: {
                  disabled:
                    !_vm.editModal.specieId &&
                    !_vm.editModal.vocalizationId &&
                    !_vm.editModal.abundanceId &&
                    !_vm.editModal.verify
                },
                on: {
                  click: () => {
                    _vm.editSelected()
                    _vm.$modal.hide("edit-modal")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("common-submit")))]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }