<template>
  <div class="vue-drag-select" @mousedown="onMouseDown">
    <slot :selectedItems="selectedItems" />
    <div v-if="mouseDown" class="vue-drag-select-box"
      :style="selectionBoxStyling"></div>
  </div>
</template>

<script>
  import { eventBus } from '@/lib/eventbus';
  export default {
    name: 'vue-drag-select',
    props: {
      selectorClass: {
        type: String,
        required: true
      },
      inputSelection: {
        type: Array
      },
      name: { /* distingish different drag area */
        type: String
      },
      excludeSelectorClass: { /* when drag area have so many different elements, we want to use this to let elements have their own events, put class in the most inside element */
        type: String
      },
      disableDragSelection: {
        type: Boolean,
        value: false
      }
    },
    data () {
      return {
        mouseDown: false,
        startPoint: null,
        endPoint: null,
        selectedItems: []
      }
    },
    computed: {
      selectionBox () {
        // Only set styling when necessary
        if (!this.mouseDown || !this.startPoint || !this.endPoint) return {}

        const clientRect = this.$el.getBoundingClientRect()
        const scroll = this.getScroll()

        // Calculate position and dimensions of the selection box
        const left = Math.min(this.startPoint.x, this.endPoint.x) - clientRect.left - scroll.x
        const top = Math.min(this.startPoint.y, this.endPoint.y) - clientRect.top - scroll.y
        const width = Math.abs(this.startPoint.x - this.endPoint.x)
        const height = Math.abs(this.startPoint.y - this.endPoint.y)

        // Return the styles to be applied
        return {
          left,
          top,
          width,
          height
        }
      },
      selectionBoxStyling () {
        // Only set styling when necessary
        if (!this.mouseDown || !this.startPoint || !this.endPoint) return {}

        const { left, top, width, height } = this.selectionBox

        // Return the styles to be applied
        return {
          left: `${left}px`,
          top: `${top}px`,
          width: `${width}px`,
          height: `${height}px`
        }
      }
    },
    watch: {
      inputSelection(newVal, oldVal) {
        if (newVal !== oldVal) {
          const children = Array.from(this.$el.getElementsByClassName(this.selectorClass));

          if (children) { // loop through children and pick divs that matches input value
            this.selectedItems = children.filter((item) => {
                return newVal.indexOf(parseInt(item.dataset.item, 10)) != -1
              })
          }
        }
      }
    },
    methods: {
      getScroll () {
        // If we're on the server, default to 0,0
        if (typeof document === 'undefined') {
          return {
            x: 0,
            y: 0
          }
        }

        return {
          x: this.$el.scrollLeft || document.body.scrollLeft || document.documentElement.scrollLeft,
          y: this.$el.scrollTop || document.body.scrollTop || document.documentElement.scrollTop
        }
      },
      onMouseDown (event) {
        // right clicks, clear all
        if (event.button === 2) {
          event.preventDefault();
          const child = event.currentTarget; // where the event is fired from
          child.oncontextmenu = function () {
            return false;
          }
          this.selectedItems = [];
          eventBus.$emit('selection-change', this.selectedItems, this.name)
          return;
        }

        // Register begin point
        this.mouseDown = true
        this.startPoint = {
          x: event.pageX,
          y: event.pageY
        }
        // Start listening for mouse move and up events
        window.addEventListener('mousemove', this.onMouseMove)
        window.addEventListener('mouseup', this.onMouseUp)
      },
      onMouseMove (event) {
        if (this.disableDragSelection) { // when disabled don't track
          return;
        }
        // Update the end point position
        if (this.mouseDown) {
          /* don't do drag selection when no much dragging happend,
          when user simply click, it actually moves mouse as well */

          if (Math.abs(this.startPoint.x - event.pageX) +
            Math.abs(this.startPoint.y - event.pageY) < 50) {
            return;
          }

          this.endPoint = {
            x: event.pageX,
            y: event.pageY
          }

          const children =this.$el.getElementsByClassName(this.selectorClass);
          let currentSelection;
          if (children) {
             currentSelection = Array.from(this.$el.getElementsByClassName(this.selectorClass)).filter((item) => {
              return this.isItemSelected(item)
            })
          }
          /* if control key is pressed add newly selected Items to previous selected ones. */
          if (event.ctrlKey) {
            this.selectedItems = [...new Set(this.selectedItems.concat(currentSelection))];
          } else {
            this.selectedItems = currentSelection;
          }


          // event.shiftKey
        }
      },
      onMouseUp (event) {
        // Clean up event listeners
        window.removeEventListener('mousemove', this.onMouseMove)
        window.removeEventListener('mouseup', this.onMouseUp)

        // Reset state
        this.mouseDown = false;
        this.startPoint = null;
        this.endPoint = null;
        eventBus.$emit('selection-change', this.selectedItems, this.name);
      },
      isItemSelected (el) {
        if (el.classList.contains(this.selectorClass)) {
          const boxA = this.selectionBox
          const boxB = {
            top: el.offsetTop,
            left: el.offsetLeft,
            width: el.clientWidth,
            height: el.clientHeight
          }

          return !!(
            boxA.left <= boxB.left + boxB.width &&
            boxA.left + boxA.width >= boxB.left &&
            boxA.top <= boxB.top + boxB.height &&
            boxA.top + boxA.height >= boxB.top
          )
        }

        return false
      },
      /* on click on individual elements, no dragging,
        toggle selection here
      */
      toggleSelection (event) {
        event.preventDefault();
        const child = event.currentTarget; // where the event is fired from
        child.oncontextmenu = function () {
          return false;
        }
        const self = this;
        if (event.button === 0) { // left mouse clicked
          /* only click on container, not elements inside container */
          let targ;
          if (event.target) {
            targ = event.target;
          } else if (event.srcElement) {
            targ = event.srcElement;
          }
          /* when clicked on the excluded class element don't do anything, useful at image list, e.g. don't count zoom in icon on selection  */
          if (this.excludeSelectorClass && targ.classList.contains(this.excludeSelectorClass)) {
              return;
          }

          const included = self.selectedItems.find((item) => {
            return child === item
          })

          if (event.shiftKey) { // click with shift key on
            // console.log(child.id); // find newly selected id;
            const currentItemId = parseInt(child.id);
            const previousSelection = self.selectedItems.map(x => parseInt(x.id));
            // The new spread operator is a shorter way of writing the apply solution to get the maximum of an array:
            let min = Math.min(...previousSelection);
            let max = Math.max(...previousSelection);
            let range = [];
            if (currentItemId >= min && currentItemId <= max) {
              return;
            }
            if (currentItemId < min) { min = currentItemId }
            if (currentItemId > max) { max = currentItemId }

            // console.log(min, max);
            const children = Array.from(this.$el.getElementsByClassName(this.selectorClass));
            // console.log(min, max, children);
            if (children) { // loop through children and pick divs that matches input value
              const newSelectedItems = children.filter((item) => {
                return (item.id >= min && item.id <=max)
              })

              // console.log('newSelectedItems', newSelectedItems);
              this.selectedItems = [...new Set(this.selectedItems.concat(newSelectedItems))];
            }
          } else if (event.ctrlKey) { // if control key pressed with click, toggle the single image selection
            if (included) {
              // remove if previous selected
              self.selectedItems = self.selectedItems.filter((item) => {
                return child !== item
              })
            } else { // inset if not previously selected.
              self.selectedItems.push(child);
            }
          } else { // otherwise, clear all previous selection and select current div
            self.selectedItems = []; // clear all
            self.selectedItems.push(child); // add current
          }

        }
      },
      contentUpdated () { // when content udpated, added more content etc. otherwise newly added box won't have a event
        const self = this;
        // get element by selector class (htmlColleciton), convert to array and loop to bind click event
        Array.from(this.$el.getElementsByClassName(this.selectorClass)).forEach((child) => { // toggle when children clicked
          // child.removeEventListener('mousedown', self.toggleSelection);
          child.removeEventListener('mousedown', self.toggleSelection);
          child.addEventListener('mousedown', self.toggleSelection);
          // if (!child.classList.contains('event')) {
          //   child.classList.add('event');
          //   child.classList.add('update'+new Date().getTime());

          // }
        });
      }
    },
    mounted () {
      // this.$children. source, not working always empty
      // get element by selector class (htmlColleciton), convert to array and loop to bind click event
      const self = this;
      // console.log('mounted')
      Array.from(this.$el.getElementsByClassName(this.selectorClass)).forEach((child) => { // toggle when children clicked
        child.classList.add('event');
        child.addEventListener('mousedown', self.toggleSelection)
      })
    },
    updated () { // called when classes changes etc.
      // this.$children. source, not working always empty


    },
    beforeDestroy () {
      //  console.log('before destroy')
      // Remove event listeners
      window.removeEventListener('mousemove', this.onMouseMove)
      window.removeEventListener('mouseup', this.onMouseUp)
      const self = this;
      Array.from(this.$el.getElementsByClassName(this.selectorClass)).forEach((child) => { // toggle when children clicked
        child.removeEventListener('mousedown', self.toggleSelection);
      });
      // this.$children.forEach((child) => {
      //   child.$off('click')
      // })
    }
  }
</script>

<style scoped>
  .vue-drag-select {
    position: relative;
    user-select: none;
  }

  .vue-drag-select-box {
    position: absolute;
    background: rgba(0, 162, 255, .4);
    z-index: 99;
  }
</style>
