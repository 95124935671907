<template>
  <div class="form">
    <modal :name="formName"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form"
      draggable=".drag-handle"
      :delay="100"
      :resizable="true"
      width = "75%"
      height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
      ref="tagForm"
      @resize="getModalWidth">
      <div id="tagging-form">
        <div @click="closeForm" class="iconButton link" :class="{disabled: loading}">
          <span class="ion-close"></span>
        </div>
        <div class="modal-header drag-handle" ref="modalHeader">
          <h6>
            <!-- confusion over the following lines: -->
            <template v-if="isBatchSelected">{{$t('aruVerificationTagForm-batch')}}</template>
            <template v-else-if="forUntagged">{{$t('aruVerificationTagForm-allUntagged')}} </template>
            {{getTitleAndLocation()}} {{hasFormModified ? '(* ' + $t('common-unsaved') + ')':''}}</h6>
        </div>
        <div class="modal-scroller" ref="scroller">

          <div class="modal-content container image-tagging" v-if="options.allSpecies!=null && localTag != null" >
            <div v-if="loading">
              <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
              <div>{{$t('cameraTagging-updating')}}</div>
            </div>
            <span @click="error=[]; validationMsg=[]; message=[];" v-show="error.length > 0 || message.length > 0 || validationMsg.length > 0" class="link red-font">
              {{$t('cameraTagging-clearMessages')}}
              <span class="ion-android-close" ></span>
            </span>
            <div>
              <template v-for="(msg, index) in message">
                <div class="message" :key="'msg' + index"> {{msg}}</div>
              </template>
            </div>
            <div class="row">
              <template v-for="(msg, index) in error">
                <div class="error" :key="'error' + index"> {{msg}}</div>
              </template>
              <template v-for="(msg, index) in validationMsg">
                <div class="validation" :key="'validation' + index"> {{msg}}</div>
              </template>
            </div>
            <template v-for="(tag, index) in localTag.speciesTags">
              <div  v-if="!tag.isLocked" class="row"
                :class="{'even': index%2 ==0, 'odd':index%2==1, 'batch-update': tag.hasOwnProperty('isLocked') && isBatchSelected}"
                :key="'tagRow' + index">
                <!-- lock: batch mode, true when some images have that tag, you can' modify them. false: shared by all images. -->
                <span v-if="isEditable" class="ion icon spp-new-tag" :class="{'ion-ios-pricetag': tag.id == null, 'ion-edit': tag.id != null}">
                </span>
                <div class="spp-column" :class="getTagFieldClasses(modalWidth, '5')">
                  <!-- <select name="species" v-model="tag.speciesId" class="modified-select" @change="changeSpecies">
                    <option :value="null" disabled >Species</option>
                    <option v-for="opt in options.species" :value="opt.id" :key="'spp' + opt.id">{{opt.commonName}}</option>
                  </select> -->
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="tag.species" :options="options.allSpecies" :multiple="false" :close-on-select="true"
                    :disabled="!isEditable" :placeholder="$tc('common-species', 1)" label="commonName" track-by="id" selectLabel="" deselectLabel=" " class="input"
                    :get-option-class="getSpeciesOptClass"
                    @input="speciesChanged(tag, id)">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc('common-speciesSelected', values.length, {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                </div>
                <div :class="getTagFieldClasses(modalWidth)">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="tag.sex" :options="options.sex" :multiple="false" :close-on-select="true"
                  :disabled="isSelectDisabled(tag.species, 'sex')" :class="getClass(tag.species, 'age')"
                  @input="tag.hasChanged = true"
                  :placeholder="$t('taggingFilter-sex')" label="type" track-by="id" selectLabel="" deselectLabel=" " class="input">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc('common-speciesSelected', values.length, {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                  <!-- <select name="category" v-model="tag.sex" required="true" class="modified-select">
                    <option :value="null" disabled >Sex</option>
                    <option v-for="opt in options.sex" :value="opt.id" :key="'spp' + opt.id">{{opt.type}}</option>
                  </select> -->
                </div>
                <div :class="getTagFieldClasses(modalWidth)">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="tag.age" :options="options.age" :multiple="false" :close-on-select="true"
                  :placeholder="$t('taggingFilter-age')" label="type" track-by="id" selectLabel="" deselectLabel=" " class="input"
                  @input="tag.hasChanged = true"
                  :class="getClass(tag.species, 'age')" :disabled="isSelectDisabled(tag.species, 'age')">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc('common-speciesSelected', values.length, {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                  <!-- <select name="category" v-model="tag.age" required="true" class="modified-select">
                    <option :value="null" disabled>Age</option>
                    <option v-for="opt in options.age" :value="opt.id" :key="'spp' + opt.id">{{opt.type}}</option>
                  </select> -->
                </div>
                <div :class="getTagFieldClasses(modalWidth, '1')">
                  <input type="text" class="individual" :placeholder="$t('taggingFilter-individualNum')" :class="getClass(tag.species, 'count')"
                    :readonly ="!isEditable"
                    @input="tag.hasChanged = true"
                    v-model="tag.individualCount"/>
                </div>
                <div :class="getTagFieldClasses(modalWidth, '2')">
                  <div class="col s12" :class="{padtop10: !isEditable}">
                    <label :for="'reviewed' + index" :class="getClass(tag.species, 'age')" class="need-review">
                      <input type="checkbox" :disabled="!isEditable" :id="'reviewed' + index" name="review" v-model="tag.needsReview" @change="tag.hasChanged = true">
                      <span :class="{inactive: !isEditable}">{{$t('cameraTagging-review')}}</span>
                    </label>
                  </div>
                  <div class="col s12">
                    <template v-if="isEditable">
                      <span @click="duplicateTag(tag)" class="ion ion-ios-copy-outline link" :class="getClass(tag.species, 'age')" :title="$t('recordingForms-duplicateTag')"></span>
                      <span @click="toggleComments(tag)" class="ion ion-ios-list-outline link" :title="$t('cameraTagging-showCommentField')"></span>
                      <span @click="deleteTag(index)" class="ion ion-android-remove-circle link" :title="$t('aruVerificationModal-deleteTag')" v-if="!isBatchSelected"></span>

                      <!-- <span v-if="isBatchSelected" @click="batchSaveTag(tag)" class="ion ion-android-upload link" title="show comment field"></span> -->
                      <span @click="batchDeleteTag(tag, index)" class="ion ion-android-delete link" :title="$t('cameraTagging-deleteTag')" v-if="isBatchSelected && tag.isLocked === false">
                        <span class="ion ion-android-delete link" :title="$t('cameraTagging-deleteTag')" style="font-size:14px"></span>
                      </span>
                      <span @click="deleteTag(index)" class="ion ion-android-remove-circle link" :title="$t('cameraTagging-deleteTag')" v-if="isBatchSelected && tag.isLocked == null"></span>
                      <!-- <span v-if="isBatchSelected && tag.id != null && tag.hasChanged === true" class="btn batchSaveButton" @click="batchUpdateTag(tag, index)" title="batch save tags">Save </span>
                      <span v-if="isBatchSelected && tag.id == null && tag.hasChanged === true" class="btn batchSaveButton" @click="batchInsertTag(tag, index)" title="batch save tags">Save</span> -->
                      <span v-if="tag.hasChanged">*</span>
                    </template>
                  </div>
                </div>
                <div class="row" v-show="tag.showComments" v-if="isEditable">
                  <div class="col s12">
                    <textarea  @input="tag.hasChanged = true" :placeholder="$t('common-comments')" class="tag-comments" rows ="1"  v-model="tag.comments"/>
                  </div>
                </div>
                <div class="row" v-if="!isEditable && tag.comments != null">
                  <span>{{tag.comments}}</span>
                </div>
              </div>
            </template>
            <div class="row even" v-if="isEditable && !loading" v-show="hasTag">
              <span class="ion icon spp-new-tag"></span><div class="spp-column col s5"><a class="btn btn-success add-spp"  @click="addTag">+</a></div>
            </div>
            <div class="row" v-if="isEditable && !loading">
              <a class='btn btn-success' @click="batchSaveAll(true)" v-if="isBatchSelected" >{{$t('cameraTagging-saveTagsAndClose')}}</a>
              <a class='btn btn-success' @click="batchSaveAll(false)" v-if="isBatchSelected" >{{$t('cameraTagging-saveTags')}}</a>
              <a class='btn' @click="noTag" v-show="false && hasTag" v-if="!isBatchSelected" >{{$t('No Species Tag')}}</a>
              <a class='btn' @click="hasTag =true" v-show="false && !hasTag" v-if="!isBatchSelected" >{{$t('Has Species Tag')}}</a>
            </div>
            <!-- <h4 id="modalTag">Other</h4> -->
            <div class="row" >
              <div class="col s12 m5" :class="{'l12': isBatchSelected, 'l6': !isBatchSelected}">
                <!-- <select name="category" v-model="localTag.task.fov" required="true" class="modified-select">
                  <option :value="null" disabled>Field Of View</option>
                  <option v-for="opt in options.fieldOfView" :value="opt.id" :key="'spp' + opt.id">{{opt.type}}</option>
                </select>  -->
                <div :class="{'col s12 m8': isBatchSelected}">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="localTag.task.fov" :options="options.fieldOfView" :multiple="false" :close-on-select="true"
                    :disabled="!isEditable"
                    :placeholder="$t('cameraTagging-fieldOfView')" label="type" track-by="id" selectLabel="" deselectLabel=" " class="input">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values.length && !isOpen">{{ $tc('aruVerificationTagForm-numFOVSelected', values.length, {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                  <!-- <select name="category" v-model="localTag.task.fov" required="true" class="modified-select">
                    <option :value="null" disabled>Field Of View</option>
                    <option v-for="opt in options.fieldOfView" :value="opt.id" :key="'spp' + opt.id">{{opt.type}}
                    </option>
                  </select>  -->
                </div>
                <div class="col s12 m4" v-if="isBatchSelected && isEditable && !loading">
                  <button class='btn btnimportant right btn-success' @click="batchUpdateTaskProperty('fov')">{{$t('common-update')}}</button>
                </div>
              </div>
              <div class="col s12 m7" :class="{'l2 padtop10': !isBatchSelected, 'l12': isBatchSelected}">
                <div :class="{'col s12 m8 padtop10': isBatchSelected}">
                  <label for="featured">
                    <input type="checkbox" id="featured" name="featured" :disabled="!isEditable" v-model="localTag.task.nice">
                    <span :class="{inactive: !isEditable}">{{$t('Nice')}}</span>
                  </label>
                </div>
                <div :class="{'col s12 m4 right': isBatchSelected}" v-if="isBatchSelected && isEditable && !loading" >
                  <button class='btn btnimportant right btn-success' @click="batchUpdateTaskProperty('nice')">{{$t('common-update')}}</button>
                </div>
              </div>
              <div class="col s12 m6" :class="{'l2 padtop10': !isBatchSelected, 'l12': isBatchSelected}">
                <div :class="{'col s12 m8 padtop10': isBatchSelected}">
                  <label for="fire">
                    <input type="checkbox" id="fire" name="fire" :disabled="!isEditable && !loading" v-model="localTag.task.fire">
                    <span :class="{inactive: !isEditable}">{{$t('cameraTagging-fire')}}</span>
                  </label>
                </div>
                <div class="col s12 m4" v-if="isBatchSelected && isEditable && !loading">
                  <button class='btn right btn-success' @click="batchUpdateTaskProperty('fire')">{{$t('common-update')}}</button>
                </div>
              </div>
                <div class="col s12 m6" :class="{'l2 padtop10': !isBatchSelected, 'l12': isBatchSelected}">
                <div :class="{'col s12 m8 padtop10': isBatchSelected}">
                  <label for="isMalfunction">
                    <input type="checkbox" id="isMalfunction" name="isMalfunction" :disabled="!isEditable" v-model="localTag.task.malfunction ">
                    <span :class="{inactive: !isEditable}">{{$t('cameraTagging-malfunction')}}
                        <span  class="info-icon ion-information-circled" v-tooltip="tooltips.malfunction"></span>
                    </span>
                  </label>
                </div>
                <div :class="{'col s12 m4 right': isBatchSelected}" v-if="isBatchSelected && isEditable && !loading" >
                  <button class='btn btnimportant right btn-success' @click="batchUpdateTaskProperty('malfunction')">{{$t('common-update')}}</button>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="col s12">
                <div :class="{'col m8': isBatchSelected}">
                  <label v-if="isEditable" >{{$t('common-comments')}}:
                    <textarea class="tagform-textarea" v-model="localTag.task.comments"></textarea>
                  </label>
                  <div v-if="!isEditable && localTag.task.comments">{{$t('common-comments')}}: <span v-if="!isEditable">{{localTag.task.comments}}</span></div>
                </div>
                <div class="col s12 m4" style="padding-top:40px;" v-if="isBatchSelected && isEditable && !loading">
                  <button class='btn btn-success right' @click="batchUpdateTaskProperty('comments')">{{$t('common-update')}}</button>
                </div>
              </div>
            </div>
            <div class="row" >
              <button v-if="!isBatchSelected && isEditable" class='dropdown-trigger btn  btn-success' data-target='save-options' @click="submitForm()" :disabled="!hasFormModified">{{$t('cameraTagging-saveAllAndClose')}}</button>
              <button v-if="!isBatchSelected && isEditable" class='dropdown-trigger btn  btnimportant' data-target='save-options' @click="resetForm()" :disabled="!hasFormModified">{{$t('common-reset')}}</button>
              <button class="btn btn-border modal-action modal-close" :class="{disabled: loading}" id="cancelAdd" @click="closeForm()">{{$t('common-close')}}</button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
/* this component show update and batch upload from
update single image: user enters all informaiton and click submit button to save all
update batch images:
  first: pass in id array,
  then update tags one by one,
  and task information one by one.
*/
import {API_URL, confirmDialog, confirmDialog3Button, alertDialog, copyObject, compareObject} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'camera-tagging-form',
  props: {
    id: { type: [Number, String, Array], required: true },
    formName: {type: String},
    options: {type: Object},
    forUntagged: {
      type: Boolean, default: false
    },
    deploymentId: {type: [Number, String]},
    isEditable: {
      type: Boolean,
      default: false
    },
    filterMode: {
      type: String,
      default: 'tag'
    }
  },
  components: {'multiselect': Multiselect, 'pulse-loader': PulseLoader},
  created () {
    if (this.id != null) {
      // check if it is batch operation or not
      if (Array.isArray(this.id) && this.id.length > 1) {
        this.isBatchSelected = true;
      } else {
        this.isBatchSelected = false;
      }
      if (!this.forUntagged) { // don't load data for untagged.
        this.loadTagDetails(this.id);
      } else { // for all untagged
        this.localTag = {speciesTags: [], task: {id: this.id}}; // initial value
        this.orgTag = {speciesTags: [], task: {id: this.id}}; // initial value
        this.addBlankTagRow();
      }
    }
  },
  mounted () { // manually open modal box when mounted
    this.$modal.show(this.formName);
    this.modalWidth = window.document.body.clientWidth * 0.65;
  },
  watch: {
  //   id (newVal, oldVal) {
  //   if (newVal && newVal !== oldVal) {
  //     // check if it is batch operation or not
  //     if (Array.isArray(newVal) && newVal.length > 1) {
  //       this.isBatchSelected = true;
  //     } else {
  //       this.isBatchSelected = false;
  //     }
  //     this.loadTagDetails(this.id);
  //   }
  //   },
    localTag: {
      handler (newVal, oldVal) {
        if (newVal == null) {
          return;
        }
        if (oldVal == null) { // when it is inital load setting
          this.hasFormModified = false;
          return;
        }
        if (this.isBatchSelected) {
          // console.log('newVal =', newVal.speciesTags, 'orgTag',this.orgTag.speciesTags)
          if (compareObject(newVal.speciesTags, this.orgTag.speciesTags)) { // when new value equals orginal tag, it is not modified
            this.hasFormModified = false;
            return;
          }
        } else {
          // console.log('newVal = all', newVal, 'orgTag',this.orgTag)
          if (compareObject(newVal.task, this.orgTag.task) && compareObject(newVal.speciesTags, this.orgTag.speciesTags, ['showComments', 'hasChanged'])) { // when new value equals orginal tag, it is not modified
            this.hasFormModified = false;
            return;
          }
        }
        this.hasFormModified = true;
      },
      deep: true
    }
  },

  methods: {
    getSpeciesOptClass (option) {
      if (option.className === 'AVES') return 'species AVES ion-social-twitter';
      if (option.className === 'NONE') return 'species NONE ion-android-walk';
      return 'species OTHER ion-ios-paw';
    },
    /* tag opertaion */
    addTag () {
      // this.localTag.speciesTags.push({id: null, age: null, sex: null, species: null, taskId: this.id, hasChanged: true, useSubsample: this.useSubsample}); // need to put task id in.
      this.addBlankTagRow();
    },

    addBlankTagRow () {
      // this.orgTag.speciesTags = [{id: null, age: null, sex: null, species: null, taskId: this.id, isBlank: true, hasChanged: true}];
      if (!this.isEditable) {
        return;
      }

      if (this.localTag == null) {
        this.localTag = {speciesTags: [], task: {}};
      }
      this.localTag.speciesTags.push({id: null, age: null, sex: null, species: null, taskId: this.id, isBlank: true, hasChanged: true});
    },
    getTitleAndLocation () {
      const title = this.$t('cameraTagging-taggingForm');
      if (this.localTag && this.localTag.locations && this.localTag.locations.name) {
        return title + ' - ' + this.localTag.locations.name;
      } else {
        return title;
      }
    },
    getModalWidth () {
      this.modalWidth = this.$refs.tagForm.$refs.modal.clientWidth;
    },
    getScrollClass () {
      if (this.$refs.tagForm.$refs.modal.clientHeight < this.$refs.scroller.clientHeight + 40) {
      }
    },
    /* based on modal size, set class */
    getTagFieldClasses (width, colWidth) {
      let classes = {
        'col': true,
        's4': width >= 600 & width < 800,
        's6': width < 600 && width > 400,
        's12': width <= 400
      };
      if (colWidth) {
        classes['s' + colWidth] = (width >= 800);
      } else {
        classes['s2'] = (width >= 800);
      }
      return classes;
    },
    closeForm () {
      if (this.loading) {
        return;
      }
      this.validationMsg = []; // clear validation message
      this.error = [];
      this.message = [];
      const self = this;
      const unsavedWarning = this.$t('cameraTagging-unsavedWarning');
      const unsavedWarningBatch = this.$t('cameraTagging-unsavedWarningBatch');
      if (this.hasFormModified && !this.isBatchSelected) { // when form modified (only in single tag mode), confirm if want to be saved
        confirmDialog3Button(this.$modal, this.$t('common-unsavedChanges'), unsavedWarning, this.$t('common-save'), this.submitForm,
          this.$t('common-close'), function () { eventBus.$emit('close-tagging-form', self.formName, false); },
          this.$t('common-cancel'));
        // return;
      } else if (this.hasFormModified && this.isBatchSelected) {
        // this.localTag = copyObject(this.orgTag); // reset it to original value
        confirmDialog(this.$modal, this.$t('common-unsavedChanges'), unsavedWarningBatch,
          this.$t('common-close'), function () { eventBus.$emit('close-tagging-form', self.formName, false); },
          this.$t('common-cancel'));
        // return;
      } else {
        eventBus.$emit('close-tagging-form', self.formName, false);
      }
    },
    refreshImages () {
      eventBus.$emit('refresh-tag');
    },
    copyTag (data) {
      this.orgTag = copyObject(data);
      this.localTag = copyObject(data);
    },
    async loadTagDetails () { // load tag details, can be batch load or single load
      let response;
      try {
        if (this.isBatchSelected) {
          response = await this.$http.post(this.getBatchTagAPI, {taskIds: this.id});
        } else {
          response = await this.$http.get(this.getTagAPI, {params: {taskId: this.id}});
        }
      } catch (err) {
        this.error = err;
        // this.orgTag = {};
        // this.localTag = {};
        this.addBlankTagRow();
        return;
      }
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
        // this.orgTag = {};
        // this.localTag = {};
        this.addBlankTagRow();
        return;
      }
      this.copyTag(response.data);
      if (!this.orgTag.speciesTags || this.orgTag.speciesTags.length === 0) { // put in default blank values
        this.addBlankTagRow();
      } else if (this.isBatchSelected) {
        if (!this.orgTag.speciesTags.find(x => { return x.isLocked === false })) { // to check if there are any common tags, if not, add blank row,
          this.addBlankTagRow();
        }
      }
      // await this.$http.get(this.isBatchSelected ? this.getBatchTagAPI : this.getTagAPI, {params: {taskId: this.id}}).then((response) => {
      //   this.copyTag(response.data);

      //   if (!this.orgTag.speciesTags || this.orgTag.speciesTags.length === 0) { // put in default blank values
      //    this.addBlankTagRow ();
      //   } else if (this.isBatchSelected) {
      //     if (!this.orgTag.speciesTags.find(x => { return x.isLocked === false})) { // to check if there are any common tags, if not, add blank row,
      //       this.addBlankTagRow ();
      //     }
      //   }
      // }, (err) => {
      // // error callback
      //   this.error = err;
      //   // this.orgTag = {};
      //   // this.localTag = {};
      //  this.addBlankTagRow ();
      // });
    },
    noTag () { // when marked as no tag, only one species tag is allowed.
      this.hasTag = false;
      const noSpecies = this.options.allSpecies.find(x => { return x.commonName === 'NONE'; });
      const noneTag = {
        taskId: this.id,
        species: noSpecies,
        sex: this.options.sex.find(x => { return x.type === 'VNA' }),
        age: this.options.age.find(x => { return x.type === 'VNA'; }),
        individualCount: 'VNA'
      };
      this.localTag.speciesTags = [];
      this.localTag.speciesTags.push(noneTag);
      // this.localTag.speciesTags = [{taskId: this.id, species: species, sex: this.options.sex.find(x => { return x.type=='VNA'}),
      //   age: this.options.age.find(x => { return x.type=='VNA'; }),
      //   individualCount: 'VNA'
      // }];
    },

    duplicateTag (tag) {
      if (!this.isEditable) {
        return;
      }
      let copiedTag = copyObject(tag);
      copiedTag.id = null;
      copiedTag.hasChanged = true;
      this.$delete(copiedTag, 'isLocked'); // for batch updated don't use this flag for unsaved item
      this.localTag.speciesTags.push(copiedTag); // copy tag, however, remove tag id
    },
    deleteTag (index) {
      if (!this.isEditable) {
        return;
      }
      if (this.localTag.speciesTags[index].isBlank) { // when delete default blank row, delete orginal tag blank row too
        this.orgTag.speciesTags.splice(index, 1);
      }
      this.localTag.speciesTags.splice(index, 1);
    },
    validationForm () {
      /* step 1: form validation:
        3. tags required all values filled
        2. tags can't be duplicated.
        1. minimum one tag, if anyone tag is none, you can't have other tags.
      */
      this.validationMsg = []; // clear validation message
      this.error = [];
      if (!this.localTag.speciesTags || this.localTag.speciesTags.length === 0) {
        this.validationMsg.push(this.$t('cameraTagging-errors.needTag'));
      } else {
        // if any species tag misses sex or age, or count, leave a warning
        if (this.localTag.speciesTags.find(spp => { return !spp.isBlank && (!spp.species || !spp.sex || !spp.age || !spp.individualCount) })) {
          this.error.push(this.$t('cameraTagging-errors.requiredAttributes'))
        }
        if (this.localTag.speciesTags.find(spp => { return spp.isBlank && (!spp.species || !spp.sex || !spp.age || !spp.individualCount) })) {
          this.validationMsg.push(this.$t('cameraTagging-errors.blankShouldBeNone'))
        }
        // if any species tag is none, you can't have other tags.
        if (this.localTag.speciesTags.find(spp => { return spp.species && spp.species.commonName === 'NONE' }) && this.localTag.speciesTags.length > 1) {
          this.error.push(this.$t('cameraTagging-errors.noneMeansOthersNotAllowed'))
        }
        if (this.localTag.speciesTags.find(spp => { return !isNaN(parseInt(spp.individualCount)) && parseInt(spp.individualCount) < 1; })) {
          this.error.push(this.$t('cameraTagging-errors.speciesCountMustBeNumber'));
        }
        if (!(!this.localTag.speciesTags || this.localTag.speciesTags[0].isBlank)) {
        // must keep 1 equal sign or else you cant submit a tag vvv
        // eslint-disable-next-line eqeqeq
          if (this.localTag.speciesTags.find(spp => { return spp.individualCount.length > 0 && (parseInt(spp.individualCount) != spp.individualCount || isNaN(parseInt(spp.individualCount))) && (spp.individualCount !== 'VNA'); })) {
            this.error.push(this.$t('cameraTagging-errors.speciesCountCanBeVna'));
          }
        }
        //
        // // if anyone tag is none, you can't have other tags.
        // if (this.localTag.speciesTags.find(spp => { return !spp.species })) {
        //   this.error.push('Species is required for all tags')
        // }
        //
        const tags = this.localTag.speciesTags.map(spp => { return (spp.species ? spp.species.id : -1) + '-' + (spp.sex ? spp.sex.id : -1) + '-' + (spp.age ? spp.age.id : -1); });
        if ((new Set(tags)).size !== tags.length) {
          this.error.push(this.$t('cameraTagging-errors.removeDuplicatedTags'));
        }

        if (this.localTag.task.fov == null) {
          this.validationMsg.push(this.$t('cameraTagging-errors.fovRequiredDefaultIsWithin'))
        }
      }
    },
    /* form submission */
    submitForm () {
      this.validationForm();
      if (this.error.length > 0) {
        alertDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationErrorMsg);
        return;
      }
      if (this.validationMsg.length > 0) {
        confirmDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationWarningMsg + '<br><span class="red-font">' + this.validationMsg + '</span>', this.$t('common-yes'), this._updateSingleTag); // function () {
        return;
      }
      // no error or warning, send update
      this._updateSingleTag();
    },

    _updateSingleTag () {
      this.message = [];
      this.validationMsg = [];
      this.error = [];
      this.loading = true;
      if (this.localTag.task.fov == null) {
        this.localTag.task.fov = {id: 0}; // set fov to default within
      }
      let tag = copyObject(this.localTag);
      /* filter out, blank rows, with all blank values */
      tag.speciesTags = tag.speciesTags.filter(spp => !(spp.isBlank && (!spp.species && !spp.sex && !spp.age && !spp.individualCount)));

      // If there are species defined as NONE then set the review flag to false.
      tag.speciesTags.forEach(e => {
        // None is id 39
        if (e.species.id === 39) { e.needsReview = false; }
      });
      this.$http.post(this.updateSingleTagAPI, tag).then(function (response) {
        this.loading = false;
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('recordingPage-updateFailed') + ':' + response.data.error);
        } else {
          this.copyTag(response.data);
          this.hasFormModified = false;
          this.message.push(this.$t('cameraTagging-formUpdated'));
          this.reloadOptions();
          this.refreshImages();
          eventBus.$emit('close-tagging-form', this.formName, false);
        }
      }, (err) => {
      // error callback
        this.loading = false;
        this.orgTag = {};
        this.localTag = {};

        this.hasFormModified = false;
        this.error.push(this.$t('cameraTagging-formUpdateFailed') + ' ' + err);
      });
    },
    // copy server tag to local tag, to reset
    resetForm () {
      // this.localTag = null;
      this.resetMsg();
      this.localTag = copyObject(this.orgTag); // reset it to original value
    },
    getClass (species, selectType) { // when selected NONE, hide sex, age, and count
      if (species && species.id === 1362) {
        return {hidden: true};
      }
    },
    isSelectDisabled (species, selectType) { // when select NONE, ATV, etc, disable sex and age
      if (!this.isEditable || (species && species.id === 1362)) {
        return true;
      }
    },
    /*
      when use select an species, when sex, age, individual not entered, fill in default values
      all previous values will be erased except switching to mammals.
    */
    speciesChanged (tag, id, $event) {
      this.$set(tag, 'hasChanged', true);
      if (tag.species) {
        // for none, bird, and domestic animal prefill all values with VNAs
        if (tag.species.className === 'NONE') { // when set as none, unknown, staff etc, set sex and age as VNA
          this.$set(tag, 'sex', this.options.sex.find(x => { return x.type === 'VNA' }));
          this.$set(tag, 'age', this.options.age.find(x => { return x.type === 'VNA' }));
          this.$set(tag, 'individualCount', 'VNA');
        } else if (tag.species.className === 'AVES' || tag.species.commonName.indexOf('Domestic') !== -1) {
          this.$set(tag, 'sex', this.options.sex.find(x => { return x.type === 'VNA' }));
          this.$set(tag, 'age', this.options.age.find(x => { return x.type === 'VNA' }));
          this.$set(tag, 'individualCount', 'VNA');
        } else if (tag.species.className == null || tag.species.className === 'MAMMALIA') {
          // for other animal prefill with UNKN sex, age, and 1 for count
          const defaultSex = this.options.sex.find(x => { return x.type === 'Unkn' });
          const vnaSex = this.options.sex.find(x => { return x.type === 'VNA' });
          this.$set(tag, 'sex', tag.sex && tag.sex.id !== vnaSex.id ? tag.sex : defaultSex);

          const defaultAge = this.options.age.find(x => { return x.type === 'Adult' });
          const vnaAge = this.options.age.find(x => { return x.type === 'VNA' });
          this.$set(tag, 'age', tag.age && tag.age.id !== vnaAge.id ? tag.age : defaultAge);
          this.$set(tag, 'individualCount', tag.individualCount && tag.individualCount !== 'VNA' ? tag.individualCount : '1');
        }

        if (tag.species.commonName === 'NONE') { // if selected NONE
          this.hasTag = false;
        } else {
          this.hasTag = true;
        }
      }
    },
    toggleComments (tag) {
      this.$set(tag, 'showComments', !tag.showComments);
    },
    validationSingleTag (spp, index, callback) {
      /* step 1: form validation:
        3. tags required all values filled
        2. tags can't be duplicated.
        1. minimum one tag, if anyone tag is none, you can't have other tags. */

      if (!spp.id && !spp.species && !spp.sex && !spp.age && !spp.individualCount) {
        // if new record with no info a spp has no infor, ignore
        return;
      }
      // if any species tag misses sex or age, or count, leave a warning

      if (!spp.species || !spp.sex || !spp.age || !spp.individualCount) {
        this.error.push(this.$t('cameraTagging-errors.requiredAttributes'));
      }

      // // if anyone tag is none, you can't have other tags.
      // if (!spp.species) {
      //   this.error.push('Species is required for all tags');
      // }
      //
      let bDuplicated = false;
      const currentValueString = (spp.species ? spp.species.id : -1) + '-' + (spp.sex ? spp.sex.id : -1) + '-' + (spp.age ? spp.age.id : -1);

      // compare to all other values with id (i.e. already submitted), if matched, duplicated occurred
      for (let i = 0; i < this.localTag.speciesTags.length; i++) {
        const item = this.localTag.speciesTags[i];
        if (index !== i && item.id != null) { // for db records only, not including ui records but no saved.
          const sppString = (item.species ? item.species.id : -1) + '-' + (item.sex ? item.sex.id : -1) + '-' + (item.age ? item.age.id : -1);
          if (sppString === currentValueString) {
            this.error.push(this.$t('cameraTagging-errors.itemAlreadyExists', {item: (item.species ? item.species.commonName : 'VNA') + '-' + (item.sex ? item.sex.type : 'VNA') + '-' + (item.age ? item.age.type : 'VNA')}));
          }
        }
      }

      if (bDuplicated) {
        this.error.push(this.$t('cameraTagging-errors.tagAlreadyExists'));
      }
      /* compare current species with other db records */
      for (let i = 0; i < this.orgTag.speciesTags.length; i++) {
        const item = this.orgTag.speciesTags[i];
        if (item.id != null && !compareObject(spp.id, item.id)) { // for db records only, not including ui records but no saved.
          const sppString = (item.species ? item.species.id : -1) + '-' + (item.sex ? item.sex.id : -1) + '-' + (item.age ? item.age.id : -1);
          if (sppString === currentValueString) {
            this.error.push(this.$t('cameraTagging-errors.itemAlreadyExists', {item: (item.species ? item.species.commonName : 'VNA') + '-' + (item.sex ? item.sex.type : 'VNA') + '-' + (item.age ? item.age.type : 'VNA')}));
          }
        }
      }
      this.error = [...new Set(this.error)];
      if (callback) { // if action provided, do confirmation and then call the action
        if (this.error.length > 0) {
          alertDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationErrorMsg);
          return false;
        }
        if (this.validationMsg.length > 0) {
          confirmDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationWarningMsg, this.$t('common-yes'), callback); // function () {
          return false;
        }

        callback();
      }
      return true;
    },
    /* for batch */
    batchUpdateTag (tag, index) { // batch update validation
      const self = this;
      this.resetMsg();
      this.validationSingleTag(tag, index, function () { self._batchUpdateTag(tag, index); });
    },

    async _batchUpdateTag (tag, index, bNoRefresh) { // batch update action
      /* copy from tag, only including ones that is not null */
      // let params =  params = Object.assign({}, tag); // copy tag to params
      const url = (this.forUntagged ? this.updateAllUntaggedAPI : this.batchUpdateTagAPI);
      return new Promise((resolve, reject) => {
        if (!tag.id && !tag.species && !tag.sex && !tag.age && !tag.individualCount) { // skip all empty fields
          resolve();
        } else {
          this.$http.put(url, tag).then(function (response) {
            if (response.data.hasOwnProperty('error')) {
              this.error.push(this.$t('cameraTagging-batchUpdateFailed', {name: (tag.species && tag.species.commonName), error: response.data.error}));
            } else {
              // this.orgTag.speciesTags.push(response.data); // always update orgTag first. as watched is on localTag only
              let newTag = response.data;
              this.$set(this.orgTag.speciesTags, index, newTag);
              this.$set(this.localTag.speciesTags, index, newTag);
              this.message.push(this.$t('cameraTagging-batchUpdateSuccessful', {name: tag.species.commonName}));
              this.reloadOptions();
            }
            if (!bNoRefresh) {
              this.refreshImages();
            }
            resolve();
          }, (err) => {
            this.error.push(this.$t('cameraTagging-batchUpdateFailed', {name: tag.species.commonName, error: err}));
            resolve();
          });
        }
      });
    },

    batchInsertTag (tag, index) { //
      const self = this;
      this.resetMsg();
      this.validationSingleTag(tag, index, function () { self._batchInsertTag(tag, index); });
    },
    async _batchInsertTag (tag, index, bNoRefresh) {
      // console.log('insert tag ==', tag)

      return new Promise((resolve, reject) => {
        if (!tag.id && !tag.species && !tag.sex && !tag.age && !tag.individualCount) { // skip all empty fields
          resolve();
        } else {
          // insert-camera-species-tags?taskId[]=8609404&taskId[]=13297316&ageId=1&sexId=7&needsReview=false&speciesId=17
          this.$http.put(this.batchInsertTagAPI, tag).then(function (response) {
            if (response.data.hasOwnProperty('error')) {
              this.error.push(this.$t('cameraTagging-batchInsertFailed', {name: (tag.species && tag.species.commonName), error: response.data.error}));
            } else {
              if (this.orgTag.speciesTags.length === 1 && this.orgTag.speciesTags[0].hasChanged) { // remove default blank row
                this.orgTag.speciesTags = [];
              }
              let newTag = response.data;
              this.$set(this.orgTag.speciesTags, index, newTag);
              this.$set(this.localTag.speciesTags, index, newTag);
              this.message.push(this.$t('cameraTagging-batchInsertSuccessful', {name: tag.species.commonName}));
              this.reloadOptions();
            }
            if (!bNoRefresh) {
              this.refreshImages();
            }
            resolve();
          }, (err) => {
            this.error.push(this.$t('cameraTagging-batchInsertFailed', {name: tag.species.commonName, error: err}));
            resolve();
          });
        }
      });
    },
    _batchDeleteTag (tag, index) {
      this.loading = true;
      this.$http.delete(this.batchDeleteTagAPI, {body: tag}).then(function (response) { // {params tag} => url request param, {body: tag} => body
        this.loading = false;
        if (response.data.hasOwnProperty('error')) {
          this.error.push({ msg: this.$t('cameraTagging-batchDeleteFailed', {name: tag.species.commonName, error: response.data.error}), error: response.data.error });
        } else {
          this.orgTag.speciesTags.splice(index, 1);
          this.localTag.speciesTags.splice(index, 1);

          this.message.push(this.$t('cameraTagging-batchDeleteSuccessful', {name: tag.species.commonName}));
        }
        this.reloadOptions();
        this.refreshImages();
      }, (err) => {
        this.loading = false;
        this.error.push(this.$t('cameraTagging-batchDeleteFailed', {name: tag.species.commonName, error: err}));
      });
    },
    batchDeleteTag (tag, index) {
      this.resetMsg();
      let self = this;
      confirmDialog(this.$modal, this.$t('cameraTagging-batchDelete'), this.$t('cameraTagging-batchDeleteWarning'), this.$t('common-yes'),
        function () { self._batchDeleteTag(tag, index); });
    },
    resetMsg () {
      this.message = [];
      this.error = [];
      this.validationMsg = [];
    },
    batchUpdateTaskProperty (propertyName) {
      let params = { id: this.orgTag.task.id };
      /* when values not null, include them */
      if (this.localTag.task[propertyName] != null) {
        params[propertyName] = this.localTag.task[propertyName];
      } else {
        this.validationMsg.push(this.$t('cameraTagging-nothingToBeUpdated'));
        return;
      }

      this.$http.put(this.batchUpdateTaskAPI, params).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error.push(this.$t('cameraTagging-batchUpdateFailed', {name: propertyName.toUpperCase(), error: response.data.error}));
        } else {
          this.orgTag.task[propertyName] = this.localTag.task[propertyName]
          this.message.push(this.$t('cameraTagging-batchUpdateSuccessful', {name: propertyName.toUpperCase()}));
          /* if update FOV, need to update image/pagination */
          if (propertyName === 'fov') {
            this.refreshImages();
          }
        }
      }, (err) => {
        this.error.push(this.$t('cameraTagging-batchUpdateFailed', {name: propertyName.toUpperCase(), error: err}));
      });
    },
    /* try find all batchSaveButton and click them all */
    batchSaveAll (bCloseForm) {
      this.resetMsg()
      /* option 1, find buttons from DOM, and trigger click */
      // batchButtons = document.querySelectorAll('#tagging-form .batchSaveButton');
      // batchButtons.forEach(function (submitBtn) {
      //   submitBtn.click();
      // });
      /* option 2: loop through tags, and save them all */
      let hasAnyChange = false;
      this.localTag.speciesTags.forEach((tag, index) => {
        if (tag.hasChanged) {
          this.validationSingleTag(tag, index, null);
          hasAnyChange = true;
        }
      })

      if (!hasAnyChange) { // nothing changed, do nothing;
        if (bCloseForm) {
          this.closeForm();
        }
        return;
      }
      if (this.error.length > 0) {
        alertDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationErrorMsg);
        return;
      }
      const self = this;
      if (this.validationMsg.length > 0) {
        confirmDialog(this.$modal, this.$t('cameraTagging-errors.validationFailed'), this.validationWarningMsg, this.$t('common-yes'), function () { self._batchUpdate(bCloseForm); });
        return;
      }
      self._batchUpdate(bCloseForm);
    },
    async _batchUpdate (bCloseForm) {
      let self = this;
      let updateTasks = [];

      await self.localTag.speciesTags.forEach(async (tag, index) => {
        if (tag.hasChanged) { // don't update when not changed
          if (tag.id) { // update
            updateTasks.push(self._batchUpdateTag(tag, index, true));
          } else { // insert
            updateTasks.push(self._batchInsertTag(tag, index, true));
          }
        }
      });
      this.loading = true;
      Promise.all(updateTasks).then((values) => {
        // console.log('all uploads are done!!', values, updateTasks);
        this.loading = false;
        self.refreshImages();
        if (self.error.length > 0 || self.validationMsg.length > 0) { // don't close form when error occurs
          return;
        }
        self.orgTag = copyObject(self.localTag);
        this.reloadOptions();
        if (bCloseForm) {
          self.closeForm();
        }
      });
    },
    reloadOptions () {
      eventBus.$emit('reload-static-option');
      eventBus.$emit('update-tagging-status'); // after batch update, check if all fully tagged.
    }
  },
  data () {
    return {
      getTagAPI: API_URL + 'get-species-for-task',
      getBatchTagAPI: API_URL + 'get-species-for-tasks',
      updateSingleTagAPI: API_URL + 'syncronize-camera-task',
      // insert-camera-species-tags?taskId[]=8609404&taskId[]=13297316&ageId=1&sexId=7&needsReview=false&speciesId=17
      batchInsertTagAPI: API_URL + 'insert-camera-species-tags',
      // update-camera-species-tags?ageId=2&sexId=7&needsReview=false&speciesId=19
      batchUpdateTagAPI: API_URL + 'update-camera-species-tags',
      // ?speciesTagId[]=17982595&speciesTagId[]=16219720,
      batchDeleteTagAPI: API_URL + 'delete-camera-species-tags',
      batchUpdateTaskAPI: API_URL + 'update-camera-species-tasks', // ?taskId[]=8609404&taskId[]=13297316&fire=true&nice=true&fovId=1'

      updateAllUntaggedAPI: API_URL + 'update-camera-species-tasks-all-untagged',
      insertAllUntaggedAPI: API_URL + 'insert-camera-species-tags-all-untagged',
      message: [],
      validationMsg: [],
      error: [],
      orgTag: {},
      localTag: null,
      hasTag: true,
      hasFormModified: false,
      isBatchSelected: false,
      modalWidth: null, // modal width for responsive classes
      isBatchUpdateAll: false,
      loading: false,
      validationErrorMsg: this.$t('cameraTagging-validationMessage'),
      validationWarningMsg: this.$t('cameraTagging-validationWarningMessage'),
      tooltips: {
        malfunction: this.$t('cameraTagging-tooltips.malfunction')
      }
    }
  }
}
</script>
<style scoped>
.message {
  color: rgb(255, 217, 0);
}
.message {
  color: rgb(255, 123, 0);
}
.info-icon {
  font-size: 18px;
  color: #FF9800;
}
.tooltip {
  max-width: 600px;
}
.modal-form {
  background-color: #fff;
}
.modal-content   .padded10 {
  padding: 10px 5px;
  margin-bottom: 10px;
}
/* alignment for naming section display */
#batchUploadModal .modal-content .naming .row .col   {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 15px;
  font-size: 1rem;
}

#batchUploadModal .modal-content .label {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 1rem;
}

.verticl-align {
  display: flex;
  align-items: center;
  height: 50px;
}
#batchUploadModal .modal-content input,
#batchUploadModal .modal-content select {
  height: 50px;
}

@media only screen and (min-width: 1px) {
  .modal-form {
  max-height: 600px;
  min-height: 450px;
  width: 60%;
  }
  .modal-form .modal-scroller {
    max-height: 550px;
    min-height: 400px;
  }
  .modal-form .modal-header {
    max-height: 50px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
}

/* @media only screen and (max-width: 991px) {
  .modal-form {
  max-height: 600px;
  width: 80%;
  }

  .modal-form .modal-scroller {
    max-height: 550px;
  }
  .modal-form .modal-header {
    max-height: 50px;
    padding: 10px 20px;
    margin: 5px;
    background-color: #efeeee;
    border-radius: 5px;
  }
} */

.modal-form .modal-scroller {
  overflow: auto;
  margin: 0px;
}

.row .margin-bottom-20 {
  margin-bottom: 20px!important;
}

.image-tagging .row.even {
  background-color:#f0f0f0;
  position: relative;
}
.image-tagging .row.odd {
  background-color:#f0f0f0;
  position: relative;
}
.image-tagging .row {
  padding: 4px 0 2px 0;
  margin: 2px;
}

.image-tagging select,
.ion {
  font-size: 20px;
  color: #C94412;
}
textarea.tag-comments {
  margin: 10px 0 0 0;
  min-height: 1em!important;
  background: #fff;
  }
.modal-content input.individual{
  padding-left: 5px;
  height: 40px;
  font-size: 0.8em;
  }

  input.individual:read-only{
      cursor: default;
  }

  input.location:read-only{
      cursor: default;
  }

 .form :deep(.multiselect__placeholder) {
    margin-bottom: 2px!important;
    padding-top: 0px!important;
  }
  /* label.need-review span{
    font-size: 12px;
  } */
  span.link.new {
    color:rgb(202, 123, 67);

  }

.right {
  float: right;
}

.spp-new-tag {
  font-size: 12px;
  display: block;
  float: left;
  position: absolute;
}

.hidden {
  display: none!important;
}
.batchSaveButton {
  height: 20px; padding: 0px 5px;
}
.tagform-textarea {
  min-height: 50px;
  width: 100%;
}
.form :deep(.multiselect__option.species:before) {
  padding-right: 8px;
}

.form :deep(.multiselect__option.species.AVES:before) {
  color: #4caf50;
}

.form :deep( .multiselect__option.species.NONE:before ){
  color: #607D8B;
}

.form :deep( .multiselect__option.species.OTHER:before) {
  color: #905844;
}

.form [type=checkbox]:not(:checked):disabled+span:not(.lever):before {
  border: 1px solid #948b8b;
  background-color: rgba(173, 173, 173, 0.42);
  cursor: auto;
}
.padtop10 {
  padding-top: 10px!important;
}

.btn.add-spp {
  margin-top: 5px;
  font-size: 25px;
  padding: 0 10px;
}

.disabled {
  cursor: not-allowed!important;
}

.inactive{
  cursor: default !important;
}

</style>
