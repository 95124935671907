<template>
<div class="container overflow agreement">
  <div v-if="!(statusId === 3 || statusId === 1 || statusId === 4)" class="col s12">
    <button class="btn btn-success  projectAdd" @click="createBlankAgreement">{{$t('agreement-addBtn')}}</button>
  </div>
  <ul class="vue collapsible" v-for="(agreement, index) in agreementList" :key="'agreement' + index" >
    <li >
      <div class="collapsible-header" @click="toggleTab(index)"><p><strong>{{agreement.name}}</strong>
      <span class="ion-arrow-down-b"></span></p></div>
      <div class="collapsible-body" v-show="collapseTabStatus[index]==true">
          <div class="row">
            <div class="col s12 m6 l8">
              <label>{{$t('agreementForm-fields.name')}}</label>
              <p> {{agreement.name}}</p>
            </div>
            <div class="col s12 m3 l2">
              <label >{{$t('common-startDate')}}</label>
              <p> {{agreement.startDate}}</p>
            </div>
            <div class="col s12 m3 l2">
              <label >{{$t('common-endDate')}}</label>
              <p> {{agreement.endDate}}</p>
            </div>
            <div class="col s12 m6 l12" v-if="options.projectUsageAgreement != null && agreement.uaoId != null">
              <label >{{$t('agreementForm-fields.constraints')}}</label>
              <p v-for="(opt, index) in options.projectUsageAgreement" :key="'UAO' + index">
                <span v-if="opt.id == agreement.uaoId">{{opt.type}}</span>
              </p>
            </div>
            <div class="col s12"> <h5 >{{$t('agreementForm-fields.signeeInfo')}}</h5></div>
            <div class="col s12 m6 l4">
              <label>{{$t('agreementForm-fields.fullName')}}</label>
              <p> {{agreement.signeeUserName}}</p>
            </div>
            <div class="col s12 m6 l4">
              <label>{{$t('agreementForm-fields.email')}}</label>
              <p> {{agreement.signeeEmail}}</p>
            </div>
            <div class="col s12 m6 l4" v-if="agreement.signeeOrganization != null">
              <label>{{$tc('common-organization', 1)}}</label>
              <p> {{agreement.signeeOrganization}}</p>
            </div>
            <div class="col s12 m6 l4">
              <label>{{$t('agreementForm-fields.signedDate')}}</label>
              <p> {{agreement.signeeSignedDate}}</p>
            </div>
            <div class="col s12" v-if="agreement.fileName != null">
              <h5><a class="link" @click="downloadStreamFile(getAgreementFileDownload + agreement.id, agreement.fileName, 'application/octet-stream')"><span class="icon ion-android-attach"></span> {{$t('agreement-view')}}</a></h5>
            </div>
            <div class="col s12">
              <button class="btn projectDeleteConfirm" @click="agreementRow = agreement; showForm = true;"><span class="ion-edit"></span> {{$t('common-update')}}</button>
              <button class="btn projectDeleteConfirm" @click="deleteAgreement(agreement.id)"><span class="ion-trash-a"></span> {{$t('agreement-delete')}}</button>
            </div>
          </div>
      </div>
    </li>
  </ul>

  <agreement-form v-if="showForm" v-bind:agreement="agreementRow" v-bind:show-form="showForm" v-bind:options="options"/>
</div>
</template>

<script>
/* This component is used to
1. display
2. insert / edit
3. delete
user agreements
*/

import AgreementForm from './ProjectAgreementTabForm';
import {API_URL, downloadStreamFile, getToday} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
export default {
  name: 'project-agreement',
  components: {'agreement-form': AgreementForm},
  props: ['projectId', 'userProfile', 'statusId'],
  watch: {
    projectId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.initialLoad();
      }
    }
  },
  created () {
    this.initialLoad();
    const that = this;
    eventBus.$on('reload-agreement-table', function () {
      that.loadAgreementList();
    });
    eventBus.$on('close-agreement-form', function () {
      that.showForm = false;
    });
  },
  methods: {
    initialLoad () {
      this.loadAgreementList();
      this.$http.get(this.getAgreementOptionUrl, {params: {projectId: this.projectId}}).then((response) => {
        this.options = response.data;
      }, (err) => {
      // error callback
        this.error = err;
      });
    },
    createBlankAgreement () {
      this.agreementRow = {projectId: this.projectId,
        signeeEmail: this.userProfile.email,
        signeeUserName: this.userProfile.name,
        signeeSignedDate: getToday(),
        startDate: getToday()
      };
      this.showForm = true;
    },
    loadAgreementList () {
      this.$http.get(this.getAgreementListUrl, {params: {projectId: this.projectId}}).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.agreementList = response.data;
            this.agreementList.forEach((element, index) => {
              this.collapseTabStatus.push(false);
            });
          }
        });
    },
    deleteAgreement (agreementId) {
      if (!agreementId || !confirm(this.deleteMsg)) {
        return;
      }

      this.$http.delete(this.deleteAgreementUrl, {params: {id: agreementId}}).then(
        function (data) {
          if (data.error) {
            alert(data.error);
            return false;
          } else {
            this.loadAgreementList();
            return true;
          }
        });
    },
    downloadStreamFile: function (url, fileName, contentType) {
      downloadStreamFile(url, fileName, contentType);
    },
    toggleTab: function (index) {
      // this.collapseTabStatus[index] = !this.collapseTabStatus[index]; // have to use Vue.set to change array values
      this.$set(this.collapseTabStatus, index, !this.collapseTabStatus[index]);
    }
  },
  data () {
    return {
      getAgreementOptionUrl: API_URL + 'project-usage-agreement-options',
      getAgreementListUrl: API_URL + 'get-project-usage-agreements', // get agreements by project id ?projectId=5',
      uploadAgreementFileUrl: API_URL + 'add-agreement-file-to-project', // upload a agreement file pdf or doc ?id=2
      insertUpdateAgreementUrl: API_URL + 'add-project-usage-agreement', // create or update agreement
      deleteAgreementUrl: API_URL + 'delete-project-usage-agreement', // delete an agreement by id ?id=2'
      getAgreementFileDownload: API_URL + 'get-project-usage-agreement-file?id=',
      deleteMsg: this.$t('agreement-deleteMsg'),
      agreementList: [],
      options: {},
      error: {},
      collapseTabStatus: [],
      showForm: false,
      agreementRow: {}

    }
  }
}
</script>
<style scoped>
.agreement .collapsible {
  border-top: 0px solid #ddd;
}
.agreement .collapsible-header {
  background-color: #887f7f36;
  padding: 0px 10px;
}
.agreement .collapsible-body p {
    color: #C94412;
}
.agreement h5 {
    color: #FF9800;
    font-weight: 500;
    font-size: 1.1rem;
    padding-bottom: 20px;
}
</style>
