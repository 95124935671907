/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
// import Vue from 'vue'
import { API_URL, getParameterByName, attachAuthorizaitonHeader, getAuthorizaitonHeader, isLocal } from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import { EventBus } from '@/lib/user-auth0';
import ClusterPointMap from '../mapGL/ClusterPointMap';

export default {
  components: {'location-map': ClusterPointMap},
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      var header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  methods: {
    getProjectStatus () {
      if (this.projectFormOptions && this.projectFormOptions.status) {
        const status = this.projectFormOptions.status.find(x => x.id === this.project.status);
        if (status) {
          return status.type;
        }
      }
      return '';
    },

    redirectPage (project) {
      var url = window.location.pathname + '?projectId=' + project.value + '&sensor=' + this.project.sensorId;
      window.location.href = url;
      // TODO: handle value updates, so redirect doesn't need to refresh the whole page
      // this.projectId = project.value;  need to handle all project id change here. otherwise, some form still using old projectId
    },
    async reloadProject () {
      this.statUrl = API_URL + 'get-task-user-stats-by-project?projectId=' + this.projectId;
      await this.loadProjectInfo();
      this.loadTaskOption();
      this.loadProjectUser();

      // set active tab
      if (this.project.hasTaskAccess) {
        this.activeTab = 'task'; // switch back to task page, so I don' thave to handle other tabs updating event/watch
      } else if (this.project.hasMapAccess) {
        this.activeTab = 'map';
      }
    },
    async loadProjectInfo () {
      /* get project details: name, year, description */
      try {
        const response = await this.$http.get(this.getProjectBasicInfoUrl + '?projectId=' + this.projectId);
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.project = response.data;
          if (this.expectedSensorType !== this.project.sensorId) { // wrong sensor type: i.e. query camera on ARU page.
            this.error = this.$t('tasks-sensorError');
            return;
          }
          this.isAbmi = this.project.organizationId === 5; // check if it is an ABMI project
          this.getOtherProjectUrl = API_URL + 'get-projects?projectId=' + this.projectId + '&sensorId=' + this.project.sensorId + '&filter=';
          if (this.project.defaultStorage) {
            this.AWSParam.bucket = this.project.defaultStorage[this.project.sensorId.toLowerCase() + 'Bucket'];
            this.AWSParam.region = this.project.defaultStorage.region;
            this.AWSParam.storageId = this.project.defaultStorage.id;

            if (this.project.dataSet && this.project.dataSet.name) {
              this.AWSParam.datasetName = this.project.dataSet.name;
              this.AWSParam.organizationId = this.project.dataSet.id;
            } else { // for testing
              this.AWSParam.datasetName = this.project.shortNm; // use project short name
            }
          }
          this.AWSParam.projectName = this.project.fullNm.replace(/\s/g, '_');
          this.isPublished = this.project.status === 3 || this.project.status === 1 || this.project.status === 4 || this.project.status === 6;
        }
      } catch (err) {
        // error callback
        this.warning = this.$t('tasks-projectWarning') + err;
      }
    },

    loadTaskOption () {
      let tmp = '';

      if (this.project.sensorId === 'ARU') {
        this.getTaskOptionUrl = API_URL + 'aru-task-options?projectId=';
        tmp = this.projectId;
      } else if (this.project.sensorId === 'CAM') {
        this.getTaskOptionUrl = API_URL + 'get-camera-pud-options?projectId=';
        tmp = this.projectId;
      } else {
        // for point count
        this.getTaskOptionUrl = API_URL + 'get-point-count-options';
      }

      this.$http.get(this.getTaskOptionUrl + tmp).then((response) => {
        this.options = response.data;
      }, (err) => {
        // error callback
        this.warning = this.$t('tasks-filterWarning') + err;
      });
    },
    updateProject () {
      this.$modal.show('project');
    },
    loadProjectUser () {
      this.$http.get(this.getProjectUserUrl, {params: {projectId: this.projectId}}).then((response) => {
        let users = response.data;
        this.userList = users.map(x => {
          return {id: x.user.id, text: this.truncateString(x.user.name), isAdmin: x.isAdmin};
        });
      }, (err) => {
      // error callback
        this.warning = this.$t('tasks-usersWarning') + err;
      });
    },
    toggleUploadForm (toShow) {
      this.showUploadForm = toShow;
      const self = this;
      this.$nextTick(() => {
        if (toShow) {
          self.$modal.show(this.uploadFormName);
        } else {
          self.$modal.hide(this.uploadFormName);
        }
      });
    },
    truncateString (str) {
      // truncates names in the userlist so they fit in the multiselect box
      const characterLimit = 27;
      if (str.length <= characterLimit) {
        return str;
      } else {
        let tmp = str.slice(0, characterLimit);
        tmp = tmp + '...';
        return tmp;
      }
    }
  },
  async created () {
    if (getParameterByName('projectId') != null) {
      this.projectId = parseInt(getParameterByName('projectId'));
    } else {
      this.projectId = null;
    }

    if (!this.projectId) {
      this.error = this.$t('tasks-idError');
      return;
    }

    /* set auto fill url to get other projects own by the user */

    attachAuthorizaitonHeader();
    const self = this;
    this.$http.get(API_URL + 'project-options').then(
      function (response) {
        this.projectFormOptions = response.data;
      });

    await this.reloadProject();

    this.activeTab = getParameterByName('tab');
    if (this.activeTab !== 'task' && this.activeTab !== 'species' && this.activeTab !== 'agreement' && this.activeTab !== 'map') {
      this.activeTab = 'task';
    } else {
      if (this.activeTab === 'species') {
        this.activeTab = 'species';
      }
    }

    eventBus.$on('show-upload-form', function (show) {
      self.toggleUploadForm(show);
    });

    eventBus.$on('load-project-info', function () {
      self.loadProjectInfo();
    });

    eventBus.$on('open-batch-upload', function () {
      self.toggleUploadForm(true);
    });

    /* get user name, email and prefill in agreement form */
    EventBus.$on('profile_updated', function (profile) {
      self.userProfile = profile;
    });

    eventBus.$on('load-project-user', function () {
      self.loadProjectUser();
    });
  },
  watch: {
    async projectId (newVal, oldVal) {
      if (newVal !== oldVal && newVal != null && oldVal != null) { // don't load again when project change from empty to parameter id
        await this.reloadProject();
      }
      if (oldVal != null && newVal != null) {
        window.history.pushState(null, this.project.fullNm, '?projectId=' + newVal);
      }
    }
  },
  data () {
    return {
      showUploadForm: false,
      getTaskOptionUrl: null,
      getProjectBasicInfoUrl: API_URL + 'get-project',
      getProjectUserUrl: API_URL + 'get-project-users',
      isAbmi: false,
      isPublished: null,
      userList: null,
      getOtherProjectUrl: null,
      project: { isAdmin: null },
      projectId: null,
      activeTab: 'deployment',
      userProfile: {},
      AWSParam: {},
      options: null,
      projectFormOptions: null,
      error: null,
      warning: null,
      showStats: false,
      attachment: [],
      activeSensor: null,
      publishedTooltip: this.$t('tasks-tooltip')
    }
  }
}
