var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "controlWrap row" }, [
        _c("div", { staticClass: "report-tool-items col s12 m3" }, [
          _c(
            "a",
            {
              attrs: {
                id: "btnPlay",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: _vm.$t("recordingPlayers-play")
              },
              on: { click: _vm.play }
            },
            [
              _c("span", {
                class: { "ion-play": !_vm.playMode, "ion-pause": _vm.playMode }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                id: "btnReplay",
                "data-toggle": "tooltip",
                "data-placement": "bottom",
                title: _vm.$t("recordingPlayers-replay")
              },
              on: { click: _vm.jwp_replay }
            },
            [_c("span", { class: { "ion-refresh": !_vm.playMode } })]
          ),
          _vm._v(" "),
          _c("div", { attrs: { id: "media_position" } }, [
            _vm._v(_vm._s(_vm.mediatPositonText) + " "),
            !_vm.canPlayAudio
              ? _c(
                  "span",
                  {
                    staticClass: "error link",
                    on: { click: _vm.playAudioIssue }
                  },
                  [_vm._v(_vm._s(_vm.$t("common-audioProblems")))]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "playBar",
            staticClass: "play-area col s12 m9",
            on: { click: _vm.moveProgress }
          },
          [
            _c("div", {
              staticClass: "report-player-progress",
              style: "width:" + _vm.progressPixels + "px"
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("div", { attrs: { id: "jwplayer_wrap" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }