var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col s12 panel boxed padded60 dataGroup tab-content" },
        [
          _vm.isEditable
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  on: {
                    click: function($event) {
                      return _vm.toggleCreateNewVisitForm(true)
                    }
                  }
                },
                [
                  _c("span", { staticStyle: { "font-size": "20px" } }),
                  _vm._v(_vm._s(_vm.$t("visitSummaryTable-createVisit")))
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "dropdown-button",
            { staticClass: "right" },
            [
              _c("template", { slot: "menuTitle" }, [
                _vm._v(_vm._s(_vm.$t("common-manage")))
              ]),
              _vm._v(" "),
              _c("template", { slot: "dropdownButtons" }, [
                _vm.isEditable
                  ? _c(
                      "button",
                      {
                        directives: [
                          { name: "close-popover", rawName: "v-close-popover" }
                        ],
                        staticClass: "btn btn-success popover-menu-item",
                        on: {
                          click: function($event) {
                            return _vm.showAutogenerateVisitsForm()
                          }
                        }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _vm._v(_vm._s(_vm.$t("visitSummaryTable-autogenerate")))
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success popover-menu-item",
                    class: { downloading: _vm.downloading },
                    attrs: { download: "" },
                    on: {
                      click: function($event) {
                        return _vm.downloadVisits()
                      }
                    }
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "20px" } }),
                    _c("span", { staticClass: "spin-icon" }),
                    _vm._v(
                      " " + _vm._s(_vm.$t("visitSummaryTable-downloadVisits"))
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-success popover-menu-item",
                    class: { downloading: _vm.downloadingCodes },
                    attrs: { download: "" },
                    on: {
                      click: function($event) {
                        return _vm.downloadCodes()
                      }
                    }
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "20px" } }),
                    _c("span", { staticClass: "spin-icon" }),
                    _vm._v(" " + _vm._s(_vm.$t("common-downloadCodes")))
                  ]
                ),
                _vm._v(" "),
                !_vm.isEditable
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: _vm.tooltips.disabledUpload,
                              show: _vm.isEditable,
                              classes: "front"
                            },
                            expression:
                              "{content: tooltips.disabledUpload, show: isEditable, classes: 'front'}"
                          }
                        ]
                      },
                      [
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "close-popover",
                                rawName: "v-close-popover"
                              }
                            ],
                            staticClass: "btn btn-success popover-menu-item",
                            attrs: { disabled: !_vm.isEditable },
                            on: { click: _vm.uploadVisitCSV }
                          },
                          [
                            _c("span", {
                              staticStyle: { "font-size": "20px" }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("common-uploadItem", {
                                    item: _vm.$tc("common-visit", 2)
                                  })
                                )
                            )
                          ]
                        )
                      ]
                    )
                  : _c(
                      "button",
                      {
                        directives: [
                          { name: "close-popover", rawName: "v-close-popover" }
                        ],
                        staticClass: "btn btn-success popover-menu-item",
                        on: {
                          click: function($event) {
                            return _vm.uploadVisitCSV()
                          }
                        }
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "20px" } }),
                        _vm._v(
                          _vm._s(
                            _vm.$t("common-uploadItem", {
                              item: _vm.$tc("common-visit", 2)
                            })
                          )
                        )
                      ]
                    )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("v-server-table", {
            ref: "table",
            staticClass: "table vue-bordered org-table w-full",
            attrs: {
              url: _vm.url,
              columns: _vm.columns,
              options: _vm.tableOptions
            },
            on: { "row-click": _vm.onRowClick },
            scopedSlots: _vm._u([
              {
                key: "hasImage",
                fn: function(props) {
                  return [
                    props.row.hasImage
                      ? [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("common-yes")) +
                              "\n        "
                          )
                        ]
                      : [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("common-no")) +
                              "\n        "
                          )
                        ]
                  ]
                }
              },
              {
                key: "id",
                fn: function(props) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "editProject modal-trigger projectAdd",
                        on: {
                          click: function($event) {
                            return _vm.updateLocationVisit(props.row)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "link",
                          class: {
                            "ion-edit": _vm.isEditable,
                            "ion-eye": !_vm.isEditable
                          }
                        })
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "location-visit",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "80%",
            height: "75%",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("visit-form", {
            ref: "visitForm",
            attrs: {
              "location-id": _vm.locationId,
              "is-editable": _vm.isEditable,
              options: _vm.options,
              "organization-id": _vm.organizationId,
              "location-label": _vm.locationLabel
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "autogenerate-visits-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: "85%",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("autogenerate-visits-form", {
            attrs: { "organization-id": _vm.organizationId }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "create-new-visit-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "85%",
            height: "75%",
            minWidth: 200,
            minHeight: 200,
            clickToClose: false
          }
        },
        [
          _c("create-new-visit-form", {
            attrs: { "organization-id": _vm.organizationId }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "visit-upload-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("upload-visits-csv", {
            attrs: {
              "organization-id": _vm.organizationId,
              "organization-name": _vm.organizationName,
              "visibility-options": _vm.options.visibility,
              type: "visits"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }