var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trackingTable" },
    [
      _vm.uuid
        ? _c("report-aru-player", {
            attrs: { taskId: _vm.taskId, uuid: _vm.uuid }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.uuid
          ? _c("h4", [_vm._v(_vm._s(_vm.$t("recordingLog-loggedSounds")))])
          : _vm._e(),
        _vm._v(" "),
        !_vm.uuid
          ? _c("div", { staticClass: "col s6 m6 l5 xl4" }, [
              _vm.taggers != null && _vm.taggers.length > 1 && _vm.isAdminView
                ? _c("div", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedUserTaskId,
                            expression: "selectedUserTaskId"
                          }
                        ],
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedUserTaskId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.taggers, function(user, index) {
                        return _c(
                          "option",
                          {
                            key: "tag" + index,
                            domProps: { value: user.taskId }
                          },
                          [_vm._v(" " + _vm._s(user.userName))]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.uuid ? _c("div", { staticClass: "col s4 l2 xl4" }) : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col s6 m6 l5 xl4" }, [
          _c(
            "table",
            { staticClass: "legendTable", class: { right: !_vm.uuid } },
            [
              _c(
                "tr",
                _vm._l(_vm.vocalizationOpt, function(row, index) {
                  return _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.$t(
                            `vocalization-legend-tooltips.${row.id}`
                          ),
                          expression:
                            "$t(`vocalization-legend-tooltips.${row.id}`)"
                        }
                      ],
                      key: "vocalization" + index,
                      class: "min vt-" + row.id
                    },
                    [
                      _c("span", { staticClass: "legend" }, [
                        _vm._v(" " + _vm._s(row.type))
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      (_vm.taskId != null &&
        _vm.taggers &&
        _vm.selectedUserTaskId != null &&
        _vm.bTableConfigLoaded) ||
      (_vm.taskId != null && _vm.uuid && _vm.bTableConfigLoaded)
        ? _c("v-server-table", {
            ref: "table",
            staticClass: "table vue-bordered",
            class: "table-min" + _vm.nMinute,
            attrs: {
              url: _vm.tableUrl,
              columns: _vm.columns,
              options: _vm.options
            },
            on: {
              loaded: function($event) {
                return _vm.setSpeciesInfo()
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "min0",
                  fn: function(props) {
                    return props.row.min0 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min0.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min0)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min0.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min1",
                  fn: function(props) {
                    return props.row.min1 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min1.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min1)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min1.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min2",
                  fn: function(props) {
                    return props.row.min2 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min2.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min2)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min2.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min3",
                  fn: function(props) {
                    return props.row.min3 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min3.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min3)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min3.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min4",
                  fn: function(props) {
                    return props.row.min4 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min4.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min4)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min4.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min5",
                  fn: function(props) {
                    return props.row.min5 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min5.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min5)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min5.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min6",
                  fn: function(props) {
                    return props.row.min6 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min6.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min6)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min6.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min7",
                  fn: function(props) {
                    return props.row.min7 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min7.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min7)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min7.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min8",
                  fn: function(props) {
                    return props.row.min8 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min8.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min8)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min8.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "min9",
                  fn: function(props) {
                    return props.row.min9 != null
                      ? [
                          _c(
                            "div",
                            {
                              class: "min vt-" + props.row.min9.vocalizationId,
                              on: {
                                click: function($event) {
                                  return _vm.editTag(props.row.min9)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(props.row.min9.seconds))
                              ])
                            ]
                          )
                        ]
                      : undefined
                  }
                },
                {
                  key: "child_row",
                  fn: function(props) {
                    return _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col s12 m12 l6 offset-l2 offset-m1" },
                        [
                          _c("img", {
                            staticClass: "round-img",
                            attrs: {
                              src: _vm.speciesMap.get(props.row.speciesCode)
                                .icon
                            },
                            on: {
                              error: function($event) {
                                _vm.imgUrlAlt(
                                  $event,
                                  _vm.speciesMap.get(props.row.speciesCode).icon
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-wrapper" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.capitalize(_vm.$tc("common-code", 1))
                                ) +
                                  ": " +
                                  _vm._s(props.row.speciesCode)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "english-name" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.speciesMap.get(props.row.speciesCode)
                                    .commonName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "italic" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.speciesMap
                                    .get(props.row.speciesCode)
                                    .scientificName.charAt(0)
                                    .toUpperCase() +
                                    _vm.speciesMap
                                      .get(props.row.speciesCode)
                                      .scientificName.slice(1)
                                )
                              )
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s12 m6 l3 stats" }, [
                        _c("p", [_vm._v(_vm._s(props.row.comments))])
                      ])
                    ])
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }