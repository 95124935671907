import { eventBus } from '@/lib/eventbus';

// shared table options
export default {
  created() {
    eventBus.$on('reload-task-table', () => {
      this.tableOptions.activeRowId = [];
    });
  },
  data() {
    const pageSize = 100;
    return {
      tableOptions: { // parent definition will be merged with child one, so use same field name.
        statusRow: 'status', // default status row for coloring the row.
        showOwnOnly: true,
        activeRowId: [],
        highlightMatches: true,
        childRowTogglerFirst: false,
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: { nav: 'fixed', edge: true }, /* nav: scroll, fixed has bug on navigation */
        texts: this.$t('common-tableTexts'),
        rowClassCallback: function (row) {
          return (row[this.statusRow] ? row[this.statusRow] : '') + (this.activeRowId.find(v=>v.id===row.id) ? ' activeTableRow' : '');
        },
        requestAdapter(data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({ column: key, value: query[key] });
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter,
            showOwnOnly: this.showOwnOnly
          }
        },
        responseAdapter(data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
