var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vdr",
      class: {
        draggable: _vm.draggable,
        resizable: _vm.resizable,
        active: _vm.enabled,
        dragging: _vm.dragging,
        resizing: _vm.resizing
      },
      style: _vm.style,
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          return _vm.elmDown.apply(null, arguments)
        },
        touchstart: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.elmDown.apply(null, arguments)
        },
        dblclick: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.dblclickBox.apply(null, arguments)
        }
      }
    },
    [
      _vm.resizable
        ? _vm._l(_vm.handles, function(handle) {
            return _c("div", {
              key: handle,
              staticClass: "handle",
              class: "handle-" + handle,
              style: { display: _vm.enabled ? "block" : "none" },
              on: {
                mousedown: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleDown(handle, $event)
                },
                touchstart: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.handleDown(handle, $event)
                }
              }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }