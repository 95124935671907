var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "autogenBox" } }, [
    _c("div", { staticClass: "tabbed-autogen" }, [
      _c(
        "ul",
        { staticClass: "tabs" },
        [
          _vm._l(_vm.tabs, function(tab, index) {
            return [
              _c(
                "li",
                {
                  key: "tab" + index,
                  staticClass: "tab",
                  class: { hide: !_vm.completedCalls && tab.id === 1 },
                  on: {
                    click: function($event) {
                      return _vm.switchTab(tab)
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "hyperlink",
                      class: { active: _vm.selectedTab == tab.id }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(tab && tab.label) +
                          "\n              "
                      )
                    ]
                  )
                ]
              )
            ]
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _vm.autogenOptions != null
      ? _c(
          "div",
          {
            staticClass: "orange-border padded40",
            class: { hide: _vm.selectedTab != 0 }
          },
          [
            _c(
              "v-server-table",
              {
                ref: "table",
                staticClass: "generate-table table vue-bordered org-table",
                attrs: {
                  url: _vm.url,
                  columns: _vm.columns,
                  options: _vm.tableOptions
                },
                on: {
                  "row-click": _vm.onRowClick,
                  loading: _vm.onLoading,
                  loaded: _vm.onLoaded
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selected",
                      fn: function(props) {
                        return [
                          _c("label", { attrs: { for: props.row.id } }, [
                            _c("input", {
                              attrs: {
                                type: "checkbox",
                                id: props.row.uniqueId
                              },
                              domProps: {
                                value: props.row.uniqueId,
                                checked: _vm.selected.get(props.row.uniqueId)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateCheckbox(props.row)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span")
                          ])
                        ]
                      }
                    },
                    {
                      key: "action",
                      fn: function(props) {
                        return [
                          _vm._v(
                            "\n      " +
                              _vm._s(_vm.autogenOptions.get(props.row.action)) +
                              "\n      "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3987846363
                )
              },
              [
                _vm.isLoading
                  ? _c(
                      "template",
                      { slot: "afterBody" },
                      [
                        _c("div", { staticClass: "loader" }),
                        _vm._v(" "),
                        _c("pulse-loader", {
                          staticClass: "pulse",
                          attrs: {
                            loading: true,
                            color: "#C94412",
                            size: "20px",
                            width: 400,
                            height: 400
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._v(" "),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: { slot: "filter__selected" },
                    slot: "filter__selected"
                  },
                  [
                    _c("label", { attrs: { for: "toggleAll" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedAll,
                            expression: "selectedAll"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "checkbox", id: "toggleAll" },
                        domProps: {
                          checked: Array.isArray(_vm.selectedAll)
                            ? _vm._i(_vm.selectedAll, null) > -1
                            : _vm.selectedAll
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.selectedAll,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedAll = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedAll = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedAll = $$c
                              }
                            },
                            function($event) {
                              return _vm.toggleAll()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("span")
                    ])
                  ]
                )
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-border btn-success modal-action modal-close",
                  attrs: { disabled: _vm.rowsSelected === 0 },
                  on: {
                    click: function($event) {
                      return _vm.createMissingValues()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("autogenVisitsTable-create")))]
              ),
              _vm._v(" "),
              _vm.tableReady
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-border btn-success modal-action modal-close",
                      attrs: { disabled: _vm.$refs.table.count === 0 },
                      on: {
                        click: function($event) {
                          return _vm.createAll()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("autogenVisitsTable-createAll")) +
                          " (" +
                          _vm._s(_vm.$refs.table.count) +
                          ")"
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.rowsSelected) +
                    " " +
                    _vm._s(
                      _vm.$tc(
                        "autogenVisitsTable-rowSelected",
                        _vm.rowsSelected
                      )
                    )
                )
              ]),
              _vm._v(" "),
              _vm.errorUploading
                ? _c("span", [_vm._v(_vm._s(_vm.errorUploading))])
                : _vm._e()
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { class: { hide: _vm.selectedTab != 1 } },
      [
        _c("generate-and-select-results", {
          attrs: {
            completedCalls: _vm.completedCalls,
            callsBegan: _vm.callsBegan,
            numSuccess: _vm.numSuccess,
            errorResultsTableData: _vm.errorResultsTableData,
            progressNum: _vm.progressNum,
            progressTotal: _vm.progressTotal
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }