<template>
  <div class="container overflow tabbed">
    <div v-if="userData && initialValues" class="boxed padded40">
        <h2>{{initialValues.name}}</h2>
        <div>
          {{$t('userPage-userSettings')}} <span class="error" v-if="changesDetected">(* {{$t('common-unsaved')}})</span>
          </div>
          <div class="row">
            <div class="col s12 m4 l4">
              <label for="user_name">{{$t('userPage-name')}}</label>
              <input id="user_name" v-model="userData.name" type="text" @input="checkForChanges(initialValues.name, $event)" :maxlength="maxNameLength" required>
          </div>
            <div class="col s12 m4 l4">
              <label for="user_initials">{{$t('userPage-initials')}}</label>
              <input id="user_initials" v-model="userData.initials" type="text" @input="checkForChanges(initialValues.initials, $event)" :maxLength="maxInitialsLength">
          </div>
          <div v-if="auth0ProfileOriginal" class="col s12 m5 l5">
              <label for="user_email">{{$t('userPage-email')}}</label>
              <input id="user_email" v-model="auth0ProfileOriginal.email" type="text" :maxLength="maxEmailLength" required :disabled="true">
          </div>
          <!--
            TODO: update user email
          <div v-if="showPWPrompt" class="col s12 m7 l7">
              <label for="user_auth">{{$t('userPage-reAuth')}}</label>
              <input id="user_auth" type="password" v-model="pw">
          </div> -->
          <div class="col s12 m8 l8">
            <label>{{$t('userPage-mailList')}} <span class="info-icon ion-information-circled" v-tooltip="$t('userPage-tooltips.mailList')" /></label>
                <ToggleButton class="mail-toggle" :value="userData.inMailList" :sync="true" color="#C94412" @change="mailToggled"></ToggleButton>
          </div>
          <div v-if="languages" class="col s12 m8 l8">
            <label>{{$t('userPage-language')}}</label>
                <div v-for="(lang, index) in languages" :key="'language' + index">
                  <label class="form-option" :key="'lang-' + index">
                  <input type=radio id="language" :value="lang.id" v-model="userData.languageCode" @change="checkForChanges(parseInt(initialValues.languageCode), $event)">
                  <span>{{$t(`common-languages.${lang.id}`)}}</span>
                </label>
                </div>
          </div>
            <div class="col s12 m6 l6">
              <label for="user_affiliation">{{$t('userPage-affiliation')}}</label>
              <input id="user_affiliation" v-model="userData.affiliation" type="text" @input="checkForChanges(initialValues.affiliation, $event)">
            </div>


            <div class="col s12">
              <label for="access_token">{{$t('JWT Access Token')}}</label>
              <div class="flex">
                <input ref="access_token" id="access_token" type="text" :value="accessToken" />
                <button class="btn" @click="copyToken" v-tooltip="{content: 'Copied!', show: showClipboardTooltip, trigger: 'manual'}"><i class="fa-solid fa-clipboard"></i></button>
              </div>
              <span class="block text-sm">{{$t('This is your personal access token to access Wildtrax. Use it to authorize in other applications, like within the wildRtrax package. Do not share this token with anyone. ')}} <a target="_blank" href="https://abbiodiversity.github.io/wildRtrax/index.html">{{$t('wildRtrax can be found here.')}}</a></span>

            </div>
          
          </div>
          <div class="msg-wrapper">
            <div class="error" v-for="(error, index) in errors" :key="index+'error'">
              {{error}}
              </div>
            <div>
              {{finishMsg}}
            </div>
          </div>
        <div>
            <button class="btn btn-success" :disabled="!changesDetected" @click="updateUser()"> <span style="font-size: 20px;"></span>{{$t('common-update')}}</button>
        </div>
    </div>
  </div>
</template>

<script>

/*
Page where user can change various settings including newsletter, language, initials, etc.
*/

import {API_URL, attachAuthorizaitonHeader, copyObject} from '@/lib/common';
import { ToggleButton } from 'vue-js-toggle-button';
import {EventBus, getProfile, handleChangeLanguage} from '@/lib/user-auth0';

export default {
  name: 'user-details',
  components: {'ToggleButton': ToggleButton},
  created () {
    attachAuthorizaitonHeader();
    this.getUserDetails();
    this.getLanguageOptions();
    getProfile(true); // ask for user profile
    EventBus.$on('profile_updated', (profile) => {
      // user profile returned
      this.auth0ProfileOriginal = profile;
      // this.newEmail = this.auth0ProfileOriginal.email;
    });
  },
  methods: {
    copyToken() {
     
      navigator.clipboard.writeText(this.accessToken);
      this.showClipboardTooltip = true;

      setTimeout(()=> {
        this.showClipboardTooltip = false;
      }, 1000);
    },
    getUserDetails () {
      this.$http.get(this.userDetailsURL).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.errors.push(response.data.error);
          } else {
            this.userData = response.data;
            this.initialValues = copyObject(this.userData);
          }
        }, response => {
        // error callback
          this.errors.push(this.$tc('common-error', 1) + ': ' + (response.data ? response.data.error : '') + ' ' + response.status);
        }
      );
    },
    getLanguageOptions () {
      this.$http.get(this.languageOptionsURL).then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.errors.push(response.data.error);
          } else {
            this.languages = response.data.language;
          }
        }, response => {
        // error callback
          this.errors.push(this.$tc('common-error', 1) + ': ' + (response.data ? response.data.error : '') + ' ' + response.status);
        }
      );
    },
    updateUser () {
      this.errors = [];
      this.finishMsg = null;
      if (this.validateInputs()) {
        // update email if applicable
        /* if (this.newEmail !== this.auth0ProfileOriginal.email) {
          this.updateEmail();
        } */

        this.$http.post(this.updateUserURL, this.userData).then(function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.errors.push(this.$t('userPage-updateFailed') + ': ' + response.data.error);
          } else {
            if (this.userData.languageCode !== this.initialValues.languageCode) {
              // update language
              handleChangeLanguage(this.userData.languageCode);
            }
            this.finishMsg = this.$t('userPage-updated');
            this.initialValues = copyObject(this.userData);
            this.changesDetected = false;
          }
        }, (err) => {
          // error callback
          this.errors.push(this.$t('userPage-updateFailed') + ': ' + err);
        });
      }
    },
    validateInputs () {
      // validate
      if (this.userData.name.trim() === '') {
        this.errors.push(this.$t('userPage-nameRequired'));
      }
      /* if (this.newEmail.trim() === '') {
        this.errors.push('Email is required');
      }
      if ((this.newEmail !== this.auth0ProfileOriginal.email) && (this.pw == null || this.pw === '')) {
        this.errors.push('You must enter your password to update your email address.')
      } */
      if (this.errors.length > 0) {
        return false;
      } else return true;
    },
    mailToggled (e) {
      // toggle is a special case
      this.userData.inMailList = !this.userData.inMailList;
      if (this.initialValues.inMailList === this.userData.inMailList) {
        this.changesDetected = false;
      } else {
        this.changesDetected = true;
      }
    },
    checkForChanges (initialValue, event) {
      if (event.target.value === String(initialValue)) {
        this.changesDetected = false;
      } else {
        this.changesDetected = true;
      }
    }
    /*
    TODO: complete at a later date
    updateEmail () {
      if (!isLoggedIn() || isTokenExpired()) {
        // TODO: msg user
        return;
      }
      authForChangeEmail(this.auth0ProfileOriginal.email, this.pw);
    } */
  },
  data () {
    return {
      userDetailsURL: API_URL + 'get-user-details',
      languageOptionsURL: API_URL + 'get-language-options',
      updateUserURL: API_URL + 'update-user',
      errors: [],
      initialValues: null,
      userData: null,
      languages: null,
      maxNameLength: 100,
      maxInitialsLength: 10,
      maxEmailLength: 321,
      finishMsg: null,
      auth0ProfileOriginal: null,
      changesDetected: false,
      accessToken: localStorage.getItem('access_token'),
      showClipboardTooltip: false
      // newEmail: null,
      // showPWPrompt: false,
      // pw: null
    }
  }

}
</script>
<style scoped>
.mail-toggle{
  margin-left: 5px;
}

.msg-wrapper{
  height: 1.8rem;
}

.user-profile{
  margin-top: 25px;
  margin-bottom: 30px;
}
</style>
