var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "overflow-scroll",
      attrs: { name: _vm.name, adaptive: true, width: "95%", height: "auto" },
      on: {
        opened: _vm.opened,
        "before-open": _vm.beforeOpen,
        "before-close": _vm.beforeClose
      }
    },
    [
      _c("div", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "h-96 items-center flex"
          },
          [
            _c(
              "div",
              { staticClass: "text-center flex-1 grow" },
              [
                _c("pulse-loader", {
                  staticClass: "text-center",
                  attrs: {
                    loading: true,
                    color: "#C94412",
                    size: "20px",
                    width: 400,
                    height: 400
                  }
                }),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("aruVerificationModal-loadingAudio")))
                ])
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        !_vm.loading
          ? _c(
              "div",
              { staticClass: "overflow-scroll p-3", attrs: { id: _vm.name } },
              [
                _c("header", { staticClass: "mb-0 flex" }, [
                  _c("span", { staticClass: "flex-1 grow" }, [
                    _c("div", { staticClass: "text-4xl block w-full" }, [
                      _vm._v(_vm._s(_vm.$t("aruVerificationModal-tagDetails")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "!cursor-pointer text-2xl py-1",
                        on: {
                          click: () => {
                            _vm.window.open(_vm.taskUrl(_vm.tag), "_blank")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$tc("common-task", 1)) +
                            ": " +
                            _vm._s(
                              _vm.details.recordingName.split("@")[0] +
                                " @ " +
                                _vm.details.recordingName.split("@")[1]
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "btn !bg-white !border-white !text-gray-300 !px-2 !py-1 flex-initial",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-times" })]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "toolbar flex w-full mb-5" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn !px-3 !py-1 flex-initial mr-1",
                      attrs: { disabled: !_vm.userCanModify },
                      on: {
                        click: function($event) {
                          return _vm.deleteModal(_vm.tag)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-trash !text-sm" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "btn !px-2 !py-1 flex-initial mr-1",
                      class: {
                        "!bg-green-500 !border-green-600":
                          _vm.tag.isVerified && _vm.userCanModify
                      },
                      attrs: { disabled: !_vm.userCanModify },
                      on: {
                        click: function($event) {
                          return _vm.markVerified(_vm.tag)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-check !text-sm" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "btn !px-2 !py-1 flex-initial",
                      class: {
                        "!bg-yellow-400 !bg-yellow-500": _vm.tag.rating
                      },
                      attrs: { disabled: !_vm.userCanModify },
                      on: {
                        click: function($event) {
                          return _vm.ratingModal()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.tag.rating) +
                          "\n            "
                      ),
                      _c("i", { staticClass: "fas fa-star !text-sm" })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "w-1/4 text-base flex-initial" }, [
                    _c("img", {
                      staticClass: "rounded-full",
                      attrs: {
                        src: `https://photo-api.abmi.ca/photo-api/get-profile-image?sname=${_vm.details.species.scientificName}`
                      }
                    }),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("recordingForms-abundance")) +
                        ": " +
                        _vm._s(
                          _vm.details.summary.abundanceType != "TMTT"
                            ? _vm.details.summary.abundanceType
                            : _vm.$t("aruVerificationModal-TMTT")
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("aruVerificationModal-minFreq")) +
                        ": " +
                        _vm._s(_vm.details.summary.minFreq)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("aruVerificationModal-maxFreq")) +
                        ": " +
                        _vm._s(_vm.details.summary.maxFreq)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("aruVerificationModal-length")) +
                        ": " +
                        _vm._s(_vm.details.recording.audioLength) +
                        "s"
                    ),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("aruVerificationModal-channel")) +
                        ": " +
                        _vm._s(
                          _vm.findChannel(_vm.details.summary.channel)
                            .description
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$t("aruSpeciesVerification-tooltips.birdnet")
                        ) +
                        ": " +
                        _vm._s(_vm.details.summary.birdNetConfidence)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$t("aruSpeciesVerification-tooltips.peakDBFS")
                        ) +
                        ": " +
                        _vm._s(_vm.details.summary.peakDBFS) +
                        "\n              "
                    ),
                    _c("div", { staticClass: "mt-5" }, [
                      _c("b", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("common-equipment")))
                      ]),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common-make")) +
                          ": " +
                          _vm._s(
                            _vm.details.locationEquipment.equipmentMake ||
                              _vm.$t("recordingPage-unknown")
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common-model")) +
                          ": " +
                          _vm._s(
                            _vm.details.locationEquipment.equipmentModel ||
                              _vm.$t("recordingPage-unknown")
                          ) +
                          "\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "items-center w-3/4 mt-3 flex-1 grow" },
                    [
                      _c("div", { staticClass: "flex overflow-x-auto" }, [
                        _c("div", { staticClass: "flex-shrink w-0 relative" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-initial flex flex-col" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex-initial grow flex flex-col"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-initial text-xs" },
                                  [_vm._v(_vm._s(_vm.details.summary.maxFreq))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "grow flex-1 relative" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "transform absolute -left-10 top-1/2 -translate-y-1/2 -rotate-90 block text-xs"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "aruVerificationModal-frequency"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-initial text-xs invisible"
                                  },
                                  [_vm._v(_vm._s(_vm.tag.minFrequency))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex-initial" }, [
                              _c(
                                "span",
                                { staticClass: "text-xs block h-10 -mt-2" },
                                [_vm._v(_vm._s(_vm.details.summary.minFreq))]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-initial flex flex-col" },
                          [
                            _c(
                              "div",
                              {
                                ref: "spectrogram",
                                staticClass:
                                  "ml-1 border-gray-500 border-solid border-4 relative float-left spectrogram overflow-hidden"
                              },
                              [
                                _c("div", {
                                  ref: "line",
                                  staticClass:
                                    "absolute spectro-line bg-red-500",
                                  staticStyle: { left: "0px" }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "absolute w-px h-full bg-gray-600 top-0",
                                  style: {
                                    left:
                                      (_vm.details.summary.clipStartTime /
                                        _vm.details.summary.clipLength) *
                                        _vm.details.summary
                                          .clipSpectrogramWidth +
                                      "px"
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "absolute w-px h-full bg-gray-600 top-0",
                                  style: {
                                    left:
                                      (_vm.details.summary.clipEndTime /
                                        _vm.details.summary.clipLength) *
                                        _vm.details.summary
                                          .clipSpectrogramWidth +
                                      "px"
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  ref: "spectrogram-image",
                                  staticClass: "spectro-image w-full",
                                  style: {
                                    "min-width":
                                      _vm.details.summary.clipSpectrogramWidth +
                                      "px"
                                  },
                                  attrs: { src: _vm.image.src },
                                  on: {
                                    load: () => {
                                      _vm.imageLoaded = true
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-initial bg-gray-500 cursor-pointer text-white ml-1 text-center py-1 px-2 clear-left float-left",
                                  attrs: { disabled: true },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleButton(_vm.tag.tagId)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    class: {
                                      "fas fa-play": !_vm.playing,
                                      "fas fa-pause": _vm.playing
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-initial bg-gray-400 cursor-pointer text-white text-center py-1 px-2 clear-left float-left",
                                  on: {
                                    click: function($event) {
                                      return _vm.resetbutton(_vm.tag)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-redo" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex-1 grow text-center flex" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-xs flex-1 grow" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAudioRef(_vm.tag.tagId)
                                            .currentTime
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-xs -mr-2 flex-initial"
                                    },
                                    [_vm._v(_vm._s(_vm.tag.audioLength))]
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-1 grow" })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-10 rounded border p-5" }, [
                        _c("div", { staticClass: "grid grid-cols-4 gap-3" }, [
                          _c("div", { staticClass: "input" }, [
                            _c(
                              "label",
                              {
                                attrs: {
                                  for: `${_vm.details.summary.tagId}-filter`
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "aruVerificationModal-filter.freqFilter"
                                    )
                                  ) + "  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.$t(
                                        "aruVerificationModal-tooltips.frequencyfilter"
                                      ),
                                      expression:
                                        "$t('aruVerificationModal-tooltips.frequencyfilter')"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.audioControls.freqRangeFilter,
                                      expression:
                                        "audioControls.freqRangeFilter"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: `${_vm.details.summary.tagId}-filter`
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.audioControls.freqRangeFilter
                                    )
                                      ? _vm._i(
                                          _vm.audioControls.freqRangeFilter,
                                          null
                                        ) > -1
                                      : _vm.audioControls.freqRangeFilter
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a =
                                            _vm.audioControls.freqRangeFilter,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.audioControls,
                                                "freqRangeFilter",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.audioControls,
                                                "freqRangeFilter",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.audioControls,
                                            "freqRangeFilter",
                                            $$c
                                          )
                                        }
                                      },
                                      function($event) {
                                        _vm.audioControlsChanged = true
                                      }
                                    ]
                                  }
                                }),
                                _vm._v(" "),
                                _vm.audioControls.freqRangeFilter
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-on !block text-4xl text-green-400"
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.audioControls.freqRangeFilter
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-off !block text-4xl text-red-400"
                                    })
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: `${_vm.details.summary.tagId}-amplify`
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("aruVerificationModal-filter.amplify")
                                ) + "  "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.amplify"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.amplify')"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.audioControls.amplify,
                                      expression: "audioControls.amplify"
                                    }
                                  ],
                                  attrs: {
                                    id: `${_vm.details.summary.tagId}-amplify`
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.audioControls,
                                          "amplify",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        _vm.audioControlsChanged = true
                                      }
                                    ]
                                  }
                                },
                                _vm._l(_vm.details.options.amplify, function(
                                  option,
                                  key
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: key,
                                      domProps: {
                                        value: option.value,
                                        selected: option.isDefault
                                      }
                                    },
                                    [_vm._v(_vm._s(option.description))]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: `${_vm.details.summary.tagId}-noise`
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "aruVerificationModal-filter.noiseFilter"
                                  )
                                ) + "  "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.noisefilter"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.noisefilter')"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.audioControls.noiseFilter,
                                      expression: "audioControls.noiseFilter"
                                    }
                                  ],
                                  attrs: {
                                    id: `${_vm.details.summary.tagId}-noise`
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.audioControls,
                                          "noiseFilter",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        _vm.audioControlsChanged = true
                                      }
                                    ]
                                  }
                                },
                                _vm._l(
                                  _vm.details.options.noiseFilter,
                                  function(option, key) {
                                    return _c(
                                      "option",
                                      {
                                        key: key,
                                        domProps: {
                                          value: option.value,
                                          selected: option.isDefault
                                        }
                                      },
                                      [_vm._v(_vm._s(option.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: `${_vm.details.summary.tagId}-channel`
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("aruVerificationModal-filter.channel")
                                ) + "  "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.channelfilter"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.channelfilter')"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.audioControls.channel,
                                      expression: "audioControls.channel"
                                    }
                                  ],
                                  attrs: {
                                    id: `${_vm.details.summary.tagId}-channel`
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.audioControls,
                                          "channel",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        _vm.audioControlsChanged = true
                                      }
                                    ]
                                  }
                                },
                                _vm._l(_vm.details.options.channel, function(
                                  option,
                                  key
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: key,
                                      domProps: {
                                        value: option.value,
                                        selected: option.isDefault
                                      }
                                    },
                                    [_vm._v(_vm._s(option.description))]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: `${_vm.details.summary.tagId}-channel`
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "aruVerificationModal-filter.zContrast"
                                  )
                                ) + "  "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.Z-contrast"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.Z-contrast')"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.audioControls.spectrogramZContrast,
                                      expression:
                                        "audioControls.spectrogramZContrast"
                                    }
                                  ],
                                  attrs: {
                                    id: `${_vm.details.summary.tagId}-channel`
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.audioControls,
                                          "spectrogramZContrast",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        _vm.audioControlsChanged = true
                                      }
                                    ]
                                  }
                                },
                                _vm._l(
                                  _vm.details.options.spectrogramZContrast,
                                  function(option, key) {
                                    return _c(
                                      "option",
                                      {
                                        key: key,
                                        domProps: {
                                          value: option.value,
                                          selected: option.isDefault
                                        }
                                      },
                                      [_vm._v(_vm._s(option.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: `${_vm.details.summary.tagId}-channel`
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "aruVerificationModal-filter.zBrightness"
                                  )
                                ) + " "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t(
                                      "aruVerificationModal-tooltips.Z-brightness"
                                    ),
                                    expression:
                                      "$t('aruVerificationModal-tooltips.Z-brightness')"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              }),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.audioControls
                                          .spectrogramZBrightness,
                                      expression:
                                        "audioControls.spectrogramZBrightness"
                                    }
                                  ],
                                  attrs: {
                                    id: `${_vm.details.summary.tagId}-channel`
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.audioControls,
                                          "spectrogramZBrightness",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        _vm.audioControlsChanged = true
                                      }
                                    ]
                                  }
                                },
                                _vm._l(
                                  _vm.details.options.spectrogramZBrightness,
                                  function(option, key) {
                                    return _c(
                                      "option",
                                      {
                                        key: key,
                                        domProps: {
                                          value: option.value,
                                          selected: option.isDefault
                                        }
                                      },
                                      [_vm._v(_vm._s(option.description))]
                                    )
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              attrs: { disabled: !_vm.audioControlsChanged },
                              on: { click: _vm.getAudioFile }
                            },
                            [_vm._v(_vm._s(_vm.$t("common-applyFilters")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              on: { click: _vm.resetFilters }
                            },
                            [_vm._v(_vm._s(_vm.$t("common-resetFilters")))]
                          )
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.details.tagClip.clipURL
                  ? _c(
                      "audio",
                      {
                        ref: _vm.details.summary.tagId,
                        attrs: { id: _vm.details.summary.tagId }
                      },
                      [
                        !_vm.audioFile
                          ? _c("source", {
                              attrs: {
                                src: _vm.details.tagClip.clipURL,
                                type: "audio/mpeg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.audioFile
                          ? _c("source", {
                              attrs: {
                                src: `data:audio/mpeg;base64,${_vm.audioFile}`,
                                type: "audio/mpeg"
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }