<template>
    <div class="vue container overflow">
      <div class="container error padded60" v-if="error != null">
        <span>
          {{error}}
        </span>
      </div>
    <div v-if="error==null">
      <div class="large boxed padded20">
        <div class="row">
          <div v-if="reportLocation" class="col s12 m12 l8">
            <h1 style="display:inline;">{{$t('landOwnerHome-title', {name: (reportLocation && reportLocation.name)})}}</h1>
            <p>
              {{$t('common-latitude')}}: {{reportLocation.latitude}} {{$t('common-longitude')}}: {{reportLocation.longitude}}<br/>
              <template v-if="!reportLocation.isTrueCoordinates">{{$t('landOwnerHome-hiddenLocation', {num: reportLocation.bufferRadius})}}<br/></template>
            </p>
            <single-visit-table v-if="reportLocation" v-bind:is-editable="false" v-bind:organization-id="null" v-bind:uuid="userUUID"
            v-bind:location-label="null" v-bind:creating-visit="false" v-bind:location-id="reportLocation.id"></single-visit-table>
          </div>
        </div>
      </div>
      <section>
      <div class="tabbed">
        <ul class="tabs">
          <li v-if="reportPuds && reportPuds.length > 0" class="tab">
            <a class="hyperlink CAM" :class="{ active: activeTab === 'CAM'}" @click="clickTab('CAM')">
              <span class="ion-ios-camera"></span>
              {{$t('common-cam')}}
            </a>
          </li>
          <li v-if="reportAruTasks && reportAruTasks.length > 0" class="tab" >
            <a class="hyperlink ARU" :class="{ active: activeTab === 'ARU'}" @click="clickTab('ARU')">
              <span class="ion-ios-mic"></span>
              {{$t('common-aru')}}
            </a>
          </li>
          <li v-if="reportLocation && reportLocation.latitude && reportLocation.longitude" class="tab" @click="clickTab('map')"><a v-bind:class="{ active: activeTab == 'map'}"><span class="ion-map"></span> {{$t('common-map')}}</a></li>
          <li v-if="hasVisitPhotos" class="tab" @click="clickTab('location-photos')"><a v-bind:class="{ active: activeTab == 'location-photos'}"><span class="ion-ios-camera"></span> {{$t('common-locationPhotos')}}</a></li>
        </ul>
        <sensor-table ref="project" v-if="activeTab != null && activeTab != 'map' && activeTab != 'location-photos' && reportLocation != null" v-bind:uuid="userUUID" v-bind:sensor="activeTab" v-bind:puds="reportPuds" v-bind:aruTasks="reportAruTasks" v-bind:location="reportLocation"/>
        <div v-if="activeTab === 'map'" class="col s12 m12 l8">
          <point-map v-if="reportLocation && reportLocation.latitude && reportLocation.longitude" v-bind:latitude="parseFloat(reportLocation.latitude)" v-bind:longitude="parseFloat(reportLocation.longitude)" />
        </div>
        <div v-if="hasVisitPhotos && allVisitsInfo && staticVisitOptions && activeTab === 'location-photos'" class="col s12 panel padded60 boxed active">
          <location-photos v-bind:location-id="reportLocation.id" v-bind:uuid="userUUID" v-bind:all-visits-info="allVisitsInfo" v-bind:options="staticVisitOptions"></location-photos>
        </div>
      </div>
      </section>
    </div>
        </div>
</template>

<script>
/* page that the land owner will land on when using their unique uuid */
import {API_URL, getParameterByName} from '@/lib/common';
import LandOwnerHomePageTable from './LandOwnerHomePageTable';
import SinglePointMap from '@/components/mapGL/SinglePointMap';
import VisitLocationPhotosVue from '../common/VisitLocationPhotos.vue';
import LocationSingleVisitTableVue from '../organization/location-visit/LocationSingleVisitTable.vue';

export default {
  name: 'landowner-home-page',
  props: [],
  components: {'sensor-table': LandOwnerHomePageTable, 'point-map': SinglePointMap, 'location-photos': VisitLocationPhotosVue, 'single-visit-table': LocationSingleVisitTableVue},
  created () {
    console.log('hello????');
    console.log('hi :3');
    this.userUUID = getParameterByName('UUID');
    if (!this.userUUID) {
      this.error = this.$t('landOwnerHome-missingUUID');
      return;
    }
    this.loadReport();
  },
  methods: {
    loadReport () {
      this.$http.get(this.getLocationReportUrl + this.userUUID).then(
        response => {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.reportPuds = response.data.puds;
            this.reportAruTasks = response.data.aruTasks;
            this.reportLocation = response.data.location;
            this.activeTab = this.reportLocation.cameraExists ? 'CAM' : 'ARU';
            this.loadLocationVisitInfo();
          }
        })
    },
    clickTab: function (tab) {
      this.activeTab = tab;
    },
    loadLocationVisitInfo () {
      this.$http.get(this.getAllVisitsUrl + this.reportLocation.id + '&uuid=' + this.userUUID).then(
        response => {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.allVisitsInfo = response.data;
            if (this.allVisitsInfo.total_row_count > 0) {
              // show or hide location photos tab
              for (let visit in this.allVisitsInfo.results) {
                if (this.allVisitsInfo.results[visit].hasImage) {
                  this.hasVisitPhotos = true;
                  break;
                }
              }
              this.$http.get(this.getVisitOptions).then(
                response => {
                  if (response.data.hasOwnProperty('error')) {
                    this.error = response.data.error;
                  } else {
                    this.staticVisitOptions = response.data;
                    this.staticVisitOptions.landFeature = (this.staticVisitOptions.landFeature || []).map(x => { return {type: x.type, landFeatureId: x.id}; });
                  }
                });
            }
          }
        })
    }
  },
  data () {
    return {
      getLocationReportUrl: API_URL + 'get-location-report?UUID=',
      getAllVisitsUrl: API_URL + 'get-all-location-visits?locationId=',
      getVisitOptions: API_URL + 'get-location-visit-static-options',
      reportPuds: null,
      reportAruTasks: null,
      reportLocation: null,
      staticVisitOptions: null,
      allVisitsInfo: null,
      userUUID: null,
      error: null,
      activeTab: 'CAM',
      hasVisitPhotos: false
    }
  }
}
</script>

<style scoped>
  .tabs li.tab a.active {
      background: #ffffff;
  }
</style>
