<template>
<div id="CAMTask-table" class="deployment-table tasks">
  <div class="row no-bottom-margin">
    <div class="col s12 m12 l12 xl12">
      <div v-if="isManager && !isPublished" class="col s12 m12 l6 xl6 left">
          <multiselect :selectAllLabel="$t('common-selectAll')" v-model="randomAssignUsers" :options="userList" :multiple="true" :close-on-select="false"
            :placeholder="$t('taskTable-selectTaggersToAssign')" label="text" track-by="id" selectLabel="" deselectLabel=""
            :showSelectAll="true" class="tagger-select">
              <template slot="selection" slot-scope="{ values, isOpen }">
                  <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{ $t('taskTable-taggersSelected',{num: values.length})}}</span>
                  <span class="multiselect__single" v-if="values.length && values.length == 1 && !isOpen">{{$t('taskTable-singleTagger')}}</span>
              </template>
          </multiselect>
          <a class='btn btn-success btnimportant right assign-btn' :class="{'downloading':randomAssignLoading}" @click="randomAssign"><span class="spin-icon"></span>{{$t('taskTable-randomAssign')}}</a>
        </div>
            <div class="right right-side-wrapper">
        <span v-if="toggleInitSetting !== null && isUserProjectMember" class="task-toggle-manage"> {{$t('taskTable-viewOnlyMine')}} <ToggleButton :value="toggleInitSetting" :sync="true" color="#C94412" @change="switchTaskView"></ToggleButton></span>
      <div v-if="isPublished != null && hasProjectWriteAccess != null" class="right">
        <dropdown-button>
          <template slot="menuTitle">{{$t('common-manage')}} <span class="!text-sm" v-show="tableOptions.activeRowId.length">({{ tableOptions.activeRowId.length }})</span></template>
          <template slot="dropdownButtons">
            <a v-show="tableOptions.activeRowId.length>0" @click="sendToAI"><span class="btn btn-success popover-menu-item" v-close-popover>
              {{$t('Tag image sets with AI')}}
            </span></a>
            <a  @click="openBatchUpload()"><span class="btn btn-success popover-menu-item" v-close-popover>{{$t('taskTable-camUpload')}}</span></a>

            <!-- Download locations -->
            <a download class="group-top btn btn-success popover-menu-item" :class="{'downloading': downloading }" @click="downloadLocationCSV"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('locationTable-downloadCSV')}}</a>
            
            <!-- Upload Locations -->
            <span v-if="!canUpload" v-tooltip="{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}">
              <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span style="font-size: 20px;"></span> {{$t('locationTable-uploadCSV')}}</button>
            </span> 
            <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span style="font-size: 20px;"></span> {{$t('locationTable-uploadCSV')}}</button>  


            <a download class="btn btn-success popover-menu-item group-top" :class="{'downloading': downloadingTags }" @click="downloadTags">
              <span style="font-size: 20px;"></span><span class="spin-icon"></span><template v-if="tableLoadedOnce && $refs.table.data.length === 0">{{$t('taskTable-downloadTagTemplate')}}</template><template v-else>{{$t('taskTable-downloadTags')}}</template>
            </a>
            <!-- popover + tooltip has been buggy so this v-if part is necessary to avoid bug -->
            <span v-if="!canUpload" v-tooltip="{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}">
              <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover class="btn btn-success popover-menu-item" @click="uploadTagsCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-tag', 2)})}}</button>
            </span>
            <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadTagsCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-tag', 2)})}}</button>
            <a download class="btn btn-success popover-menu-item group-top" :class="{'downloading': downloadingCodes }" @click="downloadCodes"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('common-downloadCodes')}}</a>
             <a v-show="tableOptions.activeRowId.length>0" @click="showDeleteTaskForm(activeRows)"><span class="group-top btn btn-success popover-menu-item" v-close-popover>
                {{$t('Delete Tasks')}}
            </span></a>
          </template>
        </dropdown-button>
        </div>
      </div>
    </div>
  </div>
  <div class="row col s12 error no-bottom-margin" :class="{ 'error-hide': !message}" v-if="message!=null">{{message}}</div>
  <v-server-table ref="table" v-if="optionsLoaded && tableOptions" :url="url" :columns="columns" :options="tableOptions" @loaded="tableLoaded()" 
  @limit="resetCheckBoxes" @pagination="resetCheckBoxes"
  class="table vue-bordered taskTable w-full">
  <template slot="h__check">
          <span @click="onCheckAllClick">
            <i class="fa fa-check text-2xl cursor-pointer" v-if="tableOptions.activeRowId.length == tableOptions.perPage"></i>
            <i class="fa-regular fa-square-full text-xl cursor-pointer" v-if="tableOptions.activeRowId.length < tableOptions.perPage"></i>
          </span>
        </template>
        <template slot="check" slot-scope="props">
          <label>
            <input type="checkbox" :data-id="props.row.id" @click="onCheckClick(props.row)" />
            <span></span>
          </label>
        </template>
    <template slot="deploymentName" slot-scope="props">
      <a :href="recordingRowUrl(props.row.id)">
        <span class="indicator" :class="cameraGetClass(props.row.statusId)"></span>{{props.row.deploymentName}}</a>
    </template>

       <template slot="statusId" slot-scope="props">
        {{getStatus(props.row.statusId)}}
        </template>
    <template slot="userId" slot-scope="props">
      <template  v-if="isManager && editUserId == props.row.id && !isPublished">
        <div class="col s10">
          <select style="height: 2em" v-model="props.row.userId" @focus="clearMessage" @change="updateUser(props.row, $event)">
            <option value="-1"> -- {{$t('taskTable-notAssigned')}} -- </option>
            <option v-for="(user, index) in userList" v-bind:key="'user' + index" :value="user.id"> {{user.text}}</option>
          </select>
        </div>
        <div class="col s2 link" @click="editUserId = -1">
          <span  class="ion-checkmark  text-burntOrange"/>
        </div>
      </template>
      <template v-else>
        <div class="col s9">
          {{props.row.tagger}} <span v-if="isManager && editUserId !== props.row.id && !isPublished" @click="editUserId = props.row.id" class="ion-edit cursor-pointer text-burntOrange "/>
        </div>
      </template>
    </template>
        <div class="row no-bottom-margin taskTable" slot="child_row" slot-scope="props" v-if="toLoadSubRow">
          <div class="grid grid-cols-6">
            <div class="center">{{$t('taskTable-totalImages')}}</div>
            <div class="center">{{$t('taskTable-motionImages')}}</div>
            <div class="center">{{$t('taskTable-model')}}</div>
            <div class="center">{{$t('Serial #')}}</div>
            <div class="center">{{$t('taskTable-firstImage')}}</div>
            <div class="center">{{$t('taskTable-lastImage')}}</div>
          
          
         
            <template v-if="props.row.subDetails != null && props.row.subDetails.length > 0">
              <div class=" center brown-color">{{props.row.subDetails[0].totalImages}} </div>
              <div class=" center brown-color">{{props.row.subDetails[0].motionImages}} </div>
              <div class=" center brown-color">{{props.row.subDetails[0].cameraMakeModel}} </div>
              <div class=" center brown-color">{{props.row.subDetails[0].serialNumber}} </div>
              <div class=" center brown-color">{{props.row.subDetails[0].firstImage}}</div>
              <div class=" center brown-color">{{props.row.subDetails[0].lastImage}}</div>
            </template>
          </div>
          <hr />
          <template v-if="props.row.subDetails != null && props.row.subDetails.length > 0">
            <div class="row" v-show="props.row.subDetails[0].tagTypes">
              <div class=" col center s2">{{$t('taskTable-tagTypes')}}</div>
            </div>
            <div class="row" v-show="props.row.subDetails[0].tagTypes">
              <div class="row col s11 offset-s1 brown-color">{{props.row.subDetails[0].tagTypes}} </div>
            </div>
            <div class="row">
              <div class="col center s2">{{$t('AI Detection Status')}}</div>
            </div>
            <div class="row">
              <div class="col s11 row offset-s1 brown-color">{{props.row.subDetails[0].megaDetectorStatus}} </div>
            </div>
          </template>
            <div v-if="!props.row.childRowLoading" class="row col s12 no-bottom-margin">
              <div class="btns-wrapper right">
                  <!-- <button :ref="'autotag-btn-' + props.row.id" class="btn btn-success" :class="{'btn-border': !isManager}" :disabled="!isManager || !props.row.subDetails[0].allowAutoTagger" @click="submitForAutotagging(props.row)"> <span style="font-size: 20px;"></span>{{$t('taskTable-submitForAutotagger')}}</button> -->
                  <button class="btn" :class="{'btn-border': !isManager}" :disabled="!isManager" @click="showDeleteTaskForm([props.row])"> <span style="font-size: 20px;"></span>{{$t('taskTable-deleteTask')}}</button>
                </div>
              </div>
        </div>
  </v-server-table>
  <modal name="delete_task"
    :scrollable="true" :adaptive="true"
    transition="nice-modal-fade"
    classes="modal-form scroll"
    draggable=".drag-handle"
    :delay="100"
    width = "99%"
    height = "420"
    :minWidth="400"
    :minHeight="540"
    :clickToClose = "false"
  >
    <task-delete-form v-if="showDeleteForm"
      v-bind:task-rows="taskRows"
      v-bind:task-ids="taskIds"
      v-bind:headings="tableOptions.headings"
      v-bind:active-sensor="'CAM'"/>
  </modal>
      <modal name="project-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <upload-csv-form
      v-bind:project-name='projectName' v-bind:projectId='projectId' v-bind:upload-url='apiUrls.uploadTagsCSVUrl'
      v-bind:uploading-tags='uploadingTags'
      />
    </modal>
    <modal name="location-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <upload-location-csv
      v-if="project.organizationId && options"
      v-bind:organization-id="project.organizationId"
      v-bind:organization-name="project.fullNm"
      v-bind:visibility-options="options.visibility"
      v-bind:project-id="projectId"
      v-bind:project="project"
      v-bind:type="'project-location'"/>
    </modal>
    <v-dialog/>
</div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
'use strict'
import {API_URL, CAMERA_RECORDING_URL, alertDialog} from '@/lib/common';
import { createLink } from '@/lib/language.js';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import TaskDeleteForm from './TaskDeleteForm';
import { ToggleButton } from 'vue-js-toggle-button';
import TaskTableMixin from './TaskTableMixin';
import VueTableMixin from '@/components/common/VueTableMixin';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';
import TaskTableUploadCSVForm from './TaskTableUploadCSVForm.vue';
import { eventBus } from '../../lib/eventbus';

export default {
  name: 'camera-task-table',
  props: ['showAutoTagButton', 'sensorId', 'hasProjectWriteAccess', 'projectName'],
  components: { 'multiselect': Multiselect, 'ToggleButton': ToggleButton, 'task-delete-form': TaskDeleteForm, 'dropdown-button': DropdownButtonWithOptionsVue, 'upload-csv-form': TaskTableUploadCSVForm},
  mixins: [TaskTableMixin, VueTableMixin],
  methods: {
    sendToAI(){
     // console.log(this.tableOptions.activeRowId);
      this.$http.post(API_URL+'apply-ai-tags-to-puds', this.tableOptions.activeRowId).then(resp=>{
        if (resp.body.hasOwnProperty('error')) {
           alertDialog(this.$modal, this.$t('Error'), resp.body.error);
        } else {
          alertDialog(this.$modal, this.$t('Success!'), this.$t('Deployment images sent to AI for processing'));
          this.resetCheckBoxes();
        }
      });
    },
     getStatus (id) {
      return this.filterOptions.status.find(status => status.id === id).type;
    },
    cameraGetClass (statusId) {
      let className, status = "";

      switch (statusId) {
        case 1:
          className = 'ion-checkmark';
          break;
        case 2:
          className = 'ion-ios-pricetag';
          break;
        case 4:
          className = 'ion-cloud-upload'; break;
        case 5:
          className = '';
        case 7:
          className = '';
      }
      
      return {[className]: true, [status.replace(/\s/g, '-')]: true};
    },
    /* call get pud detail, and change user object, and send to update */
    updateUser (row, $event) {
      const userId = row.userId;
      const deploymentId = row.id;
      this.message = '';
      /* ---------------- step 1, get user project deployment details --------- */
      this.$http.get(this.apiUrls.getDeployment, {params: {id: deploymentId}}).then(
        (response) => {
          if (response.data.hasOwnProperty('error')) {
            this.message = this.$t('cameraUploader-deploymentInfoFail', {err: response.data.error});
          } else {
            let deployment = response.data;
            deployment.user = {id: userId};

            /* -------------step 2, update user id ----------- */
            this.$http.post(this.apiUrls.updateUser, deployment).then((updated) => {
              if (updated.error) {
                this.message = this.$tc('common-error', 1) + ':' + updated.error;
              } else {
                if (updated.data.user.id === -1) {
                  this.message = this.$t('taskTable-unassigned', {task: row.deploymentName});
                } else {
                  this.message = this.$t('taskTable-assigned', {task: row.deploymentName, name: updated.data.user.name});
                }
                row.tagger = updated.data.user.name;
              }
            }, (err) => {
            // error callback
              this.message = this.$t('taskTable-messages.updateUserError') + ' ' + err;
            });
          }
        },
        (error) => {
          this.message = this.$t('cameraUploader-deploymentInfoFail', {err: error});
        }
      ).catch((err) => { this.message = this.$t('cameraUploader-deploymentInfoFail', {err: err}) });
    },
    submitForAutotagging (row) {
      // just for one task, not entire project
      this.$refs['autotag-btn-' + row.id].disabled = true;

      this.$http.post(this.apiUrls.submitForAutoTaggingUrl + row.id).then(
        (response) => {
          if (response.data.hasOwnProperty('error')) {
            alertDialog(this.$modal, this.$t('taskTable-autoTagSent'), this.$t('taskTable-messages.requestFailed', {err: response.data.error}));
            this.$refs['autotag-btn-' + row.id].disabled = false;
          } else {
            this.$refs.table.getData();
          }
        },
        (error) => {
          alertDialog(this.$modal, this.$t('taskTable-autoTagSent'), this.$t('taskTable-messages.requestFailed', {err: error}));
          this.$refs['autotag-btn-' + row.id].disabled = false;
        }
      );
    }
  },
  created () {
    // this.columns = this.isManager ? this.initColumns : this.initUserColumns;
    // this.$set(this.tableOptions.listColumns, 'methodType', (this.filterOptions.method|| []).map(x => { return {id: x.text, text: x.text}; }));
    this.$set(this.tableOptions.listColumns, 'userId', [{id: -1, text: this.$t('Not Assigned')}].concat((this.userList || []).map(x => ({id: x.id, text: x.text}))));
   // this.$set(this.tableOptions.listColumns, 'tagger', (this.filterOptions.transcriber || []).map(x => { return {id: x.text, text: x.text}; }));
    this.$set(this.tableOptions.listColumns, 'statusId', (this.filterOptions.status || []).map(x => { return {id: x.id, text: x.type}; }));
    this.optionsLoaded = true;
    // view only the user's tasks by default
    this.tableOptions.showOwnOnly = this.hasAssignedTasks;
    this.getLocationOptions();

  
   

  },

  data () {
    return {
      downloading: false,
      options: null,
      apiUrls: {
        list: API_URL + 'get-camera-tasks-for-project?projectId=' + this.projectId,
        getDeployment: API_URL + 'get-camera-pud',
        updateUser: API_URL + 'update-camera-pud',
        idUrl: createLink(CAMERA_RECORDING_URL),
        assignUser: API_URL + 'randomly-assign-users-not-assigned-camera-puds',
        taskDetailsUrl: API_URL + 'get-camera-pud-details?pudId=',
        submitForAutoTaggingUrl: API_URL + 'submit-pud-for-auto-tagging?pudId=',
        uploadTagsCSVUrl: API_URL + 'upload-camera-tags-csv-for-sync?projectId=',
        downloadTagsCSVUrl: API_URL + 'download-camera-tags-by-project-id?projectId=',
        downloadCodesURL: API_URL + 'download-camera-tag-codes'

      },
      columns: ['check', 'deploymentName', 'startDate', 'motionImageCount', 'taggedCount', 'statusId', 'userId'],
      tableOptions: {
        rowClassCallback (row) {
          let rowId = parseInt(row.id);
          return (this.activeRowId, this.activeRowId.indexOf(rowId)>=0 ? 'activeTableRow' : '');
        },
        filterByColumn: true,
        filterable: ['deploymentName', 'startDate', 'statusId', 'userId'],
        sortable: ['deploymentName', 'startDate', 'motionImageCount', 'taggedCount', 'statusId', 'userId'],
        serverMultiSorting: true,
        listColumns: {
          'userId': this.filterOptions.transcriber,
          'statusId': this.filterOptions.taskStatus
        },
        headings: {
          check: '',
          deploymentName: this.$tc('common-location', 1),
          startDate: this.$t('taskTable-CAMheadings.startDate'),
          motionImageCount: this.$t('taskTable-CAMheadings.motionImageCount'),
          taggedCount: this.$t('taskTable-CAMheadings.taggedCount'),
          statusId: this.$t('common-status'),
          userId: this.$t('taskTable-CAMheadings.tagger')
        },
        columnsClasses: {
          deploymentName: 'deploymentName',
          startDate: 'year',
          motionImageCount: 'total',
          taggedCount: 'tagged',
          statusId: 'status',
          userId: 'tagger'
        },
        // customFilters: [{
        //   name: 'status',
        //   callback: function (row, query) {
        //     return query.code.includes(row.code);
        //   }
        // }],
        orderBy: {column: 'deploymentName', ascending: true}
      }
    }
  }
}
</script>

<style>
  @import './task-table.css';

#CAMTask-table .total {
  text-align: center;
}
#CAMTask-table .year{
    width: 13%;
  }

</style>
