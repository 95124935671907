var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col s12 panel boxed padded60 dataGroup tab-content" },
        [
          _c("div", { staticClass: "button-section-wrapper" }, [
            _vm.options != null
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-success organizationAdd",
                    on: { click: _vm.createOrganization }
                  },
                  [
                    _c("span", { staticStyle: { "font-size": "20px" } }),
                    _vm._v(" " + _vm._s(_vm.$t("org-addBtn")))
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.toggleInitSetting !== null
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t(
                          "Toggle On to see all organizations you have access to. Toggle Off to display all WildTrax organizations. Click the dropdown beside any organization to find out more or to request access."
                        ),
                        expression:
                          "$t('Toggle On to see all organizations you have access to. Toggle Off to display all WildTrax organizations. Click the dropdown beside any organization to find out more or to request access.')"
                      }
                    ],
                    staticClass: "org-toggle"
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("org-viewOnlyMine")) + " \n        "
                    ),
                    _c("ToggleButton", {
                      attrs: {
                        value: _vm.toggleInitSetting,
                        sync: true,
                        color: "#C94412"
                      },
                      on: { change: _vm.switchOrgView }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("v-server-table", {
            ref: "table",
            staticClass: "table vue-bordered w-full org-table",
            attrs: {
              url: _vm.url,
              columns: _vm.columns,
              options: _vm.tableOptions
            },
            on: {
              "row-click": _vm.onRowClick,
              loaded: function($event) {
                return _vm.tableLoaded()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "organization",
                fn: function(props) {
                  return [
                    props.row.canOpenOrganization && !props.row.isPendingStatus
                      ? _c(
                          "a",
                          { attrs: { href: _vm.taskRowUrl(props.row.id) } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(props.row.organization) +
                                "\n        "
                            )
                          ]
                        )
                      : _c("span", [_vm._v(_vm._s(props.row.organization))])
                  ]
                }
              },
              {
                key: "isPendingStatus",
                fn: function(props) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          props.row.isPendingStatus
                            ? _vm.$t("org-statusOptions.pending")
                            : _vm.$t("org-statusOptions.approved")
                        ) +
                        "\n      "
                    )
                  ]
                }
              },
              {
                key: "id",
                fn: function(props) {
                  return [
                    props.row.canEditOrganization
                      ? _c(
                          "a",
                          {
                            staticClass: "editProject modal-trigger projectAdd",
                            on: {
                              click: function($event) {
                                return _vm.updateOrganization(props.row)
                              }
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "link",
                              class: {
                                "ion-edit": props.row.canEditOrganization,
                                "ion-eye": !props.row.canEditOrganization
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "child_row",
                fn: function(props) {
                  return _c("div", { staticClass: "row" }, [
                    props.row.fullName
                      ? _c("div", { staticClass: "col s12 m12 l12" }, [
                          _c("p", { staticClass: "left-align" }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.$t("org-fullName")) + ": ")
                            ]),
                            _vm._v(_vm._s(props.row.fullName))
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m12 l12" }, [
                      props.row.description
                        ? _c("p", { staticClass: "left-align" }, [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("common-description")) + ": "
                              )
                            ]),
                            _vm._v(_vm._s(props.row.description))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.canRequestAccess && !props.row.isPendingStatus
                        ? _c(
                            "button",
                            {
                              staticClass: "float-right btn btn-border",
                              on: {
                                click: function($event) {
                                  return _vm.requestAccess(props.row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("common-requestAccess")))]
                          )
                        : _vm._e()
                    ])
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.organizationId != -1 &&
      _vm.options != null &&
      _vm.organizationName == null
        ? _c("organization-form", {
            ref: "organizationForm",
            attrs: {
              "organization-id": _vm.organizationId,
              "is-editable": _vm.isEditable,
              "is-global-admin": _vm.isGlobalAdmin,
              options: _vm.options
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "requestAccess",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "75%",
            height: "620",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.organizationId != -1 && _vm.organizationName != null
            ? _c("project-access-request-form", {
                ref: "projectAccessRequestForm",
                attrs: {
                  organizationId: _vm.organizationId,
                  organizationName: _vm.organizationName
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }