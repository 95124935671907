<template>
  <div>
    <div class="col s12 panel boxed padded60 dataGroup tab-content" :class="{[sensor]: true}">
      
        <v-client-table v-show="sensor === 'ARU' && aruTableData.length > 0" :data="aruTableData" :columns="aruColumns" :options="aruTableOptions">
          <template slot="location" slot-scope="props">
            <a :href="getReportUrl(props.row)">{{props.row.location}}</a>
          </template>
          <div class="row no-bottom-margin taskTable" slot="child_row" slot-scope="props">
            <div class="row s12">
              <div class="col center s4">{{$t('landOwnerTable-speciesFound')}}</div>
            </div>
            <div class="subtable">
            <template>
              <div class="row s12 brown-color">
                <template v-if="props.row.speciesFound.length === 0">{{$t('landOwnerTable-noSpeciesFoundRecording')}}</template>
                <template v-for="(species, index) in props.row.speciesFound">{{species}}<template v-if="index != props.row.speciesFound.length -1">, </template> </template>
              </div>
            </template>
            </div>
          </div>
        </v-client-table>
        <v-client-table v-show="sensor === 'CAM' && camTableData.length > 0" :data="camTableData" :columns="camColumns" :options="camTableOptions">
          <template slot="location" slot-scope="props">
            <a :href="getReportUrl(props.row)">{{props.row.location}}</a>
          </template>
          <div class="row no-bottom-margin taskTable" slot="child_row" slot-scope="props">
            <div class="row s12">
              <div class="col center s4">{{$t('landOwnerTable-speciesFound')}}</div>
            </div>
            <div class="subtable">
            <template>
              <div class="row s12 brown-color">
                <template v-if="props.row.speciesFound.length === 0">{{$t('landOwnerTable-noSpeciesFoundImg')}}</template>
                <template v-for="(species, index) in props.row.speciesFound">{{species}}<template v-if="index != props.row.speciesFound.length -1">, </template> </template>
              </div>
            </template>
            </div>
          </div>
        </v-client-table>
        <div class="error" v-if="aruTableData.length === 0 && camTableData.length === 0">{{$t('landOwnerTable-noData')}}</div>
    </div>
  </div>
</template>

<script>
/* this component shows the projects and tasks where landowner data has been used
*/
import {eventBus} from '@/lib/eventbus';
import {LANDOWNER_REPORT_URL} from '@/lib/common';
import { createLink } from '@/lib/language.js';

export default {
  name: 'LandOwnerHomePageTable',
  props: ['sensor', 'uuid', 'puds', 'aruTasks', 'location'],
  async created () {
    const self = this;
    this.initTable();

    eventBus.$on('reload-table', function () {
      self.$refs.table.refresh();
    });
  },
  methods: {
    initTable () {
      // set up tabledata
      if (this.puds && this.puds.length > 0) {
        let row;
        this.puds.forEach(pud => {
          row = {
            location: this.location.name,
            project: pud.project.fullNm,
            year: pud.project.year,
            dueDateStr: pud.project.dueDateStr,
            status: pud.status.type,
            speciesFound: pud.speciesFound,
            pudId: pud.id
          }
          this.camTableData.push(row);
        });
      }
      if (this.aruTasks && this.aruTasks.length > 0) {
        let row;
        this.aruTasks.forEach(task => {
          row = {
            location: this.location.name,
            project: task.projectName,
            date: task.recordingDate,
            method: task.method,
            speciesFound: task.speciesFound,
            taskId: task.id
          }
          this.aruTableData.push(row);
        });
      }
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.id;
    },
    getReportUrl (row) {
      if (this.sensor === 'ARU') {
        return createLink(LANDOWNER_REPORT_URL + this.uuid + '&taskId=' + row.taskId);
      }
      if (this.sensor === 'CAM') {
        return createLink(LANDOWNER_REPORT_URL + this.uuid + '&pudId=' + row.pudId);
      }
    }
  },
  watch: {
    sensor: async function (newVal, oldVal) {
      /* for (var key in this.$refs['table'].query) {
        this.$refs['table'].query[key] = '';
      } */

    //  this.$refs.table.refresh();
    }
  },
  data () {
    return {
      recordName: this.$tc('common-project', 1).toLowerCase(),
      tableLoadedOnce: false,
      camTableData: [],
      aruTableData: [],
      camColumns: ['location', 'project', 'year', 'dueDateStr', 'status'],
      aruColumns: ['location', 'project', 'date', 'method'],
      options: {},
      camTableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        uniqueKey: 'pudId',
        filterable: ['project', 'year'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['location', 'project', 'year', 'dueDateStr', 'status'],
        listColumns: {},
        headings: {
          location: this.$t('landOwnerTable-headings.location'),
          project: this.$tc('common-project', 1),
          year: this.$t('landOwnerTable-headings.year'),
          dueDateStr: this.$t('landOwnerTable-headings.dueDateStr'),
          status: this.$t('landOwnerTable-headings.status')
        },
        columnsClasses: {
          location: 'location',
          project: 'project',
          year: 'year',
          dueDateStr: 'due-date',
          status: 'status'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'year', ascending: false},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        }
      },
      aruTableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        uniqueKey: 'taskId',
        filterable: ['project', 'method'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['location', 'project', 'date'],
        listColumns: {},
        headings: {
          location: this.$t('landOwnerTable-headings.location'),
          project: this.$tc('common-project', 1),
          date: this.$t('landOwnerTable-headings.date'),
          method: this.$t('landOwnerTable-headings.method')
        },
        columnsClasses: {
          location: 'location',
          project: 'project',
          date: 'date',
          method: 'method'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'date', ascending: false},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        }
      }
    }
  }
}
</script>
<style scoped>
div :deep( .subrow-info p ){
  color: #C94412;
}
div :deep( th.name) {
  width: 30%;
}
div :deep( th.details) {
  width: 3%;
}
div :deep( th.year), div :deep( th.tasks) {
  width: 10%;
}
div :deep( th.completed) {
  width: 12%;
}
div :deep( th.dueDate) {
  width: 10%
}
div :deep( th.status ) {
  width: 15%;
}
div :deep( .VueTables__table td),
div :deep( .VueTables__table th) {
  text-align: center;
}
div :deep( .VueTables__table td.name),
div :deep( .VueTables__table th.name) {
  text-align: left;
}

div :deep( .VueTables__child-row-toggler) {
  cursor: pointer;
}

.float-right{
  float: right;
}

div :deep( .vue-js-switch){
  padding-left: 5px;
}

.button-section-wrapper{
  display:flex;
  flex-direction: row;
  align-items: center;

}

.single-btn-wrapper{
  /* this element and css needed in order to show tooltip on disabled btn */
  display: inline-block;
}

.subtable{
margin-left:20px;
}
</style>
