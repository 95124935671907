var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue" },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.error.statusText) + "\n    ")
                ])
              : _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container overflow tabbed" },
              [
                _c("org-table", {
                  attrs: { "edit-organziation-id": _vm.editOrganziationId }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "container overflow boxed homeHead !mb-5",
        staticStyle: { "padding-top": "4%", "padding-bottom": "4%" }
      },
      [
        _c(
          "div",
          { staticClass: "headImg image-container", attrs: { id: "org-page" } },
          [
            _c("span", { staticClass: "photo-credit white small" }, [
              _vm._v("Brandon Smith")
            ])
          ]
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "grey-font" }, [
          _c("span", { staticStyle: { color: "#FFFFF" } }, [
            _vm._v(" My Organizations ")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }