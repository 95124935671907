var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col s12 panel boxed padded60 dataGroup tab-content" },
        [
          _c(
            "v-server-table",
            {
              ref: "table",
              staticClass: "table vue-bordered w-full",
              attrs: {
                url: _vm.url,
                columns: _vm.columns,
                options: _vm.tableOptions
              },
              on: {
                loaded: _vm.loaded,
                loading: _vm.loading,
                "row-click": _vm.onRowClick
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "child_row",
                    fn: function(props) {
                      return _vm.toLoadSubRow
                        ? _c("div", { staticClass: "row taskTable" }, [
                            props.row.subDetails &&
                            props.row.subDetails.length > 0
                              ? _c("div", [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col s4" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "orgImageSets-subTable.projectName"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col s3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "orgImageSets-subTable.assignedUser"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col s2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "orgImageSets-subTable.uniqueSpecies"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col s2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "orgImageSets-subTable.seriesGap"
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "subTable" },
                                    [
                                      _vm._l(props.row.subDetails, function(
                                        task,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key:
                                                "subrow-" +
                                                task.pudId +
                                                "" +
                                                index,
                                              staticClass:
                                                "row link brown-color",
                                              on: {
                                                click: function($event) {
                                                  return _vm.openChildRowLink(
                                                    task.pudId
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "col s4 red-font"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(task.projectName) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col s3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(task.transcriber) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col s2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      task.uniqueMammalCount
                                                    ) + " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col s2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(task.seriesGap) + " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ])
                              : _c("div", [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.$t("common-noTaskFound")))
                                  ])
                                ])
                          ])
                        : _vm._e()
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _vm.isLoading
                ? _c(
                    "template",
                    { slot: "beforeBody" },
                    [_c("pulse-loader")],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }