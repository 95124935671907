var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "organization",
        scrollable: true,
        adaptive: true,
        transition: "nice-modal-fade",
        classes: "modal-form scroll",
        draggable: ".drag-handle",
        delay: 100,
        width: "70%",
        height: "80%",
        minWidth: 400,
        minHeight: 560,
        clickToClose: false
      }
    },
    [
      _c("div", { attrs: { id: "organizationModal" } }, [
        _c("ul", { staticClass: "tabs drag-handle" }, [
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function($event) {
                  _vm.showTab = "organization"
                }
              }
            },
            [
              _c("a", { class: { active: _vm.showTab == "organization" } }, [
                _vm._v(
                  _vm._s(
                    _vm.organizationId == null
                      ? _vm.$t("orgForm-create")
                      : _vm.$t("orgForm-update")
                  ) +
                    " " +
                    _vm._s(_vm.organization && _vm.organization.sensorId)
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm.organization && _vm.organization.id != null && _vm.isEditable
            ? _c(
                "li",
                {
                  staticClass: "tab",
                  on: {
                    click: function($event) {
                      _vm.showTab = "user"
                    }
                  }
                },
                [
                  _c("a", { class: { active: _vm.showTab == "user" } }, [
                    _vm._v(_vm._s(_vm.$t("projectForm-users")))
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iconButton link", on: { click: _vm.close } },
            [_c("span", { staticClass: "ion-close" })]
          )
        ]),
        _vm._v(" "),
        _vm.organization != null
          ? _c("div", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTab === "organization",
                      expression: "showTab ==='organization'"
                    }
                  ],
                  staticClass: "modal-content padded40"
                },
                [
                  _c("h4"),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12 m6 l5" }, [
                      _c(
                        "label",
                        { attrs: { for: "form_organization_name" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("orgForm-acronym")) +
                              "\n              "
                          ),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.tooltips.name,
                                expression: "tooltips.name"
                              }
                            ],
                            staticClass: "info-icon ion-information-circled"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.organization.name,
                            expression: "organization.name"
                          }
                        ],
                        staticClass: "validate",
                        attrs: {
                          id: "form_organization_name",
                          type: "text",
                          required: "",
                          placeholder: _vm.$t("orgForm-orgName")
                        },
                        domProps: { value: _vm.organization.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.organization,
                              "name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l7" }, [
                      _c("label", { attrs: { for: "formshortCode" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("orgForm-fullName")) +
                            "\n              "
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.fullName,
                              expression: "tooltips.fullName"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.organization.fullName,
                            expression: "organization.fullName"
                          }
                        ],
                        staticClass: "validate",
                        attrs: {
                          id: "formshortCode",
                          type: "text",
                          required: "",
                          placeholder: _vm.$t("orgForm-fullName")
                        },
                        domProps: { value: _vm.organization.fullName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.organization,
                              "fullName",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6" }, [
                      _c(
                        "label",
                        { attrs: { for: "form_organization_institution" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("orgForm-institution")) +
                              "\n              "
                          ),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.tooltips.institution,
                                expression: "tooltips.institution"
                              }
                            ],
                            staticClass: "info-icon ion-information-circled"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.organization.institution,
                            expression: "organization.institution"
                          }
                        ],
                        staticClass: "validate",
                        attrs: {
                          id: "form_organization_institution",
                          type: "text",
                          required: "",
                          placeholder: _vm.$t("orgForm-institutionName")
                        },
                        domProps: { value: _vm.organization.institution },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.organization,
                              "institution",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.organizationId != null
                      ? _c("div", { staticClass: "col s12 m6" }, [
                          _c(
                            "label",
                            {
                              staticClass: "investigator-text",
                              attrs: { for: "form_organization_person1" }
                            },
                            [_vm._v(_vm._s(_vm.$t("orgForm-primaryPI")))]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.tooltips.primary,
                                expression: "tooltips.primary"
                              }
                            ],
                            staticClass: "info-icon ion-information-circled"
                          }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.organization
                                      .principalInvestigatorUserId,
                                  expression:
                                    "organization.principalInvestigatorUserId"
                                }
                              ],
                              attrs: {
                                disabled: _vm.organizationId == null,
                                id: "form_organization_person1"
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.organization,
                                    "principalInvestigatorUserId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.adminUserList, function(admin, index) {
                              return _c(
                                "option",
                                {
                                  key: "primary" + index,
                                  domProps: { value: admin.id }
                                },
                                [_vm._v(_vm._s(admin.name))]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.organizationId != null
                      ? _c("div", { staticClass: "col s12 m7" }, [
                          _c(
                            "label",
                            {
                              staticClass: "investigator-text",
                              attrs: { for: "form_organization_person2" }
                            },
                            [_vm._v(_vm._s(_vm.$t("orgForm-secondaryPI")))]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.tooltips.secondary,
                                expression: "tooltips.secondary"
                              }
                            ],
                            staticClass: "info-icon ion-information-circled"
                          }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.organization
                                      .alternateInvestigatorUserId,
                                  expression:
                                    "organization.alternateInvestigatorUserId"
                                }
                              ],
                              attrs: {
                                disabled: _vm.organizationId == null,
                                id: "form_organization_person2"
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.organization,
                                    "alternateInvestigatorUserId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.adminUserList, function(admin, index) {
                              return _c(
                                "option",
                                {
                                  key: "primary" + index,
                                  domProps: { value: admin.id }
                                },
                                [_vm._v(_vm._s(admin.name))]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m5" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("orgForm-defaultStorage"))),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.storage,
                              expression: "tooltips.storage"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.organization.defaultStorageId,
                                expression: "organization.defaultStorageId"
                              }
                            ],
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.organization,
                                  "defaultStorageId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.options.storage, function(option, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: option.id } },
                              [_vm._v(_vm._s(option.displayName))]
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m5 clear-left" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("orgForm-defaultLocationBuffering"))
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.trueLocation,
                              expression: "tooltips.trueLocation"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-option" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.organization.defaultIsTrueCoordinates,
                              expression:
                                "organization.defaultIsTrueCoordinates"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "trueLocation",
                            name: "bufferLocation",
                            disabled: !_vm.isEditable
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(
                              _vm.organization.defaultIsTrueCoordinates,
                              true
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.organization,
                                "defaultIsTrueCoordinates",
                                true
                              )
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("orgForm-trueLocations")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-option" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.organization.defaultIsTrueCoordinates,
                              expression:
                                "organization.defaultIsTrueCoordinates"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "bufferedLocation",
                            name: "bufferLocation",
                            disabled: !_vm.isEditable
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(
                              _vm.organization.defaultIsTrueCoordinates,
                              false
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.organization,
                                "defaultIsTrueCoordinates",
                                false
                              )
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("orgForm-bufferedLocations")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m7" }, [
                      _c(
                        "label",
                        [
                          _vm.organization.defaultIsTrueCoordinates
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("orgForm-addDefaultBufferRadius")
                                  )
                                )
                              ]
                            : [
                                _vm._v(
                                  _vm._s(_vm.$t("orgForm-bufferRadiusUsed"))
                                )
                              ],
                          _vm._v(" "),
                          _vm.organization.defaultIsTrueCoordinates
                            ? _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.tooltips.wildtraxBuffer,
                                    expression: "tooltips.wildtraxBuffer"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              })
                            : _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.tooltips.userBuffer,
                                    expression: "tooltips.userBuffer"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.organization.defaultBufferRadius,
                            expression: "organization.defaultBufferRadius"
                          }
                        ],
                        attrs: { id: "org-buffer-radius", type: "number" },
                        domProps: {
                          value: _vm.organization.defaultBufferRadius
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.organization,
                              "defaultBufferRadius",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l5" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("orgForm-defaultVisitImgAccess")) + " "
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.visitImageAccess,
                              expression: "tooltips.visitImageAccess"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.organization.defaultVisitImageAccess,
                                expression:
                                  "organization.defaultVisitImageAccess"
                              }
                            ],
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.organization,
                                  "defaultVisitImageAccess",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.options.visitImageAccess, function(
                            option,
                            index
                          ) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: option.id } },
                              [_vm._v(_vm._s(option.type))]
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m5" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("orgForm-allowLocationReports")) + " "
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.locationReports,
                              expression: "tooltips.locationReports"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-option" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.organization.allowLocationReports,
                              expression: "organization.allowLocationReports"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "enableLocationReports",
                            name: "enableLocationReports",
                            disabled: !_vm.isEditable
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(
                              _vm.organization.allowLocationReports,
                              true
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.organization,
                                "allowLocationReports",
                                true
                              )
                            }
                          }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("orgForm-allow")))])
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "form-option" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.organization.allowLocationReports,
                              expression: "organization.allowLocationReports"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "disableLocationReports",
                            name: "disableLocationReports",
                            disabled: !_vm.isEditable
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(
                              _vm.organization.allowLocationReports,
                              false
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.organization,
                                "allowLocationReports",
                                false
                              )
                            }
                          }
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("orgForm-dontAllow")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("Blurring")) + " "),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.facialBlurring,
                              expression: "tooltips.facialBlurring"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.organization.humanBlurringId,
                              expression: "organization.humanBlurringId"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.organization,
                                "humanBlurringId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.options.blurring, function(option, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: option.id } },
                            [_vm._v(_vm._s(option.type))]
                          )
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c(
                        "label",
                        { attrs: { for: "form_organization_description" } },
                        [_vm._v(_vm._s(_vm.$t("common-description")))]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.organization.description,
                            expression: "organization.description"
                          }
                        ],
                        staticClass: "materialize-textarea",
                        attrs: {
                          id: "form_organization_description",
                          "data-length": "120"
                        },
                        domProps: { value: _vm.organization.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.organization,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "character-counter",
                        staticStyle: {
                          float: "right",
                          "font-size": "12px",
                          height: "1px"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("div", { key: index, staticClass: "error" }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [
                      _vm.isEditable
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                on: {
                                  click: function($event) {
                                    return _vm.saveOrganization($event, null)
                                  }
                                }
                              },
                              [
                                !_vm.organizationId && !_vm.isGlobalAdmin
                                  ? [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("orgForm-submitForApproval")
                                        )
                                      )
                                    ]
                                  : [_vm._v(_vm._s(_vm.$t("common-save")))]
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.organization.id > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    on: { click: _vm.deleteOrganization }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("common-delete")) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.organization.id > 0 &&
                            _vm.organization.isPendingStatus &&
                            _vm.isGlobalAdmin
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    on: {
                                      click: function($event) {
                                        return _vm.saveOrganization(
                                          $event,
                                          false
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("orgForm-approve")) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-border modal-action modal-close",
                          attrs: { id: "formcancelAdd" },
                          on: { click: _vm.close }
                        },
                        [_vm._v(_vm._s(_vm.$t("common-close")))]
                      )
                    ],
                    2
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.organization.id != null &&
                        _vm.showTab == "user" &&
                        _vm.isEditable,
                      expression:
                        "organization.id != null && showTab =='user' && isEditable"
                    }
                  ],
                  staticClass: "modal-content padded20"
                },
                [
                  _c("organization-user-assignment-table", {
                    attrs: {
                      users: _vm.formatUsersForTable(_vm.options.users),
                      parentId: _vm.organizationId,
                      parentIdLabel: "organizationId",
                      roles: _vm.options.roles
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }