<template>
  <div class="container overflow vue" id="task-page" >
    <div class="container error padded60" v-if="error != null">
        <span v-if="error.statusText != null">
          {{error.statusText}}
        </span>
        <span v-else>
          {{error}}
        </span>
      </div>
    <div class="row" v-if="error==null">
      <div class="col s12 m12" :class="{l8: showStats, xl9: showStats, l12: !showStats}" id="listeningPanel">
        <!-- top panel -->
        <div class="padded60 projectHeader !px-8 !pb-4" :class="{narrowPanel: showStats}">
          <div v-if="project.isUserProjectMember" class="toggleWrap">
           
          </div>
          <p><a :href="breadcrumbHomeLink()">{{$t('common-home')}}</a> / <a :href="breadcrumbBaseLink() + 'ARU'">{{$t('common-aru')}}</a> /</p>
          <div class="row">
              <div class="col s12 m12 l12 xl8">
                  <a  class="link" @click="updateProject()" v-if="project.isAdmin" :title="$t('tasks-edit')">
                    <h1 class="inline pb-0">{{project.fullNm}}</h1>
                  <span  class="ion-edit edit-project-icon"></span></a>
                  <h1 v-else>{{project.fullNm}}</h1>
              </div>
              <div class="col s12 m12 l12 xl4" v-if="getOtherProjectUrl!=null">
                  <auto-complete
                  :placeholder="$t('tasks-jump')"
                  :source= "getOtherProjectUrl"
                  initialValue=" "
                  initialDisplay=""
                  clearButtonIcon="ion-close"
                  name="id"
                  results-display="fullNm"
                  :request-headers="authHeaders"
                  @selected="redirectPage"
                >
                </auto-complete>
              </div>
          </div>
          <p>
            <span v-if="project.isAdmin"><span class="font-bold">{{$t('tasks-projectStatus', {status: getProjectStatus()})}}</span>
              <template v-if="isPublished">
                <span class="ion-android-lock red-font"></span>
                <span class="info-icon ion-information-circled" v-tooltip="publishedTooltip" />
              </template>
          </span>
          </p>
          <p v-if="project.description && project.description.length>=descLimit" class="whitespace-pre-wrap">
            {{project.description.substring(0,descLength)}}<span v-show="descLength==descLimit">...</span>
            <a @click="showMore" class="text-xs cursor-pointer">{{$t('common-showMoreLess', {amount: (descLength==descLimit?$t('common-more'):$t('common-less'))})}}</a>
          </p>
          <p v-else-if="project.description && project.description.length<descLimit" class="whitespace-pre-wrap">{{project.description}}</p>
          <p v-else class="text-red-400">{{$t('You do not currently have any information that describes this project. Please add a description of methods, results or study design in order to inform users about your project.')}}</p>
          
        </div>
        
        <aru-batch-upload-form
          v-bind:project-id="projectId"  
          v-bind:project-name="project.fullNm" 
          v-bind:organization-id="project.organizationId"
          v-if="projectId && options != null && allMethodList != null "
          v-bind:options="options" 
          v-bind:method-option="allMethodList"
          />
        <!-- tab area -->
        <section class="tabbed">
          <ul class="tabs">
            <li class="tab" @click="clickTab('task')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'task', disabledTab: !project.hasTaskAccess}"><span class="ion-ios-mic"></span> <template v-if="project.isUserProjectMember">{{$tc('common-task', 2)}}</template><template v-else>{{$tc('common-task', 2)}}</template></a></li>
            <li class="tab" @click="clickTab('species')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'species', disabledTab: !project.hasTaskAccess}"><span class="ion-ios-paw"></span> <template v-if="project.isUserProjectMember">{{$tc('common-species', 2)}}</template><template v-else>{{$tc('common-species', 2)}}</template></a></li>
            <li class="tab" @click="clickTab('map')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'map', disabledTab: !project.hasMapAccess}"><span class="ion-map"></span> {{$t('common-map')}}</a></li>
            <li class="tab" @click="clickTab('files')"><a v-bind:class="{ '!border-gray-400 border-2 border-b-0': activeTab == 'files', disabledTab: project.hasFileAccess }"><i class="fa fa-file"></i> {{$t('Files')}}</a></li>

          </ul>
          <div class="padded60 boxed dataGroup border-2 border-gray-400">
            <task-table 
              ref="task" 
              v-if="project.isAdmin != null && options != null && userList != null" 
              v-show="activeTab=='task'" 
              v-bind:project-id="projectId" 
              v-bind:sensor-id="project.sensorId"
              v-bind:is-manager="project.isAdmin" 
              :project="project" 
              v-bind:is-user-project-member="project.isUserProjectMember" 
              v-bind:filter-options="options"  
              v-bind:user-list="userList" 
              v-bind:is-published="isPublished"
              v-bind:has-project-write-access="project.hasProjectWriteAccess" 
              v-bind:project-name="project.fullNm" 
              v-bind:task-options="taskOptions"
              :has-assigned-tasks="project.isAssignedTask"
               />
            <species-table 
              ref="species-table" 
              v-if="project.isAdmin != null && taskOptions != null && userList != null" 
              v-show="activeTab=='species'"
              v-bind:project-id="projectId"
              v-bind:is-manager="project.isAdmin" 
              v-bind:task-options="taskOptions" 
              v-bind:user-list="userList"
              v-bind:statusId="project.status" 
              />
            <location-map v-bind:map-type="'project'" v-if="activeTab=='map' && projectId" v-bind:category-id="projectId"/>         <!-- map has it own borader -->
            <files v-show="activeTab=='files'" :projectId="projectId" :project="project" :user-list="userList"></files>
          </div>
        </section>
      </div>
      <div class="col s12 m12 l4 xl3" v-if="showStats">
        <label>{{$t('tasks-stats')}}</label>
        <stats :url="statUrl" customClass="row" customDivClass="col s6 m4 l4" :is-wrapped="true"/>
      </div>
    </div>
    <modal name="project"
        :scrollable="true" :adaptive="true"
        transition="nice-modal-fade"
        classes="modal-form scroll"
        draggable="false"
        :delay="100"
        width = "99%"
        height = "808"
        :minWidth="400"
        :minHeight="540"
        :clickToClose = "false"
      >
      <project-form v-if="projectId != null" v-bind:project-id="projectId" v-bind:active-sensor="project.sensorId" v-bind:options="projectFormOptions"
        />
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/

import TaskTable from './ARUTaskTable';
import TaskSpecies from './ARUTaskSpecies';
import TaskPageMixin from './TaskPageMixin';
import Autocomplete from 'vuejs-auto-complete';
import StatsBox from '@/components/common/StatsBox';
import ProjectAgreementTab from './ProjectAgreementTab';
import {API_URL, getAuthorizaitonHeader, isLocal} from '@/lib/common';
import ProjectForm from '@/components/project/ProjectForm';
import AudioUploadForm from './audioUpload/AudioUploadForm';
import CommonPageMixin from '@/components/common/CommonPageMixin';
import { eventBus } from '../../lib/eventbus';
import FilesTab from './FilesTab.vue';

export default {
  name: 'aru-task-page',
  mixins: [TaskPageMixin, CommonPageMixin],
  components: {
    'aru-batch-upload-form': AudioUploadForm,
    'task-table': TaskTable,
    'species-table': TaskSpecies,
    'auto-complete': Autocomplete,
    'stats': StatsBox,
    'agreement': ProjectAgreementTab,
    'project-form': ProjectForm,
    'files': FilesTab

  },
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  methods: {
    /*
    Handle clicking on a tab
    only allows switching if user has permission
    */
    clickTab (tabName) {
      switch (tabName) {
        case 'task':
          if (this.project.hasTaskAccess) {
            this.activeTab = 'task';
          }
          break;
        case 'agreement':
          if (this.project.isAdmin || this.project.hasTaskAccess) {
            this.activeTab = 'agreement';
          }
          break;
        case 'map':
          if (this.project.hasMapAccess) {
            this.activeTab = 'map';
          }
          break;
        case 'species':
          if (this.project.isAdmin || this.project.hasTaskAccess) {
            this.activeTab = 'species';
          }
          break;
        default: this.activeTab = tabName;
      };
    },
    
    loadTaskOptions () {
      this.$http.get(this.getTaskOptionsURL).then((response) => {
        this.taskOptions = response.data;
      }, (err) => {
        // error callback
        this.warning = this.$t('tasks-filterWarning') + err;
      });
    },
    loadAllMethodOptions () {
      this.$http.get(API_URL + 'get-recording-options').then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.allMethodList = null;
        } else {
          this.allMethodList = response.data.method;
        }
      }, (err) => {
        // error callback
        this.warning = this.$t('tasks-methodWarning') + err;
      });
    },
    showMore () {
      if (this.descLength === this.descLimit) {
        this.descLength = 10000;
      } else {
        this.descLength = this.descLimit;
      }
    }

  },
  created () {
    this.$store.dispatch('Species/getAllSpecies');
    this.loadAllMethodOptions();
    this.loadTaskOptions();

    eventBus.$on('project-user-assignment-list-changed', () => {
      this.reloadProject();
    });

  },
  mounted() {
    
  },
  watch: {
    // projectId (newVal, oldVal) {
    //   if (newVal !== oldVal && newVal != null && oldVal != null) {
    //     this.loadTaskOptions();
    //   }
    // }
  },
  data () {
    return {
      getAllSpecies: API_URL + 'get-aru-species-all',
      getTaskOptionsURL: API_URL + 'aru-task-options',
      expectedSensorType: 'ARU',
      uploadFormName: 'upload-audio-form',
      allMethodList: [],
      taskOptions: null,
      descLimit: 50,
      descLength: 50,
      species: this.$store.state.Species.species
      
    }
  }
}
</script>
<style>
#task-page .autocomplete .autocomplete__box {
  padding: 0;
}
</style>
<style scoped>

#task-page .autocomplete__icon {
    height: 0px;
    width: 0px;
}
#task-page .autocomplete__box {
  border: 1px solid #000;
  padding: 0px !important;
}
.edit-project-icon {
  display: inline-block;
  vertical-align: top;
  padding: 1rem;
}

.disabledTab{
  pointer-events: none;
  cursor: default !important;
  color: #7D7D7D !important;

}
</style>
