var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col s12 panel padded20 boxed active",
      attrs: { id: "everythingTab" }
    },
    [
      _vm.latitude != null
        ? _c(
            "div",
            {
              staticStyle: { position: "relative", overflow: "hidden" },
              attrs: { id: "overview-map" }
            },
            [
              _c("mapbox", {
                ref: "mapBox",
                attrs: {
                  "access-token": _vm.accessToken,
                  "map-options": {
                    center: [this.longitude, this.latitude],
                    zoom: 4,
                    style: "mapbox://styles/mapbox/streets-v11"
                  },
                  "scale-control": {
                    show: true,
                    position: "top-left"
                  },
                  "fullscreen-control": {
                    show: true,
                    position: "top-left"
                  }
                },
                on: {
                  "map-load": _vm.addMarker,
                  "geolocate-error": _vm.geolocateError,
                  "geolocate-geolocate": _vm.geolocate
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }