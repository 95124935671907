var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "locationVisitEquipmentEditFormModal" } }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "modal-content padded40 main" },
        [
          _c(
            "div",
            { staticClass: "iconButton link", on: { click: _vm.close } },
            [_c("span", { staticClass: "ion-close" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", [_c("h5", [_vm._v(_vm._s(_vm.locationLabel))])]),
              _vm._v(" "),
              _vm.toShowNewEquipmentForm
                ? _c("div", { staticClass: "newEquipmentForm padded20" }, [
                    _c("h5", [
                      _vm._v(
                        _vm._s(_vm.$t("visitEquipForm-fields.quickRegister"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$tc("common-code", 1)))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.insertEquipmentRow.code,
                              expression: "insertEquipmentRow.code"
                            }
                          ],
                          attrs: { disabled: !_vm.isEditable },
                          domProps: { value: _vm.insertEquipmentRow.code },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.insertEquipmentRow,
                                "code",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("common-make")))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.insertEquipmentRow.make,
                              expression: "insertEquipmentRow.make"
                            }
                          ],
                          attrs: { disabled: !_vm.isEditable },
                          domProps: { value: _vm.insertEquipmentRow.make },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.insertEquipmentRow,
                                "make",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("common-model")))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.insertEquipmentRow.model,
                              expression: "insertEquipmentRow.model"
                            }
                          ],
                          attrs: { disabled: !_vm.isEditable },
                          domProps: { value: _vm.insertEquipmentRow.model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.insertEquipmentRow,
                                "model",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("common-serialNumber")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.insertEquipmentRow.serialNumber,
                              expression: "insertEquipmentRow.serialNumber"
                            }
                          ],
                          attrs: { disabled: !_vm.isEditable },
                          domProps: {
                            value: _vm.insertEquipmentRow.serialNumber
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.insertEquipmentRow,
                                "serialNumber",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("common-purchaseDate")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vue-select-wrapper" },
                        [
                          _c("date-picker", {
                            attrs: {
                              disabled: !_vm.isEditable,
                              type: "date",
                              lang: _vm.locales[_vm.$root.$i18n.locale],
                              valueType: "format",
                              format: "YYYY-MM-DD",
                              "value-format": "YYYY-MM-DD"
                            },
                            model: {
                              value: _vm.insertEquipmentRow.purchaseDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.insertEquipmentRow,
                                  "purchaseDate",
                                  $$v
                                )
                              },
                              expression: "insertEquipmentRow.purchaseDate"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col s12" },
                      [
                        _vm._l(_vm.errors, function(error, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "error" },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        }),
                        _vm._v(" "),
                        _vm.isEditable
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-success btn-border modal-action modal-close",
                                on: { click: _vm.saveEquipmentRow }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("visitEquipForm-fields.insertNew")
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-success btn-border modal-action modal-close",
                            on: {
                              click: function($event) {
                                _vm.toShowNewEquipmentForm = false
                                _vm.insertEquipmentRow = {}
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("common-close")))]
                        )
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col s12" }, [
                !_vm.isFullForm &&
                _vm.equipmentRow.currentEquipment &&
                _vm.equipmentRow.currentEquipment.equipmentType
                  ? _c("h6", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("visitEquipForm-fields.originalEquip")
                          ) +
                          ": " +
                          _vm._s(
                            _vm.equipmentRow.currentEquipment.equipmentType.type
                          ) +
                          " - " +
                          _vm._s(_vm.$t("common-make")) +
                          ": " +
                          _vm._s(_vm.equipmentRow.currentEquipment.make) +
                          " -\n            " +
                          _vm._s(_vm.$t("common-model")) +
                          ": " +
                          _vm._s(_vm.equipmentRow.currentEquipment.model) +
                          " " +
                          _vm._s(_vm.capitalize(_vm.$tc("common-code", 1))) +
                          ": " +
                          _vm._s(_vm.equipmentRow.currentEquipment.code) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.equipmentObj || !_vm.equipmentObj.equipmentType
                  ? _c("h6", [
                      _vm.isFullForm
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "visitEquipForm-fields.selectEquipType"
                                  )
                                ) +
                                "\n            "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("visitEquipForm-fields.selectCode")
                                ) +
                                "\n            "
                            )
                          ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.isFullForm
                ? _c("div", { staticClass: "col s4" }, [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("visitEquipForm-fields.equipType")))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "vue-select-wrapper" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.equipmentTypeId,
                              expression: "equipmentTypeId"
                            }
                          ],
                          attrs: { disabled: !_vm.isEditable },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.equipmentTypeId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.changeDeviceType()
                              }
                            ]
                          }
                        },
                        [
                          _vm._l(_vm.options.EquipmentType, function(
                            option,
                            index
                          ) {
                            return [
                              _vm.isMainDevice(
                                _vm.equipmentRow
                                  .parentLocationDeploymentEquipmentId,
                                option.id
                              )
                                ? _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: option.id }
                                    },
                                    [_vm._v(_vm._s(option.type) + " ")]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "col s4" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("visitEquipForm-fields.equipCode")))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vue-select-wrapper" },
                  [
                    _c("auto-complete", {
                      ref: "autocomplete",
                      attrs: {
                        placeholder: _vm.$t(
                          "visitEquipForm-fields.codePlaceholder"
                        ),
                        source: _vm.getEquipmentCodeUrl,
                        initialValue: "",
                        initialDisplay: _vm.equipmentObj.code,
                        clearButtonIcon: "ion-close",
                        name: "id",
                        "results-display": "code",
                        "request-headers": _vm.authHeaders,
                        disableInput: !_vm.equipmentTypeId || !_vm.isEditable
                      },
                      on: {
                        selected: _vm.displayEquipmentDetails,
                        noResults: _vm.saveInvalidAutoFill,
                        results: _vm.hasResults
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s4" }, [
                _c("div", { staticStyle: { "margin-top": "30px" } }, [
                  _vm.equipmentTypeId && _vm.isEditable
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-success btn-border modal-action modal-close",
                          on: {
                            click: function($event) {
                              _vm.toShowNewEquipmentForm = true
                              _vm.insertEquipmentRow = {
                                code: _vm.invalidEntry
                              }
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("visitEquipForm-fields.registerNew"))
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.isFullForm
                ? [
                    false
                      ? _c("div", { staticClass: "col s12 m4" }, [
                          _vm.isAttacheableDevice(_vm.equipmentTypeId)
                            ? _c("div", [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("visitEquipForm-fields.attachedTo")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "vue-select-wrapper" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.equipmentRow
                                                .parentLocationDeploymentEquipmentId,
                                            expression:
                                              "equipmentRow.parentLocationDeploymentEquipmentId"
                                          }
                                        ],
                                        attrs: {
                                          disabled: !_vm.isEditable,
                                          required: true
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.equipmentRow,
                                              "parentLocationDeploymentEquipmentId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v(" -- ")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(_vm.equipmentOption, function(
                                          option,
                                          index
                                        ) {
                                          return [
                                            _vm.isMainDevice(
                                              option.id,
                                              _vm.equipmentTypeId
                                            )
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    domProps: {
                                                      value: option.id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(option.text) + " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.$t("visitEquipForm-fields.deployment"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-5 gap-3 col s12" },
                      [
                        _c("div", {}, [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("Deployment Visit Start Date")) +
                                "\n              "
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.visit,
                                  expression: "tooltips.visit"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "vue-select-wrapper" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.equipmentRow.deployVisitId,
                                    expression: "equipmentRow.deployVisitId"
                                  }
                                ],
                                attrs: {
                                  disabled: !_vm.isEditable,
                                  required: true
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.equipmentRow,
                                      "deployVisitId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(" -- ")
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.options.deployVisitId, function(
                                  option,
                                  index
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: option.id }
                                    },
                                    [_vm._v(_vm._s(option.text))]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", {}, [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("Deployment Visit Start Time"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "vue-select-wrapper" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  disabled: !_vm.isEditable,
                                  type: "time",
                                  lang: _vm.locales[_vm.$root.$i18n.locale],
                                  format: "HH:mm",
                                  "value-type": "HH:mm:ss"
                                },
                                model: {
                                  value: _vm.equipmentRow.exactDeployTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.equipmentRow,
                                      "exactDeployTime",
                                      $$v
                                    )
                                  },
                                  expression: "equipmentRow.exactDeployTime"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _vm.isSensorDevice
                          ? _c("div", {}, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("Attachment")) +
                                    "\n                  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.deploymentType,
                                      expression: "tooltips.deploymentType"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "vue-select-wrapper" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.equipmentRow.typeId,
                                        expression: "equipmentRow.typeId"
                                      }
                                    ],
                                    attrs: {
                                      disabled: !_vm.isEditable,
                                      required: true
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.equipmentRow,
                                          "typeId",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(" -- ")
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.options
                                        .locationDeploymentEquipmentType,
                                      function(option, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: option.id }
                                          },
                                          [_vm._v(_vm._s(option.type))]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCamera && _vm.isSensorDevice
                          ? _c("div", {}, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("Target Feature")) +
                                    "\n                  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.target,
                                      expression: "tooltips.target"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "vue-select-wrapper" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.equipmentRow.targetId,
                                        expression: "equipmentRow.targetId"
                                      }
                                    ],
                                    attrs: {
                                      disabled: !_vm.isEditable,
                                      required: true
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.equipmentRow,
                                          "targetId",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(" -- ")
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.options
                                        .locationDeploymentEquipmentTarget,
                                      function(option, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            domProps: { value: option.id }
                                          },
                                          [_vm._v(_vm._s(option.type))]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCamera && _vm.isSensorDevice
                          ? _c("div", {}, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "visitEquipForm-fields.stakeDistance"
                                    )
                                  ) + "\n                  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.stakeDistance,
                                      expression: "tooltips.stakeDistance"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "vue-select-wrapper" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value:
                                        _vm.equipmentRow.stakeDistanceMeters,
                                      expression:
                                        "equipmentRow.stakeDistanceMeters",
                                      modifiers: { number: true }
                                    }
                                  ],
                                  staticClass: "validate",
                                  attrs: {
                                    disabled: !_vm.isEditable,
                                    step: "0.1",
                                    min: "0",
                                    max: "10",
                                    type: "number"
                                  },
                                  domProps: {
                                    value: _vm.equipmentRow.stakeDistanceMeters
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.equipmentRow,
                                        "stakeDistanceMeters",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function($event) {
                                      return _vm.$forceUpdate()
                                    }
                                  }
                                })
                              ])
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: { "grid gap-3 grid-cols-5 col s12": true } },
                      [
                        _vm.isSensorDevice
                          ? _c("div", {}, [
                              _c("label", { attrs: { for: "form_depth" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("Walktest Height (m)")) +
                                    "\n                  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.height,
                                      expression: "tooltips.height"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.equipmentRow.heightMeters,
                                    expression: "equipmentRow.heightMeters",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "validate",
                                attrs: {
                                  disabled: !_vm.isEditable,
                                  id: "form_depth",
                                  step: "0.1",
                                  min: "0",
                                  max: "999.999",
                                  type: "number",
                                  required: "",
                                  placeholder: _vm.$t(
                                    "visitEquipForm-fields.heightPlaceholder"
                                  )
                                },
                                domProps: {
                                  value: _vm.equipmentRow.heightMeters
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.equipmentRow,
                                      "heightMeters",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isSensorDevice
                          ? _c("div", {}, [
                              _c("label", { attrs: { for: "direction" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("Equipment direction (degrees)")
                                  ) + "\n                  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.direction,
                                      expression: "tooltips.direction"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.equipmentRow.directionDegrees,
                                    expression: "equipmentRow.directionDegrees",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "validate",
                                attrs: {
                                  disabled: !_vm.isEditable,
                                  id: "direction",
                                  step: "1",
                                  min: "0",
                                  max: "359",
                                  type: "number",
                                  required: ""
                                },
                                domProps: {
                                  value: _vm.equipmentRow.directionDegrees
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.equipmentRow,
                                      "directionDegrees",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {}, [
                          _c("label", { attrs: { for: "workingTestDone" } }, [
                            _c(
                              "span",
                              { class: { inactive: !_vm.isEditable } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("visitEquipForm-fields.sensorTest")
                                  ) + "\n                  "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.sensorTest,
                                      expression: "tooltips.sensorTest"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm.equipmentRow.workingTestDone
                              ? _c("i", {
                                  staticClass:
                                    "fas fa-toggle-on !block text-4xl text-green-400",
                                  on: {
                                    click: () => {
                                      _vm.$set(
                                        _vm.equipmentRow,
                                        "workingTestDone",
                                        false
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.equipmentRow.workingTestDone
                              ? _c("i", {
                                  staticClass:
                                    "fas fa-toggle-off !block text-4xl text-red-400",
                                  on: {
                                    click: () => {
                                      _vm.$set(
                                        _vm.equipmentRow,
                                        "workingTestDone",
                                        true
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("Walktest Height(m)")) + " "),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.walkTestHeight,
                                  expression: "tooltips.walkTestHeight"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.equipmentRow.walkTestHeight,
                                  expression: "equipmentRow.walkTestHeight"
                                }
                              ],
                              attrs: { type: "number", min: "0" },
                              domProps: {
                                value: _vm.equipmentRow.walkTestHeight
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.equipmentRow,
                                    "walkTestHeight",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("WalkTest Distance(m)")) + " "
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.walkTestDistance,
                                  expression: "tooltips.walkTestDistance"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.equipmentRow.walkTestDistance,
                                  expression: "equipmentRow.walkTestDistance"
                                }
                              ],
                              attrs: { type: "number", min: "0" },
                              domProps: {
                                value: _vm.equipmentRow.walkTestDistance
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.equipmentRow,
                                    "walkTestDistance",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("label", [
                            _vm._v(
                              _vm._s(_vm.$t("Facing Human Feature")) + " "
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.facingHumanFeature,
                                  expression: "tooltips.facingHumanFeature"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm.equipmentRow.containsHumanFeature
                              ? _c("i", {
                                  staticClass:
                                    "fas fa-toggle-on !block text-4xl text-green-400",
                                  on: {
                                    click: () => {
                                      _vm.$set(
                                        _vm.equipmentRow,
                                        "containsHumanFeature",
                                        false
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.equipmentRow.containsHumanFeature
                              ? _c("i", {
                                  staticClass:
                                    "fas fa-toggle-off !block text-4xl text-red-400",
                                  on: {
                                    click: () => {
                                      _vm.$set(
                                        _vm.equipmentRow,
                                        "containsHumanFeature",
                                        true
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid grid-cols-2 gap-1" }, [
                          _c("div", { staticClass: "col-span-2" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("Trigger Mode")) + " "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.$t("Something about triggers"),
                                    expression: "$t('Something about triggers')"
                                  }
                                ],
                                staticClass: "info-icon ion-information-circled"
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "flex items-center gap-2" },
                              [
                                _vm.equipmentRow.timeLapseEnabled
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-on !block text-4xl text-green-400 flex-initial",
                                      on: {
                                        click: () => {
                                          _vm.$set(
                                            _vm.equipmentRow,
                                            "timeLapseEnabled",
                                            false
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.equipmentRow.timeLapseEnabled
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-off !block text-4xl text-red-400 flex-initial",
                                      on: {
                                        click: () => {
                                          _vm.$set(
                                            _vm.equipmentRow,
                                            "timeLapseEnabled",
                                            true
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v(_vm._s(_vm.$t("Time Lapse")) + " ")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "flex items-center gap-2" },
                              [
                                _vm.equipmentRow.triggersEnabled
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-on !block text-4xl text-green-400 flex-initial",
                                      on: {
                                        click: () => {
                                          _vm.$set(
                                            _vm.equipmentRow,
                                            "triggersEnabled",
                                            false
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.equipmentRow.triggersEnabled
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-toggle-off !block text-4xl text-red-400 flex-initial",
                                      on: {
                                        click: () => {
                                          _vm.$set(
                                            _vm.equipmentRow,
                                            "triggersEnabled",
                                            true
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v(_vm._s(_vm.$t("Trigger")) + " ")
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", {}, [
                          _c("label", { attrs: { for: "direction" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("Seconds Between Pictures")) +
                                "\n                "
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.secondsBetweenPictures,
                                  expression: "tooltips.secondsBetweenPictures"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            })
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value:
                                  _vm.equipmentRow
                                    .triggerSecondsBetweenPictures,
                                expression:
                                  "equipmentRow.triggerSecondsBetweenPictures",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "validate",
                            attrs: {
                              disabled: !_vm.isEditable,
                              id: "direction",
                              step: "1",
                              min: "0",
                              type: "number"
                            },
                            domProps: {
                              value:
                                _vm.equipmentRow.triggerSecondsBetweenPictures
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.equipmentRow,
                                  "triggerSecondsBetweenPictures",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _vm.equipmentRow.triggersEnabled
                          ? _c("div", [
                              _c("label", { attrs: { for: "direction" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("Quiet Period (Seconds)")) +
                                    "\n                "
                                ),
                                _c("span", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.tooltips.quietPeriod,
                                      expression: "tooltips.quietPeriod"
                                    }
                                  ],
                                  staticClass:
                                    "info-icon ion-information-circled"
                                })
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.equipmentRow.quietPeriodSeconds,
                                    expression:
                                      "equipmentRow.quietPeriodSeconds",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "validate",
                                attrs: {
                                  disabled: !_vm.isEditable,
                                  id: "direction",
                                  step: "1",
                                  min: "0",
                                  type: "number"
                                },
                                domProps: {
                                  value: _vm.equipmentRow.quietPeriodSeconds
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.equipmentRow,
                                      "quietPeriodSeconds",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              })
                            ])
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-3 grid grid-cols-2 gap-3" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12" }, [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.$t("visitEquipForm-fields.retrieval"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m4" }, [
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("Retrieval Visit Date")) +
                              "\n                "
                          ),
                          _c("span", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.tooltips.visit,
                                expression: "tooltips.visit"
                              }
                            ],
                            staticClass: "info-icon ion-information-circled"
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.equipmentRow.retrieveVisitId,
                                expression: "equipmentRow.retrieveVisitId"
                              }
                            ],
                            attrs: {
                              disabled: !_vm.isEditable,
                              required: true
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.equipmentRow,
                                  "retrieveVisitId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(" -- ")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.getRetrievalOptions, function(
                              option,
                              index
                            ) {
                              return [
                                _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: option.id }
                                  },
                                  [_vm._v(_vm._s(option.text))]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l4" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("Retrieval Visit Time")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vue-select-wrapper" },
                        [
                          _c("date-picker", {
                            attrs: {
                              disabled: !_vm.isEditable,
                              type: "time",
                              lang: _vm.locales[_vm.$root.$i18n.locale],
                              format: "HH:mm",
                              "value-type": "HH:mm:ss"
                            },
                            model: {
                              value: _vm.equipmentRow.exactRetrieveTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.equipmentRow,
                                  "exactRetrieveTime",
                                  $$v
                                )
                              },
                              expression: "equipmentRow.exactRetrieveTime"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s12 m6 l4" }, [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("visitEquipForm-fields.equipCondition")
                          ) + "\n              "
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.tooltips.condition,
                              expression: "tooltips.condition"
                            }
                          ],
                          staticClass: "info-icon ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "vue-select-wrapper" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.equipmentRow.equipmentConditionId,
                                expression: "equipmentRow.equipmentConditionId"
                              }
                            ],
                            attrs: {
                              disabled: !_vm.isEditable,
                              required: true
                            },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.equipmentRow,
                                  "equipmentConditionId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(" -- ")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.options.equipmentCondition, function(
                              option,
                              index
                            ) {
                              return _c(
                                "option",
                                { key: index, domProps: { value: option.id } },
                                [_vm._v(_vm._s(option.type))]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    ])
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm._l(_vm.errors, function(error, index) {
            return _c("div", { key: index, staticClass: "error" }, [
              _vm._v(" " + _vm._s(error) + " ")
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row sticky" }, [
            _c("div", { staticClass: "col s12 modal-footer" }, [
              _vm.isFullForm && _vm.isEditable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success btn-border modal-action modal-close",
                      on: { click: _vm.updateRow }
                    },
                    [_vm._v(_vm._s(_vm.$t("common-save")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isFullForm && _vm.isEditable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success btn-border modal-action modal-close",
                      on: { click: _vm.pickDevice }
                    },
                    [_vm._v(_vm._s(_vm.$t("visitEquipForm-fields.swap")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-border modal-action modal-close",
                  attrs: { id: "formcancelAdd" },
                  on: { click: _vm.close }
                },
                [_vm._v(_vm._s(_vm.$t("common-close")))]
              ),
              _vm._v(" "),
              !_vm.equipmentRow.parentLocationDeploymentEquipmentId &&
              _vm.isEditable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-border btn-success modal-action modal-close",
                      class: { disabled: !_vm.equipmentRow.id },
                      on: { click: _vm.addEquipment }
                    },
                    [_vm._v(_vm._s(_vm.$t("visitEquipForm-fields.addEquip")))]
                  )
                : _vm._e()
            ])
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }