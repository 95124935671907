var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mapbox", {
    ref: "discoverMap",
    attrs: {
      "access-token": _vm.accessToken,
      "map-options": {
        center: [-110, 50.8],
        zoom: 13,
        style: "mapbox://styles/mapbox/light-v10"
      },
      "geolocate-control": {
        show: true
      },
      "scale-control": {
        show: true
      },
      "fullscreen-control": {
        show: true
      }
    },
    on: {
      "map-load": _vm.loadLayers,
      "map-data": _vm.dataEvent,
      "map-move": _vm.updateMarkers,
      "map-moveend": _vm.updateMarkers,
      "map-click:unclustered-point": _vm.clickPoint,
      "map-mouseleave:unclustered-point": _vm.mouseClickLeft,
      "map-click:clusters": _vm.clickCluster,
      "map-mouseenter:clusters": _vm.mouseEnteredCluster,
      "map-mouseleave:clusters": _vm.mouseLeft
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }