var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue" }, [
    _vm.error != null
      ? _c("div", [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("common-pageError", { error: _vm.error })) +
              "\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.error == null && _vm.survey.id > 0
      ? _c("div", [
          _c(
            "div",
            { staticClass: "container large taskHeader" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12 m12 l8 taskTitle" }, [
                  _c("p", [
                    _c("a", { attrs: { href: _vm.breadcrumbHomeLink() } }, [
                      _vm._v(_vm._s(_vm.$t("common-home")))
                    ]),
                    _vm._v(" / "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbBaseLink() + "PC" } },
                      [_vm._v(_vm._s(_vm.$t("common-pointCount")))]
                    ),
                    _vm._v(" / "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbSURVEYProjectLink() } },
                      [_vm._v(_vm._s(_vm.project.fullNm) + " ")]
                    ),
                    _vm._v(" /")
                  ]),
                  _vm._v(" "),
                  _c("h1", [_vm._v(_vm._s(_vm.location.name))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "currentStatus" }, [
                    _vm._v(
                      _vm._s(_vm.$t("survey-date")) +
                        ": " +
                        _vm._s(_vm.survey.surveyDate)
                    )
                  ]),
                  _vm._v(" "),
                  _vm.options != null
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            _vm._s(_vm.$t("survey-distanceMethod")) + ": "
                          ),
                          _vm._l(_vm.options.distanceMethods, function(
                            distanceMethod
                          ) {
                            return [
                              _vm.survey.distanceMethodId === distanceMethod.id
                                ? [_vm._v(_vm._s(distanceMethod.type))]
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.options != null
                    ? _c(
                        "div",
                        [
                          _vm._v(
                            _vm._s(_vm.$t("survey-durationMethod")) + ": "
                          ),
                          _vm._l(_vm.options.durationMethods, function(
                            durationMethod
                          ) {
                            return [
                              _vm.survey.durationMethodId === durationMethod.id
                                ? [_vm._v(_vm._s(durationMethod.type))]
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.survey.observerName
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.$t("survey-observer")) +
                            ": " +
                            _vm._s(_vm.survey.observerName)
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.survey && _vm.location
                ? _c("weather-segment", {
                    attrs: {
                      projectId: _vm.project.id,
                      "date-string": _vm.survey.surveyDate,
                      "location-id": _vm.location.id,
                      "site-latitude": _vm.location.latitude,
                      "site-longitude": _vm.location.longitude,
                      "show-date": false
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "container overflow tabbed" }, [
            _c("ul", { staticClass: "tabs" }, [
              _c("li", { staticClass: "tab left" }, [
                _c(
                  "a",
                  {
                    class: {
                      "!border-gray-400 border-2 border-b-0 active":
                        _vm.showTab === "main"
                    },
                    on: {
                      click: function($event) {
                        _vm.showTab = "main"
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "ion-ios-glasses-outline" }),
                    _vm._v(" " + _vm._s(_vm.$t("survey-observations")))
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.location.cameraExists || _vm.location.latitude
                ? _c("li", { staticClass: "tab left" }, [
                    _c(
                      "a",
                      {
                        class: {
                          "!border-gray-400 border-2 border-b-0 active":
                            _vm.showTab === "map"
                        },
                        on: {
                          click: function($event) {
                            _vm.showTab = "map"
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "ion-map" }),
                        _vm._v(" " + _vm._s(_vm.$t("common-map")))
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasLocationVisitImages
                ? _c("li", { staticClass: "tab left" }, [
                    _c(
                      "a",
                      {
                        class: {
                          "!border-gray-400 border-2 border-b-0 active":
                            _vm.showTab === "photos"
                        },
                        on: {
                          click: function($event) {
                            _vm.showTab = "photos"
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "ion-ios-camera" }),
                        _vm._v(_vm._s(_vm.$tc("common-locationPhotos", 1)))
                      ]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showTab === "main",
                      expression: "showTab === 'main'"
                    }
                  ],
                  staticClass:
                    "col s12 panel boxed padded60 !border-gray-400 border-2",
                  attrs: { id: "observations" }
                },
                [
                  _vm.options != null && _vm.settings
                    ? _c("survey-log-table", {
                        attrs: {
                          "survey-id": _vm.survey.id,
                          "is-admin-view": _vm.isUserAdmin,
                          options: _vm.options,
                          bands: _vm.settings.bands,
                          intervals: _vm.settings.intervals
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.showTab === "map"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col s12 panel boxed padded60 !border-gray-400 border-2",
                      attrs: { id: "mapsAndPhotos" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col s12 m12 l8" },
                          [
                            _vm.location &&
                            _vm.location.latitude &&
                            _vm.location.longitude
                              ? _c("point-map", {
                                  attrs: {
                                    latitude: parseFloat(_vm.location.latitude),
                                    longitude: parseFloat(
                                      _vm.location.longitude
                                    )
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _vm.showImage == true
                        ? _c(
                            "div",
                            {
                              ref: "modalBox",
                              staticClass: "modal modal-wrapper",
                              on: { click: _vm.hideZoomImage }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-container",
                                  on: { click: _vm.hideZoomImage }
                                },
                                [
                                  _c("img", {
                                    ref: "zoomedImg",
                                    attrs: { src: _vm.largeImage }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showTab === "photos"
                ? _c(
                    "div",
                    {
                      staticClass: "col s12 panel boxed padded60",
                      attrs: { id: "locationVisitPhotos" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col s12 m12 l8" },
                          [
                            _vm.location && _vm.project
                              ? _c("location-photos", {
                                  attrs: {
                                    "location-id": _vm.location.id,
                                    "project-id": _vm.project.id
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }