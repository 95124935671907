var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "visit-form", attrs: { id: "locationVisitEditFormModal" } },
    [
      _c("div", [
        _c("ul", { staticClass: "tabs" }, [
          _c(
            "li",
            {
              staticClass: "tab",
              on: {
                click: function($event) {
                  _vm.showTab = "visitInfo"
                }
              }
            },
            [
              _c("a", { class: { active: _vm.showTab == "visitInfo" } }, [
                _vm._v(_vm._s(_vm.$t("singleVisitForm-visitInfo")))
              ])
            ]
          ),
          _vm._v(" "),
          !_vm.hideVisitPhotos
            ? _c(
                "li",
                {
                  staticClass: "tab",
                  on: {
                    click: function($event) {
                      return _vm.clickedVisitPhotosTab()
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      class: {
                        active: _vm.showTab == "visitPhotos" && _vm.visitRow.id,
                        disabled: !_vm.visitRow.id
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common-visitPhotos")))]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "iconButton link", on: { click: _vm.close } },
            [_c("span", { staticClass: "ion-close" })]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showTab === "visitInfo",
                expression: "showTab ==='visitInfo'"
              }
            ],
            staticClass: "modal-content padded40 main"
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", [_c("h5", [_vm._v(_vm._s(_vm.locationLabel))])]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("singleVisitForm-fields.date")))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vue-select-wrapper" },
                  [
                    _c("date-picker", {
                      staticClass: "wide",
                      attrs: {
                        disabled: !_vm.isEditable,
                        lang: _vm.locales[_vm.$root.$i18n.locale],
                        type: "date",
                        valueType: "format",
                        format: "YYYY-MM-DD",
                        "value-format": "YYYY-MM-DD"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedDate()
                        }
                      },
                      model: {
                        value: _vm.visitRow.date,
                        callback: function($$v) {
                          _vm.$set(_vm.visitRow, "date", $$v)
                        },
                        expression: "visitRow.date"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("singleVisitForm-fields.crew")) +
                      "\n                "
                  ),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.isEditable
                          ? _vm.tooltips.crew
                          : _vm.tooltips.roCrew,
                        expression:
                          "isEditable? tooltips.crew : tooltips.roCrew"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vue-select-wrapper" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.visitRow.crewName,
                        expression: "visitRow.crewName",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: {
                      disabled: !_vm.isEditable,
                      required: "",
                      placeholder: _vm.$t(
                        "singleVisitForm-fields.crewPlaceholder"
                      )
                    },
                    domProps: { value: _vm.visitRow.crewName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.visitRow,
                          "crewName",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("singleVisitForm-fields.accessMethod"))),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.isEditable
                          ? _vm.tooltips.accessMethod
                          : _vm.tooltips.roAccessMethod,
                        expression:
                          "isEditable? tooltips.accessMethod : tooltips.roAccessMethod"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vue-select-wrapper" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.visitRow.accessMethodId,
                          expression: "visitRow.accessMethodId"
                        }
                      ],
                      attrs: { disabled: !_vm.isEditable },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.visitRow,
                            "accessMethodId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.options.locationAccessMethod, function(
                      option,
                      index
                    ) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: option.id } },
                        [_vm._v(_vm._s(option.type))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("singleVisitForm-fields.landscapeFeature")) +
                      "\n                "
                  ),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.isEditable
                          ? _vm.tooltips.landFeature
                          : _vm.tooltips.roLandFeature,
                        expression:
                          " isEditable? tooltips.landFeature : tooltips.roLandFeature"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "vue-select-wrapper" },
                  [
                    _c("multiselect", {
                      staticClass: "landfeature",
                      attrs: {
                        selectAllLabel: _vm.$t("common-selectAll"),
                        disabled: !_vm.isEditable,
                        "close-on-select": false,
                        placeholder: _vm.$t(
                          "singleVisitForm-fields.landscapeFeaturePlaceholder"
                        ),
                        label: "type",
                        "track-by": "landFeatureId",
                        options: _vm.options.landFeature,
                        multiple: true
                      },
                      model: {
                        value: _vm.visitRow.landFeatures,
                        callback: function($$v) {
                          _vm.$set(_vm.visitRow, "landFeatures", $$v)
                        },
                        expression: "visitRow.landFeatures"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4" }, [
                _c("label", { attrs: { for: "form_snow_depth" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("singleVisitForm-fields.snowDepth")) +
                      "\n                "
                  ),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.isEditable
                          ? _vm.tooltips.snowDepth
                          : _vm.tooltips.roSnowDepth,
                        expression:
                          "isEditable? tooltips.snowDepth : tooltips.roSnowDepth"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.visitRow.snowDepthMeters,
                      expression: "visitRow.snowDepthMeters",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "validate",
                  attrs: {
                    disabled: !_vm.isEditable,
                    id: "form_snow_depth",
                    step: "0.1",
                    min: "0",
                    max: "10",
                    type: "number",
                    required: ""
                  },
                  domProps: { value: _vm.visitRow.snowDepthMeters },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.visitRow,
                        "snowDepthMeters",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4" }, [
                _c("label", { attrs: { for: "form_water_depth" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("singleVisitForm-fields.waterDepth")) +
                      "\n                "
                  ),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.isEditable
                          ? _vm.tooltips.waterDepth
                          : _vm.tooltips.roWaterDepth,
                        expression:
                          "isEditable ? tooltips.waterDepth: tooltips.roWaterDepth"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.visitRow.waterDepthMeters,
                      expression: "visitRow.waterDepthMeters",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "validate",
                  attrs: {
                    disabled: !_vm.isEditable,
                    id: "form_water_depth",
                    step: "0.1",
                    min: "0",
                    max: "10",
                    type: "number",
                    required: ""
                  },
                  domProps: { value: _vm.visitRow.waterDepthMeters },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.visitRow,
                        "waterDepthMeters",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 l4 clear-item" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("singleVisitForm-fields.baitType")) +
                      "\n                "
                  ),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.isEditable
                          ? _vm.tooltips.scented
                          : _vm.tooltips.roScented,
                        expression:
                          "isEditable? tooltips.scented: tooltips.roScented"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "vue-select-wrapper" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.visitRow.baitId,
                          expression: "visitRow.baitId"
                        }
                      ],
                      attrs: { disabled: !_vm.isEditable },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.visitRow,
                            "baitId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.options.locationDeploymentVisitBait, function(
                      option,
                      index
                    ) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: option.id } },
                        [_vm._v(_vm._s(option.type))]
                      )
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("singleVisitForm-fields.comments")))
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.visitRow.comments,
                      expression: "visitRow.comments"
                    }
                  ],
                  staticClass: "border border-grey-300",
                  attrs: { disabled: !_vm.isEditable },
                  domProps: { value: _vm.visitRow.comments },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.visitRow, "comments", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.errors, function(error, index) {
              return _c("div", { key: index, staticClass: "warning" }, [
                _vm._v(" " + _vm._s(error) + " ")
              ])
            }),
            _vm._v(" "),
            _vm.message
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(" " + _vm._s(_vm.message) + " ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.isEditable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-border btn-success modal-action modal-close",
                      on: { click: _vm.updateVisitRow }
                    },
                    [_vm._v(_vm._s(_vm.$t("common-save")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-border modal-action modal-close",
                  attrs: { id: "formcancelAdd" },
                  on: { click: _vm.close }
                },
                [_vm._v(_vm._s(_vm.$t("common-close")))]
              ),
              _vm._v(" "),
              _vm.isEditable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-border btn-success modal-action modal-close",
                      class: {
                        disabled: !(
                          _vm.visitRow.id &&
                          _vm.options.deployVisitId &&
                          _vm.options.deployVisitId.length
                        )
                      },
                      on: { click: _vm.addSensor }
                    },
                    [_vm._v(_vm._s(_vm.$t("visitEquipTable-addSensor")))]
                  )
                : _vm._e()
            ])
          ],
          2
        ),
        _vm._v(" "),
        !_vm.hideVisitPhotos
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTab === "visitPhotos",
                    expression: "showTab ==='visitPhotos'"
                  }
                ],
                staticClass: "modal-content padded40 main"
              },
              [
                _c("div", [
                  _c("h5", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("singleVisitForm-photosOf", {
                          label: _vm.locationLabel
                        })
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.isEditable
                  ? _c("div", { staticClass: "upload-area" }, [
                      _c("div", { staticClass: "col s6 offset-s1" }, [
                        _c("input", {
                          ref: "chooseFolder",
                          staticClass: "form-control hidden",
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            id: "chooseFolder",
                            name: "picture",
                            accept: "image/*",
                            webkitdirectory: "true",
                            multiple: "true"
                          },
                          domProps: { value: _vm.fileInputVal },
                          on: { change: _vm.parseUploadFolders }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            staticStyle: { width: "90%" },
                            attrs: { type: "button", id: "browseFolderBtn" },
                            on: { click: _vm.browse }
                          },
                          [
                            _c("span", {
                              staticClass: "glyphicon glyphicon-refresh"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("common-folderBtn")) +
                                "\n          "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s6 offset-s1" }, [
                        _c("input", {
                          ref: "chooseFiles",
                          staticClass: "form-control hidden",
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            id: "chooseFiles",
                            name: "picture-files",
                            accept: "image/*",
                            multiple: "true"
                          },
                          domProps: { value: _vm.fileInputVal },
                          on: { change: _vm.parseUploadFiles }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            staticStyle: { width: "90%" },
                            attrs: { type: "button", id: "browseFilesBtn" },
                            on: { click: _vm.browse }
                          },
                          [
                            _c("span", {
                              staticClass: "glyphicon glyphicon-refresh"
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("common-fileBtn")) +
                                "\n          "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s4" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            staticStyle: { width: "90%" },
                            attrs: {
                              type: "button",
                              disabled:
                                _vm.filesPendingUpload.length === 0 ||
                                _vm.uploading
                            },
                            on: { click: _vm.resetUpload }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("singleVisitForm-clearSelection"))
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "s12" }, [
                        _c(
                          "div",
                          { staticClass: "col offset-s1 s6 filetext-wrapper" },
                          [
                            _vm.filesPendingUpload.length > 0
                              ? _c("span", { staticClass: "filetext-inner" }, [
                                  _vm._v(
                                    _vm._s(_vm.filesPendingUpload.length) +
                                      " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "singleVisitForm-imagesFound",
                                          _vm.filesPendingUpload.length
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col s4" }, [
                          _vm.isEditable
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-border btn-success modal-action modal-close",
                                  attrs: {
                                    disabled:
                                      _vm.filesPendingUpload.length === 0 ||
                                      _vm.uploading
                                  },
                                  on: { click: _vm.processBatchUpload }
                                },
                                [_vm._v(_vm._s(_vm.$t("common-upload")))]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.filesWithParseErrors.length > 0
                        ? _c(
                            "div",
                            { staticClass: "col s12 error" },
                            [
                              _vm._l(_vm.filesWithParseErrors, function(
                                problem
                              ) {
                                return [
                                  _c("div", { key: "error-" + problem.path }, [
                                    _vm._v(
                                      _vm._s(
                                        problem.error + " - " + problem.path
                                      )
                                    )
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s12 error" }, [
                        _vm._v(_vm._s(_vm.uploadMessage))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("visit-photo-gallery", {
                  ref: "gallery",
                  attrs: {
                    "visit-id": _vm.visitRow.id,
                    uploading: _vm.uploading,
                    "is-editable": _vm.isEditable
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }