var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        logContainer: _vm.callsBegan && !_vm.completedCalls,
        padded40: _vm.callsBegan && !_vm.completedCalls
      }
    },
    [
      _vm.callsBegan && !_vm.completedCalls
        ? [
            _c("pulse-loader", {
              staticClass: "pulse-results",
              attrs: {
                loading: true,
                color: "#C94412",
                size: "20px",
                width: 400,
                height: 400
              }
            }),
            _vm._v(" "),
            _vm.progressTotal
              ? _c("div", { staticClass: "results-loading-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("autogenVisitsResults-creating", {
                        num: _vm.progressNum,
                        total: _vm.progressTotal
                      })
                    )
                  )
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.completedCalls
        ? _c(
            "div",
            { staticClass: "padded40 logContainer" },
            [
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.$t("autogenVisitsResults-successfullyCreated", {
                      num: _vm.numSuccess
                    })
                  )
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm
                      .$tc(
                        "common-numErrors",
                        _vm.errorResultsTableData.length,
                        { num: _vm.errorResultsTableData.length }
                      )
                      .toLowerCase()
                  ) + "."
                )
              ]),
              _vm._v(" "),
              _vm.errorResultsTableData.length > 0
                ? _c("v-client-table", {
                    attrs: {
                      data: _vm.errorResultsTableData,
                      columns: _vm.clientColumns,
                      options: _vm.clientOptions
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "message",
                          fn: function(props) {
                            return _c("div", { staticClass: "error" }, [
                              _vm._v(
                                "\n        " +
                                  _vm._s(props.row.message) +
                                  "\n      "
                              )
                            ])
                          }
                        }
                      ],
                      null,
                      false,
                      3409742754
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }