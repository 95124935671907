<template>
    <div>

        <v-client-table :data="data" :columns="columns" :options="options">
            <template slot="checkbox" slot-scope="props">
                <label>
                    <i 
                        v-if="props.row.sizeExceeded || props.row.validNamingFormat === false || props.row.isNewRecording === false || props.row.status == 'uploaded' || props.row.status === 'uploading' || status.uploading" 
                        class="fa-solid fa-circle-exclamation text-red-500 text-lg"
                        v-tooltip="props.row.message"
                    ></i>
                    <input v-else type="checkbox" class="exclusive checkbox" @change="updateRowStatus(props.row)"
                        v-model="props.row.includeInUpload" />
                    <span />
                </label>
            </template>
            <template slot="location" slot-scope="props">
                
            </template>
            <template slot="fileName" slot-scope="props">
                {{ props.row.recordingName || props.row.fileName }}
            </template>
            <template slot="methodId" slot-scope="props">
                <select @change="updateMethodText(props.row)" v-model="props.row.methodId" :disabled="props.row.sizeExceeded || props.row.validNamingFormat === false || props.row.isNewRecording === false || props.row.status == 'uploaded' || props.row.status === 'uploading' || status.uploading">
                    <option v-for="(opt, optIndex) in methodOption" :key="'real-method' + optIndex" :value="opt.id" v-if="!opt.deprecated"> 
                        {{ opt.type }}</option>
                </select>
            </template>
            <template slot="isTriggered" slot-scope="props">
                <label>
                    <input type="checkbox" class="exclusive checkbox" @change="updateIsTriggered(props.row)"
                        :disabled="props.row.sizeExceeded || props.row.validNamingFormat === false || props.row.isNewRecording === false || props.row.status == 'uploaded' || props.row.status === 'uploading' || status.uploading"
                        v-model="props.row.isTriggered" />
                    <span />
                </label>
            </template>
            <template slot="taskLength" slot-scope="props">
                <input type="number" :disabled="props.row.sizeExceeded || props.row.validNamingFormat === false || props.row.isNewRecording === false || props.row.status == 'uploaded' || props.row.status === 'uploading' || status.uploading" v-model="props.row.taskLength" min="1" max="9999" :placeholder="$t('Default')"
                    @keyup="(e) => { if (e.target.value < 1 || e.target.value > 9999) e.target.value = ''; }" @change="updateTaskLength(props.row)">
            </template>
            <template slot="sampleRate" slot-scope="props">
                {{ props.row.sampleRate || '--' }}
            </template>
            <template slot="fileSize" slot-scope="props">
                {{ props.row.fileSize }} {{ $t('commonUnits-megabyte') }}
            </template>
        </v-client-table>


    </div>
</template>

<script>

import { statusOption, ALLCOMPLETED } from './AudioUploader';

export default {
    name: 'batch-table-upload-preview',
    mixins: [],
    props: {
        'methodOption': Array,
        'hiddenColumns': Array,
        status: {
            type: Object
        },
        counts: {
            type: Object
        },
        files: {
            type: Array
        },
        locations: {
            type: [Object,Array]
        }
    },
    components: {},
    data() {
        return {
            statusOption: statusOption,
            filterTable: '',
            data: null,
            columns: ['checkbox', 'fileName', 'location', 'methodId', 'taskLength', 'isTriggered', 'fileType', 'sampleRate', 'fileSize'],
            
            options: {
                hiddenColumns: this.hiddenColumns,
                headings: {
                    checkbox: this.$t('audioUploadForm-headings.upload'),
                    fileName: this.$t('audioUploadForm-headings.fileName'),
                    location: this.$t('audioUploadForm-headings.location'),
                    methodId: this.$t('audioUploadForm-headings.method'),
                    taskLength: this.$t('Length(s)'),
                    isTriggered: this.$t('Triggered'),
                    fileType: this.$t('File Type'),
                    sampleRate: this.$t('Sample Rate'),
                    fileSize: this.$t('audioUploadForm-headings.size')
                },
                texts: {
                    count: this.$t('common-tableTexts.count'),
                    limit: this.$t('dataDiscoverDownload-tableTexts.limit'),
                    noResults: this.$t('dataDiscoverDownload-tableTexts.noResults'),
                    first: this.$t('common-tableTexts.first'),
                    last: this.$t('common-tableTexts.last'),
                    filter: this.$t('common-tableTexts.filter'),
                    filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
                    page: this.$t('common-tableTexts.page'),
                    noRequest: this.$t('common-tableTexts.noRequest'),
                    filterBy: this.$t('common-tableTexts.filterBy'),
                    loading: this.$t('common-tableTexts.loading'),
                    defaultOption: this.$t('common-tableTexts.defaultOption'),
                    columns: this.$t('common-tableTexts.columns')
                },
            }

        }
    },
    created() {
        this.data = this.files.map(f => {
            let temp = { ...f };
            delete temp.file;
            return temp;
        });
    },
    computed: {

    },
    methods: {
        updateRowStatus(row) {
            if (row) {
                if ( row.status === this.statusOption.FAILED) { // not allow to change existing recording status
                    return;
                }
                if (row.includeInUpload) {
                    row.status = this.statusOption.QUEUE;
                } else {
                    row.status = this.statusOption.SKIP;
                }

                this.files.find(f => f.uuid === row.uuid).status = row.status;

            }
            let counts = {};
            Object.keys(this.statusOption).forEach(x => {
                counts[statusOption[x]] = 0;
            });
            this.files.forEach(x => {
                counts[x.status]++
            });
            this.$set(this.counts, 'counts', counts);
        },
        updateMethodText(row) {
            if (row.methodId) {
                row.methodText = (this.methodOption.find(m => m.id === row.methodId) || {}).type; // could use ?? for new browser
                this.files.find(f => f.uuid === row.uuid).methodText = row.methodText;
                this.files.find(f => f.uuid === row.uuid).methodId = row.methodId;
            }
        },
        updateTaskLength(row) {
            if (row.taskLength) {
                this.files.find(f => f.uuid === row.uuid).taskLength = row.taskLength;
            }
        },
        updateIsTriggered(row) {
                this.files.find(f => f.uuid === row.uuid).isTriggered = row.isTriggered;
        }
    }
};

</script>

<style scoped>
</style>