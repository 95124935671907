<template>
        <modal :name="formName"
            :scrollable="true"   :adaptive="true"
            transition="nice-modal-fade"
            classes="modal-form scroll"
            draggable=".drag-handle"
            :clickToClose = "false"
            :delay="100"
            width = "80%"
            height = "80%"
            :minWidth="200"
            :minHeight="200">
            <div @click="closeForm" class="iconButton link">
                <span class="ion-close"></span>
            </div>
            <div class="modal-header drag-handle">
                <h6>{{ $t('imageInfo-imageExifInformation') }}</h6>
            </div>
            <div class="modal-scroller" v-if="img != null">
                <div class="modal-content container">
                    <div class="input">
                        <span class="key">
                            <span>{{ $t('imageInfo-filename') }}:</span>
                        </span>
                        <span class="value" v-if="img!= null">
                            <span>{{img.fileURL}}</span>
                        </span>
                    </div>
                    <img id="imageForExif" v-show="false" :src="img.fileURL"  @load="readExifInfo()" crossorigin="use-credentials" />
                    <div class="exif">
                        <template v-for="(value, key) in exifInfo" >
                            <div class="input" :key="'md' + key">
                                <span class="key">
                                    <span>{{formatMetadatKey(key)}}:</span>
                                </span>
                                <span class="value">
                                    <span>{{value}}</span>
                                </span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

        </modal>
</template>

<script>
/*
read exif from full (thumbnail don't have the exif) image and display them.
for s3 link enable CORS in AWS first,
https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_settings_attributes
when chrome with cache enabled, sending request to s3 don't have origin header,
which will cause CORS error.
use the CORS setting to enable the header and avoid errors.
*/
import { eventBus } from '@/lib/eventbus';
import { readExifDetails } from '@/components/utils/exif/exif-parser';
export default {
  name: 'tagging-exif-image-form',
  props: ['formName', 'img'],
  created () {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/exif-js');
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    closeForm () {
      eventBus.$emit('toggle-modal-form', this.formName, false);
    },
    readExifInfo () {
      const self = this;
      this.$nextTick(async () => {
        const file = document.getElementById('imageForExif'); // resized image don't have exif use original image
        self.exifInfo = await readExifDetails(file);
        // remove markernotes, put ABMIParsed to main

        if (self.exifInfo.ABMIParsed && Object.entries(self.exifInfo.ABMIParsed).length > 0) {
          Object.entries(self.exifInfo.ABMIParsed).forEach(([key, value]) => { self.$set(self.exifInfo, key, value); });
        }
        self.$delete(self.exifInfo, 'MakerNote'); // remove maker notes
        self.$delete(self.exifInfo, 'ABMIParsed'); // remove maker notes
      })
    },
    formatMetadatKey (key) {
      return key.replace(/([A-Z])/g, ' $1').trim()
    }
  },
  computed: {
  },
  data () {
    return {
      exifInfo: null
    }
  }
}
</script>
<style scoped>
.exif .input {
    display: inline-block;

    padding: 5px 0;
    width: 33%;
    font-size: 14px;
}
.exif .input .key {
    color:brown;
}
.exif .input .value {
    color:rgb(73, 24, 24);
}
</style>
