var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deployment-table tasks", attrs: { id: "CAMTask-table" } },
    [
      _c("div", { staticClass: "row no-bottom-margin" }, [
        _c("div", { staticClass: "col s12 m12 l12 xl12" }, [
          _vm.isManager && !_vm.isPublished
            ? _c(
                "div",
                { staticClass: "col s12 m12 l6 xl6 left" },
                [
                  _c("multiselect", {
                    staticClass: "tagger-select",
                    attrs: {
                      selectAllLabel: _vm.$t("common-selectAll"),
                      options: _vm.userList,
                      multiple: true,
                      "close-on-select": false,
                      placeholder: _vm.$t("taskTable-selectTaggersToAssign"),
                      label: "text",
                      "track-by": "id",
                      selectLabel: "",
                      deselectLabel: "",
                      showSelectAll: true
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function({ values, isOpen }) {
                            return [
                              values.length && values.length > 1 && !isOpen
                                ? _c(
                                    "span",
                                    { staticClass: "multiselect__single" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("taskTable-taggersSelected", {
                                            num: values.length
                                          })
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              values.length && values.length == 1 && !isOpen
                                ? _c(
                                    "span",
                                    { staticClass: "multiselect__single" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("taskTable-singleTagger"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2961471935
                    ),
                    model: {
                      value: _vm.randomAssignUsers,
                      callback: function($$v) {
                        _vm.randomAssignUsers = $$v
                      },
                      expression: "randomAssignUsers"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-success btnimportant right assign-btn",
                      class: { downloading: _vm.randomAssignLoading },
                      on: { click: _vm.randomAssign }
                    },
                    [
                      _c("span", { staticClass: "spin-icon" }),
                      _vm._v(_vm._s(_vm.$t("taskTable-randomAssign")))
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "right right-side-wrapper" }, [
            _vm.toggleInitSetting !== null && _vm.isUserProjectMember
              ? _c(
                  "span",
                  { staticClass: "task-toggle-manage" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("taskTable-viewOnlyMine")) + " "
                    ),
                    _c("ToggleButton", {
                      attrs: {
                        value: _vm.toggleInitSetting,
                        sync: true,
                        color: "#C94412"
                      },
                      on: { change: _vm.switchTaskView }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isPublished != null && _vm.hasProjectWriteAccess != null
              ? _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "dropdown-button",
                      [
                        _c("template", { slot: "menuTitle" }, [
                          _vm._v(_vm._s(_vm.$t("common-manage")) + " "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.tableOptions.activeRowId.length,
                                  expression: "tableOptions.activeRowId.length"
                                }
                              ],
                              staticClass: "!text-sm"
                            },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.tableOptions.activeRowId.length) +
                                  ")"
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("template", { slot: "dropdownButtons" }, [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.tableOptions.activeRowId.length > 0,
                                  expression:
                                    "tableOptions.activeRowId.length>0"
                                }
                              ],
                              on: { click: _vm.sendToAI }
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "close-popover",
                                      rawName: "v-close-popover"
                                    }
                                  ],
                                  staticClass:
                                    "btn btn-success popover-menu-item"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("Tag image sets with AI")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openBatchUpload()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "close-popover",
                                      rawName: "v-close-popover"
                                    }
                                  ],
                                  staticClass:
                                    "btn btn-success popover-menu-item"
                                },
                                [_vm._v(_vm._s(_vm.$t("taskTable-camUpload")))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "group-top btn btn-success popover-menu-item",
                              class: { downloading: _vm.downloading },
                              attrs: { download: "" },
                              on: { click: _vm.downloadLocationCSV }
                            },
                            [
                              _c("span", {
                                staticStyle: { "font-size": "20px" }
                              }),
                              _c("span", { staticClass: "spin-icon" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("locationTable-downloadCSV"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.canUpload
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.tooltips.disabledUpload,
                                        show: _vm.canUpload,
                                        classes: "front"
                                      },
                                      expression:
                                        "{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "close-popover",
                                          rawName: "v-close-popover"
                                        }
                                      ],
                                      staticClass:
                                        "btn btn-success popover-menu-item",
                                      attrs: {
                                        disabled:
                                          !_vm.hasProjectWriteAccess ||
                                          _vm.isPublished
                                      },
                                      on: { click: _vm.uploadLocationCSV }
                                    },
                                    [
                                      _c("span", {
                                        staticStyle: { "font-size": "20px" }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("locationTable-uploadCSV")
                                          )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "close-popover",
                                      rawName: "v-close-popover"
                                    }
                                  ],
                                  staticClass:
                                    "btn btn-success popover-menu-item",
                                  on: { click: _vm.uploadLocationCSV }
                                },
                                [
                                  _c("span", {
                                    staticStyle: { "font-size": "20px" }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("locationTable-uploadCSV"))
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-success popover-menu-item group-top",
                              class: { downloading: _vm.downloadingTags },
                              attrs: { download: "" },
                              on: { click: _vm.downloadTags }
                            },
                            [
                              _c("span", {
                                staticStyle: { "font-size": "20px" }
                              }),
                              _c("span", { staticClass: "spin-icon" }),
                              _vm.tableLoadedOnce &&
                              _vm.$refs.table.data.length === 0
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("taskTable-downloadTagTemplate")
                                      )
                                    )
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.$t("taskTable-downloadTags"))
                                    )
                                  ]
                            ],
                            2
                          ),
                          _vm._v(" "),
                          !_vm.canUpload
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.tooltips.disabledUpload,
                                        show: _vm.canUpload,
                                        classes: "front"
                                      },
                                      expression:
                                        "{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "close-popover",
                                          rawName: "v-close-popover"
                                        }
                                      ],
                                      staticClass:
                                        "btn btn-success popover-menu-item",
                                      attrs: {
                                        disabled:
                                          !_vm.hasProjectWriteAccess ||
                                          _vm.isPublished
                                      },
                                      on: { click: _vm.uploadTagsCSV }
                                    },
                                    [
                                      _c("span", {
                                        staticStyle: { "font-size": "20px" }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("common-uploadItem", {
                                              item: _vm.$tc("common-tag", 2)
                                            })
                                          )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "close-popover",
                                      rawName: "v-close-popover"
                                    }
                                  ],
                                  staticClass:
                                    "btn btn-success popover-menu-item",
                                  on: { click: _vm.uploadTagsCSV }
                                },
                                [
                                  _c("span", {
                                    staticStyle: { "font-size": "20px" }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("common-uploadItem", {
                                          item: _vm.$tc("common-tag", 2)
                                        })
                                      )
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-success popover-menu-item group-top",
                              class: { downloading: _vm.downloadingCodes },
                              attrs: { download: "" },
                              on: { click: _vm.downloadCodes }
                            },
                            [
                              _c("span", {
                                staticStyle: { "font-size": "20px" }
                              }),
                              _c("span", { staticClass: "spin-icon" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("common-downloadCodes"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.tableOptions.activeRowId.length > 0,
                                  expression:
                                    "tableOptions.activeRowId.length>0"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.showDeleteTaskForm(_vm.activeRows)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "close-popover",
                                      rawName: "v-close-popover"
                                    }
                                  ],
                                  staticClass:
                                    "group-top btn btn-success popover-menu-item"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("Delete Tasks")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.message != null
        ? _c(
            "div",
            {
              staticClass: "row col s12 error no-bottom-margin",
              class: { "error-hide": !_vm.message }
            },
            [_vm._v(_vm._s(_vm.message))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.optionsLoaded && _vm.tableOptions
        ? _c(
            "v-server-table",
            {
              ref: "table",
              staticClass: "table vue-bordered taskTable w-full",
              attrs: {
                url: _vm.url,
                columns: _vm.columns,
                options: _vm.tableOptions
              },
              on: {
                loaded: function($event) {
                  return _vm.tableLoaded()
                },
                limit: _vm.resetCheckBoxes,
                pagination: _vm.resetCheckBoxes
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "check",
                    fn: function(props) {
                      return [
                        _c("label", [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              "data-id": props.row.id
                            },
                            on: {
                              click: function($event) {
                                return _vm.onCheckClick(props.row)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span")
                        ])
                      ]
                    }
                  },
                  {
                    key: "deploymentName",
                    fn: function(props) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: { href: _vm.recordingRowUrl(props.row.id) }
                          },
                          [
                            _c("span", {
                              staticClass: "indicator",
                              class: _vm.cameraGetClass(props.row.statusId)
                            }),
                            _vm._v(_vm._s(props.row.deploymentName))
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "statusId",
                    fn: function(props) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.getStatus(props.row.statusId)) +
                            "\n        "
                        )
                      ]
                    }
                  },
                  {
                    key: "userId",
                    fn: function(props) {
                      return [
                        _vm.isManager &&
                        _vm.editUserId == props.row.id &&
                        !_vm.isPublished
                          ? [
                              _c("div", { staticClass: "col s10" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: props.row.userId,
                                        expression: "props.row.userId"
                                      }
                                    ],
                                    staticStyle: { height: "2em" },
                                    on: {
                                      focus: _vm.clearMessage,
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            props.row,
                                            "userId",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.updateUser(
                                            props.row,
                                            $event
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "-1" } }, [
                                      _vm._v(
                                        " -- " +
                                          _vm._s(
                                            _vm.$t("taskTable-notAssigned")
                                          ) +
                                          " -- "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.userList, function(user, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: "user" + index,
                                          domProps: { value: user.id }
                                        },
                                        [_vm._v(" " + _vm._s(user.text))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col s2 link",
                                  on: {
                                    click: function($event) {
                                      _vm.editUserId = -1
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "ion-checkmark text-burntOrange"
                                  })
                                ]
                              )
                            ]
                          : [
                              _c("div", { staticClass: "col s9" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(props.row.tagger) +
                                    " "
                                ),
                                _vm.isManager &&
                                _vm.editUserId !== props.row.id &&
                                !_vm.isPublished
                                  ? _c("span", {
                                      staticClass:
                                        "ion-edit cursor-pointer text-burntOrange",
                                      on: {
                                        click: function($event) {
                                          _vm.editUserId = props.row.id
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                      ]
                    }
                  },
                  {
                    key: "child_row",
                    fn: function(props) {
                      return _vm.toLoadSubRow
                        ? _c(
                            "div",
                            { staticClass: "row no-bottom-margin taskTable" },
                            [
                              _c(
                                "div",
                                { staticClass: "grid grid-cols-6" },
                                [
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("taskTable-totalImages"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("taskTable-motionImages"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v(_vm._s(_vm.$t("taskTable-model")))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v(_vm._s(_vm.$t("Serial #")))
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("taskTable-firstImage"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "center" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("taskTable-lastImage"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  props.row.subDetails != null &&
                                  props.row.subDetails.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "center brown-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0]
                                                  .totalImages
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "center brown-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0]
                                                  .motionImages
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "center brown-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0]
                                                  .cameraMakeModel
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "center brown-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0]
                                                  .serialNumber
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "center brown-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0]
                                                  .firstImage
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "center brown-color" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0]
                                                  .lastImage
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              props.row.subDetails != null &&
                              props.row.subDetails.length > 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              props.row.subDetails[0].tagTypes,
                                            expression:
                                              "props.row.subDetails[0].tagTypes"
                                          }
                                        ],
                                        staticClass: "row"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col center s2" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("taskTable-tagTypes")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              props.row.subDetails[0].tagTypes,
                                            expression:
                                              "props.row.subDetails[0].tagTypes"
                                          }
                                        ],
                                        staticClass: "row"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row col s11 offset-s1 brown-color"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                props.row.subDetails[0].tagTypes
                                              ) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col center s2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("AI Detection Status")
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col s11 row offset-s1 brown-color"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.row.subDetails[0]
                                                .megaDetectorStatus
                                            ) + " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              !props.row.childRowLoading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row col s12 no-bottom-margin"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "btns-wrapper right" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn",
                                              class: {
                                                "btn-border": !_vm.isManager
                                              },
                                              attrs: {
                                                disabled: !_vm.isManager
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showDeleteTaskForm(
                                                    [props.row]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticStyle: {
                                                  "font-size": "20px"
                                                }
                                              }),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("taskTable-deleteTask")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c("template", { slot: "h__check" }, [
                _c("span", { on: { click: _vm.onCheckAllClick } }, [
                  _vm.tableOptions.activeRowId.length ==
                  _vm.tableOptions.perPage
                    ? _c("i", {
                        staticClass: "fa fa-check text-2xl cursor-pointer"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tableOptions.activeRowId.length < _vm.tableOptions.perPage
                    ? _c("i", {
                        staticClass:
                          "fa-regular fa-square-full text-xl cursor-pointer"
                      })
                    : _vm._e()
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "delete_task",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "99%",
            height: "420",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.showDeleteForm
            ? _c("task-delete-form", {
                attrs: {
                  "task-rows": _vm.taskRows,
                  "task-ids": _vm.taskIds,
                  headings: _vm.tableOptions.headings,
                  "active-sensor": "CAM"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "project-upload-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("upload-csv-form", {
            attrs: {
              "project-name": _vm.projectName,
              projectId: _vm.projectId,
              "upload-url": _vm.apiUrls.uploadTagsCSVUrl,
              "uploading-tags": _vm.uploadingTags
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "location-upload-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.project.organizationId && _vm.options
            ? _c("upload-location-csv", {
                attrs: {
                  "organization-id": _vm.project.organizationId,
                  "organization-name": _vm.project.fullNm,
                  "visibility-options": _vm.options.visibility,
                  "project-id": _vm.projectId,
                  project: _vm.project,
                  type: "project-location"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }