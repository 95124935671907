var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "remote-camera camera-tagging no-top-sticky-menu" },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.error.statusText) + "\n    ")
                ])
              : _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.warning != null
        ? _c("div", { staticClass: "warning" }, [
            _vm._v("\n      " + _vm._s(_vm.warning) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c("div", [
            _c("div", { staticClass: "container large" }, [
              _c("div", { staticClass: "row breadCrumb" }, [
                _c("div", { staticClass: "col s12" }, [
                  _c("p", [
                    _c("a", { attrs: { href: _vm.breadcrumbHomeLink() } }, [
                      _vm._v(_vm._s(_vm.$t("common-home")))
                    ]),
                    _vm._v(" / "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbBaseLink() + "CAM" } },
                      [_vm._v(_vm._s(_vm.$t("common-camera")))]
                    ),
                    _vm._v(" /\n                "),
                    _c(
                      "a",
                      { attrs: { href: _vm.breadcrumbCAMSPPProjectLink() } },
                      [_vm._v(_vm._s(_vm.project && _vm.project.fullNm))]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "stickyWrap", attrs: { id: "topStickyBar" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "sticky",
                        rawName: "v-sticky",
                        value: {
                          zIndex: 10,
                          stickyTop: 0,
                          stickTopOnly: true,
                          positionType: "fixed",
                          stickyContainerId: "topStickyBar"
                        },
                        expression:
                          "{zIndex: 10, stickyTop: 0, stickTopOnly: true, positionType: 'fixed', stickyContainerId: 'topStickyBar'}"
                      }
                    ],
                    staticClass: "sticky",
                    staticStyle: { position: "static" },
                    attrs: { id: "topStickyBarContent" }
                  },
                  [
                    _c("div", { staticClass: "container large taskHeader" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col s12 m12 l8 title" }, [
                          _c("div", [
                            _c("h1", [
                              _vm._v(
                                _vm._s(
                                  _vm.speciesObj && _vm.speciesObj.commonName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "actionGroup" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "editLink",
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleDialog(
                                        _vm.ui.helpFormName,
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "ion-help-circled"
                                  }),
                                  _vm._v(_vm._s(_vm.$t("common-help")))
                                ]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.$refs.tag ? _c("div") : _vm._e(),
                        _vm._v(" "),
                        _vm.projectSppInfo.canUserModify
                          ? _c(
                              "div",
                              { staticClass: "col s12 m12 l4 filters" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col s6 offset-s6" },
                                    [
                                      _vm.selectedImageCount > 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btnImportant fullWidth modal-trigger projectAdd",
                                              attrs: {
                                                href: "#tagCameraModal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.tagSelected()
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "ion-ios-pricetag-outline"
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("common-tagSelected")
                                                  ) +
                                                  "\n                              "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "qty" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedImageCount
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c(
            "div",
            {
              staticClass: "container overflow",
              staticStyle: { "margin-top": "20px" }
            },
            [
              _c(
                "div",
                { staticClass: "padded boxed dataGroup" },
                [
                  _vm.staticOptions && _vm.projectId && _vm.speciesId
                    ? _c("tagging-filter", {
                        attrs: {
                          "static-options": _vm.staticOptions,
                          project: _vm.project,
                          "mega-detector-categories":
                            _vm.megaDetectorCategories,
                          "species-id": _vm.speciesId
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.staticOptions && _vm.projectId
                    ? _c("tagging-gallery", {
                        ref: "tag",
                        attrs: {
                          "project-id": _vm.projectId,
                          speciesObj: _vm.speciesObj,
                          "static-options": _vm.staticOptions,
                          "is-tagger": _vm.projectSppInfo.canUserModify,
                          "mega-detector-categories":
                            _vm.megaDetectorCategories,
                          project: _vm.project
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("tagging-help", { attrs: { "form-name": _vm.ui.helpFormName } }),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }