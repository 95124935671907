<template>
  <div id="addProjectModal" class="h-full"  >
 
       <div @click="close" v-show="!loading" class="iconButton link">
        <span class="ion-close"></span>
      </div>
     <div v-if="loading" class="center modal-content padded40" style="padding:40px;">
        <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
        <div>{{commonMsg.loading}}</div>
     </div>
    <div v-if="project != null" v-show="!loading" class="h-full relative">

    <ul class="tabs drag-handle !m-0 !w-full !max-w-full" v-if="!newProject">
      <li class="tab"  @click="showTab='project'">
        <a :class="{active: showTab == 'project'}">{{projectId == null? $t('projectForm-create', {label: project && project.sensorId}) : $t('projectForm-update', {label: project && project.sensorId})}}</a></li>
      <li v-if="project && project.id" @click="showTab='species'" class="tab">
        <a :class="{active: showTab === 'species'}">
          {{$t('projectForm-species')}}
        </a>
      </li>
      <li  v-if="project && project.id != null"  @click="showTab='user'" class="tab"><a :class="{active: showTab =='user'}">{{$t('projectForm-users')}}</a></li>
 
    </ul> 

      <!--------------------------------
              SEPCIES TAB 
      ----------------------------------->
      <div v-show="showTab === 'species' && speciesLoading" class="center modal-content padded40" style="padding:40px;">
        <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
        <div>{{$t('Loading Species')}}</div>
      </div>
      <div v-if="species !== null" class="modal-content !p-10 !static" v-show="showTab === 'species' && !speciesLoading">
        <h4 class="mt-0"> {{$t('projectForm-species')}}</h4>

        
       
        <div class="flex relative" id="species-selection-widget">
          <div v-if="presetLoading" class="center modal-content absolute bg-white/75 w-full h-full z-10" style="padding:40px;">
            <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
            <div>{{commonMsg.loading}}</div>
          </div>
          <div class="w-1/2 flex-1">
           <label>{{$t('projectForm-displaySpeciesNamesAs')}}</label>
           <select v-model="showScientificName">
              <option :value="false">{{$t('projectForm-common-name')}}</option>
              <option :value="true">{{$t('projectForm-scientific-name')}}</option>
            </select>
            <label>{{$t('common-notIncluded')}}</label>
  
            <div class="overflow-scroll h-96 !border !border-black !border-solid block !mb-5" >
    
            <species-sort-table 
              :species="species.Excluded" 
              :showScientificName="showScientificName"  
              :selectable="true"
              :ref="EXCLUDED_LIST">
            </species-sort-table>
            </div>
            <div class="btn btn-primary" @click="selectAll(EXCLUDED_LIST)">{{$t('Select All')}}</div> 
            <div class="btn btn-primary"  @click="unselectAll(EXCLUDED_LIST)">{{$t('Unselect All')}}</div>
        </div>

        <div class="flex-init block p-1 flex-col flex pt-48">
          <div :disabled="!project.hasProjectWriteAccess && !newProject" class="btn btn-primary" @click="includeSpecies"><i class="fas fa-chevron-right align-middle"></i></div>
          <div :disabled="!project.hasProjectWriteAccess && !newProject" class="btn btn-primary" @click="excludeSpecies"><i class="fas fa-chevron-left align-middle"></i></div>
        </div>

        <div class="w-1/2 flex-1">
        <label>{{$t('common-presets')}}  <span v-tooltip="$t('Preset groups allow you to choose from different lists of species from various geographic regions, e.g. Bats of Alberta, Birds of Canada. Lists are maintained by various authorities and taxa specialists. If you need a custom preset group added, email info@wildtrax.ca')"> <i class="fas fa-info-circle text-yellow-400"></i></span></label>
        <div class="flex">
        
          <button :disabled="!project.hasProjectWriteAccess && !newProject" class="flex-1 btn" @click="showPresetModal">
            <span class="!text-sm">{{$t('Apply Preset')}}</span>
          </button>
        </div>
        <label>{{$t('common-included')}} <span v-tooltip="$t('Species included in the project are the only ones that can be tagged. If you need to tag another species, return to the project settings and add it from the Not Included column on the left')"><i class="fas fa-info-circle text-yellow-400"></i></span></label>
        <div class="overflow-scroll h-96 !border !border-black !border-solid block !mb-5">
        
        <species-sort-table
         :species="species.Included" 
         :showScientificName="showScientificName"  
         :selectable="true"
         :ref="INCLUDED_LIST"
         >
        </species-sort-table>
        </div>
         <div class="flex">
          <div class="btn btn-primary flex-initial mr-2" @click="selectAll(INCLUDED_LIST)">{{$t('common-selectAll')}}</div> 
          <div class="btn btn-primary flex-initial mr-2"  @click="unselectAll(INCLUDED_LIST)">{{$t('equipmentGridComboBox.unselectAll')}}</div>
          </div>
        </div>
        </div>
        <div class="modal-footer flex justify-end absolute bottom-5 w-full -ml-20">
           <button v-if="newProject" :disabled="!buttonStates.species.Prev" @click="showTab='project'" class="btn mr-3 flex-initial">{{$t('Previous')}}</button>
          <button v-if="newProject" :disabled="!buttonStates.species.Next" @click="() => { if (newProject) showTab='user'; }" class="btn btn-success flex-initial">{{$t(newProject?'Next':'Update')}}</button>
         
        </div>
      </div>

      <!--------------------------------
              PROJECT TAB 
      ----------------------------------->

      <div class="modal-content padded40 !static" v-show="showTab =='project' && !loading">
        <h4>{{projectId == null? $t('projectForm-create', {label: project && project.sensorId}) : $t('projectForm-update', {label: project && project.sensorId})}}</h4>
        <div class="row">
          <div class="col s12 text-sm">
            {{$t('Fields marked * are required')}}
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6 l8">
            <label  for="project_title">{{$t('projectForm-fields.title')}} <span class="font-bold text-base">*</span>
             <span class="info-icon ion-information-circled"
                v-tooltip="$t('Unique short-hand name that describes the project')"/>
            </label>
            <input :disabled="!project.hasProjectWriteAccess && !newProject && !newProject" id="project_title" v-model="project.fullNm" type="text" class="validate" required :placeholder="$t('projectForm-fields.title')">
          </div>
          <div class="col s12 m6 l4">
            <label for="project_title">{{$tc('common-organization', 1)}} <span class="font-bold text-base">*</span>
              <span class="info-icon ion-information-circled"
                v-tooltip="$t('Dropdown menu of the list of organizations the project can belong to (depending on user membership)')"/>
                
            </label>
            <select v-model="project.organizationId" :disabled="project.id > 0">
              <template v-for="(option, index) in organizationList">
              <option v-if="project.id > 0" :key="'org' + index" :value="option.id">{{option.organization}}</option>
              <option v-if="project.id == null && option.canEditOrganization" :key="'org' + index" :value="option.id">{{option.organization}}</option>
              </template>
            </select>
          </div>
          <div v-if="project.sensorId !== 'PC'" class="col s12 m4 l4">
            <label>{{$t('projectForm-fields.dueDate')}}
              <span class="info-icon ion-information-circled"
                v-tooltip="$t('Due date of the project for processors/validators')"/>

            </label>
            <div class="vue-select-wrapper">
              <date-picker :disabled="!project.hasProjectWriteAccess && !newProject && !newProject" v-model="project.dueDateStr" :lang="locales[$root.$i18n.locale]" valueType="format" format="YYYY-MM-DD" :use-utc="true" :disabled-dates="state.disabledDates"></date-picker>
            </div>
          </div>
          <div class="col s12 m4">
            <label for="project_dataSource">{{$t('projectForm-fields.dataSource')}}
              <span class="info-icon ion-information-circled"
                v-tooltip="$t('Does the data derive from an external source?')"/>
            </label>
            <input :disabled="!project.hasProjectWriteAccess && !newProject && !newProject" id="project_dataSource" type="text" class="validate" v-model.number="project.dataSource">
          </div>
          <div class="col s6 ">
            <label for="project_description" >{{$t('Purpose and Methods')}}<span class="info-icon ion-information-circled"
                v-tooltip="$t('An outline of the purposes (objectives) and methods of the project.')"/></label>
            <textarea :disabled="!project.hasProjectWriteAccess && !newProject && !newProject" id="project_description" class="materialize-textarea !overflow-auto" data-length="120" v-model="project.description"></textarea>
            <span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
          </div>
          <div class="col s6 ">
            <label for="project_results" >{{$t('Results Summary')}}
              <span class="info-icon ion-information-circled"
                v-tooltip="$t('Summary of the results and data found')"/>
            </label>
            <textarea :disabled="!project.hasProjectWriteAccess && !newProject && !newProject" id="project_results" class="!overflow-auto materialize-textarea" data-length="120" v-model="project.results"></textarea>
            <span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
          </div>
          <div class="col" v-if="project.sensorId==='ARU' && options.spectrogramOptions">
            <div class="bg-gray-100 p-6 ">
              <label class="text-base mb-3 block">{{$t('projectForm-ARUProjectOptions')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('Select from the options below to choose the default spectrogram options for processing tasks.')"></span></label>
              <div class="grid grid-cols-3 gap-2" >
                <div>
                  <label>{{$t('Default Min Freq(Hz)')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('Set the default minimum frequency of the spectrogram in hertz.')"></span></label>
                  <input :disabled="!project.hasProjectWriteAccess && !newProject" type="num" class="h-12 w-full border-gray-200 border px-3" v-model="project.defaultMinFreqHz" />
                </div>
                <div>
                  <label>{{$t('Default Max Freq(Hz)')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('Set the default maximum frequency of the spectrogram in hertz.')"></span></label>
                  <input :disabled="!project.hasProjectWriteAccess && !newProject" type="num" class="h-12 w-full border-gray-200 border px-3" v-model="project.defaultMaxFreqHz" />
                </div>
                <div>
                  <label>{{$t('Default X Scale')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time.')"></span></label>
                  <div class="flex items-center"><span class="flex-initial w-12">{{project.defaultXScale}}x </span>
                  <input :disabled="!project.hasProjectWriteAccess && !newProject" class="flex-1" type="range" min="0.25" max="10" step="0.25" v-model="project.defaultXScale" /></div>
                </div>
                <div>
                  <label>{{$t('Default Y Pixels')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('aruVerificationModal-tooltips.Y-scale')"></span></label>
                  <select :disabled="!project.hasProjectWriteAccess && !newProject" v-model="project.defaultYPixels">
                    <option v-for="(option, index) in options.spectrogramOptions.spectrogramYPixels" :key="index" :value="option.id">{{option.value}}</option>
                  </select>
                </div>
                <div>
                  <label>{{$t('Default Monochrome')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms.')"></span></label>
                    <div>
                    <i :disabled="!project.hasProjectWriteAccess" v-if="project.useMonochrome" @click="()=>{project.useMonochrome = false}" 
                      class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                    <i :disabled="!project.hasProjectWriteAccess" v-if="!project.useMonochrome" @click="()=>{project.useMonochrome = true}" 
                      class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                  </div>
                </div>
                <div>
                  <label>{{$t('Default Lightmode')}} <span  class="info-icon ion-information-circled" v-tooltip="$t('When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms.')"></span></label>
                    <div>
                    <i :disabled="!project.hasProjectWriteAccess" v-if="project.useLightMode" @click="()=>{project.useLightMode = false}" 
                      class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                    <i :disabled="!project.hasProjectWriteAccess" v-if="!project.useLightMode" @click="()=>{project.useLightMode = true}" 
                      class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12" v-if="project.sensorId==='CAM'">
            <div class="bg-gray-100 p-6 mb-3">
              <label class="text-base mb-3 block">{{$t('Use Species Settings')}}</label>
              <div class="grid grid-cols-6 gap-3">
                <template v-for="(item, index) in useSpeciesSettings">
                  <div :key="index">
                    <label class="flex items-start">
                      <input type="checkbox" v-model="project[item.name]" />
                      <span/>
                        <div>
                          {{item.label}}
                          <span class="info-icon ion-information-circled" v-tooltip ="item.tooltip"></span>
                        </div>
                    </label>
                  </div>
                </template>
              </div>
              <!-- <div class="p-3" v-if="newProject">
                <a class="btn" @click="speciesSettingsSelectAllNone">{{$t('Select All / None')}}</a>
              </div> -->
            </div>
            <div class="bg-gray-100 p-6 ">
              <label class="text-base mb-3 block">{{$t('projectForm-fields.tools')}}</label>
              <div class="grid grid-cols-5 gap-3">
                
                <div class="">
                  <label for="auto-tagger" class="flex items-start">
                    <div class="w-full">
                      {{$t('Auto-tagger')}} <span class="info-icon ion-information-circled" v-tooltip ="$t('The Auto-tagger selection indicates whether image uploads will be run through the auto-tagger automatically. The auto-tagger is a computer-based tool that uses machine-learning techniques to detect images of humans, vehicles, animals, domestic cows, and NONE (i.e., false fires). Once applied, images of humans are blurred, and those classified as human, vehicle, domestic cow, and NONE are tagged as such and hidden from further assessment (can be viewed via unfiltering “exclude auto-tagged” through the FILTER button). Importantly, the images classified as animals are not identified to species; thus, no species tag is applied; these images are automatically available for viewing and subsequent tagging. Note: some species identification may be available through the auto-tagger in the future.')"></span>
                      <br />
                      <template v-for="(tagger,i) in localOptions.autoTagger">
                        <label :key="i" class="flex items-start">
                          <input type="checkbox" @click="autoTaggerCheck(tagger.id)" :checked="autoTaggerIsChecked(tagger.id)"><span class="!normal-case" >{{tagger.type}}</span>
                        </label>
                        <label :key="`${i}_2`" class="flex items-start ml-10" v-show="autoTaggerIsChecked(tagger.id)">
                            <input type="checkbox" @click="autoTaggerCheckHiddenByDefault(tagger.id)" :checked="autoTaggerIsCheckedHiddenByDefault(tagger.id)" /><span class="!text-sm !normal-case"> Hidden by default</span>
                        </label>
                      </template>
                      
                    </div>
                  </label>
                </div>
                
                <div class="">
                  <label for="second-image-gap" > 
                    {{$t('projectForm-fields.gap')}}
                    <span class="info-icon ion-information-circled" v-tooltip ="$t('The default time (in seconds) between images or sequences that defines an independent detection event. For example, suppose the inter-detection interval is 120 seconds and a coyote is detected in an image. In that case, all images of coyotes that occur within 120 seconds of the last image of a coyote are considered the same detection event (this continues until >120 seconds pass without a coyote present). Users can modify the inter-detection interval at any time. Note: some definitions include that detections must be consecutive (i.e., not interrupted by another species); document your definition in the Purpose and Methods.')"/>
                    <input :disabled="!project.hasProjectWriteAccess && !newProject" id="second-image-gap" v-model="project.seriesGap" />
                  </label>
                </div>
                
                <div>
                  <label>{{$t('Bounding box display threshold')}}  <span class="info-icon ion-information-circled" v-tooltip ="$t('The minimum confidence of a mega detector bounding box to display it. Recommended 30-50%.')"></span>
                    <div class="flex items-center">
                      <input type="range" min="0" max="1" step="0.01" v-model="project.defaultMinMegaDetectorConfidence||0.5" />
                    </div>
                    <div class="text-center -mt-5">{{project.defaultMinMegaDetectorConfidence}}</div>
                  </label>
                </div>
                <div>
                  <label  for="timelapse" class="flex items-start">
                  <input :disabled="!project.hasProjectWriteAccess && !newProject" id="timelapse" v-model="project.showTimeLapseByDefault" type="checkbox" />
                  <span />
                  {{$t('Show timelapse images')}}
                  <span class="info-icon ion-information-circled" v-tooltip ="$t('Determines if time-lapse images are shown by default not this can be toggled on and off inside each set of images while tagging.')"></span>
                  </label>
                </div>
              
              </div>
            </div>
          </div>

          <div class="col s12 m4 l4">
            <label>{{$t('common-status')}}</label>
            <div class="vue-select-wrapper">
              <select  class="initialized" v-model="project.status" type="number">
                <template  v-for="(option, index) in options.status">
                  <!-- dont show published map + report for pc, its the same as published public as they have no media -->
                  <option v-if="!(project.sensorId === 'PC' && option.id === 4)" :key="index" :value="option.id">{{option.type}}</option>
                </template>
              </select>
            </div>
          </div>
        </div>
        <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
        <div class="modal-footer flex justify-end row w-full -ml-20">
          <button :disabled="!buttonStates.project.Next" @click="saveProject" class="btn btn-success flex-initial">{{$t(newProject?'Next':'Update')}}</button>
        </div>
      </div>

    <!--------------------------------
              USERS TAB 
      ----------------------------------->

      <div class="modal-content padded40  !static" v-show="project.id != null && showTab =='user' && !loading">
        <h4>{{$t('projectForm-users')}}</h4>
        <div class="row relative">
          <project-user-assignment-table v-if="project.id" :users="usersInProject.concat(usersNotInProject)" :parentId="project.id" parentIdLabel="projectId" />
        </div>

        <div class="message">{{message}}</div>
        <div class="modal-footer flex justify-end bottom-5 w-full">
          <button v-if="newProject" :disabled="!buttonStates.user.Prev" @click="showTab='species'" class="btn flex-initial mr-3">{{$t('Prev')}}</button>
          <button :disabled="!buttonStates.user.Next" @click="close" class="btn btn-success flex-initial">{{$t(newProject?'Create':'Update')}}</button>
        </div>
      </div>
   
    </div>

    <modal 
      name="preset-modal"
      :scrollable="true" 
      :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      :delay="100"
      width = "50%"
      :clickToClose = "false"
      :shiftY="0.25"
      height="500px"
      >
      <div @click="closePresetModal" class="iconButton link">
        <span class="ion-close"></span>
      </div>
      <div class="p-5 modal-content">
        <h5>{{$t('Apply Preset')}}</h5>
        <div class="flex gap-3 items-center">
          <treeselect
            :multiple="true"
            :options="presets.list"
            :value-consists-of="'ALL'"
            v-model="presets.selected.list"
            v-if="presets.list"
            :max-height="250"
            :placeholder="$t('Begin typing to find presets.')"
            class="flex-1">
          ></treeselect>
          
        </div>
        <div v-if="presets.selected.species && presets.selected.species.length > 0">
          <div  class="overflow-scroll h-80 !border !border-gray-300 rounded !border-solid block !my-5" >
              <species-sort-table :species="presets.selected.species" :selectable="false"></species-sort-table>
          </div>
          <div class="modal-footer flex justify-end px-5 w-full">
                  <button @click="closePresetModal" class="btn flex-initial  mr-3">{{$t('common-cancel')}}</button>
                  <button @click="applyPreset" :disabled="!presets.selected.list" class="btn btn-success flex-initial">{{$t('Confirm')}}</button>
          </div>
        </div>
        <div v-if="!Array.isArray(presets.selected.species) && presets.selected.species === null" class="py-5">
          {{$t('No species were found in this preset group. Please try again.')}}
        </div>
      </div>
      
   
    </modal>
   
  </div>
</template>

<script>
import Datepicker from 'vue2-datepicker';
import {eventBus} from '@/lib/eventbus';
import {API_URL, alertDialog, copyObject} from '@/lib/common';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';
import DatePickerMixin from '@/components/common/DatePickerMixin';
import Treeselect from '@riophae/vue-treeselect';
import '../../../static/style/vue-treeselect.css'
import SpeciesSortTable from '@/components/common/SpeciesSortTable';
import ProjectUserAssignmentTable from './ProjectUserAssignmentTable.vue';

const INCLUDED_LIST = 'INCLUDED_LIST';
const EXCLUDED_LIST = 'EXCLUDED_LIST';

export default {
  name: 'project-form',
  props: ['projectId', 'activeSensor', 'options'],
  components: {'date-picker': Datepicker, 'multiselect': Multiselect, 'pulse-loader': PulseLoader, 'treeselect': Treeselect, 'species-sort-table': SpeciesSortTable, 'project-user-assignment-table': ProjectUserAssignmentTable},
  mixins: [FormMixin, DatePickerMixin],
  data () {
    return {
      useSpeciesSettings: [
        { 
          name: 'useIndividualCounts', 
          label: this.$t('Individual Count'),
          tooltip: this.$t('The individual count field is used to indicate the number of individuals of a certain species, age, sex, behaviour, etc. (i.e., applies to a specific tag where all other fields remain equal, rather than the image). For example, if adults and juveniles occurred together in an image, the individual count would not equate to the number of both adults and juveniles, but rather, a separate tag for juveniles should be applied, and the individual counts in the separate tags should include the number of adults and the number of juveniles, respectively.')
       },
          {
          name: 'useSpeciesAge',
          label: this.$t('Age'),
          tooltip: this.$t('Age is used to classify the age class of individuals')
        },
             {
          name: 'useSpeciesSex',
          label: this.$t('Sex'),
          tooltip: this.$t('Sex is used to classify the sex class of individuals (when identifiable). The options include Unknown (default), Male, Female, and VNA (variable not applicable). Users can select VNA if they are not interested in collecting this information.')
        },
           {
          name: 'useSpeciesBehaviour',
          label: this.$t('Behaviour'),
          tooltip: this.$t('Behaviour is a one-to-many field (choose all that apply) used to flag behaviours of individual mammals. The options include Travelling, Standing, Running, Foraging, Drinking, Bedding, Inspecting, Vigilant, Territorial Display, Rutting, Unknown, Other, VNA (variable not applicable; default). Users can select VNA if they are not interested in collecting this information.')
        },
        {
          name: 'useSpeciesHealth',
          label: this.$t('Health/Disease'),
          tooltip: this.$t("Health/Disease is a one-to-many field (choose all that apply) used to flag descriptors of an individual\\'s health or disease status. The options include Poor Condition, Discolouration, Hair loss, Lumps, Scarring, Injured, Malformed (environmental and/or genetic), Diseased, Ticks, Mange, Dead, Other, Unknown, VNA (variable not applicable). Users can select VNA if they are not interested in collecting this information.")
        },
        {
          name: 'useSpeciesDirection',
          label: this.$t('Direction of travel'),
          tooltip: this.$t("Direction of travel is a numerical field (1-12) used to indicate the direction of travel of moving individuals. The numbers 1-12 represent the positions of a clock in relation to the direction the camera faces. Assuming the camera always faces the 12 o\\'clock position, the number entered for a moving individual should correspond with the clock position that the animal is moving towards. For example, if the animal travels from left to right, and the movement is perpendicular to where the camera faces, the direction of travel would be 3. The options include a number between 1 and 12 and VNA (variable not applicable).")
        },
        {
          name: 'useSpeciesCoatColor',
          label: this.$t('Coat Colour'),
          tooltip: this.$t('Coat Colour is a one-to-many field (choose all that apply) used to record the coat colour of individual mammals. The options include Beige, Brown, Black, Blonde, Cinnamon, Grey, Red, Orange, Yellow, White, Other, Unknown, and VNA (variable not applicable; default). Users can select VNA if they are not interested in collecting this information.')
        },
      
          {
          name: 'useSpeciesCoatAttributes',
          label: this.$t('Coat Attributes'),
          tooltip: this.$t("Coat Attributes is a one-to-many field (choose all that apply) used to record attributes of an individual mammal\\'s coat. The options include Spots, Speckled, Salt-pepper, Stripes, Cross-Phase, Other, Unknown, and VNA (variable not applicable; default). Users can select VNA if they are not interested in collecting this information. ")
        },
           {
          name: 'useSpeciesTineTracking',
          label: this.$t('Tine Tracking'),
          tooltip: this.$t('Antler Tine Tracking fields are used to document information about antler tines. Users will only be able to use this field for mammal species with antlers (e.g., Moose, White-tailed deer, etc.) when enabled.')
        },
           {
          name: 'useSpeciesHasCollar',
          label: this.$t('Has Collar'),
          tooltip: this.$t('Collar flags (True/False) are used to flag individuals affixed with a collar (e.g., a GPS collar).')
        },
            {
          name: 'useSpeciesHasTag',
          label: this.$t('Has Tag'),
          tooltip: this.$t('Ear tag flags (True/False) are used to flag individuals with ear tags.')
        },
          {
          name: 'useSpeciesInteractingWithHumanFeature',
          label: 'Interacting With Human Feature',
          tooltip: this.$t('Interacting with human feature flags (True/False) are used to flag individuals that are interacting with a human feature (e.g., digging in a compost pile).')
        },
      
       
     
        { 
          name: 'useSnowTracking',
          label: this.$t('Snow Tracking'),
          tooltip: this.$t('The "Snow Presence" flag is a binary field (True/False) that can be enabled to allow users to flag images where snow is present on the ground. If snow is in the air (snowing) but not on the ground, this field should be False. This field appears as a flag, where selecting the flag indicates a "True" condition and an unchecked flag indicates a "False" (default) condition.')
        },
        { 
            name: 'useSnowDepth',
            label: this.$t('Snow Depth'),
            tooltip: this.$t('Snow depth indicates the depth of snow at the distance at which the camera detects motion at the ground/snow surface level.')
        },
        { 
          name: 'useWaterDepth',
          label: this.$t('Water Depth'),
          tooltip: this.$t('Water depth indicates the depth of water at the distance at which the camera detects motion at the water’s surface.')
        },
        {
          name: 'useFire',
          label: this.$t('Fire'),
          tooltip: this.$t('Fire flags (True/False) are used to flag images where the camera was clearly triggered by fire. Note: there may be animals present in these images or not.')
        } ,
        {
          name: 'useNice',
          label: this.$t('Nice'),
          tooltip: this.$t('Nice flags (True/False) are used to highlight (flag) high-quality images so they are easier to find at a later date.')
        },
        {
          name: 'useFieldOfView',
          label: this.$t('Field of View'),
          tooltip: this.$t("Field of View (FOV) tags are used when an image reveals that the camera\\'s FOV has changed significantly (for >3 hours) compared to the camera setup FOV (see \"Camera range criteria\" in \"5.3 Image tagging methods\"). The image may be a motion or time-lapse image. Four FOV tags exist Within (default), Out of Range, END, and START. Importantly, the Within and Out of Range tags are automatically applied by default or based on the user\\'s application of the END and START tags. ")
        }
      
     
     
      
     
      ],
      INCLUDED_LIST: INCLUDED_LIST,
      EXCLUDED_LIST: EXCLUDED_LIST,
      presets: {
        selected: {
          list: [],
          species: []
        },
        list: []
      },
      loadedSpeciesOnce: false,
      newProject: null, // flag to keep track of original state. 
      selectedPresetId: null,
      showScientificName: false,
      presetLoading: false,
      speciesLoading: true,
      selectMultiple: false,
      dragSelect: false,
      url: API_URL + 'get-all-users-in-and-out-of-project',
      getProjectUrl: API_URL + 'get-project',
      projectUpdateUrl: API_URL + 'insert-update-project',
      listOrgUrl: API_URL + 'get-organization-summary',
      getAllSpecies: API_URL + 'get-project-species-details',
      addSpeciesUrl: API_URL + 'add-project-species',
      removeSpeciesUrl: API_URL + 'remove-project-species',
      getPresetUrl: API_URL + 'get-project-species-presets',
      applyPresetUrl: API_URL + 'apply-project-species-list-from-preset',
      getSpeciesFromPresetList: API_URL + 'get-species-list-for-preset',
      organizationList: [],
      species: null,
      formName: 'project',
      project: null,
      loading: false,
      usersNotInProject: [],
      usersInProject: [],
      recordingName: 'Project',
      message: '',
      selectedUser: null,
      showTab: 'project',
      errors: [],
      userOptionList: [],
      userGrantedList: [],
      doesDeploymentExistInProject: true, // if unknown use 1 so not able to edit,
      state: {
        disabledDates: {
        }
      },
      selectedSpecies: {
        leftList: new Map(),
        rightList: new Map()
      },
      filters: {
        leftList: {commonName: '', className: '', orderName: ''},
        rightList: {commonName: '', className: '', orderName: ''}
      },
      localOptions: {},
      autoTaggerMap: new Map()
      
    }
  },
  computed: {
    buttonStates () {
      return { project: {
        'Next': this.project && this.project.organizationId && this.project.fullNm && this.project.fullNm.length > 0
      },
      species: {
        'Next': this.species && this.species.Included && this.species.Included.length,
        'Prev': true
      },
      user: {
        'Prev': true,
        'Next': true
      },
      settings: {
        'Prev': true,
        'Next': true
      }
      }
    },
    presetsSelected() {
      return this.presets.selected.list;
    }
  },
  async created () {

 this.localOptions = copyObject(this.options);
    this.options.autoTagger.forEach((v,i) => {
        let temp = copyObject(v);
        temp.autoTaggerId = v.id;
        temp.projectId = this.projectId;
        temp.hideByDefault = false;

        this.localOptions.autoTagger[i] = temp;

    });

    this.$store.dispatch('Species/getAllSpecies');
    this.newProject = this.projectId == null;
    await this.loadProject(this.projectId);
    await this.loadOrganization();
    
    await this.getAllPresets(this.projectId)
    
   

    let date = new Date();
    date.setDate(date.getDate() - 1);
    this.state.disabledDates.to = date;
    let that = this;

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Shift') {
        that.selectMultiple = true;
      } 
    });
    document.addEventListener('keyup', function (event) {
      if (event.key === 'Shift') {
        that.selectMultiple = false;
      } 
    });
    document.getElementById('addProjectModal').addEventListener('mouseleave', e => {
      that.dragSelect = false;
    });
    window.addEventListener('blur', e => {
      that.selectMultiple = false;
      that.dragSelect = false;
    });

    let widget = document.getElementById('species-selection-widget');

    if (widget !== null) {
      widget.addEventListener('mousedown', e => {
        that.dragSelect = true;
      });
      widget.addEventListener('mouseup', e => {
        that.dragSelect = false;
      });
    }
  },
  watch: {
    showTab: function(newVal, oldVal) {
      if (newVal === 'species' && !this.loadedSpeciesOnce) {
        this.speciesLoading = true;
        this.getSpecies(this.projectId);
      }
    },  
    presetsSelected: function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.loadPresetPreview();
      }
    },
    selectedPresets: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      
      }
    },
    projectId: function (newVal, oldVal) {
      if (newVal !== -1) {
        this.switchProject();
      }
    },
    usersNotInProject: function () {
      this.userOptionList = [];
      this.usersNotInProject.map(x => this.userOptionList.push({id: x.user.id, name: x.user.name}));
      this.userOptionList.sort(function (a, b) {
        return (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
      });
    },
    usersInProject: function () {
      this.userGrantedList = [];
      this.usersInProject.map(x => this.userGrantedList.push(x));
      this.userGrantedList.sort(function (a, b) {
        return (a.user.name.toUpperCase() < b.user.name.toUpperCase() ? -1 : 1);
      });
    }
  },
  methods: {
    speciesSettingsSelectAllNone() {
      let inverse = null;
      this.useSpeciesSettings.forEach((v,i) => {
        if (inverse === null) inverse = this.project[v.name]?!this.project[v.name]:true;
        this.project[v.name] = inverse;
      }); 
    },
    loadPresetPreview() {
        if (this.presets.selected.list.length === 0) {
          this.presets.selected.species = [];
          return;
        }

        this.$http.post(this.getSpeciesFromPresetList, this.presets.selected.list).then(response => {
          if (!response.data || response.data.hasOwnProperty('error')) {
            
          } else {
            if (response.data.length > 0) { 
              this.presets.selected.species = response.data.map(specieId => {
                return this.$store.state.Species.species.find(species=> species.id === specieId);
              });
            } else {
              this.presets.selected.species = null;
            }

          }
        });
    },
    showPresetModal() {
      this.$modal.show('preset-modal');
    },
    closePresetModal() {
      // this.presets.selected =  {
      //     list: [],
      //     species: []
      //   };
      this.$set(this.presets, 'selected', {
          list: [],
          species: []
      });
      this.presets.selected.species = [];
      this.$modal.hide('preset-modal');
    },
    applyPreset () {
      let that = this;
      this.presetLoading = true;
      this.$http.post(this.applyPresetUrl + `?projectId=${this.projectId}`, this.presets.selected.list).then(response => {
        if (!response.data || response.data.hasOwnProperty('error')) {
          // this.presetList = [];
        } else {
          that.presetLoading = false;
          this.getSpecies(this.projectId);
        }
        this.getSpecies();
        this.$refs[INCLUDED_LIST].refresh();
        this.closePresetModal();
      });
    },
    async getAllPresets (projectId) {

      const removeNullChildrenProp = (object) => {

        object.label = object.userId;

        if (typeof object.children == 'undefined') 
          return object;

        if (object.children === null) {
          delete object.children;
         
        } else {
          object.children = object.children.map(child => removeNullChildrenProp(child));
        }
         return object;
      };

      if (projectId) {
        await this.$http.get(this.getPresetUrl + '?projectId=' + projectId).then(
          function (response) {
            if (!response.data || response.data.hasOwnProperty('error')) {
              this.presets.list = [];
            } else { 
              let data = [];
              response.data.forEach( obj => { data.push( removeNullChildrenProp(obj) )});
              this.presets.list = data;
            }
          }
        );
      }
    },
   
  
    selectAll (list) {
      this.$refs[list].selectAll();
    },
    unselectAll (list) {
      this.$refs[list].unselectAll();
    },
    moveSpecies (list) {
      let altList = list===this.EXCLUDED_LIST?this.INCLUDED_LIST:this.EXCLUDED_LIST;
      let action = list===this.EXCLUDED_LIST?this.addSpeciesUrl : this.removeSpeciesUrl;
      let that = this;
      let speciesArray = Array.from(this.$refs[list].getSelectedSpecies().keys());
      this.presetLoading = true;
      this.$http.post(action, {
        projectId: this.projectId,
        speciesIds: speciesArray
      }).then(response => {
          if ((response.body && response.body.message && response.body.message === "success")) {
            that.$refs[list].removeSpecies(speciesArray);
            that.$refs[altList].addSpecies(speciesArray);
    
          } else {
            alert("Something went wrong. Please contact support");
          }
          
          that.$refs[list].getSelectedSpecies().clear();
          that.$refs[list].clearFilter();
          that.$refs[list].refresh();

          that.$refs[altList].getSelectedSpecies().clear();
          that.$refs[altList].clearFilter();
          that.$refs[altList].refresh();
          this.presetLoading = false;
      });
      this.$forceUpdate();
    },
    includeSpecies () {
      this.moveSpecies(this.EXCLUDED_LIST);
    },
    excludeSpecies () {
      this.moveSpecies(this.INCLUDED_LIST);
    },
    getSpecies (projectId) {
      if (projectId) {
        this.presetLoading = true;
        this.$http.get(`${this.getAllSpecies}?projectId=${projectId}`).then(response => {
          this.species = response.data;
          this.species.Excluded = response.data.Excluded.map(exclude => this.$store.state.Species.species.find(specie=>specie.id === exclude.speciesId));
          this.species.Included = response.data.Included.map(include => ({
              ...this.$store.state.Species.species.find(specie=>specie.id === include.speciesId),
              exists: include.exists
            })
          );
          this.speciesLoading = false;
          this.loadedSpeciesOnce = true;
          this.presetLoading = false;
        });
      }
    },
    switchProject () {
      this.showTab = 'project';
      if (this.projectId !== null && this.projectId > 0) {
        if (this.projectId !== this.project.id) { // only clear messages when project id are different.
          this.errors = [];
        }
        this.loadProject(this.projectId); // need to reload to get user lists
      } else {
        this.errors = [];
        this.initProject();
      }
    },
    initProject () {
      this.project = {
        autoTaggerConfig: [],
        seriesGap: 120,
        sensorId: this.activeSensor,
        useStaffSetupAutoTag: true,
        defaultStorageId: 2,
        // shortNm: 'xx',
        //   visibility: 2,
        defaultXScale: 1,
        defaultYPixels: 244,
        defaultMinFreqHz: 0,
        defaultMaxFreqHz: 12000,
        useMonochrome: false,
        useLightMode: true,
        defaultMinMegaDetectorConfidence: 0.5,
        status: 2
      };
      this.doesDeploymentExistInProject = false;

      this.options.autoTagger.map(v=>{
        if (v.selectByDefaultOnProjectCreation) {
          this.project.autoTaggerConfig.push({
            "autoTaggerId": v.id,
            "hideByDefault": false
          });
        }
      });
    },
    autoTaggerIsChecked(taggerID) {
      return this.project.autoTaggerConfig.find(o=>o.autoTaggerId == taggerID)?true:false;
    },
    autoTaggerCheck(taggerID) {

      let exists = this.autoTaggerIsChecked(taggerID);
      if (exists) {
        this.project.autoTaggerConfig = this.project.autoTaggerConfig.filter(o=>o.autoTaggerId != taggerID);
      } else {
        let temp = this.project.autoTaggerConfig;
        let config = {
          autoTaggerId: taggerID,
          hideByDefault: false
        };

        if (!this.newProject) config.projectId = this.projectId;

        temp.push(config);
        this.project.autoTaggerConfig = temp;
      }

    },
    autoTaggerCheckHiddenByDefault(taggerID) {
      let config = this.project.autoTaggerConfig.find(o=>o.autoTaggerId == taggerID);
      
      config.hideByDefault = !config.hideByDefault;

    },
    autoTaggerIsCheckedHiddenByDefault(taggerID) {
       let config = this.project.autoTaggerConfig.find(o=>o.autoTaggerId == taggerID);
      return config && config.hideByDefault;
    },
    async loadOrganization () {
      await this.$http.get(this.listOrgUrl).then(
        function (response) {
          if (!response.data || response.data.hasOwnProperty('error')) {
            this.organizationList = [];
          } else {
            this.organizationList = response.data.results;
          }
        }
      );
    },
    async loadProject (projectId) {
      if (projectId) { // get projectId from database if id is provided.

        await this.$http.get(this.getProjectUrl, {params: {projectId}}).then(function(response) {

          if (response.body.error || response.data.error) {
              alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view);
              this.close();
            } else {
                this.project = response.body;
            }

        });

        await this.$http.get(this.url, {params: {sensor: this.activeSensor, projectId: projectId}}).then(
          function (response) {
            if (response.body.error || response.data.error) {
              alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view);
              this.close();
            } else {
              //this.project = response.data.project;
              this.usersNotInProject = response.data.usersNotInProject.map(u=>({...u, inProject: false, readonly: false}));
              this.usersInProject = response.data.usersInProject.map(u=>({...u, inProject: true, readonly: true}));
              this.doesDeploymentExistInProject = (response.data.doesDeploymentExistInProject != null ? response.data.doesDeploymentExistInProject : true);
              this.project.sensorId = this.activeSensor;
              delete this.project.year;

              if (!this.project.defaultStorage && this.project.sensorId === 'CAM') {
                this.project.defaultStorage = {};
              }
              //   this.localVisibility = this.project.visibility;
              //this.getSpecies(projectId);
            }
          },
          function (error) {
            alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view + error.body);
            this.close();
          }
        );
      } else {
        this.initProject();
        //      this.localVisibility = this.project.visibility;
      }
    },
    close () {
      this.errors = [];
      this.message = '';
      this.showTab = 'project';
      eventBus.$emit('close-project-form');
      this.$modal.hide('project');
    },
    async saveProject () {
      /* validation */
      this.errors = [];
      if (!this.project.fullNm) {
        this.errors.push(this.$t('projectForm-validName'));
      }

      if (!this.project.organizationId) {
        this.errors.push(this.$t('projectForm-validOrg'));
      }
      // if (!this.project.defaultStorageId) {
      //   this.errors.push('Project defaultStorage required.');
      // }

      if (this.errors.length > 0) {
        return;
      }
      const self = this;
      this.project.sensorId = this.activeSensor;
      this.loading = true;
      let update = this.projectId > 0;
      this.commonInsertUpdate(this.projectUpdateUrl, this.project, (update ? 'update' : 'add'), function (response) {
        self.project = {
          ...self.project,
          ...response.data
        };

        if (!self.projectId > 0) {
          self.errors = [self.$t('projectForm-createSuccess')];
        }
        eventBus.$emit('reload-table');
        eventBus.$emit('reload-project-form', response.data.id);
        eventBus.$emit('load-project-info');
        if (!update) {
          self.getAllPresets(self.project.id);
        }
      }).then(result => {
        if (this.showTab === 'project' && this.newProject) {
          this.showTab = 'species';
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    addUser (asAdmin, action) {
      if (this.project.id != null && this.selectedUser != null) {
     
        let newUsers = this.selectedUser.map(u => {
          return {
            user: { 
              id: u.id,
              name: u.name
            },
            admin: asAdmin }
        });
        const params = {
          projectId: this.project.id,
          users: newUsers,
          action: action
        };
        this.message = '';
        this.$http.post(API_URL + 'alter-project-users', params).then(
          function (response) {
            if (response.data.hasOwnProperty('error')) {
              this.errors.push(this.$t('projectForm-userAddError') + response.data.error);
            } else {
              newUsers.map(u => {
                this.usersInProject.push(u);
              });
              for (let i = this.usersNotInProject.length - 1; i >= 0; i--) {
                if (this.usersNotInProject[i].user.id === this.selectedUser.id) {
                  this.usersNotInProject.splice(i, 1);
                }
              }
              this.selectedUser = null;
              this.message = this.$t('projectForm-userAdded');
              eventBus.$emit('load-project-user');
            }
          });
      }
    },
    removeUser (option) {
      if (this.project.id != null) {
        if (!confirm(this.$t('projectForm-removeUserDialog', {user: option.user.name}))) {
          return;
        }
        const params = {
          projectId: this.project.id,
          users: [ option ],
          action: 'delete'
        };
        this.message = '';
        this.$http.post(API_URL + 'alter-project-users', params).then(
          function (response) {
            if (response.data.hasOwnProperty('error')) {
              this.errors.push(this.$t('projectForm-userRemoveError') + response.data.error);
            } else {
              this.usersNotInProject.push(option);
              for (let i = this.usersInProject.length - 1; i >= 0; i--) {
                if (this.usersInProject[i].user.id === option.user.id) {
                  this.usersInProject.splice(i, 1);
                  // break;
                }
              }
              this.message = this.$t('projectForm-userRemoved');
              eventBus.$emit('load-project-user');
            }
            // alert(response.data.result);
          });
      }
    }
  }
}
</script>
<style scoped>

.bottom-margin{
  margin-bottom: 20px;
}

</style>
