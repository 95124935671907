import {attachAuthorizaitonHeader, HOME_URL, ARU_PROJECT_URL, PROJECTS_PAGE_WITH_SENSOR_URL, ARU_RECORDING_URL, CAM_PROJECT_URL, PC_PROJECT_URL} from '@/lib/common.js';
import { createLink } from '@/lib/language.js';
/*
Contains functions used across several pages
For helper functions and static variables, see common.js
*/

export default {
  created () {
    attachAuthorizaitonHeader();
  },
  methods: {
    // breadcrumb links:
    getNextTaskUrl () {
      return createLink(ARU_RECORDING_URL + this.taskOverview.nextTaskId);
    },
    breadcrumbBaseLink () {
      return createLink(PROJECTS_PAGE_WITH_SENSOR_URL);
    },
    breadcrumbARUProjectLink () {
      return createLink(ARU_PROJECT_URL + this.taskOverview.projectId);
    },
    breadcrumbCAMSPPProjectLink () {
      return createLink(CAM_PROJECT_URL + (this.project ? this.project.id : '') + '&tab=species');
    },
    breadcrumbARUSPPProjectLink () {
      return createLink(ARU_PROJECT_URL + (this.project ? this.project.id : '') + '&tab=species');
    },
    breadcrumbCAMTAGGINGProjectLink () {
      return createLink(CAM_PROJECT_URL + (this.deployment ? this.deployment.project.id : ''));
    },
    breadcrumbSURVEYProjectLink () {
      return createLink(PC_PROJECT_URL + this.project.id);
    },
    breadcrumbHomeLink () {
      return createLink(HOME_URL);
    }
  },
  data () {
    return {}
  }
}
