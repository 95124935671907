<template>
  <div class="remote-camera camera-tagging no-top-sticky-menu">
    <div class="container error padded60" v-if="error != null">
      <span v-if="error.statusText != null">
        {{error.statusText}}
      </span>
      <span v-else>
        {{error}}
      </span>
    </div>
    <div class="warning" v-if="warning != null">
        {{warning}}
    </div>
    <div v-if="error == null">
      <div class="container large ">
          <div class="row breadCrumb">
              <div class="col s12">
                  <p><a :href="breadcrumbHomeLink()">{{$t('common-home')}}</a> / <a :href="breadcrumbBaseLink() + 'CAM'">{{$t('common-camera')}}</a> /
                  <a :href="breadcrumbCAMSPPProjectLink()">{{project && project.fullNm}}</a></p>
              </div>
          </div>
      </div>
      <div class="stickyWrap"  id="topStickyBar">
        <div id="topStickyBarContent" class="sticky" style="position:static; "
          v-sticky="{zIndex: 10, stickyTop: 0, stickTopOnly: true, positionType: 'fixed', stickyContainerId: 'topStickyBar'}"
          >
          <div class="container large taskHeader">
              <div class="row"  >
                  <div class="col s12 m12 l8 title">
                    <div>
                      <h1>{{speciesObj && speciesObj.commonName}}</h1>
                      <div class="actionGroup">
                          
                          <div class="editLink" @click="toggleDialog(ui.helpFormName, true)">
                              <span class="ion-help-circled"></span>{{$t('common-help')}}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="$refs.tag">
                  </div>
                  <div class="col s12 m12 l4 filters" v-if="projectSppInfo.canUserModify">
                    <div class="row">
                        <div class="col s6 offset-s6">
                            <button class="btn btnImportant fullWidth modal-trigger projectAdd" href="#tagCameraModal" v-if="selectedImageCount > 0"  @click="tagSelected()">
                            
                              <span class="ion-ios-pricetag-outline"></span> {{$t('common-tagSelected')}}
                                <span class="qty">{{selectedImageCount}}</span>
                            </button>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
           
        </div>
      </div>
    </div>
    <!-- tab area -->
    <div class="container overflow" style="margin-top: 20px;" v-if="error == null">
        <div class="padded boxed dataGroup">
           <tagging-filter v-if="staticOptions && projectId && speciesId"
            v-bind:static-options="staticOptions"
            :project="project"
            v-bind:mega-detector-categories="megaDetectorCategories"
            v-bind:species-id="speciesId"/>
          <tagging-gallery ref="tag"
          v-if="staticOptions && projectId"
            v-bind:project-id="projectId"
            
            v-bind:speciesObj ="speciesObj"
            v-bind:static-options="staticOptions"
            v-bind:is-tagger="projectSppInfo.canUserModify"
            v-bind:mega-detector-categories="megaDetectorCategories"
            :project="project"
            />
        </div>
    </div>
    <tagging-help v-bind:form-name="ui.helpFormName" />
    <v-dialog/>
  </div>
</template>

<script>
/* this component show is the camera tagging page.
*/
import Autocomplete from 'vuejs-auto-complete';
import {API_URL, CAM_PROJECT_URL, getParameterByName, getAuthorizaitonHeader} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import TaggingGallery from '@/components/camera/TaggingGallery';
import TaggingFilter from '@/components/camera/TaggingFilter';
import TaggingHelp from '@/components/camera/TaggingHelp';
import TaggingMixin from './TaggingMixin.js';
import CommonPageMixin from '@/components/common/CommonPageMixin';

export default {
  name: 'camera-spp-verification-page',
  mixins: [TaggingMixin, CommonPageMixin],
  components: {'auto-complete': Autocomplete, 'tagging-gallery': TaggingGallery, 'tagging-filter': TaggingFilter, 'tagging-help': TaggingHelp},
  computed: {
    /* attache headers for auto complete input */
    authHeaders () {
      const header = getAuthorizaitonHeader();
      return { 'Authorization': header };
    }
  },
  methods: {
  },
  created () {
    this.projectId = parseInt(getParameterByName('projectId'));
    if (!this.projectId) {
      this.error = this.$t('tasks-idError');
      return;
    }

    this.speciesId = parseInt(getParameterByName('speciesId'));
    if (!this.speciesId) {
      this.error = this.$t('common-missingSpecies');
      return;
    }

    this.loadStaticOption(false);
    /* get project details: name, year, description */
    this.$http.get(this.getProjectDescriptionUrl, {params: {projectId: this.projectId}}).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.project = response.data;
      }
      // this.deployment = response.data.projectUserDeploymentOverview;
    }, (err) => {
    // error callback
      this.error = err;
    });

    this.$http.get(this.getProjectSpeciesInfoUrl, {params: {projectId: this.projectId, speciesId: this.speciesId}}).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        this.projectSppInfo = response.data;
      }
      // this.deployment = response.data.projectUserDeploymentOverview;
    }, (err) => {
    // error callback
      this.error = err;
    });

    this.$http.get(this.getDeploymentStatusOptionUrl).then((response) => {
      if (response.data.hasOwnProperty('error')) {
        this.error = response.data.error;
      } else {
        //this.statusOptions = response.data.status;
        this.megaDetectorCategories = response.data.megaDetectorCategories;
      }
    }, (err) => {
    // error callback
      this.error = err;
    });

    const self = this;
    /* always have specie filtered */
    eventBus.$on('update-filter-flag', function (queryParam) {
      self.hasFilter = (Object.keys(queryParam).length > 1);
    });
    eventBus.$on('selectionImageCount-change', (num) => {
      this.selectedImageCount = num;
    });
  },
  data () {
    return {
      megaDetectorCategories: [],
      getDeploymentStatusOptionUrl: API_URL + 'get-camera-pud-options',
      projectPageUrl: CAM_PROJECT_URL,
      projectId: -1, // current deployment + user assignment id
      getDeploymentOptionUrl: API_URL + 'get-camera-project-user-deployment-options?id=', // dynamic options such as species name
      deploymentOptions: null,
      getProjectDescriptionUrl: API_URL + 'get-project',
      // getDeploymentOverviewUrl: API_URL + 'get-camera-project-user-deployment-overview?id=', // basic information about this deployment
      project: {},
      getProjectSpeciesInfoUrl: API_URL + 'get-project-species-info',
      projectSppInfo: {},
      // deployment: {},
      speciesId: [],
      hasFilter: false, // for UI, show whether filter has values;
      selectedImageCount: 0,
      ui: {
        activeTab: 'tag',
        showFilter: false,
        helpFormName: 'help-form',
        showSelectAll: true
      },
      error: null

    }
  }
}
</script>
<style scoped>
  .remote-camera .actionGroup .editLink.has-filter {
    color: #308233;
  }
  .padded.boxed.dataGroup {
    padding: 40px 20px;
  }
</style>
