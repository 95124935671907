var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.id,
        width: "80%",
        clickToClose: !_vm.uploading,
        height: "auto"
      },
      on: { closed: _vm.onClose }
    },
    [
      _c(
        "div",
        {
          class: { "p-5": true, "bg-slate-100": _vm.dragover },
          attrs: { id: "file-drag-drop" }
        },
        [
          _c(
            "form",
            {
              ref: "fileform",
              class: {
                "h-36 flex  items-center justify-center ": true,
                "": !_vm.dragover
              },
              on: {
                drop: function($event) {
                  $event.preventDefault()
                  return _vm.onDrop($event)
                },
                dragover: function($event) {
                  $event.preventDefault()
                  _vm.dragover = true
                },
                dragenter: function($event) {
                  $event.preventDefault()
                  _vm.dragover = true
                },
                dragleave: function($event) {
                  $event.preventDefault()
                  _vm.dragover = false
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "drop-files text-center flex-col flex" },
                [
                  _c("i", {
                    staticClass: "fa fa-cloud-arrow-up text-5xl block icon",
                    class: [_vm.dragover ? "mt-2, mb-6" : "mt-5"]
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("Drop your files here!")) + " or "),
                    _c(
                      "a",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.$refs.filebutton.click()
                          }
                        }
                      },
                      [_vm._v("browse for files")]
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("input", {
            ref: "filebutton",
            staticStyle: { visibility: "hidden" },
            attrs: { type: "file", multiple: _vm.multiple },
            on: { change: _vm.onFileDialog }
          }),
          _vm._v(" "),
          _vm.uploadedFiles.length > 0
            ? _c("div", { staticClass: "h-72 w-full overflow-y-scroll mt-5" }, [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { class: _vm.cellClasses() }),
                      _vm._v(" "),
                      _c("th", { class: _vm.cellClasses() }, [
                        _vm._v(_vm._s(_vm.$t("File Name")))
                      ]),
                      _vm._v(" "),
                      _c("th", { class: _vm.cellClasses() }, [
                        _vm._v(_vm._s(_vm.$t("Description")))
                      ]),
                      _vm._v(" "),
                      _c("th", { class: _vm.cellClasses("text-center") }, [
                        _vm._v(_vm._s(_vm.$t("Hidden")))
                      ]),
                      _vm._v(" "),
                      _c("th", { class: _vm.cellClasses("text-center") }, [
                        _vm._v(_vm._s(_vm.$t("Inc. in d/l")))
                      ]),
                      _vm._v(" "),
                      _c("th", { class: _vm.cellClasses() })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.uploadedFiles, function(file, key) {
                      return _c("tr", { staticClass: "relative" }, [
                        _c("td", { class: _vm.cellClasses() }, [
                          _vm.uploadStarted
                            ? _c("span", [
                                !_vm.uploadStatus[file.file.name].success &&
                                !_vm.uploadStatus[file.file.name].error
                                  ? _c("i", {
                                      staticClass: "fa fa-spinner fa-spin"
                                    })
                                  : _vm.uploadStatus[file.file.name].success &&
                                    !_vm.uploadStatus[file.file.name].error
                                  ? _c("i", { staticClass: "fa fa-check" })
                                  : !_vm.uploadStatus[file.file.name].success &&
                                    _vm.uploadStatus[file.file.name].error
                                  ? _c("i", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            _vm.uploadStatus[file.file.name]
                                              .error,
                                          expression:
                                            "uploadStatus[file.file.name].error"
                                        }
                                      ],
                                      staticClass:
                                        "fa-solid fa-triangle-exclamation"
                                    })
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { class: _vm.cellClasses() }, [
                          _vm._v(_vm._s(file.file.name))
                        ]),
                        _vm._v(" "),
                        _c("td", { class: _vm.cellClasses() }, [
                          _c("input", {
                            staticClass: "!mb-0 !border-0",
                            attrs: {
                              disabled: _vm.uploading || _vm.uploaded,
                              type: "text",
                              placeholder: _vm.$t("Describe file")
                            },
                            on: {
                              blur: e =>
                                _vm.setProp(file, "description", e.target.value)
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", { class: _vm.cellClasses("text-center") }, [
                          !_vm.uploading && !_vm.uploaded
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "cursor-pointer flex-none !text-gray-500",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleProp(file, "hidden")
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    class: {
                                      "fa-regular fa-square": !file.hidden,
                                      "fa fa-check": file.hidden
                                    }
                                  })
                                ]
                              )
                            : _c("a", { staticClass: "!text-gray-500" }, [
                                _c("i", {
                                  class: {
                                    "fa-regular fa-square": !file.hidden,
                                    "fa fa-check": file.hidden
                                  }
                                })
                              ])
                        ]),
                        _vm._v(" "),
                        _c("td", { class: _vm.cellClasses("text-center") }, [
                          !_vm.uploading && !_vm.uploaded
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "cursor-pointer flex-none !text-gray-500",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleProp(
                                        file,
                                        "includeInDownload"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    class: {
                                      "fa-regular fa-square": !file.includeInDownload,
                                      "fa fa-check": file.includeInDownload
                                    }
                                  })
                                ]
                              )
                            : _c("a", { staticClass: "!text-gray-500" }, [
                                _c("i", {
                                  class: {
                                    "fa-regular fa-square": !file.includeInDownload,
                                    "fa fa-check": file.includeInDownload
                                  }
                                })
                              ])
                        ]),
                        _vm._v(" "),
                        _c("td", { class: _vm.cellClasses("text-center") }, [
                          !_vm.uploading && !_vm.uploaded
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "remove flex-none !text-gray-500 cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.removeFile(file.file.name)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              )
                            : _c("a", { staticClass: "!text-gray-500" }, [
                                _c("i", { staticClass: "fa fa-trash" })
                              ])
                        ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.uploadedFiles.length > 0
            ? _c(
                "div",
                { staticClass: "footer mt-5 flex gap-1 items-center" },
                [
                  _c("div", { staticClass: "grow" }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.uploaded,
                          expression: "!uploaded"
                        }
                      ],
                      staticClass: "btn btn-success flex-initial",
                      attrs: { disabled: _vm.uploading },
                      on: { click: _vm.submit }
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.uploading,
                            expression: "uploading"
                          }
                        ],
                        staticClass: "fa fa-spinner text-base fa-spin"
                      }),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.uploading
                              ? `${_vm.$t("Uploading")} ${_vm.numUploaded} of ${
                                  _vm.uploadedFiles.length
                                } `
                              : _vm.$t("Upload")
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.uploaded && !_vm.uploadedErrors
                    ? _c(
                        "div",
                        { staticClass: "px-10 font-bold text-success" },
                        [_vm._v(_vm._s(_vm.$t("Upload Complete!")))]
                      )
                    : _vm.uploaded && _vm.uploadedErrors
                    ? _c(
                        "div",
                        { staticClass: "px-10 font-bold text-burntOrange" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("There were issues uploading.")) +
                              "\n              "
                          ),
                          _c("a", { on: { click: _vm.showErrorDialog } }, [
                            _vm._v(_vm._s(_vm.$t("Click to Show Issues")))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-link",
                      attrs: { disabled: _vm.uploading },
                      on: { click: _vm.onClearAll }
                    },
                    [_vm._v(_vm._s(_vm.$t("Reset")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn flex-initial",
                      attrs: { disabled: _vm.uploading },
                      on: { click: _vm.closeDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("Close")))]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { name: "errors", height: "auto" },
          on: { closed: _vm.onErrorModalClose }
        },
        [
          _c("div", { staticClass: "p-3" }, [
            _c("h3", [_vm._v("Upload Errors")]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-5 h-48 overflow-y-scroll" }, [
              _c(
                "ul",
                _vm._l(_vm.errors, function(error, key) {
                  return _c(
                    "li",
                    { staticClass: "border-b border-slate-100 py-2" },
                    [_vm._v(_vm._s(error))]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn w-full",
                on: {
                  click: function($event) {
                    return _vm.$modal.hide("errors")
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }