var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "tagform",
      staticClass: "col s12 m12 l4 xl3",
      attrs: { id: "infoPanel" }
    },
    [
      _vm.showTagForm && _vm.tag != null
        ? _c(
            "div",
            {
              staticClass: "panel ltgrey padded40",
              staticStyle: { display: "block" },
              attrs: { id: "newSpecies" }
            },
            [
              _c("span", {
                staticClass: "ion-close closeButton",
                on: { click: _vm.closeTagForm }
              }),
              _vm._v(" "),
              _vm.tagId <= 0
                ? _c("h3", [_vm._v(_vm._s(_vm.$t("recordingForms-addNewTag")))])
                : _vm._e(),
              _vm._v(" "),
              _vm.tagId > 0
                ? _c("h3", [_vm._v(_vm._s(_vm.$t("recordingForms-updateTag")))])
                : _vm._e(),
              _vm._v(" "),
              _vm.showDuplicateForm && !_vm.disableDuplicateForm
                ? _c("div", { staticClass: "duplicateForm" }, [
                    _c("div", [
                      _c("label", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("recordingForms-duplicateTag"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.duplicateSppId,
                              expression: "duplicateSppId"
                            }
                          ],
                          ref: "duplicatedSppInput",
                          staticClass: "initialized",
                          attrs: { type: "number" },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.duplicateSppId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.onDupeTagSelect($event.target)
                              }
                            ]
                          }
                        },
                        _vm._l(_vm.speciesIndividuals, function(option, index) {
                          return _c(
                            "option",
                            {
                              key: "duplicatspp" + index,
                              attrs: { value2: option.speciesCode },
                              domProps: { value: option.id }
                            },
                            [
                              _vm._v(
                                _vm._s(option.speciesCode) +
                                  " | " +
                                  _vm._s(option.uniqueName)
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "invalid_msg" }, [
                        _vm._v(_vm._s(_vm.duplicateFormError))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("recordingForms-vocalizationIfDiffers"))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputVocalizationId,
                              expression: "inputVocalizationId"
                            }
                          ],
                          staticClass: "initialized",
                          attrs: {
                            disabled: _vm.tagOptions.vocalization.length == 0,
                            type: "number"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.inputVocalizationId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            { attrs: { disabled: "", selected: "" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("recordingForms-chooseType"))
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.tagOptions.vocalization, function(
                            option,
                            index
                          ) {
                            return [
                              !option.deprecated
                                ? _c(
                                    "option",
                                    {
                                      key: "vol" + index,
                                      domProps: { value: option.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(option.type)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                      _c("label", { attrs: { for: "needsreview" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputNeedsReview,
                              expression: "inputNeedsReview"
                            }
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "needsreview",
                            disabled: !_vm.isEditable,
                            name: "needsreview"
                          },
                          domProps: {
                            checked: Array.isArray(_vm.inputNeedsReview)
                              ? _vm._i(_vm.inputNeedsReview, null) > -1
                              : _vm.inputNeedsReview
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.inputNeedsReview,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.inputNeedsReview = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.inputNeedsReview = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.inputNeedsReview = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { class: { inactive: !_vm.isEditable } }, [
                          _vm._v(_vm._s(_vm.$t("common-needsReview")))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col s6 m6 l12 xl6" }, [
                        _vm.isEditable
                          ? _c("input", {
                              staticClass: "btn btn-success fullWidth",
                              attrs: {
                                type: "button",
                                id: "btnLock",
                                value: _vm.$t("recordingForms-duplicate")
                              },
                              on: {
                                click: () => {
                                  _vm.duplicateTag()
                                }
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s6 m6 l12 xl6" }, [
                        _vm.isEditable
                          ? _c("input", {
                              staticClass: "btn btn-action fullWidth",
                              attrs: {
                                type: "button",
                                id: "btnLock",
                                value: _vm.$t("recordingForms-new")
                              },
                              on: { click: _vm.switchToNewForm }
                            })
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showDuplicateForm || _vm.disableDuplicateForm,
                      expression: "!showDuplicateForm || disableDuplicateForm"
                    }
                  ],
                  staticClass: "newForm autofilterInput"
                },
                [
                  _c(
                    "div",
                    [
                      !_vm.editParent || !_vm.isEditable
                        ? _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("recordingForms-numTaggedOnIndividual", {
                                  num: _vm.tag.speciesIndividualTagCount
                                })
                              ) + " "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "active",
                          attrs: { for: "autocomplete-input" }
                        },
                        [_vm._v(_vm._s(_vm.$tc("common-species", 1)))]
                      ),
                      _vm._v(" "),
                      !_vm.editParent || !_vm.isEditable
                        ? _c("span", { staticClass: "displayValue" }, [
                            _vm._v(
                              ":" +
                                _vm._s(
                                  _vm.species[
                                    _vm.tag.speciesIndividual.speciesId
                                  ].code
                                ) +
                                " | " +
                                _vm._s(
                                  _vm.species[
                                    _vm.tag.speciesIndividual.speciesId
                                  ].commonName
                                )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("auto-complete", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editParent && _vm.isEditable,
                            expression: "editParent && isEditable"
                          }
                        ],
                        ref: "speciesInput",
                        attrs: {
                          placeholder: _vm.$t(
                            "recordingForms-spaceListsAllSpecies"
                          ),
                          source: _vm.getSpeciesAPIUrl,
                          initialValue: "",
                          initialDisplay: "",
                          name: "code",
                          resultsValue: "code",
                          "results-display": _vm.formattedSpeciesDisplay,
                          clearButtonIcon: "ion-close",
                          excludeCharacter: "|",
                          "request-headers": _vm.authHeaders,
                          "custom-result": _vm.autoIdSpecies
                        },
                        on: {
                          selected: _vm.setSpeciesId,
                          clear: _vm.clearSpeciesInput,
                          focus: _vm.appendAutoSpeciesIds
                        }
                      }),
                      _vm._v(" "),
                      _vm.editParent && _vm.invalidSpecies
                        ? _c("span", { staticClass: "invalid_msg" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("recordingForms-enterValidSpeciesName")
                              ) + " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", [
                      _vm._v(
                        _vm._s(_vm.$t("recordingForms-individualOptional"))
                      ),
                      _c("span", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.$t(
                              "recordingForms-tooltips.autogenIndividual"
                            ),
                            expression:
                              "$t('recordingForms-tooltips.autogenIndividual')"
                          }
                        ],
                        staticClass: "ion-information-circled"
                      })
                    ]),
                    _vm._v(" "),
                    !_vm.editParent || !_vm.isEditable
                      ? _c("span", { staticClass: "displayValue" }, [
                          _vm._v(
                            ": " + _vm._s(_vm.tag.speciesIndividual.uniqueName)
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editParent && _vm.isEditable
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.tag.speciesIndividual.uniqueName,
                              expression: "tag.speciesIndividual.uniqueName",
                              modifiers: { number: true }
                            }
                          ],
                          attrs: {
                            type: "number",
                            placeholder: _vm.$t(
                              "recordingForms-individualAutoGenerated"
                            ),
                            step: "1",
                            min: "0",
                            max: "100",
                            disabled:
                              _vm.tag.speciesIndividual &&
                              this.species &&
                              this.species[
                                _vm.tag.speciesIndividual.speciesId
                              ] &&
                              this.species[_vm.tag.speciesIndividual.speciesId]
                                .allowMultipleIndividuals === false
                          },
                          domProps: {
                            value: _vm.tag.speciesIndividual.uniqueName
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.tag.speciesIndividual,
                                  "uniqueName",
                                  _vm._n($event.target.value)
                                )
                              },
                              _vm.loadSppIndividualInfo
                            ],
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editParent && _vm.tagId && _vm.existingIndividual
                      ? _c("span", { staticClass: "invalid_msg" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("recordingForms-individualExistsWarning")
                            )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editParent && !_vm.tagId && _vm.existingIndividual
                      ? _c("span", { staticClass: "invalid_msg" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "recordingForms-individualExistsWillUpdate"
                              )
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("recordingForms-abundance")) + " "
                        ),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.$t(
                                "recordingForms-tooltips.abundance"
                              ),
                              expression:
                                "$t('recordingForms-tooltips.abundance')"
                            }
                          ],
                          staticClass: "ion-information-circled"
                        })
                      ]),
                      _vm._v(" "),
                      !_vm.editParent || !_vm.isEditable
                        ? _vm._l(_vm.tagOptions.abundance, function(
                            option,
                            index
                          ) {
                            return _c("span", { key: "abund" + index }, [
                              option.id ===
                              _vm.tag.speciesIndividual.abundanceId
                                ? _c("span", { staticClass: "displayValue" }, [
                                    _vm._v(_vm._s(option.type))
                                  ])
                                : _vm._e()
                            ])
                          })
                        : [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.tag.speciesIndividual.abundanceId,
                                    expression:
                                      "tag.speciesIndividual.abundanceId"
                                  }
                                ],
                                staticClass: "initialized",
                                attrs: { type: "number" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.tag.speciesIndividual,
                                      "abundanceId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.tagOptions.abundance, function(
                                option,
                                index
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: "abundanceSel" + index,
                                    domProps: { value: option.id }
                                  },
                                  [_vm._v(_vm._s(option.type))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.tagOptionsError
                              ? _c("span", { staticClass: "invalid_msg" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "recordingForms-unableToGetAbundance"
                                      )
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.editParentOnly
                    ? _c(
                        "div",
                        [
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("recordingForms-vocalization"))
                            )
                          ]),
                          _vm._v(" "),
                          !_vm.isEditable
                            ? _vm._l(_vm.tagOptions.vocalization, function(
                                option,
                                index
                              ) {
                                return _c("span", { key: "vocal" + index }, [
                                  option.id === _vm.tag.vocalizationId
                                    ? _c(
                                        "span",
                                        { staticClass: "displayValue" },
                                        [_vm._v(_vm._s(option.type))]
                                      )
                                    : _vm._e()
                                ])
                              })
                            : [
                                _vm.isEditable
                                  ? _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.tag.vocalizationId,
                                            expression: "tag.vocalizationId"
                                          }
                                        ],
                                        staticClass: "initialized",
                                        attrs: { type: "number" },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.tag,
                                              "vocalizationId",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._l(
                                          _vm.tagOptions.vocalization,
                                          function(option, index) {
                                            return [
                                              !option.deprecated ||
                                              _vm.isVocalizationDeprecated
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: "vocalSel" + index,
                                                      attrs: {
                                                        disabled:
                                                          option.deprecated
                                                      },
                                                      domProps: {
                                                        value: option.id
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.type)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.tagOptionsError
                                  ? _c("span", { staticClass: "invalid_msg" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "recordingForms-unableToGetVocalization"
                                          )
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ],
                          _vm._v(" "),
                          _vm.formIncomplete
                            ? _c("span", { staticClass: "invalid_msg" }, [
                                _vm._v(_vm._s(this.incompleteTagMsg))
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { attrs: { for: "needsreview" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tag.needsReview,
                            expression: "tag.needsReview"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "needsreview",
                          disabled: !_vm.isEditable,
                          name: "needsreview"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.tag.needsReview)
                            ? _vm._i(_vm.tag.needsReview, null) > -1
                            : _vm.tag.needsReview
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.tag.needsReview,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.tag,
                                    "needsReview",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.tag,
                                    "needsReview",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.tag, "needsReview", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { class: { inactive: !_vm.isEditable } }, [
                        _vm._v(_vm._s(_vm.$t("common-needsReview")))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", [
                      _vm._v(" " + _vm._s(_vm.$t("common-comments")))
                    ]),
                    _vm._v(" "),
                    !_vm.editParent || !_vm.isEditable
                      ? _c("span", { staticClass: "displayValue" }, [
                          _vm._v(_vm._s(_vm.tag.speciesIndividual.comments))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.editParent && _vm.isEditable
                      ? _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tag.speciesIndividual.comments,
                              expression: "tag.speciesIndividual.comments"
                            }
                          ],
                          domProps: {
                            value: _vm.tag.speciesIndividual.comments
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.tag.speciesIndividual,
                                "comments",
                                $event.target.value
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "divider" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s6 m6 l12 xl6" }, [
                      _vm.isEditable
                        ? _c("input", {
                            staticClass: "btn btn-action fullWidth",
                            attrs: {
                              type: "button",
                              value: _vm.$t("common-save")
                            },
                            on: {
                              click: () => {
                                _vm.insertUpdateTag()
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.tagId > 0
                      ? _c("div", { staticClass: "col s4 m6 l12 xl6" }, [
                          _vm.isEditable
                            ? _c("input", {
                                staticClass: "btn btn-outline fullWidth w-full",
                                attrs: {
                                  type: "button",
                                  value: _vm.$t("common-delete")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeTag()
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col s6 m6 l12 xl6" }, [
                      _c("input", {
                        staticClass: "btn btn-border fullWidth",
                        attrs: {
                          type: "button",
                          value: _vm.$t("common-cancel")
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeTagForm(false)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.tag.speciesIndividualTagCount > 1
                      ? _c("div", { staticClass: "col s4" }, [
                          !_vm.editParent && _vm.isEditable
                            ? _c("input", {
                                staticClass: "btn btn-action fullWidth",
                                attrs: {
                                  type: "button",
                                  id: "btnLock",
                                  value: _vm.$t("recordingForms-editAllFields")
                                },
                                on: {
                                  click: function($event) {
                                    _vm.editParent = true
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editParent && _vm.isEditable
                            ? _c("input", {
                                staticClass: "btn btn-action fullWidth",
                                attrs: {
                                  type: "button",
                                  id: "btnLock",
                                  value: _vm.$t(
                                    "recordingForms-editCurrentFields"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    _vm.editParent = false
                                  }
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "panel ltgrey padded40" }, [
        _c("span", {
          staticClass: "ion-close closeButton",
          on: {
            click: function($event) {
              return _vm.toggleTaskForm(false)
            }
          }
        }),
        _vm._v(" "),
        _c("h3", [_vm._v(_vm._s(_vm.$t("recordingForms-taskInfo")))]),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v(" " + _vm._s(_vm.$t("recordingForms-rain")))]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.task.rainId,
                  expression: "task.rainId"
                }
              ],
              staticClass: "initialized",
              attrs: { disabled: !_vm.modify, type: "number" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.task,
                    "rainId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "-100", disabled: "", selected: "" } },
                [_vm._v(_vm._s(_vm.$t("recordingForms-level")))]
              ),
              _vm._v(" "),
              _vm._l(_vm.options.rain, function(option, index) {
                return _c(
                  "option",
                  { key: "rainSel" + index, domProps: { value: option.id } },
                  [_vm._v(_vm._s(option.type))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v(" " + _vm._s(_vm.$t("recordingForms-wind")))]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.task.windId,
                  expression: "task.windId"
                }
              ],
              staticClass: "initialized",
              attrs: { disabled: !_vm.modify, type: "number" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.task,
                    "windId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "-100", disabled: "", selected: "" } },
                [_vm._v(_vm._s(_vm.$t("recordingForms-level")))]
              ),
              _vm._v(" "),
              _vm._l(_vm.options.wind, function(option, index) {
                return _c(
                  "option",
                  { key: "windSel" + index, domProps: { value: option.id } },
                  [_vm._v(_vm._s(option.type))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("recordingForms-industry")))
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.task.industrialNoiseId,
                  expression: "task.industrialNoiseId"
                }
              ],
              staticClass: "initialized",
              attrs: { disabled: !_vm.modify, type: "number" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.task,
                    "industrialNoiseId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "-100", disabled: "", selected: "" } },
                [_vm._v(_vm._s(_vm.$t("recordingForms-level")))]
              ),
              _vm._v(" "),
              _vm._l(_vm.options.industrialNoise, function(option, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: option.id } },
                  [_vm._v(_vm._s(option.type))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v(" " + _vm._s(_vm.$t("recordingForms-noise")))]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.task.otherNoiseId,
                  expression: "task.otherNoiseId"
                }
              ],
              staticClass: "initialized",
              attrs: { disabled: !_vm.modify, type: "number" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.task,
                    "otherNoiseId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "-100", disabled: "", selected: "" } },
                [_vm._v(_vm._s(_vm.$t("recordingForms-level")))]
              ),
              _vm._v(" "),
              _vm._l(_vm.options.noise, function(option, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: option.id } },
                  [_vm._v(_vm._s(option.type))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [
            _vm._v(" " + _vm._s(_vm.$t("recordingForms-audioQuality")))
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.task.audioQualityId,
                  expression: "task.audioQualityId"
                }
              ],
              staticClass: "initialized",
              attrs: { disabled: !_vm.modify, type: "number" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.task,
                    "audioQualityId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "-100", disabled: "", selected: "" } },
                [_vm._v(_vm._s(_vm.$t("recordingForms-areThereIssues")))]
              ),
              _vm._v(" "),
              _vm._l(_vm.options.audioQuality, function(option, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: option.id } },
                  [_vm._v(_vm._s(option.type))]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v(" " + _vm._s(_vm.$t("common-comments")))]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.task.comments,
                expression: "task.comments"
              }
            ],
            attrs: { disabled: !_vm.modify },
            domProps: { value: _vm.task.comments },
            on: {
              input: function($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.task, "comments", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "invalid_msg" }, [
            _vm._v(_vm._s(_vm.taskValidateMessage))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "invalid_msg" }, [
            _vm._v(_vm._s(_vm.taskUpdateMessage))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s6" }, [
            _vm.modify
              ? _c("input", {
                  staticClass: "btn btn-success fullWidth",
                  attrs: { id: "btnSubmit", type: "submit" },
                  domProps: { value: _vm.$t("common-update") },
                  on: { click: _vm.updateTask }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col s6" }, [
            _c("input", {
              staticClass: "btn btn-border fullWidth",
              attrs: { type: "button", value: _vm.$t("common-close") },
              on: {
                click: function($event) {
                  return _vm.toggleTaskForm(false)
                }
              }
            })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }