/* place cdn included library initaliztion here, to avoid errors
  from eslint, this folder is ignored from eslint
*/
export function setJwplayer(divId, options) {
  jwplayer(divId).setup(options);
  return jwplayer(divId);

}
// jwplayer(that.playerDiv).setup({
//   file: that.recording.url,
//   // testing mp4 file: 'https://www2.iis.fraunhofer.de/AAC/ChID-BLITS-EBU-Narration441AOT2.mp4',
//   height: 24,
//   width: 310,
//   title: '',
//   autostart: false,
//   primary: 'html5',
//   flashplayer: '/.resources/wildtrax-content/webresources/bundle/recording-page/jwplayer.flash.swf',
//   html5player: '/.resources/wildtrax-content/webresources/bundle/recording-page/jwplayer.html5.js',
//   repeat: false
// });
// that.jwPlayer = jwplayer(that.playerDiv);

export function createDhtmlxLayout (container, layout) {
  // new dhtmlXLayoutObject(this.$refs.container, '2U');
  return new dhtmlXLayoutObject(container, layout);
}

export function createdataProcessor (apiUrl) {
  return new dataProcessor(apiUrl);
}

export function toggleSubMenu () {
  toggleMenu ('.abmi ');
}