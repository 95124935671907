var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.creatingVisit && !_vm.uuid
        ? _c(
            "div",
            { staticClass: "iconButton link", on: { click: _vm.close } },
            [_c("span", { staticClass: "ion-close" })]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.creatingVisit
        ? _c(
            "div",
            {
              staticClass: "modal-header",
              class: { "drag-handle": !_vm.uuid }
            },
            [
              _c(
                "h5",
                [
                  _vm.uuid
                    ? [_vm._v(_vm._s(_vm.$t("singleVisitTable-visitDates")))]
                    : [
                        _vm._v(
                          _vm._s(_vm.$t("singleVisitTable-visitMetadata")) +
                            " - " +
                            _vm._s(_vm.locationLabel)
                        )
                      ]
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col s12 dataGroup location-visit tab-content",
          class: {
            boxed: !_vm.creatingVisit,
            panel: !_vm.uuid,
            padded40: !_vm.uuid,
            "report-table": _vm.uuid
          }
        },
        [
          _vm.isEditable
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success locationSingleVisitAdd",
                  on: {
                    click: function($event) {
                      return _vm.updateLocationSingleVisit(null)
                    }
                  }
                },
                [
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltips.addVisit,
                        expression: "tooltips.addVisit"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("singleVisitTable-addVisit"))
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          (!_vm.creatingVisit && _vm.tableOptionLoaded) ||
          (_vm.creatingVisit && _vm.tableOptionLoaded && _vm.locationId)
            ? _c("v-server-table", {
                ref: "table",
                staticClass: "table vue-bordered org-table",
                attrs: {
                  url: _vm.url,
                  columns: _vm.columns,
                  options: _vm.tableOptions
                },
                on: {
                  loaded: _vm.collectVisitInfo,
                  "row-click": _vm.onRowClick
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selected",
                      fn: function(props) {
                        return [
                          _c("span", {
                            class: {
                              "ion-checkmark  bright-red-font":
                                props.row.id == _vm.tableOptions.activeRowId,
                              "ion-android-radio-button-off":
                                props.row.id != _vm.tableOptions.activeRowId
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "hasImage",
                      fn: function(props) {
                        return [
                          props.row.hasImage
                            ? [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("common-yes")) +
                                    "\n        "
                                )
                              ]
                            : [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("common-no")) +
                                    "\n        "
                                )
                              ]
                        ]
                      }
                    },
                    {
                      key: "id",
                      fn: function(props) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass:
                                "editProject modal-trigger projectAdd",
                              on: {
                                click: function($event) {
                                  return _vm.updateLocationSingleVisit(
                                    props.row
                                  )
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "link",
                                class: {
                                  "ion-edit": _vm.isEditable,
                                  "ion-eye": !_vm.isEditable
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "editProject modal-trigger projectAdd",
                              on: {
                                click: function($event) {
                                  return _vm.deleteLocationSingleVisit(
                                    props.row
                                  )
                                }
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "link",
                                class: { "ion-ios-trash": _vm.isEditable }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1798532852
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.errors, function(error, index) {
            return _c("div", { key: index, staticClass: "warning" }, [
              _vm._v(" " + _vm._s(error) + " ")
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "error" }, [
            _vm._v(" " + _vm._s(_vm.message) + " ")
          ]),
          _vm._v(" "),
          (!_vm.creatingVisit &&
            !_vm.uuid &&
            _vm.tableOptionLoaded &&
            _vm.options.deployVisitId &&
            _vm.options.deployVisitId.length > 0) ||
          (_vm.creatingVisit &&
            !_vm.uuid &&
            _vm.tableOptionLoaded &&
            _vm.locationId)
            ? _c("visit-equipment-table", {
                attrs: {
                  "organization-id": _vm.organizationId,
                  "location-label": _vm.locationLabel,
                  "visit-id": _vm.tableOptions.activeRowId,
                  "location-id": _vm.locationId,
                  options: _vm.options,
                  "is-editable": _vm.isEditable,
                  "creating-visit": _vm.creatingVisit
                }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "visit-detail-form",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "75%",
            height: 620,
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _c("single-visit-form", {
            ref: "singleVisitForm",
            attrs: {
              "is-editable": _vm.isEditable,
              options: _vm.options,
              "init-visit-row": _vm.visitRow,
              "location-label": _vm.locationLabel,
              "location-id": _vm.locationId
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }