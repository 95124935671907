<template>
  <section>
    <div class="container overflow ddMapSelect vue">
      <div class="row flexRow">
        <div class="col s12 m12 l5 xl4 boxed padded40 tabbed">
          <h4>{{$t('dataDiscover-exploreData')}}</h4>
          <p>{{$t('dataDiscover-instructions')}} </p>
            <!-- or use the Map Selection Tool (<span class="ion-android-contract"></span>) in the top right corner to begin defining your area of interest. -->
          <ul class="tabs">
            <li v-cloak v-for="(mode, index) in queryModes" :key="'sensor' + index" class="tab"
            v-bind:class="{active: (activeMode.id === mode.id), [mode.className]: true}">
              <a class="hyperlink"  @click="activeMode = mode">
                <span :class="{[mode.icon]: true}"></span>
                {{mode && mode.label}}
              </a>
            </li>
          </ul>
          <div class="ddFieldsWrap boxed padded20 tab-content" :class="{[activeMode.className]: true}">
            <div class="ddFields row" v-if="activeMode.id =='basic'">
              <div class="input-field col s12">
                <div class="select-wrapper">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="rawQuery.organizationIds" :options="organization" :multiple="true" :close-on-select="false"
                    :placeholder="$tc('common-organization', 2)" label="name" track-by="id" selectLabel="" deselectLabel=" " class="input" :showSelectAll="true"
                    @input="loadOptions('sensor')">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <!-- combined the condition here, no matching one will use default, if use mulitple span, default will be empty -->
                      <span class="multiselect__single" v-if="values && ((values.length > 1 && !isOpen) || (values.length > 5 && isOpen))">{{ $t('dataDiscover-organizationsSelected', {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                </div>
                <label>{{$tc('common-organization', 1)}}</label>
              </div>
              <div class="input-field col s12">
                <div class="select-wrapper">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="rawQuery.sensors" :options="sensor" :multiple="true" :close-on-select="false"
                    selectLabel="" deselectLabel=" " class="input" :disabled="!rawQuery.organizationIds || rawQuery.organizationIds.length === 0"
                    :loading="optionLoading.sensor"
                    :placeholder="$tc('common-sensor', 2)" label="label" track-by="id" :showSelectAll="true"
                    @input="loadOptions('project')">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values && values.length > 1 && !isOpen">{{ $t('dataDiscover-sensorsSelected', {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                </div>
                <label>{{$tc('common-sensor', 1)}}</label>
              </div>
              <div class="input-field col s12">
                <div class="select-wrapper">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="rawQuery.projectIds" :options="project" :multiple="true" :close-on-select="false"
                  :placeholder="$t('dataDiscover-filterProjects')" label="projectDisplayNm" track-by="projectId" :project="true" selectLabel="" deselectLabel=" "
                  :disabled="!rawQuery.sensors || rawQuery.sensors.length === 0"
                  class="input" :showSelectAll="true"
                  >
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values && ((values.length > 1 && !isOpen) || (values.length > 5 && isOpen))">{{ $t('dataDiscover-projectsSelected', {num: values.length}) }}</span>
                  </template>
                </multiselect>
                </div>
                <label>{{$tc('common-project', 1)}}</label>
              </div>
              <div class="input-field col s12">
                <div class="select-wrapper">
                  <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="rawQuery.startDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></date-picker>
                </div>
                <label>{{$t('common-startDate')}}</label>
              </div>
              <div class="input-field col s12">
                <div class="select-wrapper">
                  <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="rawQuery.endDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></date-picker>
                </div>
                <label>{{$t('common-endDate')}}</label>
              </div>
              <!--<div class="input-field col s12 m6"  v-if="false">
                <div class="select-wrapper">
                  <date-picker type="time" v-model="rawQuery.time" format="HH:mm" value-type="HH:mm:ss"></date-picker>
                </div>
                <label>Time</label>
              </div>-->
            </div>
            <div class="ddFields row" v-if="activeMode.id =='spp'">
              <div class="input-field col s12">
                <multiselect :selectAllLabel="$t('common-selectAll')" v-if="false" v-model="rawQuery.sensors" :options="sensor" :multiple="false" :close-on-select="false"
                  selectLabel="" deselectLabel=" " class="input" :disabled="false && (!sensor || sensor.length === 0)"
                  :loading="optionLoading.sensor"
                  :placeholder="$tc('common-sensor', 2)" label="label" track-by="id" :showSelectAll="false"
                  @change="loadOptions('project')">
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values && values.length > 1 && !isOpen">{{ $t('dataDiscover-sensorsSelected', {num: values.length}) }}</span>
                  </template>
                </multiselect>
                  <div class="select-wrapper">
                    <template v-for="(opt, index) in sensor">
                      <label class="form-option" v-bind:key="'sppSensorOpt' + index">
                        <input type="checkbox" :id="opt.id" :value="opt.id" name="sensorRadio" v-model="rawQuery.sensors" @change="loadOptions('project')"><span>{{opt.label}}</span>
                      </label>
                    </template>
                  </div>
                  <label>{{$tc('common-sensor', 1)}}</label>
              </div>
              <div class="input-field col s12">
                <div class="select-wrapper">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="rawQuery.projectIds" :options="project" :multiple="true" :close-on-select="false"
                  :placeholder="$t('projectDownload-chooseProjects')" label="projectDisplayNm" track-by="projectId" :project="true" selectLabel="" deselectLabel=" "
                  class="input" :showSelectAll="true" @input="loadOptions('species')" :loading="optionLoading.project"
                  :disabled="!rawQuery.sensors || rawQuery.sensors.length === 0"
                  >
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values && ((values.length > 1 && !isOpen) || (values.length > 5 && isOpen))">{{ $t('dataDiscover-projectsSelected', {num: values.length}) }}</span>
                  </template>
                </multiselect>
                </div>
                <label>{{$tc('common-project', 1)}}</label>
              </div>

              <div class="input-field col s12">
                <div class="select-wrapper">
                  <multiselect :selectAllLabel="$t('common-selectAll')" v-model="rawQuery.species" :options="species" :multiple="true" :close-on-select="false"
                    :placeholder="$t('dataDiscover-filterSpecies')" label="species_common_name" track-by="species_id" selectLabel="" deselectLabel=" "
                    class="input"  :loading="optionLoading.species" :disabled="!rawQuery.projectIds || rawQuery.projectIds.length === 0">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span class="multiselect__single" v-if="values && ((values.length > 1 && !isOpen) || (values.length > 5 && isOpen))">{{ $tc('common-speciesSelected', values.length, {num: values.length}) }}</span>
                    </template>
                  </multiselect>
                </div>
                <label>{{$tc('common-species', 2)}}</label>
              </div>
              <div class="input-field col s12 m12">
                <div class="select-wrapper">
                  <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="rawQuery.startDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></date-picker>
                </div>
                <label>{{$t('common-startDate')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.date"></span></label>
              </div>
              <div class="input-field col s12 m12">
                <div class="select-wrapper">
                  <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="rawQuery.endDate" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></date-picker>
                </div>
                <label>{{$t('common-endDate')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.date"></span></label>
              </div>
              <!--<div class="input-field col s12 m6" v-if="false">
                <div class="select-wrapper">
                  <date-picker type="time" v-model="rawQuery.time" format="HH:mm" value-type="HH:mm:ss"></date-picker>
                </div>
                <label>Time</label>
              </div>-->
            </div>
            <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
            <span class="center-align"><button @click="searchForData" class="btn"> {{$t('common-search')}}</button></span>
            <span class="center-align"><button @click="resetForm" class="btn"> {{$t('common-reset')}}</button></span>
          </div>
        </div>
        <div class="col s12 m12 l7 xl8 ddMap" style="position: relative; border: 1px solid #ddd;">
          <div v-if="loading || apiLoad" class="loading !z-20">
            <pulse-loader :loading="true" color="#127916" size="25px" :width="100" :height="50"></pulse-loader>
          </div>
          <div class="absolute w-full h-full bg-orange-500/25 z-10" v-show="apiLoad">

          </div>
          <point-map ref="map"/>
          <div class="absolute top-2 left-5 w-24 text-left">
            <a @click="setStyle('light-v10')"  class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block text-left !rounded-t !rounded-b-none"><i class="far  !text-xs" :class="{'fa-check-square': mapStyle=='light-v10', 'fa-square': mapStyle!='light-v10'}"></i> {{$t('common-light')}}</a>
            <a @click="setStyle('satellite-streets-v11')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='satellite-streets-v11','fa-square': mapStyle!='satellite-streets-v11'}"></i> {{$t('common-satellite')}}</a> 
            <a @click="setStyle('streets-v11')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='streets-v11','fa-square': mapStyle!='streets-v11'}"></i> {{$tc('common-street', 2)}}</a>
            <a @click="setStyle('dark-v10')" class="btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-b !rounded-t-none"><i class="far !text-xs" :class="{'fa-check-square': mapStyle=='dark-v10','fa-square': mapStyle!='dark-v10'}"></i> {{$t('common-dark')}}</a>
            <!-- <a @click="setStyle('outdoors-v11')" class="btn !text-xs !p-3 !py-1"><i class="far fa-square !text-xs" :class="{'fa-check-square': getStyle()=='outdoors-v11'}"></i> Outdoors</a>  -->
          </div>
        </div>
        
      </div>
    </div>
    <download v-bind:static-options="staticOptions" v-if="organization != null && optionLoaded"> </download>
  </section>
</template>

<script>
/* data discover top rawQuery panel
*/
import { eventBus } from '@/lib/eventbus';
import {API_URL, attachAuthorizaitonHeader} from '@/lib/common';
import {isLoggedIn} from '@/lib/user-auth0';
import DataDiscoverMap from './DataDiscoverMap';
import DataDiscoverDownload from './DataDiscoverDownload';
import FormMixin from '@/components/common/FormMixin.js';
import DatePickerMixin from '@/components/common/DatePickerMixin';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import DatePicker from 'vue2-datepicker'; // new one can pick time
import { mapGetters, mapActions } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading

export default {
  name: 'data-discover-page',
  mixins: [FormMixin, DatePickerMixin],
  components: {'point-map': DataDiscoverMap, DatePicker, 'multiselect': Multiselect, 'download': DataDiscoverDownload, 'pulse-loader': PulseLoader},
  data () {
    return {
      apiLoad: false,
      optionNames: ['organization', 'sensor', 'project', 'species'], // name used in UI
      queryNames: ['organizationIds', 'sensors', 'projectIds', 'species'], // name used for API calls
      optionLoading: {},
      rawQuery: {},
      idParameters: {},
      recordingName: '',
      staticOptions: {projectStatus: []},
      optionLoaded: false,
      loading: false,
      /* below are options, intially put them inside options object, however,
        vue multiselect has issues detected the changes
        (even using this.$set doesn't work)
        so put them under root component
      */
      // organization: [],
      // organizationInfo: {},
      sensor: [],
      project: [],
      species: [],
      // colors: [],
      errors: [],
      /* ui */
      activeMode: null,
      queryModes: [{id: 'basic', label: this.$t('common-general'), className: 'ARU', icon: 'ion-ios-list-outline'}, {id: 'spp', label: this.$tc('common-species', 1), className: 'CAM', icon: 'ion-ios-paw'}],
      tooltips: {
        date: this.$t('dataDiscover-tooltips.date')
      },
      mapStyle: 'light-v10'
    }
  },
  created () {
    // check if user is logged in
    if (isLoggedIn()) {
      attachAuthorizaitonHeader();
    }
    this.activeMode = this.queryModes[0];
    this.getStaticOptions();
    let self = this;
    eventBus.$on('map-loading', function (status) {
      self.loading = status;
    });
  },
  watch: {
    activeMode (newVal, oldVal) {
      if (newVal !== oldVal) {
        /* first reset all values */
        this.resetForm();
      }
    }
    // organization (newVal, oldVal) {
    //   this.organizationInfo = {};
    //   const favorColors = [...this.colors];
    //   this.organization.forEach(x => {
    //     const randomIndex = Math.floor(Math.random() * favorColors.length);
    //     const pickedColor = favorColors.splice(randomIndex, 1)
    //     this.organizationInfo[x.id] = { color: pickedColor[0], name: x.name };
    //   })
    // }
  },
  computed: mapGetters(['organization']),
  methods: {
    setStyle (style) {
      this.$refs.map.$refs.discoverMap.map.setStyle('mapbox://styles/mapbox/' + style);
      this.mapStyle = style;
    },
    ...mapActions(['loadOrganization', 'queryLocations']),
    resetForm () {
      this.optionLoading = {};
      this.rawQuery = {};
      this.idParameters = {};

      for (let i = 1; i < this.optionNames.length; i++) { // from index 1, clear options don't clear organization
        // clear options and values
        this.$set(this, this.optionNames[i], []);
      }
      this.queryLocations({sensors: [], data: []});
      this.$refs.map.manualUpdate(true);
      this.loading = true;
      if (this.activeMode.id === 'basic') {
        this.loadOrganization();
      } else if (this.activeMode.id === 'spp') {
        this.loadOptions('sensor');
      }
    },
    convertRawToIds () {
      let self = this;
      self.idParameters = {};
      Object.keys(this.rawQuery).forEach(key => {
        if (Array.isArray(self.rawQuery[key])) {
          if (key !== 'sensors')
            self.idParameters[key] = self.rawQuery[key].map(x => x.id || x.projectId || x.species_id || x.species_code);
          else 
            self.idParameters[key] = self.rawQuery[key];
        } else {
          if (this.queryNames.includes(key)) { // place in array
            self.idParameters[key] = [self.rawQuery[key]];
          } else {
            self.idParameters[key] = self.rawQuery[key];
          }
        }
      })
      this.idParameters['isSpeciesTab'] = this.activeMode.id === 'spp';
    },
    async getStaticOptions () {
      let self = this;
      let url = 'project-options';
      await this.commonGet(API_URL + url, null, false, (response) => {
        self.$set(self.staticOptions, 'projectStatus', (response.data.status || []).map(x => { return {id: x.id, text: x.type}; }));
      });
      this.optionLoaded = true;
    },
    loadOptions (optionName) {
      let self = this;
      let url;
      // cascade options organization -> sensor -> projects -> species
      const optionLevel = this.optionNames.findIndex(x => x === optionName); // don't clear options when species selected
      if (optionLevel < 3) {
        for (let i = optionLevel; i < this.optionNames.length; i++) {
          // clear options and values
          self.$set(self, this.optionNames[i], []);
          // TODO: keep valid values and remove invalid values
          self.$set(self.rawQuery, this.queryNames[i], []);
        }
      }
      switch (optionName) {
        case 'organization':
          url = 'get-all-readable-organizations';
          break;
        case 'sensor':
          url = 'get-project-sensors-for-organizations';
          break;
        case 'project':
          url = 'get-projects-for-organizations-and-sensors';
          break;
        case 'species':
          url = 'get-species-for-project-dd';
          break;
      };
      this.convertRawToIds();
      /* should use get, but the API take parameters from body, and I can't send them through body using
        get, so use post instead
      */
      self.$set(self.optionLoading, optionName, true);
      this.commonPost(API_URL + url, this.idParameters, false, (response) => {
        self.$set(self, optionName, response.data || []);
        self.$set(self.optionLoading, optionName, false);
      }, () => {
        self.$set(self.optionLoading, optionName, true);
      });
    },
    searchForData () {
      // let self = this;
      this.errors = [];
      if (this.activeMode.id === 'basic') {
        if (!this.idParameters.organizationIds || this.idParameters.organizationIds.length === 0) {
          this.errors.push(this.$t('dataDiscover-errors.orgRequired'));
        }
      } else if (this.activeMode.id === 'spp') {
        if (!this.idParameters.projectIds || this.idParameters.projectIds.length === 0) {
          this.errors.push(this.$t('dataDiscover-errors.projectRequired'));
        }
      }

      if (this.errors.length > 0) {
        return;
      }
      const url = API_URL + 'get-data-discoverer-map-and-projects';
      this.convertRawToIds();
      this.apiLoad = true;
      this.loading = false;
      
      this.commonPost(url, this.idParameters, false, (response) => {
        this.queryLocations({sensors: this.idParameters.sensors, data: response.data}); // send sensor to store
        if (!response.data.map || !response.data.map.features || response.data.map.features.length === 0) {
          if (response.data.projects && response.data.projects.length > 0) {
            this.errors.push(this.$t('dataDiscover-errors.projectsNoLocationFound'));
          }
        }
        if ((!response.data.projects || response.data.projects.length === 0) && (!response.data.map.features || response.data.map.features.length === 0)) {
          this.errors.push(this.$t('dataDiscover-errors.noResults'));
        }
        if ((!response.data.projects || response.data.projects.length === 0) && (response.data.map.features.length > 0)) {
          this.errors.push(this.$t('dataDiscover-errors.noResultsWithTags'));
        }
        this.$refs.map.manualUpdate();
        this.apiLoad = false;
      },
      (failed) => {
        this.apiLoad = false;
        this.loading = false;
      }
      );
    }
  }
}
</script>
<style scoped>
.ddFieldsWrap input {
  height: 40px!important;
}
.ddFields {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.loading {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 3;
  /* width: 100%;
  height: 100%; */
  /* background: white; */
}

.form-option{
  padding-right: 10px;
}

.tab.active a.hyperlink{
  background-color: #FFFFFF;
}
</style>
