<template>
        <modal :name="formName"
            :scrollable="true"   :adaptive="true"
            transition="nice-modal-fade"
            classes="modal-form"
            draggable=".drag-handle"
            :delay="100"
            :width = "1000"
            height = "80%"
            :minWidth="200"
            :minHeight="200"
            :clickToClose = "false">
            <div @click="closeForm" class="iconButton link">
                <span class="ion-close"></span>
            </div>
            <div class="modal-header drag-handle">
                <h6>{{$t('common-help')}}</h6>
            </div>
            <div class="modal-scroller">
                <div class="modal-content">
                    <ul class="vue collapsible getHelp">
                      <li class="">
                          <div class="collapsible-header" @click="showView=!showView"><h5>{{$t('taggingHelp-taggingTabs')}}<span
                          :class="{'ion-arrow-down-b': showView,'ion-arrow-up-b': !showView}"></span></h5></div>
                          <div class="collapsible-body" v-if="showView">
                                  <div class="w-full p-4">
                                      <h6>{{$t('taggingHelp-fullTaggingView')}}</h6>
                                      <p>{{$t('taggingHelp-fullTaggingDesc')}}</p>
                                      <h6>{{$t('taggingHelp-seriesView')}}</h6>
                                      <p>{{$t('taggingHelp-seriesDesc')}}
                                      </p>
                                      <h6>{{$t('taggingHelp-fieldOfView')}}</h6>
                                      <p>{{$t('taggingHelp-fieldOfViewDesc')}}</p>
                                  </div>
                          </div>
                        </li>
                        <li class="">
                            <div class="collapsible-header" @click="showHelp=!showHelp">  <h5>{{$t('common-keyboardShortcuts')}} <span
                            :class="{'ion-arrow-down-b': showHelp,'ion-arrow-up-b': !showHelp}"></span></h5></div>
                            <div class="collapsible-body" v-if="showHelp">
                                <div class="row">
                                    <div class="col s12 m12">

                                        <div class="col s12 m6">
                                            <h6>{{$t('onTaggingPage')}}</h6>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('common-left')}}</div>
                                                <p>{{$t('taggingHelp-leftDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon"> {{$t('common-right')}}</div>
                                                <p>{{$t('taggingHelp-rightDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-CtrlA')}}</div>
                                                <p>{{$t('taggingHelp-ctrlADesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlQ')}}</div>
                                                <p>{{$t('taggingHelp-ctrlQDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-escape')}}</div>
                                                <p>{{$t('taggingHelp-escapeDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-space')}}</div>
                                                <p>{{$t('taggingHelp-spaceDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon double-line">{{$t('taggingHelp-shiftSpace')}}</div>
                                                <p>{{$t('taggingHelp-shiftSpaceDesc')}}</p>
                                            </div>

                                        </div>

                                        <div class="col s12 m6">
                                            <div class="keyIcon">
                                                <div class="icon double-line">{{$t('taggingHelp-shiftCtrl')}}</div>
                                                <p>{{$t('taggingHelp-shiftCtrlDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlE')}}</div>
                                                <p>{{$t('taggingHelp-ctrlEDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlS')}}</div>
                                                <p>{{$t('taggingHelp-ctrlSDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                            <div class="icon">{{$t('taggingHelp-ctrlB')}}</div>
                                                <p>{{$t('taggingHelp-ctrlBDesc')}}</p>
                                            </div>
                                            <h6>{{$t('taggingHelp-zoomedImageView')}}</h6>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('common-left')}}</div>
                                                <p>{{$t('taggingHelp-zoomLeftDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon"> {{$t('common-right')}}</div>
                                                <p>{{$t('taggingHelp-zoomRightDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-escape')}}</div>
                                                <p>{{$t('taggingHelp-zoomEscapeDesc')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- legend -->
                        <li class="">
                            <div class="collapsible-header" @click="showMouse=!showMouse">
                                <h5>{{$t('taggingHelp-imageSelection')}}<span :class="{'ion-arrow-down-b': showMouse,'ion-arrow-up-b': !showMouse}"></span></h5>
                            </div>
                            <div class="collapsible-body" v-show="showMouse">
                                    <div class="w-full p-4">
                                        <div>{{$t('taggingHelp-selectionMainInstructions')}}   </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{$t('taggingHelp-mouseDrag')}}</span></div>
                                            <p>{{$t('taggingHelp-mouseDragDesc')}}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{$t('taggingHelp-ctrlMouseDrag')}}</span></div>
                                            <p>{{$t('taggingHelp-ctrlMouseDragDesc')}}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{$t('taggingHelp-singleLeftClick')}}</span></div>
                                            <p>{{$t('taggingHelp-singleLeftClickDesc')}}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{$t('taggingHelp-ctrlSingleLeftClick')}}</span></div>
                                            <p>{{$t('taggingHelp-ctrlSingleLeftClickDesc')}}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{$t('taggingHelp-rightClick')}}</span></div>
                                            <p>{{ $t('taggingHelp-rightClickDesc') }}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>${{ $t('taggingHelp-shiftSingleClick') }}</span></div>
                                            <p v-html="$t('taggingHelp-shiftSingleClickDesc')">
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="collapsible-header" @click="showLegend=!showLegend">  <h5>{{$t('common-legend')}}<span :class="{'ion-arrow-down-b': showLegend,'ion-arrow-up-b': !showLegend}"></span></h5></div>
                            <div class="collapsible-body" v-show="showLegend">
                                <div class="row">
                                    <div class="col s12 m12">

                                        <div class="col s12 m6">
                                            <div class="keyIcon">
                                                <div class="legend-block legend-tagged"><span>2</span></div>
                                                <p>{{ $t('taggingHelp-tagged') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="legend-block legend-none"><span>2</span></div>
                                                <p>{{ $t('None') }} </p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="legend-block legend-untagged"><span>2</span></div>
                                                    <p>{{ $t('taggingHelp-untagged') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="legend-block legend-selected white" sty><span>2</span></div>
                                                    <p>{{ $t('taggingHelp-selected') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="legend-block legend-tagged"><div class="multipleSpecies"/><span>2</span></div>
                                                    <p>{{ $t('taggingHelp-multipleSpecies') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="legend-block legend-tagged"><div class="multipleCount"/><span>2</span></div>
                                                    <p>{{ $t('taggingHelp-multipleIndividuals') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="legend-block legend-tagged"><div class="outOfRange"/><span>2</span></div>
                                                    <p>{{ $t('taggingHelp-outOfRange') }}</p>
                                            </div>
                                        </div>

                                        <div class="col s12 m6">
                                            <div class="keyIcon">
                                                <div class="legend-block legend-tagged legend-needReview"><span>2</span></div>
                                                <p>{{ $t('taggingHelp-imageNeedsReview') }}</p>
                                            </div>
                                            <h6>{{ $t('taggingHelp-zoomedImageView') }}</h6>
                                            <div class="keyIcon">
                                                <div class="icon">{{ $t('common-left') }}</div>
                                                <p>{{ $t('taggingHelp-zoomLeftDesc') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon"> {{ $t('common-right') }}</div>
                                                <p>{{ $t('taggingHelp-zoomRightDesc') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{ $t('taggingHelp-escape') }}</div>
                                                <p>{{ $t('taggingHelp-zoomEscapeDesc') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="collapsible-header" @click="showIcon=!showIcon">  <h5>{{ $t('taggingHelp-icons') }}<span :class="{'ion-arrow-down-b': showIcon,'ion-arrow-up-b': !showIcon}"></span></h5></div>
                            <div class="collapsible-body" v-show="showIcon">
                                <div class="row">
                                    <div class="col s12 m12">
                                        <div class="col s12 m6">
                                            <div class="keyIcon">
                                                <div class="icon-block "><span class="ion ion-ios-copy-outline link"></span></div>
                                                <p> {{ $t('taggingHelp-duplicateTag') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon-block "><span class="ion ion-ios-list-outline link"></span></div>
                                                <p> {{ $t('taggingHelp-toggleCommentField') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon-block "><span class="ion ion-android-remove-circle"></span></div>
                                                <p> {{ $t('taggingHelp-removeUnsavedTag') }}</p>
                                            </div>
                                        </div>
                                        <div  class="col s12 m6">
                                            <div class="keyIcon">
                                                <div class="icon-block "><span class="ion ion-android-delete"> <span class="ion ion-android-delete link" :title="$t('taggingHelp-deleteTag')" style="font-size:14px"></span></span></div>
                                                <p> {{ $t('taggingHelp-batchRemoveTagsFromDB') }}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon-block "><span class="ion ion-android-done-all"></span></div>
                                                <p> {{ $t('taggingHelp-batchUpdateTagsDB') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </modal>
</template>

<script>
import { eventBus } from '@/lib/eventbus';
export default {
  name: 'tagging-help',
  props: ['formName'],
  methods: {
    closeForm () {
      eventBus.$emit('toggle-modal-form', this.formName, false);
    }
  },
  data () {
    return {
      showHelp: false,
      showLegend: false,
      showIcon: false,
      showMouse: false,
      showView: false
    }
  }
}
</script>
<style scoped>
.keyIcon .icon {
    border: 2px solid #d9d9d9;
    padding: 12px 20px 12px 20px;
    height: 60px;
    width: 100px;
    margin-bottom: 20px;
    margin-right: 10px;
}
.keyIcon .icon.big {
  width: 200px;
}
.keyIcon .icon.double-line {
    line-height: 20px;
}
.keyIcon {
  width: 100%;
  display: flex;
}
.legend-block {
    width: 45px;
    height: 45px;
    padding: 13px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    line-height: 1.2em;
    margin-right: 10px;
    position: relative;
}

.legend-untagged {
    background-color: #fff;
    border:#ccc 1px solid;
    color: #ccc;
}
.legend-tagged {
    background-color: #7DA484;
}
.legend-none {
    background: rgb(203, 221, 206);
    color: #666;
}
.legend-selected {
    color:#ccc;
    border: #00ffff63 solid 3px
}

.legend-block span {
    z-index: 10;
}
.legend-needReview {
    border: #FF9800 4px solid;
}
.icon-block {
    width: 65px;
    height: 45px;
    padding: 13px;
    color: #C94412;
    text-align: center;
    vertical-align: middle;
    line-height: 1.2em;
    margin-right: 10px;
    position: relative;
}
.keyIcon {
    margin: 5px 0;
}
.keyIcon .icon-block .ion{
    font-size: 30px;
}
.getHelp h5 {
    font-size: 1.2rem
}
.multipleSpecies { /* top right red triangle */
      width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #b73333;
    border-top: 5px solid #b73333;
    border-left: 5px solid transparent;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}
 .multipleCount, .multipleIndividual{/* top left blue triangle */
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #337ab7;
    border-left: 5px solid #337ab7;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
  .outOfRange:before {
    content: "";
    position: absolute;
    top: 0%;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left top, rgba(224, 226, 97, 0.6) 50%, transparent 50%)
}
</style>
