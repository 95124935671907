var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "iconButton link", on: { click: _vm.close } }, [
      _c("span", { staticClass: "ion-close" })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-header drag-handle" }, [
      _c("h5", [_vm._v(_vm._s(_vm.$t("autogenVisitsForm-title")))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col s12 panel padded40 dataGroup autogen-visit" },
      [
        _c("div", { staticClass: "flex-row" }, [
          _c(
            "span",
            {
              staticClass: "link",
              class: {
                "ion-arrow-expand": !_vm.showSettings,
                "ion-arrow-shrink": _vm.showSettings
              },
              on: {
                click: function($event) {
                  _vm.showSettings = !_vm.showSettings
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.showSettings
                      ? _vm.$t("autogenVisitsForm-hideSettings")
                      : _vm.$t("autogenVisitsForm-showSettings")
                  ) +
                  "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSettings,
                expression: "showSettings"
              }
            ],
            staticClass: "boxed container-fluid padded20",
            attrs: { id: "autogenForm" }
          },
          [
            _c("div", { attrs: { clas: "row" } }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("autogenVisitsForm-description")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s12 m6 l4" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("autogenVisitsForm-fields.gapDays")) + " "
                  ),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltips.gapDays,
                        expression: "tooltips.gapDays"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.autogenInputs.gapDays,
                        expression: "autogenInputs.gapDays",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "validate",
                    attrs: {
                      step: "1",
                      min: "1",
                      max: "365",
                      type: "number",
                      required: ""
                    },
                    domProps: { value: _vm.autogenInputs.gapDays },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.autogenInputs,
                          "gapDays",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 m6 l4" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.autogenInputs.checkEquipment,
                        expression: "autogenInputs.checkEquipment",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "exclusive checkbox",
                    attrs: {
                      id: "autogen-checkEquipment",
                      type: "checkbox",
                      required: ""
                    },
                    domProps: {
                      checked: Array.isArray(_vm.autogenInputs.checkEquipment)
                        ? _vm._i(_vm.autogenInputs.checkEquipment, null) > -1
                        : _vm.autogenInputs.checkEquipment
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.autogenInputs.checkEquipment,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(null),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.autogenInputs,
                                "checkEquipment",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.autogenInputs,
                                "checkEquipment",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.autogenInputs, "checkEquipment", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass: "control-label col-md-2 col-sm-3",
                        attrs: { for: "autogen-checkEquipment" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("autogenVisitsForm-fields.checkEquipment")
                          ) + " "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.checkEquipment,
                          expression: "tooltips.checkEquipment"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 m6 l4" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.autogenInputs.createNewRecords,
                        expression: "autogenInputs.createNewRecords",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "exclusive checkbox",
                    attrs: {
                      id: "autogen-createNewRecords",
                      type: "checkbox",
                      required: ""
                    },
                    domProps: {
                      checked: Array.isArray(_vm.autogenInputs.createNewRecords)
                        ? _vm._i(_vm.autogenInputs.createNewRecords, null) > -1
                        : _vm.autogenInputs.createNewRecords
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.autogenInputs.createNewRecords,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(null),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.autogenInputs,
                                "createNewRecords",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.autogenInputs,
                                "createNewRecords",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.autogenInputs, "createNewRecords", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass: "control-label col-md-2 col-sm-3",
                        attrs: { for: "autogen-createNewRecords" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("autogenVisitsForm-fields.createNew")) +
                            " "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.createNewRecords,
                          expression: "tooltips.createNewRecords"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col s12 m6 l4" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.autogenInputs.correctBadDates,
                        expression: "autogenInputs.correctBadDates",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "exclusive checkbox",
                    attrs: {
                      id: "autogen-correctBadDates",
                      type: "checkbox",
                      required: ""
                    },
                    domProps: {
                      checked: Array.isArray(_vm.autogenInputs.correctBadDates)
                        ? _vm._i(_vm.autogenInputs.correctBadDates, null) > -1
                        : _vm.autogenInputs.correctBadDates
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.autogenInputs.correctBadDates,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(null),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.autogenInputs,
                                "correctBadDates",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.autogenInputs,
                                "correctBadDates",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.autogenInputs, "correctBadDates", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass: "control-label col-md-2 col-sm-3",
                        attrs: { for: "autogen-correctBadDates" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "autogenVisitsForm-fields.alterIncorrectDates"
                            )
                          ) + " "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.correctBadDates,
                          expression: "tooltips.correctBadDates"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-border btn-success modal-action modal-close",
                  on: {
                    click: function($event) {
                      return _vm.submitInputs()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("autogenVisitsForm-findButton")))]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _vm.showTable
          ? _c("generate-and-select-table", {
              attrs: {
                "organization-id": _vm.organizationId,
                url: _vm.autogenUrl
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }