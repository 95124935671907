var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.users
    ? _c(
        "div",
        [
          _vm.errors.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "my-5 bg-red-300 border border-red-500 p-3 text-red-600 text-sm"
                },
                _vm._l(_vm.errors, function(error) {
                  return _c("span", [_vm._v(_vm._s(error))])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-client-table",
            {
              attrs: {
                data: _vm.data,
                columns: _vm.columns,
                options: _vm.options
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "name",
                    fn: function(props) {
                      return [
                        _c("div", [_vm._v(_vm._s(props.row.name))]),
                        _c("div", { staticClass: "text-xs" }, [
                          _vm._v(_vm._s(props.row.email))
                        ])
                      ]
                    }
                  },
                  _vm._l(_vm.roles, function(role) {
                    return {
                      key: role.type,
                      fn: function(props) {
                        return [
                          _c("label", [
                            _c("input", {
                              class: { "exclusive checkbox": true },
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.disabledCheckbox(
                                  role.id,
                                  props.row
                                )
                              },
                              domProps: {
                                checked: props.row.roles.includes(role.id)
                              },
                              on: {
                                change: () =>
                                  _vm.onCheckboxChange(props.row, role.id)
                              }
                            }),
                            _vm._v(" "),
                            _c("span", {
                              class: {
                                "!cursor-default": _vm.disabledCheckbox(
                                  role.id,
                                  props.row
                                )
                              }
                            })
                          ])
                        ]
                      }
                    }
                  })
                ],
                null,
                true
              )
            },
            [
              _c("template", { slot: "h__Admin" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.$t("Admin")) + "\n            "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("projectForm-tooltips.admin"),
                        expression: "$t('projectForm-tooltips.admin')"
                      }
                    ]
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-info-circle text-yellow-400"
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("template", { slot: "h__Read Access" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("Read Access")) +
                    "\n            "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("projectForm-tooltips.read"),
                        expression: "$t('projectForm-tooltips.read')"
                      }
                    ]
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-info-circle text-yellow-400"
                    })
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }