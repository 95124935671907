<template>
  <div class="vue">
    <div v-show="!loaded" class="overaly" style="opacity: 0.6; width: 100%; height: 100%; z-index: 100;">
          <div style="text-align: center; margin:250px 0px;">
            <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <div> {{ $t('common-tableTexts.loading') }} </div>
          </div>
    </div>
    <div  class="container error padded60" v-if="error != null">
      <span v-if="error.statusText != null">
        {{error.statusText}}
      </span>
      <span v-else>
        {{error}}
      </span>
    </div>

    <div v-show="loaded" class="container overflow boxed homeHead" style="padding-top: 6%; padding-bottom: 6%;">
      <div class="headImg image-container" id="project-page">
          <span class="photo-credit black small">Paul Reeves</span>
      </div>

      <h2 class="white-font">My Projects    </h2>
		
    </div>

    <div v-show="loaded" v-if="error==null && activeSensor">

      <div class="container overflow tabbed pt-5">
        <ul class="tabs">
          <li v-cloak v-for="(sensor, index) in sensors" :key="'sensor' + index" class="tab">
            <a  class="hyperlink" v-bind:class="{ '!border-gray-400 border-2 border-b-0': (activeSensor.id == sensor.id && activeTab == 'table'), [activeSensor.id]: true}" @click="updateSensor(sensor)">
              <span :class="{'ion-ios-mic': sensor.id =='ARU',
              'ion-ios-camera': sensor.id == 'CAM', 'ion-android-locate': sensor.id == 'PC'}"></span>
              {{sensor && $t(`common-${sensor.id.toLowerCase()}`)}}
            </a>
          </li>
        </ul>
        <project-table ref="project" v-bind:sensor="activeSensor" v-if="activeTab == 'table' && activeSensor != null"
        v-bind:show-admin-only-projects="false" @loaded="loadedEvent"/>
      </div>
    </div>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all projects
under each project tab, show a table listing all projects. users can also update project properties + users
or remove projects if they have no associated data.
*/
// import Vue from 'vue';
import ProjectTable from './ProjectTable';
import StatsBox from '@/components/common/StatsBox';
import {API_URL, getParameterByName, attachAuthorizaitonHeader} from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import { eventBus } from '../../lib/eventbus';


export default {
  name: 'project-page',
  components: {'project-table': ProjectTable, 'stats': StatsBox, 'pulse-loader': PulseLoader},
  computed: {
    statsSensorUrl: function () {
      return this.statsUrl + '?sensorId=' + this.activeSensor.id;
    }
  },
  methods: {
    // a computed getter
    updateSensor: function (sensor) {
      if (this.activeSensor !== sensor) {
        this.loadedEvent(false);
        // `this` points to the vm instance
        this.activeSensor = sensor;
        // if (this.$refs['project']) {
        //   this.$refs['project'].url = API_URL + 'get-project-summary?sensorId=' + name; // use this.url won't update the url. lag behind. so I set his way again
        // }
        localStorage.setItem('activeSensor', sensor.id);
        this.activeTab = 'table';
        eventBus.$emit('tab-switch', sensor);
      }
    },
    createProject: function () {
      this.$refs.project.createProject();
    },
    switchProjectView () {
      this.showMine = !this.showMine;
    },
    loadedEvent (isLoaded) {
      this.loaded = isLoaded;
    }
  },
  created () {
    attachAuthorizaitonHeader();

    //Calling this to pre-emptively store species for ProjectForm.
    this.$store.dispatch('Species/getAllSpecies');

    this.$http.get(API_URL + 'sensor-options').then(
      function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        }
        this.sensors = response.data;
        if (this.sensors != null) {
          
          if (localStorage.getItem('activeSensor')) {
            this.activeSensor = this.sensors.find(sensor => sensor.id === localStorage.getItem('activeSensor'));
          } else 
            this.activeSensor = this.sensors.find(sensor => sensor.id === getParameterByName('sensorId', 'ARU'));

          this.isManagerOfAnyProject = false;
          this.sensors.forEach(x => { this.isManagerOfAnyProject = this.isManagerOfAnyProject || x.isManagerOfAnyProject; });
        }
      }, response => {
        // error callback
       // this.error = this.$tc('common-error', 1) + ': ' + (response.data ? response.data.error : '') + ' ' + response.status;
        // console.log('here');
      }
    );
  },
  data () {
    return {
      loaded: false,
      url: API_URL + 'get-project-summary',
      statsUrl: API_URL + 'get-task-user-stats',
      stats: [
        {action: 'ided', count: '12', type: 'species'},
        {action: 'completed', count: '112', type: 'tasks'}
      ],
      sensors: [],
      isManagerOfAnyProject: false, /** local admin for at least one project */
      activeSensor: null, /* default ARU sensor */
      projectListUrl: API_URL + 'get-sensor-summary?projectType=all',
      error: null,
      activeTab: 'table'
    }
  }
}
</script>
<style scoped>
.headImg image-container {
  background-image:url('../../assets/PaulReeves_Perisoreus_canadensis.jpg');
}
.hyperlink {
  cursor: pointer;
}
.stats-label {
  color: #C94412;
  font-weight: 600;
}
.tabs li.tab {
  margin-left: 2px;
}

.bullseye-outer{
  display: inline-block;
  font-size: 28px !important;
}

.bullseye-inner{
  position: absolute !important;
  top: -1px !important;
  font-size: 16px !important;
  left: 5px;
}

.bullseye-innermost{
  position: absolute !important;
  top: 0px !important;
  font-size: 6px !important;
  left: 9px;
}

.tabs li.tab a.active {
      background: #ffffff;
  }

</style>
