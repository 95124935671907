<style scoped>
  .icon {
    transition: margin 300ms;
  } 
</style>

<template>
  <modal 
        :name="id"
        @closed="onClose"
        width="80%"
        :clickToClose="!uploading"
        height="auto">
          <div id="file-drag-drop" :class="{'p-5':true,'bg-slate-100':dragover}">
            <form @drop.prevent="onDrop($event)" @dragover.prevent="dragover = true" @dragenter.prevent="dragover = true"
              @dragleave.prevent="dragover = false" 
              :class="{'h-36 flex  items-center justify-center ': true, '':!dragover } " ref="fileform">
              <div class="drop-files text-center flex-col flex">
                <i class="fa fa-cloud-arrow-up text-5xl block icon" :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"></i>
                <p>{{$t('Drop your files here!')}} or <a class="cursor-pointer" @click="$refs.filebutton.click()">browse for files</a></p>
              </div>
            
            </form>
            <input ref="filebutton" type="file" @change="onFileDialog" :multiple="multiple" style="visibility:hidden" />
            <div class="h-72 w-full overflow-y-scroll mt-5" v-if="uploadedFiles.length>0">


              <table>
                <thead>
                  <tr>
                    <th :class="cellClasses()"></th>
                    <th :class="cellClasses()">{{$t('File Name')}}</th>
                    <th :class="cellClasses()">{{$t('Description')}}</th>
                    <th :class="cellClasses('text-center')">{{$t('Hidden')}}</th>
                    <th :class="cellClasses('text-center')">{{$t('Inc. in d/l')}}</th>
                    <th :class="cellClasses()"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, key) in uploadedFiles" class="relative">
                    <td :class="cellClasses()">
                      <span v-if="uploadStarted">
                        <i v-if="!uploadStatus[file.file.name].success && !uploadStatus[file.file.name].error" class="fa fa-spinner fa-spin"></i>
                        <i v-else-if="uploadStatus[file.file.name].success && !uploadStatus[file.file.name].error" class="fa fa-check"></i>
                        <i v-else-if="!uploadStatus[file.file.name].success && uploadStatus[file.file.name].error" v-tooltip="uploadStatus[file.file.name].error" class="fa-solid fa-triangle-exclamation"></i>
                      </span>
                    </td>
                    <td :class="cellClasses()">{{ file.file.name }}</td>
                    <td :class="cellClasses()">
                      <input :disabled="uploading || uploaded" type="text" class="!mb-0 !border-0" :placeholder="$t('Describe file')" @blur="(e)=>setProp(file, 'description', e.target.value)" />
                    </td>
                    <td  :class="cellClasses('text-center')"> 
                      <a v-if="!uploading && !uploaded" class="cursor-pointer flex-none !text-gray-500 " @click.stop="toggleProp(file, 'hidden')">
                        <i :class="{'fa-regular fa-square':!file.hidden, 'fa fa-check':file.hidden }"></i>
                      </a>
                      <a v-else class="!text-gray-500"><i :class="{'fa-regular fa-square':!file.hidden, 'fa fa-check':file.hidden }"></i></a>
                    </td>
                    <td  :class="cellClasses('text-center')">
                      <a v-if="!uploading && !uploaded" class="cursor-pointer flex-none !text-gray-500 " @click.stop="toggleProp(file, 'includeInDownload')">
                        <i :class="{'fa-regular fa-square':!file.includeInDownload, 'fa fa-check':file.includeInDownload }"></i>
                      </a>
                      <a v-else class="!text-gray-500"><i :class="{'fa-regular fa-square':!file.includeInDownload, 'fa fa-check':file.includeInDownload }"></i></a>
                    </td>
                    <td  :class="cellClasses('text-center')">  
                      <a v-if="!uploading && !uploaded" class="remove flex-none !text-gray-500 cursor-pointer" @click.stop="removeFile(file.file.name)">
                        <i class="fa fa-trash"></i>
                      </a>
                      <a v-else class="!text-gray-500"><i class="fa fa-trash"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="footer mt-5 flex gap-1 items-center" v-if="uploadedFiles.length>0">
              <div class="grow"></div>
              
              <a  v-show="!uploaded" class="btn btn-success flex-initial" :disabled="uploading" @click="submit">
                <i class="fa fa-spinner text-base fa-spin" v-show="uploading" ></i>
                {{uploading?`${$t('Uploading')} ${numUploaded} of ${uploadedFiles.length} `:$t('Upload')}}
              </a>
              <div v-if="uploaded && !uploadedErrors" class="px-10 font-bold text-success">{{$t('Upload Complete!')}}</div>
              <div v-else-if="uploaded && uploadedErrors" class="px-10 font-bold text-burntOrange">
                {{$t('There were issues uploading.')}}
                <a @click="showErrorDialog">{{$t('Click to Show Issues')}}</a>
              </div>
              <a class="btn btn-link" @click="onClearAll" :disabled="uploading">{{$t('Reset')}}</a>
              <a class="btn flex-initial" @click="closeDialog" :disabled="uploading">{{$t('Close')}}</a>
            </div>
          </div>
          <modal name="errors" @closed="onErrorModalClose" height="auto">
            <div class="p-3">
              <h3>Upload Errors</h3>
              <div class="mb-5 h-48 overflow-y-scroll">
                <ul>
                <li class="border-b border-slate-100 py-2" v-for="(error, key) in errors">{{error}}</li>
                </ul>
              </div>
              <div class="btn w-full" @click="$modal.hide('errors')">Close</div>
            </div>
          </modal>
  </modal>
</template>

<script scoped>

import { v4 as uuidv4 } from 'uuid';
import { eventBus } from '@/lib/eventbus';

export default {
  'name':'file-uploader',
  'props': ['multiple', 'validMimeTypes', 'fileNameExcludes'],

  data() {
    return {
      id: `file-upload-modal-${uuidv4()}`, //id to identifiy this instance if multiple upload boxes in place.
      uploadedFiles: [],
      uploadStatus:{}, 
      dragover: false,
      errors: [],
      uploadStarted: false,
      uploadComplete: false
    }
  },
  created() {
    this.$emit('getID', this.id);
    eventBus.$on('ProjectFiles:batchUploadStarted', this.onBatchUploadStarted);
    eventBus.$on('ProjectFiles:batchUploadComplete', this.onBatchUploadComplete);
    eventBus.$on('ProjectFiles:uploadFileComplete', this.onUploadFileComplete);
    window.vueContext = this;
  },

  mounted() {


  },
  watch: {
    uploaded(newval,oldval) {
      if (newval != oldval && newval === true) {
        //when upload completes.

        //check for errors
        if (this.uploadedErrors) {
          Object.entries(this.uploadStatus).forEach(([file,status])=>{
            if (status.error !== null)
              this.errors.push(`${this.$t('Issue uploading')} ${file}: ${status.error}`);
          });
        }

      }
    }
  },
  computed: {
    localFiles() {
      return this.uploadedFiles.map(f=>f.file.name);
    },
    uploaded() {
      return this.uploadStarted && this.uploadComplete;
    },
    uploading() {
      return this.uploadStarted && !this.uploadComplete;
    },
    numUploaded() {
      return Object.values(this.uploadStatus).filter(s=>s.success).length;
    },
    uploadedErrors() {
      return  Object.values(this.uploadStatus).filter(s=>s.error!=null).length > 0;
    }
  },

  methods: {
    cellClasses(...classes) {
      classes.unshift('py-2');
      return classes;
    },
    onBatchUploadStarted(e) {
      this.uploadStarted = true;
    },
    onBatchUploadComplete() {
      this.uploadComplete = true;
    },
    onUploadFileJsonStarted() {
      
    },
    onUploadFileComplete(fileName, error = null) {
      let self = this;

      this.uploadStatus = {...this.uploadStatus};
      this.uploadStatus[fileName] = {
        success: (error==null),
        error
      };

      // self.$set(self.uploadStatus, fileName, {
      //   success: (error==null),
      //   error
      // });
    },  
    onFileDialog(e) {
      this.onHandleFiles(e.target.files);

      if (this.errors.length > 0)
        this.$modal.show('errors');
    },  
    onErrorModalClose() {
      this.errors = [];
    },
    onClearAll() {
      this.uploadedFiles = [];
      this.uploadStatus = {};
      this.uploadStarted = false;
      this.uploadComplete = false;
      this.onErrorModalClose();
    },  
    setProp(file, prop, value) {
      //console.log(value);
      file[prop] = value;
    },
    toggleProp(file, prop) {
      this.setProp(file, prop, !file[prop]);
    },  
    onClose() {
      // Remove all the uploaded files
      this.uploadedFiles = [];
      this.uploadStatus = {};
      this.uploadStarted = false;
      this.uploadComplete = false;
    },
    closeDialog() {
      this.onClose();
      this.$modal.hide(this.id);
    },
    showErrorDialog() {
      this.$modal.show('errors');
    }, 
    hideErrorDialog() {
      this.$modal.hide('errors');
    },
    onHandleFiles(files) {
      for (let element of files) {
          if (this.validMimeTypes.length == 0 || Object.values(this.validMimeTypes).find(MIME=> MIME==element.type)) {
            if (this.fileNameExcludes.indexOf(element.name) > -1) {
              this.errors.push(`Cant upload ${element.name} file already exists.`);
            } else {
              if (this.localFiles.indexOf(element.name) == -1) {
                this.uploadedFiles.push({file: element, hidden: false, description: '', includeInDownload: true});
                this.uploadStatus[element.name] = {success: false, error: null};
              }
            }
          } else {
            this.errors.push(`Cant upload ${element.name} invalid file format (${element.type})`);
          }
        }
    },
    onDrop(e) {
      
      this.dragover = false;
    
      // If user has uploaded multiple files but the component is not multiple throw error
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        alert('Only one file uploaded at a time');
        // this.$store.dispatch("addNotification", {
        //   message: "Only one file can be uploaded at a time..",
        //   colour: "error"
        // });
      }
      // Add each file to the array of uploaded files
      else {
        this.onHandleFiles(e.dataTransfer.files);
      }

      if (this.errors.length > 0)
        this.showErrorDialog();

    },

    submit() {
      // If there aren't any files to be uploaded throw error
      if (!this.uploadedFiles.length > 0) {
        this.$store.dispatch("addNotification", {
          message: "There are no files to upload",
          colour: "error"
        });
      } else {
        // Send uploaded files to parent component
        this.$emit("filesUploaded", this.uploadedFiles);
        // Close the dialog box
        //this.closeDialog();
      }
    },

    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        file => file.file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
  }
}
</script>