<template>
  <div id="agreementModal"  class="modal"  v-bind:class="{ open: showForm }" style="z-index: 1003; width: 65%; display: block; opacity: 1; transform: scaleX(1); top: 10%;">
    <div  @click="close" class="iconButton close-modal"><span class="ion-close"></span> </div>
    <div class="container overflow">
      <div class="row">
        <div class="col s12 padded20 dataGroup"><h5>{{$t('agreementForm-title')}}</h5></div>
        <div class="col s12">
          <label>{{$t('agreementForm-fields.name')}}</label>
          <input v-model="row.name" type="text" :placeholder="$t('agreementForm-fields.name')">
        </div>
          <div class="col s12 m6">
          <label >{{$t('common-startDate')}}</label>
          <div class="vue-select-wrapper">
            <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="row.startDate" format="YYYY-MM-DD" :use-utc="true"></date-picker>
          </div>
        </div>
          <div class="col s12 m6">
          <label >{{$t('common-endDate')}}</label>
          <div class="vue-select-wrapper">
            <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="row.endDate" format="YYYY-MM-DD" :use-utc="true"></date-picker>
          </div>
        </div>
          <div class="col s12">
          <label >{{$t('agreementForm-fields.constraints')}}</label>
          <div class="vue-select-wrapper">
            <select class="initialized" v-model="row.uaoId" type="number" v-if="options.projectUsageAgreement != null">
              <option  v-for="(option, index) in options.projectUsageAgreement" :key="index" :value="option.id">{{option.type}}</option>
            </select>
          </div>
        </div>
        <div class="col s12"> <label >{{$t('agreementForm-fields.signeeInfo')}}</label></div>
        <div class="col s12 m6 l4">
          <label for="full-name">{{$t('agreementForm-fields.fullName')}}</label>
          <input id="full-name" v-model="row.signeeUserName" type="text" :placeholder="$t('agreementForm-fields.fullName')" >
        </div>
        <div class="col s12 m6 l4">
          <label for="signeeEmail">{{$t('agreementForm-fields.email')}}</label>
          <input id="signeeEmail" v-model="row.signeeEmail" type="text" :placeholder="$t('agreementForm-fields.email')">
        </div>
          <div class="col s12 m6 l4">
          <label>{{$tc('common-organization', 1)}}</label>
          <input id="signeeOrganization" v-model="row.signeeOrganization" type="text" :placeholder="$tc('common-organization', 1)">
        </div>
        <div class="col s12 m6 l4">
          <label>{{$t('agreementForm-fields.signedDate')}}</label>
          <div class="vue-select-wrapper">
            <!-- <date-picker type="date" valueType="format" :lang="locales[$root.$i18n.locale]" v-model="row.signeeSignedDate" format="YYYY-MM-DD" :use-utc="true"></date-picker> -->
            <date-picker v-model="row.signeeSignedDate" :lang="locales[$root.$i18n.locale]" valueType="format" format="YYYY-MM-DD" :use-utc="true"></date-picker>

          </div>
        </div>
        <div class="col s12" v-if="agreement.fileName != null">
          <p><a class="link" @click="downloadStreamFile(getAgreementFileDownload + agreement.id, agreement.fileName, 'application/octet-stream')"><span class="icon ion-android-attach"></span> {{$t('agreementForm-fields.viewAgreement')}}</a></p>
        </div>
        <div class="col s12">
          <p>{{$t('agreementForm-fields.uploadAgreement')}} <span class="ion-information-circled"
          v-tooltip="$t('agreementForm-tooltip')"></span> </p>
          <input type="file" ref="chooseFile" enctype="multipart/form-data" name="file" @change="trackUploadFile($event.target.name, $event.target.files);"
            accept=".jpg,doc,.docx,.pdf,application/msword" class="input-file" style="display: none;">
          <button type="button" style="width: 30%" class="btn btn-default" id="browseFileBtn"  @click="browse">
            <span class="glyphicon glyphicon-refresh"></span> {{$t('common-fileBtn')}}
          </button>
          <span>{{uploadFilename}}</span>
        </div>
        <div class="col s12">
          <div class="error" v-for="(message, index) in messages" :key="'msg' + index"> {{message}}</div>
        </div>
        <div class="col s12 padded20">
          <button class="btn inverted" @click="saveAgreement" >{{$t('common-save')}}</button>
          <button class="btn btn-border" @click="close"> {{$t('common-close')}} </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* This component is to display a form to insert/update singel user agreement information
*/
import {API_URL, downloadStreamFile, validateEmail} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import Datepicker from 'vue2-datepicker';
import DatePickerMixin from '@/components/common/DatePickerMixin';

export default {
  name: 'project-agreement-edit-form',
  components: { 'date-picker': Datepicker },
  mixins: [DatePickerMixin],
  props: ['showForm', 'agreement', 'options'],
  created () {
    this.loadRowDetails();
  },
  methods: {
    browse ($event) {
      if ($event.target.id === 'browseFileBtn') {
        this.$refs.chooseFile.click();
      }
    },
    downloadStreamFile: function (url, fileName, contentType) {
      downloadStreamFile(url, fileName, contentType);
    },
    loadRowDetails () {
      const that = this;
      if (this.agreement != null) {
        that.row = {...that.agreement};
      }
    },
    close () {
      eventBus.$emit('close-agreement-form');
    },
    saveAgreement () {
      /* validation */
      this.messages = [];

      if (!this.row.name) {
        this.messages.push(this.$t('agreementForm-validation.name'));
      }
      if (!this.row.startDate) {
        this.messages.push(this.$t('agreementForm-validation.startDate'));
      }
      if (!this.row.endDate) {
        this.messages.push(this.$t('agreementForm-validation.endDate'));
      }
      if (!this.row.uaoId) {
        this.messages.push(this.$t('agreementForm-validation.constraints'));
      }
      if (!this.row.signeeUserName) {
        this.messages.push(this.$t('agreementForm-validation.signeeName'));
      }
      if (!this.row.signeeSignedDate) {
        this.messages.push(this.$t('agreementForm-validation.signeeDate'));
      }

      if (!validateEmail(this.row.signeeEmail)) {
        this.messages.push(this.$t('agreementForm-validation.email'));
      }

      if (this.messages.length > 0) {
        return;
      }
      this.$http.post(this.insertUpdateAgreementUrl,
        this.row).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.messages.push(this.$t('agreementForm-updateFail') + response.data.error);
        } else {
          this.messages = [];
          this.row = response.data;
          if (this.agreement.id > 0) {
            this.messages.push(this.$t('agreementForm-update'));
          } else {
            this.messages.push(this.$t('agreementForm-add'));
          }
          this.uploadFile(this.row.id, function () { eventBus.$emit('reload-agreement-table'); });
        }
      },
      function (failed) {

      });
    },
    trackUploadFile (fieldName, fileList) {
      // handle file changes
      this.uploadFilename = '';
      this.uploadFilename = fileList[0].name;
      this.fileUploadForm = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          this.fileUploadForm.append(fieldName, fileList[x], fileList[x].name);
        });
    },
    uploadFile (id, cb) {
      if (!this.fileUploadForm || this.fileUploadForm.entries().length === 0) {
        cb();
        return;
      }
      this.$http.post(this.uploadAgreementFileUrl + id,
        this.fileUploadForm).then(function (response) {
        cb();
        if (response.data.hasOwnProperty('error')) {
          this.messages.push(this.$t('agreementForm-uploadFail') + response.data.error);
        } else {
          this.messages.push(this.$t('agreementForm-uploaded'));
          this.row = response.data;
          this.uploadFilename = '';
        }
      },
      function (error) {
        cb();
        this.messages.push(this.$t('agreementForm-uploadFail') + (error.status === 0 ? this.$t('common-failServer') : (this.$t('common-code') + ':' + error.status + ':' + error.message)));
      });
    }
  },
  data () {
    return {
      uploadAgreementFileUrl: API_URL + 'add-agreement-file-to-project?id=', // upload a agreement file pdf or doc id=2
      insertUpdateAgreementUrl: API_URL + 'add-project-usage-agreement', // create or update agreement
      deleteAgreementUrl: API_URL + 'delete-project-usage-agreement', // delete an agreement by id ?id=2'
      getAgreementFileDownload: API_URL + 'get-project-usage-agreement-file?id=',
      fileUploadForm: null,
      messages: [],
      row: {},
      uploadFilename: '',
      date: ''
    }
  }
}
</script>
<style scoped>

</style>
