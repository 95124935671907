<template>
    <div id="report-wrapper" :class="{'remote-camera': pudId != null}">
      <div class="container error padded60" v-if="error != null">
        <span>
          {{error}}
        </span>
      </div>
      <template v-if="error == null">
        <!-- top panel -->
        <div class="container overflow padded60 !pb-0 projectHeader">
          <p><a v-if="userUUID && overview" :href="getHref()">{{$t('landOwnerHome-title', {name: overview.location.name})}} </a> / </p>
          <div class="row">
              <div class="col s12 m12 l12 xl8">
                  <h1 v-if="overview" style="display:inline">{{overview.location.name}}</h1>
              </div>
          </div>
          <p>{{$t('common-year')}}: <strong v-if="overview">{{overview.project.year}} </strong>
          </p>
          <p v-if="overview"><strong>{{$t('common-description')}}:</strong> {{overview.project.description}}</p>
        </div>

          <!-- tab area -->
          <section>
        <div class="container overflow">
            <ul class="tabs">
              <li v-if="pudId" @click="switchTab('cam')" class="tab" v-bind:class="{ active: ui.activeTab == 'cam', 'cam': true}"><a v-bind:class="{ active: ui.activeTab == 'cam'}"><span class="ion-ios-camera"></span>{{$tc('common-imageSet', 1)}}</a></li>
              <li v-if="taskId" @click="switchTab('aru')" class="tab" v-bind:class="{ active: ui.activeTab == 'aru', 'aru': true}"><a v-bind:class="{ active: ui.activeTab == 'aru'}"><span class="ion-ios-mic"></span>{{$t('landOwnerReport-aruRecording')}}</a></li>
            </ul>
            <div class="padded60 boxed dataGroup tab-content" v-bind:class="{[ui.activeTab]: true}">
              <image-gallery v-if="pudId && staticOptions && overview && ui.activeTab === 'cam'"
                v-bind:uuid="userUUID" v-bind:pudId="pudId" v-bind:staticOptions="staticOptions" v-bind:mega-detector-categories="megaDetectorCategories" />
              <audio-log v-if="taskId && staticOptions && ui.activeTab === 'aru'"
                v-bind:uuid="userUUID" v-bind:taskId="taskId" v-bind:vocalizationOpt="staticOptions.vocalization"/>
            </div>
        </div>
        </section>
      </template>
        </div>
</template>

<script>
/* page that the land owner will look at specific imageset/audio */
import { eventBus } from '@/lib/eventbus';
import {getParameterByName, API_URL, LANDOWNER_REPORTS_LIST_URL} from '@/lib/common';
import { createLink } from '@/lib/language.js';
import ReportImageGallery from './ReportImageGallery';
import RecordingLogTableVue from '../ARU/RecordingLogTable.vue';

export default {
  name: 'landowner-report-page',
  components: {'image-gallery': ReportImageGallery, 'audio-log': RecordingLogTableVue},
  async created () {
    this.userUUID = getParameterByName('UUID');
    this.pudId = parseInt(getParameterByName('pudId'));
    this.taskId = parseInt(getParameterByName('taskId'));
    if (!this.userUUID) {
      this.error = this.$t('landOwnerHome-missingUUID');
      return;
    }
    if (!this.pudId && !this.taskId) {
      this.error = this.$t('landOwnerReport-missingPud');
      return;
    }

    this.ui.activeTab = this.pudId ? 'cam' : 'aru';

    await this.loadOptions();

    /* always have specie filtered */
    let self = this;
    eventBus.$on('update-filter-flag', function (queryParam) {
      let count = 0;
      if (queryParam.excludeAutoTag === false) {
        count++;
      }
      // console.log(Object.keys(queryParam).length, count, queryParam);
      self.hasFilter = (Object.keys(queryParam).length > count);
    });

    /*  eventBus.$on('reload-filter-options', function () {
      self.loadFilterOption();
    }); */

    eventBus.$on('toggle-modal-form', function (name, show) {
      self.toggleDialog(name, show);
    });
    /* when user selection of images chagned, update number in button */
    // eventBus.$on('selection-change', function (selection, name) {
    //   self.selectedImageCount = selection.length;
    // });
    // eventBus.$on('manual-selection-change', function (selection, name) {
    //   self.selectedImageCount = selection.length;
    // });
    /* show or hide form by setting ui parameter */
    eventBus.$on('toggle-form', function (name, show) {
      self.ui[name] = show;
    });
  },
  methods: {
    toggleDialog (dialogName, toShow) {
      if (toShow) {
        this.$modal.show(dialogName);
      } else {
        this.$modal.hide(dialogName);
      }
    },
    switchTab (tabName) {
      this.ui.activeTab = tabName;
      /* reload image */
      let self = this;
      if (self.$refs.filter && this.ui.activeTab === 'cam') {
        this.$nextTick(() => {
          self.$refs.filter.resetFilter();
          self.$refs.filter.filterImages();
        });
      }
    },
    async loadOptions () {
      let response;
      if (this.pudId) {
        // camera only
        response = await this.$http.get(this.getStaticCameraOptionsUrl + this.pudId + '&uuid=' + this.userUUID);
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.staticOptions = response.data;
        }
        response = await this.$http.get(this.getCameraDeploymentOverview + this.pudId + '&uuid=' + this.userUUID);
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.overview = response.data;
        }
        this.$http.get(this.getDeploymentStatusOptionUrl).then((response) => {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          } else {
            this.statusOptions = response.data.status;
            this.megaDetectorCategories = response.data.megaDetectorCategories;
          }
        }, (err) => {
        // error callback
          this.error = err;
        });
      } else {
        // aru only
        response = await this.$http.get(this.getAruOptionsUrl)
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.staticOptions = response.data;
        }
        response = await this.$http.get(this.getARUTaskOverview + this.taskId + '&uuid=' + this.userUUID);
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          // if needed, this call also provides the task info but currently only using the taskOverview info
          this.overview = response.data.taskOverview;
        }
      }
    },
    getHref () {
      return createLink(LANDOWNER_REPORTS_LIST_URL + this.userUUID);
    }
  },
  data () {
    return {
      getStaticCameraOptionsUrl: API_URL + 'get-camera-project-user-deployment-static-options?pudId=',
      getCameraDeploymentOverview: API_URL + 'get-camera-project-user-deployment-overview?id=',
      getARUTaskOverview: API_URL + 'get-task-overview?taskId=',
      getDeploymentStatusOptionUrl: API_URL + 'get-camera-pud-options',
      getAruOptionsUrl: API_URL + 'aru-task-options',
      userUUID: null,
      pudId: null,
      taskId: null,
      error: null,
      staticOptions: null,
      staticVisitOptions: null,
      overview: null,
      allVisitsInfo: null,
      visitPhotos: null,
      hasFilter: false,
      statusOptions: null,
      megaDetectorCategories: null,
      ui: {
        activeTab: null,
        showFilter: false,
        helpFormName: 'help-form'
      }
    }
  }
}
</script>

<style scoped>
.remote-camera .actionGroup .editLink.has-filter {
    color: #308233;
}
.padded.boxed.dataGroup {
  padding: 40px 20px;
}
.remote-camera #filterDrop .container.overflow {
    margin-bottom: 0px;
}
.vue .tabbed li.tab.active {
/*  border: #d6a491 2px solid; */
  border-bottom: 0px;
}
.vue .tab-content {
/*  border: #d6a491 2px solid; */
}
.vue .tabbed li.tab.active.fov {
  border: #71b3e8 2px solid;
  border-bottom: 0px;
}
.vue .tab-content.fov {
  border: #71b3e8 2px solid;
}
.vue .tabbed li.tab.active.series {
  border: #ffc107 2px solid;
  border-bottom: 0px;
}
.vue .tab-content.series {
  border: #ffc107 2px solid;
}

.alert {
  font-size: 1.1em;
}

.highlight {
  font-weight: 600;
  color: #cc0000;
}

#location-photos{
  padding-top: 0px;
}

</style>
<style scoped>
.tabs .tab a{
  cursor: default !important; /* theres only one tab on this page */
}
</style>
