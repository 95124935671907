<template>
  <div id="autogenBox">
        <div class ="tabbed-autogen">
          <ul class="tabs">
            <template v-cloak v-for="(tab, index) in tabs">
              <li @click="switchTab(tab)" :key="'tab' + index"  class="tab" :class="{'hide' : !completedCalls && tab.id === 1}">
                <a class="hyperlink"  v-bind:class="{ active: (selectedTab == tab.id)}">
                  {{tab && tab.label}}
                </a>
              </li>
            </template>
        </ul>
        </div>

    <div v-if="autogenOptions != null" :class="{hide: selectedTab != 0}" class="orange-border padded40">
    <v-server-table ref="table" :url="url" :columns="columns" :options="tableOptions" class="generate-table table vue-bordered org-table" @row-click="onRowClick" @loading="onLoading" @loaded="onLoaded">
        <template v-if="isLoading" slot="afterBody">
          <div class="loader"></div>
          <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="pulse"></pulse-loader>
          </template>
        <template slot="selected" slot-scope="props">
        <label :for="props.row.id">
            <input @change="updateCheckbox(props.row)" type=checkbox :id="props.row.uniqueId" :value="props.row.uniqueId" :checked="selected.get(props.row.uniqueId)" />
            <span></span>
            </label>
        </template>
        <template slot="action" slot-scope="props">
        {{autogenOptions.get(props.row.action)}}
        </template>
        <div slot="filter__selected">
            <label for="toggleAll">
                <input type="checkbox" id="toggleAll" class="form-control" v-model="selectedAll" @change="toggleAll()">
                <span></span>
            </label>
        </div>
    </v-server-table>
    <div class="row">
      <button class="btn btn-border btn-success modal-action modal-close" :disabled="rowsSelected === 0" @click="createMissingValues()">{{$t('autogenVisitsTable-create')}}</button>
      <button v-if="tableReady" :disabled="$refs.table.count === 0" class="btn btn-border btn-success modal-action modal-close" @click="createAll()">{{$t('autogenVisitsTable-createAll')}} ({{$refs.table.count}})</button>
      <span> {{rowsSelected}} {{$tc('autogenVisitsTable-rowSelected', rowsSelected)}}</span>
      <span v-if="errorUploading">{{errorUploading}}</span>
    </div>
    </div>
    <div :class="{hide: selectedTab != 1}">
      <generate-and-select-results v-bind:completedCalls="completedCalls" v-bind:callsBegan="callsBegan" v-bind:numSuccess="numSuccess" v-bind:errorResultsTableData="errorResultsTableData" v-bind:progressNum="progressNum" v-bind:progressTotal="progressTotal"></generate-and-select-results>
      </div>
  </div>
</template>

<script>
/* this component is the table portion for the generate and select form, also contains the tabs, is parent of results page
Babel is not allowing me to use Promise.allSettled() so for now Im using Promise.all() with no rejects
the returned objects will be in the same format as a promise.allSettled() ie {status, value} or {status, reason}
So if youd like to change the babel file you wont need to change a lot here :)
*/
import { API_URL } from '@/lib/common';
import FormMixin from '@/components/common/FormMixin.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import AutogenerateVisitsResults from './AutogenerateVisitsResults.vue';
import { eventBus } from '@/lib/eventbus';

export default {
  name: 'generate-and-select-table',
  props: ['organizationId', 'url'],
  components: {'pulse-loader': PulseLoader, 'generate-and-select-results': AutogenerateVisitsResults},
  mixins: [FormMixin],
  created () {
    this.$http.get(this.getAutogenOptionsUrl).then(
      function (response) {
        this.autogenOptions = new Map();
        response.data.codes.forEach(pair => {
          this.autogenOptions.set(pair.id, pair.type);
        });
      });
  },
  methods: {
    toggleAll () {
      // this.selected = this.selectedAll ? this.$refs.table.data.map(row => row.uniqueId) : [];
      if (this.selectedAll) {
        this.$refs.table.data.map(row => {
          // this.selected[row.uniqueId].checked = true;
          this.selected.set(row.uniqueId, true);
        });
        this.selectCount = this.selected.size; // this.$refs.table.data.length;
      } else {
        this.selected.clear();
        this.selectCount = 0;
      }
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.uniqueId;
    },
    onLoading (event) {
      this.isLoading = true;
    },
    onLoaded (event) {
      this.tableReady = true;
      this.isLoading = false;
    },
    switchTab (tab) {
      this.selectedTab = tab.id;
    },
    updateCheckbox (row) {
      this.errorUploading = null;
      if (this.selected.has(row.uniqueId)) {
        this.selected.delete(row.uniqueId);
        this.selectCount--;
      } else {
        this.selected.set(row.uniqueId, true);
        this.selectCount++;
      }
    },
    async createMissingValues () {
      // need to create missing location deployments, visits, and equipment
      // show results tab
      this.switchTab(this.tabs[1]);
      this.callsBegan = true;
      this.numSuccess = 0;
      this.completedCalls = false;
      this.errorResultsTableData = [];
      this.errorUploading = null;
      this.progressNum = 0;
      this.progressTotal = this.selectCount;

      // want to limit the number of calls being sent to the server at once
      const concurrentTaskCount = Math.min(this.selectCount, this.parallelUploadCount);
      this.getAndPassBack(this.url + '&orderBy=locationName&isDesc=false&limit=' + this.$refs.table.count + '&page=1', null, (response) => {
        // getting all pages is faster than making several calls for several pages
        this.allRows = response.body.results;
        this.getRows(concurrentTaskCount, 0);
      }, (failed) => {
        this.errorUploading = failed;
      });
    },
    async getRows (concurrentTaskCount, startIndex) {
      let rows = [];

      for (let index = startIndex; index < this.allRows.length; index++) {
        if (!this.isCreateAll && this.selected.has(this.allRows[index].uniqueId)) {
          rows.push(this.allRows[index]);
        }
        if (this.isCreateAll) {
          rows.push(this.allRows[index]);
        }
        if (rows.length === this.rowsSentAtOnce) {
          // this.handleConcurrentGroup(rows, concurrentTaskCount, index);
          await this.sendRows(rows);
          rows = [];
          continue;
        }
        if (!this.isCreateAll && (rows.length + this.numSuccess === this.selectCount)) {
          // we dont need to continue looping if we have everything
          // this.handleConcurrentGroup(rows, concurrentTaskCount, -1);
          await this.sendRows(rows);
          return;
        }
      }
      // for create all -- if the loop finishes and theres still some rows to do
      if (rows.length > 0) {
        await this.sendRows(rows);
        // await this.handleConcurrentGroup(rows, concurrentTaskCount, -1);
      }
    },
    sendRows (rows) {
      // rows are now being handled completely in the backend
      // 100 being sent in at one time
      return new Promise((resolve, reject) => {
        this.$http.post(this.applyAutogenRowURL, rows).then(response => {
          if (response.body.hasOwnProperty('error')) {
            // overall api failure eg no connection
            // TODO
          } else {
            this.progressNum = this.progressNum + response.body.length;
            response.body.forEach(row => {
              if (row.message === 'success') {
                this.numSuccess++;
              } else {
                this.errorResultsTableData.push({
                  locationName: row.locationName,
                  deployDate: row.deployDate,
                  retrieveDate: row.retreiveDate,
                  message: this.$tc('common-error', 1) + ': ' + row.error
                });
              }
            });
            if ((!this.isCreateAll && this.numSuccess + this.errorResultsTableData.length === this.selectCount) || (this.isCreateAll && this.numSuccess === this.allRows.length)) {
              this.finishedCalls();
            }
            resolve();
          }
        },
        function (er) {
          // problem with call
          // TODO
        }
        );
      });
    },
    finishedCalls () {
      this.completedCalls = true;
      this.progressTotal = null;
      eventBus.$emit('reload-summary-table');
      this.$refs.table.refresh();
      this.selectCount = 0;
      this.selectedAll = false;
      this.selected.clear();
      this.isCreateAll = false;
    },
    createAll () {
      // user clicked the 'create all' button. Create all rows on all pages
      this.switchTab(this.tabs[1]);
      this.callsBegan = true;
      this.numSuccess = 0;
      this.completedCalls = false;
      this.errorResultsTableData = [];
      this.errorUploading = null;
      this.progressNum = 0;
      this.isCreateAll = true;

      this.getAndPassBack(this.url + '&orderBy=locationName&isDesc=false&limit=' + this.$refs.table.count + '&page=1', null, (response) => {
        this.progressTotal = response.body.total_row_count;
        this.allRows = response.body.results;
        this.getRows(this.parallelUploadCount, 0);
      }, (failed) => {
        // TODO: put error here
        this.errorUploading = failed;
      });
    }
  },
  computed: {
    rowsSelected: function () {
    /*  if (this.selectedAll) {
        // all pages/rows have been selected note: selectedAll can still be true even if some items on current page have been deselected! so subtract current page and add selected
        return this.$refs.table.count - this.$refs.table.data.length + this.selectCount;
      } else {
        */
      return this.selectCount;
      // }
    }
  },
  data () {
    const pageSize = 100;
    return {
      createLocationUrl: API_URL + 'insert-update-location',
      createUpdateVisitUrl: API_URL + 'insert-update-location-visit',
      registerNewEquipmentUrl: API_URL + 'insert-update-delete-equipment',
      getEquipmentBySerialUrl: API_URL + 'get-equipment-by-serial?organizationId=',
      getAllVisitsURL: API_URL + 'get-all-location-visits?locationId=',
      getAutogenOptionsUrl: API_URL + 'get-autogen-options',
      applyAutogenRowURL: API_URL + 'apply-autogen-row',
      parallelUploadCount: 100,
      rowsSentAtOnce: 100,
      allRows: null,
      isCreateAll: false,
      callsBegan: false,
      completedCalls: false,
      errorResultsTableData: [], // show rows that had problems
      numSuccess: 0, // number of rows that had no problems
      progressNum: 0, // show the user progress instead of just a loading symbol
      progressTotal: null,
      errors: [],
      errorUploading: null,
      autogenOptions: null,
      selected: new Map(), // key: uniqueId, value: ischecked according to SO can hold a max of 16.7 M keys so should be ok
      selectedAll: false,
      selectCount: 0,
      tableReady: false,
      isLoading: false,
      selectedTab: 0,
      tabs: [{
        id: 0,
        label: this.$tc('common-location', 2),
        class: 'table'
      }, {
        id: 1,
        label: this.$t('autogenVisitsTable-log'),
        class: 'log'
      }
      ],
      columns: ['selected', 'locationName', 'deployDate', 'retreiveDate', 'recordingSerials', 'visitDeploy', 'visitRetreive', 'visitSerial', 'action'],
      tableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: false, // true,
        filterable: false, // ['locationName', 'deployDate', 'retreiveDate', 'visitDeploy', 'visitRetreive', 'recordingSerials', 'action'],
        sortable: ['locationName', 'deployDate', 'retreiveDate', 'visitDeploy', 'visitRetreive'],
        listColumns: {
        },
        headings: {
          selected: '',
          locationName: this.$t('autogenVisitsTable-headings.locationName'),
          deployDate: this.$t('autogenVisitsTable-headings.deployDate'),
          retreiveDate: this.$t('autogenVisitsTable-headings.retrieveDate'),
          recordingSerials: this.$t('autogenVisitsTable-headings.recordingSerials'),
          visitDeploy: this.$t('autogenVisitsTable-headings.visitDeploy'),
          visitRetreive: this.$t('autogenVisitsTable-headings.visitRetreive'),
          visitSerial: this.$t('autogenVisitsTable-headings.visitSerial'),
          action: this.$t('autogenVisitsTable-headings.action')
        },
        columnsClasses: {
          selected: 'selected',
          locationName: 'locationName',
          deployDate: 'deployDate',
          retreiveDate: 'retreiveDate',
          recordingSerials: 'recordingSerials',
          visitDeploy: 'visitDeploy',
          visitRetreive: 'visitRetreive',
          visitSerial: 'visitSerial',
          action: 'action'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [50, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        orderBy: {column: 'locationName', ascending: true},
        rowClassCallback (row) {
          return (row.uniqueId === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            orderBy: data.orderBy ? data.orderBy : this.orderBy.column,
            isDesc: data.ascending ? 'false' : 'true',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style scoped>

.hide{
  display: none;
}

.generate-table th.locationName {
  width: 10%;
}

.generate-table th.recordingSerials {
  width: 10%;
}

.generate-table th.visitSerial {
  width: 10%;
}

.generate-table
.VueTables__table td,
.generate-table .VueTables__table th {
  text-align: center;
}

.table{
  width: 100%;
  position: relative;
}

.pulse{
  position: absolute;
	left: 50%;
	right: 50%;
  top: 30px;
  z-index: 1000;
/*bottom: 60%;
	top: 40%; */
}

.loader {
  position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.8;
	filter: blur(5px);
}

.tabbed-autogen{
  width: 30%;
}

#autogenBox .tabs li.tab a.active,
#autogenBox .tab-content,
.orange-border
{
  border: 1px solid #d89b9b;
}

#autogenBox .tabs li.tab a.active {
  background-color: #FFF;
}

#autogenBox .tabs li.tab a {
  background-color: #f9f9f9;
}

.VueTables__limit {
  bottom: 0px;
}

.VueTables__table{
  overflow:visible !important;
}

.tab{
  width: 100%;
}
</style>
