<template>
  <div class="row">
    <audio ref="audio-player" @loadeddata="audioLoaded" preload="auto">
      <source v-if="recording.filePath" :src="recording.filePath" type="audio/mpeg" />
    </audio>
    <audio ref="audio-player-dynamic" @loadeddata="audioLoaded" preload="auto">
      <source v-if="recording.file" :src="`data:audio/${recording.fileType};base64,${recording.file}`" />
    </audio>
    <div class="col s12 m12" :class="{ l8: showForm, xl9: showForm, l12: !showForm }" id="listeningPanel">
        <div v-if="task.length > 600" class="bg-blue-100 text-blue-400 border border-blue-400 rounded mb-10 p-3 text-base">
            <i class="fa-solid fa-circle-info"></i> {{$t('Task length is larger than 600 seconds. It may take a few moments for the spectrogram to load completely.')}}
          </div>
      <div v-show="!Object.keys(recording).length || !Object.entries(this.options).length" class="overaly">
        <div style="text-align: center;" class="my-64">
          <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
          <div> {{ $t('common-tableTexts.loading') }} </div>
        </div>
      </div>
      
      <div v-show="Object.keys(recording).length">
        <modal name="parameterModal" height="auto" v-if="options.spectrogramOptions">

          <div class="p-5 relative">
            <i @click="hideParameterModal" class="fas fa-times absolute right-3 top-3 cursor-pointer"></i>
            <h5>{{ $t('Settings') }}</h5>
            <div class="grid grid-cols-2 gap-2">
              <div>
                <label>{{ $t('Channel') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.channelfilter')"></span></label>
                <div>
                  <select v-model="dynParams.channel">
                    <option v-for="(channel, index) in options.spectrogramOptions.channel" :value="channel.value"
                      :key="index">{{ channel.description }}</option>
                  </select>
                </div>
              </div>
              <div>
                <label>{{ $t('Amplify') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.amplify')"></span></label>
                <div>
                  <select v-model="dynParams.amplifyId">
                    <option v-for="(value, index) in options.spectrogramOptions.amplify" :value="value.value"
                      :key="index">{{ value.description }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-2">
              <div>
                <label>{{ $t('Minimum Frequency (Hz)') }}</label>
                <div>
                  <input type="number" v-model="dynParams.minFreqHz" @keyup="validateMinFreq" />
                </div>
              </div>
              <div>
                <label>{{ $t('Maximum Frequency (Hz)') }}</label>
                <div>
                  <input type="number" v-model="dynParams.maxFreqHz" @keyup="validateMaxFreq" />
                </div>
              </div>
            </div>
            <label>{{ $t('X Scale') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time.')"></span></label>
            <div :class="{'bg-yellow-100 text-yellow-500 p-1 text-sm':dynParams.xScale>=5 && !isUltraSonic() }">
               <span v-show="dynParams.xScale>=5&&!isUltraSonic()">High X-scale spectrograms are only recommended for ultra sonic recordings</span>
                <div class="flex">
                  <label class="flex-initial w-10 text-center">{{ dynParams.xScale }}</label>
                
                  <input type="range" class="flex-initial" min="0.25"
                    max="10" step="0.25" v-model="dynParams.xScale" />
                  
                </div>
           </div>
            <!-- 
                <label>Y Scale</label>
                <div class="flex"> 
                  <label class="flex-1">{{dynParams.yPixels}}</label> <input type="range" class="flex-init" min="0.5" max="10" step="0.1"  v-model="dynParams.yPixels" />
                </div> 
              -->
            <div>
              <label>{{ $t('Y Pixels') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.Y-scale')"></span></label>
              <div>
                <select v-model="dynParams.yPixels">
                  <option v-for="(value, index) in options.spectrogramOptions.spectrogramYPixels" :value="value.id"
                    :key="index">{{ value.value }}</option>
                </select>
              </div>
            </div>
            <div
              :class="{ 'grid gap-2 grid-cols-3': true }">
              <div>
                <label>{{ $t('Use Monochrome') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms.')"></span></label>
                <div>
                  <i v-if="dynParams.useMonochrome" @click="dynParams.useMonochrome = false"
                    class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                  <i v-if="!dynParams.useMonochrome" @click="dynParams.useMonochrome = true"
                    class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                </div>
              </div>
              <div>
                <label>{{ $t('Use Lightmode') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms.')"></span></label>
                <div>
                  <i v-if="tempLightMode" @click="tempLightMode = false"
                    class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                  <i v-if="!tempLightMode" @click="tempLightMode = true"
                    class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                </div>
              </div>
              <div>
                <label>{{ $t('Generate Audio') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('When toggled on, only the audio between the selected frequency ranges will be played.')"></span></label>
                <div>
                  <i v-if="dynParams.generateAudio" @click="dynParams.generateAudio = false"
                    class="fas fa-toggle-on !block text-4xl text-green-400 "></i>
                  <i v-if="!dynParams.generateAudio" @click="dynParams.generateAudio = true"
                    class="fas fa-toggle-off !block text-4xl text-red-400 "></i>
                </div>
              </div>
          

            </div>
            <div class="grid grid-cols-3 gap-2">
              <div>
                <label>{{ $t('Noise Filter') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.noisefilter')"></span></label>
                <div>
                  <select v-model="dynParams.noiseFilterId">
                    <option v-for="(value, index) in options.spectrogramOptions.noiseFilter" :value="value.value"
                      :key="index">{{ value.description }}</option>
                  </select>
                </div>
              </div>
              <div>
                <label>{{ $t('Contrast') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.Z-contrast')"></span></label>
                <div>
                  <select v-model="dynParams.contrastId">
                    <option v-for="(value, index) in options.spectrogramOptions.spectrogramZContrast"
                      :value="value.value" :key="index">{{ value.description }}</option>
                  </select>
                </div>
              </div>
              <div>
                <label>{{ $t('Brightness') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.Z-brightness')"></span></label>
                <div>
                  <select v-model="dynParams.brightnessId">
                    <option v-for="(value, index) in options.spectrogramOptions.spectrogramZBrightness"
                      :value="value.value" :key="index">{{ value.description }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-show="dynParamModalError" class="text-red-700">
              {{ dynParamModalError }}
            </div>
            <div class="grid grid-cols-2 gap-2">
              <button class="btn btn-success block w-full" :disabled="dynParamApplyButtonDisabled"
                @click="submitParameters()">
                {{ $t('Apply') }}
              </button>
              <button class="btn btn-success block w-full" @click="resetParameters()">
                {{ $t('Reset') }}
              </button>
            </div>

          </div>
        </modal>
        <div class="spectrogramWrap">
          <div v-if="numOutOfBounds > 0" class="bg-blue-100 border border-blue-400 rounded mb-10 p-3 text-base">
            <i class="fas fa-info-circle"></i>
            <span>{{ $tc('recordingPlayer-OOB-message', numOutOfBounds, { num: numOutOfBounds }) }}</span>
            <ul class="!list-decimal list-inside" v-if="$store.state.Species.species">
              <li class="!list-decimal" v-for="(tag, index) in outOfBoundsList()">
                {{
                  $store.state.Species.species.find(s => s.id === tag.speciesId).code
              }} from {{ Math.ceil(tag.minFreq / 1000) }}
                kHz to {{ Math.ceil(tag.maxFreq / 1000) }} kHz</li>
            </ul>
          </div>
          <div v-if="isUltraSonic() && isFirefox()" class="bg-red-100 text-red-400 border border-red-400 rounded mb-10 p-3 text-base">
            <i class="fa-solid fa-triangle-exclamation"></i> {{$t('Playback may not work due to a Firefox issue. We recommend using Chrome to playback audio with high frequencies.')}}
          </div>
        
          <label v-show="recording.showLeftChannel">{{ $t('common-left') }}</label>
          <div v-show="recording.showLeftChannel" id="spectrogramWrap-left" class="singleWrap">
            <div class="spectrogram-index-marker"
              :style="'left:' + leftOffset + 'px; height: ' + (recording.totalHeight + 40) + 'px;'"></div>
            
            <!-- <div class="absolute top-[1px] left-[46px] text-[10px] z-[200]">{{this.recording.maxFreqHZ/1000}}</div> -->
            <div class="yPixels border-t border-[#d9d9d9]" :style="{ position: 'absolute', top: '0px', left: '1px', 'z-index': getTagCount }"></div>
            <!-- <div class="absolute bottom-[18px] text-[10px] left-[37px] z-[200] ">{{this.recording.minFreqHz/1000}}</div> -->
            <!-- <img :alt="$t('recordingPlayers-yAxis')" :src="verticalAxis" :style="{position: 'absolute', top: '1px', left: '1px', 'z-index': getTagCount}"> -->

            <div id="spectrogram-display-left" ref="spectrogram-display-left" class="spectrogram-display"
              :class="{ noscroll: !paused }" :style="'height: ' + (this.recording.totalHeight + 40) + 'px;'">

              <div ref="spectrogram-images-left" id="spectrogram-images-left" :class="{ forbidden: !isEditable }"
                :style="{ left: leftOffset + 'px', width: (recording.totalLengthPixel + displayWidth + leftOffset) + 'px', height: (recording.totalHeight) + 'px' }"
                @mousedown.prevent="draw_box_start($event, 'left')"
                @mousemove.prevent="draw_box_drawing($event, 'left')"
                @mouseup.prevent="draw_box_complete($event, 'left')"
                @mouseleave.prevent="draw_box_complete($event, 'left')" v-if="recording.spectrograms">

                <div>
                  <div v-for="(image, index) in recording.spectrograms.filter(i => i && i.channel === channelIDs.left)"
                    :key="index">
                    <img :src="`data:image/${image.fileType};base64, ${image.imageData}`" class="float-left">
                    <div v-if="task.methodId === 2"
                      :style="{ left: (index) * ((recording.totalLengthPixel/recording.totalLengthSec)*60) + 'px', top: '0px', width: '1px', height: recording.totalHeight + 'px', 'border-left': 'thin #CC0000 solid', position: 'absolute' }">
                    </div>
                  </div>
                  <div class="xscale"
                    :style="{ width: (recording.totalLengthPixel) + 'px', float: 'left', 'max-width': '100%', 'vertical-align': 'middle', position: 'absolute', left: 0, top: (recording.totalHeight) + 'px' }">
                  </div>
                  <div v-if="showLeftTagBoxes">
                    <div v-for="(tags, index) in visibleTags()" v-bind:key="'leftTagUser' + index"
                      :style="{ width: (recording.totalLengthPixel) + 'px', height: (recording.totalHeight) + 'px' }">
                      <vue-draggable-resizable
                        v-if="tagRow.maxFreq <= dynParams.maxFreqHz && tagRow.minFreq >= dynParams.minFreqHz"
                        v-bind="calculateTagCoordinates(tagRow)"
                        :style="{color: tagRow.id == getParam('tagId') ? colors('readonly') : getColor(tags.taskId), 
                                  'background-color': tagRow.id == getParam('tagId') ? colors('readonly',20) : getColor(tags.taskId, false, 20)}"
                        :class="{ 'shadow': true, 'shadow-black':dynParams.lightMode,'shadow-white':!dynParams.lightMode, 'hideBorder !shadow-none ': hideBoxes }"
                        v-for="(tagRow, tagIndex) in tags.tag" v-bind:key="'leftTag' + tagIndex"
                        :active="tagRow.id === tagId" :tag="tagRow"
                        :minw="minBoxWidth" :minh="minBoxWidth" :resizable="isEditable"
                        v-on:resizestop="resize_box_complete" v-on:dragging="dragging_box"
                        v-on:dragstop="drag_box_complete" v-on:dblclick="editTag"
                         @activated="()=>{selectedTag=tagRow.id;}"
                        :draggable="isDraggable && isEditable">
                        <span class="tag-label" v-if="tagRow.speciesIndividual != null && showTagText">
                          {{ label(tagRow) }}</span>
                      </vue-draggable-resizable>
                      <div v-for="(markerRow, markIndex) in tags.marker" class="mark_box"
                        v-bind:key="'leftMarker' + markIndex" @mousedown="deleteMarker(markerRow)"
                        :id="'l' + markerRow.id" :class="{ 'highlighted_mark_box': selectedMarkerIndex === markIndex }"
                        :style="{ left: markerRow.startTime * recording.pixelsPerSecond + 'px', top: '0px', width: '10px', height: (recording.totalHeight) + 'px', 'border-color': getColor(tags.taskId) }"
                        :title="$t('recordingPlayers-clickToDelete')">
                      </div>
                    </div>
                  </div>
                  <div class="sel_box new_sel_box"
                    :style="{ left: drawBox.boxStartX + 'px', top: drawBox.boxStartY + 'px', width: (drawBox.boxWidth) + 'px', height: (drawBox.boxHeight) + 'px' }">
                  </div>
                </div>
              </div>

            </div>
          </div>
          <label v-show="recording.showRightChannel">{{ $t('common-right') }}</label>
          <div v-show="recording.showRightChannel" id="spectrogramWrap-right" class="singleWrap">
            <div class="spectrogram-index-marker"
              :style="'left:' + leftOffset + 'px; height: ' + (recording.totalHeight + 40) + 'px;'"></div>
             <!-- <div class="absolute top-[1px] left-[46px] text-[10px] z-[200]">{{this.recording.maxFreqHZ/1000}}</div> -->
            <div class="yPixels border-t border-[#d9d9d9]" :style="{ position: 'absolute', top: '0px', left: '1px', 'z-index': getTagCount }"></div>
            <!-- <div class="absolute bottom-[18px] text-[10px] left-[37px] z-[200] ">{{this.recording.minFreqHz/1000}}</div> -->

            <!-- <img :alt="$t('recordingPlayers-yAxis')" :src="verticalAxis" :style="{position: 'absolute', top: '1px', left: '1px', 'z-index': getTagCount}"> -->
            <div id="spectrogram-display-right" ref="spectrogram-display-right" class="spectrogram-display"
              :style="'height: ' + (recording.totalHeight + 40) + 'px;'">
              <div style="width: 100px; height: 50px; text-align: left; z-index: 0; position: absolute; left: 400px">
                <div id="pl-twrap-right"></div>
              </div>

              <div id="spectrogram-images-right" ref="spectrogram-images-right" :class="{ forbidden: !isEditable }"
                :style="{ left: leftOffset + 'px', width: (recording.totalLengthPixel + displayWidth + leftOffset) + 'px', height: (recording.totalHeight + 40) + 'px' }"
                @mousedown.prevent="draw_box_start($event, 'right')"
                @mousemove.prevent="draw_box_drawing($event, 'right')"
                @mouseup.prevent="draw_box_complete($event, 'right')"
                @mouseleave.prevent="draw_box_drawing($event, 'right')"
                v-if="recording.showRightChannel && recording.spectrograms">

                <div>
                  <div v-for="(image, index) in recording.spectrograms.filter(i => i && i.channel === channelIDs.right)"
                    :key="index">
                    <img :src="`data:image/${image.fileType};base64, ${image.imageData}`" class="float-left">
                    <div v-if="task.methodId === 2"
                      :style="{ left: (index) * ((recording.totalLengthPixel/recording.totalLengthSec)*60) + 'px', top: '0px', width: '1px', height: recording.totalHeight + 'px', 'border-left': 'thin #CC0000 solid', position: 'absolute' }">
                    </div>
                  </div>

                  <div class="xscale"
                    :style="{ width: (recording.totalLengthPixel) + 'px', float: 'left', 'max-width': '100%', 'vertical-align': 'middle', position: 'absolute', left: 0, top: (recording.totalHeight) + 'px' }">
                  </div>
                  <!-- <img :alt="$t('recordingPlayers-xAxis')" :src="horizontalAxis"/> -->
                  <div v-if="showRightTagBoxes">
                    <div v-for="(tags, index) in visibleTags()" v-bind:key="'rightTagUser' + index"
                      :style="{ width: (recording.totalLengthPixel) + 'px', height: (recording.heightHz + 40) + 'px' }">
                      <vue-draggable-resizable
                        v-if="tagRow.maxFreq <= dynParams.maxFreqHz && tagRow.minFreq >= dynParams.minFreqHz"
                        v-for="(tagRow, tagIndex) in tags.tag" 
                        v-bind:key="'rightTag' + tagIndex"
                        :style="{color: tagRow.id == getParam('tagId') ? colors('readonly') : getColor(tags.taskId), 
                                  'background-color': tagRow.id == getParam('tagId') ? colors('readonly',20): getColor(tags.taskId, false, 20)}"
                        :class="{ 'shadow': true, 'shadow-black':dynParams.lightMode,'shadow-white':!dynParams.lightMode, 'hideBorder !shadow-none ': hideBoxes }"
                        :active="tagRow.id === tagId" 
                        :tag="tagRow"
                        v-bind="calculateTagCoordinates(tagRow)" 
                        :minw="minBoxWidth" 
                        :minh="minBoxWidth"
                        :resizable="isEditable" 
                        v-on:resizestop="resize_box_complete" 
                        v-on:dragging="dragging_box"
                        v-on:dragstop="drag_box_complete" 
                        v-on:dblclick="editTag"
                        @click="()=>{this.selectedTag=tagRow.id}"
                         @activated="()=>{selectedTag=tagRow.id;}"
                        :draggable="isDraggable && isEditable">
                        <span class="tag-label" v-if="tagRow.speciesIndividual != null && showTagText">{{ label(tagRow)}}</span>
  
                      </vue-draggable-resizable>
                      <div v-for="(markerRow, markerIndex) in tags.marker" class="mark_box"
                        v-bind:key="'leftMarker' + markerIndex" @mousedown="deleteMarker(markerRow)"
                        :id="'l' + markerRow.id"
                        :class="{ 'highlighted_mark_box': selectedMarkerIndex === markerIndex }"
                        :style="{ left: markerRow.startTime * recording.pixelsPerSecond + 'px', top: '0px', width: '10px', height: (recording.totalHeight) + 'px', 'border-color': getColor(tags.taskId) }"
                        :title="$t('recordingPlayers-clickToDelete')">
                      </div>
                    </div>
                  </div>
                  <div class="sel_box new_sel_box"
                    :style="{ left: drawBox.boxStartX + 'px', top: drawBox.boxStartY + 'px', width: (drawBox.boxWidth) + 'px', height: (drawBox.boxHeight) + 'px' }">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- control -->
        <div class="controlWrap" v-if="audioTag">
          <div class="tool-items">
            <a id="btnPlay" data-toggle="tooltip" data-placement="bottom" :title="$t('recordingPlayers-play')"
              @click="() => { toggle();}"><span :class="{ 'ion-play': paused, 'ion-pause': !paused }"></span></a>
            <a id="btnBookmark" class="cursor-pointer" data-toggle="tooltip" data-placement="bottom"
              :title="'Set parameters'" @click="showParameterModal">
              <i class="fas fa-cog"></i>
            </a>
            <a id="btnReplay" data-toggle="tooltip" data-placement="bottom" :title="$t('recordingPlayers-replay')"
              @click="restart"><span :class="{ 'ion-refresh': paused }"></span></a>
            <div id="media_position"><span id="currentTime">0</span>s {{ $t('common-audioProgress', {
                position:
                  "", length:
                  seconds_to_minutes_seconds(recording.totalLengthSec)
              })
            }}
              <span class="error link" v-if="!canPlayAudio" @click="playAudioIssue">
                {{ $t('common-audioProblems') }}
              </span>
            </div>
          </div>


          <div class="legend grid grid-cols-3 gap-2" v-show="showAllTasks">
            <div class="transcriber" v-for="(user) in legendTaskUsers" v-bind:key="'withData' + user.taskId">
              <template >
                <div class="legend_name">{{ user.userName }}</div>
                <input type="checkbox" :value="user.taskId" v-model="legendList">
                <!-- <div class="legend_square" :style="'background: ' + getColor(user.taskId, true,40)"></div> -->
               
              </template>
            </div>
      
          </div>
          <div class="p-3" v-show="showAllTasks">
            <span class="btn btn-default float-right" @click="toggleAllCheckmarks">{{$t('Turn off all users')}}</span>
          </div>
          <div class="legend" v-show="!showAllTasks && myMarkers.length > 0">
            {{ $tc('recordingPlayers-numMarkers', myMarkers.length, { num: myMarkers.length }) }}
          </div>

        </div>
           <div v-if="task.id===370154" class="btn btn-success" @click="seek(11.74)">Seek to 11.74s</div>
        <div class="params" v-if="options && options.spectrogramOptions">
          <h5>{{ $t('Current settings') }}</h5>
          <table>
            <tr>
              <th>{{ $t('Channel') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.channelfilter')"></span></th>
              <td>{{ (options.spectrogramOptions.channel.find(v => v.value === dynParams.channel) || {}).description }}
              </td>
              <th>{{ $t('Amplify') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.amplify')"></span></th>
              <td>{{ (options.spectrogramOptions.amplify.find(v => v.value === dynParams.amplifyId) || {}).description
              }}</td>
              <th>{{ $t('Noise Filter') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.noisefilter')"></span></th>
              <td>{{ (options.spectrogramOptions.noiseFilter.find(v => v.value === dynParams.noiseFilterId) ||
                  {}).description
              }}</td>
              <th>{{ $t('Contrast') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.Z-contrast')"></span></th>
              <td>{{
                  (options.spectrogramOptions.spectrogramZContrast.find(v => v.value === dynParams.contrastId) ||
                    {}).description
              }}</td>
              <th>{{ $t('Brightness') }}<span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.Z-brightness')"></span></th>
              <td>{{
                  (options.spectrogramOptions.spectrogramZBrightness.find(v => v.value === dynParams.brightnessId) ||
                    {}).description
              }}</td>
            </tr>
            <tr>
              <th>{{ $t('X Scale') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('Use the slider to change the amount of seconds displayed on the spectrogram. A lower value will display more time whereas a larger value will display less time.')"></span></th>
              <td>{{ dynParams.xScale }}x</td>
              <th>{{ $t('Y Height') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('aruVerificationModal-tooltips.Y-scale')"></span></th>
              <td>{{ dynParams.yPixels }}px</td>
              <th>{{ $t('Minimum Frequency (Hz)') }}</th>
              <td>{{ dynParams.minFreqHz }}</td>
              <th>{{ $t('Maximum Frequency (Hz)') }}</th>
              <td>{{ dynParams.maxFreqHz }}</td>
              <th>{{ $t('Monochrome') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('When toggled on, default spectrogram colours are in monochrome (black and white). Toggle off to enable coloured spectrograms.')"></span></th>
              <td class="text-center"><i v-show="dynParams.useMonochrome"
                  class="fas fa-check-circle text-green-500"></i><i v-show="!dynParams.useMonochrome"
                  class="fas fa-times-circle text-red-500"></i>
              </td>
              <th>{{ $t('Lightmode') }} <span  class="ion-information-circled text-yellow-500" v-tooltip="$t('When toggled on, default spectrogram are light colours (black and white). Toggle off to enable dark spectrograms.')"></span></th>
              <td class="text-center"><i v-show="dynParams.lightMode"
                  class="fas fa-check-circle text-green-500"></i><i v-show="!dynParams.lightMode"
                  class="fas fa-times-circle text-red-500"></i>
              </td>
            </tr>
          </table>
        </div>
        <h4>{{ $t('recordingPlayers-loggedSounds') }}</h4>
        <div><span class="mode-heading">{{ $t('recordingPlayers-playMode') }}:</span> <span class="playMode">
            <span class="mode" :class="{ active: logTableAutoPlay, 'ion-checkmark': logTableAutoPlay }"
              @click="logTableAutoPlay = true"> {{ $t('recordingPlayers-auto') }}</span>
            <span class="mode" :class="{ active: !logTableAutoPlay, 'ion-checkmark': !logTableAutoPlay }"
              @click="logTableAutoPlay = false"> {{ $t('recordingPlayers-manual') }}</span>
          </span>
        </div>
        <log-table v-if="tagList.length > 0" v-bind:task-id="task.id" v-bind:vocalization-opt="options.vocalization"
          v-bind:taggers="taskUsers" v-bind:is-admin-view="showAllTasks" :species="species"></log-table>
      </div>
    </div>
    <recording-form ref="form" v-if="showForm" v-bind:show-tag-form="showTagForm" v-bind:tag-permitted="isEditable"
      v-bind:task-update-message="taskUpdateMessage" v-bind:task="task" v-bind:options="options"
      v-bind:task-overview="taskOverview" v-bind:tag-id="tagId" v-bind:draw-box="drawBox"
      v-bind:is-editable="isEditable" v-bind:to-scroll="toScroll" v-bind:edit-parent-only="editParentOnly"
      v-bind:modify="modify" :species="species"></recording-form>

  </div>
  <!-- end of player -->
</template>

<script>

import RecordingLogTable from '@/components/ARU/RecordingLogTable';
import RecordingForms from '@/components/ARU/RecordingForms';
import VueDraggableResizable from '@/components/utils/drag-resize-box/vue-draggable-resizable'
import { eventBus } from '@/lib/eventbus';
import { API_URL, alertDialog, getParameterByName } from '@/lib/common';
import anime from 'animejs/lib/anime.es';
import * as d3 from 'd3';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import moment from 'moment';
import { readonly } from 'vue';


export default {
  name: 'recording',
  // components: {'project-table': ProjectTable},
  props: ['showForm', 'taskOverview', 'options', 'task', 'showAllTasks', 'taskUpdateMessage', 'modify', 'loading', 'optionsLoaded'],
  components: {
    'log-table': RecordingLogTable,
    'recording-form': RecordingForms,
    'vue-draggable-resizable': VueDraggableResizable,
    'pulse-loader': PulseLoader
  },
  created() {
    this.$store.dispatch('Species/getAllSpecies');
    const that = this;
    /* edge doesn't support smooth scrolling, so check before use the option */
    this.supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

    eventBus.$on('update-tag', function (tag) {
      // Remove species object in place of ID only. API change.
      // tag.speciesIndividual.speciesId = tag.speciesIndividual.species.id;
      // delete tag.speciesIndividual.species;
      that.updateTag(tag);
    });
    eventBus.$on('delete-tag', function (tagId) {
      that.deleteTag(tagId);
    });
    eventBus.$on('show-tag-form', function (show) {
      that.showTagForm = show;
      if (show === false) {
        that.tagId = null;
      }
    });
    // open tag editing box, when clicked from log table
    eventBus.$on('edit-tag', function (tagObj) {
      that.editTag(tagObj.tagId, true, tagObj.isParent);
      if (!tagObj.isParent) {
        // 80px = 1s so go back 1s before the tag instead of at tag start.
        that.scrollTo((tagObj.tagStartTime * that.recording.pixelsPerSecond) > that.recording.pixelsPerSecond ? tagObj.tagStartTime * that.recording.pixelsPerSecond - that.recording.pixelsPerSecond : tagObj.tagStartTime * that.recording.pixelsPerSecond, that.logTableAutoPlay);
      }
    });

    eventBus.$on('tags-loaded', function (tagList) {
      if (!tagList) return;

      let selTag = tagList[0].tag.find(tag => tag.id == that.getParam('tagId'));
      if (selTag) {
        that.scrollTo(selTag.boxStartX);
      }
    });



  },
  watch: {
    // when a task is locked, users can't do tagging
    showAllTasks: function (newVal, oldVal) {
      this.draw_box_clear();
      // only redraw tag boxes. don't update log tables, as its url changes, the table will be reload automatically.
      this.loadAllTags();
      // this.refreshTagsTable();
    },
    options(newVal, oldVal) {
      this.afterGetOptionsAndOverView();
    },
    audioTagPosition(newAudioPosition, oldVal) {
      document.getElementById("currentTime").innerText = newAudioPosition.toFixed(2);
    }
  },
  computed: {  
    legendTaskUsers() {
      
      return Object.keys(this.taskUsers).length>0?this.taskUsers.filter((user)=> user.count>0):[];
    },
    isEditable: function () {
      return !this.showAllTasks && !this.taskOverview.isReadOnly;
    },

    getTagCount: function () {
      if (this.tags && this.tags.tag) {
        return this.tags.tag.length;
      }
      return 100;
    },
    numOutOfBounds: function () {
      let count = 0;
      if (this.tagList.length > 0) {
        this.tagList.forEach(list => {
          list.tag.forEach(tag => {
            if (tag.maxFreq > this.dynParams.maxFreqHz || tag.minFreq < this.dynParams.minFreqHz)
              count++;
          });
        });
      }
      return count;
    },
    currentTime() {
      return this.audioTag.currentTime;
    }
  },
  mounted() {
    // this.showParameterModal();
    const that = this;

    this.audioTag = document.getElementById('audio-player');



    window.onfocus = this.windowFocusEvent;
    // window.onblur = () => console.log('unfocused tab');

    setInterval(function () {
      if (this.audioTag) {
        that.timeUpdate({});
        that.playerXPosition = that.audioTag.currentTime / that.recording.totalLengthSec;
      }
    }, 100);

    if (Object.entries(this.options).length && Object.entries(this.taskOverview).length) {
      this.afterGetOptionsAndOverView();
    }
  },
  beforeDestroy() {
    const that = this;
    this.$refs['spectrogram-display-left'].removeEventListener('scroll', that.handleScroll);
  },
  methods: {
    toggleAllCheckmarks() {
        this.legendList = [];
    },
    colors(index, opacity = false) {
      let colors = 
              {
               'readonly':'#00008B',
               'selected':'#00008B',
               0: this.dynParams.lightMode?'#000000':'#FFF', //black
               1: '#4f9e4f', //green
               2: '#1924b9', //blue
               3: '#e08b0d', //orange
               4: '#8a44d2', //purple
               5: '#673ab7', //purple 2
               6: '#2196f3', //light blue
               7: '#e91e63', //Fusha
               8: '#cddc39', //Yellow
               9: '#600507'
          };

       

        if (opacity) {
          colors[index] = colors[index] + Math.ceil(opacity*2.55).toString(16);
        }

        if (colors[index] == "#000000") {
        //  console.log(colors[index]);
        }

        return colors[index];
    },
    isUltraSonic() {
      return this.taskOverview.maxRecordingFrequencyHz > 45000;
    },
    isFirefox() {
      return typeof InstallTrigger !== 'undefined';
    },
    outOfBoundsList() {
      let out = [];
      if (this.tagList.length > 0) {
        this.tagList.forEach(list => {
          list.tag.forEach(tag => {
            if (tag.maxFreq > this.dynParams.maxFreqHz || tag.minFreq < this.dynParams.minFreqHz)
              out.push(tag);
          });
        });
      }
      return out;
    },
    calculateTagCoordinates(tagRow) {
      let recording = this.recording;
      let coords = {
        y: tagRow.boxStartY || ((1 - ((tagRow.maxFreq / 1000) / (recording.heightHz / 1000))) * recording.totalHeight),
        h: tagRow.boxHeight || ((1 - ((tagRow.minFreq / 1000) / (recording.heightHz / 1000))) * recording.totalHeight) - ((1 - ((tagRow.maxFreq / 1000) / (recording.heightHZ / 1000))) * recording.totalHeight),
        x: tagRow.boxStartX || (tagRow.startTime * recording.pixelsPerSecond) + 1,
        w: tagRow.boxWidth || (tagRow.endTime * recording.pixelsPerSecond) - (tagRow.startTime * recording.pixelsPerSecond),
        z: tagRow.zIndex
      };
      return coords;
    },

    // Wonderful method to encapsulate what to do after options and task overview is recieved by the component. 
    afterGetOptionsAndOverView() {
      this.dynParams.channel = this.options.spectrogramOptions.channel.find(c => c.isDefault === true).value;
      this.dynParams.amplifyId = this.options.spectrogramOptions.amplify.find(c => c.isDefault === true).value;
      this.dynParams.noiseFilterId = this.options.spectrogramOptions.noiseFilter.find(c => c.isDefault === true).value;
      this.dynParams.contrastId = this.options.spectrogramOptions.spectrogramZContrast.find(c => c.isDefault === true).value;
      this.dynParams.brightnessId = this.options.spectrogramOptions.spectrogramZBrightness.find(c => c.isDefault === true).value;
      this.dynParams.xScale = this.taskOverview.project.defaultXScale;
      this.dynParams.yPixels = this.taskOverview.project.defaultYPixels;
      this.dynParams.minFreqHz = this.taskOverview.project.defaultMinFreqHz;
      this.dynParams.maxFreqHz = this.taskOverview.project.defaultMaxFreqHz;
      this.dynParams.useMonochrome = this.taskOverview.project.useMonochrome;
      this.tempLightMode = this.taskOverview.project.useLightMode;
      this.dynParams.generateAudio = false;
    //  this.dynParams.warpPitchToHearableRange = false;

      if (!this.defaultDynParams) {
        this.defaultDynParams = { ...this.dynParams };
      }

      this.getRecording();
    },
    validateMinFreq(e) {
      if (this.dynParams.maxFreqHz && parseInt(e.target.value) > parseInt(this.dynParams.maxFreqHz)) {
        this.dynParamApplyButtonDisabled = true;
        this.dynParamModalError = this.$t('Min frequency must be lower than max frequency');
      } else {
        if (parseInt(e.target.value) < 0) {
          this.dynParamApplyButtonDisabled = true;
          this.dynParamModalError = this.$t('Min frequency must be greater than 0');
        } else {
          this.dynParamApplyButtonDisabled = false;
          this.dynParamModalError = null;
        }
      }
    },
    validateMaxFreq(e) {
      if (this.dynParams.minFreqHz && parseInt(e.target.value) < parseInt(this.dynParams.minFreqHz)) {
        this.dynParamApplyButtonDisabled = true;
        this.dynParamModalError = this.$t('Max frequency must be greater than min frequency');
      } else {
        if (e.target.value > this.taskOverview.maxRecordingFrequencyHz) {
          this.dynParamApplyButtonDisabled = true;
          this.dynParamModalError = this.$t('Oops, max frequency cannot be over', {num: this.taskOverview.maxRecordingFrequencyHz});
        } else {
          this.dynParamApplyButtonDisabled = false;
          this.dynParamModalError = null;
        }
      }
    },
    showParameterModal() {
      this.$modal.show('parameterModal');
    },
    hideParameterModal() {
      this.$modal.hide('parameterModal');
    },
    submitParameters() {
      this.pause();
      this.restart(false);
      window.removeEventListener('keyup', this.keyUp);
      window.removeEventListener('keydown', this.keyDown);
      this.audioTag.removeEventListener('ended', this.ended);
      this.audioTag.removeEventListener('timeupdate', this.timeUpdate);

      this.hideParameterModal();
      this.getRecording();
    },
    resetParameters() {
      this.dynParams = { ...this.defaultDynParams };
      this.dynParamApplyButtonDisabled = false;
      this.dynParamModalError = null;
    },
    label(tagRow) {

      if (this.species.length > 0 && typeof this.species[tagRow.speciesIndividual.speciesId] !== 'undefined') {
        let code = this.species[tagRow.speciesIndividual.speciesId].code;
        return code + ' | ' + tagRow.speciesIndividual.uniqueName;
      }

      return tagRow.speciesIndividual.uniqueName;
    },
    keyUp(event) {
      if (event.target.nodeName !== 'TEXTAREA' &&
        event.target.nodeName !== 'INPUT' &&
        event.target.nodeName !== 'SELECT' &&
        event.target.nodeName !== 'CHECKBOX'
      ) {
        this.keyPressed(event);
      }
    },
    keyDown(event) {
      if (event.target.nodeName !== 'TEXTAREA' &&
        event.target.nodeName !== 'INPUT' &&
        event.target.nodeName !== 'SELECT' &&
        event.target.nodeName !== 'CHECKBOX'
      ) {
        if (event.which === 32) event.preventDefault();
      }
    },
    ended(event) {
      // Flac issue (bats) to reset to beginning and allow to play. If scroll past end of audio it stops working.
        this.pause();
    },
    timeUpdate(event) {
      this.audioTagPosition = this.audioTag.currentTime;
      if (this.audioTagPosition >= this.recording.totalLengthSec) {
          this.pause();
          this.scrollToTime(this.recording.totalLengthSec);
      }
    },
    getRecording() {
      //Save filepath because recording gets re-written
      let filePath = this.recording.filePath;

      this.recording = {};
      if (this.options && this.options.spectrogramOptions && this.options.spectrogramOptions.channel) {
        this.channelIDs.left = (this.options.spectrogramOptions.channel.find(v => v.value === 1) || {}).value;
        this.channelIDs.right = (this.options.spectrogramOptions.channel.find(v => v.value === 2) || {}).value;
      }
      const that = this;

      this.dynParams = {
        ...this.dynParams,
        taskId: this.task.id,
        startInterval: 0,
        intervalCount: 2,
        lightMode: this.tempLightMode
      };

      this.$http.get(API_URL + 'get-task-recording-dynamically', { params: { ...this.dynParams, includeAudio: true } }).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          // alert('Failed to get Task Details');
          return;
        }

        // Map some old values to new to avoid having to go through code and rename
        this.recording = {
          filePath: filePath,
          ...response.data,
          heightHz: response.data.maxFreqHZ - response.data.minFreqHz,
          pixelsPerSecond: response.data.totalLengthPixel / response.data.totalLengthSec
        };

        eventBus.$emit('recording-ready', { 'location': this.taskOverview.location });

        this.animator = anime({
          targets: [this.$refs['spectrogram-display-left'], this.$refs['spectrogram-display-right']],
          scrollLeft: this.recording.totalLengthPixel,
          duration: this.recording.totalLengthSec * 1000,
          easing: 'linear',
          autoplay: false,
          loop: false
        });

        window.animator = this.animator;


        this.singleImageWidth = this.recording.spectrograms[0].width;
        this.intervalSize = this.recording.spectrograms[0].endTime;

        // load tags only after recordings are loaded, otherwise, no width height on the spectrWrap div, and the resizeable boxes won't render properly
        this.loadAllTags();

        // TODO: uncomment this
        // load mp3 files, and spectrum images
        // $('#spectrogram-wrap, #spectrogram-wrap2').addClass('busy');
        const a = document.createElement('audio');
        this.canPlayAudio = !!(a.canPlayType && a.canPlayType('audio/mpeg;').replace(/no/, ''));

        window.addEventListener('keyup', this.keyUp);
        window.addEventListener('keydown', this.keyDown);
      
        if (this.recording.showLeftChannel && !this.loadedOnce) {
          that.registerScrollEvent(true);
        } else {
          this.scrollToTime(isNaN(this.audioTagPosition)?0:this.audioTagPosition);
        }

        if (this.recording.showRightChannel && !this.loadedOnce) {
          that.registerScrollEvent(false);
        }

      

        //   this.generateScales();

        // Need to load the remainder of the images but only if total audio length is greater than 20 seconds. (20 intervals)
        if (this.recording.totalLengthSec > this.intervalSize*2) {
          this.$http.get(API_URL + 'get-task-recording-dynamically', { 'params': { ...this.dynParams, includeAudio: false, startInterval: 2, intervalCount: undefined } }).then(function (response) {
            this.$set(this.recording, 'spectrograms', this.recording.spectrograms.concat(response.body.spectrograms));
          });
        }
        this.afterGetRecording();

      }).catch(function (err) {
        console.log('There is an error', err);
      });
    },
    afterGetRecording() {
      if (!this.recording.file) {
        this.audioTag = this.$refs['audio-player'];
      } else {
        this.audioTag = this.$refs['audio-player-dynamic'];
      }
      //this.restart(false);

      this.audioTag.addEventListener('ended', this.ended);
      this.audioTag.addEventListener('timeupdate', this.timeUpdate);
      this.loadedOnce = true;

      this.$nextTick(() => {
        //console.log(this.audioTag);
        this.scrollToTime(this.audioTagPosition);
        this.audioTag.currentTime = this.audioTagPosition;
        //console.log(this.audioTag.currentTime);
        this.generateScales();
     

      });
    },
    windowFocusEvent() {
      // Audio keeps playing if tab is blurred. Need to update the position to aline with current audio because animation stops.
      if (!this.audioTag.paused) {
        let ratio = this.recording.totalLengthPixel / this.recording.totalLengthSec;
        let currentTime = this.audioTag.currentTime

        this.pause();
        this.animator.set('#spectrogram-display-left, #spectrogram-display-right', {
          'scrollLeft': () => { return currentTime * ratio }
        });
        setTimeout(this.play, 300);
      }
    },
    audioLoaded() {
      eventBus.$emit('audio-loaded');
    },

    visibleTags() { /* for admin: only show tags checked in legend, for reg user, show all. */
      if (this.tagList.length > 0) {
        if (this.taskOverview && !this.taskOverview.enableShowAllRecordingTasks) {
          return this.tagList;
        }
        const legendList = this.legendList;
        let flist = this.tagList.filter(function (u) {
          return legendList != null && legendList.indexOf(u.taskId) !== -1;
        });
        return flist;
      } else {
        return null;
      }
    },
    getParam(param) {
      return getParameterByName(param);
    },
    getColor(taskId, bReadOnly = false, opacity = false) {
      
      if (taskId) {
        let index = this.userColorMap.indexOf(taskId);
        if (index === -1) {
          if (!bReadOnly) {
            this.userColorMap.push(taskId);
            // this.legendList.push(taskId);
            index = this.userColorMap.indexOf(taskId);
          } else {
            return this.colors('readonly',opacity);
          }
        }
        return this.colors(bReadOnly?'readonly':(index % this.colors.length), opacity);
      } else {
        return this.colors(0, opacity);
      }
    },
    toggleForm(show) {
      eventBus.$emit('toggle-form', show);
    },
    keyPressed(e) {
      switch (e.code) {
        case 'Delete':
          e.preventDefault();
            if (this.selectedTag > 0) {
              if (!confirm(this.$t('recordingForms-deleteTagMsg'))) {
                return;
              }
              eventBus.$emit('delete-tag', this.selectedTag);
            }
          //console.log(this.selectedTag);
          break;
        case 'Space':
          e.preventDefault();
          this.toggle();
          break;
        case 'KeyL':
          e.preventDefault();
          this.showLeftTagBoxes = !this.showLeftTagBoxes;
          break;
        case 'KeyA':
          e.preventDefault();
          this.rewind();
          break;
        case 'KeyD':
          e.preventDefault();
          this.fastForward();
          break;
        case 'Digit1':
          e.preventDefault();
          this.placeMarker(e);
          break;
        case 'ArrowLeft':
          e.preventDefault();
          this.previousMarker();
          break;
        case 'ArrowUp':
          e.preventDefault();
          this.firstMarker();
          break;
        case 'ArrowDown':
          e.preventDefault();
          this.lastMarker();
          break;
        case 'ArrowRight':
          e.preventDefault();
          this.nextMarker();
          break;
        case 'KeyT':
          e.preventDefault();
          this.showTagText = !this.showTagText;
          break;
        case 'KeyL':
          e.preventDefault();
          this.showLeftTagBoxes = !this.showLeftTagBoxes;
          break;
        case 'KeyR':
          if (!e.ctrlKey) {
            e.preventDefault();
            this.showRightTagBoxes = !this.showRightTagBoxes;
          }
          break;
        case 'KeyB':
            e.preventDefault();
            this.hideBoxes = !this.hideBoxes;
          break;
      }
    },
    playAudioIssue() {
      const audioText = `
        <ul>
          <li><span class="error">${this.$t('common-firefoxAudioIssue.knownIssue')}</span>
          ${this.$t('common-firefoxAudioIssue.issueMsg')}
          <div style="padding:5px 20px"><b>${this.$t('common-firefoxAudioIssue.issueHowTo')}</b>
            <br><a target=_blank href="${this.$t('common-firefoxAudioIssue.mozillaAudioVideoLink')}">${this.$t('common-firefoxAudioIssue.audioVideo')}</a>
            <br><a target=_blank href="${this.$t('common-firefoxAudioIssue.mozillaFixLink')}">${this.$t('common-firefoxAudioIssue.fixAudioVideo')}</a>
            <br><span class="warning">${this.$t('common-firefoxAudioIssue.useChrome')}</span>
          </div>
          </li>

          <li><span class="error">${this.$t('common-firefoxAudioIssue.otherIssues')}</span> ${this.$t('common-firefoxAudioIssue.pleaseContact', { name: `<a href="mailto:${this.$t('common-wildtraxEmail')}">${this.$t('common-firefoxAudioIssue.wildtraxTeam')}</a>` })} </li>
        </ul>
      `;
      alertDialog(this.$modal, this.$t('common-audioProblems'), audioText, 600);
    },
    /* when scroll image, set audio player Position */
    registerScrollEvent(isLeft) {
      let that = this;
      that.$refs['spectrogram-display-' + (isLeft ? 'left' : 'right')].addEventListener('scroll', (e) => {
            let timer = null;
            if (this.paused) { // only do scroll event if animator is paused. Animator handles this alone.
            
              this.scrollImage(isLeft);
              // that.setPlayerPosition(isLeft);
              if (timer !== null) {
                clearTimeout(timer);
              }
              timer = setTimeout(function () {
                // do something
               
                 that.setPlayerPosition(isLeft);
              }, 250);
            }      
      });
    },
    scrollEvent (event, that) {
            let timer = null;
            if (this.paused) { // only do scroll event if animator is paused. Animator handles this alone.
            
              this.scrollImage(that.isLeft);
              // that.setPlayerPosition(isLeft);
              if (timer !== null) {
                clearTimeout(timer);
              }
              timer = setTimeout(function () {
                // do something
                that.setPlayerPosition(that.isLeft);
              }, 250);
            }
    },
    scrollImage(isLeft) {
      // don't scroll when it is play
      if (!this.paused) return;
      // console.log('scroll image');
      let currentDiv = 'spectrogram-display-';
      let otherDiv = 'spectrogram-display-';
      if (isLeft) {
        currentDiv += 'left';
        otherDiv += 'right';
      } else {
        currentDiv += 'right';
        otherDiv += 'left';
      }
      const x = this.$refs[currentDiv].scrollLeft; // get current div scroll position

      // Always scroll both, just hidden.
      this.$refs[otherDiv].scrollLeft = x; // set other div scroll position
    },
    setPlayerPosition(isLeft) {
      const currentDiv = 'spectrogram-display-' + (isLeft ? 'left' : 'right');
      this.audioSeekPoint = this.$refs[currentDiv].scrollLeft; // get current div scroll position
      // set media position to that same position
      this.scrollTo(this.audioSeekPoint);
      // console.log('position to ' + this.audioSeekPoint + ' ' + isLeft);
    },
    scrollTo(x, autoPlay) {
      if (!this.paused) return;
      let second = x / this.recording.totalLengthPixel * this.recording.totalLengthSec;
      // this.$refs['spectrogram-display-left'].scrollLeft = x;
      this._scroll(second, x, autoPlay);
    },
    scrollToTime(second, autoPlay) {
      if (!this.paused) return;
      let x = second * this.recording.totalLengthPixel / this.recording.totalLengthSec;
      // this.$refs['spectrogram-display-left'].scrollLeft = x;
      this._scroll(second, x, autoPlay);
    },
    _scroll(second, x, autoPlay) {
      if (second < this.recording.totalLengthSec) {
        if (this.supportsNativeSmoothScroll) {
              this.$refs['spectrogram-display-left'].scrollTo({ left: x, behavior: 'smooth' });
              this.$refs['spectrogram-display-right'].scrollTo({ left: x, behavior: 'smooth' });
            } else {
              this.$refs['spectrogram-display-left'].scrollLeft = x;
              this.$refs['spectrogram-display-right'].scrollLeft = x;
            }
    
            // if (second > this.recording.totalLengthSec) {
            //   second = this.recording.totalLengthSec-0.001;
            // }

            //  this.imageDivScrolling(x);
            this.audioTagPosition = second;
            this.seek(second);
            if (autoPlay) {
              this.play();
            }
      }
    },

    rewind() {
      if (this.audioTagPosition > 0) {
        let paused = this.paused;
        this.pause();

        this.audioTagPosition -= 10;
        this.audioTagPosition = (this.audioTagPosition < 0 ? 0 : this.audioTagPosition);
        this.scrollToTime(this.audioTagPosition);

        if (!paused) { this.play(); }
      }
    },
    fastForward() {
      if (this.audioTagPosition < this.recording.totalLengthSec) {
        let paused = this.paused;
        this.pause();
        this.audioTagPosition += 10;

        if (this.audioTagPosition > this.recording.totalLengthSec) {
          this.audioTagPosition = 0;
          this.scrollToTime(this.audioTagPosition);
        } else {
          this.scrollToTime(this.audioTagPosition);
          if (!paused) { this.play(); }
        }
      }
    },
    seek(second) {
      this.audioTag.currentTime = second;

      //Mark animation as not complete in case it ended. 
      //This avoids it from restarting IF you scroll backwards again
      if (this.animator.completed) this.animator.completed = false;

      this.animator.seek(second * 1000);
    },
    restart(autoplay = true) {
      this.scrollTo(0);
      if (autoplay)
        this.play();
    },
    pause() {
      this.audioTag.pause();
      this.animator.pause();
      this.paused = true;
    },
    play() {
      if (this.audioTagPosition >= this.recording.totalLengthSec) {
        this.restart(false);
      } else {
        this.paused = false;
        this.audioTag.play().then(acpt=> {
            this.animator.play();
        }, err => {
          
        });
       
      
      }
    },
    toggle() {
      if (!this.paused) {
        this.pause();
      } else {
        this.play();
      }
      this.drawBox = { boxStartX: -1, boxStartY: -1, boxWidth: 0, boxHeight: 0 }
      this.isDrawingBox = false;
    },
    seconds_to_minutes_seconds(ss) { // converting seconds to readable minutes and second text
      if (isNaN(ss)) {
        return '0.0' + this.$t('commonUnits-seconds');
      }
      if (ss < 60) {
        return ss + this.$t('commonUnits-seconds');
      }
      const m = Math.floor(ss / 60);
      const s = ss % 60;
      return s === 0 ? m + this.$t('commonUnits-minutes') : m + this.$t('commonUnits-minutes') + s.toFixed(1) + this.$t('commonUnits-seconds');
    },

    /* image rectangular box drawing */
    draw_box_clear(e, imagePosition) {
      this.drawBox = { boxStartX: -1, boxStartY: -1, boxWidth: 0, boxHeight: 0 }
      this.tagId = null;
      this.showTagForm = false;
      // this.isDrawingBox = false;
    },
    draw_box_start(e, imagePosition) { // start to draw boxes and get mouse x, y,
      if (!this.isEditable) {
        return;
      }
      this.draw_box_clear(e, imagePosition);
      if (e.which !== 1 || this.isDrawingBox) {
        return;
      }
      /* refs[] alone won't give the position of the component, as the root component is not a vuejs component */
      const wrapDiv = this.$refs['spectrogram-images-' + imagePosition].getBoundingClientRect();
      const startX = e.pageX - wrapDiv.left - window.scrollX;
      const startY = e.pageY - wrapDiv.top - window.scrollY;

      if (startX > 0 && startY > 0 && startX < this.recording.totalLengthPixel && startY < this.recording.totalHeight) { // only able to draw on pictures.
        this.drawBox.boxStartY = startY;
        this.drawBox.boxStartX = startX;
        this.drawBox.startingTop = e.pageY;
        this.drawBox.startingLeft = e.pageX;
        this.isDrawingBox = true;
      }
    },
    draw_box_drawing(e, imagePosition) { // drawing box, tracking mouse x, y
      if (e.which !== 1 || !this.isDrawingBox) {
        return;
      }
      if (this.drawBox.boxStartX < 0) {
        this.draw_box_clear(e, imagePosition);
        return;
      }
      /* use the original stored starting offset to get height and width */
      let height = e.pageY - this.drawBox.startingTop;
      let width = e.pageX - this.drawBox.startingLeft;
      const wrapDiv = this.$refs['spectrogram-images-' + imagePosition].getBoundingClientRect();
      const startX = e.pageX - wrapDiv.left - window.scrollX; // current mouse position on image x
      const startY = e.pageY - wrapDiv.top - window.scrollY;

      if (startX > 0 && startY > 0 && startX < this.recording.totalLengthPixel && startY < this.recording.totalHeight) { // only able to draw on pictures.
        if (height < 0) { this.drawBox.boxStartY = e.pageY - wrapDiv.top - window.scrollY; height = -height; } // move start point to reserve position
        if (width < 0) { this.drawBox.boxStartX = e.pageX - wrapDiv.left - window.scrollX; width = -width; }
        this.drawBox.boxHeight = height;
        this.drawBox.boxWidth = width;
      }
    },
    draw_box_complete(e, imagePosition) { // finish drawing a box and open a editing box for creating a new tag
      if (e.which !== 1 || !this.isDrawingBox) {
        return;
      }
      this.isDrawingBox = false;
      // don't save box that is < minBoxWidth px in width or height
      if (this.drawBox.boxHeight < this.minBoxWidth ||
        this.drawBox.boxWidth < this.minBoxWidth) {
        this.draw_box_clear();
        return;
      }

      this.editTag(-1, false);
    },
    box_selected(tagId) { // select a box, assign tagId to current tagId value
      if (!this.isEditable) {
        return;
      }
      this.tagId = tagId;
    },
    dragging_box: function (left, top, tag) {
      if (!this.isEditable) {
        return;
      }

      if (top + tag.boxHeight > this.recording.totalHeight) {
        // return;
      }
    },
    drag_box_complete: function (x, y, tag) {
      if (!this.isEditable) {
        return;
      }
      // if the move is too small, don't do anything
      if (Math.abs(tag.boxStartX - x) < this.minBoxWidth &&
        Math.abs(tag.boxStartY - y) < this.minBoxWidth
      ) {
        return;
      }
      this.resize_box_complete(x, y, tag.boxWidth, tag.boxHeight, tag);
    },
    resize_box_complete: function (x, y, width, height, tag) {
      // when resize box is done, send ajax to to update box location/size
      if (!this.isEditable) {
        return;
      }
      let params = tag;
      params.boxStartX = x;
      params.boxStartY = y;
      params.boxWidth = width;
      params.boxHeight = height;
      if (x < 0 || y < 0 || width < 0 || height < 0) {
        if (x < 0 || y < 0) {
          if (x<0) {
            params = {...params, boxStartX: 0, boxWidth: params.boxWidth+x};
          }
          if (y<0) {
            params = {...params, boxStartY: 0, boxHeight: params.boxHeight+y};
            console.log({...params});
          }
          this.updateTag(params);
        }
      } else {

        if ( (y+params.boxHeight) > this.recording.totalHeight) {
             params = {...params, boxStartY: y, boxHeight: params.boxHeight-((y+params.boxHeight) - this.recording.totalHeight)};
             console.log({...params});
        }

        this.updateTag(params);
      }
    },
    loadAllSpecies() {

    },
    loadAllTags() {
      // load all tags + marker + user belong to current task id
      const params = { params: { taskId: this.task.id, adminView: this.showAllTasks ? 1 : 0 } };
      this.$http.get(API_URL + 'get-task-tags', params).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('recordingPlayers-failedToGetTagDetails'), 600);
          return;
        }
        this.tagList = response.data;
        this.sortBoxBySize();
        this.taskUsers = [];
        this.legendList = [];
        for (let index in this.tagList) {
          for (let i in this.tagList[index].tag) {
            let hertzPerPixel = (parseInt(this.dynParams.maxFreqHz) - parseInt(this.dynParams.minFreqHz)) / this.recording.totalHeight;
            let boxStartY = (parseInt(this.dynParams.maxFreqHz) - this.tagList[index].tag[i].maxFreq) / hertzPerPixel;
            let boxEndY = (parseInt(this.dynParams.maxFreqHz) - this.tagList[index].tag[i].minFreq) / hertzPerPixel;
            let boxHeight = boxEndY - boxStartY;

            this.tagList[index].tag[i].boxWidth = (this.tagList[index].tag[i].endTime - this.tagList[index].tag[i].startTime) * this.recording.pixelsPerSecond;
            this.tagList[index].tag[i].boxHeight = boxHeight;
            this.tagList[index].tag[i].boxStartY = boxStartY;
            this.tagList[index].tag[i].boxStartX = (this.tagList[index].tag[i].startTime * this.recording.pixelsPerSecond) + 1;
          }

          const tag = {
            name: this.tagList[index].taskUnqDisplayNm,
            taskId: this.tagList[index].taskId,
            userName: this.tagList[index].userName,
            count: this.tagList[index].tag.length + this.tagList[index].marker.length
          };
          this.taskUsers.push(tag);
          // this.legendList.push(this.tagList[index].taskId);
        }
        // sort taskUsers alphabetically here
        this.taskUsers.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        // then do another for loop to establish legendList
        for (let index in this.taskUsers) {
          this.legendList.push(this.taskUsers[index].taskId);
        }
        if (this.tagList.length) {
          this.myMarkers = this.tagList[0].marker;

          Object.values(this.tagList).forEach(task => {
            task.tag.forEach(tag => {
              this.speciesIds.push(tag.speciesIndividual.speciesId);
            });
          });

          // for (let tags in this.tagList) {
          //   for (let tag in this.tagList[tags]) {
          //     if (this.tagList[tags][tag].speciesIndividual && this.tagList[tags][tag].speciesIndividual.speciesId > 0)
          //       this.speciesIds.push(this.tagList[tags][tag].speciesIndividual.speciesId);
          //   }
          // }
          if (this.speciesIds.length > 0) {
            this.$http.get(API_URL + 'get-species-details?speciesIds=' + this.speciesIds.join(',')).then(function (response) {
              if (response) {
                response.data.forEach(element => {
                  this.$set(this.species, element.id, element);
                });
              }
            });
          }
        }
        eventBus.$emit('tags-loaded', this.tagList);
      });
    },
    generateScales() {
      // if (this.scalesGenerated) { return; }
      d3.selectAll('.xscale').html(null);
      var svg = d3.selectAll('.xscale')
        .append('svg')
        .attr('width', this.recording.totalLengthPixel + 10).attr('height', 25);

      // Create the scale
      var x = d3.scaleLinear()
        .domain([0, this.task.length]) // This is what is written on the Axis: from 0 to 100
        .range([0, this.recording.totalLengthPixel]); // This is where the axis is placed: from 100px to 800px

      // Draw the axis
      var axis = d3.axisBottom(x);
      axis.ticks(this.task.length);
      axis.tickFormat(x => x % 5 === 0 ? `${x === 0 ? "  -0" : x + 's'}` : '');
      svg
        .append('g')
        .attr('transform', 'translate (0,0)') // This controls the vertical position of the Axis
        .call(axis);

      d3.selectAll('.yPixels').html(null);

      var svg = d3.selectAll('.yPixels')
        .append('svg')
        .attr('width', 60)
        .attr('height', this.recording.totalHeight + 10).attr('style', 'background-color: #FFF');

      // Create the scale
      var y = d3.scaleLinear()
        .domain([this.recording.minFreqHz ? this.recording.minFreqHz / 1000 : 0, this.recording.maxFreqHZ / 1000])
        .range([this.recording.totalHeight, 0]);

      var axis = d3.axisLeft(y);
      let divisor = 0;
      let heightHz = Math.floor(this.recording.heightHz);
      switch (this.dynParams.yPixels) {
        case 244:
          if (heightHz <= 1000) {
            divisor = 100;
          } else if (heightHz <= 10000) {
            divisor = 1000;
          } else if (heightHz <= 50000) {
            divisor = 2000;
          } else if (heightHz <= 100000) {
            divisor = 10000;
          } else {
            divisor = 20000;
          }
          break;
        case 122:
          if (heightHz <= 1000) {
            divisor = 100;
          } else if (heightHz <= 10000) {
            divisor = 1000;
          }  else if (heightHz <= 50000) {
            divisor = 2000;
          } else if (heightHz <= 100000) {
            divisor = 10000;
          }  else {
            divisor = 20000;
          }
        break;
        
        case 974:
        case 1946:
        case 486:
          if (heightHz <= 1000) {
            divisor = 100;
          } else if (heightHz <= 10000) {
            divisor = 500;
          }  else if (heightHz <= 50000) {
            divisor = 1000;
          } else if (heightHz <= 100000) {
            divisor = 10000;
          }  else {
            divisor = 20000;
          }
        break;
      }

      axis.ticks(this.recording.heightHz/divisor);

  
      axis.tickFormat(x => {
        return x;
      });

      // Note it is reversed

      // Draw the axis
      svg
        .append('g')
        .attr('transform', 'translate(59,0)') // This controls the vertical position of the Axis
        .call(axis);
      let label = this.$t('recordingPlayers-yAxis');
      svg.append('text').attr('text-anchor', 'end').attr('y', 6).attr('dy', '.75em').attr('transform', `rotate(-90), translate(${(this.recording.totalHeight / 2 - ((label.length * 5) / 2)) * -1},0)`).text(label).attr('style', 'font-size: 10px;');

      this.scalesGenerated = true;
    },
    editTag(tagId, toScroll, editParentOnly) {
      /* can still open  the form if the task is locked
        but all buttons will be disabled.
      */
      this.isDrawingBox = false;
      this.toggleForm(true);
      this.showTagForm = true; // open add new/edit tag form
      this.tagId = tagId;
      this.toScroll = (toScroll === true);
      this.editParentOnly = (editParentOnly === true);
    },
    placeMarker() { // add a marker, reload all tags
      if (!this.isEditable) {
        return;
      }
      // console.log(this.audioTag.currentTime, this.recording.totalLengthPixel); 
      const params = {
        taskId: this.task.id,
        startTime: this.audioTag.currentTime
      };
      const url = API_URL + 'insert-marker';
      this.$http.put(url, params).then(
        function (response) {
          const data = response.body;
          if (!data.hasOwnProperty('error')) {
            if (data.taskStatusId) {
              eventBus.$emit('update-task-status-display', data.taskStatusId);
            }
          } else {
            alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('recordingPlayers-failedToPlaceMarker') + ' ' + data.error, 600);
          }
          this.refreshTagsTable(); // force to refersh tag boxes
        });
    },
    deleteMarker(marker) { // delete a marker, and refersh view

    let currentTimestamp = Date.now();
    if (currentTimestamp - this.lastMarkerClickTimestamp > 500) {
      if (!this.isEditable) {
        return;
      }
      const url = API_URL + 'delete-marker';
      this.$http.delete(url, { body: marker }).then(
        function (response) {
          const data = response.body;
          if (data.hasOwnProperty('error')) {
            alertDialog(this.$modal, this.$tc('common-error', 1), this.$t('recordingPlayers-failedToDeleteMarker') + ' ' + data.error, 600);
          }
          this.refreshTagsTable(); // force to refersh tag boxes
        });
      this.lastMarkerClickTimestamp = currentTimestamp;
    }
    },
    firstMarker() { // jump to first marker when press combination keys
      this.selectedMarkerIndex = 0;
      this.scrollToSelectedMarker();
    },
    nextMarker() { // move to the next marker
      this.selectedMarkerIndex++;
      if (this.selectedMarkerIndex >= this.myMarkers.length) {
        this.selectedMarkerIndex = this.myMarkers.length - 1
      }
      this.scrollToSelectedMarker();
    },
    previousMarker() { // move to previous marker
      this.selectedMarkerIndex--;
      if (this.selectedMarkerIndex < 0) {
        this.selectedMarkerIndex = 0;
      }
      this.scrollToSelectedMarker();
    },
    lastMarker() { // jump and highlight the last marker
      this.selectedMarkerIndex = this.myMarkers.length - 1;
      this.scrollToSelectedMarker();
    },
    scrollToSelectedMarker() { // move to selected marker and make audio location there too
      if (!this.myMarkers || this.myMarkers.length === 0) {
        return;
      }
      // get marker x position
      try {
        const x = this.myMarkers[this.selectedMarkerIndex].startTime * this.recording.pixelsPerSecond;
        this.scrollTo(x);
      } catch (e) { }
    },
    /** @augments parmas: put parameters
     */
    updateTag(params) {
      if (!this.isEditable) {
        return;
      }
      // ---
      let hertzPerPixel = (parseInt(this.dynParams.maxFreqHz) - parseInt(this.dynParams.minFreqHz)) / this.recording.totalHeight;
      let boxEndY = params.boxStartY + params.boxHeight;
      let freqFromTop = (params.boxStartY>0?params.boxStartY:1) * hertzPerPixel;
      let pixelsFromBottom = (this.recording.totalHeight - boxEndY);
      let freqFromBottom = pixelsFromBottom * hertzPerPixel;

      let maxFreq = (parseInt(this.dynParams.maxFreqHz) - freqFromTop) || params.maxFreq;
      let minFreq = (parseInt(this.dynParams.minFreqHz) + freqFromBottom) || params.minFreq;
      // ---
      //Only adjust these values IF new prerequisets are set i.e. resize box complete. NOT if just editing tag data.
      if (params.boxStartY >-1 && params.boxHeight > 0) {
        
        // calculate maxFreq from boxStartY 
        params.maxFreq = maxFreq;

        // calculate minFreq from boxStartY & boxHeight
        params.minFreq = minFreq;

        // calculate startTime from boxStartX
        params.startTime = (params.boxStartX / this.recording.pixelsPerSecond);

        // calculate endTime from boxStartX & boxWidth
        params.endTime = (params.startTime + (params.boxWidth / this.recording.pixelsPerSecond));
      }



      params.speciesIndividual.projectId = this.taskOverview.projectId;

      //Delete pixel params
      delete params.boxStartY;
      delete params.boxStartX;
      delete params.boxWidth;
      delete params.boxHeight;

      let url = API_URL + 'insert-update-aru-tag';
      let once = true;
      // console.log(params, {"speciesIndividual":{"species":{"code":"GAEL","englishName":"Greater Antillean Elaenia"},"confidenceId":6,"abundanceId":2},"isMarker":false,"taskId":69029,"boxStartX":383,"boxStartY":108,"boxWidth":131,"boxHeight":103,"vocalizationId":1,"hasAIResult":false});
      
      this.$http.put(url, params).then(
        (response) => {
          const data = response.body;
          if (!data.hasOwnProperty('error')) {
        
            if (data.taskStatusId) {
              eventBus.$emit('update-task-status-display', data.taskStatusId);
            }
            this.refreshTagsTable();
            this.tagId = data.id;
            this.draw_box_clear();
            this.toggleForm(false);
          } else {
            //console.log(data.error);
            alertDialog(this.$modal, this.$t('recordingPlayers-failedToInsertUpdateTag'), data.error, 600);
            this.refreshTagsTable(); // force to refersh tag boxes
          }
        },
        (error) => {
          alertDialog(this.$modal, this.$t('recordingPlayers-failedToInsertUpdateTag'), error, 600);
          console.log(error);
          this.refreshTagsTable(); // force to refersh tag boxes
        });
    },
    deleteTag(tagId) { // delete a tag, refresh view and hide form
      if (!this.isEditable) {
        return;
      }
      const params = {
        id: tagId,
        taskId: this.task.id
      };
      this.$http.delete(API_URL + 'delete-tag?', { body: params }).then(
        function (data) {
          if (data.hasOwnProperty('error')) {
            alertDialog(this.$modal, this.$t('recordingPlayers-failedToDeleteTag'), data.error, 600);
            return false;
          } else {
            this.refreshTagsTable();
            this.toggleForm(false);
            return true;
          }
        });
    },
    refreshTagsTable() { // refresh view: load all tags, and reload log table
      this.loadAllTags();
      eventBus.$emit('reload-log-table');
    },
    /*
      sort tag boxes by size, so when two boxes overlap, make the smaller one on top, use large z-index
    */
    sortBoxBySize() {
      this.boxSizeSorted = [];
      for (let i in this.tagList) {
        for (let j in this.tagList[i].tag) {
          let tag = this.tagList[i].tag[j];
          //No Longer works as is. boxWidth and boxHeight were removed from the data.
          //we now have minFreq/maxFreq startTime/endTime
          //time ~~ width
          //freq ~~ height
          //
          let width = tag.endTime - tag.startTime;
          let height = tag.maxFreq - tag.minFreq;

          this.boxSizeSorted.push([this.tagList[i].tag[j].id, width * height]);
        }
      }
      this.boxSizeSorted.sort(function (a, b) { // small one get bigger  number
        return b[1] - a[1];
      });
      for (let i in this.tagList) {
        for (let j in this.tagList[i].tag) {
          for (let indx = 0; indx < this.boxSizeSorted.length; indx++) {
            if (this.tagList[i].tag[j].id === this.boxSizeSorted[indx][0]) {
              this.tagList[i].tag[j].zIndex = indx;
            }
          }
        }
      }
    },
    downloadRecording() {
      // called by parent when download btn clicked
      this.downloading = true;
      let bucket = this.recording.filePath.split('.')[0];
      let storagePath = bucket.replace('https://', '');
      let name = this.recording.filePath.split('/').pop();
      name = name.split('.')[0];
      let url = '/s3/' + storagePath + '/' + (this.recording.filePath.replace((bucket + '.s3-accelerate.amazonaws.com/'), '')) + '/' + name + '.' + this.recording.filePath.split('.').pop();
      let link = document.createElement('a');
      link.href = url;
      let dateTime = this.taskOverview.recordingDate.split(' ')[0].replace(/-/g, '') + '_' + this.taskOverview.recordingDate.split(' ')[1].replace(/:/g, '');
      link.download = this.taskOverview.organizationName + '_' + this.taskOverview.location.name + '_' + dateTime + '.' + this.recording.filePath.split('.').pop();
      document.body.appendChild(link); // for firefox if link not added into body, click won't work
      link.click();
      link.remove();
      this.downloading = false;
    },
    downloadGeneratedRecording() {
      let a = document.createElement('a');
      let dateTime = this.taskOverview.recordingDate.split(' ')[0].replace(/-/g, '') + '_' + this.taskOverview.recordingDate.split(' ')[1].replace(/:/g, '');
      a.href = `data:audio/${this.recording.fileType};base64,${this.recording.file}`;
      a.download = this.taskOverview.organizationName + '_' + this.taskOverview.location.name + '_' + dateTime + '_GENERATED.' + this.recording.filePath.split('.').pop();;
      a.click();
    }
  
  },
  data() {
    return {
      intervalSize: null,
      lastMarkerClickTimestamp: 0,
      userCheckMarkToggle: true,
      loadedOnce: false,
      channelIDs: {
        left: null,
        right: null,
        backLeft: null,
        backRight: null
      },
      dynParams: {
        channel: 0,
        lightMode: 0
      },
      tempLightMode: null,
      defaultDynParams: null,
      dynParamApplyButtonDisabled: false,
      dynParamModalError: null,
      audioTag: null,
      audioTagPosition: 0,
      animator: null,
      // isEditable: false,
      isDraggable: true,
      toScroll: false,
      /* show hide forms */
      showUsage: false,
      showTagForm: false,
      showRightTagBoxes: true,
      showLeftTagBoxes: true,
      hideBoxes: false,
      showTagText: true,
      tagId: -1,
      boxSizeSorted: [],
      logTableAutoPlay: false,
      editParentOnly: false, /* false for normal tag, true for species individual editing */
      /* spectrogram, audio data */
      jwPlayer: null,
      playerDiv: 'jwplayer_wrap',
      silent: false,
      bAutoStart: false,
      paused: true,
      audioSeekPoint: 0, // audio play at
      audioSeekable: false,
      playerXPosition: 0,
      mediatPositonText: '',
      playSpeed: 80.0,
      drawBox: { boxStartX: -1, boxStartY: -1, boxWidth: 0, boxHeight: 0 },
      minBoxWidth: 5,
      isDrawingBox: false,
      isResizeBox: false,
      horizontalAxis: '', // '/.resources/wildtrax-content/webresources/bundle/recording-page/legend/xaxis-r80L600s.png',
      verticalAxis: '', // '/.resources/wildtrax-content/webresources/bundle/recording-page/legend/yaxis-c1r14h400.png',
      leftOffset: 350,
      displayWidth: 1000,
      singleImageWidth: 0,
      userColorMap: [],
      legendList: [],
      taskUsers: {},
      // tags: {}, // old
      recording: {},
      tagList: {},
      selectedMarkerIndex: -1,
      selectedTag: null,
      myMarkers: [],
      // auto id
      autoIdSpecies: [],
      canPlayAudio: null,
      downloading: false,
      speciesIds: [],
      species: [],
      scalesGenerated: false
    }
  }
}
</script>
<style scoped>
.spectrogramWrap img {}

.spectrogramWrap .spectrogram-display {
  overflow: hidden;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

#spectrogram-images-left,
#spectrogram-images-right {
  position: absolute;
}

.singleWrap {
  position: relative;
}

.sel_box {
  width: 0px;
  height: 0px;
  position: absolute;
  background-color: transparent;
  z-index: 5;
  border: 2px dotted #454545;
  position: absolute;
  background-color: rgba(200, 255, 155, 0.5);
}

.sel_box:hover {
  border-color: #660000;
  cursor: pointer;
}

.mark_box {
  width: 0px;
  height: 0px;
  position: absolute;
  background-color: yellow;
  border-width: 1px;
  border-style: dashed;
  /*   background-image: url(/bis/static/images/nogif.gif);*/
  z-index: 5;
}

.highligted_mark_box {
  background-color: red !important;
}

span.tag-label {
  position: absolute;
  top: 0px;
  width: 100px;
}

.spectrogram-index-marker {
  border-bottom: 1px solid #880000;
  border-left: 1px solid #880000;
  border-right: 1px solid #880000;
  position: absolute;
  width: 3px;
  z-index: 2;
}

.vdr {
  border: 2px solid;
}


.controlWrap [type="checkbox"]:not(:checked),
.controlWrap [type="checkbox"]:checked {
  position: relative;
  opacity: 1;
  pointer-events: auto;
}

.forbidden {
  cursor: not-allowed;
}

.controlWrap .legend .transcriber .legend_square {
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 10%;
}

.controlWrap .legend .transcriber input {
  display: inline-block;
}

span.playMode {
  vertical-align: middle;
}

span.playMode span {
  display: inline-block;
  background-color: #dcdcd5;
  border-radius: 2px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  width: 100px;
  margin: 5px 15px;
}

span.playMode span {
  pointer-events: auto;
  cursor: pointer;
}

span.playMode span.active {
  pointer-events: none;
  cursor: default;
}

span.playMode span.active {
  background-color: #7da484;
  color: #fff;
}

span.playMode span.active:hover {
  background-color: #8dbb86;
  color: #fff;
}

span.mode-heading {
  font-weight: 800;
}

.transcriber.nodata .legend_name {
  text-decoration: line-through;
}

.hideBorder {
  border-color: transparent;
  background-color:transparent !important;
  box-shadow: none !important;

}

.recording-download-btn {
  margin-bottom: 0px !important;
}
</style>
