<template>
  <div>
    <div class="col s12 panel boxed padded60 dataGroup tab-content">
      <dropdown-button class="right">
        <template slot="menuTitle">{{$t('common-manage')}}</template>
        <template slot="dropdownButtons">
          <a v-if="tableLoaded" download class="btn btn-success popover-menu-item" :class="{'downloading': downloading }" @click="downloadLocationCSV"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$refs.table.data.length > 0 ? $t('locationTable-downloadCSV') : $t('common-downloadCsvTemplate')}}</a>
          <a v-if="tableLoaded" download class="btn btn-success popover-menu-item" :class="{'downloading': downloadingCodes }" @click="downloadCodes"> <span style="font-size: 20px;"></span><span class="spin-icon"></span>{{$t('common-downloadCodes')}}</a>
          <!-- popover + tooltip has been buggy so this v-if part is necessary to avoid bug -->
            <span v-if="!isEditable" v-tooltip="{content: tooltips.disabledUpload, show: isEditable, classes: 'front'}">
              <button :disabled="!isEditable" v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span style="font-size: 20px;"></span> {{$t('locationTable-uploadCSV')}}</button>
            </span>
          <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadLocationCSV"> <span style="font-size: 20px;"></span> {{$t('locationTable-uploadCSV')}}</button>
        </template>
      </dropdown-button>
      <button class="btn btn-success  locationAdd" v-if="isEditable" @click="createLocation"> <span style="font-size: 20px;"></span> {{$t('locationTable-addLocation')}}</button>

      <template id="people" vcloak v-if="isEditable">
        <span class="info-icon ion-android-warning" v-tooltip="$t('locationTable-tooltips.missingLatLong', {count: missingCount})" v-if="missingCount > 0">
        </span>
      </template>
      <v-server-table ref="table" v-if="tableOptionLoaded" :url="url" :columns="columns" :options="tableOptions" @row-click="onRowClick" class="table vue-bordered w-full" @loaded="tableLoaded=true">
        <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
        <template slot="id" slot-scope="props">
          <a  class="editProject modal-trigger projectAdd" @click="updateLocation(props.row)"><span class="link" :class="{'ion-edit': isEditable, 'ion-eye': !isEditable}"></span></a>
        </template>
        <div class="row no-bottom-margin" slot="child_row" slot-scope="props" v-if="toLoadSubRow">
          <div class="subTable col s9">
            <template v-for="(project, index) in props.row.subDetails">
              <div class="row link brown-color" :key="'subrow-' + project.projectId + '' + index" @click="openProjectLink(project.projectId, project.sensor)">
                <div class="col s4 red-font">{{project.projectName}} </div>
                <div class="col s4">{{project.sensor}} </div>
                <div v-if="project.sensor === 'PC'" class="col s4">{{project.projectLocationCount}} {{$tc('common-survey', project.projectLocationCount)}}</div>
                <div v-if="project.sensor === 'CAM'" class="col s4">{{project.projectLocationCount}} {{$tc('common-imageSet', project.projectLocationCount)}}</div>
                <div v-if="project.sensor === 'ARU'" class="col s4">{{project.projectLocationCount}} {{$tc('common-recording', project.projectLocationCount)}}</div>
              </div>
            </template>
            </div>
            <div v-if="!props.row.childRowLoading" class="row col s12 no-bottom-margin">
              <div class="btns-wrapper right">
                <div class="single-btn-wrapper" v-tooltip="{content: isEditable ? null: $t('org-adminAccessRequired')}">
                  <button class="btn" :class="{'btn-success':isEditable, 'btn-border': !isEditable}" :disabled="!isEditable" @click="openMergeLocationModal(props.row)"> <span style="font-size: 20px;"></span>{{$t('locationTable-mergeLocation')}}</button>
                </div>
                <div class="single-btn-wrapper right delete-location-btn" v-tooltip="{content: deleteBtnTooltip(props.row, 'location')}">
                  <button class="btn" :class="{'btn-border': !isEditable}" :disabled="!isEditable || !canBeDeleted(props.row)" @click="deleteLocation(props.row)"> <span style="font-size: 20px;"></span>{{$t('locationTable-deleteLocation')}}</button>
                </div>
              </div>
            </div>
        </div>
      </v-server-table>
    </div>
    <modal name="location"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width="55%"
      height="80%"
      :minWidth="400"
      :minHeight="500"
      :clickToClose="false"
    >
      <location-form ref="locationForm" v-if="locationId != -1" v-bind:organization-obj="organizationObj" v-bind:organization-id="organizationId" v-bind:location-id="locationId"  v-bind:is-editable="isEditable"
      v-bind:options="options"
      />
    </modal>
    <modal name="merge-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width="55%"
      height="auto"
      :minWidth="400"
      :minHeight="500"
      :clickToClose="false"
    >
      <merge-locations-form ref="mergeLocationForm" v-if="mergeSourceInfo" v-bind:organizationId="organizationId" v-bind:mergeSourceInfo="mergeSourceInfo" v-bind:merge-type="'location'"
      />
      </modal>
      <modal name="location-upload-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "90%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <upload-location-csv
      v-bind:organization-id="organizationId"
      v-bind:organization-name="organizationObj.name"
      v-bind:visibility-options="options.visibility"
      v-bind:type="'location'"/>
    </modal>
    <v-dialog/>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all locations
under each Location tab, show a table listing all locations. users can also update Location properties + users
or remove locations if they have no associated data.
*/
import LocationForm from './LocationForm';
import MergeForm from './MergeForm';
import { eventBus } from '@/lib/eventbus';
import OrganizationMixin from './OrganizationMixin';
import FormMixin from '@/components/common/FormMixin.js';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import UploadCSVForm from '@/components/common/UploadCSVForm';
import { API_URL, PROJECT_URLS, alertDialog, capitalizeString } from '@/lib/common';
import { createLink } from '@/lib/language.js';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';

export default {
  name: 'LocationTable',
  components: {
    'location-form': LocationForm,
    'merge-locations-form': MergeForm,
    'multiselect': Multiselect,
    'upload-location-csv': UploadCSVForm,
    'dropdown-button': DropdownButtonWithOptionsVue
  },
  mixins: [OrganizationMixin, FormMixin],
  props: {
    isEditable: {
      default: null,
      type: Boolean
    },
    organizationId: {
      default: null,
      type: Number
    },
    organizationObj: {
      default: null,
      type: Object
    },
    missingCount: {
      default: null,
      type: Number
    }
  },
  watch: {
    organizationId () {
      if (this.$refs.table) {
        this.url = API_URL + 'get-location-summary?organizationId=' + this.organizationId;
        // this.$refs.table.refresh();
      }
    }
  },
  created () {
    const self = this;
    // get options
    // this.loadOptions();
    this.commonGet(API_URL + 'get-location-options', null, false, (response) => {
      self.options = response.data;
      self.$set(this.tableOptions.listColumns, 'visibilityId', (this.options.visibility || []).map(x => { return {id: x.id, text: x.type}; }));
      self.tableOptionLoaded = true;
    });
    eventBus.$on('reload-table', function () {
      if (self.$refs.table) {
        self.$refs.table.getData();
      }
    });
    eventBus.$on('reload-location-form', function (locationId) {
      self.locationId = locationId;
    });
    eventBus.$on('close-location-form', function (locationId) {
      self.locationId = -1;
    });
    eventBus.$on('close-merge-form', function (locationId) {
      self.mergeSourceInfo = null;
    });
    eventBus.$on('hide-csv-upload-form', () => {
      this.$modal.hide('location-upload-form');
    });
  },
  methods: {
    openProjectLink (id, sensor) {
      let url;
      url = createLink(PROJECT_URLS[sensor]);
      window.open(url + id, '_blank');
    },
    updateLocation (row) {
      this.locationId = row.id;
      this.showLocationForm();
    },
    createLocation () {
      if (!this.isEditable) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.add);
        return;
      }
      this.locationId = null;
      this.showLocationForm();
    },
    deleteLocation (row) {
      this.commonDelete(this.locationRemoveUrl, {locationIds: [row.id]}, () => {
        this.$refs.table.refresh();
      });
    },
    showLocationForm () {
      //  console.log('locationId =', this.locationId)
      // this.$refs.locationForm.switchLocation();
      this.$modal.show('location');
    },
    initTable () {
      this.tableData = [];
      // console.log(this === this.$refs['table']);
      // this.$refs['table'].refresh();
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.id;
    },
    viewMissingCoordinates () {
      const filters = [
        {
          'column': 'latitude',
          'value': null
        }
      ];
      this.$refs.table.setFilter(filters);
    },
    openMergeLocationModal (row) {
      this.mergeSourceInfo = row;
      this.$modal.show('merge-form');
    },
    downloadLocationCSV () {
      if (this.downloading) {
        return;
      }
      this.downloading = true;
      this.$http.get(this.downloadLocationUrl + this.organizationId, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.getFileName();
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloading = false;
      },
      (err) => {
        this.downloading = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    getFileName () {
      let now = new Date();
      let filename = this.organizationObj.name + '_' + this.$tc('common-location', 2).toLowerCase() + '_' + now.getFullYear();
      if ((now.getMonth() + 1) < 10) {
        filename += '0' + (now.getMonth() + 1) + now.getDate() + '.csv';
      } else {
        filename += (now.getMonth() + 1) + now.getDate() + '.csv';
      }
      return filename;
    },
    downloadCodes () {
      if (this.downloadingCodes) {
        return;
      }
      this.downloadingCodes = true;
      this.$http.get(this.downloadCodesURL, {headers: {'Content-Type': 'application/zip', 'Accept': '*/*'},
        responseType: 'arraybuffer'}).then(function (response) { // receving streamming data, create blob objects for downloading
        const blob = new Blob([response.body], {type: 'text/csv;charset=utf-8'});
        const objectUrl = URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = objectUrl;
        link.download = this.$tc('common-location', 1) + '_' + capitalizeString(this.$tc('common-code', 2)) + '.zip';
        document.body.appendChild(link); // for firefox if link not added into body, click won't work
        link.click();
        link.remove(); // remove the link after clicking. only needed for firefox
        this.downloadingCodes = false;
      },
      (err) => {
        this.downloadingCodes = false;
        alert(this.$t('common-downloadFail') + err.status);
      });
    },
    uploadLocationCSV () {
      this.$modal.show('location-upload-form');
    },
    canBeDeleted (row) {
      if (row.subDetails.length === 0) {
        return true;
      }
      return false;
    }
  },
  data () {
    const pageSize = 100;
    return {
      url: API_URL + 'get-location-summary?organizationId=' + this.organizationId,
      getDetailUrl: ['get-location-details-view?organizationId=', 'locationId'],
      locationUpdateUrl: API_URL + 'insert-update-location',
      locationRemoveUrl: API_URL + 'delete-location-batch',
      downloadLocationUrl: API_URL + 'download-location-by-org-id?orgId=',
      downloadCodesURL: API_URL + 'download-location-codes',
      downloading: false,
      downloadingCodes: false,
      tableLoaded: false,
      locationId: null,
      tooltips: {
        disabledUpload: this.$t('common-disabledUpload')
      },
      mergeSourceInfo: null,
      assignProjectId: null,
      columns: ['locationName', 'latitude', 'longitude', 'visibilityId', 'recordingCount', 'imageCount', 'id'],
      recordName: this.$tc('common-location', 1),
      options: {},
      tableOptionLoaded: false,
      tableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        filterable: ['locationName', 'longitude', 'latitude', 'visibilityId'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['locationName', 'longitude', 'latitude', 'visibilityId', 'recordingCount', 'imageCount'],
        listColumns: {
        },
        headings: {
          locationName: this.$t('locationTable-headings.locationName'),
          visibilityId: this.$t('locationTable-headings.visibilityId'),
          buffer: this.$t('locationTable-headings.buffer'),
          recordingCount: this.$t('locationTable-headings.recordingCount'),
          imageCount: this.$t('locationTable-headings.imageCount'),
          accessMethodId: this.$t('locationTable-headings.accessMethodId'),
          id: this.$t('locationTable-headings.id')
        },
        columnsClasses: {
          locationName: 'name'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'locationName', ascending: true},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
        /*  created_at(h, row) {
            return this.formatDate(row.created_at);
          },
          updated_at(h, row) {
            return this.formatDate(row.updated_at);
          },
          pushed_at(h, row) {
            return this.formatDate(row.pushed_at);
          } */
      }
    }
  }
}
</script>
<style scoped>
.subrow-info p {
  color: #C94412;
}

div :deep( .VueTables__table td),
div :deep( .VueTables__table th) {
  text-align: center;
}
div :deep( .VueTables__table td.name),
div :deep( .VueTables__table th.name) {
  text-align: left;
}

.VueTables__child-row-toggler {
  cursor: pointer;
}
.no-bottom-margin{
  margin-bottom: 0;
}

.delete-location-btn{
  margin-left: 10px;
}

.single-btn-wrapper{
  /* this element and css needed in order to show tooltip on disabled btn */
  display: inline-block;
}

.front{
  z-index: 1000000;
}

.VueTables__table td.name {
    color: #c94412;
}

.red-font {
    color: red;
}

.brown-color {
    color: #ca7c0b;
}

</style>
