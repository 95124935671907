import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import i18n from '@/i18n';
export async function checkSourceUnload (id, map) {
  let time = 0;
  let timer = setInterval(() => {
    try {
      if (!map.getSource(id) || !map.isSourceLoaded(id)) {
        clearInterval(timer)
        return id;
      }

      time += 500;

      // timeout
      if (map.getSource(id) || time > 30000) {
        clearInterval(timer)
        return id;
      }
    } catch (e) {
      clearInterval(timer)
      throw (e)
    }
  }, 500);
}

export function fitToPoints (map, coordinates, padding) {
  if (coordinates.length === 0) {
    return; // do nothing when no location provided.
  }
  let pointSet = new Set();
  coordinates.forEach(c => pointSet.add([c.geometry.coordinates[0], c.geometry.coordinates[1]]));
  const pointsArray = Array.from(pointSet);
  if (pointsArray.length > 1) { // fit boundary when more than one points given
    let bounds = pointsArray.reduce(function (bounds, coordinates) {
      return bounds.extend([coordinates[0], coordinates[1]]);
    }, new mapboxgl.LngLatBounds());
    /* if i pass bounds in, to fitBounds, it somehow expects a array and return an error
      `LngLatLike` argument must be specified as a LngLat instance, an object {lng: <lng>, lat: <lat>},
      an object {lon: <lng>, lat: <lat>}, or an array of [<lng>, <lat>]

      it works fine in plain js, I doubt it is related to the library used */
    const boundArray = [[bounds._sw.lng, bounds._sw.lat], [bounds._ne.lng, bounds._ne.lat]];
    map.fitBounds(boundArray, {
      padding: padding || 20
    });
  } else {
    // zoom to the given point
    map.flyTo({center: [pointsArray[0][0], pointsArray[0][1]], zoom: 9});
  }
}

export function setLanguage (map) {
  /* map.setLayoutProperty('country-label', 'text-field', [
    'get',
    `name_fr`
  ]); */
  const language = new MapboxLanguage();
  map.addControl(language);
  map.setStyle(language.setLanguage(map.getStyle(), i18n.locale));
}
