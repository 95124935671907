var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-popover",
    {
      attrs: {
        "auto-hide": true,
        placement: "bottom",
        trigger: "click focus",
        popoverClass: "dropdown-popover"
      },
      on: {
        show: function($event) {
          return _vm.showingPopover()
        }
      }
    },
    [
      _c(
        "button",
        {
          staticClass:
            "btn btn-success tooltip-target popover-target-button dropdown-btn"
        },
        [
          _c("span", { staticStyle: { "font-size": "20px" } }),
          _vm._t("menuTitle")
        ],
        2
      ),
      _vm._v(" "),
      _c("template", { slot: "popover" }, [
        _c(
          "div",
          { staticClass: "menu-wrapper" },
          [_vm._t("dropdownButtons")],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }