<template>
    <div :class="{logContainer: callsBegan && !completedCalls, padded40: callsBegan && !completedCalls}">
      <template v-if="callsBegan && !completedCalls">
        <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400" class="pulse-results"></pulse-loader>
        <div v-if="progressTotal" class="results-loading-text">{{$t('autogenVisitsResults-creating',{num: progressNum, total: progressTotal})}}</div>
      </template>
      <div v-if="completedCalls" class="padded40 logContainer">
        <div>{{$t('autogenVisitsResults-successfullyCreated', {num: numSuccess})}}</div>
        <div>{{$tc('common-numErrors', errorResultsTableData.length, {num: errorResultsTableData.length}).toLowerCase()}}.</div>
        <v-client-table v-if="errorResultsTableData.length > 0" :data="errorResultsTableData" :columns="clientColumns" :options="clientOptions">
          <div class="error" slot="message" slot-scope="props">
            {{props.row.message}}
          </div>
        </v-client-table>
      </div>
      </div>
</template>

<script>
/*
This component shows the results to the user from the AutogenerateVisitsTable
*/

import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'generate-and-select-results',
  components: {'pulse-loader': PulseLoader},
  props: ['completedCalls', 'numSuccess', 'errorResultsTableData', 'callsBegan', 'progressNum', 'progressTotal'],
  mixins: [],
  created () {
  },
  methods: {
  },
  data () {
    return {
      clientColumns: ['locationName', 'deployDate', 'retrieveDate', 'message'],
      clientOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        filterable: ['locationName', 'deployDate', 'retrieveDate', 'message'],
        sortable: ['locationName', 'deployDate', 'retreiveDate', 'message'],
        headings: {
          locationName: this.$t('autogenVisitsTable-headings.locationName'),
          deployDate: this.$t('autogenVisitsTable-headings.deployDate'),
          retrieveDate: this.$t('autogenVisitsTable-headings.retrieveDate'),
          message: this.$t('autogenVisitsTable-headings.message')
        },
        columnsClasses: {
          selected: 'selected',
          locationName: ' locationName',
          deployDate: 'deployDate',
          retrieveDate: 'retrieveDate',
          message: 'message'
        },
        //  debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [50, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        orderBy: {column: 'locationName', ascending: true},
        rowClassCallback (row) {
          return (row.uniqueId === this.activeRowId ? 'activeTableRow' : '');
        }
      }
    }
  }
}
</script>
<style scoped>
.pulse-results{
  text-align: center;
}

.results-loading-text{
  text-align: center;
  padding-top: 10px;
}

.logContainer{
  padding-top: 20px;
  border: 1px solid #d89b9b;
}
</style>
