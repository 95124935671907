var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row weather-wrapper" },
    [
      !_vm.weatherLoaded
        ? _c("div", { staticClass: "large-weather-box col m8 s12" }, [
            _c("div", { staticClass: "col s12 m6 weatherbox" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("weatherSegment-hourlyPending")) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col s12 m6 weatherbox" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("weatherSegment-dailyPending")) +
                  "\n      "
              )
            ])
          ])
        : [
            _vm.weather.errorMsg
              ? _c("div", { staticClass: "large-weather-box col m8 s12" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.weather.errorMsg) + "\n      "
                  )
                ])
              : _c(
                  "div",
                  {
                    staticClass: "large-weather-box col s12",
                    class: _vm.getWrapperClass(),
                    on: {
                      click: function($event) {
                        return _vm.openWeatherModal()
                      }
                    }
                  },
                  [
                    _vm.showDate
                      ? _c(
                          "div",
                          {
                            staticClass: "weatherbox col centered",
                            class: _vm.classObj.dateSize
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("weather-data.dateTime")) + " "
                            ),
                            _c(
                              "div",
                              { staticClass: "data" },
                              [
                                _vm.weather && _vm.weather.hourlyTimestamp
                                  ? [
                                      _vm._v(
                                        _vm._s(_vm.weather.hourlyTimestamp)
                                      )
                                    ]
                                  : [_vm._v("--")]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.weather &&
                    _vm.weather.hourlyWeather &&
                    _vm.weather.hourlyWeather[0] !== _vm.$t("common-na")
                      ? _c("div", { class: _vm.classObj.conditions }, [
                          _c(
                            "div",
                            { staticClass: "icon-text-wrap" },
                            [
                              _vm._l(_vm.weather.hourlyWeather, function(
                                condition,
                                index
                              ) {
                                return [
                                  condition === _vm.$t("common-na")
                                    ? _c(
                                        "div",
                                        { key: "weather-icon-" + index },
                                        [_vm._v("-")]
                                      )
                                    : _c("img", {
                                        key: "weather-icon-" + index,
                                        staticClass: "weather-icon mx-auto",
                                        attrs: { src: _vm.weatherIcons[index] }
                                      }),
                                  _vm._v(" "),
                                  _c("div", { key: "condition-" + index }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(condition) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col s12 weatherbox",
                        class: _vm.classObj.hourly
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col s6" }, [
                            _vm._v(
                              _vm._s(_vm.$t("weather-data.temperature")) + " "
                            ),
                            _c(
                              "div",
                              { staticClass: "data" },
                              [
                                _vm.weather && _vm.weather.hourlyTemperature
                                  ? [
                                      _vm._v(
                                        _vm._s(_vm.weather.hourlyTemperature)
                                      )
                                    ]
                                  : [_vm._v("--")]
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s6" }, [
                            _vm._v(
                              _vm._s(_vm.$t("weather-data.windSpeed")) + " "
                            ),
                            _c(
                              "div",
                              { staticClass: "data" },
                              [
                                _vm.weather && _vm.weather.hourlyWindSpeed
                                  ? [
                                      _vm._v(
                                        _vm._s(_vm.weather.hourlyWindSpeed)
                                      )
                                    ]
                                  : [_vm._v("--")]
                              ],
                              2
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col s12 weatherbox",
                        class: _vm.classObj.daily
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col s4" }, [
                            _vm._v(_vm._s(_vm.$t("weather-data.min")) + " "),
                            _c(
                              "div",
                              { staticClass: "data" },
                              [
                                _vm.weather && _vm.weather.dailyMinTemp
                                  ? [_vm._v(_vm._s(_vm.weather.dailyMinTemp))]
                                  : [_vm._v("--")]
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s4" }, [
                            _vm._v(_vm._s(_vm.$t("weather-data.max")) + " "),
                            _c(
                              "div",
                              { staticClass: "data" },
                              [
                                _vm.weather && _vm.weather.dailyMaxTemp
                                  ? [_vm._v(_vm._s(_vm.weather.dailyMaxTemp))]
                                  : [_vm._v("--")]
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col s4" }, [
                            _vm._v(_vm._s(_vm.$t("weather-data.mean")) + " "),
                            _c(
                              "div",
                              { staticClass: "data" },
                              [
                                _vm.weather && _vm.weather.dailyMeanTemp
                                  ? [_vm._v(_vm._s(_vm.weather.dailyMeanTemp))]
                                  : [_vm._v("--")]
                              ],
                              2
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "centered weatherbox col",
                        class: _vm.classObj.distSize
                      },
                      [
                        _vm._v(_vm._s(_vm.$t("weather-data.distance")) + ": "),
                        _c("div", { staticClass: "data" }, [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _vm.weather && _vm.weather.hourlyStationDistance
                                ? [
                                    _vm.weather.hourlyStationName ===
                                    _vm.weather.dailyStationName
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm.weather.hourlyStationDistance
                                            )
                                          )
                                        ]
                                      : [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "weatherSegment-hourlyShort"
                                                )
                                              ) +
                                                ": " +
                                                _vm._s(
                                                  _vm.weather
                                                    .hourlyStationDistance
                                                )
                                            )
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "weatherSegment-dailyShort"
                                                )
                                              ) +
                                                ": " +
                                                _vm._s(
                                                  _vm.weather
                                                    .dailyStationDistance
                                                )
                                            )
                                          ])
                                        ]
                                  ]
                                : [_vm._v("--")]
                            ],
                            2
                          )
                        ])
                      ]
                    )
                  ]
                )
          ],
      _vm._v(" "),
      _c(
        "modal",
        {
          ref: "tagForm",
          attrs: {
            name: "weatherModal",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: ".drag-handle",
            delay: 100,
            width: "90%",
            height: "90%",
            minWidth: 400,
            minHeight: 440,
            clickToClose: false
          }
        },
        [
          _vm.dateString && _vm.locationId
            ? _c("weather-modal", {
                attrs: {
                  projectId: _vm.projectId,
                  "icons-src": _vm.weatherIcons,
                  "date-string": _vm.dateString,
                  "location-id": _vm.locationId,
                  "site-latitude": _vm.siteLatitude,
                  "site-longitude": _vm.siteLongitude
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }