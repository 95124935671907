var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "iconButton link", on: { click: _vm.closeForm } },
      [_c("span", { staticClass: "ion-close" })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-header" }, [
      _vm._v(_vm._s(_vm.$t("Delete Selected Tasks")))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-content padded40" }, [
      _c("p", { staticClass: "orange-font" }, [
        _vm._v(_vm._s(_vm.headers[_vm.activeSensor + "_preview"]))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "table",
          [
            _c(
              "tr",
              [
                _vm._l(_vm.allHeadings, function(heading, i) {
                  return [
                    !["id", "subDetails", "childRowLoading"].includes(i) &&
                    !i.endsWith("Id")
                      ? _c("th", { key: i }, [_vm._v(_vm._s(heading) + " ")])
                      : _vm._e()
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.taskRows, function(taskRow, taskRowIndex) {
              return [
                _c(
                  "tr",
                  { key: "taskRow-" + taskRowIndex },
                  [
                    _vm._l(_vm.allHeadings, function(heading, i) {
                      return [
                        !["id", "subDetails", "childRowLoading"].includes(i) &&
                        !i.endsWith("Id")
                          ? _c(
                              "td",
                              { key: "taskRowCell-" + taskRowIndex + "-" + i },
                              [
                                i === "deploymentName" && taskRow["subDetails"]
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                              " +
                                          _vm._s(taskRow[i]) +
                                          " "
                                      ),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: taskRow["subDetails"],
                                            expression: "taskRow['subDetails']"
                                          }
                                        ],
                                        staticClass:
                                          "ion-information-circled text-yellow-500"
                                      })
                                    ])
                                  : _c("span", [
                                      taskRow[i]
                                        ? _c("span", [
                                            _vm._v(_vm._s(taskRow[i]))
                                          ])
                                        : _c("span", [_vm._v("Unknown")])
                                    ])
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "orange-font" }, [
        _vm._v(_vm._s(_vm.headers.delete_media))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _c(
          "div",
          { staticClass: "row col s12" },
          [
            _vm.loading
              ? _c("pulse-loader", {
                  staticClass: "preview-pulse",
                  attrs: {
                    loading: true,
                    color: "#C94412",
                    size: "20px",
                    width: 400,
                    height: 400
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.errors, function(error, index) {
              return _c("div", { key: index, staticClass: "error" }, [
                _vm._v(" " + _vm._s(error) + " ")
              ])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          [
            true
              ? [
                  !_vm.bDeleted
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn modal-trigger modal-action modal-close",
                          on: {
                            click: function($event) {
                              return _vm.deleteTask(true)
                            }
                          }
                        },
                        [[_vm._v(_vm._s(_vm.$t("taskDeleteForm-deleteBtn")))]],
                        2
                      )
                    : _vm._e()
                ]
              : [
                  !_vm.bDeleted
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-success modal-trigger modal-action modal-close",
                          on: {
                            click: function($event) {
                              return _vm.deleteTask(false)
                            }
                          }
                        },
                        [[_vm._v(_vm._s(_vm.$t("taskDeleteForm-userRow")))]],
                        2
                      )
                    : _vm._e()
                ],
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-border modal-action modal-close",
                attrs: { id: "cancelAdd" },
                on: { click: _vm.closeForm }
              },
              [_vm._v(_vm._s(_vm.$t("common-close")))]
            )
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }