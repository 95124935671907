var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "carousel",
        {
          attrs: { "per-page": 1, "mouse-drag": false, "pagination-padding": 3 }
        },
        _vm._l(_vm.feature, function(slide, index) {
          return _c(
            "slide",
            { key: "slide" + index },
            _vm._l(slide, function(row, key) {
              return _c(
                "p",
                { key: "slidecontent" + key, staticClass: "short" },
                [
                  row != null
                    ? _c("span", [
                        _vm._v(_vm._s(key) + ": "),
                        _c("strong", [_vm._v(_vm._s(row))])
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }