var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container overflow tabbed" }, [
    _vm.userData && _vm.initialValues
      ? _c("div", { staticClass: "boxed padded40" }, [
          _c("h2", [_vm._v(_vm._s(_vm.initialValues.name))]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("userPage-userSettings")) + " "
            ),
            _vm.changesDetected
              ? _c("span", { staticClass: "error" }, [
                  _vm._v("(* " + _vm._s(_vm.$t("common-unsaved")) + ")")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s12 m4 l4" }, [
              _c("label", { attrs: { for: "user_name" } }, [
                _vm._v(_vm._s(_vm.$t("userPage-name")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userData.name,
                    expression: "userData.name"
                  }
                ],
                attrs: {
                  id: "user_name",
                  type: "text",
                  maxlength: _vm.maxNameLength,
                  required: ""
                },
                domProps: { value: _vm.userData.name },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.userData, "name", $event.target.value)
                    },
                    function($event) {
                      return _vm.checkForChanges(_vm.initialValues.name, $event)
                    }
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col s12 m4 l4" }, [
              _c("label", { attrs: { for: "user_initials" } }, [
                _vm._v(_vm._s(_vm.$t("userPage-initials")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userData.initials,
                    expression: "userData.initials"
                  }
                ],
                attrs: {
                  id: "user_initials",
                  type: "text",
                  maxLength: _vm.maxInitialsLength
                },
                domProps: { value: _vm.userData.initials },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.userData, "initials", $event.target.value)
                    },
                    function($event) {
                      return _vm.checkForChanges(
                        _vm.initialValues.initials,
                        $event
                      )
                    }
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _vm.auth0ProfileOriginal
              ? _c("div", { staticClass: "col s12 m5 l5" }, [
                  _c("label", { attrs: { for: "user_email" } }, [
                    _vm._v(_vm._s(_vm.$t("userPage-email")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.auth0ProfileOriginal.email,
                        expression: "auth0ProfileOriginal.email"
                      }
                    ],
                    attrs: {
                      id: "user_email",
                      type: "text",
                      maxLength: _vm.maxEmailLength,
                      required: "",
                      disabled: true
                    },
                    domProps: { value: _vm.auth0ProfileOriginal.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.auth0ProfileOriginal,
                          "email",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col s12 m8 l8" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("userPage-mailList")) + " "),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("userPage-tooltips.mailList"),
                        expression: "$t('userPage-tooltips.mailList')"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                ]),
                _vm._v(" "),
                _c("ToggleButton", {
                  staticClass: "mail-toggle",
                  attrs: {
                    value: _vm.userData.inMailList,
                    sync: true,
                    color: "#C94412"
                  },
                  on: { change: _vm.mailToggled }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.languages
              ? _c(
                  "div",
                  { staticClass: "col s12 m8 l8" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("userPage-language")))]),
                    _vm._v(" "),
                    _vm._l(_vm.languages, function(lang, index) {
                      return _c("div", { key: "language" + index }, [
                        _c(
                          "label",
                          { key: "lang-" + index, staticClass: "form-option" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userData.languageCode,
                                  expression: "userData.languageCode"
                                }
                              ],
                              attrs: { type: "radio", id: "language" },
                              domProps: {
                                value: lang.id,
                                checked: _vm._q(
                                  _vm.userData.languageCode,
                                  lang.id
                                )
                              },
                              on: {
                                change: [
                                  function($event) {
                                    return _vm.$set(
                                      _vm.userData,
                                      "languageCode",
                                      lang.id
                                    )
                                  },
                                  function($event) {
                                    _vm.checkForChanges(
                                      parseInt(_vm.initialValues.languageCode),
                                      $event
                                    )
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t(`common-languages.${lang.id}`))
                              )
                            ])
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col s12 m6 l6" }, [
              _c("label", { attrs: { for: "user_affiliation" } }, [
                _vm._v(_vm._s(_vm.$t("userPage-affiliation")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userData.affiliation,
                    expression: "userData.affiliation"
                  }
                ],
                attrs: { id: "user_affiliation", type: "text" },
                domProps: { value: _vm.userData.affiliation },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.userData, "affiliation", $event.target.value)
                    },
                    function($event) {
                      return _vm.checkForChanges(
                        _vm.initialValues.affiliation,
                        $event
                      )
                    }
                  ]
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col s12" }, [
              _c("label", { attrs: { for: "access_token" } }, [
                _vm._v(_vm._s(_vm.$t("JWT Access Token")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }, [
                _c("input", {
                  ref: "access_token",
                  attrs: { id: "access_token", type: "text" },
                  domProps: { value: _vm.accessToken }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: "Copied!",
                          show: _vm.showClipboardTooltip,
                          trigger: "manual"
                        },
                        expression:
                          "{content: 'Copied!', show: showClipboardTooltip, trigger: 'manual'}"
                      }
                    ],
                    staticClass: "btn",
                    on: { click: _vm.copyToken }
                  },
                  [_c("i", { staticClass: "fa-solid fa-clipboard" })]
                )
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "block text-sm" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "This is your personal access token to access Wildtrax. Use it to authorize in other applications, like within the wildRtrax package. Do not share this token with anyone. "
                    )
                  ) + " "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href:
                        "https://abbiodiversity.github.io/wildRtrax/index.html"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("wildRtrax can be found here.")))]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "msg-wrapper" },
            [
              _vm._l(_vm.errors, function(error, index) {
                return _c(
                  "div",
                  { key: index + "error", staticClass: "error" },
                  [_vm._v("\n            " + _vm._s(error) + "\n            ")]
                )
              }),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n            " + _vm._s(_vm.finishMsg) + "\n          "
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                attrs: { disabled: !_vm.changesDetected },
                on: {
                  click: function($event) {
                    return _vm.updateUser()
                  }
                }
              },
              [
                _c("span", { staticStyle: { "font-size": "20px" } }),
                _vm._v(_vm._s(_vm.$t("common-update")))
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }