<template>
  
  <div id="app">
    <header class="mb-5 pt-10 z-10 relative" v-cloak>
      <div class="h-24">
        <nav class="headerNav container overflow boxed">
          <div class="headerLogo">
            <!-- <router-link to="/" class="logo"></router-link> -->
            <a class="logo" href="https://wildtrax.ca" target="_blank"></a>
          </div>
            <ul class="logIn mainNav" id="userInfo">
              <span class="logininfo user-login">
              <li class="profile-area">
                <router-link class="!text-burntOrange navigation-mainLink" v-if="loggedIn && profile" to="/home/user-settings.html"><i class="fa fa-user text-sm"></i> {{ profile.name }}</router-link>
               </li>
                <li class="profile-area">
                  <a v-if="loggedIn" class="!text-burntOrange " @click="logout">
                    <i class="fa fa-right-from-bracket text-sm"></i> 
                    {{$t('signIn-logout')}}
                  </a>
                  <a class="!text-burntOrange" v-else @click="login">
                    <i class="fa fa-right-to-bracket text-sm"></i>
                    {{$t('signIn-login')}}
                  </a>
                </li>
              </span>


            </ul>
          <transition name="slide-fade">
            <div class="mobileWrap" :style="mobileNavStyles" v-show="!mobileNavVisible || mobileNavOpen" >
              <ul class="mainNav">
              <li><a href="https://wildtrax.ca/resources/user-guide/" target="_blank">Guide</a></li>
              <li><router-link to="/data-discover.html">{{$t('dataDiscover-exploreData')}}</router-link></li>
              <li class="hasChildren abmi"  v-if="loggedIn">
                <div class="abmi sub-toggle" @click="subMenuClick"></div> <a
                  :class="{'navigation-mainLink ':true, 'border-burntOrange border !text-burntOrange hover:!bg-burntOrange hover:!text-white':!mobileNavVisible}">{{$t('My Data')}}</a>
                <div data-v-2b6409dc="" class="sub-menu-wrap" :style="subMenuNavStyles">
                  <ul data-v-2b6409dc="" class="submenu">
                  
                    <li @click="activateNav"><router-link  to="/home/organization-list.html">{{$t('org-myOrganizations')}}</router-link></li>
                    <li @click="activateNav"><router-link  to="/home/projects.html">{{$t('My Projects')}}</router-link></li>
                    <li @click="activateNav"><router-link  to="/home/projects/data-download.html">{{$t('Data Downloads')}}</router-link></li>
                  </ul>
                </div>
              </li> 
            
              </ul>
            </div>
          </transition>

         

          <div :class="{'nav-activator hide-on-large-only': true, 'open':mobileNavOpen}" @click="activateNav">
            <button :class="{'hamburger hamburger--spin':true,'is-active':mobileNavOpen}" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </nav>
      </div>
    </header>
   
    <router-view></router-view>
    <footer v-cloak>
      <div class="container large">
        <div class="row">
          <div class="col s12 m4 l2">
            <ul class="footerNav">
              <li class="hasChildren"> 
        <div class="sub-toggle"> </div>
               
        <div class="sub-menu-wrap">
                    <ul class="sub-menu">
                </ul>
                </div>
           
    </li>
    <li class="hasChildren"> 
        <div class="sub-toggle"> </div>
               
                <div class="sub-menu-wrap">
                    <ul class="sub-menu">
                </ul>
                </div>
           
    </li>
            </ul>
          </div>
          <div class="col s12 m8 l6 contactInfo">
            <div class="row">
              <div class="col s12 m6 l6 xl5">
      <div class="contentRichText richtext">
        <p>1-107 Centennial Centre for<br>
    Interdisciplinary Studies (CCIS)<br>
    University of Alberta, Edmonton,<br>
    AB, Canada T6G 2E9</p>
    
      </div>
              </div>
              <div class="col s12 m6 l6 xl7">
      <div class="contentRichText richtext">
        <p><strong>Telephone:</strong>&nbsp;780.248.1592<br>
    <strong>Fax:</strong>&nbsp;780.492.7635<br>
    info@wildtrax.ca</p>
    
      </div>
              </div>
            </div>
          </div>
          <div class="col s12 m12 l4">
    
    
                <p class="learnMoreAbmi"><a href="https://us4.campaign-archive.com/home/?u=de3236a0b48dda3b348d3943b&amp;id=ebc7168db0" target="_blank">Archive</a> | <a href="//abmi.ca">Learn more about ABMI »</a></p>
    
          </div>
          <div class="col s12 m12 l12 copyright1">
            <hr>
            <span> 
    
    
    <a href="/home/terms-of-use" class=" hide ">
           Privacy Policy and Terms 
    </a>     
    
    
    <a href="/home/terms-of-use" class="hide ">
           | Privacy Policy and Terms 
    </a>     
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>

</template>

<script>
import { isDataDiscover } from '@/lib/common';
import { isLoggedIn, getProfile, checkLanguageToken, handleAuthentication, login, logout } from '@/lib/user-auth0.js'
import { eventBus } from '@/lib/eventbus.js'

export default {
  name: 'WildtraxApp',
  mounted () {
    this.profile = localStorage.getItem('profile')?JSON.parse(localStorage.getItem('profile')):null;
    const self = this;
    eventBus.$on('token_updated', (data) => {
      isLoggedIn()
      getProfile();
    });
    eventBus.$on('profile_updated', profile=> {
      if (typeof profile == 'string') {
        this.profile =JSON.parse(profile);
      } else {
        this.profile = profile;
      }
    });
    eventBus.$on('authentication_updated', isLoggedIn => {
      this.loggedIn = isLoggedIn;
    });
    window.addEventListener('resize', e=>{
      this.windowWidth = e.target.innerWidth;
    });
    this.windowWidth = window.innerWidth;
  },
  computed: {
    mobileNavVisible() {
      return this.windowWidth < 993;
    },
    mobileNavStyles() {
      if (this.mobileNavVisible && this.mobileNavOpen) {
        return {
          height: "auto"
        }
      }
      return {};
    },
    subMenuNavStyles() {
      if (this.subMenuToggle) {
        return {
          height: "auto"
        };
      }
      return { height: 0};
    }
  },
  methods: {
    login() {
      login();
    },
    logout() {
      logout();
    },
    subMenuClick(e){
      this.subMenuToggle = !this.subMenuToggle;
      if (!this.subMenuToggle) {
        e.target.classList.remove('toggled');
      } else {
        e.target.classList.add('toggled');
      }
    },
    activateNav() {
      this.mobileNavOpen=!this.mobileNavOpen;
      // var headHeight = $('.navContain').height();
			// var windowHeight = $(window).height() - headHeight;
			// var navHeight = $('.mobileWrap .mainNav').outerHeight(true);

			// $('.mobileWrap').css('max-height', windowHeight);

			// if(!$(this).hasClass("open")){
			// 	if($('.searchWrap').is(":visible")){
			// 		$('.search-act').removeClass('open');
			// 		$('.search-act').addClass('close');
			// 		$('.searchWrap').slideToggle(150);
			// 	}

			// 	$('.mobileWrap').stop().animate({
			// 		height:navHeight
			// 	},150,function(){
			// 		$(this).css({'height':'auto'});
			// 	});

			// 	$(this).addClass('open');

			// 	$('body').css('overflow','hidden');

			// }else{

			// 	$('.mobileWrap').stop().animate({
			// 		height:0
			// 	},150,function(){
			// 		$(this).removeAttr('style');
			// 	});

			// 	$(this).removeClass("open");

			// 	$('body').css('overflow','auto');
			// }

    }
  },
  watch: {
   
  },
  data() {
    return {
      profile: {},
      DDOnly: isDataDiscover(),
      loggedIn: isLoggedIn(),
      mobileNavOpen:false,
      windowWidth: 0,
      subMenuToggle: false
    }
  }
}


</script>

<style scoped>

.headerNav .mainNav li a {
  text-transform: uppercase;
}
.headerNav .mainNav li a:before{
  content: " ";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
