var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "weather-modal" }, [
    _c(
      "div",
      { staticClass: "iconButton link", on: { click: _vm.closeForm } },
      [_c("span", { staticClass: "ion-close" })]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-header drag-handle" }, [
      _c("h5", [
        _vm._v(_vm._s(_vm.$t("weatherModal-title")) + " "),
        _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("weatherSegment-tooltip"),
              expression: "$t('weatherSegment-tooltip')"
            }
          ],
          staticClass: "fas fa-question-circle"
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-content padded40" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "col m12 s12 centered loader-wrapper"
        },
        [
          _c(
            "div",
            { staticClass: "loading" },
            [
              _c("pulse-loader", {
                attrs: {
                  loading: true,
                  color: "#C94412",
                  size: "25px",
                  width: 100,
                  height: 50
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "col m12 s12" },
            [
              _vm.totalStations
                ? _c("div", { staticClass: "station-btn-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-border",
                        attrs: { disabled: _vm.currentStationIndex <= 0 },
                        on: {
                          click: function($event) {
                            return _vm.previousStation()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "ion-ios-arrow-thin-left" }),
                        _vm._v(" " + _vm._s(_vm.$t("common-previous")))
                      ]
                    ),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.$t("weatherModal-stationOfStations", {
                            num: _vm.currentStationIndex + 1,
                            total: _vm.totalStations
                          })
                        ) +
                        "\n              "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-border",
                        attrs: {
                          disabled:
                            _vm.currentStationIndex + 1 >= _vm.totalStations
                        },
                        on: {
                          click: function($event) {
                            return _vm.nextStation()
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.$t("common-next")) + " "),
                        _c("span", { staticClass: "ion-ios-arrow-thin-right" })
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("location-map", {
                ref: "map",
                attrs: {
                  "mapbox-classes": { "!h-80 !text-left": true },
                  "map-type": "weather",
                  "category-string":
                    _vm.dateString +
                    "&locationId=" +
                    _vm.locationId +
                    "&projectId=" +
                    _vm.projectId,
                  "site-location": {
                    latitude: _vm.siteLatitude,
                    longitude: _vm.siteLongitude
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.currentStation
        ? _c("div", { staticClass: "col m12" }, [
            _c(
              "h5",
              [
                _vm.currentStation.hourlyStationName
                  ? [_vm._v(_vm._s(_vm.currentStation.hourlyStationName))]
                  : _vm.currentStation.dailyStationName
                  ? [_vm._v(_vm._s(_vm.currentStation.dailyStationName))]
                  : _c("span", [_vm._v(_vm._s(_vm.$t("weather-data-unknown")))])
              ],
              2
            ),
            _vm._v(" "),
            _c("table", [
              _vm._m(0),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.distanceFromStation")))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation &&
                    _vm.currentStation.dailyStationDistance
                      ? [
                          _vm._v(
                            _vm._s(_vm.currentStation.dailyStationDistance)
                          )
                        ]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.windSpeed")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyWindSpeed
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyWindSpeed))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.stationElevation")))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation &&
                    _vm.currentStation.dailyStationElevation
                      ? [
                          _vm._v(
                            _vm._s(_vm.currentStation.dailyStationElevation)
                          )
                        ]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.temperature")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyTemperature
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyTemperature))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.stationCoords")))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation &&
                    (_vm.currentStation.dailyStationLatitude ||
                      _vm.currentStation.dailyStationLongitude)
                      ? [
                          _vm._v(
                            _vm._s(_vm.currentStation.dailyStationLatitude) +
                              ", " +
                              _vm._s(_vm.currentStation.dailyStationLongitude)
                          )
                        ]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.dewPoint")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyDewPoint
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyDewPoint))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.mean")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailyMeanTemp
                      ? [_vm._v(_vm._s(_vm.currentStation.dailyMeanTemp))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.humidity")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyHumidity
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyHumidity))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.snowOnGround")) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailySnowOnGround
                      ? [_vm._v(_vm._s(_vm.currentStation.dailySnowOnGround))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.pressure")) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyPressure
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyPressure))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.totalSnow")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailyTotalSnow
                      ? [_vm._v(_vm._s(_vm.currentStation.dailyTotalSnow))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.windchill")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyWindChill
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyWindChill))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.min")) + " ")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailyMinTemp
                      ? [_vm._v(_vm._s(_vm.currentStation.dailyMinTemp))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.windDirection")))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyWindDirection
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyWindDirection))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.max")) + " ")]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailyMaxTemp
                      ? [_vm._v(_vm._s(_vm.currentStation.dailyMaxTemp))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.humidex")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyHumidex
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyHumidex))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.precipitation")))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailyPrecipitation
                      ? [_vm._v(_vm._s(_vm.currentStation.dailyPrecipitation))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.precipitation")) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyPrecipitation
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyPrecipitation))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("weather-data.totalRain")) + " ")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.dailyTotalRain
                      ? [_vm._v(_vm._s(_vm.currentStation.dailyTotalRain))]
                      : [_vm._v("--")]
                  ],
                  2
                ),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("weather-data.visibility")))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.currentStation && _vm.currentStation.hourlyVisibility
                      ? [_vm._v(_vm._s(_vm.currentStation.hourlyVisibility))]
                      : [_vm._v("--")]
                  ],
                  2
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        { staticClass: "font-bold text-base", attrs: { colspan: "2" } },
        [_vm._v("Daily")]
      ),
      _vm._v(" "),
      _c(
        "th",
        { staticClass: "font-bold text-base", attrs: { colspan: "2" } },
        [_vm._v("Hourly")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }