<template>
  <div>
    <div class="col s12 panel boxed padded60 dataGroup tab-content">
      <div class="button-section-wrapper">
        <button v-if="options != null" class="btn btn-success organizationAdd" @click="createOrganization"> <span style="font-size: 20px;"></span> {{$t('org-addBtn')}}</button>
        <span v-tooltip="$t('Toggle On to see all organizations you have access to. Toggle Off to display all WildTrax organizations. Click the dropdown beside any organization to find out more or to request access.')" v-if="toggleInitSetting !== null" class="org-toggle"> {{$t('org-viewOnlyMine')}} 
          <ToggleButton :value="toggleInitSetting" :sync="true" color="#C94412" @change="switchOrgView"></ToggleButton></span>
      </div>

      <v-server-table ref="table" :url="url" :columns="columns" :options="tableOptions" @row-click="onRowClick" @loaded="tableLoaded()" class="table vue-bordered w-full org-table">
        <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
       
        <template slot="organization" slot-scope="props">
          <a v-if="props.row.canOpenOrganization && !props.row.isPendingStatus" :href="taskRowUrl(props.row.id)">
            {{props.row.organization}}
          </a>
          <span v-else>{{props.row.organization}}</span>
        </template>
        <template slot="isPendingStatus" slot-scope="props">
          {{props.row.isPendingStatus?$t('org-statusOptions.pending'):$t('org-statusOptions.approved')}}
        </template>
        <template slot="id" slot-scope="props">
          <a v-if="props.row.canEditOrganization" class="editProject modal-trigger projectAdd" @click="updateOrganization(props.row)">
            <span class="link" :class="{'ion-edit': props.row.canEditOrganization, 'ion-eye': !props.row.canEditOrganization}"></span></a>
        </template>
        <div class="row" slot="child_row" slot-scope="props">
          <div class="col s12 m12 l12" v-if="props.row.fullName">
            <p class="left-align"><b>{{$t('org-fullName')}}: </b>{{props.row.fullName}}</p>
          </div>
          <div class="col s12 m12 l12">
            <p v-if="props.row.description" class="left-align"><b>{{$t('common-description')}}: </b>{{props.row.description}}</p>
            <button v-if="props.row.canRequestAccess && !props.row.isPendingStatus" class="float-right btn btn-border" @click="requestAccess(props.row)">{{$t('common-requestAccess')}}</button>
          </div>
        </div>
      </v-server-table>
    </div>

    <organization-form ref="organizationForm" v-if="organizationId != -1 && options != null && organizationName == null"
      v-bind:organization-id="organizationId"  v-bind:is-editable="isEditable"
      v-bind:is-global-admin="isGlobalAdmin"
      v-bind:options="options"
    />

    <modal name="requestAccess"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "75%"
      height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <project-access-request-form ref="projectAccessRequestForm" v-if="organizationId != -1 && organizationName != null" v-bind:organizationId="organizationId" v-bind:organizationName="organizationName"
      />
    </modal>

    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all organizations
under each Organization tab, show a table listing all organizations. users can also update Organization properties + users
or remove organizations if they have no associated data.
*/
import OrganizationForm from './OrganizationForm'
import { eventBus } from '@/lib/eventbus';
import { API_URL, ORGANIZATION_ADMIN_URL, alertDialog, attachAuthorizaitonHeader } from '@/lib/common';
import { createLink } from '@/lib/language.js';
import ProjectAccessRequestForm from '../project/ProjectAccessRequestForm';
import FormMixin from '@/components/common/FormMixin.js';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  name: 'OrganizationTable',
  components: {
    'organization-form': OrganizationForm, 'project-access-request-form': ProjectAccessRequestForm, 'ToggleButton': ToggleButton
  },
  props: {
    isAdmin: {
      default: null,
      type: Boolean
    }
  },
  mixins: [FormMixin],
  created () {
    const that = this;
    attachAuthorizaitonHeader();
    // get options
    this.$http.get(API_URL + 'organization-details-static-options').then(
      function (response) {
        this.options = response.data;
      });
    // get global admin options from sensor option is sensorAdmin, both ARU and camera isSensorAdmin are the same
    if (this.isAdmin === null) {
      this.$http.get(API_URL + 'sensor-options').then(
        function (response) {
          if (response.data.hasOwnProperty('error')) {
            this.error = response.data.error;
          }
          this.sensors = response.data;
          if (this.sensors != null) {
            this.sensors.forEach(x => { this.isGlobalAdmin = this.isGlobalAdmin || x.isSensorAdmin; });
          }
        }, response => {
          // error callback
          this.error = this.$tc('common-error', 1) + ': ' + (response.data ? response.data.error : '') + ' ' + response.status;
        }
      );
    } else {
      this.isGlobalAdmin = this.isAdmin;
    }
    eventBus.$on('reload-table', function () {
      that.$refs.table.refresh();
    });
    eventBus.$on('close-organization-form', function (organizationId) {
      that.organizationId = -1;
    });
    eventBus.$on('close-request-access-form', function () {
      that.organizationId = -1;
      that.organizationName = null;
    });
    eventBus.$on('organization-created', function () {
      that.toggleInitSetting = true;
      that.tableOptions.isMember = true;
      that.$refs.table.refresh();
    });
  },
  watch: {
    toggleInitSetting (newval, oldval) {
      console.log(newval);
    }
  },
  methods: {
    setupTableHeadings () {
      let headings = {
        organization: this.$t('org-headings.organization'),
        fullName: this.$t('org-headings.fullName'),
        projects: this.$t('org-headings.projects'),
        locations: this.$t('org-headings.locations'),
        isPendingStatus: this.$t('common-status')
      };

      for (let heading in headings) {
        this.tableOptions.headings[heading] = headings[heading];
      }

      let texts = {
        count: this.$t('organizationList-tableTexts.count'),
        limit: this.$t('organizationList-tableTexts.limit'),
        noResults: this.$t('No matching Organizations'),
        first: this.$t('common-tableTexts.first'),
        last: this.$t('common-tableTexts.last'),
        filter: this.$t('common-tableTexts.filter'),
        filterPlaceholder: this.$t('common-tableTexts.filterPlaceholder'),
        page: this.$t('common-tableTexts.page'),
        noRequest: this.$t('common-tableTexts.noRequest'),
        filterBy: this.$t('common-tableTexts.filterBy'),
        loading: this.$t('common-tableTexts.loading'),
        defaultOption: this.$t('common-tableTexts.defaultOption'),
        columns: this.$t('common-tableTexts.columns')
      };
      for (let text in texts) {
        this.tableOptions.texts[text] = texts[text];
      }
    },
    toggleAll () {
      this.selected = this.selectedAll ? this.$refs.table.data.map(row => row.id) : [];
      // this.selectedAll  = !this.selectedAll; the click action will do this don't need to call here.
    },
    updateOrganization (row) {
      this.organizationId = row.id;
      this.isEditable = row.canEditOrganization;
      this.showOrganizationForm();
    },
    createOrganization () {
      this.organizationId = null;
      this.isEditable = true;
      this.showOrganizationForm();
    },
    deleteOrganizations () { // remove selected organizations
      if (this.selected.length === 0) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.selectionRequired);
        return;
      }
      const self = this;
      this.commonDelete(this.organizationRemoveUrl, {organizationIds: this.selected}, () => {
        self.$refs.table.refresh();
      }, ' ' + this.$t('org-deleteOrgNote'));
    },
    showOrganizationForm () {
      //  console.log('organizationId =', this.organizationId)
      // this.$refs.organizationForm.switchOrganization();
      this.$nextTick(() => {
        this.$modal.show('organization');
      });
    },
    initTable () {
      this.tableData = [];
      // console.log(this === this.$refs['table']);
      // this.$refs['table'].refresh();
    },
    taskRowUrl (id) {
      return this.adminPageUrl + id;
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.id;
    },
    switchOrgView () {
      // switch between showing all orgs and only the orgs where the user is a member
      this.toggleInitSetting = !this.toggleInitSetting;
      this.tableOptions.isMember = !this.tableOptions.isMember;
      this.$refs.table.refresh();
    },
    requestAccess (row) {
      this.organizationId = row.id;
      this.organizationName = row.organization;
      this.$modal.show('requestAccess');
    },
    tableLoaded () {
      /*
      Only do this the first time table is loaded
      if user has at least 1 org, can set toggle to view only theirs
      If not, see all orgs
          */
      if (!this.tableLoadedOnce) {
        this.tableLoadedOnce = true;
        // if (this.$refs.table.data.length > 0) {
        //   // user has at least one org
        //   this.tableOptions.isMember = true
        //   this.toggleInitSetting = true;
        // } else {
        //   this.toggleInitSetting = false;
        //   this.tableOptions.isMember = false;
        // }
        // this.$refs.table.refresh();
        this.setupTableHeadings();
      }
    }
  },
  data () {
    const pageSize = 100;
    return {
      adminPageUrl: createLink(ORGANIZATION_ADMIN_URL),
      url: API_URL + 'get-organization-summary',
      organizationUpdateUrl: API_URL + 'insert-update-organization',
      organizationRemoveUrl: API_URL + 'delete-organization',
      selected: [],
      organizationId: null,
      organizationName: null,
      tableLoadedOnce: false,
      toggleInitSetting: false,
      recordName: this.$tc('common-organization', 1).toLowerCase(),
      columns: ['organization', 'fullName', 'projects', 'locations','id'],
      // customFilters: ['organizationType'],
      selectedAll: false,
      isEditable: false,
      options: {},
      isGlobalAdmin: null,
      tableOptions: {
        isMember: false, // show projects if the user is a member or not
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        // childRow: function (h, row) {
        //   return
        // },
        filterByColumn: true,
        filterable: ['organization', 'fullName', 'isPendingStatus'], // 'dueDate' can't sort or filter dueDate as
        sortable: ['organization', 'fullName', 'projects', 'locations', 'isPendingStatus'],
        listColumns: {
          isPendingStatus: [{id: false, text: this.$t('org-statusOptions.approved')}, {id: true, text: this.$t('org-statusOptions.pending')}]
        },
        headings: {
          organization: this.$t('org-headings.organization'),
          fullName: this.$t('org-headings.fullName'),
          projects: this.$t('org-headings.projects'),
          locations: this.$t('org-headings.locations'),
          isPendingStatus: this.$t('common-status'),
          id: ' '
        },
        
        columnsClasses: {
          organization: 'name',
          fullName: 'full_name',
          projects: 'tasks',
          locations: 'completed',
          isPendingStatus: 'status'
        },
        debounce: 1000, // one second debounce
        perPage: 100,
        perPageValues: [25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'organization', ascending: true},
        rowClassCallback (row) {
          let classes = [];
          if(row.isPendingStatus) classes.push('bg-gray-100');
          if(row.id === this.activeRowId) classes.push('activeTableRow');

          return classes.join(' ');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          if (this.isMember) {
            filter.push({column: 'isMember', value: this.isMember}); // set if it  member or not
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
        /*  created_at(h, row) {
            return this.formatDate(row.created_at);
          },
          updated_at(h, row) {
            return this.formatDate(row.updated_at);
          },
          pushed_at(h, row) {
            return this.formatDate(row.pushed_at);
          } */
      }
    }
  }
}
</script>
<style scoped>
  .subrow-info p {
    color: #C94412;
  }
  th.name {
    width: 20%;
  }
  th.full_name {
    width: 30%;
  }
/*  th.checkbox, th.details {
    width: 3%;
  }*/
  th.tasks {
    width: 10%;
  }
  th.completed {
    width: 12%;
  }

  th.status  {
    width: 12%;
  }
  .VueTables__table td,
  .VueTables__table th {
    text-align: center;
  }
  .VueTables__table td.name,
  .VueTables__table th.name {
    text-align: left;
  }

  .VueTables__child-row-toggler {
    cursor: pointer;
  }
  .org-table tbody td {
    cursor: pointer;
  }

  .vue-js-switch{
    padding-left: 5px;
}

.button-section-wrapper{
  display:flex;
  flex-direction: row;
  align-items: center;

}
.org-toggle{
  margin-bottom: 10px; /* To match the margin on the 2 buttons */
  margin-left: auto;
  font-weight: 600;
}
.float-right{
  float: right;
}

</style>
