import { decodeMakerNote } from '@/components/utils/exif/exif-parser-reconyx';
import { EXIF } from '@/components/utils/exif/exif-js';
const RECONYX_MAKERNOTE_INIT = new RegExp('^1241([2|3|4]0)?', 'i');


// convert date from yyyy:mm:ddThh.mm.ss to yyyy:mm:dd hh:ss:mm
export function dateParse (date)	{
  try {
    if (typeof date === 'string') {
      if (date.indexOf('-') === -1) { // when no -, all :
        const dateParsed = date.split(' ');
        return dateParsed[0].replace(/:/g, '-') + ' ' + dateParsed[1];
      }
      return date;
    } else {
      const dateParsed = date.toISOString().split('T');
      return dateParsed[0] + ' ' + dateParsed[1].split('.')[0];
    }
  } catch (e) {
    return '';
  }
}
// convert // ms ss hh mon day year -> yyyy:mm:dd hh:ss:mm
function convertDateFormat (dateTime) {
  if (!dateTime) {
    return null;
  }
  try {
    if (dateTime.indexOf(':') === -1) { // 25 27 14 12 31 2019 => 2019:12:31 14:27:25
      const dateParsed = dateTime.split(' ');
      let formattedDateTime = '';
      if (dateParsed.length === 6) {
        formattedDateTime = dateParsed[5] + ':' + dateParsed[3] + ':' + dateParsed[4] + ' ' + dateParsed[2] + ':' + dateParsed[1] + ':' + dateParsed[0];
        return formattedDateTime;
      }
    }
    return dateTime;
  } catch (e) { return null; }
}
function getExif (file) {
  return new Promise((resolve, reject) => {
    try {
      EXIF.getData(file, function () {
        if (this.error) {
          reject(this.error);
        } else {
          resolve(this.exifdata);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
}

export async function readExifDetails (file) {
  let customExif = {}
  let exifInfo;
  try {
    exifInfo = await getExif(file);
  } catch (e) {
    return {};
  }
  if (!exifInfo) {
    return {};
  }
  /* first copy metadata from commona fields */
  const exifCommonFields = ['Make', 'Model', 'Flash', 'WhiteBalance', 'SceneCaptureType'];
  exifCommonFields.forEach((field) => {
    if (exifInfo[field]) {
      customExif[field] = exifInfo[field];
    }
  });
  /* parse maker notes and pass in other metadata fields */
  const exifMakerNoteFields = ['TriggerMode', 'MoonPhase', 'AmbientTemperatureFahrenheit', 'AmbientTemperature', 'SerialNumber', 'MotionSensitivity', 'UserLabel', 'DateTimeOriginal'];
  if (exifInfo.MakerNote && (exifInfo.Make === 'RECONYX' || (typeof exifInfo.MakerNote === 'object' && RECONYX_MAKERNOTE_INIT.test(exifInfo.MakerNote.join(''))))) { // get sequence from makernote
    exifInfo.Make = 'RECONYX'; // set make to reconyx
    let makeNoteReconyx = decodeMakerNote(new Buffer(exifInfo.MakerNote), exifInfo.Model);
    const exifSequenceObj = makeNoteReconyx.find(x => { return x.name === 'Sequence'; });
    if (exifSequenceObj) {
      customExif.Sequence = exifSequenceObj.value.replace(' ', ' of ');
    }
    // read all customExif fields values
    exifMakerNoteFields.forEach((field) => {
      const obj = makeNoteReconyx.find(x => { return x.name === field; });
      if (obj) {
        customExif[field] = obj.value;
      }
    });
  } else { // for other camera make, use default make up values
    // customExif.Sequence = '1 of 1';
    // customExif.TriggerMode = 'Motion Detection';
    customExif.makeUpExif = true;
  }
  // format date and time
  // temp fix
  const standDateFormat = /^\d{4}[/\-:](0?[1-9]|1[012])[/\-:](0?[1-9]|[12][0-9]|3[01])/; // 2019:12:31 14:27:25
  const reverseDateFormat = /[/\-:\s](0?[1-9]|1[012])[/\-:\s](0?[1-9]|[12][0-9]|3[01])[/\-:\s]\d{4}$/; // 25 27 14 12 31 2019
  const dateTimeOriginal = exifInfo.DateTimeOriginal || customExif.DateTimeOriginal;
  if (standDateFormat.exec(dateTimeOriginal) || reverseDateFormat.exec(dateTimeOriginal)) {
    exifInfo.DateTimeOriginal = convertDateFormat(dateTimeOriginal);
  } else {
    exifInfo.DateTimeOriginal = convertDateFormat(exifInfo.DateTimeOriginal || exifInfo.DateTimeDigitized || customExif.DateTimeOriginal || customExif.DateTimeDigitized);
  }

  customExif.DateTimeOriginal = exifInfo.DateTimeOriginal // for sending to scanning

  exifInfo.ABMIParsed = customExif; // put all useful customExif in ABMIParsed

  return exifInfo;
}
