var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "container overflow ddMapSelect vue" }, [
        _c("div", { staticClass: "row flexRow" }, [
          _c(
            "div",
            { staticClass: "col s12 m12 l5 xl4 boxed padded40 tabbed" },
            [
              _c("h4", [_vm._v(_vm._s(_vm.$t("dataDiscover-exploreData")))]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("dataDiscover-instructions")) + " ")
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "tabs" },
                _vm._l(_vm.queryModes, function(mode, index) {
                  return _c(
                    "li",
                    {
                      key: "sensor" + index,
                      staticClass: "tab",
                      class: {
                        active: _vm.activeMode.id === mode.id,
                        [mode.className]: true
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "hyperlink",
                          on: {
                            click: function($event) {
                              _vm.activeMode = mode
                            }
                          }
                        },
                        [
                          _c("span", { class: { [mode.icon]: true } }),
                          _vm._v(
                            "\n              " +
                              _vm._s(mode && mode.label) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ddFieldsWrap boxed padded20 tab-content",
                  class: { [_vm.activeMode.className]: true }
                },
                [
                  _vm.activeMode.id == "basic"
                    ? _c("div", { staticClass: "ddFields row" }, [
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("multiselect", {
                                staticClass: "input",
                                attrs: {
                                  selectAllLabel: _vm.$t("common-selectAll"),
                                  options: _vm.organization,
                                  multiple: true,
                                  "close-on-select": false,
                                  placeholder: _vm.$tc(
                                    "common-organization",
                                    2
                                  ),
                                  label: "name",
                                  "track-by": "id",
                                  selectLabel: "",
                                  deselectLabel: " ",
                                  showSelectAll: true
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.loadOptions("sensor")
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function({ values, search, isOpen }) {
                                        return [
                                          values &&
                                          ((values.length > 1 && !isOpen) ||
                                            (values.length > 5 && isOpen))
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "multiselect__single"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataDiscover-organizationsSelected",
                                                        { num: values.length }
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3433462325
                                ),
                                model: {
                                  value: _vm.rawQuery.organizationIds,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.rawQuery,
                                      "organizationIds",
                                      $$v
                                    )
                                  },
                                  expression: "rawQuery.organizationIds"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tc("common-organization", 1)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("multiselect", {
                                staticClass: "input",
                                attrs: {
                                  selectAllLabel: _vm.$t("common-selectAll"),
                                  options: _vm.sensor,
                                  multiple: true,
                                  "close-on-select": false,
                                  selectLabel: "",
                                  deselectLabel: " ",
                                  disabled:
                                    !_vm.rawQuery.organizationIds ||
                                    _vm.rawQuery.organizationIds.length === 0,
                                  loading: _vm.optionLoading.sensor,
                                  placeholder: _vm.$tc("common-sensor", 2),
                                  label: "label",
                                  "track-by": "id",
                                  showSelectAll: true
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.loadOptions("project")
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function({ values, search, isOpen }) {
                                        return [
                                          values && values.length > 1 && !isOpen
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "multiselect__single"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataDiscover-sensorsSelected",
                                                        { num: values.length }
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3489069718
                                ),
                                model: {
                                  value: _vm.rawQuery.sensors,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "sensors", $$v)
                                  },
                                  expression: "rawQuery.sensors"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tc("common-sensor", 1)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("multiselect", {
                                staticClass: "input",
                                attrs: {
                                  selectAllLabel: _vm.$t("common-selectAll"),
                                  options: _vm.project,
                                  multiple: true,
                                  "close-on-select": false,
                                  placeholder: _vm.$t(
                                    "dataDiscover-filterProjects"
                                  ),
                                  label: "projectDisplayNm",
                                  "track-by": "projectId",
                                  project: true,
                                  selectLabel: "",
                                  deselectLabel: " ",
                                  disabled:
                                    !_vm.rawQuery.sensors ||
                                    _vm.rawQuery.sensors.length === 0,
                                  showSelectAll: true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function({ values, search, isOpen }) {
                                        return [
                                          values &&
                                          ((values.length > 1 && !isOpen) ||
                                            (values.length > 5 && isOpen))
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "multiselect__single"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataDiscover-projectsSelected",
                                                        { num: values.length }
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3527607387
                                ),
                                model: {
                                  value: _vm.rawQuery.projectIds,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "projectIds", $$v)
                                  },
                                  expression: "rawQuery.projectIds"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tc("common-project", 1)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  type: "date",
                                  valueType: "format",
                                  lang: _vm.locales[_vm.$root.$i18n.locale],
                                  format: "YYYY-MM-DD",
                                  "value-format": "YYYY-MM-DD"
                                },
                                model: {
                                  value: _vm.rawQuery.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "startDate", $$v)
                                  },
                                  expression: "rawQuery.startDate"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("common-startDate")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  type: "date",
                                  valueType: "format",
                                  lang: _vm.locales[_vm.$root.$i18n.locale],
                                  format: "YYYY-MM-DD",
                                  "value-format": "YYYY-MM-DD"
                                },
                                model: {
                                  value: _vm.rawQuery.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "endDate", $$v)
                                  },
                                  expression: "rawQuery.endDate"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("common-endDate")))
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeMode.id == "spp"
                    ? _c("div", { staticClass: "ddFields row" }, [
                        _c(
                          "div",
                          { staticClass: "input-field col s12" },
                          [
                            false
                              ? _c("multiselect", {
                                  staticClass: "input",
                                  attrs: {
                                    selectAllLabel: _vm.$t("common-selectAll"),
                                    options: _vm.sensor,
                                    multiple: false,
                                    "close-on-select": false,
                                    selectLabel: "",
                                    deselectLabel: " ",
                                    disabled:
                                      false &&
                                      (!_vm.sensor || _vm.sensor.length === 0),
                                    loading: _vm.optionLoading.sensor,
                                    placeholder: _vm.$tc("common-sensor", 2),
                                    label: "label",
                                    "track-by": "id",
                                    showSelectAll: false
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.loadOptions("project")
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function({
                                          values,
                                          search,
                                          isOpen
                                        }) {
                                          return [
                                            values &&
                                            values.length > 1 &&
                                            !isOpen
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "multiselect__single"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "dataDiscover-sensorsSelected",
                                                          { num: values.length }
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3489069718
                                  ),
                                  model: {
                                    value: _vm.rawQuery.sensors,
                                    callback: function($$v) {
                                      _vm.$set(_vm.rawQuery, "sensors", $$v)
                                    },
                                    expression: "rawQuery.sensors"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select-wrapper" },
                              [
                                _vm._l(_vm.sensor, function(opt, index) {
                                  return [
                                    _c(
                                      "label",
                                      {
                                        key: "sppSensorOpt" + index,
                                        staticClass: "form-option"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.rawQuery.sensors,
                                              expression: "rawQuery.sensors"
                                            }
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: opt.id,
                                            name: "sensorRadio"
                                          },
                                          domProps: {
                                            value: opt.id,
                                            checked: Array.isArray(
                                              _vm.rawQuery.sensors
                                            )
                                              ? _vm._i(
                                                  _vm.rawQuery.sensors,
                                                  opt.id
                                                ) > -1
                                              : _vm.rawQuery.sensors
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a = _vm.rawQuery.sensors,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = opt.id,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.rawQuery,
                                                        "sensors",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.rawQuery,
                                                        "sensors",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.rawQuery,
                                                    "sensors",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.loadOptions(
                                                  "project"
                                                )
                                              }
                                            ]
                                          }
                                        }),
                                        _c("span", [_vm._v(_vm._s(opt.label))])
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(_vm._s(_vm.$tc("common-sensor", 1)))
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("multiselect", {
                                staticClass: "input",
                                attrs: {
                                  selectAllLabel: _vm.$t("common-selectAll"),
                                  options: _vm.project,
                                  multiple: true,
                                  "close-on-select": false,
                                  placeholder: _vm.$t(
                                    "projectDownload-chooseProjects"
                                  ),
                                  label: "projectDisplayNm",
                                  "track-by": "projectId",
                                  project: true,
                                  selectLabel: "",
                                  deselectLabel: " ",
                                  showSelectAll: true,
                                  loading: _vm.optionLoading.project,
                                  disabled:
                                    !_vm.rawQuery.sensors ||
                                    _vm.rawQuery.sensors.length === 0
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.loadOptions("species")
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function({ values, search, isOpen }) {
                                        return [
                                          values &&
                                          ((values.length > 1 && !isOpen) ||
                                            (values.length > 5 && isOpen))
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "multiselect__single"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dataDiscover-projectsSelected",
                                                        { num: values.length }
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3527607387
                                ),
                                model: {
                                  value: _vm.rawQuery.projectIds,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "projectIds", $$v)
                                  },
                                  expression: "rawQuery.projectIds"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tc("common-project", 1)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("multiselect", {
                                staticClass: "input",
                                attrs: {
                                  selectAllLabel: _vm.$t("common-selectAll"),
                                  options: _vm.species,
                                  multiple: true,
                                  "close-on-select": false,
                                  placeholder: _vm.$t(
                                    "dataDiscover-filterSpecies"
                                  ),
                                  label: "species_common_name",
                                  "track-by": "species_id",
                                  selectLabel: "",
                                  deselectLabel: " ",
                                  loading: _vm.optionLoading.species,
                                  disabled:
                                    !_vm.rawQuery.projectIds ||
                                    _vm.rawQuery.projectIds.length === 0
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function({ values, search, isOpen }) {
                                        return [
                                          values &&
                                          ((values.length > 1 && !isOpen) ||
                                            (values.length > 5 && isOpen))
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "multiselect__single"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "common-speciesSelected",
                                                        values.length,
                                                        { num: values.length }
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  913654924
                                ),
                                model: {
                                  value: _vm.rawQuery.species,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "species", $$v)
                                  },
                                  expression: "rawQuery.species"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$tc("common-species", 2)))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12 m12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  type: "date",
                                  valueType: "format",
                                  lang: _vm.locales[_vm.$root.$i18n.locale],
                                  format: "YYYY-MM-DD",
                                  "value-format": "YYYY-MM-DD"
                                },
                                model: {
                                  value: _vm.rawQuery.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "startDate", $$v)
                                  },
                                  expression: "rawQuery.startDate"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("common-startDate")) + " "),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.date,
                                  expression: "tooltips.date"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-field col s12 m12" }, [
                          _c(
                            "div",
                            { staticClass: "select-wrapper" },
                            [
                              _c("date-picker", {
                                attrs: {
                                  type: "date",
                                  valueType: "format",
                                  lang: _vm.locales[_vm.$root.$i18n.locale],
                                  format: "YYYY-MM-DD",
                                  "value-format": "YYYY-MM-DD"
                                },
                                model: {
                                  value: _vm.rawQuery.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.rawQuery, "endDate", $$v)
                                  },
                                  expression: "rawQuery.endDate"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("common-endDate")) + " "),
                            _c("span", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.tooltips.date,
                                  expression: "tooltips.date"
                                }
                              ],
                              staticClass: "info-icon ion-information-circled"
                            })
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("div", { key: index, staticClass: "error" }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "center-align" }, [
                    _c(
                      "button",
                      { staticClass: "btn", on: { click: _vm.searchForData } },
                      [_vm._v(" " + _vm._s(_vm.$t("common-search")))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "center-align" }, [
                    _c(
                      "button",
                      { staticClass: "btn", on: { click: _vm.resetForm } },
                      [_vm._v(" " + _vm._s(_vm.$t("common-reset")))]
                    )
                  ])
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col s12 m12 l7 xl8 ddMap",
              staticStyle: { position: "relative", border: "1px solid #ddd" }
            },
            [
              _vm.loading || _vm.apiLoad
                ? _c(
                    "div",
                    { staticClass: "loading !z-20" },
                    [
                      _c("pulse-loader", {
                        attrs: {
                          loading: true,
                          color: "#127916",
                          size: "25px",
                          width: 100,
                          height: 50
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.apiLoad,
                    expression: "apiLoad"
                  }
                ],
                staticClass: "absolute w-full h-full bg-orange-500/25 z-10"
              }),
              _vm._v(" "),
              _c("point-map", { ref: "map" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "absolute top-2 left-5 w-24 text-left" },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn w-full !text-xs !p-3 !py-1 !mb-0 !block text-left !rounded-t !rounded-b-none",
                      on: {
                        click: function($event) {
                          return _vm.setStyle("light-v10")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "far !text-xs",
                        class: {
                          "fa-check-square": _vm.mapStyle == "light-v10",
                          "fa-square": _vm.mapStyle != "light-v10"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("common-light")))
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none",
                      on: {
                        click: function($event) {
                          return _vm.setStyle("satellite-streets-v11")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "far !text-xs",
                        class: {
                          "fa-check-square":
                            _vm.mapStyle == "satellite-streets-v11",
                          "fa-square": _vm.mapStyle != "satellite-streets-v11"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("common-satellite")))
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-none",
                      on: {
                        click: function($event) {
                          return _vm.setStyle("streets-v11")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "far !text-xs",
                        class: {
                          "fa-check-square": _vm.mapStyle == "streets-v11",
                          "fa-square": _vm.mapStyle != "streets-v11"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$tc("common-street", 2)))
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn w-full !text-xs !p-3 !py-1 !mb-0 !block !rounded-b !rounded-t-none",
                      on: {
                        click: function($event) {
                          return _vm.setStyle("dark-v10")
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "far !text-xs",
                        class: {
                          "fa-check-square": _vm.mapStyle == "dark-v10",
                          "fa-square": _vm.mapStyle != "dark-v10"
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("common-dark")))
                    ]
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.organization != null && _vm.optionLoaded
        ? _c("download", { attrs: { "static-options": _vm.staticOptions } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }