<template>
    
</template>
<script>
import { logout } from '@/lib/user-auth0';
export default {
    name: 'logout',
    created() {
        logout();
    }
}
</script>