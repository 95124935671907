<template>
<div>
    <!-- <div v-show="loading || recordingPlayerLoading" class="overaly" style="opacity: 0.6; position: absolute; width: 100%; height: 100%; z-index: 100; background: white;">
          <div style="text-align: center; margin-top:250px;">
            <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            <div> {{ $t('common-tableTexts.loading') }} </div>
          </div>
    </div> -->
<div id="recording-page" class="vue">
  <div v-if="error!=null">
    {{$t('common-pageError', {error: error})}}
  </div>
  <div v-if="error==null && taskOverview.taskId > 0">
    <div class="container large taskHeader !pb-3">
      <div class="row">
        <div class="col s12 m12 l8 taskTitle">
          <p><a :href="breadcrumbHomeLink()">{{ $t('common-home') }}</a> / <a :href="breadcrumbBaseLink() + 'ARU'">{{ $t('common-aru') }}</a> / <a :href="breadcrumbARUProjectLink()">{{taskOverview.projectFullName}} </a> /</p>
          <span v-html="formatHeading(taskOverview.recordingName)"></span>
          <p class="NoiseLevel" v-if="taskOverview.meanDecibels != null">
            <span>{{ $t('recordingPage-taskDecibels') }}:</span>
            <span>{{ $t('recordingPage-mean') }}</span> ({{taskOverview.meanDecibels}})
            <span>{{ $t('recordingPage-max') }}</span> ({{taskOverview.maxDecibels}})
            <span>{{ $t('recordingPage-rms') }}</span> ({{taskOverview.rmsDecibels}})
          </p>
        </div>
        
      


      </div>
      <weather-segment v-if="recordingInformation" :projectId="taskOverview.project.id" v-bind:date-string="taskOverview.recordingDate" v-bind:location-id="recordingInformation.location.id" v-bind:site-latitude="recordingInformation.location.latitude" v-bind:site-longitude="recordingInformation.location.longitude" v-bind:show-date="false"></weather-segment>
      <div class="flex mt-5 ">
        <div class=" flex-1 flex -ml-2">
          <i  v-if="taskOverview.isReadOnly" class="col !text-6xl s2 icon ion-ios-locked"></i> <span>{{taskOverview.readOnlyReason}}</span>
          <!-- <i v-if="!taskOverview.isReadOnly" class="col s2  !text-6xl icon ion-ios-unlocked" v-tooltip="$t('common-unlocked')"></i> -->
        </div>
        <div class="flex-initial w-1/3">
          <div :class="{'no-statusOption':!isStatusOptionVisible}" class="flex">
            <div class="flex-1 pr-2">
                <select class="" :disabled="!canModifyStatus" id="statusSelector" @change="updateTaskStatus" v-model.number="orgStatusId" :value="orgStatusId" type="number">
                  <option v-for="(option, index) in options.taskStatus" :key="'status'+index" :value="option.id">{{option.type}}</option>
                </select>
            </div>
            <div class="flex-1">
              <span v-if="taskOverview.nextTaskId<=0" v-tooltip="$t('You have no assigned tasks to go to.')">
                <a :disabled="taskOverview.nextTaskId<=0" class="btn btn-border fullWidth">{{ $t('recordingPage-nextTask') }} <span class="ion-ios-arrow-thin-right"></span></a>
              </span>
              <a v-else :href="getNextTaskUrl()" class="btn btn-border fullWidth">{{ $t('recordingPage-nextTask') }} <span class="ion-ios-arrow-thin-right"></span></a>
            </div>
          </div>
          <p></p>
             <span v-if="showIncompleteMessage()" class="text-yellow-600">
            {{$t('recordingPage-incompleteTaskInfo')}}
          </span>
            <span class="invalid_msg"> {{localTaskStatusUpdateMessage}}</span>
        </div>
      </div>

    </div>
    <div class="container overflow tabbed">
      <ul class="tabs !ml-3">
        <li class="tab left !ml-0"><a :class="{'!border-gray-400 border-2 border-b-0': showTab ==='main'}" @click="showTab='main'"><span class="ion-volume-medium"></span> {{ $t('recordingPage-spectrograms') }}</a></li>
        <li class="tab left" v-if="taskOverview.location && taskOverview.location.latitude"><a :class="{'!border-gray-400 border-2 border-b-0': showTab === 'map'}" @click="showTab='map'"><span class="ion-map"></span> {{ $t('common-map') }}</a></li>
        <li class="tab left" v-if="taskOverview.taskId && hasLocationVisitImages"><a :class="{'!border-gray-400 border-2 border-b-0': showTab === 'photos' && hasLocationVisitImages}" @click="showTab = 'photos'"><span class="ion-ios-camera"></span>{{ $t('common-locationPhotos') }}</a></li>
        <li class="tab left" v-if="taskOverview.taskId && taskOverview.isUserAdmin"><a :class="{'!border-gray-400 border-2 border-b-0': showTab === 'audit'}" @click="clickedAuditTab()"><span class="ion-clipboard"></span> {{ $t('recordingPage-audit') }}</a></li>
      </ul>
      <div class="row">
        <div v-show="showTab === 'main'" id="spectrograms" class="col s12 panel boxed padded60 border-gray-400 border-2">
          <div class="spectrogram-top row flex"><!--class="toggleWrap">-->
            <!-- Transcriber/Info panel buttons -->
            <!-- Keyboard shortcuts -->
            <span class="mini-help-bar col s12 m8 flex-initial">
              <span class="currentStatus">{{ $t('recordingPage-method') }}: {{taskOverview && taskOverview.methodStr }}</span>
              <v-popover id="hotkeys" placement='top' trigger='hover' popoverClass="shortcut-popover-base">
              <span class="popover-target-button shortcuts-btn">{{ $t('recordingPage-hotkeys') }}</span>
              <template slot="popover">
              <div class="shortcuts">
                <span>{{ $t('recordingPage-startStop') }} (<span class="white-emphasis">{{ $t('recordingPage-space') }}</span>) </span>
                <span>| {{ $t('recordingPage-skipAhead') }} (<span class="white-emphasis">{{ $t('recordingPage-m') }}</span>) </span>
                <span>| {{ $t('recordingPage-skipBackwards') }} (<span class="white-emphasis">{{ $t('recordingPage-z') }}</span>) </span>
                <span>| {{ $t('recordingPage-addMarker') }} (<span class="white-emphasis">1</span>) </span>
                <span>| {{ $t('recordingPage-previousMarker') }} (<span class="white-emphasis arrow-key ion-arrow-left-b"></span>) </span>
                <span>| {{ $t('recordingPage-nextMarker') }} (<span class="white-emphasis arrow-key ion-arrow-right-b"></span>) </span>
                <span>| {{ $t('recordingPage-firstMarker') }} (<span class="white-emphasis arrow-key ion-arrow-up-b"></span>) </span>
                <span>| {{ $t('recordingPage-lastMarker') }} (<span class="white-emphasis arrow-key ion-arrow-down-b"></span>) </span>
                <span>| {{ $t('recordingPage-hideShowBoxes') }} (<span class="white-emphasis">{{ $t('recordingPage-b') }}</span>) </span>
                <span>| {{ $t('recordingPage-hideShowText') }} (<span class="white-emphasis">{{ $t('recordingPage-t') }}</span>) </span>
                <span>| {{ $t('recordingPage-hideShowLeftRight') }} (<span class="white-emphasis">{{ $t('recordingPage-lr') }}</span>)</span>
              </div> <!-- Hide Left (L) | Hide Right (R) -->
              </template>
              </v-popover>
              </span>

                <span class="top-buttons-wrapper col m3  flex-initial" style="float: none !important">
              <span v-tooltip="$t('Toggle On to see all the tags made for this recording in this project. Toggle Off to only see your tags.')" v-if="(taskOverview.enableShowAllRecordingTasks)" class="text-right text-toggle"> {{ $t('recordingPage-viewAll') }} <ToggleButton :value="taskOverview.isUserTranscriber" color="#C94412" @change="toggleChange()"></ToggleButton></span>
              <!--  
              <span class="btn btn-border ion-edit userMod action" v-if="(taskOverview.isUserAdmin || isFullReadUser) && isAdminView" @click="isAdminView=false">As Transcriber</span>
              <span class="btn btn-border ion-ios-eye-outline userMod action" v-if="(taskOverview.isUserAdmin || isFullReadUser) && !isAdminView" @click="isAdminView=true">View As Admin</span> 
              -->
              <span class="btn btn-noborder ion-eye-disabled text-right" v-if="showForm" @click="showForm=false">{{ $t('recordingPage-hideInfoPanel') }}</span>
              <span v-if="!showForm" class="btn btn-noborder ion-eye text-right" @click="showForm=true">{{ $t('recordingPage-showInfoPanel') }}</span>
            
            </span>

              <span class="col m flex-initial">
                <dropdown-button v-if="$refs.recording">
                <template slot="menuTitle">{{$t('common-manage')}}</template>
                <template slot="dropdownButtons">
                  <a  download class='btn btn-success popover-menu-item' :class="{'downloading': $refs.recording.downloading }" @click="$refs.recording.downloadRecording()">
                    <span style="font-size: 20px;"></span><span class="spin-icon"></span> 
                    <p v-if="$refs.recording.recording.file">{{ $t('recordingPlayers-downloadOriginal') }}</p>
                    <p v-else>{{ $t('recordingPlayers-downloadRecording') }}</p>
                  </a>
                  <a v-if="$refs.recording.recording.file"  download class='btn btn-success popover-menu-item' :class="{'downloading': $refs.recording.downloading }" @click="$refs.recording.downloadGeneratedRecording()">
                    <span style="font-size: 20px;"></span><span class="spin-icon"></span> 
                    {{ $t('recordingPlayers-downloadGenerated') }}
                  </a>
                  <a v-close-popover class="btn btn-success popover-menu-item" @click="()=>{this.$refs['recording'].showParameterModal()}">
                      {{ $t('Audio Settings') }}
                  </a>

                  <!-- <a download class="btn btn-success popover-menu-item" :class="{'downloading': downloadingTags }" @click="downloadTags">
                    <span style="font-size: 20px;"></span><span class="spin-icon"></span>
                    
                  </a> -->
                  <!-- popover + tooltip has been buggy so this v-if part is necessary to avoid bug -->
                  <!-- <span v-if="!canUpload" v-tooltip="{content: tooltips.disabledUpload, show: canUpload, classes: 'front'}">
                    <button :disabled="!hasProjectWriteAccess || isPublished" v-close-popover class="btn btn-success popover-menu-item" @click="uploadSurveysCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-survey', 2)})}}</button>
                  </span>
                  <button v-else v-close-popover class="btn btn-success popover-menu-item" @click="uploadSurveysCSV"> <span style="font-size: 20px;"></span> {{$t('common-uploadItem', {item: $tc('common-survey', 2)})}}</button>
                  <a download class="group-top btn btn-success popover-menu-item" :class="{'downloading': downloadingCodes }" @click="downloadCodes"> <span style="font-size: 20px;"></span><span class="spin-icon"></span> {{$t('common-downloadCodes')}}</a> -->
                </template>
              </dropdown-button>
            </span>
              
            <!-- <div class="col s10 m9 xl1"></div> -->
          </div>
          <div class="row" v-if="taskOverview.taskId != null">
              <recording-play 
                ref="recording" 
                :loading="recordingPlayerLoading" 
                v-bind:task-update-message="taskUpdateMessage"
                v-bind:show-form="showForm" 
                v-bind:modify="canModifyTask" 
                v-bind:task-overview="taskOverview" 
                v-bind:options="options" 
                :options-loaded="optionsLoaded" 
                v-bind:task="task" 
                v-bind:show-all-tasks="showAllTasks">
              </recording-play>
          </div>
          <!-- -END SPECTROGRAM PANEL- -->
        </div>
        <div v-if="showTab === 'map'" id="mapsAndPhotos" class="col s12 panel boxed padded60 border-gray-400 border-2">
          <div class="row">
            <div class="col s12 m12 l8">
              <point-map v-if="taskOverview && taskOverview.location.latitude && taskOverview.location.longitude" v-bind:latitude="parseFloat(taskOverview.location.latitude)" v-bind:longitude="parseFloat(taskOverview.location.longitude)" />
            </div>
            <div class="col s12 m12 l4">
              <div class="row gallery">
                <div v-for="(img, index) in taskOverview.photos" v-bind:key="'photo' + index" :src="img" @click="openZoomImage(img.url)" class="col s6 m4 l6 materialboxed">
                  <div class="thumbnail" :style="{'background-image': 'url(' + img.thumbNailURL + ')'}"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showImage==true" class="modal modal-wrapper" ref="modalBox" @click="hideZoomImage">
            <div class="modal-container" @click="hideZoomImage">
            <!--div @click="showImage=false" class="iconButton"><span class="ion-close"></span> </div-->
              <img ref="zoomedImg" :src="largeImage">
            </div>
          </div>
          <!-- END MAP PANEL -->
        </div>
        <div v-if="showTab === 'audit'" id="auditHistory" class="col s12 panel boxed padded60 border-gray-400 border-2">
          <div class="row">
            <div v-show="loading" style="text-align: center; margin-top:250px;">
              <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
            </div>
            <div class="col s12 m12">
              <div v-if="auditsText != null">{{auditsText}}</div>
              <div class="audit" v-for="(line, index) in audits" v-bind:key="'audit' + index">
                <span class="audit-type">{{line.auditType}}</span>
                <span>{{line.audit}}</span>
              </div>
            </div>
          </div>
        </div>
      <!-- END AUDIT PANEL -->
        <div v-if="showTab === 'photos'" id="location-photos" class="col s12 panel boxed padded60 border-gray-400 border-2">
          <div class="row">
            <div class="col s12 m12 l8">
              <location-photos v-if="taskOverview.location"
              v-bind:location-id="taskOverview.location.id" v-bind:project-id="taskOverview.projectId"/>
            </div>
          </div>
        </div>
        <!-- END LOCATION PHOTOS PANEL -->
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import {getParameterByName, API_URL} from '@/lib/common';
import { eventBus } from '@/lib/eventbus';
import DropdownButtonWithOptionsVue from '../common/DropdownButtonWithOptions.vue';

import RecordingPlayers from '@/components/ARU/RecordingPlayers';
import CommonPageMixin from '@/components/common/CommonPageMixin';
import SinglePointMap from '@/components/mapGL/SinglePointMap';
import { ToggleButton } from 'vue-js-toggle-button';
import VisitLocationPhotosVue from '../common/VisitLocationPhotos.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import WeatherSegment from '@/components/common/WeatherSegment';
import moment from 'moment';

export default {
  name: 'record-page',
  components: { 'recording-play': RecordingPlayers, 'weather-segment': WeatherSegment, 'point-map': SinglePointMap, 'ToggleButton': ToggleButton, 'location-photos': VisitLocationPhotosVue, 'pulse-loader': PulseLoader, 'dropdown-button': DropdownButtonWithOptionsVue },
  mixins: [CommonPageMixin],
  created () { // get task information,
    const taskId = getParameterByName('taskId');
    this.$store.dispatch('Species/getAllSpecies');

    if (!taskId) {
      this.error = this.$t('common-missingTaskInfoError');
      return;
    }
    const that = this;
    eventBus.$on('toggle-form', function (showForm) {
      that.showForm = showForm;
    });
    eventBus.$on('update-task', function () {
      that.taskUpdateMessage = that.updateTask();
    });
    eventBus.$on('update-task-status', function () {
      that.taskUpdateMessage = that.updateTask();
    });
    eventBus.$on('update-task-status-display', function (displayStatusId) {
      that.updateTaskStatusDisplay(displayStatusId);
    });
    eventBus.$on('toggle-modal-form', function (name, show) {
      that.toggleDialog(name, show);
    });
    eventBus.$on('recording-ready', (recordingInfo) => {
      // info needed to load weather
      this.recordingInformation = recordingInfo;
    });
    eventBus.$on('audio-loaded', () => {
      this.recordingPlayerLoading = false;
    });

    const params = { params: { taskId: taskId } };
    this.$http.get(this.getTaskOverviewUrl, params).then(
      (response) => {
        // get body data
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.taskOverview = response.data.taskOverview;
          this.task = response.data.task;
          this.taskOverview.taskId = this.task.id;
          this.orgStatusId = this.task.statusId;
          this.hasLocationVisitImages = response.data.taskOverview.hasLocationVisitImages;
         
          this.isFullReadUser = (!this.taskOverview.isUserTranscriber && !this.isAdminView);
          this.isProjectFinalized = this.taskOverview.isProjectFinalized;
        }
      }, (err) => {
      // error callback
        this.error = err;
      });

    this.$http.get(this.getTaskOptionUrl, params).then((response) => {
    //  this.options = response.data;
      this.$set(this, 'options', response.data);
    }, (err) => {
    // error callback
      this.error = err;
    });
  },
  watch: {
    options (newval, oldval) {
      if (oldval !== newval) {
        this.optionsLoaded = true;
      }
    }
  },
  computed: {
    showToggle () {
      console.log(getParameterByName('showViewAllTasksToggle'));
      return getParameterByName('showViewAllTasksToggle') == 1;
    },
    currentStatus () {
      for (let i = 0; this.options.taskStatus && i < this.options.taskStatus.length; i++) {
        if (this.options.taskStatus[i].id === this.orgStatusId) {
          return this.options.taskStatus[i].type;
        }
      }
      return this.$t('recordingPage-unknown');
    },
    isComplete () {
      return this.options.taskStatus && (this.options.taskStatus.find(status => status.id === this.orgStatusId)||{complete:false}).complete;
    },
    updateable () {
      if (!this.options.taskStatus) { return false; }
      let status = this.options.taskStatus.find(status => status.id === this.orgStatusId);
      return status ? status.updateable : false;
    },
    canModifyTask () {
      return !this.taskOverview.isReadOnly;
    },
    canModifyStatus () {
      return !this.taskOverview.isReadOnly;
    },
    // markableTaskStatus () {
    //   if (this.options.taskStatus) {
    //     return this.options.taskStatus.filter(function (u) {
    //       return u.selectableByTranscriber;
    //     });
    //   }
    //   return null;
    // },
    isStatusOptionVisible () {
    /*  if (this.options.taskStatus) {
        const currentStatus = this.options.taskStatus.find(status => status.id === this.task.statusId);
        if (currentStatus) {
          console.log(currentStatus);
          return currentStatus.updateableByTranscriber;
        }
      }
      return false; */
      if (this.options.taskStatus && (this.taskOverview.isUserAdmin || this.taskOverview.isUserTranscriber)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    formatHeading(str) {
      let chunks = str.split("@");
      if (chunks.length > 1) {
        return `<h1 class="mb-0">${chunks[0]}</h1><h5 class="mt-0">${ moment(chunks[1]).format('YYYY-MM-DD HH:mm:ss')}</h5>`;
      } else {
        return `<h1 class="mb-0">${chunks[0]}</h1>`;
      }
    },  
    showIncompleteMessage () {
      return !this.taskOverview.isReadOnly && !this.isComplete && (this.task.audioQualityId === null || this.task.industrialNoiseId === null || this.task.otherNoiseId === null || this.task.rainId === null || this.task.windId === null);
    },
    toggleDialog (dialogName, toShow) {
      if (toShow) {
        this.$modal.show(dialogName);
      } else {
        this.$modal.hide(dialogName);
      }
    },
    openZoomImage (img) {
      this.largeImage = img;
      this.showImage = true;
      const that = this;
      this.$nextTick(() => function (e) {
        that.$refs.modalBox.clientWidth = that.$refs.zoomedImg.clientWidth;
      });
    },
    hideZoomImage (img) {
      this.showImage = false;
    },
    markAsCompelete () {
      if (confirm(this.$t('recordingPage-updateProjectStatusWarning'))) {
        this.orgStatusId = this.newStatusId;
        this.updateTaskStatus(true);
      } else { // when cancel selection
        this.newStatusId = -1;
      }
    },
    updateTaskStatusDisplay (updatedStatusId) { // update status display after user inserted tags or markers
      if (updatedStatusId !== this.task.statusId) {
        this.task.statusId = updatedStatusId;
        this.orgStatusId = updatedStatusId;
        this.newStatusId = -1; // reset status id
      }
    },
    updateTaskStatus (e) { // just to distinguish the source of update and get error message back
      let ogStatusId = this.task.statusId;
      this.task.statusId = e.target.value;
      this.$http.put(this.updateTaskStatusUrl, this.task).then(function(response) {
        if (response.body) {
          if (response.body.error) {
            alert(response.body.error);
            this.orgStatusId = ogStatusId;
          } else {
            this.orgStatusId = response.body.statusId;
          }
        }
      });
    },
    /* udpate task form, put here so, it can be called form parent component. */
    updateTask (statusOnly) {
      let dbStatusId = this.task.statusId;
      this.task.statusId = this.orgStatusId;
      let message = '';
      this.taskUpdateMessage = '';
      this.localTaskStatusUpdateMessage = '';
      this.$http.put(this.updateTaskUrl, this.task).then(function (response) {
        if (response.data.hasOwnProperty('error')) {
          this.task.statusId = dbStatusId;
          this.orgStatusId = dbStatusId;
          if (response.data.error.indexOf('MARKERS EXIST') !== -1) {
            alert(this.markExistMsg);
            return;
          }
          this.newStatusId = -1; // reset status id
          message = this.$t('recordingPage-updateFailed') + ': ' + response.data.error;
        } else {
          this.task = response.body;
          message = this.$t('recordingPage-taskInfoSuccessfullyUpdated');
        }
        if (statusOnly) {
          this.localTaskStatusUpdateMessage = message;
        } else {
          this.taskUpdateMessage = message;
        }
      });
    },
    clickedAuditTab () {
      this.showTab = 'audit';
      this.loading = true;
      this.auditsText = null;
      const params = { params: { taskId: this.task.id } };
      if (this.taskOverview.isUserAdmin) {
        // audit history for admins only
        this.$http.get(this.getTaskAuditHistory, params).then((response) => {
          if (response.body.hasOwnProperty('error')) {
            this.auditsText = this.$t('recordingPage-errorGettingAuditInfo') + ' ' + response.body.error;
          } else {
            this.audits = response.data.map(x => {
              var splitAudit = {auditType: x.substring(0, x.indexOf(' ')), audit: x.substring(x.indexOf(' ') + 1)};
              return splitAudit;
            });
            this.loading = false;
            if (this.audits.length === 0) {
              this.auditsText = this.$t('recordingPage-auditNoDeletedTagsFound');
            }
          }
        }, (err) => {
          // error callback
          this.error = err;
        });
      }
    },
    toggleChange () {
      this.showAllTasks = !this.showAllTasks;
      document.activeElement.blur();
    }
  },
  data () {
    return {
      optionsLoaded: false,
      getTaskOverviewUrl: API_URL + 'get-task-overview?',
      getTaskOptionUrl: API_URL + 'aru-task-options',
      updateTaskUrl: API_URL + 'update-task',
      updateTaskStatusUrl: API_URL + 'update-task-status',
      getTaskAuditHistory: API_URL + 'get-task-audit-history',
      showAllTasks: false,
      markExistMsg: this.$t('recordingPage-yellowMarkersExistMsg'),
      auditsText: null,
      showForm: false,
      showTab: 'main',
      taskOverview: {},
      orgStatusId: null,
      newStatusId: -1, // for transcriber purpose
      statusOption: {},
      options: [],
      audits: [],
      loading: false,
      recordingPlayerLoading: true,
      task: {},
      error: null,
      isAdminView: null,
      isFullReadUser: null,
      hasLocationVisitImages: null,
      taskUpdateMessage: '',
      localTaskStatusUpdateMessage: '',
      largeImage: '',
      showImage: false,
      recordingInformation: null
    }
  }
}
</script>
<style lang='scss'>

#recording-page {
  & .ion-ios-unlocked, .ion-ios-locked {
    font-size: 21px;
  }
  & .flex {
    display:flex;
    align-items: center;
    & i {
      margin-right: 10px;
    }
  }
}

#recording-page .toggleWrap #toggleInfo .ion-eye-disabled {
    display: block;
}
#recording-page .currentStatus{
  color: #1369c5;
  font-weight: 800;
}
#recording-page .userMod {
  color:#c94412;
}
/*
.spectrogram-top{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}*/

/*
.padding-right {
  padding-right: 30px;
  float:right;
}
.padding-right .action {
  cursor:pointer;
}*/
#recording-page .row .col .thumbnail {
  min-height: 200px;
  background-position: cover;
  background-size: 100%;
  background-repeat: no-repeat;
}
#recording-page #mapsAndPhotos .iconButton {
  float: right;
  width: 50px;
}

#recording-page #mapsAndPhotos .modal-wrapper {
  display: flex;
  align-items:center;
  justify-content:center;
  height:100%;
  z-index: 1003;
  top: 10%;
}

#recording-page #mapsAndPhotos .modal-container{
    display:inline-block;
    width:auto;
}

#recording-page .audit{
  margin-bottom: 10px;
}

  #recording-page .audit-type{
    font-weight: 800;
  }

@media only screen and (max-width: 992px) {
  #recording-page .row.gallery {
    margin-top:30px;
  }
  #recording-page .row .col .thumbnail {
    min-height: 150px;
  }
}

#recording-page .tabbed .tab {
  cursor: pointer;
}
#recording-page .jwplayer {
  display:none;
}
#recording-page .NoiseLevel span {
  color: #1369c5;
  font-weight: 800;
}

#recording-page .btn.btn-noborder {
  background-color: transparent;
  color: #646464;
  border: 0px;
  margin: 0
}

#recording-page .mini-help-bar{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 0.8rem;
    color: #9E9E9E;
}

#recording-page .red-link{
    display: inline;
    color: #C94412;
    padding-right: 12px;
    cursor: pointer;
}

#recording-page p .red-link{
  font-weight:normal;
  padding-left:5px;
}

#recording-page .white-emphasis{
  color: #FFFFFF;
  font-weight:800;
}

#recording-page .top-buttons-wrapper{
  float:right !important;
  display: flex;
  flex-direction: column;
}

#recording-page .shortcuts > span{
  white-space: nowrap;
}

#recording-page .text-right{
    text-align: right;
}

#recording-page .text-toggle{
  font-size:14px;
  color: #646464;
  font-weight: 600;
}

#recording-page .no-statusOption{
  margin-top: 3em;
}

#recording-page .recording-status-wrapper{
  margin-block-start: 1em;
  margin-block-end: 1em;
}

#recording-page .no-float{
  float: none !important;
}

.shortcut-popover-base.tooltip {
  // ...
  max-width: 70% !important;

@media only screen and (max-width: 1150px) {
  max-width: 80% !important;
}

@media only screen and (max-width: 1000px) {
  max-width: 100% !important;
}

  &.popover {
    $color: #C94412;

    .popover-inner {
      background: $color !important;
      color: white !important;
      padding: 10px !important;
      border-radius: 2px !important;
      box-shadow: 0 5px 30px rgba(black, .1) !important;
    }

    .wrapper{
      padding-top: 0px;
    //  transform-origin: top left;
    //  width: 825px;
    }

  /*  .popover-arrow {
      border-color: $color !important;
    }*/

    .popover-menu-item{
      width: 100%;
      display: block;
      background-color: transparent !important;
      border:none;
    }
  }
}

#recording-page .shortcuts-btn.popover-target-button{
  color: #C94412 !important;
  text-decoration: underline;
  cursor: default;
}

#recording-page #hotkeys {
  display: inline !important;
}

</style>
