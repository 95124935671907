var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "locationModal" } }, [
    _vm.location != null
      ? _c("div", [
          _c("ul", { staticClass: "tabs drag-handle" }, [
            _c(
              "li",
              {
                staticClass: "tab",
                on: {
                  click: function($event) {
                    _vm.showTab = "main"
                  }
                }
              },
              [
                _c(
                  "a",
                  { class: { active: _vm.showTab == "main" } },
                  [
                    _vm.locationId == null
                      ? [_vm._v(_vm._s(_vm.$t("locationForm-create")))]
                      : [_vm._v(_vm._s(_vm.$t("locationForm-update")))],
                    _vm._v(" "),
                    _vm.hasFormModified
                      ? [_vm._v("(* " + _vm._s(_vm.$t("common-unsaved")) + ")")]
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _vm.location &&
            _vm.location.id != null &&
            _vm.location.latitude &&
            _vm.location.longitude &&
            !isNaN(_vm.location.latitude) &&
            !isNaN(_vm.location.longitude)
              ? _c(
                  "li",
                  {
                    staticClass: "tab",
                    on: {
                      click: function($event) {
                        _vm.showTab = "map"
                      }
                    }
                  },
                  [
                    _c("a", { class: { active: _vm.showTab == "map" } }, [
                      _vm._v(_vm._s(_vm.$t("common-map")))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "iconButton link", on: { click: _vm.close } },
              [_c("span", { staticClass: "ion-close" })]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTab == "main",
                  expression: "showTab =='main'"
                }
              ],
              staticClass:
                "modal-content padded40 main border-t border-gray-300 !pt-10"
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12 m4 l4" }, [
                  _c("label", { attrs: { for: "form_location_name" } }, [
                    _vm._v(_vm._s(_vm.$t("locationForm-fields.name")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.location.name,
                        expression: "location.name"
                      }
                    ],
                    staticClass: "validate",
                    attrs: {
                      id: "form_location_name",
                      disabled: !_vm.isEditable,
                      type: "text",
                      required: "",
                      placeholder: _vm.$t("locationForm-fields.name")
                    },
                    domProps: { value: _vm.location.name },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.location, "name", $event.target.value)
                        },
                        function($event) {
                          _vm.hasFormModified = true
                        }
                      ]
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col s12 m4 l4" }, [
                  _c("label", { attrs: { for: "formshortCode" } }, [
                    _vm._v(_vm._s(_vm.$t("common-latitude"))),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.latitude,
                          expression: "tooltips.latitude"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.location.latitude,
                        expression: "location.latitude"
                      }
                    ],
                    staticClass: "validate",
                    attrs: {
                      id: "formshortCode",
                      disabled: !_vm.isEditable,
                      type: "text",
                      placeholder: _vm.$t("locationForm-fields.latitudeDegrees")
                    },
                    domProps: { value: _vm.location.latitude },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.location,
                            "latitude",
                            $event.target.value
                          )
                        },
                        function($event) {
                          _vm.hasFormModified = true
                        }
                      ]
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col s12 m4 l4" }, [
                  _c("label", { attrs: { for: "formshortCode" } }, [
                    _vm._v(_vm._s(_vm.$t("common-longitude"))),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.longitude,
                          expression: "tooltips.longitude"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.location.longitude,
                        expression: "location.longitude"
                      }
                    ],
                    staticClass: "validate",
                    attrs: {
                      id: "formshortCode",
                      disabled: !_vm.isEditable,
                      type: "text",
                      placeholder: _vm.$t(
                        "locationForm-fields.longitudeDegrees"
                      )
                    },
                    domProps: { value: _vm.location.longitude },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.location,
                            "longitude",
                            $event.target.value
                          )
                        },
                        function($event) {
                          _vm.hasFormModified = true
                        }
                      ]
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12 m6 l6" }, [
                  _c("label", { attrs: { for: "formElevation" } }, [
                    _vm._v(_vm._s(_vm.$t("locationForm-fields.elevation")))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.location.elevationMeters,
                        expression: "location.elevationMeters"
                      }
                    ],
                    staticClass: "validate",
                    attrs: {
                      id: "formElevation",
                      disabled: !_vm.isEditable,
                      type: "text"
                    },
                    domProps: { value: _vm.location.elevationMeters },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.location,
                            "elevationMeters",
                            $event.target.value
                          )
                        },
                        function($event) {
                          _vm.hasFormModified = true
                        }
                      ]
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col s12 m6 l6" }, [
                  _c("label", { attrs: { for: "selectVisibility" } }, [
                    _vm._v(_vm._s(_vm.$t("locationForm-fields.visibility")))
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.tooltips.visibility,
                        expression: "tooltips.visibility"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "vue-select-wrapper" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.location.visibilityId,
                            expression: "location.visibilityId"
                          }
                        ],
                        attrs: {
                          id: "selectVisibility",
                          disabled: !_vm.isEditable
                        },
                        on: {
                          input: function($event) {
                            _vm.hasFormModified = true
                          },
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.location,
                              "visibilityId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.options.visibility, function(option, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: option.id } },
                          [_vm._v(_vm._s(option.type))]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12 m6 l4" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("locationForm-fields.locationBuffering")) +
                        " "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.locationBuffer,
                          expression: "tooltips.locationBuffer"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-option" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.location.isTrueCoordinates,
                          expression: "location.isTrueCoordinates"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        id: "trueLocation",
                        name: "bufferLocation",
                        disabled: !_vm.isEditable
                      },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.location.isTrueCoordinates, true)
                      },
                      on: {
                        input: function($event) {
                          _vm.hasFormModified = true
                        },
                        change: function($event) {
                          return _vm.$set(
                            _vm.location,
                            "isTrueCoordinates",
                            true
                          )
                        }
                      }
                    }),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("locationForm-fields.trueLocation")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-option" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.location.isTrueCoordinates,
                          expression: "location.isTrueCoordinates"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        id: "bufferedLocation",
                        name: "bufferLocation",
                        disabled: !_vm.isEditable
                      },
                      domProps: {
                        value: false,
                        checked: _vm._q(_vm.location.isTrueCoordinates, false)
                      },
                      on: {
                        input: function($event) {
                          _vm.hasFormModified = true
                        },
                        change: function($event) {
                          return _vm.$set(
                            _vm.location,
                            "isTrueCoordinates",
                            false
                          )
                        }
                      }
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("locationForm-fields.bufferedLocation"))
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col s12 m6 l8" }, [
                  _c(
                    "label",
                    [
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              _vm.location.isTrueCoordinates
                                ? "locationForm-fields.defaultBufferRadius"
                                : "locationForm-fields.amountBufferYouAdded"
                            )
                          )
                        )
                      ],
                      _vm._v(" "),
                      _c("span", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.tooltips.userBuffer,
                            expression: "tooltips.userBuffer"
                          }
                        ],
                        staticClass: "info-icon ion-information-circled"
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "vue-select-wrapper" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.location.bufferRadius,
                          expression: "location.bufferRadius"
                        }
                      ],
                      staticClass: "validate",
                      attrs: {
                        disabled: !_vm.isEditable,
                        id: "formshortCode",
                        type: "text"
                      },
                      domProps: { value: _vm.location.bufferRadius },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.location,
                              "bufferRadius",
                              $event.target.value
                            )
                          },
                          function($event) {
                            _vm.hasFormModified = true
                          }
                        ]
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12" }, [
                  _c("label", { attrs: { for: "form_location_description" } }, [
                    _vm._v(_vm._s(_vm.$t("common-description")))
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.location.comments,
                        expression: "location.comments"
                      }
                    ],
                    staticClass: "materialize-textarea",
                    attrs: {
                      disabled: !_vm.isEditable,
                      id: "form_location_description",
                      "data-length": "120"
                    },
                    domProps: { value: _vm.location.comments },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.location,
                            "comments",
                            $event.target.value
                          )
                        },
                        function($event) {
                          _vm.hasFormModified = true
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "character-counter",
                    staticStyle: {
                      float: "right",
                      "font-size": "12px",
                      height: "1px"
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col s12" }, [
                  _c("label", { attrs: { for: "form_location_report_link" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("locationForm-fields.reportLink")) +
                        "\n          "
                    ),
                    _c("span", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.tooltips.reportLink,
                          expression: "tooltips.reportLink"
                        }
                      ],
                      staticClass: "info-icon ion-information-circled"
                    })
                  ]),
                  _vm._v(" "),
                  _vm.locationReportLink
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.locationReportLink,
                            expression: "locationReportLink"
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: _vm.tooltips.copyTooltip,
                              show: _vm.showLinkCopied
                            },
                            expression:
                              "{content: tooltips.copyTooltip, show: showLinkCopied}"
                          }
                        ],
                        attrs: {
                          id: "form_location_report_link",
                          type: "text"
                        },
                        domProps: { value: _vm.locationReportLink },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.locationReportLink = $event.target.value
                          }
                        }
                      })
                    : _c("div", { staticClass: "row col s12" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("locationForm-fields.reportLinksDisabled")
                          )
                        )
                      ])
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.errors, function(error, index) {
                return _c("div", { key: index, staticClass: "error" }, [
                  _vm._v(" " + _vm._s(error) + " ")
                ])
              }),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _vm.isEditable
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-success modal-trigger modal-action modal-close",
                        attrs: { id: "form_locationSave" },
                        on: { click: _vm.saveLocation }
                      },
                      [[_vm._v(_vm._s(_vm.$t("common-save")))]],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-border modal-action modal-close",
                    attrs: { id: "formcancelAdd" },
                    on: { click: _vm.close }
                  },
                  [_vm._v(_vm._s(_vm.$t("common-close")))]
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTab == "map",
                  expression: "showTab =='map'"
                }
              ],
              staticClass:
                "modal-content padded60 border-t border-gray-300 !pt-10"
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.location &&
                  _vm.location.id &&
                  _vm.location.latitude &&
                  _vm.location.longitude &&
                  !isNaN(_vm.location.latitude) &&
                  !isNaN(_vm.location.longitude)
                    ? _c("point-map", {
                        attrs: {
                          "is-show": _vm.showTab == "map",
                          latitude: parseFloat(_vm.location.latitude),
                          longitude: parseFloat(_vm.location.longitude)
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }