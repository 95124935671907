<template>
        <modal :name="formName"
            :scrollable="true"   :adaptive="true"
            transition="nice-modal-fade"
            classes="modal-form"
            draggable=".drag-handle"
            :delay="100"
            :width = "1000"
            height = "80%"
            :minWidth="200"
            :minHeight="200"
            :clickToClose = "false">
            <div @click="closeForm" class="iconButton link">
                <span class="ion-close"></span>
            </div>
            <div class="modal-header drag-handle">
                <h6>{{$t('common-help')}}</h6>
            </div>
            <div class="modal-scroller">
                <div class="modal-content">
                    <ul class="vue collapsible getHelp">
                      <!-- <li class="">
                          <div class="collapsible-header" @click="showView=!showView"><h5>Tagging Tabs<span
                          :class="{'ion-arrow-down-b': showView,'ion-arrow-up-b': !showView}"></span></h5></div>
                          <div class="collapsible-body" v-if="showView">
                              <div class="row px-5">
                                  <div class="col s12 m12">
                                      <h6>Full Tagging View</h6>
                                      <p>This view contains all images (pre-filtered to exclude time-lapsed and auto-tagged images) ordered in chronological order.</p>
                                      <h6>Series View</h6>
                                      <p>This view contains images where time between consecutive motion-triggered images is less than what is predefined in the project settings. Each page contains all images within a single series. Images are order chronologically.
                                      </p>
                                      <h6>Field of View</h6>
                                      <p>This view will appear if an "out of range” tag was applied during the tagging process. Images are ordered in reverse chronological order on this page. Locate where the out of range tag was applied by looking for the yellow triangle in the page numbers. Navigate to that page, and apply an End Tag to the last good image, which will occur to the right on the out of range image. Once complete, click Verify Field of View.</p>
                                  </div>
                              </div>
                          </div>
                        </li> -->
                        <li class="">
                            <div class="collapsible-header" @click="showHelp=!showHelp">  <h5>{{$t('common-keyboardShortcuts')}} <span
                            :class="{'ion-arrow-down-b': showHelp,'ion-arrow-up-b': !showHelp}"></span></h5></div>
                            <div class="collapsible-body" v-if="showHelp">
                                <div class="row p-5">
                                    <div class="col s12 m12">

                                        <div class="col s12 m6">
                                            <h6>{{$t('taggingHelp-onTaggingPage')}}</h6>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('common-left')}}</div>
                                                <p>{{$t('taggingHelp-leftDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon"> {{$t('common-right')}}</div>
                                                <p>{{$t('taggingHelp-rightDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlA')}}</div>
                                                <p>{{$t('taggingHelp-ctrlADesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlD')}}</div>
                                                <p>{{$t('taggingHelp-ctrlDDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlQ')}}</div>
                                                <p>{{$t('taggingHelp-ctrlQDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-escape')}}</div>
                                                <p>{{$t('taggingHelp-escapeAltDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-space')}}</div>
                                                <p>{{$t('taggingHelp-spaceDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon double-line">{{$t('taggingHelp-shiftSpace')}}</div>
                                                <p>{{$t('taggingHelp-shiftSpacecDesc')}}</p>
                                            </div>

                                        </div>

                                        <div class="col s12 m6">
                                            <div class="keyIcon">
                                                <div class="icon double-line">{{$t('taggingHelp-shiftCtrl')}}</div>
                                                <p>{{$t('taggingHelp-shiftCtrlDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlE')}}</div>
                                                <p>{{$t('taggingHelp-ctrlEAltDesc')}}</p>
                                            </div>
                                            <div class="keyIcon">
                                                <div class="icon">{{$t('taggingHelp-ctrlS')}}</div>
                                                <p>{{$t('taggingHelp-ctrlSAltDesc')}}</p>
                                            </div>
                                             <div class="keyIcon">
                                                <div class="icon double-line">{{$t('Ctrl Shift 1-5')}}</div>
                                                <p>{{$t('Rate selected tags 1-5')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- legend -->
                        <li class="">
                            <div class="collapsible-header" @click="showMouse=!showMouse">
                                <h5>{{$t('taggingHelp-tagSelection')}}<span :class="{'ion-arrow-down-b': showMouse,'ion-arrow-up-b': !showMouse}"></span></h5>
                            </div>
                            <div class="collapsible-body" v-show="showMouse">
                                <div class="row px-5">
                                    <div class="col s12 m12">
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{ $t('taggingHelp-mouseDrag') }}</span></div>
                                            <p>{{ $t('taggingHelp-mouseDragDesc') }}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{ $t('taggingHelp-ctrlMouseDrag') }}</span></div>
                                            <p>{{ $t('taggingHelp-ctrlMouseDragDesc') }}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{ $t('taggingHelp-singleLeftClick') }}</span></div>
                                            <p>{{ $t('taggingHelp-singleLeftClickDesc') }}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{ $t('taggingHelp-ctrlSingleLeftClick') }}</span></div>
                                            <p>{{ $t('taggingHelp-ctrlSingleLeftClickDesc') }}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{ $t('taggingHelp-rightClick') }}</span></div>
                                            <p>{{ $t('taggingHelp-rightClickDesc') }}</p>
                                        </div>
                                        <div class="keyIcon">
                                            <div class="big icon"><span>{{ $t('taggingHelp-shiftSingleClick') }}</span></div>
                                            <p>{{ $t('taggingHelp-shiftSingleClickDesc') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="collapsible-header" @click="showLegend=!showLegend">  <h5>{{ $t('common-legend') }}<span :class="{'ion-arrow-down-b': showLegend,'ion-arrow-up-b': !showLegend}"></span></h5></div>
                            <div class="collapsible-body" v-show="showLegend">
                                <div class="row px-5">
                                    <div class="col s12 m12">
                                        <div class="">
                                            <div class="flex w-full p-3"><i class="far fa-check-circle flex-initial mr-3"></i><div class="flex-initial grow">{{ $t('taggingHelp-unverified') }}</div></div>
                                            <div class="flex w-full p-3"><i class="fas fa-check-circle flex-initial mr-3 text-green-500"></i><div class="flex-initial grow">{{ $t('taggingHelp-verified') }}</div></div>
                                            <div class="flex w-full p-3"><i class="far fa-star flex-initial mr-3"></i><div class="flex-initial grow">{{ $t('taggingHelp-tagNotMarkedNice') }}</div></div>
                                            <div class="flex w-full p-3"><i class="fas fa-star text-yellow-500 mr-3 flex-initial"></i><div class="flex-initial grow">{{ $t('taggingHelp-niceTag') }}</div></div>
                                            <div class="flex w-full p-3"><i class="fas fa-tasks mr-3 flex-initial"></i><div class="flex-initial grow">{{ $t('taggingHelp-goToOriginalTask') }}</div></div>
                                            <div class="flex w-full p-3"><span class="font-bold mr-3 flex-initial">#</span> <div class="flex-initial grow">{{ $t('recordingForms-abundance') }}</div></div>
                                            <div class="flex w-full p-3"><i class="fas fa-infinity mr-3 flex-initial"></i><div class="flex-initial grow">{{ $t('taggingHelp-abundanceTooMany') }}</div></div>
                                            <div class="flex w-full p-3"><i class="far fa-window-maximize mr-3 flex-initial"></i><div class="flex-initial grow">{{ $t('taggingHelp-openDetailedWindow') }}</div></div>
                                            <div class="flex w-full p-3"><i class="fas fa-play mr-3 flex-initial"></i><div class="flex-initial grow">{{ $t('taggingHelp-playSoundClip') }}</div></div>
                                            <div class="flex w-full p-3"><span class="font-bold mr-3 flex-initial">{{ $t('taggingHelp-blackLines') }}</span> <div class="flex-initial grow">{{ $t('taggingHelp-tagDimensionsBuffered') }}</div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </modal>
</template>

<script>
import { eventBus } from '@/lib/eventbus';
export default {
  name: 'tagging-help',
  props: ['formName'],
  methods: {
    closeForm () {
      eventBus.$emit('toggle-modal-form', this.formName, false);
    }
  },
  data () {
    return {
      showHelp: false,
      showLegend: false,
      showIcon: false,
      showMouse: false,
      showView: false
    }
  }
}
</script>
<style scoped>
.keyIcon .icon {
    border: 2px solid #d9d9d9;
    padding: 12px 20px 12px 20px;
    height: 60px;
    width: 100px;
    margin-bottom: 20px;
    margin-right: 10px;
}
.keyIcon .icon.big {
  width: 200px;
}
.keyIcon .icon.double-line {
    line-height: 20px;
}
.keyIcon {
  width: 100%;
  display: flex;
}
.legend-block {
    width: 45px;
    height: 45px;
    padding: 13px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    line-height: 1.2em;
    margin-right: 10px;
    position: relative;
}

.legend-untagged {
    background-color: #fff;
    border:#ccc 1px solid;
    color: #ccc;
}
.legend-tagged {
    background-color: #7DA484;
}
.legend-selected {
    color:#ccc;
    border: #00ffff63 solid 3px
}

.legend-block span {
    z-index: 10;
}
.legend-needReview {
    border: #FF9800 4px solid;
}
.icon-block {
    width: 65px;
    height: 45px;
    padding: 13px;
    color: #C94412;
    text-align: center;
    vertical-align: middle;
    line-height: 1.2em;
    margin-right: 10px;
    position: relative;
}
.keyIcon .icon-block .ion{
    font-size: 30px;
}
.getHelp h5 {
    font-size: 1.2rem
}
</style>
