<template>

<div class="deployment-table">
  
   <div  v-show="loading" class="overaly" style="opacity: 0.6; width: 100%; height: 100%; z-index: 100; background: white;">
      <div style="text-align: center;" class="my-20">
        <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
        <div> {{ $t('common-loading') }} </div>
    </div>
  </div>

  <div v-show="!loading">
  <div class="row col s12 m9 l8 xl6 left" v-if="isManager && !(statusId === 3 || statusId === 1 || statusId === 4)">
      <multiselect :selectAllLabel="$t('common-selectAll')" v-model="randomAssignUsers" :options="userList" :multiple="true" :close-on-select="false"
        :placeholder="$t('taskTable-selectValidatorsToAssign')" label="text" track-by="id" selectLabel="" deselectLabel=""
        :showSelectAll="true" class="tagger-select">
          <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__single" v-if="values.length && values.length > 1 && !isOpen">{{$t('taskTable-validatorsSelected', {num: values.length})}}</span>
              <span class="multiselect__single" v-if="values.length && values.length == 1 && !isOpen">{{$t('taskTable-singleValidator')}}</span>
          </template>
      </multiselect>
      <a class='btn btnimportant btn-success right assign-btn' :class="{'downloading':randomAssignLoading}" @click="randomAssign"><span class="spin-icon"></span>{{$t('taskTable-randomAssign')}}</a>
  </div>
  <div class="error w-full">{{message}}</div>
 
  <v-server-table  ref="table" :url="url" :columns="columns" :options="tableOptions" class="table w-full vue-bordered speciesTable"
    @loading="loading=true" @loaded="loading=false" @row-click="onRowClick">
    <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
    <template slot="h__pendingNightlyBatchCount">
       {{$t('taskTable-SPPheadings.pendingNightlyBatchCount')}}
       <span v-tooltip="$t('taskTable-SPPheadings.pendingNightlyBatchCount-tooltip')"> <i class="fas fa-info-circle text-yellow-400"></i></span>
    </template>
    <template slot="speciesCode" slot-scope="props">
      <a :href="recordingRowUrl(props.row)">
        <span class="indicator" v-if="species" :class="getRowClass(props.row.status)"></span>{{species.length>0 && species.find(item=> {return item.id == props.row.speciesId; }).code}} </a>
    </template>
    <template slot="vocalization" slot-scope="props">
      {{taskOptions && taskOptions.vocalization && taskOptions.vocalization.find(e => e.id == props.row.vocalizationId) && taskOptions.vocalization.find(e => e.id == props.row.vocalizationId).type}}
    </template>
    <template slot="verifiedCount" slot-scope="props">
      {{props.row.verifiedCount}}
    </template>
    <template slot="totalCount" slot-scope="props">
         {{props.row.totalCount}}
    </template>
    <template slot="assignedUserId" slot-scope="props">
        <select v-model="props.row.assignedUserId" @focus="clearMessage" @change="updateUser(props.row.id, props.row.assignedUserId, $event)" v-if="isManager">
          <option value="-1"> -- {{$t('taskTable-notAssigned')}} -- </option>
          <option v-for="(user, index) in userList" v-bind:key="'user' + index" :value="user.id"> {{user.text}}</option>
        </select>
        <span v-else>
          {{getUserName(props.row.assignedUserId)}}
        </span>
    </template>

  </v-server-table>
</div></div>
</template>

<script>
/* Species tab for Aru project
*/

import {API_URL, ARU_VERIFY_PAGE_URL, alertDialog} from '@/lib/common';
import { createLink } from '@/lib/language.js';
import { eventBus } from '@/lib/eventbus';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'aru-species-table',
  props: ['projectId', 'filterOptions', 'taskOptions', 'userList', 'isManager', 'statusId'],
  components: {'multiselect': Multiselect, 'pulse-loader': PulseLoader},
  methods: {
    getTaggedNumber (row) {
      if (row.totalCount === 0) {
        return this.$t('taskTable-notAssigned');
      }
      return ` ${row.verifiedCount} / ${row.totalCount}`
    },
    getUserName (userId) {
      const tagger = this.userList.find(x => x.id === userId);
      return (tagger ? tagger.text : '');
    },
    recordingRowUrl: function (row) {
      return createLink(ARU_VERIFY_PAGE_URL + this.projectId + '&speciesId=' + row.speciesId + '&vocalizationId=' + row.vocalizationId);
    },
    onRowClick (event) {
      this.tableOptions.activeRowId = event.row.id;
    },
    getRowClass (statusId) {
      if (!this.filterOptions) { return; }

      let text = this.filterOptions.taskStatus.find(status => status.id == statusId).text;
      try {
        return text ? text.replace(/\s/g, '-') : '';
      } catch (e) {}
      return '';
    },
    getStatus (statusObj) {
      try {
        return statusObj.text;
      } catch (e) {}
      return '';
    },
    clearMessage () {
      this.message = '';
    },
    updateUser (id, value, $event) {
      this.message = '';
      const params = {id: id, newUserId: (value === 'null' ? -1 : value)}; // deploymentId, and user Id
      this.$http.post(this.updateUserUrl, params).then((response) => {
        if (response.error) {
          this.message = this.$tc('common-error', 1) + ':' + response.error;
        } else {
          this.message = response.body.message;
        }
      }, (err) => {
        // error callback
        this.message = this.$t('taskTable-messages.updateUserError') + ' ' + err.message;
      });
    },
 
    randomAssignDone (msg) {
      this.randomAssignLoading = false;
      this.message = msg;
    },
    randomAssign () {
      if (!this.randomAssignUsers || this.randomAssignUsers.length === 0) {
        alertDialog(this.$modal, this.$t('taskTable-validatorEmpty'), this.$t('taskTable-mustassign2'));
        return;
      }
      this.randomAssignLoading = true;
      const params = {
        projectId: this.projectId,
        userIds: this.randomAssignUsers.map(x => x.id)
      }

      this.$http.post(this.randomSpeicesAssignUserUrl, params).then(
        (response) => {
          this.randomAssignLoading = false;
          if (response.data.hasOwnProperty('error')) {
            this.randomAssignDone(response.data.error);
          } else {
            this.randomAssignDone(this.$t('taskTable-userAssignmentDone'));
            eventBus.$emit('reload-task-table-option');
            if (this.$refs.table) {
              this.$refs.table.refresh();
            }
          }
        },
        (error) => this.randomAssignDone(error)
      ).catch((error) => this.randomAssignDone(error));
    }
  },
  created () {
    const self = this;
    eventBus.$on('reload-task-table', function (show) {
      if (self.$refs.table) {
        self.$refs.table.refresh();
      }
    });

  },
  computed:{
    species(){
      //console.log(this.$store.state.Species.species.find(a=>1==1));
      return this.$store.state.Species.species || [];
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.url = API_URL + 'get-aru-project-species?projectId=' + this.projectId;
      }
    }
  },
  data () {
    const pageSize = 25;
    return {
      url: API_URL + 'get-aru-project-species?projectId=' + this.projectId,
      updateUserUrl: API_URL + 'update-aru-project-species-user',
      message: null,
      columns: ['speciesCode', 'vocalization', 'verifiedCount', 'totalCount', 'pendingNightlyBatchCount', 'assignedUserId'],
      loading: true,
      randomAssignLoading: false,
      randomSpeicesAssignUserUrl: API_URL + 'aru-randomly-assign-users-not-assigned-species',
      randomAssignUsers: [],
      tableOptions: {
        activeRowId: null,
        highlightMatches: true,
        childRowTogglerFirst: false,
        filterByColumn: true,
        filterable: ['speciesCode', 'vocalization', 'verifiedCount', 'totalCount', 'assignedUserId'],
        listColumns: {
          'assignedUserId': this.userList
        },
        sortable: ['speciesCode', 'vocalization', 'verifiedCount', 'pendingNightlyBatchCount', 'totalCount'],
        headings: {
          speciesCode: this.$t('taskTable-SPPheadings.speciesCode'),
          vocalization: this.$t('taskTable-SPPheadings.vocalization'),
          verifiedCount: this.$t('taskTable-SPPheadings.verified'),
          totalCount: this.$t('taskTable-SPPheadings.total'),
          status: this.$t('common-complete'),
          assignedUserId: this.$t('taskTable-SPPheadings.validator')
        },
        columnsClasses: {
          status: 'status',
          assignedUserId: 'tagger'
        },
        perPage: 25,
        perPageValues: [15, 25, 50, 75, 100],
        pagination: { nav: 'fixed', edge: true }, /* nav: scroll, fixed has bug on navigation */
        customFilters: [{
          name: 'status',
          callback: function (row, query) {
            return query.code.includes(row.code);
          }
        }],
        texts: {
          count: this.$t('common-tableTexts.count'),
          first: this.$t('common-tableTexts.first'),
          last: this.$t('common-tableTexts.last'),
          // filter: 'Filter:',
          // filterPlaceholder: 'Search query',
          limit: this.$t('common-tableTexts.limit'),
          page: this.$t('common-tableTexts.page'),
          noResults: this.$t('common-tableTexts.noResults'),
          filterBy: this.$t('common-tableTexts.filterBy'),
          loading: this.$t('common-tableTexts.loading'),
          defaultOption: this.$t('common-tableTexts.defaultOption'),
          columns: this.$t('common-tableTexts.columns')
        },
        orderBy: {column: 'speciesCode', ascending: true},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results,
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.speciesTable select {
  margin-bottom: 0px;
}

.speciesTable tr a {
    position: relative;
}

.speciesTable tr a {
padding-left: 0px;
}

.deployment-table .multiselect {
width: 50%;
float: left;
}

.deployment-table .assign-btn {
float: right;
display: block;
width: 45%;
}

</style>
