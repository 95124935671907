var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "accessRequestModal" } }, [
    _c(
      "div",
      { staticClass: "iconButton link", on: { click: _vm.closeForm } },
      [_c("span", { staticClass: "ion-close" })]
    ),
    _vm._v(" "),
    _c("div", { ref: "modalHeader", staticClass: "modal-header drag-handle" }, [
      _c(
        "h6",
        [
          _vm._v(_vm._s(_vm.$t("accessReqForm-title"))),
          _vm.projectId
            ? [_vm._v(_vm._s(_vm.projectName))]
            : [_vm._v(_vm._s(_vm.organizationName))]
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-content padded40" }, [
      _c("div", { staticClass: "row no-bottom-margin" }, [
        _c("div", { staticClass: "col s12 m6 l6" }, [
          _c("label", { attrs: { for: "request_org" } }, [
            _vm._v(_vm._s(_vm.$tc("common-organization", 1)))
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.organizationName))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col s12 m6 l6" },
          [
            _c("h6", [
              _vm._v(
                _vm._s(_vm.$t("accessReqForm-fields.accessTo")) + "\n          "
              ),
              _vm.projectId
                ? _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("accessReqForm-tooltips.projectAccess"),
                        expression: "$t('accessReqForm-tooltips.projectAccess')"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
                : _c("span", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.$t("accessReqForm-tooltips.orgAccess"),
                        expression: "$t('accessReqForm-tooltips.orgAccess')"
                      }
                    ],
                    staticClass: "info-icon ion-information-circled"
                  })
            ]),
            _vm._v(" "),
            _vm.projectName
              ? [_vm._v(_vm._s(_vm.$t("accessReqForm-fields.projectAccess")))]
              : [_vm._v(_vm._s(_vm.$t("accessReqForm-fields.orgAccess")))]
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "reasonSection col s12" }, [
          _c(
            "label",
            { attrs: { for: "request_reason" } },
            [
              _vm.projectName
                ? [_vm._v(_vm._s(_vm.$t("accessReqForm-fields.reasonProj")))]
                : [_vm._v(_vm._s(_vm.$t("accessReqForm-fields.reasonOrg")))]
            ],
            2
          ),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reason,
                expression: "reason"
              }
            ],
            staticClass: "materialize-textarea",
            attrs: { id: "request_reason", "data-length": "250" },
            domProps: { value: _vm.reason },
            on: {
              input: function($event) {
                if ($event.target.composing) return
                _vm.reason = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "character-counter",
            staticStyle: { float: "right", "font-size": "12px", height: "1px" }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col s12" },
          [
            _vm.projectName
              ? [_vm._v(_vm._s(_vm.$t("accessReqForm-emailProject")))]
              : [_vm._v(_vm._s(_vm.$t("accessReqForm-emailOrg")))]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer" }, [
        _vm.sending
          ? _c("div", { staticClass: "status message col s12" }, [
              _vm._v(_vm._s(_vm.$t("accessReqForm-sending")))
            ])
          : _c(
              "div",
              {
                staticClass: "status col s12",
                class: { error: _vm.error, message: !_vm.error }
              },
              [_vm._v(_vm._s(_vm.message))]
            ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn btn-success modal-trigger modal-action modal-close",
            attrs: { id: "sendRequest" },
            on: { click: _vm.sendRequest }
          },
          [[_vm._v(_vm._s(_vm.$t("accessReqForm-send")))]],
          2
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-border modal-action modal-close",
            attrs: { id: "cancelAdd" },
            on: { click: _vm.closeForm }
          },
          [_vm._v(_vm._s(_vm.$t("common-close")))]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "center", staticStyle: { padding: "40px" } },
          [
            _c("pulse-loader", {
              attrs: {
                loading: true,
                color: "#C94412",
                size: "15px",
                width: 60,
                height: 30
              }
            }),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.commonMsg.loading))])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }