<template>
  <div>
    <div v-if="!creatingVisit && !uuid" @click="close" class="iconButton link">
      <span class="ion-close"></span>
    </div>
    <div v-if="!creatingVisit" :class="{'drag-handle':!uuid}" class="modal-header">
      <h5><template v-if="uuid">{{$t('singleVisitTable-visitDates')}}</template><template v-else>{{$t('singleVisitTable-visitMetadata')}} - {{locationLabel}}</template></h5>
    </div>
    <div :class="{'boxed':!creatingVisit, 'panel':!uuid, 'padded40':!uuid, 'report-table':uuid}" class="col s12 dataGroup location-visit tab-content">
      <button class="btn btn-success  locationSingleVisitAdd" v-if="isEditable" @click="updateLocationSingleVisit(null)">
        <span class="info-icon ion-information-circled" v-tooltip="tooltips.addVisit"></span>
        {{$t('singleVisitTable-addVisit')}}</button>
      <v-server-table v-if="!creatingVisit && tableOptionLoaded || (creatingVisit && tableOptionLoaded && locationId)" ref="table" :url="url" :columns="columns" :options="tableOptions" @loaded="collectVisitInfo" @row-click="onRowClick" class="table vue-bordered org-table">
        <!-- a slot="edit" slot-scope="props" class="fa fa-edit" :href="edit(props.row.common_name)"></a -->
        <template slot="selected" slot-scope="props">
          <span class="" :class="{'ion-checkmark  bright-red-font': props.row.id == tableOptions.activeRowId, 'ion-android-radio-button-off':props.row.id != tableOptions.activeRowId}" />
        </template>
        <template slot="hasImage" slot-scope="props">
          <template v-if="props.row.hasImage">
            {{$t('common-yes')}}
          </template>
          <template v-else>
            {{$t('common-no')}}
          </template>
        </template>
        <template slot="id" slot-scope="props">
          <a class="editProject modal-trigger projectAdd" @click="updateLocationSingleVisit(props.row)"><span class="link" :class="{'ion-edit': isEditable, 'ion-eye': !isEditable}"></span></a>
          <a class="editProject modal-trigger projectAdd" @click="deleteLocationSingleVisit(props.row)"><span class="link" :class="{'ion-ios-trash': isEditable}"></span></a>
        </template>
      </v-server-table>
      <div v-for="(error,index) in errors" v-bind:key="index" class="warning"> {{error}} </div>
      <div class="error"> {{message}} </div>
      <visit-equipment-table v-if="(!creatingVisit && !uuid && tableOptionLoaded && options.deployVisitId && options.deployVisitId.length > 0) || (creatingVisit && !uuid && tableOptionLoaded && locationId)"
        v-bind:organization-id="organizationId" v-bind:location-label="locationLabel"
        v-bind:visit-id="tableOptions.activeRowId" v-bind:location-id="locationId"  v-bind:options="options"
        v-bind:is-editable="isEditable" v-bind:creating-visit="creatingVisit"/>
    </div>
    <modal name="visit-detail-form"
      :scrollable="true" :adaptive="true"
      transition="nice-modal-fade"
      classes="modal-form scroll"
      draggable=".drag-handle"
      :delay="100"
      width = "75%"
      :height = "620"
      :minWidth="400"
      :minHeight="540"
      :clickToClose = "false"
    >
      <single-visit-form ref="singleVisitForm"
        v-bind:is-editable="isEditable"
        v-bind:options="options" v-bind:init-visit-row = "visitRow"
        v-bind:location-label="locationLabel" v-bind:location-id="locationId"
      />
    </modal>
    <v-dialog/>
  </div>
</template>

<script>
/* this component show two tabs + stats + notification for all locationSingleVisits
under each LocationSingleVisit tab, show a table listing all locationSingleVisits. users can also update LocationSingleVisit properties + users
or remove locationSingleVisits if they have no associated data.
*/
import LocationSingleVisitForm from './LocationSingleVisitForm';
import LocationSingleVisitEquipmentTable from './LocationSingleVisitEquipmentTable';
import FormMixin from '@/components/common/FormMixin.js';
import {eventBus} from '@/lib/eventbus';
import {API_URL, copyObject} from '@/lib/common';

export default {
  name: 'location-visit-table',
  components: {
    'single-visit-form': LocationSingleVisitForm,
    'visit-equipment-table': LocationSingleVisitEquipmentTable
  },
  props: {
    isEditable: {
      default: true,
      type: Boolean
    },
    organizationId: {
      default: null,
      type: Number
    },
    locationLabel: {
      default: '',
      type: String
    },
    creatingVisit: {
      default: false,
      type: Boolean
    },
    locationId: {
      defult: null,
      type: Number
    },
    uuid: {
      default: null,
      type: String
    }
  },
  mixins: [FormMixin],
  async created () {
    const that = this;
    let uuidInfo = '';
    if (this.uuid) {
      uuidInfo = '&uuid=' + this.uuid;
    }
    this.url = API_URL + 'get-all-location-visits?locationId=' + this.locationId + uuidInfo;
    this.columns = this.uuid ? ['date', 'crewName', 'baitId'] : ['selected', 'date', 'crewName', 'baitId', 'hasImage', 'comments', 'id'];
    // get options
    try {
      const response = await this.$http.get(API_URL + 'get-location-visit-static-options');
      this.options = response.data;
      this.tableOptions.listColumns['baitId'] = (this.options.locationDeploymentVisitBait || []).map(x => { return {id: x.id, text: x.type} });
      this.$set(this.tableOptions.listColumns, 'accessMethodId', (this.options.locationAccessMethod || []).map(x => { return {id: x.id, text: x.type}; }));
      this.options.landFeature = (this.options.landFeature || []).map(x => { return {type: x.type, landFeatureId: x.id}; });
    } catch (e) {
      // do nothing
    }
    if (!this.isEditable && !this.uuid) {
      this.columns.shift(); // remove first column: selection table
    }
    this.options.deployVisitId = [];
    this.options.retreiveVisitId = [];

    this.tableOptionLoaded = true;
    // get global admin options from sensor option is sensorAdmin, both ARU and camera isSensorAdmin are the same
    eventBus.$on('reload-visit-table', function () {
      that.$refs.table && that.$refs.table.refresh();
    });

    eventBus.$on('close-single-visit-form', function () {
      that.$modal.hide('visit-detail-form');
      if (that.$refs.table) {
        that.$refs.table.refresh();
      }
      that.tableOptions.activeRowId = null;
    });
    eventBus.$on('add-sensor', function (row) {
      that.$modal.hide('visit-detail-form');
      that.$refs.table && that.$refs.table.refresh();
      that.tableOptions.activeRowId = row.id;
      eventBus.$emit('add-equipment', {
        deployVisitId: row.deployVisitId,
        id: null, // equipment id
        locationId: row.locationId
      });
    });
  },
  methods: {
    updateLocationSingleVisit (row) {
      if (row) {
        this.visitRow = row.org;
      } else {
        // get last land features
        let landFeatures = [];
        if (this.$refs.table && this.$refs.table.data && this.$refs.table.data.length > 0) {
          landFeatures = this.$refs.table.data[this.$refs.table.data.length - 1].org.landFeatures || [];
        }
        this.visitRow = { baitId: 0, landFeatures };
      }
      this.showLocationSingleVisitForm();
    },
    deleteLocationSingleVisit (row) { // remove locationSingleVisits
      const self = this;
      this.commonDelete(this.locationSingleVisitRemoveUrl, row.org, () => {
        if (self.tableOptions.activeRowId === row.id) {
          self.tableOptions.activeRowId = null;
        }
        self.$refs.table.refresh();
        eventBus.$emit('reload-summary-table');
      },
      this.msg.deleteNote
      );
    },
    showLocationSingleVisitForm () {
      this.$modal.show('visit-detail-form');
    },
    initTable () {
      this.tableData = [];
    },
    taskRowUrl (id) {
      return this.adminPageUrl + '?id=' + id;
    },
    onRowClick (event) {
      if (this.uuid) {
        return;
      }
      if (this.tableOptions.activeRowId === event.row.id) {
        this.tableOptions.activeRowId = null;
      } else {
        this.tableOptions.activeRowId = event.row.id;
      }
    },
    collectVisitInfo () {
      // this.options = Object.assign({}, this.options, {crew: response.data.crew});
      // const visitList = this.$refs.table.data.map(x => { return {'id': x.id, 'text': x.visitTypeId + ' ' + x.date + ' ' + (x.crewId ? x.crewId : '')} });
      const visitList = this.$refs.table.data.map(x => { return {'id': x.id, 'text': x.date, 'time': x.time} });
      this.$delete(this.options, 'deployVisitId');
      this.$delete(this.options, 'retreiveVisitId');
      this.options = Object.assign({}, this.options, {deployVisitId: visitList});
      this.options = Object.assign({}, this.options, {retreiveVisitId: visitList});
      let self = this;
      this.$refs.table.data.forEach(x => {
        // find land feature type txt
        (x.org.landFeatures || []).forEach(f => {
          f.type = (self.options.landFeature.find(l => l.landFeatureId === f.landFeatureId) || {}).type;
        });
      });
    },
    close () {
      this.message = '';
      eventBus.$emit('close-location-visit-form');
      this.$modal.hide('location-visit');
    }
  },
  watch: {
    locationId: function (newVal, oldVal) {
      this.url = API_URL + 'get-all-location-visits?locationId=' + this.locationId;
    }
  },
  data () {
    const pageSize = 25;
    return {
      url: null,
      locationSingleVisitRemoveUrl: API_URL + 'delete-location-visit',
      visitRow: null,
      message: null,
      errors: [],
      /* -- */
      selected: [],
      tableOptionLoaded: false,
      columns: null,
      showCheckbox: false,
      // customFilters: ['locationSingleVisitType'],
      selectedAll: false,
      recordName: this.$tc('common-visit', 1).toLowerCase(),
      tooltips: {
        addVisit: this.$t('singleVisitTable-tooltips.addVisit')
      },
      msg: {
        deleteNote: this.$t('singleVisitTable-deleteNote')
      },
      options: {},
      tableOptions: {
        activeRowId: null,
        filterByColumn: true,
        filterable: ['crewName', 'baitId'],
        sortable: [],
        listColumns: {
        },
        skin: 'table-striped',
        headings: {
          comments: this.$t('common-comments'),
          selected: this.$t('common-selected'),
          crewName: this.$t('singleVisitTable-headings.crewName'),
          baitId: this.$t('singleVisitTable-headings.baitId'),
          id: '',
          hasImage: this.$t('singleVisitTable-headings.hasImage')
        },
        debounce: 1000, // one second debounce
        perPage: 1500,
        perPageValues: [15, 25, 50, 75, 100],
        pagination: {
          nav: 'fixed',
          edge: true},
        texts: this.$t('common-tableTexts'),
        // initFilters: {status: 'Active'},
        orderBy: {column: 'locationId', ascending: true},
        rowClassCallback (row) {
          return (row.id === this.activeRowId ? 'activeTableRow' : '');
        },
        requestAdapter (data) {
          let filter = [];
          const query = data.query;
          if (query) {
            Object.keys(query).forEach(function (key) {
              filter.push({column: key, value: query[key]});
            });
          }
          return {
            sort: data.orderBy ? data.orderBy : this.orderBy.column,
            order: data.ascending ? 'asc' : 'desc',
            limit: data.limit ? data.limit : pageSize,
            page: data.page ? data.page : 1,
            filters: filter
          }
        },
        responseAdapter (data) {
          if (data && data.total_row_count > 0) {
            return {
              data: data.results.map(x => {
                return Object.assign(x, {org: copyObject(x)});
              }), // already sorted by api: .sort((a, b) => (a == b ? (b.id - a.id) : (a > b ? -1 : 1))),
              count: data.total_row_count
            };
          } else {
            return { data: [], count: 0 };
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.location-visit {
  font-size: 0.8em;
}
.location-visit :deep( .VueTables__filters-row ){ /* hide filter row */
  display: none;
}
.location-visit :deep( tbody tr:nth-child(even)) {background-color: #d6e0ca; }
.location-visit :deep( thead tr) {background-color: #8bc34a; color: white; }
.location-visit :deep( td), .location-visit :deep( th) {border: 1px solid #d4cdcd; padding: 5px; }
.location-visit :deep( nav) {height: auto; }
a span.link {font-size: 18px; }

.report-table :deep( .VueTables--server .VuePagination){
margin-bottom:0px !important;
}

.report-table{
  background: none;
  box-shadow: none;;
}

.report-table :deep( .VuePagination__count){
  margin-bottom: 0px;
}
.red-font {color: red; }

.location-visit :deep( .VueTables__table td),
.location-visit :deep( .VueTables__table th) {
text-align: center;
}
</style>
