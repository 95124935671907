<template>
  <div id="locationVisitEditFormModal" class="visit-form" >
    <div>
      <!-- parent and child tables to show detailed visit information -->
        <ul class="tabs">
          <li class="tab" @click="showTab='visitInfo'"><a :class="{active: showTab =='visitInfo'}">{{$t('singleVisitForm-visitInfo')}}</a></li>
          <li v-if="!hideVisitPhotos" class="tab" @click="clickedVisitPhotosTab()"><a :class="{active: showTab =='visitPhotos' && visitRow.id, disabled: !visitRow.id}">{{$t('common-visitPhotos')}}</a></li>
        <div @click="close" class="iconButton link">
          <span class="ion-close"></span>
        </div>
        </ul>
      <div v-show="showTab ==='visitInfo'" class="modal-content padded40 main">
            <div class="row">
              <div><h5>{{locationLabel}}</h5></div>
              <div class="col s12 l4">
                <label>{{$t('singleVisitForm-fields.date')}}</label>
                <div class="vue-select-wrapper">
                  <date-picker class="wide" :disabled="!isEditable" :lang="locales[$root.$i18n.locale]" type="date" valueType="format" v-model="visitRow.date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" @change="changedDate()"></date-picker>
                </div>
              </div>
              <div class="col s12 l4">
                <label>{{$t('singleVisitForm-fields.crew')}}
                  <span class="info-icon ion-information-circled" v-tooltip="isEditable? tooltips.crew : tooltips.roCrew"></span>
                </label>
                <div class="vue-select-wrapper">
                  <input :disabled="!isEditable" v-model.number="visitRow.crewName" required :placeholder="$t('singleVisitForm-fields.crewPlaceholder')">
                  <!-- <select v-model="visitRow.crewId">
                    <option value=""> -- </option>
                    <option v-for="(option, index) in options.crew" :key="index" :value="option.id">{{option.name}}</option>
                  </select> -->
                </div>
              </div>
              <div class="col s12 l4">
                <label>{{$t('singleVisitForm-fields.accessMethod')}}<span class="info-icon ion-information-circled" v-tooltip="isEditable? tooltips.accessMethod : tooltips.roAccessMethod"></span> </label>
                <div class="vue-select-wrapper">
                  <select :disabled="!isEditable" v-model="visitRow.accessMethodId">
                    <option v-for="(option, index) in options.locationAccessMethod" :key="index" :value="option.id">{{option.type}}</option>
                  </select>
                </div>
              </div>

              <div class="col s12 l4">
                <label>{{$t('singleVisitForm-fields.landscapeFeature')}}
                  <span class="info-icon ion-information-circled" v-tooltip=" isEditable? tooltips.landFeature : tooltips.roLandFeature"></span> </label>
                <div class="vue-select-wrapper">
                  <multiselect :selectAllLabel="$t('common-selectAll')" :disabled="!isEditable" v-model="visitRow.landFeatures" :close-on-select="false" class="landfeature"
                    :placeholder="$t('singleVisitForm-fields.landscapeFeaturePlaceholder')" label="type" track-by="landFeatureId" :options="options.landFeature" :multiple="true">
                  </multiselect>
                </div>
              </div>
              <div class="col s12 l4">
                <label for="form_snow_depth">{{$t('singleVisitForm-fields.snowDepth')}}
                  <span class="info-icon ion-information-circled" v-tooltip="isEditable? tooltips.snowDepth : tooltips.roSnowDepth"></span>
                </label>
                <input :disabled="!isEditable" id="form_snow_depth" v-model.number="visitRow.snowDepthMeters" step="0.1" min="0" max="10" type="number" class="validate" required
                >
              </div>
              <div class="col s12 l4">
                <label for="form_water_depth">{{$t('singleVisitForm-fields.waterDepth')}}
                  <span class="info-icon ion-information-circled" v-tooltip="isEditable ? tooltips.waterDepth: tooltips.roWaterDepth"></span>
                </label>
                <input :disabled="!isEditable" id="form_water_depth" v-model.number="visitRow.waterDepthMeters" step="0.1" min="0" max="10" type="number" class="validate" required
                >
              </div>
              <div class="col s12 l4 clear-item">
                <label>{{$t('singleVisitForm-fields.baitType')}}
                  <span class="info-icon ion-information-circled" v-tooltip="isEditable? tooltips.scented: tooltips.roScented"></span>
                </label>
                <div class="vue-select-wrapper">
                  <select :disabled="!isEditable" v-model="visitRow.baitId">
                    <option v-for="(option, index) in options.locationDeploymentVisitBait" :key="index" :value="option.id">{{option.type}}</option>
                  </select>
                </div>
              </div>

              <div class="col s12">
                <label>{{$t('singleVisitForm-fields.comments')}}</label>
                <textarea class="border border-grey-300" :disabled="!isEditable" v-model="visitRow.comments"></textarea>
              </div>
            </div>
            <div v-for="(error,index) in errors" v-bind:key="index" class="warning"> {{error}} </div>
            <div v-if="message" class="error"> {{message}} </div>
            <div class="modal-footer">
              <button v-if="isEditable" class="btn btn-border btn-success modal-action modal-close" @click="updateVisitRow">{{$t('common-save')}}</button>
              <button class="btn btn-border modal-action modal-close" id="formcancelAdd" @click="close">{{$t('common-close')}}</button>
              <!-- only for deployment, wait until table collecting deployment option id -->
              <button v-if="isEditable" class="btn btn-border btn-success modal-action modal-close"
                :class="{'disabled': !(visitRow.id && options.deployVisitId && options.deployVisitId.length)}"
                @click="addSensor">{{$t('visitEquipTable-addSensor')}}</button>
            </div>
      </div>
      <div v-if="!hideVisitPhotos" v-show="showTab ==='visitPhotos'" class="modal-content padded40 main">
        <div><h5>{{$t('singleVisitForm-photosOf', {label: locationLabel})}}</h5></div>
        <div v-if="isEditable" class="upload-area">
            <div class="col s6 offset-s1">
            <!-- upload folder -->
            <input type="file" ref="chooseFolder" id="chooseFolder" name="picture"
              :value="fileInputVal" accept="image/*"
              class="form-control hidden" webkitdirectory="true"
              multiple="true"
              @change="parseUploadFolders" style="display: none;" />

            <button type="button" style="width: 90%" class="btn btn-default" id="browseFolderBtn"  @click="browse">
              <span class="glyphicon glyphicon-refresh"></span> {{$t('common-folderBtn')}}
            </button>
          </div>
          <div class="col s6 offset-s1">
            <!-- upload file -->
            <input type="file" ref="chooseFiles" id="chooseFiles" name="picture-files"
              :value="fileInputVal" accept="image/*"
              class="form-control hidden"
              multiple="true"
              @change="parseUploadFiles" style="display: none;" />

            <button type="button" style="width: 90%" class="btn btn-default" id="browseFilesBtn"  @click="browse">
              <span class="glyphicon glyphicon-refresh"></span> {{$t('common-fileBtn')}}
            </button>
          </div>
          <div class="col s4">
            <button type="button" style="width: 90%" class="btn btn-secondary" :disabled="filesPendingUpload.length === 0 || uploading" @click="resetUpload">{{$t('singleVisitForm-clearSelection')}}</button>
            </div>

          <div class="s12">
            <div class="col offset-s1 s6 filetext-wrapper">
              <span class="filetext-inner" v-if="filesPendingUpload.length > 0">{{filesPendingUpload.length}} {{$tc('singleVisitForm-imagesFound', filesPendingUpload.length)}}
              </span>
              </div>
            <div class="col s4">
              <button v-if="isEditable" :disabled="filesPendingUpload.length === 0 || uploading" class="btn btn-border btn-success modal-action modal-close" @click="processBatchUpload">{{$t('common-upload')}}</button>
            </div>
          </div>
          <div class="col s12 error" v-if="filesWithParseErrors.length > 0">
            <template v-for="problem in filesWithParseErrors">
              <div v-bind:key="'error-' + problem.path">{{problem.error + ' - ' + problem.path}}</div>
            </template>
          </div>
          <div class="col s12 error">{{uploadMessage}}</div>
        </div>
      <visit-photo-gallery ref="gallery" v-bind:visit-id="visitRow.id" v-bind:uploading="uploading" v-bind:is-editable="isEditable"></visit-photo-gallery>
      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from '@/lib/eventbus';
import {API_URL, alertDialog} from '@/lib/common';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import FormMixin from '@/components/common/FormMixin.js';
import DatePickerMixin from '@/components/common/DatePickerMixin';
import DatePicker from 'vue2-datepicker'; // new one can pick time
import VisitPhotoGallery from './VisitPhotoGallery.vue';

export default {
  name: 'single-visit-form',
  props: ['options', 'initVisitRow', 'isEditable', 'locationLabel', 'locationId', 'hideVisitPhotos'],
  components: { DatePicker, 'multiselect': Multiselect, 'visit-photo-gallery': VisitPhotoGallery, Multiselect },
  mixins: [FormMixin, DatePickerMixin],
  data () {
    return {
      // url: API_URL + 'get-location-by-id',
      // locationUpdateUrl: API_URL + 'insert-update-location',
      // deleteUrl: API_URL + 'delete-location',
      // // formName:'location',
      createLocationUrl: API_URL + 'insert-update-location',
      createUpdateVisitPhotoUrl: API_URL + 'create-or-update-location-visit-image',
      getPreSignAWSUrl: 'https://yd7zjbacq3.execute-api.us-west-2.amazonaws.com/beta', // default settings 'https://d9co5p2kw9.execute-api.us-west-2.amazonaws.com/beta/';
      location: null,
      visitRow: this.initVisitRow,
      showTab: 'visitInfo',
      fileInputVal: '',
      parallelUploadCount: 30,
      failedRetryCount: 10,
      processingImageList: [],
      processedImageId: [],
      uploading: false,
      isFolderSelected: false,
      filesPendingUpload: [],
      filesWithParseErrors: [],
      uploadMessage: '',
      checkedPhotos: [],
      uploadedPhotos: [],
      message: '',
      errors: [],
      recordName: this.$tc('common-visit', 1).toLowerCase(),
      tooltips: {
        crew: this.$t('singleVisitForm-tooltips.crew'),
        snowDepth: this.$t('singleVisitForm-tooltips.snowDepth'),
        waterDepth: this.$t('singleVisitForm-tooltips.waterDepth'),
        scented: this.$t('singleVisitForm-tooltips.scented'),
        landFeature: this.$t('singleVisitForm-tooltips.landFeature'),
        accessMethod: this.$t('singleVisitForm-tooltips.accessMethod'),
        // for readonly vvvvv
        roCrew: this.$t('singleVisitForm-tooltips.roCrew'),
        roSnowDepth: this.$t('singleVisitForm-tooltips.roSnowDepth'),
        roWaterDepth: this.$t('singleVisitForm-tooltips.roWaterDepth'),
        roScented: this.$t('singleVisitForm-tooltips.roScented'),
        roLandFeature: this.$t('singleVisitForm-tooltips.roLandFeature'),
        roAccessMethod: this.$t('singleVisitForm-tooltips.roAccessMethod')
      }
    }
  },
  created () {
  },
  watch: {
    initVisitRow: function (newVal, oldVal) {
      this.visitRow = newVal;
    }
  },
  methods: {
    close () {
      this.message = '';
      this.uploadMessage = '';
      this.errors = [];
      this.showTab = 'main';
      eventBus.$emit('close-single-visit-form');
    },
    updateVisitRow () {
      if (!this.isEditable) {
        alertDialog(this.$modal, this.commonMsg.deny.update, this.$t('common-denied'), 600);
        return;
      }
      this.message = '';
      this.errors = [];
      if (this.visitRow.date) {
        if (typeof this.visitRow.date === 'object') {
          // 2011-10-05T14:48:00.000Z => 2011-10-05 14:48:00
          this.visitRow.date = this.visitRow.date.toISOString().split('.')[0].split('T').join(' ');
        } else if (typeof this.visitRow.date === 'string') {
          // this.visitRow.date = this.visitRow.date.split(' ')[0]
          // dont allow a visit on a day that hasnt happened yet
          let today = new Date();
          let tmp = this.visitRow.date.split('-');

          if (parseInt(tmp[0]) > today.getFullYear() ||
          (parseInt(tmp[0]) === today.getFullYear() && parseInt(tmp[1]) > today.getMonth() + 1) ||
          (parseInt(tmp[0]) === today.getFullYear() && parseInt(tmp[1]) === today.getMonth() + 1 && parseInt(tmp[2]) > today.getDate())) {
            this.errors.push(this.$t('singleVisitForm-noVisitInFuture'));
          }
        }
      } else {
        this.errors.push(this.$t('singleVisitForm-dateRequired'));
      }
      if (this.errors.length > 0) {
        return;
      }
      if (!this.visitRow.locationId) {
        this.visitRow.locationId = this.locationId;
      }
      if (this.visitRow.id) {
        (this.visitRow.landFeatures || []).forEach(x => { x.locationVisitId = this.visitRow.id; });
      } /* if (!this.locationId) {
        // we must be from the create visit form and this is the first visit being created
        this.createNewLocationDeployment().then(id => {
          this.visitRow.locationId = id;
          eventBus.$emit('created-location-visit', id);
          this.createUpdateVisit();
        }).catch((er) => {
          this.errors.push(er);
        });
      } else { */
      this.createUpdateVisit();
      // }
    },
    addSensor () {
      eventBus.$emit('add-sensor', this.visitRow);
    },
    createUpdateVisit () {
      const self = this;
      this.commonInsertUpdate(API_URL + 'insert-update-location-visit', this.visitRow, (this.visitRow.id ? 'update' : 'add'), function (response) {
        eventBus.$emit('reload-visit-table');
        eventBus.$emit('reload-summary-table');
        self.visitRow.id || (self.visitRow.id = response.data.id); // don't copy other fields, otherwise, the multiple option missing label values
      }, null, ' ' + this.$t('singleVisitForm-noDuplicates'));
    },
    createNewLocationDeployment () {
      return new Promise((resolve, reject) => {
        this.commonPost(this.createLocationUrl, {locationId: this.locationId, comments: null}, true, (response) => {
          return resolve(response.body.id);
        }, (failed) => {
          return reject(failed.body.error);
        });
      });
    },
    changedDate () {
      this.errors = [];
    },
    clickedVisitPhotosTab () {
      if (this.visitRow.id) {
        this.showTab = 'visitPhotos';
      }
    },
    /*
  File uploading & parsing
  */
    browse ($event) {
      if ($event.target.id === 'browseFolderBtn') {
        this.$refs.chooseFolder.click();
      } else {
        this.$refs.chooseFiles.click();
      }
    },
    parseUploadFolders (e) {
      this.isFolderSelected = true;
      e.stopPropagation();
      e.preventDefault();
      // parse files
      this.parseFiles(e.target.files);
    },
    parseUploadFiles (e) {
      this.isFolderSelected = false;
      e.stopPropagation();
      e.preventDefault();
      // parse files
      this.parseFiles(e.target.files);
    },
    parseFiles: function (files) {
      let fileCount = files.length;
      for (let i = 0; i < fileCount; i++) {
        let file = files[i];
        // for now this functionlaity works in chrome only, so webkitRelativePath *must* exist
        // only for folder selection
        if (!file.webkitRelativePath && this.isFolderSelected) {
          console.error('webkitRelativePath NOT supported!');
          continue;
        }

        let mimeType = file.type;
        if (mimeType.indexOf('image') === -1 || file.size <= 1) { // don't process none image files or image too small
          this.filesWithParseErrors.push({error: this.$t('singleVisitForm-notAnImage'), path: file.webkitRelativePath});
          continue;
        }
        this.filesPendingUpload.push(file);
      }
    },
    processBatchUpload () {
      /* do a validation */
      this.uploadMessage = '';
      this.uploading = true;
      if (this.filesPendingUpload.length > 0) {
        this.uploadToAWS();
      } else {
        this.uploadMessage = this.$t('singleVisitForm-cannotUpload');
      }
    },
    uploadToAWS () {
      // want to upload in chunks
    /* ----------------------------------------------------------------------------------
      proccessing  image asynchronizely
      parallelUploadCount: 30,
      failedRetryCount: 10,
    ---------------------------------------------------------------------------------- */
      this.processingImageList = [];
      this.processedImageId = [];

      this.filesPendingUpload.forEach((file, index) => {
        this.processingImageList[index] = {
          file: file,
          index: index,
          uploadCount: (this.failedRetryCount > 0 ? this.failedRetryCount : 1)
        };
      });

      this.processImageGroup();
    },
    /* asynchorizely process a number of files */
    processImageGroup () {
      if (Object.keys(this.processingImageList).length === 0) {
        return;
      }
      let currentImageList = [];
      const imageKeys = Object.keys(this.processingImageList);

      for (let i = 0; i < imageKeys.length; i++) {
        if (this.processingImageList[imageKeys[i]].uploadCount > 0) {
          currentImageList.push(this.processingImageList[imageKeys[i]]);
        }
        if (currentImageList.length >= this.parallelUploadCount) {
          break;
        }
      }
      /* create tasks for handling single file upload */
      let imageUploadTask = [];
      currentImageList.map((file, index) => {
        try {
          imageUploadTask.push(this.processSingleFile(file));
        } catch (e) {
          this.uploadMessage = this.$t('singleVisitForm-uploadError') + ': ' + e;
          // self.failedCallback(deploymentKey, file);
        // console.log('error on single file ', file, e);
        }
      });

      /* ----------------------------------------------------------------------------------
      after ALL image uploaded, get the values to send to api to confirm updating
     ---------------------------------------------------------------------------------- */

      Promise.all(imageUploadTask).then(async (values) => {
        this.$refs.gallery.addUploadedImages(values);
        this.uploading = false;
        this.filesPendingUpload = [];
        this.isFolderSelected = false;
      }); // done promise.all
    },
    /* acutally uploading for a single file */
    async processSingleFile (fileObj) {
      const file = fileObj.file;
      const index = fileObj.index;
      if (!file) { // don't do anything when no valid file is provided.
        delete this.processingImageList[index]; // remove file
        this.uploadMessage = this.$t('singleVisitForm-unableToUploadFile');
        // self.failedCallback(deploymentKey, fileObj, true);
        return -1;
      }
      const imageCheckingParam = {
        locationVisitId: this.visitRow.id ? this.visitRow.id : null,
        fileName: file.name,
        extension: file.name.split('.').pop(),
        size: file.size
      };

      let imageCheckObj;
      try {
        const imageCheckResponse = await this.$http.post(this.createUpdateVisitPhotoUrl, imageCheckingParam); /* check image agaist db, to get uuid id etc. */
        imageCheckObj = imageCheckResponse.data;
        if (!imageCheckObj || imageCheckObj.hasOwnProperty('error')) { // failed call, don't upload
          throw imageCheckObj.error;
        }
      } catch (e) {
        this.uploadMessage = this.$t('singleVisitForm-uploadError') + ': ' + e;
        // self.failedCallback(deploymentKey, fileObj, true, 'failed', 'Failed to register image. {' + JSON.stringify(e) + '}');
        return -1;
      }

      /* ---------------------------------------------------------------------------------------
      step 3
      otherwise, upload  to AWS
      Image path site-photos/ file uuid / large / id .jpg
    --------------------------------------------------------------------------------------- */
      const fileKey = 'site-photos/' + imageCheckObj.uuid + '/large/' + imageCheckObj.id + '.' + imageCheckObj.extension;

      /* ---------step 3.1  get presignedurl from aws -------------------
      version 1: use getPresignedUrl to upload image as standard types
      version 2: to custom createPresignedPost to set storage TYPE Header, so it can save as intelligence type
    */
      const params = { params: { fileKey: fileKey, projectId: this.locationLabel, organizationId: this.organizationId, bucket: imageCheckObj.storage.camBucket, region: imageCheckObj.storage.region, time: Date.now() } }
      try {
        const urlResponse = await this.$http.get(this.getPreSignAWSUrl, params);
        let uploadUrl = (urlResponse && urlResponse.body && urlResponse.body.body && urlResponse.body.body.url) ? urlResponse.body.body.url : false;
        /* ----------------------------------------------------------------
        step 3.2  call presignedurl to do actual uploading
      --------------------------------------------------------------- */
        if (uploadUrl) {
          let data = new FormData();
          for (const field in urlResponse.body.body.fields) {
            data.append(field, urlResponse.body.body.fields[field]);
          }
          data.append('file', file);
          /* important: set content-type to image, otherwise, won't display automatically image on the website */
          try {
            const response = await this.$http.post(uploadUrl, data, { headers: { 'Content-Type': file.type } });

            if (response.status === 204) {
              // self.onUpdateCallback(deploymentKey, { fileStatus: 'completed', timeType: 'current', timeStamp: Date.now() });
              delete this.processingImageList[index]; // remove file
              return (imageCheckObj);
            } else {
              throw new Error(this.$t('common-failedUpload'));
            }
          } catch (e) {
            throw e;
          }
        } else {
          throw new Error(this.$t('cameraUploader-uploadAddressFail'));
        }
      } catch (e) { // failed to get presigned url
      // console.log('can\'t get uploadUrl');
        // self.failedCallback(deploymentKey, fileObj, false, 'failed', 'Failed Amazon uploading {' + e.message + '}');
        this.uploadMessage = this.$t('singleVisitForm-uploadError') + ': ' + e;
        return -1;
      }
    /* end of uploading */
    },

    resetUpload () {
      this.filesWithParseErrors = [];
      this.isFolderSelected = false;
      this.uploading = false;
      this.filesPendingUpload = [];
      this.$set(this, 'fileInputVal', '');
    }
  }
}
</script>
<style scoped>

.landfeature :deep( .multiselect__tags) {
  margin-bottom: 8px;
}

textarea:disabled{
  resize:none;
}

.upload-area{
  padding-top: 20px;
  padding-bottom: 20px;
}

.filetext-wrapper{
  /* text-align: center; */
    padding-top:10px !important;
    font-size: 14px;
}

.filetext-inner{
  /*padding-left:19px;
  padding-right:19px;
  */
}

a.disabled{
  cursor: default !important;
}

.clear-item{
  clear:both;
}

.wide{
  width:100%;
}

#locationVisitEditFormModal .tabs .disabled {
  background: #d5d5d5;
}

#locationVisitEditFormModal .tab{
  width: 100%;
}

</style>
