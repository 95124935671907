var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "container overflow boxed padded60" }, [
      _c("div", { staticClass: "bordered" }),
      _vm._v(" "),
      _c("div", { staticClass: "container small center-align" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("common-results")))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "break" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container center-align ddFilters" },
        [
          _c(
            "v-client-table",
            {
              ref: "table",
              attrs: {
                columns: _vm.columns,
                data: _vm.displayData,
                options: _vm.options
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "checkbox",
                    fn: function(props) {
                      return false
                        ? [
                            _c("label", { attrs: { for: props.row.id } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selected,
                                    expression: "selected"
                                  }
                                ],
                                attrs: { type: "checkbox", id: props.row.id },
                                domProps: {
                                  value: props.row.id,
                                  checked: Array.isArray(_vm.selected)
                                    ? _vm._i(_vm.selected, props.row.id) > -1
                                    : _vm.selected
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.selected,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = props.row.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selected = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selected = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selected = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span")
                            ])
                          ]
                        : undefined
                    }
                  },
                  {
                    key: "name",
                    fn: function(props) {
                      return [
                        props.row.hasDetailedAccess
                          ? _c("span", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.getProjectLink(props.row)
                                  }
                                },
                                [_vm._v(_vm._s(props.row.name) + " ")]
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(props.row.name) +
                                  "\n          "
                              )
                            ])
                      ]
                    }
                  },
                  {
                    key: "organizationColor",
                    fn: function(props) {
                      return [
                        _c("span", {
                          style:
                            "display:inline-block; width: 25px; height: 25px; background-color:" +
                            props.row.organizationColor
                        })
                      ]
                    }
                  },
                  {
                    key: "child_row",
                    fn: function(props) {
                      return _c(
                        "div",
                        { staticClass: "row subTable" },
                        [
                          props.row.examples && props.row.examples.length > 0
                            ? _c("v-client-table", {
                                attrs: {
                                  columns: _vm.subTableOptions.columns,
                                  data: props.row.examples,
                                  options: _vm.subTableOptions
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "locationName",
                                      fn: function(subProps) {
                                        return [
                                          props.row.hasDetailedAccess
                                            ? _c("span", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      href: _vm.getTaskPageLink(
                                                        subProps.row,
                                                        props.row
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        subProps.row
                                                          .locationName
                                                      ) + " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      subProps.row.locationName
                                                    ) +
                                                    "\n              "
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _vm._v(" "),
              false
                ? _c(
                    "div",
                    {
                      attrs: { slot: "filter__checkbox" },
                      slot: "filter__checkbox"
                    },
                    [
                      _c("label", { attrs: { for: "toggleAll" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedAll,
                              expression: "selectedAll"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "checkbox", id: "toggleAll" },
                          domProps: {
                            checked: Array.isArray(_vm.selectedAll)
                              ? _vm._i(_vm.selectedAll, null) > -1
                              : _vm.selectedAll
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.selectedAll,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.selectedAll = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.selectedAll = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.selectedAll = $$c
                                }
                              },
                              function($event) {
                                return _vm.toggleAll()
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span")
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "break" }),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "container center-align" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-large download",
                class: {
                  downloading: _vm.isDownloading,
                  disabled: _vm.selected.length == 0
                },
                attrs: { download: "" },
                on: { click: _vm.downloadRaw }
              },
              [
                _c("span", { staticClass: "spin-icon" }),
                _c("span", { staticClass: "ion-ios-download-outline" }),
                _vm._v(_vm._s("dataDiscoverDownload-downloadData"))
              ]
            ),
            _vm._v(" "),
            _c("button", { staticClass: "btn btn-border btn-large" }, [
              _vm._v(_vm._s(_vm.$t("dataDiscoverDownload-remove")) + " "),
              _c("span", { staticClass: "ion-ios-trash-outline" })
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }