import throttle from './throttle'

let listenAction
let supportCSSSticky

const getBindingConfig = binding => {
  const params = binding.value || {}
  let stickyTop = params.stickyTop || 0 /* sticky space between top and current component. */
  let stickyTopElementId = params.stickyTopElementId /* stick under some element */
  let zIndex = params.zIndex || 10
  let disabled = params.disabled
  let positionType = params.positionType || 'sticky' 
  let stickTopOnly = params.stickTopOnly || false /* when false, it can sticky to top and bottom , otherwise, only stick to top */
  let stickyContainerId = params.stickyContainerId /* sticky to which container, if null use body */
  return {stickyTop, zIndex, disabled, positionType, stickyContainerId , stickyTopElementId, stickTopOnly}
}

const getInitialiConfig = el => {
  return {
    zIndex: el.style.zIndex,
  }
}

const unwatch = () => {
  window.removeEventListener('scroll', listenAction)

}
const watch = () => {
  window.addEventListener('scroll', listenAction)
  
}

const updateElement = (el, bindingConfig) => {
  const {stickyTop, zIndex, positionType, stickyContainerId, stickyTopElementId , stickTopOnly} = bindingConfig
  // console.log('is updating now');
  // console.log(el);
  // const parentElement = (stickyContainerId ? document.getElementById(stickyContainerId) : document.body);
  const stickyTopElement = (stickyTopElementId ? document.getElementById(stickyTopElementId) : null)
  // const top = (stickyTopElement ? stickyTopElement.clientTop + stickyTopElement.clientHeight + stickyTop : stickyTop)
  // console.log('is updating now top=' + top);
  let childStyle
  if (el.firstElementChild) {
    childStyle = el.firstElementChild.style
  } else {
    childStyle = {}
  }

  if (supportCSSSticky) {
    // el.style.top = `${stickyTop}px` // dynamic stick top.
    // if (positionType == 'fixed') {
    //   const parentOffsetTop = el.parentElement.getBoundingClientRect().top
    //   const  element = el.parentElement;
    //   console.log('parent top...' + parentOffsetTop)
    //   console.log('scroll ...' + window.scrollY)
    //   const topPosition = (parentOffsetTop + window.scrollY );
    //   el.style.top = `${topPosition}px`
    // }
    // el.style.top = `${top}px` // don't set top, otherwise, when user selected by dragging, this will be called. and make the top 0
    el.style.zIndex = zIndex
    el.style.width = `${el.parentElement.clientWidth}px` // for fixed position, width is required. so use parent width
  } else {
    childStyle.top = `${stickyTop}px`
    childStyle.zIndex = zIndex
  }    

  if (bindingConfig.disabled) {
    if (supportCSSSticky) {
      el.style.position = ''
    } else {
      childStyle.position = ''
      childStyle.top = ''
      childStyle.zIndex = initialConfig.zIndex
      unwatch()
    }
    return
  }

  if (supportCSSSticky ) {
    if (positionType == 'sticky') {
      el.style.position = '-webkit-' + positionType
      el.style.position = positionType
    }
  } else {
    watch()
  }
}
let bindingConfig = {}
let initialConfig = {}

export default {
  // bind(el, binding) { /* source code using bind, but our page using dynamic contents, when binding, parent element not exist, can't get width height */
  inserted(el, binding) {
    bindingConfig = getBindingConfig(binding)
    initialConfig = getInitialiConfig(el)
    // positionType : sticky or fixed
    const {disabled, stickyTop, zIndex, positionType, stickyContainerId, stickyTopElementId , stickTopOnly} = bindingConfig
    
    if (disabled) return
    // window.addEventListener('resize', update)
    const parentElement = (stickyContainerId ? document.getElementById(stickyContainerId) : document.body);
    const stickyTopElement = (stickyTopElementId ? document.getElementById(stickyTopElementId) : null)
    const top = (stickyTopElement ? stickyTopElement.clientTop + stickyTopElement.clientHeight + stickyTop : stickyTop)
    const elStyle = el.style
    let initWidth = el.clientWidth;
    elStyle.position = '-webkit' + positionType
    elStyle.position = positionType
    

    let childStyle = el.firstElementChild.style

    // test if the browser support css sticky
    supportCSSSticky = ~elStyle.position.indexOf(positionType)
    
    if (supportCSSSticky) {
      elStyle.top = `${top}px`
      elStyle.zIndex = zIndex
      elStyle.width = `${initWidth}px` // for fixed position, width is required. so use parent width
      if (positionType !== 'sticky') {
        elStyle.position = 'static';
      }
      
    } else {
      elStyle.position = ''
      childStyle.cssText = `left: 0; right: 0; top: ${stickyTop}px; z-index: ${zIndex}; ${childStyle.cssText}`
    }

    window.addEventListener('resize', function() {updateElement(el, getBindingConfig(binding));});
    let active = false
    /* use class to update position type, otherwise, it will be slow on broswer, and can notice flicking divs */
    const sticky = (inStcikyTop) => {
      if (supportCSSSticky || active) {
        // console.log('is sticking now');
        // console.log(el);
        if (positionType !== 'sticky') {
          el.classList.add('pinned');
          el.classList.remove('pin-top'); 
        }
      
        if (inStcikyTop != el.style.top) {
          el.style.top = `${inStcikyTop}px`
        }
        
        // const elStyle = el.style
        // elStyle.position = '-webkit' + positionType
        // elStyle.position = positionType
      }
      // if (!el.style.height) { // don't set height for dynmaic content
      //   el.style.height = `${el.offsetHeight}px`
      // }
      if (!supportCSSSticky) {
        if (childStyle) {
          childStyle.position = 'fixed'
        }
      }
      active = true
      
    }

    const reset = () => {
      if (supportCSSSticky || !active) {
        // console.log('is static now');
        // console.log(el);
        if (positionType !== 'sticky') {
          el.classList.remove('pinned');
          el.classList.add('pin-top');
        }
        active = false
        return;
      }
      childStyle.position = 'static'
      
      active = false
    }

    listenAction = throttle(() => {
      if (el.getBoundingClientRect().height == 0) {
        reset(); // when the div is invisible, don't do stick
        return;
      }
      const offsetTop = el.getBoundingClientRect().top
      
      const parentElement = (stickyContainerId ? document.getElementById(stickyContainerId) : document.body);
      const stickyTopElement = (stickyTopElementId ? document.getElementById(stickyTopElementId) : null)
      const top = (stickyTopElement ? stickyTopElement.clientTop + stickyTopElement.clientHeight + stickyTop : stickyTop)

      if (positionType == 'fixed' && parentElement) {
        const parentOffsetTop = parentElement.getBoundingClientRect().top

        const stickyElementBottom = top + el.getBoundingClientRect().height
        const parentOffsetBottom = parentOffsetTop + parentElement.getBoundingClientRect().height
        // console.log (stickyContainerId + ' obj ...t:' + offsetTop );
        // console.log('top ', top ,'parentOffsetTop:' , 
        // parentOffsetTop , ' parentOffsetBottom:' ,parentOffsetBottom,
        // ' container height', parentElement.getBoundingClientRect().height)
        // console.log('offsetBottom ', stickyElementBottom, 'stickyTopElement height', stickyTopElement.getBoundingClientRect().height, ' div height', el.getBoundingClientRect().height);
        
        // if ( ( parentOffsetTop) >= stickyTop) { /* when top of the parent div is in view, no need to sticky */
        //   return reset();
        // } 
        if(parentOffsetTop >= top) {
          return reset();
        }
        // stick bottom doesn't stick nicely
        if (!stickTopOnly && stickyElementBottom >= parentOffsetBottom) { /* when bottom outside of the parent bottom, try stick bottom */
          /*88888 calculate top position */
          const tmpStickyTop = parentOffsetBottom - el.getBoundingClientRect().height;
          // console.log('stick bottom =', tmpStickyTop, el.getBoundingClientRect());
          return sticky(tmpStickyTop);
        }
      }
      if (offsetTop <= top) { /* include = condition, so stays at sticky mode when scrolling */
        // console.log('stick top =' + top);
        return sticky(top)
      }
      reset();
    })

    watch()
  },

  unbind: unwatch,

  update (el, binding) { // component changed, such as adding more content
    bindingConfig = getBindingConfig(binding);
    updateElement(el, bindingConfig);
  },
}
