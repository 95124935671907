import Vue from 'vue';
import i18n from '@/i18n';
import { WebAuth, Authentication } from 'auth0-js';
import { createLink, SUPPORTED_LANGUAGES, getUserLanguagePreference, changeLanguage, localeFromURL } from '@/lib/language.js'
import { eventBus } from '@/lib/eventbus.js'
// const CLIENT_ID = '{AUTH0_CLIENT_ID}';
// const CLIENT_DOMAIN = '{AUTH0_DOMAIN}';
// const REDIRECT = 'YOUR_CALLBACK_URL';
// const SCOPE = '{SCOPE}';
// const AUDIENCE = 'AUDIENCE_ATTRIBUTE';
const AUTH0_DOMAIN = 'abmi.auth0.com';
const AUTH0_CLIENT_ID = 'Eg2MPVtqkf3SuKS5uXzP97nxU13Z2K1i';
// const CLIENT_DOMAIN = 'abmi.auth0.com';
/* callback url needs to be register in auth0 allowed call back fields */
const AUTH0_CALLBACK_URL = window.location.origin; // window.location.href; //'http://localhost:8088/';
const AUDIENCE = 'http://www.wildtrax.ca';
const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const EXPIRE_TOKEN_KEY = 'expire_at';
const ORIGINAL_PATH = 'originalLocalPath';
// const CUSTOM_DOMAIN_NAMESPACE = 'https://www.wildtrax.ca/home/change_email'; // cannot use auth0 namespace for custom claims https://auth0.com/docs/security/tokens/json-web-tokens/create-namespaced-custom-claims
let gAuthenticated;
const authParam = {
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  redirectUri: AUTH0_CALLBACK_URL,
  // audience: 'https://' + AUTH0_DOMAIN + '/userinfo',
  audience: AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid email profile',
  leeway: 60
};
const LANGUAGE_TOKEN_KEY = 'session_language';

let auth = new WebAuth(authParam);
const auth0Endpoint = new Authentication({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID
});
export const EventBus = new Vue();
/* this call back , need to be at created section, otherwise, the popup window won't close */
// auth.popup.callback({});

function setSession (authResult) {
  // console.log('reload page');
  if (authResult) {
    //  window.location.hash = '';
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem(ACCESS_TOKEN_KEY, authResult.accessToken);
    localStorage.setItem(ID_TOKEN_KEY, authResult.idToken);
    localStorage.setItem(EXPIRE_TOKEN_KEY, expiresAt);
    // EventBus.$emit('authentication_updated', gAuthenticated);
    eventBus.$emit('token_updated', authResult.accessToken);
    // getProfile();
  }
}

function reloadSession (bRefreshPage) {
  /* if (redirectPath) {
      window.location.href = redirectPath;
    } else */
  if (bRefreshPage) {
    window.location.reload(true);
  } else {
    gAuthenticated = isLoggedIn();
    scheduleRenewal(true);
  }
}

/* From the webAuth.authorize depending on the user intentions you would pass a conditional parameter:
webAuth.authorize({ mode: "[login|signUp]", ... });
On the hosted login page you would configure Lock with:

var lock = new Auth0Lock(config.clientID, config.auth0Domain, {
    initialScreen: config.extraParams.mode,
    ...
});
*/
function savePath () {
  localStorage.setItem(ORIGINAL_PATH, window.location.href);
}
export function signup () {
  savePath();
  auth.authorize({
    mode: 'signUp',
    responseType: 'token id_token',
    redirectUri: AUTH0_CALLBACK_URL,
    state: ORIGINAL_PATH
  });
}
export function login () {
  savePath();
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: AUTH0_CALLBACK_URL,
    state: ORIGINAL_PATH
  });
}
/*
  auth.popup.authorize({},
    // auth.authorize({},
    function (err, authResult) {
      console.log(' haha ha ' + err);
      if (err) {
        console.error(err);
      }
      setSession(authResult, true);
    });  */
export function logout () {
  savePath();
  localStorage.removeItem(ID_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(EXPIRE_TOKEN_KEY);
  localStorage.removeItem('profile');
  gAuthenticated = false;
  // console.debug(' logout bus');
  eventBus.$emit('authentication_updated', gAuthenticated);
  //getProfile();
  clearTimeout(tokenRenewalTimeoutAuth); // not clearing the lang token timeout here so the user can still use the site in their language after logout

 // let href = createLink('/home.html');
  window.location.href = localStorage.getItem(ORIGINAL_PATH);
  location.reload();
}
// function requireAuth (to, from, next) {
//   if (!isLoggedIn()) {
//     next({
//       path: '/',
//       query: { redirect: to.fullPath }
//     });
//   } else {
//     next();
//   }
// }

function getIdToken () {
  return localStorage.getItem(ID_TOKEN_KEY);
}

// function getAccessToken () {
//   return localStorage.getItem(ACCESS_TOKEN_KEY);
// }
// Helper function that will allow us to extract the access_token and id_token
// function getParameterByName (name) {
//   let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
//   return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
// }

export function isLoggedIn () {
  const idToken = getIdToken();
  const isLoggedIn = !!idToken && !isTokenExpired();

  eventBus.$emit('authentication_updated', isLoggedIn);
  // getProfile ();
  return isLoggedIn;
}

export function isTokenExpired () {
  const expiresAt = JSON.parse(localStorage.getItem(EXPIRE_TOKEN_KEY));
  return new Date().getTime() > Number(expiresAt);
}

export function getProfile (forceUpdate = false) {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  let localProfile = localStorage.getItem('profile');
  if (accessToken) {
    if ((localProfile === null || forceUpdate)) {
      auth.client.userInfo(accessToken, function (err, profile) {
        if (err && !profile) {
          eventBus.$emit('profile_updated', { given_name: i18n.t('signIn-guest') });
        } else {
          localStorage.setItem('profile', JSON.stringify(profile));
          eventBus.$emit('profile_updated', profile);
        }
      });
    } else {
      eventBus.$emit('profile_updated', localProfile);
    }
  } else {
    eventBus.$emit('profile_updated', { given_name: i18n.t('signIn-guest') });
  }
}

function renewToken () {
  console.debug(new Date().toUTCString() + ' renewing token');
  auth.checkSession({},
    function (err, authResult) {
      if (err) {
        console.error(new Date().toUTCString() + ' renewing token failed: ');
        console.error(err);
      }
      setSession(authResult);
      reloadSession(false);
    }
  );
}

let tokenRenewalTimeoutAuth;
// ...
function scheduleRenewal () {
  const expiresAt = JSON.parse(localStorage.getItem(EXPIRE_TOKEN_KEY));
  const delay = expiresAt - Date.now();
  if (delay > 10000) { // schedule to renew 10 seconds before section expires.
    tokenRenewalTimeoutAuth = setTimeout(function () {
      renewToken();
    }, delay - 10000);
  } else if (delay > 0) { // if within little time, renewed directly.
    renewToken()
  }
}

export function authForChangeEmail (email, pw) {
  // calls the oauth/token endpoint to check user pw, then returns claim so the user may change their password
  /*
  Resources for changing email:
  - General steps: https://community.auth0.com/t/how-to-let-users-change-their-email-safely/41748/5
  - How to check only the password: https://community.auth0.com/t/best-way-to-check-if-a-password-is-correct/6051
  - Docs for Authorization.loginWithDefaultDirectory: https://auth0.github.io/auth0.js/index.html
  - Auth endpoint doc: https://auth0.com/docs/api/authentication?javascript#authenticate-user
  - Custom scopes must use a non-auth0 namespace: https://auth0.com/docs/security/tokens/json-web-tokens/create-namespaced-custom-claims
  - Custom scopes (scroll to bottom): https://auth0.com/docs/configure/apis/scopes/sample-use-cases-scopes-and-claims
  The basic jist: you will need to give the user a new scope that gives permission to change their email
  This function currently isnt working, there is a new scope added but its undefined
  */
  auth0Endpoint.loginWithDefaultDirectory({
    username: email,
    realm: 'email',
    password: pw,
    scope: 'update_email'
  }, (err) => {
    console.log(err);
  });
}

export function handleAuthentication () {
  auth.parseHash(function (err, authResult) {
    // console.debug(new Date().toUTCString() + ' log in error = ' + err);
    if (err && err.error) {
      // remove query parameters, so when page refreshed, same error won't be alerted again.
      // window.history.pushState({}, "Wildtrax Home", '/home.html');
      // alert won't stay (disappeared) if show alert too earlier, so use a timer to delay it.
      window.setTimeout(() => {
        alert(i18n.t('signIn-loginFailed') + ': ' + err.errorDescription);
      }, 2000);
      return;
    } else if (authResult && authResult.accessToken && authResult.idToken) {
      // window.location.hash = '';
      const redirectPath = localStorage.getItem(ORIGINAL_PATH);
      localStorage.removeItem(ORIGINAL_PATH);
      setSession(authResult);

      // get user's language setting from db
      getUserLanguagePreference().then(loc => {
        handleChangeLanguage(loc);
        window.location.href = redirectPath;
        //changeLanguage(loc, redirectPath);
      });

      return;
    }
    scheduleRenewal();
  });
}

/**
 * Maybe in the future figure out default locale from browser or something. 
 * For now just returns en string statically.
 */
function getDefaultLocale() {
  return 'en';
}

export function checkLanguageToken () {
  // first check if one has been set
  let languageSession = getLanguageSession();
  

  if (isLoggedIn()) {
    i18n.locale = languageSession;
    getUserLanguagePreference().then(loc => {
      setLanguageSession(loc, true);
      i18n.locale = loc;
      if (languageSession !== loc) {
        changeLanguage(loc);
      }
    });
  } else {
    handleChangeLanguage(getDefaultLocale());
    eventBus.$emit('update_language_list', i18n.locale);
  }
}

function checkBrowserLanguage () {
  // if the browser can give us a hint we can use that to set the language
  let browserLocale = navigator.language;
  let detected;
  SUPPORTED_LANGUAGES.forEach(lang => {
    if (browserLocale.includes(lang.locale)) {
      detected = lang.locale;
    }
  })
  return detected;
}

function setLanguageSession (loc) {
  localStorage.setItem(LANGUAGE_TOKEN_KEY, loc);
}

export function getLanguageSession () {
  return localStorage.getItem(LANGUAGE_TOKEN_KEY);
}

export function handleChangeLanguage (newLoc) {
  setLanguageSession(newLoc);
  changeLanguage(newLoc, null);
}

//global state to fire handleAuthentication once. Its being included in 2 different bundles.
//window.handleAuth = window.handleAuth ? window.handleAuth : false;

// document.addEventListener('DOMContentLoaded',
//   // window.addEventListener('load',
//   function () {
    
//     // console.log('loading page',  document.readyState, document.readyState)
//     if (!window.handleAuth) {
//       handleAuthentication();
//       window.handleAuth = true;
//       console.log('in user-auth0 is DOM loaded');
//     }
//   }, { once: true });

// renewal works for password and user name
// for social providers: please set up the API key: https://auth0.com/docs/identityproviders
