var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "iconButton link", on: { click: _vm.close } }, [
        _c("span", { staticClass: "ion-close" })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding-m" },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("createVisitForm-pleaseSelectLocation")))
          ]),
          _vm._v(" "),
          _c("multiselect", {
            staticClass: "input",
            attrs: {
              selectAllLabel: _vm.$t("common-selectAll"),
              options: _vm.locationList,
              multiple: false,
              "close-on-select": true,
              selectLabel: "",
              deselectLabel: " ",
              placeholder: _vm.$t("createVisitForm-selectLocation"),
              label: "locationName",
              "track-by": "id"
            },
            model: {
              value: _vm.createVisitLocation,
              callback: function($$v) {
                _vm.createVisitLocation = $$v
              },
              expression: "createVisitLocation"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.createVisitLocation
        ? _c("visit-form", {
            attrs: {
              "is-editable": true,
              "organization-id": _vm.organizationId,
              "location-label": _vm.createVisitLocation.locationName,
              "creating-visit": true,
              "location-id": _vm.createVisitLocation.locationId
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }