var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { ref: _vm.ID, staticClass: "relative", attrs: { id: _vm.ID } },
    [
      _c("thead", [
        _c("tr", { staticClass: "w-full" }, [
          _c("th", { staticClass: "w-1/2 p-0 px-1 sticky top-0 bg-white" }, [
            _vm._v(_vm._s(_vm.$tc("common-species", 1)) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.commonName,
                  expression: "filter.commonName"
                }
              ],
              domProps: { value: _vm.filter.commonName },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.filter, "commonName", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "w-1/4 p-0 px-1 sticky top-0 bg-white" }, [
            _vm._v(_vm._s(_vm.$t("common-class")) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.className,
                  expression: "filter.className"
                }
              ],
              domProps: { value: _vm.filter.className },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.filter, "className", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "w-1/4 p-0 px-1 sticky top-0 bg-white" }, [
            _vm._v(_vm._s(_vm.$t("common-order")) + " "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter.order,
                  expression: "filter.order"
                }
              ],
              domProps: { value: _vm.filter.order },
              on: {
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.filter, "order", $event.target.value)
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        {
          attrs: { onmousedown: "return false", onselectstart: "return false" }
        },
        [
          _vm._l(_vm.mutableSpecies, function(specie, index) {
            return [
              _c(
                "tr",
                {
                  key: index,
                  class: {
                    exists: specie.exists,
                    hidden: _vm.isRowHidden(
                      specie.commonName,
                      specie.className,
                      specie.order
                    ),
                    "!border-b !border-gray-300": true,
                    "cursor-pointer": _vm.selectable && !specie.exists,
                    "bg-gray-300 text-gray-400": specie.exists,
                    "selected bg-blue-100": _vm.selectedSpecies.get(specie.id)
                  },
                  attrs: { "data-id": specie.id },
                  on: {
                    mouseover: function($event) {
                      return _vm.mouseOver(specie)
                    },
                    click: function($event) {
                      return _vm.addToList(specie)
                    }
                  }
                },
                [
                  _c(
                    "td",
                    { class: { "p-0 px-1 w-1/2 overflow-ellipsis": true } },
                    [
                      _c("div", { staticClass: "flex items-center w-full" }, [
                        specie.exists
                          ? _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.$t(
                                    "This species cannot be removed because it is being used in the project."
                                  ),
                                  expression:
                                    "$t('This species cannot be removed because it is being used in the project.')"
                                }
                              ],
                              staticClass:
                                "fas fa-lock mr-1 text-xs flex-initial"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: { hidden: _vm.showScientificName } },
                          [_vm._v(_vm._s(specie.commonName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: { hidden: !_vm.showScientificName } },
                          [_vm._v(_vm._s(specie.scientificName))]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "p-0 px-1 w-1/4 overflow-ellipsis" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(specie.className) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "p-0 px-1 w-1/4 overflow-ellipsis" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(specie.order) +
                          "\n                "
                      )
                    ]
                  )
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }