<template>
    <div class="row">
            <div v-show="visitPhotosLoading" style="text-align: center; margin-top:250px;">
                <pulse-loader :loading="true" color="#C94412" size="20px" :width="400" :height="400"></pulse-loader>
                <div> {{$t('visitLocationPhotos-loading')}} </div>
            </div>
            <template v-if="!visitPhotosLoading && locationId && locationVisitInfo">
                <div v-for="visit in locationVisitInfo" v-bind:key="'visit' + visit.visit.id">
                    <h4 class="row site-photo-date">{{visit.visit.date}}
                      <a v-if="allVisitsInfo" @click="seeVisitInfo(visit.visit.id)">
                        <span class="link ion-eye"></span>
                        </a></h4>
                    <div v-if="visit.visitImages.length === 0">{{$t('visitLocationPhotos-noImages')}}</div>
                    <template v-for="(img, index) in visit.visitImages">
                      <div class="col s3 xl3 site-photo" v-bind:key="'img' + visit.visit.id + '-' + img.id">
                          <div class="imgWrap" :id="index" @click.stop.prevent="showLightbox(img.largeStoragePath)">
                              <img :src="getImageSrc(false, img)" :id="'img' + img.id" @error="imgUrlAlt($event, img)"/>

                              <div class="imgOverlay">
                                  <div class="magnify" >
                                      <span class="ion-android-search exclude" ></span>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </template>
                </div>
            <!-- popup and modal boxes -->
            <lightbox id="mylightbox" ref="lightbox"
            v-bind:images="lightboxImages"
            :timeoutDuration="10000" v-bind:can-download="true"></lightbox>

            <!-- visit info for landowner reports only -->
            <modal name="reportVisitInfo"
            :scrollable="true"   :adaptive="true"
            transition="nice-modal-fade"
            classes="modal-form"
            draggable=".drag-handle"
            :delay="100"
            :width = "1000"
            height = "80%"
            :minWidth="200"
            :minHeight="200"
            :clickToClose = "false">
              <visit-form v-if="selectedReportVisitDetails" v-bind:is-editable="false" v-bind:init-visit-row="selectedReportVisitDetails"
              v-bind:options="options" v-bind:location-label="selectedReportVisitDetails.date" v-bind:location-id="selectedReportVisitDetails.locationId"
              v-bind:hide-visit-photos="true"
              />
        </modal>
      </template>
    </div>
</template>

<script>
/*
this component shows the location visit photos on the recording, tagging, and survey pages
*/
import Lightbox from '@/components/utils/lightbox.vue';
import {API_URL} from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'; // spinner for loading
import LocationSingleVisitFormVue from '../organization/location-visit/LocationSingleVisitForm.vue';
import { eventBus } from '@/lib/eventbus';

export default {
  name: 'location-visit-photos-page',
  props: ['locationId', 'projectId', 'uuid', 'allVisitsInfo', 'options'], // uuid, allvisitsinfo, options are from landowner reports
  components: {
    'lightbox': Lightbox,
    'pulse-loader': PulseLoader,
    'visit-form': LocationSingleVisitFormVue
  },
  created () {
    this.getLocationPhotoInfo();
    eventBus.$on('close-single-visit-form', () => {
      // only for landowner report
      this.$modal.hide('reportVisitInfo');
    });
  },
  methods: {
    getLocationPhotoInfo () {
      this.visitPhotosLoading = true;
      let url = this.getLocationPhotosUrl + this.locationId;
      url += this.uuid ? '&uuid=' + this.uuid : '';
      this.$http.get(url).then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.locationVisitInfo = response.body;
          this.visitPhotosLoading = false;
          // populate lightbox
          let imgs = [];
          this.locationVisitInfo.forEach((visit) => {
            imgs = imgs.concat(visit.visitImages);
          });
          this.populateLightBoxImages(imgs);
        }
      }, (err) => {
      // error callback
        this.error = err;
        // return;
      });
    },
    /*  getPermissionInfo () {
      this.$http.get(this.getPermissionInfoURL + this.projectId).then((response) => {
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.isAdmin = response.body.isAdmin;
          this.hasTaskAccess = response.body.hasTaskAccess;
          this.isUserProjectMember = response.body.isUserProjectMember;
          console.log('isadmin: ', this.isAdmin, ' isprojectmember: ', this.isUserProjectMember, ' taskaccess:', this.hasTaskAccess);
        }
      }, (err) => {
      // error callback
        this.error = err;
        // return;
      });
    }, */
    /* when image load error use place holder image */
    imgUrlAlt (event, img) {
      // if the thumbnail hasnt been created on s3, use original img instead
      if (this.getImageSrc(false, img) === event.target.src) {
        event.target.src = this.getImageSrc(true, img);
        return;
      }
      const placeHolderImageUrl = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      if (placeHolderImageUrl !== event.target.src) {
        event.target.src = '//www.wildtrax.ca/.resources/wildtrax-content/webresources/img/placeholder.png';
      }
    },
    getImageSrc (isLarge, img) {
      if (img != null) {
        return 'https://' + img.storage.camBucket + '.s3-accelerate.amazonaws.com/site-photos/' + img.uuid + '/' + (isLarge ? img.storage.imagePrefix : img.storage.thumbNailPrefix) + '/' + img.id + '.' + img.extension;
      }
    },
    showLightbox (imgUrl) {
      this.$refs.lightbox.show(imgUrl);
    },
    populateLightBoxImages (imgs) {
      let lbImages = [];
      this.lightboxImages = [];
      if (!imgs || imgs.length === 0) {
        return;
      }
      imgs.forEach((img, index) => {
        lbImages.push({
          ...img,
          'bucket':img.storage.camBucket,
          'name': this.getImageSrc(true, img),
          'alt': this.$t('common-imgNumber', {num: (index + 1), total: imgs.length}),
          'id': 'image' + index,
          'extension': img.extension,
          'fileName': img.fileName
        });
      });
      this.lightboxImages = lbImages;
    },
    seeVisitInfo (id) {
      for (let i = 0; i < this.allVisitsInfo.results.length; i++) {
        if (this.allVisitsInfo.results[i].id === id) {
          this.selectedReportVisitDetails = this.allVisitsInfo.results[i];
          break;
        }
      }
      // must add type text to the visit's landfeatures
      // find land feature type txt
      (this.selectedReportVisitDetails.landFeatures || []).forEach(f => {
        f.type = (this.options.landFeature.find(l => l.landFeatureId === f.landFeatureId) || {}).type;
      });
      // show user details in popup
      this.$modal.show('reportVisitInfo');
    }
  },
  data () {
    return {
      getLocationPhotosUrl: API_URL + 'get-location-visit-image-by-location?locationId=',
      locationVisitInfo: [],
      visitPhotosLoading: false,
      lightboxImages: [],
      selectedReportVisitDetails: null
    //  isUserProjectMember: null,
    //  isAdmin: null,
    //  hasTaskAccess: null

    }
  }
}
</script>
<style scoped>
.imgWrap .imgOverlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(50, 50, 50, 0.4);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 2.3em;
    cursor: pointer;
}

.imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
    transition-delay: 0.8s;

}
.imgWrap:hover .imgOverlay {
    opacity: 1;
    display:flex;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
}
.imgWrap .imgOverlay{
    opacity: 0;
    display:flex;
}
.imgWrap .imgOverlay .magnify{
    font-size: 1px;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.5s!important;
}
.imgWrap:hover .imgOverlay span{
    font-size: 2.3rem;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    transition-delay: 0.8s!important;
}

.imgOverlay .magnify {
    color: #fff;
    display: inline-block;
    padding: 4px 6px;
    cursor: pointer;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
}

.imgWrap{
  width: 100%;
  position: relative;
  display: inline-block;
  float: left;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.imgWrap img{
  width: 100%;
  display: inline-block;
  float: left;
}

  .site-photo{
    margin-bottom: 20px;
  }

  .site-photo-date{
    clear: both;
  }
</style>
