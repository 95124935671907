var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("header", { staticClass: "mb-5 pt-10 z-10 relative" }, [
        _c("div", { staticClass: "h-24" }, [
          _c(
            "nav",
            { staticClass: "headerNav container overflow boxed" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "logIn mainNav", attrs: { id: "userInfo" } },
                [
                  _c("span", { staticClass: "logininfo user-login" }, [
                    _c(
                      "li",
                      { staticClass: "profile-area" },
                      [
                        _vm.loggedIn && _vm.profile
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "!text-burntOrange navigation-mainLink",
                                attrs: { to: "/home/user-settings.html" }
                              },
                              [
                                _c("i", { staticClass: "fa fa-user text-sm" }),
                                _vm._v(" " + _vm._s(_vm.profile.name))
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("li", { staticClass: "profile-area" }, [
                      _vm.loggedIn
                        ? _c(
                            "a",
                            {
                              staticClass: "!text-burntOrange",
                              on: { click: _vm.logout }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-right-from-bracket text-sm"
                              }),
                              _vm._v(
                                " \n                  " +
                                  _vm._s(_vm.$t("signIn-logout")) +
                                  "\n                "
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "!text-burntOrange",
                              on: { click: _vm.login }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-right-to-bracket text-sm"
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("signIn-login")) +
                                  "\n                "
                              )
                            ]
                          )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "slide-fade" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.mobileNavVisible || _vm.mobileNavOpen,
                        expression: "!mobileNavVisible || mobileNavOpen"
                      }
                    ],
                    staticClass: "mobileWrap",
                    style: _vm.mobileNavStyles
                  },
                  [
                    _c("ul", { staticClass: "mainNav" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://wildtrax.ca/resources/user-guide/",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Guide")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/data-discover.html" } },
                            [_vm._v(_vm._s(_vm.$t("dataDiscover-exploreData")))]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.loggedIn
                        ? _c("li", { staticClass: "hasChildren abmi" }, [
                            _c("div", {
                              staticClass: "abmi sub-toggle",
                              on: { click: _vm.subMenuClick }
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                class: {
                                  "navigation-mainLink ": true,
                                  "border-burntOrange border !text-burntOrange hover:!bg-burntOrange hover:!text-white": !_vm.mobileNavVisible
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("My Data")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "sub-menu-wrap",
                                style: _vm.subMenuNavStyles,
                                attrs: { "data-v-2b6409dc": "" }
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "submenu",
                                    attrs: { "data-v-2b6409dc": "" }
                                  },
                                  [
                                    _c(
                                      "li",
                                      { on: { click: _vm.activateNav } },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: "/home/organization-list.html"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("org-myOrganizations")
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { on: { click: _vm.activateNav } },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: { to: "/home/projects.html" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("My Projects"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      { on: { click: _vm.activateNav } },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to:
                                                "/home/projects/data-download.html"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Data Downloads"))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "nav-activator hide-on-large-only": true,
                    open: _vm.mobileNavOpen
                  },
                  on: { click: _vm.activateNav }
                },
                [
                  _c(
                    "button",
                    {
                      class: {
                        "hamburger hamburger--spin": true,
                        "is-active": _vm.mobileNavOpen
                      },
                      attrs: { type: "button" }
                    },
                    [_vm._m(1)]
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("footer", {}, [_vm._m(2)])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "headerLogo" }, [
      _c("a", {
        staticClass: "logo",
        attrs: { href: "https://wildtrax.ca", target: "_blank" }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "hamburger-box" }, [
      _c("span", { staticClass: "hamburger-inner" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container large" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 m4 l2" }, [
          _c("ul", { staticClass: "footerNav" }, [
            _c("li", { staticClass: "hasChildren" }, [
              _c("div", { staticClass: "sub-toggle" }),
              _vm._v(" "),
              _c("div", { staticClass: "sub-menu-wrap" }, [
                _c("ul", { staticClass: "sub-menu" })
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "hasChildren" }, [
              _c("div", { staticClass: "sub-toggle" }),
              _vm._v(" "),
              _c("div", { staticClass: "sub-menu-wrap" }, [
                _c("ul", { staticClass: "sub-menu" })
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col s12 m8 l6 contactInfo" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s12 m6 l6 xl5" }, [
              _c("div", { staticClass: "contentRichText richtext" }, [
                _c("p", [
                  _vm._v("1-107 Centennial Centre for"),
                  _c("br"),
                  _vm._v("\n  Interdisciplinary Studies (CCIS)"),
                  _c("br"),
                  _vm._v("\n  University of Alberta, Edmonton,"),
                  _c("br"),
                  _vm._v("\n  AB, Canada T6G 2E9")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col s12 m6 l6 xl7" }, [
              _c("div", { staticClass: "contentRichText richtext" }, [
                _c("p", [
                  _c("strong", [_vm._v("Telephone:")]),
                  _vm._v(" 780.248.1592"),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Fax:")]),
                  _vm._v(" 780.492.7635"),
                  _c("br"),
                  _vm._v("\n  info@wildtrax.ca")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col s12 m12 l4" }, [
          _c("p", { staticClass: "learnMoreAbmi" }, [
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://us4.campaign-archive.com/home/?u=de3236a0b48dda3b348d3943b&id=ebc7168db0",
                  target: "_blank"
                }
              },
              [_vm._v("Archive")]
            ),
            _vm._v(" | "),
            _c("a", { attrs: { href: "//abmi.ca" } }, [
              _vm._v("Learn more about ABMI »")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col s12 m12 l12 copyright1" }, [
          _c("hr"),
          _vm._v(" "),
          _c("span", [
            _c(
              "a",
              { staticClass: "hide", attrs: { href: "/home/terms-of-use" } },
              [_vm._v("\n         Privacy Policy and Terms \n  ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "hide", attrs: { href: "/home/terms-of-use" } },
              [_vm._v("\n         | Privacy Policy and Terms \n  ")]
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }