<template>
    <div>
        <div @click="close" class="iconButton link">
            <span class="ion-close"></span>
        </div>
    <div class="modal-header drag-handle">
        <h5>{{$t('autogenVisitsForm-title')}}</h5>
    </div>
    <div class="col s12 panel padded40 dataGroup autogen-visit">
        <div class="flex-row">
          <span @click="showSettings = !showSettings" class="link" :class="{'ion-arrow-expand': !showSettings, 'ion-arrow-shrink': showSettings}">
            {{showSettings ? $t('autogenVisitsForm-hideSettings') : $t('autogenVisitsForm-showSettings')}}
          </span>
        </div>
        <div v-show="showSettings" id="autogenForm" class="boxed container-fluid padded20">
        <div clas="row">
          {{$t('autogenVisitsForm-description')}}
          </div>
        <div class="row">
          <div class="col s12 m6 l4">
            <label>{{$t('autogenVisitsForm-fields.gapDays')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.gapDays"></span>
            <input v-model.number="autogenInputs.gapDays" step="1" min="1" max="365" type="number" class="validate" required>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input id="autogen-checkEquipment" v-model.number="autogenInputs.checkEquipment" type="checkbox" class="exclusive checkbox" required>
              <span>
                <span class="control-label col-md-2 col-sm-3" for="autogen-checkEquipment">{{$t('autogenVisitsForm-fields.checkEquipment')}} </span>
                <span class="info-icon ion-information-circled" v-tooltip="tooltips.checkEquipment"></span>
              </span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input id="autogen-createNewRecords" v-model.number="autogenInputs.createNewRecords" type="checkbox" class="exclusive checkbox" required>
              <span>
                <span class="control-label col-md-2 col-sm-3" for="autogen-createNewRecords">{{$t('autogenVisitsForm-fields.createNew')}} </span>
                <span class="info-icon ion-information-circled" v-tooltip="tooltips.createNewRecords"></span>
              </span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
            <input id="autogen-correctBadDates" v-model.number="autogenInputs.correctBadDates" type="checkbox" class="exclusive checkbox" required>
              <span>
                <span class="control-label col-md-2 col-sm-3" for="autogen-correctBadDates">{{$t('autogenVisitsForm-fields.alterIncorrectDates')}} </span>
                <span class="info-icon ion-information-circled" v-tooltip="tooltips.correctBadDates"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-border btn-success modal-action modal-close" @click="submitInputs()">{{$t('autogenVisitsForm-findButton')}}</button>
        </div>
        </div>
        <generate-and-select-table v-if="showTable" v-bind:organization-id="organizationId" v-bind:url="autogenUrl"></generate-and-select-table>
    </div>
    </div>
</template>

<script>
/* this component is the form part of the autogenerate and select form. Generates a table and allows the user to choose a selection of items to act on
*/

import { eventBus } from '@/lib/eventbus';
import { API_URL } from '@/lib/common';
import AutogenerateVisitsTable from './AutogenerateVisitsTable';
import FormMixin from '@/components/common/FormMixin.js';

export default {
  name: 'generate-and-select-form',
  components: {
    'generate-and-select-table': AutogenerateVisitsTable
  },
  props: ['organizationId'],
  mixins: [FormMixin],
  created () {
  },
  methods: {
    close () {
      this.createVisitLocation = null;
      eventBus.$emit('close-autogenerate-visits-form');
      this.$modal.hide('autogenerate-visits-form');
    },
    submitInputs () {
      // update url for the table
      this.showTable = false;
      this.autogenUrl = API_URL + 'get-location-visit-autogen-qaqc-data?organizationId=' + this.organizationId + '&gapDays=' + this.autogenInputs.gapDays +
      '&checkEquipment=' + this.autogenInputs.checkEquipment + '&createNewRecords=' + this.autogenInputs.createNewRecords + '&correctBadDates=' + this.autogenInputs.correctBadDates;
      this.showTable = true;
      this.showSettings = false;
    //  eventBus.$emit('refresh-autogen-table');
    }
  },
  data () {
    return {
      autogenUrl: '',
      showTable: false,
      showSettings: true,
      autogenInputs: {
        organizationId: null,
        gapDays: 45,
        checkEquipment: true,
        createNewRecords: true,
        correctBadDates: true
      },
      tooltips: {
        gapDays: this.$t('autogenVisitsForm-tooltips.gapDays'),
        checkEquipment: this.$t('autogenVisitsForm-tooltips.checkEquipment'),
        createNewRecords: this.$t('autogenVisitsForm-tooltips.createNewRecords'),
        correctBadDates: this.$t('autogenVisitsForm-tooltips.correctBadDates')
      }
    }
  }
}
</script>
<style scoped>
/*
.autogen-visit {
  font-size: 0.8em;
}
*/
.hide{
  display:none;
}

span.link {
  color: #C94412;
  font-size: 17px;
}

.flex-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#autogenForm{
  border: 1px solid #d89b9b;
  margin-bottom: 20px;
}

</style>
