var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PCTable overflow-scroll" },
    [
      _vm.error != null
        ? _c("div", { staticClass: "error" }, [
            _vm._v("\n  " + _vm._s(_vm.error) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableReady === false,
              expression: "tableReady === false"
            }
          ],
          staticClass: "pc-loader"
        },
        [
          _c("pulse-loader", {
            attrs: {
              loading: true,
              color: "#C94412",
              size: "20px",
              width: 400,
              height: 400
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableReady,
              expression: "tableReady"
            }
          ]
        },
        [
          _c(
            "tr",
            { staticClass: "row-seperator" },
            [
              _vm._l(_vm.headings, function(heading) {
                return [
                  _c("th", { key: heading.id, class: heading.class }, [
                    _vm._v(_vm._s(heading.heading))
                  ])
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm._l(_vm.tableData, function(row) {
            return _c(
              "tr",
              { key: row.id },
              [
                row.speciesInfo != null
                  ? _c(
                      "td",
                      {
                        key: row.id + "-" + row.speciesInfo.name,
                        staticClass: "row-seperator",
                        attrs: { rowspan: _vm.tableMap.get(row.code).length }
                      },
                      [
                        _c("div", { staticClass: "species-info-container" }, [
                          _c("img", {
                            staticClass: "round-img flex-initial",
                            attrs: { src: row.speciesInfo.icon },
                            on: {
                              error: function($event) {
                                return _vm.imgUrlAlt(
                                  $event,
                                  row.speciesInfo.icon
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-wrapper flex-1" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.capitalize(_vm.$tc("common-code", 1))
                                ) +
                                  ": " +
                                  _vm._s(row.speciesCode)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "english-name" }, [
                              _vm._v(_vm._s(row.speciesInfo.commonName))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "italic" }, [
                              _vm._v(
                                _vm._s(
                                  row.speciesInfo.scientificName
                                    .charAt(0)
                                    .toUpperCase() +
                                    row.speciesInfo.scientificName.slice(1)
                                )
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    key: row.id + "-" + row.distance.id,
                    class: {
                      "row-seperator":
                        row.subRowNum ===
                        _vm.uniqueSpecies.get(row.code).rowCount
                    },
                    attrs: { id: row.distance.id }
                  },
                  [_vm._v(_vm._s(row.distance.typeLong))]
                ),
                _vm._v(" "),
                _vm._l(_vm.intervals, function(interval, index) {
                  return [
                    row["i" + index].abundance != null
                      ? _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: row["i" + index].comments,
                                expression: "row['i' + index].comments"
                              }
                            ],
                            key: row.id + "-i" + index,
                            class: {
                              "cell-has-tooltip":
                                row["i" + index].comments != null,
                              "row-seperator":
                                row.subRowNum ===
                                _vm.uniqueSpecies.get(row.code).rowCount
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                class: {
                                  "cell-comment":
                                    row["i" + index].comments != null
                                }
                              },
                              [_vm._v(_vm._s(row["i" + index].abundance))]
                            )
                          ]
                        )
                      : _c(
                          "td",
                          {
                            key: row.id + "-i" + index,
                            class: {
                              "row-seperator":
                                row.subRowNum ===
                                _vm.uniqueSpecies.get(row.code).rowCount
                            }
                          },
                          [_vm._v(_vm._s(row["i" + index].abundance))]
                        )
                  ]
                }),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    class: {
                      "row-seperator":
                        row.subRowNum ===
                        _vm.uniqueSpecies.get(row.code).rowCount
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(row.isSeen))])]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    class: {
                      "row-seperator":
                        row.subRowNum ===
                        _vm.uniqueSpecies.get(row.code).rowCount
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(row.isHeard))])]
                )
              ],
              2
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }