<template>
  <div id="locationModal" >
    <div v-if="location != null">
      <ul class="tabs drag-handle">
        <li class="tab" @click="showTab='main'">
          <a :class="{active: showTab == 'main'}"><template v-if="locationId ==null">{{$t('locationForm-create')}}</template><template v-else>{{$t('locationForm-update')}}</template> <template v-if="hasFormModified">(* {{$t('common-unsaved')}})</template></a></li>
        <li  v-if="location && location.id != null && location.latitude && location.longitude && !isNaN(location.latitude) && !isNaN(location.longitude)" @click="showTab='map'" class="tab"><a :class="{active: showTab =='map'}">{{$t('common-map')}}</a></li>
        <div @click="close" class="iconButton link">
          <span class="ion-close"></span>
        </div>
      </ul>
      <div class="modal-content padded40 main border-t border-gray-300 !pt-10" v-show="showTab =='main'">
        <div class="row">

          <div class="col s12 m4 l4">
            <label for="form_location_name">{{$t('locationForm-fields.name')}}</label>
            <input @input="hasFormModified=true" id="form_location_name" :disabled="!isEditable" v-model="location.name" type="text" class="validate" required :placeholder="$t('locationForm-fields.name')">
          </div>
          <div class="col s12 m4 l4">
            <label for="formshortCode">{{$t('common-latitude')}}<span class="info-icon ion-information-circled" v-tooltip="tooltips.latitude" /></label>
            <input @input="hasFormModified=true" id="formshortCode" :disabled="!isEditable" v-model="location.latitude" type="text" class="validate" :placeholder="$t('locationForm-fields.latitudeDegrees')">
          </div>
          <div class="col s12 m4 l4">
            <label for="formshortCode">{{$t('common-longitude')}}<span class="info-icon ion-information-circled" v-tooltip="tooltips.longitude" /></label>
            <input @input="hasFormModified=true" id="formshortCode" :disabled="!isEditable" v-model="location.longitude" type="text" class="validate" :placeholder="$t('locationForm-fields.longitudeDegrees')">
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6 l6">
              <label for="formElevation">{{$t('locationForm-fields.elevation')}}</label>
              <input @input="hasFormModified=true" id="formElevation" :disabled="!isEditable" v-model="location.elevationMeters" type="text" class="validate">
            </div>
          <div class="col s12 m6 l6">
              <label for="selectVisibility">{{$t('locationForm-fields.visibility')}}</label>
               <span class="info-icon ion-information-circled" v-tooltip="tooltips.visibility" />
              <div class="vue-select-wrapper">
                <!-- v-model="location.visibility" use :value instead of v-model to track old and new vlaues -->
                <select @input="hasFormModified=true" id="selectVisibility" :disabled="!isEditable" v-model="location.visibilityId">
                  <option v-for="(option, index) in options.visibility" :key="index" :value="option.id">{{option.type}}</option>
                </select>
              </div>
            </div>
          <!-- <div class="col s12 m6 l4">
            <label>{{$t('locationForm-fields.existing')}}
              <span class="info-icon ion-information-circled" v-tooltip="tooltips.sensor" />
            </label>
            <div>
            <label>{{$t('common-camera')}}
              <input type="checkbox" disabled v-model="location.cameraExists">
              <span/>
            </label>
            <label>{{$t('common-aru')}}
              <input type="checkbox" disabled v-model="location.aruExists">
              <span/>
            </label>
            </div>
          </div> -->
        </div>

        <!-- <div v-if="isEditable" class="row col s12">
          <div class="mini-text">{{$t('locationForm-fields.orgDefaultLocationBuffer')}} <span class="no-split" v-if="organizationObj.defaultIsTrueCoordinates">{{$t('locationForm-fields.trueLocation')}}</span><span class="no-split" v-else>{{$t('locationForm-fields.bufferedLocation')}}</span></div>
          <div class="mini-text">{{$t('locationForm-fields.orgDefaultBufferRadius')}} {{organizationObj.defaultBufferRadius}}{{$t('commonUnits-metre')}}</div>
        </div> -->

          <div class="row">
          <div class="col s12 m6 l4">
            <label>{{$t('locationForm-fields.locationBuffering')}} <span class="info-icon ion-information-circled" v-tooltip="tooltips.locationBuffer" /></label>
                <label class="form-option">
                <input @input="hasFormModified=true" type=radio id="trueLocation" name="bufferLocation" :disabled="!isEditable" :value="true" v-model="location.isTrueCoordinates"><span>{{$t('locationForm-fields.trueLocation')}}</span>
              </label>
              <label class="form-option">
                <input @input="hasFormModified=true" type="radio" id="bufferedLocation" name="bufferLocation" :disabled="!isEditable" :value="false" v-model="location.isTrueCoordinates"><span>{{$t('locationForm-fields.bufferedLocation')}}</span>
              </label>
          </div>

          <div class="col s12 m6 l8">
            <label>
              <template>{{$t(location.isTrueCoordinates?'locationForm-fields.defaultBufferRadius':'locationForm-fields.amountBufferYouAdded')}}</template>
              <span class="info-icon ion-information-circled" v-tooltip="tooltips.userBuffer" />
            </label>
              <div class="vue-select-wrapper">
              <input @input="hasFormModified=true" :disabled="!isEditable" id="formshortCode" v-model="location.bufferRadius" type="text" class="validate">
              <!-- <select v-model="location.bufferRadius">
                <option v-for="(option, index) in options.bufferRadius" :key="index" :value="option.id">{{option.type}}</option>
              </select> -->
            </div>
          </div>
      </div>

      <div class="row">
          <div class="col s12 ">
            <label for="form_location_description" >{{$t('common-description')}}</label>
            <textarea @input="hasFormModified=true" :disabled="!isEditable" id="form_location_description" class="materialize-textarea" data-length="120" v-model="location.comments"></textarea>
            <span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
          </div>
      </div>
      <div class="row">
      <div class="col s12">
          <label for="form_location_report_link">{{$t('locationForm-fields.reportLink')}}
            <span class="info-icon ion-information-circled" v-tooltip="tooltips.reportLink" />
          </label>
          <input v-if="locationReportLink" id="form_location_report_link" type="text" v-model="locationReportLink" v-tooltip="{content: tooltips.copyTooltip, show: showLinkCopied}">
          <div class="row col s12" v-else>{{$t('locationForm-fields.reportLinksDisabled')}}</div>
      </div></div>
        <!-- bottom -->
        <div v-for="(error,index) in errors" v-bind:key="index" class="error"> {{error}} </div>
        <div class="modal-footer">
          <button v-if="isEditable" class="btn btn-success modal-trigger modal-action modal-close" id="form_locationSave" @click="saveLocation">
            <template>{{$t('common-save')}}</template>
          </button>
          <button class="btn btn-border modal-action modal-close" id="formcancelAdd" @click="close">{{$t('common-close')}}</button>
        </div>
      </div>
      <div class="modal-content padded60 border-t border-gray-300 !pt-10" v-show="showTab =='map'">
        <div class="row">
          <point-map v-if="location && location.id && location.latitude && location.longitude && !isNaN(location.latitude) && !isNaN(location.longitude)" v-bind:is-show="showTab =='map'" v-bind:latitude="parseFloat(location.latitude)" v-bind:longitude="parseFloat(location.longitude)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from '@/lib/eventbus';
import {API_URL, alertDialog, confirmDialog3Button} from '@/lib/common';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import SinglePointMap from '@/components/mapGL/SinglePointMap';
import FormMixin from '@/components/common/FormMixin.js';

export default {
  name: 'location-form',
  props: ['organizationId', 'organizationObj', 'locationId', 'options', 'isEditable'],
  components: { 'multiselect': Multiselect, 'point-map': SinglePointMap },
  mixins: [FormMixin],
  data () {
    return {
      url: API_URL + 'get-location-by-id',
      locationUpdateUrl: API_URL + 'insert-update-location',
      generateReportLinkUrl: API_URL + 'get-location-report-link?locationId=',
      // formName:'location',
      location: null,
      message: '',
      selectedUser: null,
      showTab: 'main',
      errors: [],
      recordName: this.$tc('common-location', 1).toLowerCase(),
      hasFormModified: false,
      locationReportStatic: 'https://www.wildtrax.ca/home/landowners/reports.html?UUID=',
      locationReportLink: null,
      showLinkCopied: false,
      tooltips: {
        sensor: this.$t('locationForm-tooltips.sensor'),
        latitude: this.$t('locationForm-tooltips.latitude'),
        longitude: this.$t('locationForm-tooltips.longitude'),
        userBuffer: this.$t('locationForm-tooltips.userBuffer'),
        visibility: this.$t('locationForm-tooltips.visibility'),
        reportLink: this.$t('locationForm-tooltips.reportLink'),
        wildtraxBuffer: this.$t('locationForm-tooltips.wildtraxBuffer'),
        locationBuffer: this.$t('locationForm-tooltips.locationBuffer'),
        copyTooltip: ''
      }
    }
  },
  created () {
    this.loadLocation(this.locationId);
    if (this.isEditable && this.locationId) {
      this.generateReportLink();
    }
  },
  watch: {
    locationId: function (newVal, oldVal) {
      if (newVal !== -1) {
        this.switchLocation();
      }
    }
  },
  methods: {
    switchLocation () {
      this.showTab = 'location';
      // console.log('locationId =', this.locationId, this.location.id)
      if (this.locationId !== null && this.locationId > 0) {
        if (this.locationId !== this.location.id) { // only clear messages when location id are different.
          this.errors = [];
        }
        this.loadLocation(this.locationId); // need to reload to get user lists
      } else {
        this.errors = [];
        this.initLocation();
      }
    },
    initLocation () {
      this.location = { organizationId: this.organizationId, visibilityId: 2, typeId: [], bufferRadius: this.organizationObj.defaultBufferRadius, isTrueCoordinates: this.organizationObj.defaultIsTrueCoordinates };
    },
    loadLocation (locationId) {
      if (locationId) { // get locationId from database if id is provided.
        this.$http.get(this.url,
          {params: {id: locationId}}).then(function (response) {
          if (response.body.error || response.data.error) {
            alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view);
            this.close();
          } else {
            this.location = response.data;
          }
        },
        function (error) {
          alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view + (error.body || ''));
          this.close();
        });
      } else {
        this.initLocation();
      }
    },
    close () {
      const self = this;
      const unsavedWarning = this.$t('locationForm-unsavedWarning');
      if (this.hasFormModified) {
        confirmDialog3Button(this.$modal, this.$t('common-unsavedChanges'), unsavedWarning,
          this.$t('common-save'), async function () {
            await self.saveLocation();
            self.errors = [];
            self.message = '';
            self.showTab = 'location';
            eventBus.$emit('close-location-form');
            self.$modal.hide('location');
          },
          this.$t('common-close'), function () {
            self.errors = [];
            self.message = '';
            self.showTab = 'location';
            eventBus.$emit('close-location-form');
            self.$modal.hide('location');
          },
          this.$t('common-cancel'));
      } else {
        this.errors = [];
        this.message = '';
        this.showTab = 'location';
        eventBus.$emit('close-location-form');
        this.$modal.hide('location');
      }
    },
    async saveLocation () {
      /* validation */
      this.errors = [];
      if (!this.location.name) {
        this.errors.push(this.$t('locationForm-validation.nameRequired'));
      } else {
        this.location.name = this.location.name.trim();
        if (!((/^[\w@_&#-:]+$/i).test(this.location.name))) {
          this.errors.push(this.$t('locationForm-validation.nameInvalid'));
        }
      }

      if (isNaN(this.location.longitude) || isNaN(this.location.latitude)) {
        this.errors.push(this.$t('locationForm-validation.latLongValidNumbers'));
      }
      if (parseFloat(this.location.longitude) && (parseFloat(this.location.longitude) > 180 || parseFloat(this.location.longitude) < -180)) {
        this.errors.push(this.$t('locationForm-validation.longitudeRange'));
      }
      if (parseFloat(this.location.latitude) && (parseFloat(this.location.latitude) > 90 || parseFloat(this.location.latitude) < -90)) {
        this.errors.push(this.$t('locationForm-validation.latitudeRange'));
      }
      if (this.location.elevationMeters != null && isNaN(this.location.elevationMeters)) {
        this.errors.push(this.$t('locationForm-validation.elevationValidNumber'));
      }
      if (!this.location.isTrueCoordinates && !this.location.bufferRadius) {
        this.errors.push(this.$t('locationForm-validation.bufferRadiusRequired'));
      }

      if (!this.location.isTrueCoordinates && this.location.bufferRadius <= 0) {
        this.errors.push(this.$t('locationForm-validation.bufferRadiusGreaterThanZero'));
      }

      if (this.errors.length > 0) {
        return;
      }
      const self = this;
      this.commonInsertUpdate(this.locationUpdateUrl, this.location, 'update', function (response) {
        self.location = response.data;
        self.hasFormModified = false;
        eventBus.$emit('reload-table');
        // eventBus.$emit('reload-location-form', response.data.id);
      });
    },
    generateReportLink () {
      this.$http.get(this.generateReportLinkUrl + this.locationId).then(response => {
        if (response.body.error || response.data.error) {
          alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view);
          this.close();
        } else {
          if (response.data.locationLinkCode) {
            this.locationReportLink = this.locationReportStatic + response.data.locationLinkCode;
          }
        }
      },
      function (error) {
        alertDialog(this.$modal, this.$tc('common-error', 1), this.commonMsg.deny.view + (error.body || ''));
        this.close();
      });
    },
    copyReportLink () {
      let input = document.getElementById('form_location_report_link');
      input.select();
      input.setSelectionRange(0, 99999); // for mobile
      document.execCommand('copy');
      this.showLinkCopied = true;
      this.tooltips.copyTooltip = this.$t('locationForm-tooltips.linkCopied');
      setTimeout(() => {
        this.showLinkCopied = false;
        this.tooltips.copyTooltip = null;
      }, 1500);
    }
  }
}
</script>
<style scoped>

input[type="radio"]+span,
input[type="checkbox"]+span{
  font-size: 0.8rem !important;
  padding-left: 26px !important;
}

.form-option{
  display:block;
}

.mini-text{
  text-transform: uppercase;
  letter-spacing:2px;
  font-weight:300;
  font-size: 0.7rem;
  color: #9e9e9e;
}

.no-split{
  white-space: nowrap;
}

</style>
