var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.formName,
        scrollable: true,
        adaptive: true,
        transition: "nice-modal-fade",
        classes: "modal-form",
        draggable: ".drag-handle",
        delay: 100,
        width: 1000,
        height: "80%",
        minWidth: 200,
        minHeight: 200,
        clickToClose: false
      }
    },
    [
      _c(
        "div",
        { staticClass: "iconButton link", on: { click: _vm.closeForm } },
        [_c("span", { staticClass: "ion-close" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-header drag-handle" }, [
        _c("h6", [_vm._v(_vm._s(_vm.$t("common-help")))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-scroller" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("ul", { staticClass: "vue collapsible getHelp" }, [
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showView = !_vm.showView
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("taggingHelp-taggingTabs"))),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showView,
                        "ion-arrow-up-b": !_vm.showView
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showView
                ? _c("div", { staticClass: "collapsible-body" }, [
                    _c("div", { staticClass: "w-full p-4" }, [
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-fullTaggingView")))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-fullTaggingDesc")))
                      ]),
                      _vm._v(" "),
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-seriesView")))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("taggingHelp-seriesDesc")) +
                            "\n                              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-fieldOfView")))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-fieldOfViewDesc")))
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showHelp = !_vm.showHelp
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("common-keyboardShortcuts")) + " "),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showHelp,
                        "ion-arrow-up-b": !_vm.showHelp
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showHelp
                ? _c("div", { staticClass: "collapsible-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col s12 m12" }, [
                        _c("div", { staticClass: "col s12 m6" }, [
                          _c("h6", [_vm._v(_vm._s(_vm.$t("onTaggingPage")))]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("common-left")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-leftDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(" " + _vm._s(_vm.$t("common-right")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-rightDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-CtrlA")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlADesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlQ")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlQDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-escape")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-escapeDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-space")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-spaceDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon double-line" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-shiftSpace")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-shiftSpaceDesc"))
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col s12 m6" }, [
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon double-line" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-shiftCtrl")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-shiftCtrlDesc"))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlE")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlEDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlS")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlSDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlB")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlBDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("h6", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-zoomedImageView"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("common-left")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-zoomLeftDesc")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(" " + _vm._s(_vm.$t("common-right")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-zoomRightDesc"))
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "keyIcon" }, [
                            _c("div", { staticClass: "icon" }, [
                              _vm._v(_vm._s(_vm.$t("taggingHelp-escape")))
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("taggingHelp-zoomEscapeDesc"))
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showMouse = !_vm.showMouse
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("taggingHelp-imageSelection"))),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showMouse,
                        "ion-arrow-up-b": !_vm.showMouse
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMouse,
                      expression: "showMouse"
                    }
                  ],
                  staticClass: "collapsible-body"
                },
                [
                  _c("div", { staticClass: "w-full p-4" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("taggingHelp-selectionMainInstructions")
                        ) + "   "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "keyIcon" }, [
                      _c("div", { staticClass: "big icon" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-mouseDrag")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-mouseDragDesc")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "keyIcon" }, [
                      _c("div", { staticClass: "big icon" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlMouseDrag")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-ctrlMouseDragDesc")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "keyIcon" }, [
                      _c("div", { staticClass: "big icon" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-singleLeftClick")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("taggingHelp-singleLeftClickDesc"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "keyIcon" }, [
                      _c("div", { staticClass: "big icon" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("taggingHelp-ctrlSingleLeftClick"))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("taggingHelp-ctrlSingleLeftClickDesc"))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "keyIcon" }, [
                      _c("div", { staticClass: "big icon" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-rightClick")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("taggingHelp-rightClickDesc")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "keyIcon" }, [
                      _c("div", { staticClass: "big icon" }, [
                        _c("span", [
                          _vm._v(
                            "$" + _vm._s(_vm.$t("taggingHelp-shiftSingleClick"))
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("taggingHelp-shiftSingleClickDesc")
                          )
                        }
                      })
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showLegend = !_vm.showLegend
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("common-legend"))),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showLegend,
                        "ion-arrow-up-b": !_vm.showLegend
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showLegend,
                      expression: "showLegend"
                    }
                  ],
                  staticClass: "collapsible-body"
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12 m12" }, [
                      _c("div", { staticClass: "col s12 m6" }, [
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            { staticClass: "legend-block legend-tagged" },
                            [_c("span", [_vm._v("2")])]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-tagged")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            { staticClass: "legend-block legend-none" },
                            [_c("span", [_vm._v("2")])]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.$t("None")) + " ")])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            { staticClass: "legend-block legend-untagged" },
                            [_c("span", [_vm._v("2")])]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-untagged")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            {
                              staticClass: "legend-block legend-selected white",
                              attrs: { sty: "" }
                            },
                            [_c("span", [_vm._v("2")])]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-selected")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            { staticClass: "legend-block legend-tagged" },
                            [
                              _c("div", { staticClass: "multipleSpecies" }),
                              _c("span", [_vm._v("2")])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-multipleSpecies"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            { staticClass: "legend-block legend-tagged" },
                            [
                              _c("div", { staticClass: "multipleCount" }),
                              _c("span", [_vm._v("2")])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-multipleIndividuals"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            { staticClass: "legend-block legend-tagged" },
                            [
                              _c("div", { staticClass: "outOfRange" }),
                              _c("span", [_vm._v("2")])
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-outOfRange")))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s12 m6" }, [
                        _c("div", { staticClass: "keyIcon" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "legend-block legend-tagged legend-needReview"
                            },
                            [_c("span", [_vm._v("2")])]
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("taggingHelp-imageNeedsReview"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h6", [
                          _vm._v(_vm._s(_vm.$t("taggingHelp-zoomedImageView")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon" }, [
                            _vm._v(_vm._s(_vm.$t("common-left")))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-zoomLeftDesc")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon" }, [
                            _vm._v(" " + _vm._s(_vm.$t("common-right")))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-zoomRightDesc")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon" }, [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-escape")))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("taggingHelp-zoomEscapeDesc")))
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("li", {}, [
              _c(
                "div",
                {
                  staticClass: "collapsible-header",
                  on: {
                    click: function($event) {
                      _vm.showIcon = !_vm.showIcon
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("taggingHelp-icons"))),
                    _c("span", {
                      class: {
                        "ion-arrow-down-b": _vm.showIcon,
                        "ion-arrow-up-b": !_vm.showIcon
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showIcon,
                      expression: "showIcon"
                    }
                  ],
                  staticClass: "collapsible-body"
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12 m12" }, [
                      _c("div", { staticClass: "col s12 m6" }, [
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon-block" }, [
                            _c("span", {
                              staticClass: "ion ion-ios-copy-outline link"
                            })
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("taggingHelp-duplicateTag"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon-block" }, [
                            _c("span", {
                              staticClass: "ion ion-ios-list-outline link"
                            })
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("taggingHelp-toggleCommentField"))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon-block" }, [
                            _c("span", {
                              staticClass: "ion ion-android-remove-circle"
                            })
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("taggingHelp-removeUnsavedTag"))
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col s12 m6" }, [
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon-block" }, [
                            _c(
                              "span",
                              { staticClass: "ion ion-android-delete" },
                              [
                                _c("span", {
                                  staticClass: "ion ion-android-delete link",
                                  staticStyle: { "font-size": "14px" },
                                  attrs: {
                                    title: _vm.$t("taggingHelp-deleteTag")
                                  }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("taggingHelp-batchRemoveTagsFromDB")
                                )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "keyIcon" }, [
                          _c("div", { staticClass: "icon-block" }, [
                            _c("span", {
                              staticClass: "ion ion-android-done-all"
                            })
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("taggingHelp-batchUpdateTagsDB"))
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }