var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visitPhotosLoading,
              expression: "visitPhotosLoading"
            }
          ],
          staticStyle: { "text-align": "center", "margin-top": "250px" }
        },
        [
          _c("pulse-loader", {
            attrs: {
              loading: true,
              color: "#C94412",
              size: "20px",
              width: 400,
              height: 400
            }
          }),
          _vm._v(" "),
          _c("div", [
            _vm._v(" " + _vm._s(_vm.$t("visitLocationPhotos-loading")) + " ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      !_vm.visitPhotosLoading && _vm.locationId && _vm.locationVisitInfo
        ? [
            _vm._l(_vm.locationVisitInfo, function(visit) {
              return _c(
                "div",
                { key: "visit" + visit.visit.id },
                [
                  _c("h4", { staticClass: "row site-photo-date" }, [
                    _vm._v(_vm._s(visit.visit.date) + "\n                  "),
                    _vm.allVisitsInfo
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.seeVisitInfo(visit.visit.id)
                              }
                            }
                          },
                          [_c("span", { staticClass: "link ion-eye" })]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  visit.visitImages.length === 0
                    ? _c("div", [
                        _vm._v(_vm._s(_vm.$t("visitLocationPhotos-noImages")))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(visit.visitImages, function(img, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: "img" + visit.visit.id + "-" + img.id,
                          staticClass: "col s3 xl3 site-photo"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "imgWrap",
                              attrs: { id: index },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.showLightbox(img.largeStoragePath)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImageSrc(false, img),
                                  id: "img" + img.id
                                },
                                on: {
                                  error: function($event) {
                                    return _vm.imgUrlAlt($event, img)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm._m(0, true)
                            ]
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            }),
            _vm._v(" "),
            _c("lightbox", {
              ref: "lightbox",
              attrs: {
                id: "mylightbox",
                images: _vm.lightboxImages,
                timeoutDuration: 10000,
                "can-download": true
              }
            }),
            _vm._v(" "),
            _c(
              "modal",
              {
                attrs: {
                  name: "reportVisitInfo",
                  scrollable: true,
                  adaptive: true,
                  transition: "nice-modal-fade",
                  classes: "modal-form",
                  draggable: ".drag-handle",
                  delay: 100,
                  width: 1000,
                  height: "80%",
                  minWidth: 200,
                  minHeight: 200,
                  clickToClose: false
                }
              },
              [
                _vm.selectedReportVisitDetails
                  ? _c("visit-form", {
                      attrs: {
                        "is-editable": false,
                        "init-visit-row": _vm.selectedReportVisitDetails,
                        options: _vm.options,
                        "location-label": _vm.selectedReportVisitDetails.date,
                        "location-id":
                          _vm.selectedReportVisitDetails.locationId,
                        "hide-visit-photos": true
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgOverlay" }, [
      _c("div", { staticClass: "magnify" }, [
        _c("span", { staticClass: "ion-android-search exclude" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }