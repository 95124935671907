var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container overflow vue", attrs: { id: "task-page" } },
    [
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.error.statusText) + "\n      "
                  )
                ])
              : _c("span", [
                  _vm._v("\n        " + _vm._s(_vm.error) + "\n      ")
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error == null
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col s12 m12",
                class: {
                  l8: _vm.showStats,
                  xl9: _vm.showStats,
                  l12: !_vm.showStats
                },
                attrs: { id: "listeningPanel" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "padded60 projectHeader !px-8 !pb-4",
                    class: { narrowPanel: _vm.showStats }
                  },
                  [
                    _vm.project.isUserProjectMember
                      ? _c("div", { staticClass: "toggleWrap" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", [
                      _c("a", { attrs: { href: _vm.breadcrumbHomeLink() } }, [
                        _vm._v(_vm._s(_vm.$t("common-home")))
                      ]),
                      _vm._v(" / "),
                      _c(
                        "a",
                        { attrs: { href: _vm.breadcrumbBaseLink() + "ARU" } },
                        [_vm._v(_vm._s(_vm.$t("common-aru")))]
                      ),
                      _vm._v(" /")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col s12 m12 l12 xl8" }, [
                        _vm.project.isAdmin
                          ? _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { title: _vm.$t("tasks-edit") },
                                on: {
                                  click: function($event) {
                                    return _vm.updateProject()
                                  }
                                }
                              },
                              [
                                _c("h1", { staticClass: "inline pb-0" }, [
                                  _vm._v(_vm._s(_vm.project.fullNm))
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "ion-edit edit-project-icon"
                                })
                              ]
                            )
                          : _c("h1", [_vm._v(_vm._s(_vm.project.fullNm))])
                      ]),
                      _vm._v(" "),
                      _vm.getOtherProjectUrl != null
                        ? _c(
                            "div",
                            { staticClass: "col s12 m12 l12 xl4" },
                            [
                              _c("auto-complete", {
                                attrs: {
                                  placeholder: _vm.$t("tasks-jump"),
                                  source: _vm.getOtherProjectUrl,
                                  initialValue: " ",
                                  initialDisplay: "",
                                  clearButtonIcon: "ion-close",
                                  name: "id",
                                  "results-display": "fullNm",
                                  "request-headers": _vm.authHeaders
                                },
                                on: { selected: _vm.redirectPage }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm.project.isAdmin
                        ? _c(
                            "span",
                            [
                              _c("span", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("tasks-projectStatus", {
                                      status: _vm.getProjectStatus()
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _vm.isPublished
                                ? [
                                    _c("span", {
                                      staticClass: "ion-android-lock red-font"
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.publishedTooltip,
                                          expression: "publishedTooltip"
                                        }
                                      ],
                                      staticClass:
                                        "info-icon ion-information-circled"
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.project.description &&
                    _vm.project.description.length >= _vm.descLimit
                      ? _c("p", { staticClass: "whitespace-pre-wrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.project.description.substring(
                                  0,
                                  _vm.descLength
                                )
                              )
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.descLength == _vm.descLimit,
                                  expression: "descLength==descLimit"
                                }
                              ]
                            },
                            [_vm._v("...")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "text-xs cursor-pointer",
                              on: { click: _vm.showMore }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("common-showMoreLess", {
                                    amount:
                                      _vm.descLength == _vm.descLimit
                                        ? _vm.$t("common-more")
                                        : _vm.$t("common-less")
                                  })
                                )
                              )
                            ]
                          )
                        ])
                      : _vm.project.description &&
                        _vm.project.description.length < _vm.descLimit
                      ? _c("p", { staticClass: "whitespace-pre-wrap" }, [
                          _vm._v(_vm._s(_vm.project.description))
                        ])
                      : _c("p", { staticClass: "text-red-400" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "You do not currently have any information that describes this project. Please add a description of methods, results or study design in order to inform users about your project."
                              )
                            )
                          )
                        ])
                  ]
                ),
                _vm._v(" "),
                _vm.projectId &&
                _vm.options != null &&
                _vm.allMethodList != null
                  ? _c("aru-batch-upload-form", {
                      attrs: {
                        "project-id": _vm.projectId,
                        "project-name": _vm.project.fullNm,
                        "organization-id": _vm.project.organizationId,
                        options: _vm.options,
                        "method-option": _vm.allMethodList
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("section", { staticClass: "tabbed" }, [
                  _c("ul", { staticClass: "tabs" }, [
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function($event) {
                            return _vm.clickTab("task")
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "!border-gray-400 border-2 border-b-0":
                                _vm.activeTab == "task",
                              disabledTab: !_vm.project.hasTaskAccess
                            }
                          },
                          [
                            _c("span", { staticClass: "ion-ios-mic" }),
                            _vm._v(" "),
                            _vm.project.isUserProjectMember
                              ? [_vm._v(_vm._s(_vm.$tc("common-task", 2)))]
                              : [_vm._v(_vm._s(_vm.$tc("common-task", 2)))]
                          ],
                          2
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function($event) {
                            return _vm.clickTab("species")
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "!border-gray-400 border-2 border-b-0":
                                _vm.activeTab == "species",
                              disabledTab: !_vm.project.hasTaskAccess
                            }
                          },
                          [
                            _c("span", { staticClass: "ion-ios-paw" }),
                            _vm._v(" "),
                            _vm.project.isUserProjectMember
                              ? [_vm._v(_vm._s(_vm.$tc("common-species", 2)))]
                              : [_vm._v(_vm._s(_vm.$tc("common-species", 2)))]
                          ],
                          2
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function($event) {
                            return _vm.clickTab("map")
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "!border-gray-400 border-2 border-b-0":
                                _vm.activeTab == "map",
                              disabledTab: !_vm.project.hasMapAccess
                            }
                          },
                          [
                            _c("span", { staticClass: "ion-map" }),
                            _vm._v(" " + _vm._s(_vm.$t("common-map")))
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function($event) {
                            return _vm.clickTab("files")
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "!border-gray-400 border-2 border-b-0":
                                _vm.activeTab == "files",
                              disabledTab: _vm.project.hasFileAccess
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-file" }),
                            _vm._v(" " + _vm._s(_vm.$t("Files")))
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "padded60 boxed dataGroup border-2 border-gray-400"
                    },
                    [
                      _vm.project.isAdmin != null &&
                      _vm.options != null &&
                      _vm.userList != null
                        ? _c("task-table", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeTab == "task",
                                expression: "activeTab=='task'"
                              }
                            ],
                            ref: "task",
                            attrs: {
                              "project-id": _vm.projectId,
                              "sensor-id": _vm.project.sensorId,
                              "is-manager": _vm.project.isAdmin,
                              project: _vm.project,
                              "is-user-project-member":
                                _vm.project.isUserProjectMember,
                              "filter-options": _vm.options,
                              "user-list": _vm.userList,
                              "is-published": _vm.isPublished,
                              "has-project-write-access":
                                _vm.project.hasProjectWriteAccess,
                              "project-name": _vm.project.fullNm,
                              "task-options": _vm.taskOptions,
                              "has-assigned-tasks": _vm.project.isAssignedTask
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.project.isAdmin != null &&
                      _vm.taskOptions != null &&
                      _vm.userList != null
                        ? _c("species-table", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeTab == "species",
                                expression: "activeTab=='species'"
                              }
                            ],
                            ref: "species-table",
                            attrs: {
                              "project-id": _vm.projectId,
                              "is-manager": _vm.project.isAdmin,
                              "task-options": _vm.taskOptions,
                              "user-list": _vm.userList,
                              statusId: _vm.project.status
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activeTab == "map" && _vm.projectId
                        ? _c("location-map", {
                            attrs: {
                              "map-type": "project",
                              "category-id": _vm.projectId
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("files", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTab == "files",
                            expression: "activeTab=='files'"
                          }
                        ],
                        attrs: {
                          projectId: _vm.projectId,
                          project: _vm.project,
                          "user-list": _vm.userList
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.showStats
              ? _c(
                  "div",
                  { staticClass: "col s12 m12 l4 xl3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("tasks-stats")))]),
                    _vm._v(" "),
                    _c("stats", {
                      attrs: {
                        url: _vm.statUrl,
                        customClass: "row",
                        customDivClass: "col s6 m4 l4",
                        "is-wrapped": true
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            name: "project",
            scrollable: true,
            adaptive: true,
            transition: "nice-modal-fade",
            classes: "modal-form scroll",
            draggable: "false",
            delay: 100,
            width: "99%",
            height: "808",
            minWidth: 400,
            minHeight: 540,
            clickToClose: false
          }
        },
        [
          _vm.projectId != null
            ? _c("project-form", {
                attrs: {
                  "project-id": _vm.projectId,
                  "active-sensor": _vm.project.sensorId,
                  options: _vm.projectFormOptions
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }