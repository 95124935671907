<template>
<div class="vue">
  <div v-if="error!=null">
    {{$t('common-pageError', {error: error})}}
  </div>
  <div v-if="error==null && survey.id > 0">
    <div class="container large taskHeader">
      <div class="row">
        <div class="col s12 m12 l8 taskTitle">
          <p><a :href="breadcrumbHomeLink()">{{$t('common-home')}}</a> / <a :href="breadcrumbBaseLink() + 'PC'">{{$t('common-pointCount')}}</a> / <a :href="breadcrumbSURVEYProjectLink()">{{project.fullNm}} </a> /</p>
          <h1>{{location.name}}</h1>
          <div class="currentStatus">{{$t('survey-date')}}: {{survey.surveyDate}}</div>
          <div v-if="options != null">{{$t('survey-distanceMethod')}}: <template v-for="distanceMethod in options.distanceMethods"><template v-if="survey.distanceMethodId === distanceMethod.id">{{distanceMethod.type}}</template></template></div>
          <div v-if="options != null">{{$t('survey-durationMethod')}}: <template v-for="durationMethod in options.durationMethods"><template v-if="survey.durationMethodId === durationMethod.id">{{durationMethod.type}}</template></template></div>
          <div v-if="survey.observerName">{{$t('survey-observer')}}: {{survey.observerName}}</div>
        </div>
      </div>
      <weather-segment :projectId="project.id" v-if="survey && location" v-bind:date-string="survey.surveyDate" v-bind:location-id="location.id" v-bind:site-latitude="location.latitude" v-bind:site-longitude="location.longitude" v-bind:show-date="false"></weather-segment>
    </div>
    <div class="container overflow tabbed">
      <ul class="tabs">
        <li class="tab left"><a :class="{'!border-gray-400 border-2 border-b-0 active': showTab ==='main'}" @click="showTab='main'"><span class="ion-ios-glasses-outline"></span> {{$t('survey-observations')}}</a></li>
        <li class="tab left" v-if="location.cameraExists || location.latitude"><a :class="{'!border-gray-400 border-2 border-b-0 active': showTab ==='map'}" @click="showTab='map'"><span class="ion-map"></span> {{$t('common-map')}}</a></li>
        <li class="tab left" v-if="hasLocationVisitImages"><a  :class="{'!border-gray-400 border-2 border-b-0 active': showTab ==='photos'}" @click="showTab='photos'"><span class="ion-ios-camera"></span>{{$tc('common-locationPhotos', 1)}}</a></li>
      </ul>
      <div class="row">
        <div v-show="showTab === 'main'" id="observations" class="col s12 panel boxed padded60 !border-gray-400 border-2 ">
          <survey-log-table v-if="options != null && settings" v-bind:survey-id="survey.id" v-bind:is-admin-view="isUserAdmin" v-bind:options="options" v-bind:bands="settings.bands" v-bind:intervals="settings.intervals"></survey-log-table>
        </div>
        <div v-if="showTab === 'map'" id="mapsAndPhotos" class="col s12 panel boxed padded60 !border-gray-400 border-2">
          <div class="row">
            <div class="col s12 m12 l8">
              <point-map v-if="location && location.latitude && location.longitude" v-bind:latitude="parseFloat(location.latitude)" v-bind:longitude="parseFloat(location.longitude)" />
            </div>
          </div>
          <div v-if="showImage==true" class="modal modal-wrapper" ref="modalBox" @click="hideZoomImage">
            <div class="modal-container" @click="hideZoomImage">
              <img ref="zoomedImg" :src="largeImage">
            </div>
          </div>
          <!-- END MAP PANEL -->
        </div>
        <div v-if="showTab === 'photos'" id="locationVisitPhotos" class="col s12 panel boxed padded60">
          <div class="row">
            <div class="col s12 m12 l8">
              <location-photos v-if="location && project" v-bind:location-id="location.id" v-bind:project-id="project.id"/>
            </div>
          </div>
          <!-- END LOCATION PHOTOS PANEL -->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {getParameterByName, API_URL} from '@/lib/common';
import SinglePointMap from '@/components/mapGL/SinglePointMap';
import PointCountSurveyLogTable from './PointCountSurveyLogTable';
import VisitLocationPhotosVue from '../common/VisitLocationPhotos.vue';
import WeatherSegmentVue from '../common/WeatherSegment.vue';
import CommonPageMixin from '@/components/common/CommonPageMixin';

export default {
  name: 'point-count-survey-page',
  components: { 'point-map': SinglePointMap, 'survey-log-table': PointCountSurveyLogTable, 'location-photos': VisitLocationPhotosVue, 'weather-segment': WeatherSegmentVue },
  mixins: [CommonPageMixin],
  created () { // get task information,
    const surveyId = getParameterByName('surveyId');
    if (!surveyId) {
      this.error = this.$t('common-missingTaskInfoError');
      return;
    }
    this.$http.get(this.getTaskOverviewUrl + surveyId).then(
      (response) => {
        // get body data
        if (response.data.hasOwnProperty('error')) {
          this.error = response.data.error;
        } else {
          this.survey = response.data.survey;
          this.location = response.data.location;
          this.project = response.data.project;
          this.isUserAdmin = response.data.isUserAdmin;
          this.hasLocationVisitImages = response.data.hasLocationVisitImages;
          this.settings = {bands: response.data.bands, intervals: response.data.intervals};
        }
      }, (err) => {
      // error callback
        this.error = err;
      });

    this.$http.get(this.getTaskOptionUrl).then((response) => {
      this.options = response.data;
    }, (err) => {
    // error callback
      this.error = err;
    });
  },
  computed: {},
  methods: {
  },
  data () {
    return {
      getTaskOverviewUrl: API_URL + 'get-observation-overview?surveyId=',
      getTaskOptionUrl: API_URL + 'get-point-count-options',
      showTab: 'main',
      orgStatusId: null,
      options: null,
      survey: {},
      location: {},
      project: {},
      isUserAdmin: null,
      hasLocationVisitImages: null,
      error: null,
      isAdminView: null,
      isFullReadUser: null,
      taskUpdateMessage: '',
      localTaskStatusUpdateMessage: '',
      largeImage: '',
      showImage: false,
      settings: null
    }
  }
}
</script>
<style scoped>
.toggleWrap #toggleInfo .ion-eye-disabled {
    display: block;
}
.currentStatus{
  color: #1369c5;
  font-weight: 800;
}
.userMod {
  color:#c94412;
}

.row .col .thumbnail {
  min-height: 200px;
  background-position: cover;
  background-size: 100%;
  background-repeat: no-repeat;
}
#mapsAndPhotos .iconButton {
  float: right;
  width: 50px;
}

#mapsAndPhotos .modal-wrapper {
  display: flex;
  align-items:center;
  justify-content:center;
  height:100%;
  z-index: 1003;
  top: 10%;
}

#mapsAndPhotos .modal-container{
    display:inline-block;
    width:auto;
}

@media only screen and (max-width: 992px) {
  .row.gallery {
    margin-top:30px;
  }
  .row .col .thumbnail {
    min-height: 150px;
  }
}

.tabbed .tab {
  cursor: pointer;
}
.jwplayer {
  display:none;
}
.NoiseLevel span {
  color: #1369c5;
  font-weight: 800;
}

.btn.btn-noborder {
  background-color: transparent;
  color: #646464;
  border: 0px;
  margin: 0
}

.mini-help-bar{
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 0.8rem;
    color: #9E9E9E;
}

.red-link{
    display: inline;
    color: #C94412;
    padding-right: 12px;
    cursor: pointer;
}

p .red-link{
  font-weight:normal;
  padding-left:5px;
}

.red-emphasis{
  color: #C94412;
  font-weight:800;
}

.top-buttons-wrapper{
  float:right !important;
  display: flex;
  flex-direction: column;
}

.shortcuts > span{
  white-space: nowrap;
}

.text-right{
    text-align: right;
}

.text-toggle{
  font-size:14px;
  color: #646464;
  letter-spacing: .5px;
}

.no-statusOption{
  margin-top: 3em;
}

</style>
