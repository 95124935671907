var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loaded,
              expression: "!loaded"
            }
          ],
          staticClass: "overaly",
          staticStyle: {
            opacity: "0.6",
            width: "100%",
            height: "100%",
            "z-index": "100"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center", margin: "250px 0px" } },
            [
              _c("pulse-loader", {
                attrs: {
                  loading: true,
                  color: "#C94412",
                  size: "20px",
                  width: 400,
                  height: 400
                }
              }),
              _vm._v(" "),
              _c("div", [
                _vm._v(" " + _vm._s(_vm.$t("common-tableTexts.loading")) + " ")
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.error != null
        ? _c("div", { staticClass: "container error padded60" }, [
            _vm.error.statusText != null
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.error.statusText) + "\n    ")
                ])
              : _c("span", [_vm._v("\n      " + _vm._s(_vm.error) + "\n    ")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaded,
              expression: "loaded"
            }
          ],
          staticClass: "container overflow boxed homeHead",
          staticStyle: { "padding-top": "6%", "padding-bottom": "6%" }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("h2", { staticClass: "white-font" }, [_vm._v("My Projects    ")])
        ]
      ),
      _vm._v(" "),
      _vm.error == null && _vm.activeSensor
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loaded,
                  expression: "loaded"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "container overflow tabbed pt-5" },
                [
                  _c(
                    "ul",
                    { staticClass: "tabs" },
                    _vm._l(_vm.sensors, function(sensor, index) {
                      return _c(
                        "li",
                        { key: "sensor" + index, staticClass: "tab" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "hyperlink",
                              class: {
                                "!border-gray-400 border-2 border-b-0":
                                  _vm.activeSensor.id == sensor.id &&
                                  _vm.activeTab == "table",
                                [_vm.activeSensor.id]: true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.updateSensor(sensor)
                                }
                              }
                            },
                            [
                              _c("span", {
                                class: {
                                  "ion-ios-mic": sensor.id == "ARU",
                                  "ion-ios-camera": sensor.id == "CAM",
                                  "ion-android-locate": sensor.id == "PC"
                                }
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    sensor &&
                                      _vm.$t(
                                        `common-${sensor.id.toLowerCase()}`
                                      )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.activeTab == "table" && _vm.activeSensor != null
                    ? _c("project-table", {
                        ref: "project",
                        attrs: {
                          sensor: _vm.activeSensor,
                          "show-admin-only-projects": false
                        },
                        on: { loaded: _vm.loadedEvent }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-dialog")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "headImg image-container", attrs: { id: "project-page" } },
      [
        _c("span", { staticClass: "photo-credit black small" }, [
          _vm._v("Paul Reeves")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }