var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container overflow" }, [
      _c("div", { staticClass: "col s12 panel boxed padded60 dataGroup" }, [
        _c("div", { attrs: { id: "msform" } }, [
          _c("ul", { attrs: { id: "progressbar" } }, [
            _c("li", { class: { active: _vm.navStep === 1 } }, [
              _vm._v(_vm._s(_vm.$tc("common-sensor", 2)))
            ]),
            _vm._v(" "),
            _c("li", { class: { active: _vm.navStep === 2 } }, [
              _vm._v(_vm._s(_vm.$tc("common-project", 2)))
            ]),
            _vm._v(" "),
            _c("li", { class: { active: _vm.navStep === 3 } }, [
              _vm._v(_vm._s(_vm.$t("projectDownload-nav.customize")))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "nextPrev" }, [
            _vm.navStep > 1
              ? _c("input", {
                  staticClass: "previous btn btn-large",
                  attrs: {
                    type: "button",
                    name: "previous",
                    value: _vm.$t("common-prev")
                  },
                  on: {
                    click: function($event) {
                      return _vm.validSelection(-1)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.navStep < 3
              ? _c("input", {
                  staticClass: "next btn btn-large",
                  attrs: {
                    type: "button",
                    name: "next",
                    value: _vm.$t("common-next")
                  },
                  on: {
                    click: function($event) {
                      return _vm.validSelection(1)
                    }
                  }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.warning != null
            ? _c("div", { staticClass: "center-align warning" }, [
                _c("p", {}, [_vm._v(_vm._s(_vm.warning))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.navStep === 1,
                  expression: "navStep === 1"
                }
              ]
            },
            [
              _c("h2", [
                _vm._v(_vm._s(_vm.$t("projectDownload-chooseSensor")))
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "lead center-align" }, [
                _vm._v(_vm._s(_vm.$t("projectDownload-chooseSensorSub")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row sensorSelect" },
                _vm._l(_vm.sensors, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: "sensor" + index,
                      staticClass: "col s12 m12 l3 sensor"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sensor,
                            expression: "sensor"
                          }
                        ],
                        attrs: {
                          name: "sensor",
                          type: "radio",
                          id: "sensor" + item.id,
                          disabled:
                            false &&
                            !item.isSensorAdmin &&
                            !item.isManagerOfAnyProject
                        },
                        domProps: {
                          value: item.id,
                          checked: _vm._q(_vm.sensor, item.id)
                        },
                        on: {
                          change: function($event) {
                            _vm.sensor = item.id
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "sensor" + item.id } }, [
                        _vm._v(
                          _vm._s(_vm.$t(`common-${item.id.toLowerCase()}`))
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _vm.navStep === 2
            ? _c("fieldset", [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("projectDownload-chooseProjects")))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "lead center-align" }, [
                  _vm._v(_vm._s(_vm.$t("projectDownload-chooseProjectsSub")))
                ]),
                _vm._v(" "),
                _vm.sensor === "all"
                  ? _c("div", { staticClass: "allList" }, [
                      _c("p", { staticClass: "center-align" }, [
                        _vm._v(_vm._s(_vm.$t("projectDownload-chooseAll")))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "aruList" },
                  [
                    _c("project-table", {
                      ref: "project-table",
                      attrs: {
                        "active-sensor": _vm.sensor,
                        "pre-selected": _vm.projectIds,
                        "show-admin-only-projects": true
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "fieldset",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.navStep === 3,
                  expression: "navStep === 3"
                }
              ]
            },
            [
              _c("div", { staticClass: "col s3 offset-s4 center" }, [
                _c("h3", [_vm._v(_vm._s(_vm.$t("common-results")))]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-large download",
                    class: {
                      downloading: _vm.isDownloading,
                      disabled: _vm.projectIds.length == 0
                    },
                    attrs: { download: "" },
                    on: { click: _vm.downloadRaw }
                  },
                  [
                    _c("span", { staticClass: "spin-icon" }),
                    _vm._v(_vm._s(_vm.$t("projectDownload-downloadRaw")))
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn btn-large btn-border",
                    attrs: { href: _vm.resetUrl },
                    on: { click: _vm.restart }
                  },
                  [
                    _c("span", { staticClass: "ion-loop" }),
                    _vm._v(_vm._s(_vm.$t("projectDownload-clear")))
                  ]
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "container overflow boxed homeHead !mb-5",
        staticStyle: { "padding-top": "5%", "padding-bottom": "5%" }
      },
      [
        _c(
          "div",
          {
            staticClass: "headImg image-container",
            attrs: { id: "download-page" }
          },
          [
            _c("span", { staticClass: "photo-credit white small" }, [
              _vm._v("Nina Veselka")
            ])
          ]
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "white-font" }, [_vm._v("Data Downloads    ")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }