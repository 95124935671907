<template>
    <v-popover :auto-hide="true" placement='bottom' trigger="click focus" @show="showingPopover()" popoverClass="dropdown-popover">
          <button class="btn btn-success tooltip-target popover-target-button dropdown-btn"> <span style="font-size: 20px;"></span><slot name='menuTitle'></slot></button>
          <template slot="popover">
            <div class="menu-wrapper">
              <slot name="dropdownButtons"></slot>
            </div>
        </template>
    </v-popover>
</template>

<script>
/*
This componente creates a button that opens to a dropdown menu with various options inside. It mimics the magnolia dropdown buttons
*/

import { eventBus } from '@/lib/eventbus';

export default {
  name: 'DropdownButton',
  props: {
  },
  methods: {
    showingPopover () {
      eventBus.$emit('showing-dropdown');
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang='scss'>
.dropdown-popover.tooltip {
  // ...

  &.popover {
    $color: #C94412;

    .popover-inner.inner-profile-dropdown{
      font-size: .74em !important;
      padding: 25px 28px !important;
    }

    .popover-inner {
      background: $color !important;
      color: white !important;
      padding: 10px !important;
      border-radius: 2px !important;
      box-shadow: 0 5px 30px rgba(black, .1) !important;
    }

    .wrapper{
      padding-top: 0px !important;
    }

    /*.popover-arrow {
      border-color: $color !important;
    }*/

    .popover-menu-item{
      width: 100%;
      display: block;
      background-color: transparent !important;
      border:none;
    }

    .popover-menu-item:hover{
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .popover-menu-item:last-child{
      margin-bottom: 0px;
    }
  }
}

.tooltip-target.dropdown-btn.popover-target-button{
  background: white !important;
  border: solid #C94412 1px !important;
  border-radius: 2px;
  color: #C94412 !important;
}

.tooltip.target.dropdown-btn.popover-target-button:disabled{
  border: #9F9F9F !important;
}

.tooltip-target.dropdown-btn.popover-target-button:hover{
  background: #C94412 !important;
  color: white !important;
}

</style>
