<template>
  <div id="everythingTab" class="col s12 panel padded20 boxed active">
    <div id="overview-map" style="position: relative; overflow: hidden;" v-if="latitude != null">
      <mapbox ref="mapBox"
        :access-token="accessToken"
        :map-options="{
          center: [this.longitude, this.latitude],
          zoom: 4,
          style: 'mapbox://styles/mapbox/streets-v11'
        }"
        :scale-control="{
          show: true,
          position: 'top-left',
        }"
        :fullscreen-control="{
          show: true,
          position: 'top-left',
        }"
        @map-load="addMarker"

        @geolocate-error="geolocateError"
        @geolocate-geolocate="geolocate"
      />
    </div>
  </div>
</template>

<script>
/* https://github.com/phegman/vue-mapbox-g
  need to include mapbox gl js and css in html as well.
*/
import Mapbox from 'mapbox-gl-vue'; // can't be combined import; i.e. import {Mapbox} will fail
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import {MAP_ACCESS_TOKEN} from '@/lib/common';
import { setLanguage } from '@/components/mapGL/mapUtil';

export default {
  name: 'location-maps',
  props: {
    latitude: {type: Number, default: 55},
    longitude: {type: Number, default: -112},
    isShow: {type: Boolean, default: false}
  },
  components: {
    'mapbox': Mapbox
  },

  data: function () {
    return {
      accessToken: MAP_ACCESS_TOKEN,
      sourceName: 'location'
    }
  },
  created () {
    /* don't put map, or markere in the data. it will crush vue dev tool, and mess up the map;
      declare them as non-reactive data here.
    */
    this.marker = null;
  },
  watch: {
    isShow (newVal, oldVal) { // when put map box in tab, initally it was hidden, so need to call resize to show the map in full size
      if (this.$refs.mapBox && this.$refs.mapBox.map && newVal) {
        this.$refs.mapBox.map.resize();
        this.marker.setLngLat([this.longitude, this.latitude]);
        this.$refs.mapBox.map.flyTo({
          center: [this.longitude, this.latitude],
          zoom: 4,
          essential: true
        });
      }
    }
  },
  methods: {
    addMarker (map) {
      setLanguage(map);
      this.marker = new mapboxgl.Marker({'color': '#C94412'})
        .setLngLat([this.longitude, this.latitude])
        .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML('<p>' + this.$t('common-longitude').toLowerCase() + ': ' + this.longitude + '</p><p>' + this.$t('common-latitude').toLowerCase() + ': ' + this.latitude + '</p>'))
        .addTo(map);
    },
    initalized (map) {
      // console.log('Map Initalized');
      // this.loadCustomStyle(map);
      const Draw = new MapboxDraw()
      map.addControl(Draw, 'top-right')
    },
    mouseEntered (map) {
      map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeft (map) {
      map.getCanvas().style.cursor = ''
    },
    geolocateError (control, positionError) {
      console.log(positionError)
    },
    geolocate (control, position) {
      console.log(
        `User position: ${position.coords.latitude}, ${position.coords.longitude}`
      )
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 500px;
}
</style>
