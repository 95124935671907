var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-height": "600px" } },
    [
      _c(
        "div",
        { staticClass: "row", staticStyle: { position: "relative" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ui.loading,
                  expression: "ui.loading"
                }
              ],
              staticClass: "overaly",
              staticStyle: {
                opacity: "0.6",
                position: "absolute",
                width: "100%",
                height: "100%",
                "z-index": "100",
                background: "white"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "250px" }
                },
                [
                  _c("pulse-loader", {
                    attrs: {
                      loading: true,
                      color: "#C94412",
                      size: "20px",
                      width: 400,
                      height: 400
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.$t("common-loadingImages")) + " ")
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          !_vm.ui.loading && (!_vm.imageList || _vm.imageList.length === 0)
            ? [
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("cameraTagging-noImagesFound")))
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.imageList && _vm.imageList.length > 0
            ? _c(
                "div",
                {
                  ref: "scrollBounds",
                  staticClass: "col s12 panel",
                  attrs: { id: "scrollBounds" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.ui.infoPanelVisible,
                            expression: "ui.infoPanelVisible"
                          }
                        ],
                        staticClass: "col s12 m2 custom",
                        staticStyle: { position: "relative", padding: "20px" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { id: "infoPanel" }
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "sticky",
                                    rawName: "v-sticky",
                                    value: {
                                      zIndex: 9,
                                      stickyTop: 10,
                                      stickTopOnly: false,
                                      stickyTopElementId: "topStickyBarContent",
                                      positionType: "fixed",
                                      className: "sticky-buttons",
                                      stickyContainerId: "scrollBounds"
                                    },
                                    expression:
                                      "{zIndex: 9, stickyTop: 10, stickTopOnly: false, stickyTopElementId: 'topStickyBarContent', positionType: 'fixed', className: 'sticky-buttons', stickyContainerId: 'scrollBounds'}"
                                  }
                                ],
                                staticClass: "row numberRow"
                              },
                              [
                                _vm.filterMode !== "fov" && _vm.isTagger
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn fullWidth",
                                        attrs: {
                                          disabled: !_vm.ui.showSelectAll,
                                          title: _vm.$t(
                                            "cameraTagging-ctrlASelectAll"
                                          )
                                        },
                                        on: { click: _vm.selectAll }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("common-selectAll"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.filterMode !== "fov" &&
                                _vm.isTagger &&
                                _vm.deploymentId
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn fullWidth",
                                        attrs: {
                                          disabled:
                                            _vm.untaggedImageCount === 0,
                                          title: _vm.$t(
                                            "cameraTagging-ctrlQSelectAllUntagged"
                                          )
                                        },
                                        on: { click: _vm.selectAllUntagged }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "cameraTagging-selectUntagged"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.filterMode !== "fov" && _vm.isTagger
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn fullWidth",
                                        attrs: {
                                          disabled:
                                            _vm.selectedImageCount === 0,
                                          title: _vm.$t(
                                            "cameraTagging-escToDeselectAll"
                                          )
                                        },
                                        on: { click: _vm.unselectAll }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("cameraTagging-deselectAll")
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("drag-select-container", {
                                  ref: "numberSelectArea",
                                  staticClass: "row1 numberSelectArea",
                                  class: {
                                    scroller: _vm.imageList.length > 50
                                  },
                                  attrs: {
                                    "input-selection": _vm.selectedImgId,
                                    selectorClass: _vm.ui.numberPanelClass,
                                    name: "numberArea",
                                    "disable-drag-selection":
                                      _vm.filterMode === "fov"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function({ selectedItems }) {
                                          return _vm._l(_vm.imageList, function(
                                            img,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: img.task.id,
                                                ref: "numberPanel",
                                                refInFor: true,
                                                staticClass: "col numberCol",
                                                class: _vm.getClasses(
                                                  img,
                                                  selectedItems,
                                                  true
                                                ),
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                attrs: {
                                                  id: index,
                                                  "data-item": img.task.id
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "number" },
                                                  [
                                                    img.species &&
                                                    img.species.length > 1
                                                      ? _c("div", {
                                                          staticClass:
                                                            "multipleSpecies"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    img.hasMultipleIndividuals
                                                      ? _c("div", {
                                                          staticClass:
                                                            "multipleIndividual"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    img.task.fovId !== 0
                                                      ? _c("div", {
                                                          staticClass:
                                                            "outOfRange"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(index + 1)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          })
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    642048468
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col s12 m10 custom",
                        class: { fullWidth: !_vm.ui.infoPanelVisible },
                        attrs: { id: "mainPanel" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "grid grid-cols-4 top-item-wrapper mb-3"
                          },
                          [
                            _c("div", { staticClass: "toggleWrap" }, [
                              _c(
                                "div",
                                { staticStyle: { cursor: "pointer" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.ui.infoPanelVisible,
                                          expression: "ui.infoPanelVisible"
                                        }
                                      ],
                                      staticClass: "ion-eye-disabled",
                                      on: {
                                        click: function($event) {
                                          _vm.ui.infoPanelVisible = !_vm.ui
                                            .infoPanelVisible
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "cameraTagging-hideNumberPanel"
                                            )
                                          )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.ui.infoPanelVisible,
                                          expression: "! ui.infoPanelVisible"
                                        }
                                      ],
                                      staticClass: "ion-eye",
                                      on: {
                                        click: function($event) {
                                          _vm.ui.infoPanelVisible = !_vm.ui
                                            .infoPanelVisible
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "cameraTagging-showNumberPanel"
                                            )
                                          )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.ui.showImageType === "untagged",
                                          expression:
                                            "ui.showImageType === 'untagged'"
                                        }
                                      ],
                                      staticClass: "red-orange-colored"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("cameraTagging-untaggedView")
                                          )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.ui.showImageType === "tagged",
                                          expression:
                                            "ui.showImageType === 'tagged'"
                                        }
                                      ],
                                      staticClass: "red-orange-colored"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("cameraTagging-taggedView")
                                          )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.ui.showImageType === "partial",
                                          expression:
                                            "ui.showImageType === 'partial'"
                                        }
                                      ],
                                      staticClass: "red-orange-colored"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "cameraTagging-partialTaggedView"
                                            )
                                          )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.filterMode === "series"
                              ? _c("div", { staticClass: "user-series-gap" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("cameraTagging-seriesGap")
                                      ) +
                                      "\n                "
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.userSeriesGap,
                                          expression: "userSeriesGap"
                                        }
                                      ],
                                      staticClass: "series-gap-select",
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.userSeriesGap = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function($event) {
                                            return _vm.applyUserSeriesGap()
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _vm._l(_vm.userSeriesOptions, function(
                                        option
                                      ) {
                                        return [
                                          _c(
                                            "option",
                                            {
                                              key: option.id,
                                              domProps: { value: option }
                                            },
                                            [_vm._v(_vm._s(option.text))]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.filterMode !== "series"
                              ? _c(
                                  "div",
                                  { staticClass: "toggleWrap items-center" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.imageQueryParam.limit,
                                            expression: "imageQueryParam.limit"
                                          }
                                        ],
                                        ref: "limit",
                                        staticClass:
                                          "record-per-page !flex-initial",
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.imageQueryParam,
                                                "limit",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            _vm.changeNumberPerPage
                                          ]
                                        }
                                      },
                                      _vm._l(_vm.ImagePerPageOption, function(
                                        count,
                                        index
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: "countPerPage" + index,
                                            domProps: { value: count }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("common-perPage", {
                                                  count: count
                                                })
                                              )
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _vm.totalImageCount > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "total-image !flex-initial mr-20"
                                          },
                                          [
                                            _vm._v(
                                              " [ " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "common-image",
                                                    _vm.totalImageCount,
                                                    { num: _vm.totalImageCount }
                                                  )
                                                ) +
                                                "] "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {}, [
                              _c(
                                "label",
                                { staticClass: "whitespace-nowrap" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("Bounding box display threashold")
                                    )
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex items-center" }, [
                                _c("span", [_vm._v("0")]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ui.filter.MDConfidence,
                                      expression: "ui.filter.MDConfidence"
                                    }
                                  ],
                                  staticClass: "flex-grow !m-0",
                                  attrs: {
                                    type: "range",
                                    min: "0",
                                    max: "1",
                                    step: "0.01"
                                  },
                                  domProps: {
                                    value: _vm.ui.filter.MDConfidence
                                  },
                                  on: {
                                    __r: function($event) {
                                      return _vm.$set(
                                        _vm.ui.filter,
                                        "MDConfidence",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("1")])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w-full text-center -mt-[5px]" },
                                [_vm._v(_vm._s(_vm.ui.filter.MDConfidence))]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "toggleWrap right-align",
                                class: {
                                  l6: _vm.filterMode === "series",
                                  l4: _vm.filterMode !== "series"
                                }
                              },
                              [
                                _vm.totalPageNum >= 0 || _vm.pagination !== null
                                  ? _c("pagination", {
                                      ref: "paginationTop",
                                      staticClass: "right",
                                      attrs: {
                                        "active-page-number":
                                          _vm.imageQueryParam.page,
                                        pagination: _vm.pagination,
                                        "total-page-num": _vm.totalPageNum,
                                        "time-stamp": _vm.timeStamp,
                                        "status-field":
                                          _vm.paginationStatusField,
                                        "order-display": _vm.orderDisplay,
                                        "is-series": _vm.filterMode === "series"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.filterMode === "series"
                              ? _c(
                                  "div",
                                  { staticClass: "toggleWrap col-span-4" },
                                  [
                                    _vm.imageList.length > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "total-image warning",
                                            staticStyle: { display: "block" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "cameraTagging-imagesInSeries",
                                                    _vm.imageList.length,
                                                    {
                                                      num: _vm.imageList.length
                                                    }
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.totalImageCount > 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "total-image warning"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "cameraTagging-numImagesTotal",
                                                    _vm.totalImageCount,
                                                    { num: _vm.totalImageCount }
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userSeriesGap || _vm.seriesGap
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "total-image warning"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "cameraTagging-timeBetweenImages"
                                                  )
                                                ) +
                                                " ≤ "
                                            ),
                                            _vm.appliedUserSeriesGap &&
                                            _vm.appliedUserSeriesGap.value !=
                                              null
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.appliedUserSeriesGap
                                                        .value
                                                    )
                                                  )
                                                ]
                                              : [_vm._v(_vm._s(_vm.seriesGap))],
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "commonUnits-secondsFull"
                                                  )
                                                )
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.imageList && _vm.imageList.length > 0,
                                expression: "imageList && imageList.length> 0"
                              }
                            ],
                            staticClass: "row selectable",
                            attrs: { id: "selectable-wrapper" }
                          },
                          [
                            _c(
                              "drag-select-container",
                              {
                                ref: "imageSelectArea",
                                staticClass: "row less-margin",
                                attrs: {
                                  "input-selection": _vm.selectedImgId,
                                  selectorClass: _vm.ui.imagePanelClass,
                                  excludeSelectorClass: "exclude",
                                  "disable-drag-selection":
                                    _vm.filterMode === "fov"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ selectedItems }) {
                                        return [
                                          _vm._l(_vm.imageList, function(
                                            img,
                                            index
                                          ) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: "image" + img.task.id,
                                                  ref: "imagePanel",
                                                  refInFor: true,
                                                  staticClass:
                                                    "col capture img-selectable",
                                                  class: _vm.getClasses(
                                                    img,
                                                    selectedItems,
                                                    true
                                                  ),
                                                  attrs: {
                                                    id: index,
                                                    "data-item": img.task.id
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "imgWrap relative",
                                                      attrs: {
                                                        "data-item":
                                                          img.task.id,
                                                        id: index
                                                      }
                                                    },
                                                    [
                                                      img.megaDetector
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _vm._l(
                                                                img.megaDetector,
                                                                function(
                                                                  MDResult,
                                                                  index
                                                                ) {
                                                                  return [
                                                                    MDResult.confidence >
                                                                    _vm.ui
                                                                      .filter
                                                                      .MDConfidence
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            key: index,
                                                                            staticClass:
                                                                              "absolute border border-red-500",
                                                                            style: {
                                                                              left: `${MDResult.box1 *
                                                                                100}%`,
                                                                              top: `${MDResult.box2 *
                                                                                100}%`,
                                                                              width: `${MDResult.box3 *
                                                                                100}%`,
                                                                              height: `${MDResult.box4 *
                                                                                100}%`
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      typeof img.provided ==
                                                      "undefined"
                                                        ? _c("img", {
                                                            directives: [
                                                              {
                                                                name: "lazy",
                                                                rawName:
                                                                  "v-lazy",
                                                                value:
                                                                  img.fileThumbURL,
                                                                expression:
                                                                  "img.fileThumbURL"
                                                              }
                                                            ],
                                                            attrs: {
                                                              id:
                                                                "img" +
                                                                img.task.id
                                                            },
                                                            on: {
                                                              error: function(
                                                                $event
                                                              ) {
                                                                return _vm.imgUrlAlt(
                                                                  $event,
                                                                  img.fileURL
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _c("img", {
                                                            staticClass:
                                                              "imageNotFound"
                                                          }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "imgOverlay"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "magnify"
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "ion-android-search exclude",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    $event.preventDefault()
                                                                    return _vm.showLightbox(
                                                                      img.fileURL
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.isTagger
                                                                ? _c("span", {
                                                                    staticClass:
                                                                      "tag-isNice exclude",
                                                                    class: {
                                                                      "ion-ios-star-outline": !img
                                                                        .task
                                                                        .nice,
                                                                      "ion-star":
                                                                        img.task
                                                                          .nice
                                                                    },
                                                                    attrs: {
                                                                      title: _vm.$t(
                                                                        "cameraTagging-clickToggleNice"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.markNice(
                                                                          img
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "vidData",
                                                      staticStyle: {
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "number"
                                                        },
                                                        [
                                                          img.species &&
                                                          img.species.length > 1
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "multipleSpecies"
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          img.hasMultipleIndividuals
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "multipleIndividual"
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          img.task.fovId != 0
                                                            ? _c("div", {
                                                                staticClass:
                                                                  "outOfRange"
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                index + 1
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      img.speciesTags &&
                                                      img.speciesTags.length > 0
                                                        ? [
                                                            _vm._l(
                                                              img.speciesTags,
                                                              function(
                                                                tag,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      "species" +
                                                                      tag.id,
                                                                    staticClass:
                                                                      "info exclude"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "tag-details link exclude",
                                                                        attrs: {
                                                                          name:
                                                                            "species" +
                                                                            tag.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "link exclude tag-text break-words",
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.editImageTag(
                                                                                  img
                                                                                    .task
                                                                                    .id
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "tagSpp"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.getTagSpp(
                                                                                      tag
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.getTagDisplay(
                                                                                    tag
                                                                                  )
                                                                                ) +
                                                                                "\n                              "
                                                                            ),
                                                                            index ===
                                                                              0 &&
                                                                            img
                                                                              .task
                                                                              .fovId !==
                                                                              0
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.getFOVType(
                                                                                            img
                                                                                              .task
                                                                                              .fovId
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _vm.isTagger
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "delete"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ion-trash-b exclude",
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        return _vm.confirmDeleteSpecies(
                                                                                          tag.id
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        index ==
                                                                        0
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "moreInfo"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "ion-ios-information exclude",
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.showImageInfoForm(
                                                                                          img
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.speciesId !==
                                                            null
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "location"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getLocation(
                                                                          index
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        : [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "info link exclude"
                                                              },
                                                              [
                                                                _vm.isTagger
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "tag-details link exclude",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.editImageTag(
                                                                              img
                                                                                .task
                                                                                .id
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "cameraTagging-newTag"
                                                                                )
                                                                              ) +
                                                                                "\n                          "
                                                                            ),
                                                                            img
                                                                              .task
                                                                              .fovid
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " | " +
                                                                                        _vm._s(
                                                                                          _vm.staticOptions.fieldOfView.find(
                                                                                            fov =>
                                                                                              fov.id ==
                                                                                              img
                                                                                                .task
                                                                                                .fovid
                                                                                          )
                                                                                            .type
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c("div", {
                                                                      staticClass:
                                                                        "tag-details"
                                                                    }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "moreInfo exclude"
                                                                  },
                                                                  [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "ion-ios-information exclude",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.showImageInfoForm(
                                                                            img
                                                                          )
                                                                        }
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                    ],
                                                    2
                                                  )
                                                ]
                                              )
                                            ]
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2922523297
                                )
                              },
                              [_vm._v("\n              }}\n              ")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.imageList && _vm.imageList.length > 0
                          ? _c("div", {}, [
                              _c(
                                "div",
                                { staticClass: "toggleWrap col s12 m12 l6" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.ui.infoPanelVisible,
                                              expression: "ui.infoPanelVisible"
                                            }
                                          ],
                                          staticClass: "ion-eye-disabled",
                                          on: {
                                            click: function($event) {
                                              _vm.ui.infoPanelVisible = !_vm.ui
                                                .infoPanelVisible
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "cameraTagging-hideNumberPanel"
                                                )
                                              )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.ui.infoPanelVisible,
                                              expression:
                                                "! ui.infoPanelVisible"
                                            }
                                          ],
                                          staticClass: "ion-eye",
                                          on: {
                                            click: function($event) {
                                              _vm.ui.infoPanelVisible = !_vm.ui
                                                .infoPanelVisible
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "cameraTagging-showNumberPanel"
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "toggleWrap col s12 m12 l6 right-align"
                                },
                                [
                                  _vm.totalPageNum >= 0 ||
                                  _vm.pagination !== null
                                    ? _c("pagination", {
                                        ref: "paginationBottom",
                                        staticClass: "right",
                                        attrs: {
                                          "active-page-number":
                                            _vm.imageQueryParam.page,
                                          pagination: _vm.pagination,
                                          "time-stamp": _vm.timeStamp,
                                          "total-page-num": _vm.totalPageNum,
                                          "status-field":
                                            _vm.paginationStatusField,
                                          "order-display": _vm.orderDisplay,
                                          "is-series":
                                            _vm.filterMode === "series"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.speciesId !== null &&
                        _vm.pagination !== null &&
                        _vm.isTagger
                          ? _c(
                              "div",
                              { staticClass: "col s12 m12 right-align" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col s12 m9 right-align" },
                                  [_vm._v(" " + _vm._s(_vm.messages))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col s12 m3 right-align" },
                                  [
                                    _vm.pagination[
                                      _vm.imageQueryParam.page - 1
                                    ][_vm.paginationStatusField] !== "full"
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn btnImportant",
                                            attrs: {
                                              disabled:
                                                _vm.ui.workingOnVerifySpp
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.verifySpecies()
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "ion-checkmark"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "cameraTagging-verifySpecies",
                                                    {
                                                      name:
                                                        _vm.speciesObj
                                                          .commonName
                                                    }
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pagination[
                                      _vm.imageQueryParam.page - 1
                                    ][_vm.paginationStatusField] === "full"
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cameraTagging-pageNumVerified",
                                                {
                                                  num: _vm.imageQueryParam.page
                                                }
                                              )
                                            )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.imageList
        ? _c("lightbox", {
            ref: "lightbox",
            attrs: {
              id: "mylightbox",
              images: _vm.lightboxImages,
              "mega-detector-categories": _vm.megaDetectorCategories,
              timeoutDuration: 10000,
              "can-download": _vm.isTagger,
              project: _vm.project
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.currentImgId != null &&
      _vm.staticOptions &&
      _vm.staticOptions.age &&
      _vm.staticOptions.age.length > 0
        ? _c("tag-form", {
            attrs: {
              id: _vm.currentImgId,
              "form-name": _vm.ui.tagFormName,
              options: _vm.staticOptions,
              "for-untagged": _vm.forUntagged,
              "is-editable": _vm.isTagger,
              project: _vm.project,
              "image-list": _vm.imageList,
              "mega-detector-categories": _vm.megaDetectorCategories
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("img-info", {
        attrs: { img: _vm.currentExifImgObj, "form-name": _vm.ui.exifFormName }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }