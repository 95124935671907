import {API_URL} from '@/lib/common';
import {createLink} from '@/lib/language.js';
// shared by organization page
export default {
  computed: {
    toLoadSubRow () {
      /* can't pass in row id, so use table openChildRows:Array to load all
        ideal solution will handle this for subrow toggler click event, however, the click event is not exposed
      */
      const openedRows = this.$refs.table.openChildRows;
      const data = this.$refs.table.data;
      if (openedRows && openedRows.length > 0) {
        openedRows.forEach((rowId) => {
          const row = data.find(x => x.id === rowId);
          // console.log(row);
          if (row && !row.hasOwnProperty('subDetails')) { // bug from table, when move to different page, the openChildRows remain same for the current page,
            row.childRowLoading = true;
            this.$http.get(API_URL + this.getDetailUrl[0] + this.organizationId + '&' + this.getDetailUrl[1] + '=' + rowId).then(
              (response) => {
                row.childRowLoading = false;
                this.$set(row, 'subDetails', response.data);
              },
              () => { this.$set(row, 'subDetails', []); }

            ).catch(() => { this.$set(row, 'subDetails', []); });
          }
        });
      }
      return true;
    }
  },
  methods: {
    openChildRowLink (id, url) {
      window.open(createLink((url || this.childRowUrl) + id), '_blank');
    },
    deleteBtnTooltip (row, type) {
      if (!this.isEditable) {
        return this.$t('common-adminAccessRequired');
      }
      if (row.subDetails.length > 0) {
        return this.$t('org-cannotDelete', {type: type});
      }
      return null;
    }
  }
}
