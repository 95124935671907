<!--
This form is used for the user to request access to a project or organization
It is used in both projectTable and organizationTable 
-->
<template>
  <div id="accessRequestModal" >
    <div @click="closeForm" class="iconButton link">
      <span class="ion-close"></span>
    </div>
    <div class="modal-header drag-handle" ref="modalHeader">
      <h6>{{$t('accessReqForm-title')}}<template v-if="projectId">{{projectName}}</template><template v-else>{{organizationName}}</template></h6>
    </div>
      <div class="modal-content padded40">
        <div class="row no-bottom-margin">
          <div class="col s12 m6 l6">
            <label for="request_org">{{$tc('common-organization', 1)}}</label>
            <div>{{organizationName}}</div>
          </div>
          <div class="col s12 m6 l6">
          <h6>{{$t('accessReqForm-fields.accessTo')}}
            <span v-if="projectId" class="info-icon ion-information-circled"
              v-tooltip="$t('accessReqForm-tooltips.projectAccess')"/>
            <span v-else class="info-icon ion-information-circled"
              v-tooltip="$t('accessReqForm-tooltips.orgAccess')"/>
          </h6>
          <template v-if="projectName">{{$t('accessReqForm-fields.projectAccess')}}</template><template v-else>{{$t('accessReqForm-fields.orgAccess')}}</template>
          </div>
          <div class="reasonSection col s12">
            <label for="request_reason" ><template v-if="projectName">{{$t('accessReqForm-fields.reasonProj')}}</template><template v-else>{{$t('accessReqForm-fields.reasonOrg')}}</template></label>
              <textarea id="request_reason" class="materialize-textarea" data-length="250" v-model="reason"></textarea>
              <span class="character-counter" style="float: right; font-size: 12px; height: 1px;"></span>
          </div>
            <div class="col s12">
              <template v-if="projectName">{{$t('accessReqForm-emailProject')}}</template><template v-else>{{$t('accessReqForm-emailOrg')}}</template></div>
        </div>
        <div class="modal-footer">
        <div v-if="sending" class="status message col s12" >{{$t('accessReqForm-sending')}}</div>
        <div v-else class=" status col s12" :class="{error: error, message: !error}">{{message}}</div>
          <button class="btn btn-success modal-trigger modal-action modal-close" id="sendRequest" @click="sendRequest">
            <template>{{$t('accessReqForm-send')}}</template>
          </button>
          <button class="btn btn-border modal-action modal-close" id="cancelAdd" @click="closeForm">{{$t('common-close')}}</button>
        </div>
      </div>
    <div v-if="loading" class="center" style="padding:40px;">
        <pulse-loader :loading="true" color="#C94412" size="15px" :width="60" :height="30"></pulse-loader>
        <div>{{commonMsg.loading}}</div>
    </div>
  </div>
</template>

<script>
import {eventBus} from '@/lib/eventbus';
import {API_URL} from '@/lib/common';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading
import FormMixin from '@/components/common/FormMixin.js';

export default {
  name: 'project-access-request-form',
  props: ['projectId', 'organizationId', 'projectName', 'organizationName'],
  components: {'pulse-loader': PulseLoader},
  mixins: [FormMixin],
  data () {
    return {
      sendProjectAccessRequestUrl: API_URL + 'request-project-access',
      sendOrganizationAccessRequestUrl: API_URL + 'request-organization-access',
      loading: true,
      reason: '',
      message: '',
      error: false,
      sending: false
    }
  },
  async created () {
    this.loading = false;
  },
  methods: {
    sendRequest () {
      this.sending = true;
      if (this.projectId) {
        this.requestProjectAccess();
      } else {
        this.requestOrganizationAccess();
      }
    },
    requestProjectAccess () {
      this.$http.get(this.sendProjectAccessRequestUrl, {params: {projectId: this.projectId, requestMessage: this.reason}}).then(function (response) {
        this.sending = false;
        if (response.body.error) {
          this.message = this.$t('accessReqForm-error');
          this.error = true;
        } else {
          this.closeForm();
          // this.message = "Your request has been sent.";
        }
      });
    },

    requestOrganizationAccess () {
      this.$http.get(this.sendOrganizationAccessRequestUrl, {params: {organizationId: this.organizationId, requestMessage: this.reason}}).then(function (response) {
        this.sending = false;
        if (response.body.error) {
          this.message = this.$t('accessReqForm-error');
          this.error = true;
        } else {
          this.closeForm();
          // this.message = "Your request has been sent.";
        }
      });
    },

    closeForm () {
      this.message = '';
      this.error = false;
      this.sending = false;
      eventBus.$emit('close-request-access-form');
      this.$modal.hide('requestAccess');
    }
  }
}
</script>
<style scoped>
.message {
  color: rgb(255, 123, 0);
}

.status{
  height: 25px;
}

.reasonSection{
  margin-top:15px;
}

.no-bottom-margin{
  margin-bottom: 0;
}

</style>
