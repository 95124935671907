<template>
    <div>
<div @click="close" class="iconButton link">
          <span class="ion-close"></span>
        </div>
          <div class="padding-m">
        <h6>{{$t('createVisitForm-pleaseSelectLocation')}}</h6>
        <multiselect :selectAllLabel="$t('common-selectAll')" v-model="createVisitLocation" :options="locationList" :multiple="false" :close-on-select="true"
          selectLabel="" deselectLabel=" " class="input"
          :placeholder="$t('createVisitForm-selectLocation')" label="locationName" track-by="id">
        </multiselect>
        </div>
      <visit-form v-if="createVisitLocation" v-bind:is-editable="true" v-bind:organization-id="organizationId"
      v-bind:location-label="createVisitLocation.locationName" v-bind:creating-visit="true" v-bind:location-id="createVisitLocation.locationId"
      />
    </div>
</template>

<script>
/* this component allows the user to create both a location deployment and visits for it at the same time
*/

import { eventBus } from '@/lib/eventbus';
import { API_URL } from '@/lib/common';
import LocationSingleVisitTable from './LocationSingleVisitTable';
import Multiselect from '@/components/utils/vue-multiselect/src/Multiselect';
import FormMixin from '@/components/common/FormMixin.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue' // spinner for loading

export default {
  name: 'create-new-visit-form',
  mixins: [FormMixin],
  props: [ 'organizationId' ],
  components: {
    'multiselect': Multiselect,
    'visit-form': LocationSingleVisitTable,
    'pulse-loader': PulseLoader
  },
  created () {
    this.loadOptions();

    /* const that = this;
    eventBus.$on('created-location-visit', function (id) {
      that.locationId = id;
    }); */
  },
  watch: {
    organizationId () {
      if (this.$refs.table) {
        this.url = API_URL + 'get-location-visit-summary?organizationId=' + this.organizationId;
      }
      this.loadOptions();
    }
  },
  methods: {
    loadOptions () {
      // load list of locations to choose from
      this.$http.get(this.getLocationsUrl, {params: {organizationId: this.organizationId}}).then(function (response) {
        if (response.body.error) {
        } else {
          this.locationList = response.body.results;
          // sort response
          this.locationList.sort((a, b) => {
            if (a.locationName > b.locationName) {
              return 1;
            }
            if (a.locationName < b.locationName) {
              return -1;
            }
            return 0;
          });
        }
      });
    },
    close () {
      this.createVisitLocation = null;
      eventBus.$emit('close-create-new-visit-form');
      this.$modal.hide('create-new-visit-form');
    }
  },
  data () {
    return {
      getLocationsUrl: API_URL + 'get-location-visit-summary?organizationId=' + this.organizationId,
      locationList: [],
      loading: false,
      createVisitLocation: null
    }
  }
}
</script>
<style scoped>

.padding-m{
  padding: 40px 40px 0 40px;
}
</style>
